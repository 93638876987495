<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div class="flex items-center gap-2">
            <app-platform-logo imgClasses="h-8 w-8" [logo]="platformKey()" [withLazyLoading]="true"></app-platform-logo>
            <div class="malou-text-section-title">
                {{
                    ((platformState() && platformState() === PlatformState.CONNECTED
                        ? 'platforms.help.title.connected'
                        : 'platforms.help.title.not_connected'
                    ) | translate) + platformHelp()?.fullName
                }}
            </div>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <div class="mb-6 flex flex-col gap-4 md:px-4 md:pt-8">
            <ng-container
                [ngTemplateOutlet]="
                    platformState() && platformState() === PlatformState.CONNECTED ? connectedHelpContent : notConnectedHelpContent
                " />
        </div>
    </div>
</div>

<ng-template let-title="title" let-description="description" #infoTemplate>
    <div class="flex">
        <div class="flex flex-col gap-4">
            <div class="malou-text-12--bold malou-color-text-1">{{ title }}</div>
            <div class="malou-text-12--regular text-primary">{{ description }}</div>
        </div>
    </div>
</ng-template>

<ng-template let-title="title" let-features="features" #availableFeatures>
    <div class="flex">
        <div class="flex w-full flex-col gap-2">
            <div class="malou-text-12--bold malou-color-text-1">{{ title }}</div>
            <div
                class="ml-4 grid grid-flow-col grid-cols-2 gap-x-7"
                [ngStyle]="{ 'grid-template-rows': 'repeat(' + featureCountByCol() + ', minmax(0, 1fr))' }">
                @for (feature of features; track feature) {
                    <ng-container>
                        <li class="flex flex-row items-center gap-2">
                            <span class="malou-color-primary text-2xl">•</span>
                            <p class="malou-text-12--regular">{{ feature | enumTranslate: 'platform_connection_help_feature' }}</p>
                        </li>
                    </ng-container>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-title="title" let-information="information" #informationSent>
    <div class="flex">
        <div class="flex w-full flex-col gap-2">
            <div class="malou-text-12--bold malou-color-text-1">{{ title }}</div>
            <div
                class="ml-4 grid grid-flow-col grid-cols-2 gap-x-7"
                [ngStyle]="{ 'grid-template-rows': 'repeat(' + informationCountByCol() + ', minmax(0, 1fr))' }">
                @for (info of information; track info) {
                    <ng-container>
                        <li class="flex flex-row items-center gap-2">
                            <span class="malou-color-primary text-2xl">•</span>
                            <p class="malou-text-12--regular">
                                {{ info | enumTranslate: 'platform_connection_help_information' }}
                            </p>
                        </li>
                    </ng-container>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-title="title" let-updated_infos="updated_infos" #updatedInformation>
    <div class="mt-4 flex w-full flex-col gap-4">
        <div class="flex w-full flex-col gap-2">
            @for (updated_info of updated_infos; track updated_info) {
                <ng-container>
                    <div
                        class="flex grow flex-row items-center gap-4 rounded-xl border border-malou-color-border-primary px-6.5 py-4.5 align-middle">
                        <img class="mr-2 h-5 w-5 shrink-0" loading="lazy" [src]="updated_info.icon | emojiPathResolver" />

                        <div class="flex flex-col gap-1">
                            <div class="malou-text-12--bold malou-color-text-1">{{ updated_info.title }}</div>
                            <div class="malou-text-12--medium malou-color-text-2">{{ updated_info.description }}</div>
                        </div>
                    </div>
                </ng-container>
            }
        </div>
    </div>
</ng-template>

<ng-template #notConnectedHelpContent>
    <ng-container
        [ngTemplateOutlet]="infoTemplate"
        [ngTemplateOutletContext]="{
            title: 'platforms.connection.informations' | translate,
            description: 'platforms.help.descriptions.' + platformHelp()?.key | translate,
        }">
    </ng-container>

    <ng-container
        [ngTemplateOutlet]="updatedInformation"
        [ngTemplateOutletContext]="{
            updated_infos: [
                {
                    icon: 'hourglass',
                    title: 'platforms.help.config_time' | translate,
                    description: (platformHelp()?.registrationTime ?? '' | enumTranslate: 'registration_time'),
                },
                {
                    icon: 'roi/estimated-customers/business_bookings',
                    title: 'platforms.help.price' | translate,
                    description: platformHelp()?.cost ?? '' | enumTranslate: 'platform_cost',
                },
                {
                    icon: 'timer',
                    title: 'platforms.help.creation_time' | translate,
                    description: platformHelp()?.validationPeriod ?? '' | enumTranslate: 'validation_period',
                },
            ],
        }">
    </ng-container>
</ng-template>

<ng-template #connectedHelpContent>
    <ng-container
        [ngTemplateOutlet]="availableFeatures"
        [ngTemplateOutletContext]="{
            title: 'platforms.connection.features.available_features' | translate,
            features: platformHelp()?.features,
        }">
    </ng-container>

    @let updateTime = platformHelp()?.updateTime;
    @if (updateTime && platformHelpMappedInformationSent().length) {
        <ng-container>
            <ng-container
                [ngTemplateOutlet]="informationSent"
                [ngTemplateOutletContext]="{
                    title: 'platforms.connection.update_information.title' | translate,
                    information: platformHelpMappedInformationSent(),
                }">
            </ng-container>

            <div class="flex grow flex-row gap-4 rounded-xl border border-malou-color-border-primary px-6.5 py-4.5 align-middle">
                <img class="mr-2 h-5 w-5 shrink-0" loading="lazy" [src]="'hourglass' | emojiPathResolver" />
                <div class="flex w-full flex-row items-center justify-between">
                    <div class="malou-text-12--bold malou-color-text-1">{{ 'platforms.connection.update_time' | translate }}</div>
                    <div class="malou-text-12--semibold malou-color-text-1">
                        {{ updateTime | enumTranslate: 'update_time' }}
                    </div>
                </div>
            </div>
        </ng-container>
    }
</ng-template>
