import { compact, omit } from 'lodash';
import { DateTime } from 'luxon';

import {
    AddressDto,
    LightRestaurantDto,
    LightRestaurantWithBoosterPackDto,
    NfcWithRestaurantDto,
    RestaurantResponseDto,
} from '@malou-io/package-dto';
import {
    BusinessCategory,
    CountriesWithEvents,
    PictureSize,
    PlatformDataFetchedStatus,
    PostType,
    removeDiacritics,
    SocialNetworkKey,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { UserRestaurant } from ':modules/user/user';

import { Address } from './address';
import { RestaurantAttribute } from './attribute';
import { Brick } from './brick';
import { Category } from './category';
import { Description } from './description';
import { SimpleKeyword } from './keyword';
import { Media } from './media';
import { Menu } from './menu';
import { Organization } from './organization.js';
import { HoursType, OtherPeriod, SpecialTimePeriod, TimePeriod } from './periods';
import { Phone } from './phone';
import { Platform } from './platform';
import { PlatformAccess } from './platform-access';
import { PostWithInsightsAndHoveredPosts } from './post.js';

export interface CurrentState {
    reviews: {
        fetched: Record<string, FetchedState<PlatformDataFetchedStatus>>;
    };
    messages: {
        lastSync: Date;
        fetched: Record<string, FetchedState<string>>;
    };
    comments: {
        fetched: Record<string, FetchedState<string>>;
    };
    posts: {
        lastSync: Date;
    };
}

export interface FetchedState<T> {
    status: T;
    lastTried: Date;
    error: string;
}

export interface SocialNetworkUrl {
    key: SocialNetworkKey;
    url: string;
}

export interface RestaurantInterface {
    name?: string;
    type?: BusinessCategory;
    description?: string;
    phone?: Phone;
    address?: Address;
    website?: string;
    email?: string;
    openingDate?: Date;
    logo?: Media | null;
    cover?: Media;
    regularHours?: TimePeriod[];
    specialHours?: SpecialTimePeriod[];
    descriptions?: Description[];
    menu?: Menu | null;
    category?: Category | null;
    categoryList?: Category[];
    access?: PlatformAccess[];
    organization?: Organization;
}

export interface IRestaurant {
    _id: string;
    id: string;
    name: string;
    uniqueKey: string;
    type: BusinessCategory;
    placeId?: string;
    socialId?: string;
    description: string;
    organization?: Organization;
    organizationId?: string;
    phone?: Phone;
    address?: Address;
    bricksPostalCode: string;
    website: string;
    email: string;
    openingDate: Date;
    logo: Media | null;
    logoChanged?: boolean;
    cover: Media;
    coverChanged?: boolean;
    createdAt: Date;
    updatedAt: Date;
    platforms: Platform[];
    platformKeys: string[];
    regularHours: TimePeriod[];
    specialHours: SpecialTimePeriod[];
    otherHours: OtherPeriod[];
    availableHoursTypes: HoursType[];
    availableHoursTypeIds: string[];
    isClosedTemporarily: boolean;
    descriptions: Description[];
    menu?: Menu | null;
    category: Category | null;
    categoryList: Category[];
    attributeList?: RestaurantAttribute[];
    access: PlatformAccess[];
    keywords: SimpleKeyword[];
    bricks: Brick[];
    reviewsLastUpdate: Date;
    isClaimed: boolean;
    menuUrl: string;
    toolboxMenuUrl: string;
    relatedUrls: string[];
    currentState: CurrentState;
    managers: UserRestaurant[];
    bookmarkedPosts: PostWithInsightsAndHoveredPosts[];
    latlng: {
        lat: number;
        lng: number;
    };
    isMine?: boolean;
    calendarEventsCountry: CountriesWithEvents;

    fullFormattedAddress: string;
    internalName: string;
}

export class Restaurant implements IRestaurant {
    _id: string;
    id: string;
    reservationUrl?: string;
    orderUrl?: string;
    socialNetworkUrls?: SocialNetworkUrl[];
    name: string;
    uniqueKey: string;
    type: BusinessCategory;
    placeId?: string;
    socialId?: string;
    description: string;
    organization?: Organization;
    organizationId?: string;
    phone?: Phone;
    address?: Address;
    bricksPostalCode: string;
    website: string;
    email: string;
    openingDate: Date;
    logo: Media | null;
    cover: Media;
    createdAt: Date;
    updatedAt: Date;
    platforms: Platform[] = [];
    platformKeys: string[] = [];
    regularHours: TimePeriod[] = [];
    specialHours: SpecialTimePeriod[] = [];
    otherHours: OtherPeriod[] = [];
    availableHoursTypes: HoursType[] = [];
    availableHoursTypeIds: string[] = [];
    isClosedTemporarily: boolean;
    descriptions: Description[] = [];
    menu?: Menu | null;
    category: Category | null;
    categoryList: Category[] = [];
    attributeList?: RestaurantAttribute[];
    access: PlatformAccess[];
    keywords: SimpleKeyword[];
    bricks: Brick[];
    reviewsLastUpdate: Date;
    isClaimed: boolean;
    menuUrl: string;
    toolboxMenuUrl: string;
    relatedUrls: string[];
    currentState: CurrentState;
    managers: UserRestaurant[];
    bookmarkedPosts: (PostWithInsightsAndHoveredPosts & { type?: PostType })[];
    latlng: {
        lat: number;
        lng: number;
    };
    isManagedByLoggedUser?: boolean;
    calendarEventsCountry: CountriesWithEvents;

    fullFormattedAddress: string;
    ai: {
        callCount?: number;
        monthlyCallCount?: number;
    };
    boosterPack: {
        activated: boolean;
        activationDate: Date | null;
    };
    active: boolean;
    internalName: string;
    logoChanged: boolean;
    coverChanged: boolean;
    roiActivated: boolean;
    isYextActivated: boolean;
    keywordToolApiLocationId?: string;

    public constructor(init?: Partial<Restaurant>) {
        Object.assign(this, omit(init, 'otherHours'));
        this.regularHours = (this.regularHours || []).map((u: any) => new TimePeriod(u));
        this.specialHours = (this.specialHours || []).map((u: any) => new SpecialTimePeriod(u));
        this.descriptions = this.descriptions.map((u: any) => new Description(u));

        if (this.category) {
            this.category = new Category(this.category);
        }
        this.categoryList = (this.categoryList || []).map((u: any) => new Category(u));

        if (this.address) {
            this.address = new Address(this.address);
        }

        this.fullFormattedAddress = this.getFullFormattedAddress();

        if (this.platforms.length > 0 && typeof this.platforms[0] !== 'string') {
            this.platforms = this.platforms.map((u: any) => new Platform(u));
        }

        if (this.menu) {
            this.menu = new Menu(this.menu);
        }

        if (this.phone) {
            this.phone = new Phone(this.phone);
        }

        if (this.logo) {
            this.logo = new Media(this.logo);
        }

        if (this.cover) {
            this.cover = new Media(this.cover);
        }

        this._initOtherHours(init);
        this.availableHoursTypes = (this.availableHoursTypes ?? []).map((hour: any) => new HoursType(hour));
        this.openingDate = this.openingDate ? new Date(this.openingDate) : this.openingDate;
        this.internalName = this.internalName ?? this.name;
        this.id = init?.id ?? init?._id ?? this._id;
    }

    static fromRestaurantResponseDto(restaurantDto: RestaurantResponseDto): Restaurant {
        return new Restaurant({
            // TODO to complete later, sorry I don't have time to do it now
            ...restaurantDto,
            access: undefined,
            bricks: [],
            phone: restaurantDto.phone ? new Phone(restaurantDto.phone) : undefined,
            address: restaurantDto.address ? new Address(restaurantDto.address) : undefined,
            openingDate: restaurantDto.openingDate ? new Date(restaurantDto.openingDate) : undefined,
            logo: undefined,
            cover: undefined,
            category: undefined,
            categoryList: [],
            otherHours: restaurantDto.otherHours ? restaurantDto.otherHours.map((oh) => new OtherPeriod(oh)) : undefined,
            regularHours: restaurantDto.regularHours ? restaurantDto.regularHours.map((rh) => new TimePeriod(rh)) : undefined,
            specialHours: restaurantDto.specialHours ? restaurantDto.specialHours.map((sh) => new SpecialTimePeriod(sh)) : undefined,
            descriptions: [],
            reviewsLastUpdate: restaurantDto.reviewsLastUpdate ? new Date(restaurantDto.reviewsLastUpdate) : undefined,
            relatedUrls: restaurantDto.relatedUrls ? compact(restaurantDto.relatedUrls) : [],
            currentState: undefined,
            bookmarkedPosts: [],
            calendarEventsCountry: undefined,
            boosterPack: restaurantDto.boosterPack
                ? {
                      activationDate: restaurantDto.boosterPack.activationDate ? new Date(restaurantDto.boosterPack.activationDate) : null,
                      activated: !!restaurantDto.boosterPack.activated,
                  }
                : undefined,
            keywordToolApiLocationId: restaurantDto.keywordToolApiLocationId,
            createdAt: restaurantDto.createdAt ? new Date(restaurantDto.createdAt) : undefined,
            updatedAt: restaurantDto.updatedAt ? new Date(restaurantDto.updatedAt) : undefined,
        });
    }

    /**
     * Returns true if a restaurant is linked to a platform
     *
     * @param key - Platform key
     */
    hasPlatform(key: string): Boolean {
        return this.platformKeys.includes(key);
    }

    /**
     * Return formatted phone number
     */
    getDisplayPhone(): string | null {
        if (!this.phone || !this.phone.prefix || !this.phone.digits) {
            return null;
        }
        const strDigits = this.phone.digits.toString();
        if (this.phone.prefix === 1) {
            // US phone format
            return `+${this.phone.prefix} (${strDigits.substring(0, 3)}) ${strDigits.substring(3, 6)}-${strDigits.substring(6)}`;
        }
        return `+${this.phone.prefix} ${strDigits[0]} ${strDigits
            .substring(1)
            .match(/.{1,2}/g) // split string in equal number of chunks of size 2
            ?.join(' ')}`;
    }

    equals(o: any): boolean {
        return o instanceof Restaurant && (o as Restaurant)._id === this._id;
    }

    getFullFormattedAddress(): string {
        if (!this.address) {
            return '';
        }
        return this.address.getFullFormattedAddress();
    }

    getFormattedAddressWithPostalCodeAndLocality(): string {
        if (!this.address) {
            return '';
        }
        return this.address.getFormattedAddressWithPostalCodeAndLocality();
    }

    isBrandBusiness(): boolean {
        return this.type === BusinessCategory.BRAND;
    }

    getDisplayedValue(): string {
        return this.name;
    }

    getDisplayName(): string {
        return this.internalName || this.name;
    }

    getNameAsHashtag(): string {
        let name = this.name;
        name = removeDiacritics(name);
        name = name.replace(/[^a-zA-Z0-9]/g, '');
        name = name.toLowerCase();
        return `#${name}`;
    }

    numberOfMonthSinceCreated(): number {
        const malouCreationDate = DateTime.fromJSDate(new Date(this.createdAt));
        const numberOfMonthsFromNow = malouCreationDate.diffNow('months')?.months;
        return Math.floor(-numberOfMonthsFromNow) ?? 0;
    }

    getMostRecentDateBetweenCreationAndOpening(): Date | null {
        if (!this.openingDate) {
            return new Date(this.createdAt);
        }
        if (!this.createdAt) {
            return null;
        }
        const createdDate = new Date(this.createdAt);
        const openedDate = new Date(this.openingDate);
        return createdDate.getTime() <= openedDate.getTime() ? openedDate : createdDate;
    }

    private _initOtherHours(init?: Partial<Restaurant>): void {
        const initOtherHours: OtherPeriod[] = init?.otherHours ?? [];
        if (initOtherHours.length) {
            this.otherHours = initOtherHours.map((otherPeriod: OtherPeriod) => new OtherPeriod(otherPeriod));
        }
    }
}

export class RestaurantWithTotemsAndWheels extends Restaurant {
    hasAggregatedWheelOfFortune?: boolean;
    hasWheelOfFortune?: boolean;
    missingPermissionKeys: WheelOfFortuneRedirectionPlatformKey[] = [];
    totems?: NfcWithRestaurantDto[];

    constructor(data: Partial<RestaurantWithTotemsAndWheels>) {
        super(data);
        this.totems = data.totems;
        this.hasAggregatedWheelOfFortune = data.hasAggregatedWheelOfFortune;
        this.hasWheelOfFortune = data.hasWheelOfFortune;
        this.missingPermissionKeys = data.missingPermissionKeys ?? [];
    }

    hasNoWheel(): boolean {
        return !this.hasAggregatedWheelOfFortune && !this.hasWheelOfFortune;
    }

    hasNoOtherWheel(isInCurrentWheel = false): boolean {
        return !(this.hasAggregatedWheelOfFortune && !isInCurrentWheel) && !this.hasWheelOfFortune;
    }
}

interface ILightRestaurant {
    id: string;
    name: string;
    internalName?: string;
    address?: Address | AddressDto;
    logo: Media | null;
}

export class LightRestaurant implements ILightRestaurant {
    id: string;
    name: string;
    internalName?: string;
    address?: Address;
    logo: Media | null;

    constructor(init: LightRestaurantDto | ILightRestaurant) {
        this.id = init.id;
        this.name = init.name;
        this.internalName = init.internalName;
        this.address = init.address ? new Address(init.address) : undefined;
        this.logo = init.logo ? new Media(init.logo) : null;
    }

    getDisplayName(): string {
        return this.internalName || this.name;
    }

    getAddressDisplayedValue(): string {
        return this.address?.getDisplayedValue() || '';
    }

    getFullFormattedAddress(): string {
        return this.address?.getFullFormattedAddress() || '';
    }

    getLogoUrl(size: string = PictureSize.ORIGINAL): string {
        return this.logo?.getMediaUrl(size) || '';
    }

    static fromRestaurant(restaurant: Restaurant): LightRestaurant {
        return new LightRestaurant({
            id: restaurant._id,
            name: restaurant.name,
            internalName: restaurant.internalName,
            address: restaurant.address,
            logo: restaurant.logo,
        });
    }
}

export class LightRestaurantWithBoosterPack extends LightRestaurant {
    boosterPack?: {
        activated: boolean;
        activationDate: Date | null;
    };

    constructor(init: LightRestaurantWithBoosterPackDto | LightRestaurantWithBoosterPack) {
        super(init);
        this.boosterPack = init.boosterPack
            ? {
                  activated: init.boosterPack.activated,
                  activationDate: init.boosterPack.activationDate ? new Date(init.boosterPack.activationDate) : null,
              }
            : undefined;
    }

    static fromRestaurant(restaurant: Restaurant): LightRestaurantWithBoosterPack {
        return new LightRestaurantWithBoosterPack({
            id: restaurant._id,
            name: restaurant.name,
            internalName: restaurant.internalName,
            address: restaurant.address,
            logo: restaurant.logo,
            boosterPack: restaurant.boosterPack
                ? {
                      activated: restaurant.boosterPack.activated,
                      activationDate: restaurant.boosterPack.activationDate?.toISOString() ?? null,
                  }
                : undefined,
        });
    }
}

export class RestaurantWithTooltipProperties extends Restaurant {
    disabledWithTooltip?: boolean;
    tooltipMessage?: string;
    constructor(init: Restaurant, disabled: boolean, tooltipMessage?: string) {
        super(init);
        this.disabledWithTooltip = disabled;
        this.tooltipMessage = tooltipMessage;
    }
}

export const mapRestaurantToLightRestaurant = (restaurant: Restaurant): LightRestaurant =>
    new LightRestaurant({
        id: restaurant._id,
        name: restaurant.getDisplayedValue(),
        internalName: restaurant.internalName,
        address: restaurant.address,
        logo: restaurant.logo?.id && restaurant.logo?.urls ? restaurant.logo : null,
    });

export const mapRestaurantWithTotemsAndWheelsToLightRestaurant = (restaurant: RestaurantWithTotemsAndWheels): LightRestaurant =>
    new LightRestaurant({
        id: restaurant._id,
        name: restaurant.getDisplayedValue(),
        internalName: restaurant.internalName,
        address: restaurant.address,
        logo: restaurant.logo?.id && restaurant.logo?.urls ? restaurant.logo : null,
    });
