import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit, output, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { catchError, combineLatest, EMPTY, filter, map, Observable, switchMap, take, tap } from 'rxjs';

import { isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SocialPostMediaItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-media-item/social-post-media-item.component';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { updateRefreshDates } from ':modules/posts/posts.actions';
import { selectRefreshDates } from ':modules/posts/posts.selectors';
import { SizeStrategy } from ':shared/components/media-item/media-item.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { shouldRefreshPost } from ':shared/helpers/should-refresh-post';
import { Platform, Restaurant } from ':shared/models';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

interface PlatformsStore {
    platformsData: {
        [key: string]: Platform[];
    };
}

/** A preview of the Instagram feed */
@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostMediaItemComponent,
        SkeletonComponent,
        InfiniteScrollDirective,
        ApplyPurePipe,
        ApplySelfPurePipe,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedComponent implements OnInit {
    readonly feed = input.required<FeedItem[]>();
    readonly shouldShowLoading = input.required<boolean>();
    readonly shouldShowLoadingMore = input.required<boolean>();
    readonly draggable = input.required<boolean>();

    readonly feedItemClicked = output<FeedItem>();

    readonly placeholder = viewChild<CdkDropList>(CdkDropList);

    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _postsService = inject(PostsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly PostPublicationStatus = PostPublicationStatus;
    readonly SizeStrategy = SizeStrategy;

    readonly MEDIA_WITHOUT_LAZY_LOAD_COUNT = 30;

    private readonly _platformsStore$: Observable<PlatformsStore> = this._store.select((state) => state.platforms);
    private readonly _availablePlatforms$ = this._getAvailablePlatforms$();
    private readonly _availablePlatforms = toSignal(this._availablePlatforms$, { initialValue: [] });

    readonly isIgConnected = computed(() => this._availablePlatforms().some((p) => p === PlatformKey.INSTAGRAM));

    readonly isFeed45Enabled = toSignal(this._experimentationService.isFeatureEnabled$('release-feed-4-5'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-feed-4-5'),
    });

    ngOnInit(): void {
        // Initial fetch
        this._socialPostsContext.goNextPageFeed();
    }

    onScroll(): void {
        const hasNextPage = this._socialPostsContext.hasNextPageFeed();
        if (hasNextPage) {
            this._socialPostsContext.goNextPageFeed();
        }
    }

    onRefreshPost(feedItem: FeedItem): void {
        this._store
            .select(selectRefreshDates)
            .pipe(
                take(1),
                map((dates) => dates[feedItem.postId]),
                switchMap((date) => {
                    if (shouldRefreshPost(date)) {
                        return this._postsService.refresh(feedItem.postId).pipe(
                            map((res) => res.data),
                            tap((_newPost) => {
                                // TODO with context
                                // this.igPosts.update((currentPosts) =>
                                //     currentPosts.map((currentPost) =>
                                //         currentPost.id === newPost.id ? currentPost.copyWith(newPost) : currentPost
                                //     )
                                // );
                                this._store.dispatch(updateRefreshDates({ postId: feedItem.postId }));
                            })
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    navigateToPlatforms(): void {
        this._router.navigate([`/restaurants/${this._restaurantsService.currentRestaurant._id}/settings/platforms`]);
    }

    sortPredicate(index: number, drag: CdkDrag<FeedItem>, drop: CdkDropList<FeedItem[]>): boolean {
        const dropFeedItem = drop.data[index];
        return (
            isNotNil(dropFeedItem) &&
            dropFeedItem.published !== PostPublicationStatus.PUBLISHED &&
            dropFeedItem.getPostDate() > new Date() &&
            isNotNil(drag.data.plannedPublicationDate) &&
            new Date(drag.data.plannedPublicationDate).getTime() > Date.now()
        );
    }

    onDropListDropped(event: CdkDragDrop<FeedItem[], FeedItem[], FeedItem>): void {
        const movingPost: FeedItem = event?.item?.data;
        if (!movingPost) {
            return;
        }

        const sourceIndex = event.previousIndex;
        const destinationIndex = event.currentIndex;
        if (sourceIndex === destinationIndex) {
            return;
        }
        this._socialPostsContext.swapPlannedPublicationDates(sourceIndex, destinationIndex);
    }

    onFeedItemClicked(feedItem: FeedItem): void {
        if (this.canFeedItemBeClicked(feedItem)) {
            this.feedItemClicked.emit(feedItem);
        }
    }

    getFeedItemStatus = (feedItem: FeedItem): PostPublicationStatus | undefined => {
        if (feedItem.published === PostPublicationStatus.PUBLISHED) {
            return;
        }
        return feedItem.published;
    };

    canFeedItemBeDragged = (feedItem: FeedItem): boolean =>
        this.draggable() &&
        [PostPublicationStatus.DRAFT, PostPublicationStatus.PENDING].includes(feedItem.published) &&
        feedItem.getPostDate() >= new Date();

    canFeedItemBeClicked = (feedItem: FeedItem): boolean =>
        this.draggable() &&
        ([PostPublicationStatus.ERROR, PostPublicationStatus.PUBLISHED].includes(feedItem.published) ||
            ([PostPublicationStatus.DRAFT, PostPublicationStatus.PENDING].includes(feedItem.published) &&
                feedItem.getPostDate() < new Date()));

    private _getAvailablePlatforms$(): Observable<PlatformKey[]> {
        return combineLatest([this._platformsStore$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([platforms, restaurant]) => !!restaurant && !!platforms.platformsData[restaurant._id]),
            map(([platforms, restaurant]: [PlatformsStore, Restaurant]) => {
                const socialPostsPlatforms = PlatformDefinitions.getPlatformKeysWithFeed();
                const connectedPlatforms = platforms.platformsData[restaurant._id].map((plat) => plat.key);
                return socialPostsPlatforms.filter((platform) => connectedPlatforms.includes(platform));
            }),
            catchError((err) => {
                console.warn('err :>> ', err);
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }
}
