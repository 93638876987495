import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { Observable, of } from 'rxjs';

import { SelectComponent } from ':shared/components/select/select.component';
import { TemplateRatingFilterComponent } from ':shared/components/template-rating-filter/template-rating-filter.component';
import { Template } from ':shared/models';
import { CommentOption } from ':shared/models/comment-option';
import { MapPipe } from ':shared/pipes/map.pipe';

// TODO: delete when removing 'release-review-revamp' feature flag
export enum SaveReplyAsTemplateTheme {
    OUTLINED = 'outlined',
    FILLED = 'filled',
}

@Component({
    selector: 'app-save-reply-as-template',
    standalone: true,
    imports: [
        NgClass,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SelectComponent,
        TemplateRatingFilterComponent,
        MapPipe,
    ],
    templateUrl: './save-reply-as-template.component.html',
    styleUrls: ['./save-reply-as-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveReplyAsTemplateComponent {
    readonly templates = input.required<Template[]>();
    readonly theme = input<string>(SaveReplyAsTemplateTheme.FILLED);
    readonly disabled = input<boolean>(false);
    readonly saveTemplateForm = input.required<FormGroup>();
    readonly availableCommentOptions = input.required<CommentOption[]>();
    readonly shouldDisplayTemplateUpdateWarning = model.required<boolean>();

    readonly saveTemplateActivatedChange = output<boolean>();
    readonly templateNameChange = output<string>();
    readonly ratingsChange = output<number[]>();

    readonly templateNames = computed(() => compact(this.templates().map((template) => template.name)));

    readonly SaveReplyAsTemplateTheme = SaveReplyAsTemplateTheme;

    readonly templateNameBuilder = (templateName: string): Observable<string> => of(templateName);
    readonly commentOptionDisplayWith = (commentOption: CommentOption): string => commentOption?.text;

    onSelectSaveTemplateChange(event: string | null): void {
        if (!event) {
            this.shouldDisplayTemplateUpdateWarning.set(false);
            return;
        }
        const templateName = event;
        this.shouldDisplayTemplateUpdateWarning.set(this.templateNames().includes(templateName));
        this.templateNameChange.emit(templateName);
    }

    toggleSaveTemplate(event: MatCheckboxChange): void {
        this.saveTemplateActivatedChange.emit(event.checked);
        if (!event.checked) {
            return;
        }
        const element = document.getElementById('saveTemplateRef');
        if (element) {
            setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'center' }), 200);
        }
    }

    onSelectRatings(ratings: number[]): void {
        this.ratingsChange.emit(ratings);
    }
}
