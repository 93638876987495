import { ReviewAnalysisSentiment, ReviewAnalysisStatus, ReviewAnalysisTag, SemanticAnalysisProviderKey } from '../../constants';

// TODO: Remove when feature toggle 'release-new-semantic-analysis' is removed
export function getReviewsForSemanticAnalysis(reviews) {
    return reviews.filter(
        (review) =>
            review.semanticAnalysis?.providerKey === SemanticAnalysisProviderKey.OPENAI &&
            review.semanticAnalysis?.status !== ReviewAnalysisStatus.FAILED
    );
}

// TODO: Semantic Analysis Reports - 'release-new-semantic-analysis' - use semanticAnalysisSegments
export function getReviewsForSemanticAnalysisStats(reviews) {
    return reviews
        .map((review) => review.semanticAnalysis?.segmentAnalyses)
        .filter((review) => review !== null || review !== undefined)
        .flat()
        .filter((el) => el.tag !== ReviewAnalysisTag.OVERALL_EXPERIENCE && el.sentiment !== ReviewAnalysisSentiment.NEUTRAL);
}

export function getPositiveAndNegativeStatsForSemanticAnalysis(reviews) {
    const positiveSentimentsCount = reviews.filter((s) => s.sentiment === ReviewAnalysisSentiment.POSITIVE).length;
    const negativeSentimentsCount = reviews.filter((s) => s.sentiment === ReviewAnalysisSentiment.NEGATIVE).length;
    const positiveSentimentsPercentage = Math.round((positiveSentimentsCount * 100) / reviews.length);
    const negativeSentimentsPercentage = Math.round((negativeSentimentsCount * 100) / reviews.length);

    return {
        positiveSentimentsCount,
        positiveSentimentsPercentage,

        negativeSentimentsCount,
        negativeSentimentsPercentage,
    };
}
