<div
    class="modal-container min-w-[40vw]"
    [ngClass]="{
        'grid h-full place-items-center': displayCloseModal() && !screenSizeService.isPhoneScreen,
        'h-[90vh]':
            !displayCloseModal() &&
            !isReviewRevampFeatureEnabled() &&
            !screenSizeService.isPhoneScreen &&
            (!data().isAggregatedView || !simplerCombinedReviewsExperimentationEnabled()),
        'h-full':
            !displayCloseModal() &&
            !screenSizeService.isPhoneScreen &&
            data().isAggregatedView &&
            !!simplerCombinedReviewsExperimentationEnabled(),
        'h-[100vh]': isReviewRevampFeatureEnabled(),
        'h-[80vh]': !displayCloseModal && screenSizeService.isPhoneScreen && !isReviewRevampFeatureEnabled(),
        'h-[50vh]': displayCloseModal() && screenSizeService.isPhoneScreen && !isReviewRevampFeatureEnabled(),
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal class="w-full" id="closeModal" (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)">
    </app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <div class="malou-dialog min-md:!flex-row">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="leftSide"></ng-container>
        }
        <ng-container [ngTemplateOutlet]="rightSide"></ng-container>
    </div>
</ng-template>

<ng-template #leftSide>
    <div class="flex w-[40%] flex-col" [ngClass]="{ 'm-8': !isReviewRevampFeatureEnabled(), 'my-8': isReviewRevampFeatureEnabled() }">
        @if (!data().isAggregatedView || !simplerCombinedReviewsExperimentationEnabled()) {
            <ng-container [ngTemplateOutlet]="filters"></ng-container>
        }
        @if (reviews().length) {
            <div
                class="mr-[-1rem] overflow-y-scroll pr-4"
                id="scrollContainer"
                infinite-scroll
                [infiniteScrollDistance]="2"
                [infiniteScrollContainer]="scrollContainerSelector"
                [fromRoot]="true"
                (scrolled)="onScrollDown()"
                (scrolledUp)="onScrollUp()">
                <div class="flex flex-col pb-1" [ngClass]="{ 'gap-y-2.5': !isReviewRevampFeatureEnabled() }">
                    @for (review of reviews(); track trackByUpdatedAtFn(index, review); let index = $index) {
                        <div>
                            <ng-container
                                [ngTemplateOutlet]="reviewTemplate"
                                [ngTemplateOutletContext]="{
                                    reviewData: review,
                                    withArchiveButton: true,
                                    isSelected: review._id === selectedReview()?._id,
                                    isSelectable: true,
                                    hasBorder: review._id === selectedReview()?._id,
                                    id: review._id,
                                    isLastReview: index === reviews().length - 1,
                                    disabled: !(review | canBeReplied),
                                    parentListIndex: index,
                                }"></ng-container>
                            @if (isReviewRevampFeatureEnabled() && isAroundSelectedReview()(index)) {
                                <mat-divider class="!mx-4 !my-0 !border-malou-color-border-primary"></mat-divider>
                            }
                        </div>
                    }
                    @if (!allReviewsLoaded()) {
                        <button class="mt-2 cursor-pointer text-center" mat-button (click)="onScrollDown()">
                            {{ 'common.see_more' | translate }}
                        </button>
                    }
                </div>
            </div>
        } @else {
            <div class="flex h-full flex-col items-center justify-center">
                <div class="mb-10 w-32 md:mb-5 md:w-24">
                    <img alt="Googles illustration" [src]="'Goggles' | illustrationPathResolver" />
                </div>
                <div class="px-6 text-center">
                    <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                        {{ 'reviews.no_reviews.with_filters' | translate }}
                    </h3>
                    <p class="malou-text-10--regular">{{ 'reviews.no_reviews_due_to_search' | translate }}</p>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #filters>
    <app-reviews-header
        class="mb-3"
        searchButtonId="tracking_answer_review_modal_search_button"
        sortButtonId="tracking_answer_review_modal_sort_button"
        filtersButtonId="tracking_answer_review_modal_filters_button"
        synchronizeButtonId="tracking_answer_review_modal_synchronize_button"
        [ngClass]="{ 'mx-2': isReviewRevampFeatureEnabled() }"
        [isAggregatedView]="data().isAggregatedView"
        [displayAutomaticReplyFilter]="false"
        [displayRestaurantsFilter]="false">
    </app-reviews-header>
</ng-template>

<ng-template #rightSide>
    @if (selectedReview$ | async; as selectedReview) {
        <app-answer-review
            class="flex flex-col md:overflow-y-auto min-md:!w-[60%]"
            [fromNotificationId]="data().fromNotificationId"
            [selectedReview]="selectedReview"
            [isAggregatedView]="data().isAggregatedView"
            (confirmClose)="confirmClose()"
            (displayCloseModal)="setDisplayCloseModal($event)"
            (goNext)="goNext($event)"></app-answer-review>
    } @else {
        <app-skeleton skeletonClass="secondary-bg h-[70vh] w-full p-4">
            <app-skeleton skeletonClass="h-[70px] w-full mt-4" [count]="5"></app-skeleton>
        </app-skeleton>
    }
</ng-template>

<ng-template
    let-reviewData="reviewData"
    let-withArchiveButton="withArchiveButton"
    let-isSelected="isSelected"
    let-isSelectable="isSelectable"
    let-hasBorder="hasBorder"
    let-id="id"
    let-disabled="disabled"
    let-parentListIndex="parentListIndex"
    #reviewTemplate>
    <app-basic-preview
        parentContainerClasses="tracking_answer_review_modal_select_review_container"
        [id]="id"
        [review]="reviewData"
        [withArchiveButton]="withArchiveButton"
        [withSeeMoreButton]="false"
        [isSelected]="isSelected"
        [isSelectable]="isSelectable"
        [hasBorder]="hasBorder"
        [displayFullText]="isReviewRevampFeatureEnabled() && isSelected"
        [disabled]="disabled"
        [shouldDisplaySemanticAnalysis]="isReviewRevampFeatureEnabled() && isSelected && reviewData.hasText()"
        [isAggregatedView]="data().isAggregatedView"
        [restaurant]="(getRestaurantFromReview | applyPure: reviewData) ?? null"
        [parentListIndex]="parentListIndex"
        [canShowTranslation]="isReviewRevampFeatureEnabled() && isSelected"
        (archived)="onArchivedReview($event)"
        (select)="isSelectable && !disabled ? onSelectReview($event) : null"></app-basic-preview>
</ng-template>
