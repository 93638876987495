import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

export enum WheelOfFortuneMessage {
    CLIENT_ALREADY_PLAYED = 'CLIENT_ALREADY_PLAYED',
    GIFT_DRAW_ALREADY_CLAIMED = 'GIFT_DRAW_ALREADY_CLAIMED',
    GIFT_DRAW_EXPIRED = 'GIFT_DRAW_EXPIRED',
    GIFT_DRAW_NOT_CLAIMABLE_YET = 'GIFT_DRAW_NOT_CLAIMABLE_YET',
    GIFT_DRAW_NOT_SET = 'GIFT_DRAW_NOT_SET',
    WHEEL_OF_FORTUNE_PROGRAMMED = 'WHEEL_OF_FORTUNE_PROGRAMMED',
    WHEEL_OF_FORTUNE_EXPIRED = 'WHEEL_OF_FORTUNE_EXPIRED',
    THANKS_FOR_PLAYING = 'THANKS_FOR_PLAYING',
    BOOSTER_PACK_DEACTIVATED = 'BOOSTER_PACK_DEACTIVATED',
}

@Component({
    selector: 'app-wheel-of-fortune-messages',
    standalone: true,
    imports: [NgClass, NgStyle, NgTemplateOutlet, TranslateModule, ImagePathResolverPipe, IncludesPipe],
    templateUrl: './wheel-of-fortune-messages.component.html',
    styleUrls: ['./wheel-of-fortune-messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneMessagesComponent {
    readonly WheelOfFortuneMessage = WheelOfFortuneMessage;

    readonly wheelOfFortuneMessage: WritableSignal<WheelOfFortuneMessage | null> = signal(null);
    readonly primaryColor: WritableSignal<string | null> = signal(null);
    readonly formattedDate: WritableSignal<string | null> = signal(null);

    readonly date: WritableSignal<Date | null> = signal(null);
    readonly prettyDate = computed(() => {
        const date = this.date();
        const locale = LocalStorage.getLang();
        return date ? DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.DATETIME_SHORT) : '';
    });

    readonly countdown = computed(() => {
        const date = this.date();
        if (!date) {
            return { days: '0', hours: '00', minutes: '00' };
        }
        const diffNow = DateTime.fromJSDate(date).diffNow(['days', 'hours', 'minutes']).toObject();
        return {
            days: diffNow.days?.toString(),
            hours: diffNow.hours?.toString().padStart(2, '0'),
            minutes: diffNow.minutes ? Math.ceil(diffNow.minutes).toString().padStart(2, '0') : '00',
        };
    });

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    constructor(
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router
    ) {
        const message = this._activatedRoute.snapshot.queryParams.message as WheelOfFortuneMessage;
        const color = this._activatedRoute.snapshot.queryParams.color as string | null;
        const date = this._activatedRoute.snapshot.queryParams.date as string | null;

        const areMessageAndColorValid = Object.values(WheelOfFortuneMessage).includes(message) && !!color;
        const isDateSetIfGiftDrawNotClaimableYet =
            ![WheelOfFortuneMessage.GIFT_DRAW_NOT_CLAIMABLE_YET, WheelOfFortuneMessage.WHEEL_OF_FORTUNE_PROGRAMMED].includes(message) ||
            !!date;

        if (!areMessageAndColorValid || !isDateSetIfGiftDrawNotClaimableYet) {
            this._router.navigate(['./wheel-of-fortune-not-found']);
        }

        this.wheelOfFortuneMessage.set(message);
        this.primaryColor.set(color);
        this.formattedDate.set(date);
        this.date.set(date ? new Date(date) : null);
    }
}
