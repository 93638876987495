<app-reviews-kpis-card
    [title]="'statistics.e_reputation.reviews_kpis.average_answer_time' | translate"
    [icon]="SvgIcon.CLOCK"
    [currentValue]="currentAverageAnswerTime() | formatMillisecondsDuration: { onlyValue: true }"
    [currentValueUnit]="currentAverageAnswerTime() | formatMillisecondsDuration: { onlyUnit: true }"
    [currentValueRaw]="currentAverageAnswerTime()"
    [diffValue]="diffAverageAnswerTime()"
    [diffDisplayedValue]="diffAverageAnswerTime() | formatMillisecondsDuration: { onlyValue: true }"
    [numberEvolutionTooltip]="diffAverageAnswerTime() | formatMillisecondsDuration: { onlyValue: false }"
    [reverseArrow]="true"
    [isLoading]="isLoading()"
    [error]="httpError()">
</app-reviews-kpis-card>
