import { Component, Inject, OnInit, signal, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { cloneDeep } from 'lodash';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { PHONE_CODES } from ':core/constants';
import { ToastService } from ':core/services/toast.service';
import { ClientsService } from ':modules/clients/clients.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputGoogleMapsAutocompleteComponent } from ':shared/components/input-google-maps-autocomplete/input-google-maps-autocomplete.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { removeNullOrEmptyField } from ':shared/helpers';
import { isValidEmail } from ':shared/helpers/email.validator';
import { MatGoogleMapsAutocompleteEvent } from ':shared/interfaces/mat-google-maps-autocomplete-event.interface';
import { Address, Phone } from ':shared/models';
import { CivilityType, ClientsSource, ClientWithStats, ContactMode } from ':shared/models/client';
import { PhoneCode } from ':shared/models/phone-code';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-manual-client-import-modal',
    templateUrl: './manual-client-import-modal.component.html',
    styleUrls: ['./manual-client-import-modal.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        TranslateModule,
        ButtonComponent,
        InputTextComponent,
        InputGoogleMapsAutocompleteComponent,
        InputDatePickerComponent,
        SelectComponent,
    ],
})
export class ManualClientImportModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild('addressInput') addressInput; // accessing the reference element

    readonly CIVILITY_TYPES = [
        { key: CivilityType.MALE, translate: this._translate.instant('clients.add_manual.civility_types.male') },
        { key: CivilityType.FEMALE, translate: this._translate.instant('clients.add_manual.civility_types.female') },
        { key: CivilityType.OTHER, translate: this._translate.instant('clients.add_manual.civility_types.other') },
    ];
    readonly LANGS = [
        { key: ApplicationLanguage.FR, translate: this._translate.instant('clients.add_manual.langs.french') },
        { key: ApplicationLanguage.EN, translate: this._translate.instant('clients.add_manual.langs.english') },
    ];
    PHONE_CODES = [...PHONE_CODES];

    clientForm: UntypedFormGroup;
    clientDuplicatedKey: string;

    addressControl = new FormControl('');

    upsertClient: (client: ClientWithStats) => void;

    readonly isUpsertingClient = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<ManualClientImportModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurantId: string;
            client: ClientWithStats;
            upsertClient: (client: ClientWithStats) => void;
        },
        private readonly _fb: UntypedFormBuilder,
        private readonly _translate: TranslateService,
        private readonly _clientsService: ClientsService,
        private readonly _toastService: ToastService
    ) {
        this._initFormGroup();
        this.upsertClient = this.data.upsertClient;
    }

    get address(): Address {
        return this.clientForm.get('address')?.value;
    }

    ngOnInit(): void {
        this._initAddressControl(this.data.client);
        this._fillFormWithClient(this.data.client);
    }

    cancel(): void {
        this._dialogRef.close(false);
    }

    submit(addOther = true): void {
        this.isUpsertingClient.set(true);
        this.clientForm.disable();

        const cleanData = removeNullOrEmptyField(cloneDeep(this.clientForm.value));
        if (!cleanData.phone?.digits) {
            delete cleanData.phone;
        }
        if (cleanData.phone?.prefix) {
            cleanData.phone.prefix = cleanData.phone.prefix.code;
        }
        if (cleanData.email) {
            cleanData.email = cleanData.email.trim().toLowerCase();
        }
        const acceptedContacts: ContactMode[] = [];
        if (cleanData.acceptsEmails) {
            acceptedContacts.push(ContactMode.EMAIL);
        }
        if (cleanData.acceptsSms) {
            acceptedContacts.push(ContactMode.SMS);
        }
        delete cleanData.acceptsEmails;
        delete cleanData.acceptsSms;
        Object.assign(cleanData, {
            restaurantId: this.data.restaurantId,
            accepts: acceptedContacts,
            source: ClientsSource.MANUAL,
        });

        if (cleanData.civility?.key) {
            cleanData.civility = cleanData.civility.key;
        }

        if (cleanData.language?.key) {
            cleanData.language = cleanData.language.key;
        }

        const createOrUpdateClient$ = this.data.client
            ? this._clientsService.update(this.data.client._id, cleanData)
            : this._clientsService.create(cleanData);

        createOrUpdateClient$.subscribe({
            next: ({ data: client }) => {
                if (this.data.client) {
                    this._toastService.openSuccessToast(this._translate.instant('clients.add_manual.client_updated'));
                } else {
                    this._toastService.openSuccessToast(this._translate.instant('clients.add_manual.client_added'));
                }

                const newClient = new ClientWithStats({ ...this.data.client, ...client });
                this.upsertClient(newClient);
                if (!this.data.client && addOther) {
                    this._resetForm();
                } else {
                    this._dialogRef.close(false);
                }
                this.isUpsertingClient.set(false);
                this.clientForm.enable();
            },
            error: (err) => {
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._checkErrors(err);
                this.isUpsertingClient.set(false);
                this.clientForm.enable();
            },
        });
    }

    displayWithPhone(value: PhoneCode): string {
        return value.text;
    }

    displayWithSelectInput(value: { key: string; translate: string }): string {
        return value.translate;
    }

    selectedAddress($event: MatGoogleMapsAutocompleteEvent): void {
        // map return google places object to our address format
        try {
            this.clientForm.get(['address'])?.markAsDirty();
            const { address_components } = $event;
            const streetNumber = address_components.find((el) => el.types.includes('street_number'))?.long_name || '';
            const route = address_components.find((el) => el.types.includes('route'))?.long_name || '';
            const locality =
                address_components.find((el) => el.types.includes('locality') || el.types.includes('postal_town'))?.long_name || '';
            const regionCode = address_components.find((el) => el.types.includes('country'))?.short_name || '';
            const country = address_components.find((el) => el.types.includes('country'))?.long_name || '';
            const postalCode =
                regionCode === 'GB'
                    ? this._getBritishPostalCode()
                    : address_components.find((el) => el.types.includes('postal_code'))?.long_name || '';
            const province =
                regionCode === 'US' ? address_components.find((el) => el.types.includes('administrative_area_level_1'))?.long_name : '';
            const formattedAddress = ((streetNumber || '') + ' ' + (route || '')).trim();
            this.clientForm.patchValue({
                address: new Address({
                    streetNumber,
                    route,
                    locality,
                    administrativeArea: province && province,
                    regionCode,
                    country,
                    postalCode,
                    formattedAddress,
                }),
            });
        } catch (err) {}
    }

    private _initFormGroup(): void {
        this.clientForm = this._fb.group({
            civility: [null, this._validSelectField()],
            firstName: ['', this._customRequiredValidation()],
            lastName: ['', this._customRequiredValidation()],
            email: ['', this._customEmailValidation()],
            phone: this._fb.group({
                digits: [null],
                prefix: [
                    PHONE_CODES.find(
                        (phoneCode) =>
                            this._translate.currentLang.toLowerCase().includes(phoneCode.countryCode.toLowerCase()) ?? PHONE_CODES[0]
                    ),
                ],
            }),
            lastVisitedAt: [new Date(), [this._customRequiredValidation()]],
            address: this._fb.group({
                route: [null],
                streetNumber: [null],
                locality: [null],
                postalCode: [null],
                country: [null],
                province: [null],
                formattedAddress: [null],
            }),
            acceptsEmails: [true],
            acceptsSms: [true],
            language: ['', this._validSelectField()],
            visitCount: [''],
        });
        if (this.addressInput?.nativeElement?.value) {
            this.addressInput.nativeElement.value = null;
        }
        this.clientForm.setValidators([this._validPhoneNumberValidator(), this._atLeastOneRequiredValidator()]);
    }

    private _fillFormWithClient(client: ClientWithStats): void {
        if (!client) {
            return;
        }
        this.clientForm.get('firstName')?.patchValue(client.firstName);
        this.clientForm.get('lastName')?.patchValue(client.lastName);
        this.clientForm.get('email')?.patchValue(client.email);
        this.clientForm.get('lastVisitedAt')?.patchValue(client.lastVisitedAt);

        this.clientForm.get('acceptsEmails')?.patchValue(client.accepts.includes(ContactMode.EMAIL));
        this.clientForm.get('acceptsSms')?.patchValue(client.accepts.includes(ContactMode.SMS));

        if (client.phone) {
            this.clientForm.get('phone')?.patchValue({
                prefix: PHONE_CODES.find((phoneCode) => phoneCode.code === client.phone.prefix),
                digits: client.phone.digits,
            });
        }
        if (client.civility) {
            this.clientForm.get('civility')?.patchValue(this.CIVILITY_TYPES.find((civility) => civility.key === client.civility));
        }
        if (client.address) {
            this.clientForm.get('address')?.patchValue(client.address);
        }
        if (client.language) {
            this.clientForm.get('language')?.patchValue(this.LANGS.find((lang) => lang.key === client.language));
        }
        if (client.visitCount) {
            this.clientForm.get('visitCount')?.patchValue(client.visitCount);
        }
    }

    private _validSelectField(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control.value) {
                return { error: this._translate.instant('common.invalid_field') };
            }
            return null;
        };
    }

    private _customEmailValidation(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!isValidEmail(control)) {
                return { error: this._translate.instant('common.invalid_email') };
            }
            return null;
        };
    }

    private _customRequiredValidation(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (Validators.required(control)) {
                return { error: this._translate.instant('common.field_required') };
            }
            return null;
        };
    }

    private _atLeastOneRequiredValidator(): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors | null => {
            const email = group.get('email');
            const prefix = group.get(['phone', 'prefix']);
            const digits = group.get(['phone', 'digits']);
            if (!email?.value && (!prefix?.value || !digits?.value)) {
                return { ref_email_phone: this._translate.instant('clients.add_manual.email_or_phone') };
            }
            return null;
        };
    }

    private _validPhoneNumberValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const prefix = group.get(['phone', 'prefix']);
            const digits = group.get(['phone', 'digits']);
            const phoneNumber = parsePhoneNumberFromString('+' + String(prefix?.value?.code) + String(digits?.value));
            if (!digits?.value || digits.value === '') {
                digits?.setErrors(null);
            } else if (!String(digits.value).match(/^\d{0,20}$/g)) {
                digits.setErrors({
                    error: this._translate.instant('information.information.invalid_phone_number_digit'),
                });
            } else if (!phoneNumber || !phoneNumber.isValid()) {
                digits.setErrors({ error: this._translate.instant('information.information.invalid_phone_number') });
            } else {
                digits.setErrors(null);
            }
            return null;
        };
    }

    private _initAddressControl(client: ClientWithStats): void {
        let displayedAddress = client?.address?.formattedAddress || '';
        if (displayedAddress && client.address.locality) {
            displayedAddress = displayedAddress + ', ' + client.address.locality;
        }
        if (displayedAddress && client.address.country) {
            displayedAddress = displayedAddress + ', ' + client.address.country;
        }
        this.addressControl.patchValue(displayedAddress);
    }

    private _checkErrors(err): void {
        if (err.error?.duplicateRecordError) {
            const duplicatedKey = err.error.duplicatedKey;
            const errorField =
                duplicatedKey === 'phone'
                    ? new Phone(this.clientForm.get(duplicatedKey)?.value).toString()
                    : this.clientForm.get(duplicatedKey)?.value;

            this._toastService.openErrorToast(this._translate.instant('clients.add_manual.client_already_exist') + ' : ' + errorField);
        } else if (err?.error?.message?.match(/Invalid email address/)) {
            this._toastService.openErrorToast(this._translate.instant('clients.status_not_ok'));
        } else {
            this._toastService.openErrorToast(this._translate.instant('clients.unknown_error'));
        }
    }

    private _getBritishPostalCode(): string {
        const splitAddress = this.addressInput.nativeElement.value.split(',')[1].split(' ');
        return [splitAddress.at(-2), splitAddress.at(-1)].join(' ');
    }

    private _resetFormValidation(): void {
        const phoneFormGroup = this.clientForm.get('phone') as UntypedFormGroup;
        this.clientForm.clearValidators();
        Object.keys(this.clientForm.controls).forEach((key) => this.clientForm.get(key)?.clearValidators());
        Object.keys(phoneFormGroup.controls).forEach((key) => phoneFormGroup.get(key)?.clearValidators());
    }

    private _resetForm(): void {
        this._resetFormValidation();
        this.clientForm.reset();
        this.addressControl.reset();
        this._initFormGroup();
    }
}
