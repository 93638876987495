import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { KeywordSearchImpressionsInsightsDto, TopKeywordSearchImpressionsDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';

@Injectable({
    providedIn: 'root',
})
export class KeywordSearchImpressionsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/keyword-search-impressions`;

    constructor(private readonly _http: HttpClient) {}

    getKeywordSearchImpressionsInsights({
        restaurantId,
        startDate,
        endDate,
        comparisonPeriod,
    }: {
        restaurantId: string;
        startDate: string;
        endDate: string;
        comparisonPeriod?: string;
    }): Observable<KeywordSearchImpressionsInsightsDto[]> {
        const cleanParams = removeNullOrUndefinedField(objectToSnakeCase({ startDate, endDate, comparisonPeriod }));
        return this._http
            .get<
                ApiResultV2<KeywordSearchImpressionsInsightsDto[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/insights`, { params: cleanParams })
            .pipe(map((res) => res.data));
    }

    getTopKeywordSearchImpressions({
        restaurantId,
        startDate,
        endDate,
        limit,
    }: {
        restaurantId: string;
        startDate: string;
        endDate: string;
        limit: number;
    }): Observable<TopKeywordSearchImpressionsDto> {
        const cleanParams = removeNullOrUndefinedField(objectToSnakeCase({ startDate, endDate, limit }));
        return this._http
            .get<
                ApiResultV2<TopKeywordSearchImpressionsDto>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/top`, { params: cleanParams })
            .pipe(map((res) => res.data));
    }
}
