import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import { ReportType, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ReportsService } from ':core/services/report.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { Restaurant } from ':shared/models';
import { Report } from ':shared/models/report';

import { selectUserInfos } from '../store/user.selectors';
import { PerformanceReportsSettingsComponent } from './performance-reports-settings/performance-reports-settings.component';
import { ReviewsReportsSettingsComponent } from './reviews-reports-settings/reviews-reports-settings.component';

@Component({
    selector: 'app-reports-settings',
    standalone: true,
    imports: [
        TranslateModule,
        ReviewsReportsSettingsComponent,
        PerformanceReportsSettingsComponent,
        AsyncPipe,
        NgTemplateOutlet,
        MalouSpinnerComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './reports-settings.component.html',
    styleUrls: ['./reports-settings.component.scss'],
})
export class ReportsSettingsComponent implements OnInit {
    readonly activeRestaurants = signal<Restaurant[]>([]);
    readonly activeRestaurantsWithoutBrandBusinesses = computed(() =>
        this.activeRestaurants().filter((restaurant) => !restaurant.isBrandBusiness())
    );

    readonly candidates = signal<string[]>([]);

    readonly reports = signal<Report[]>([]);
    readonly isLoading = signal(true);

    readonly dailyReviewsReport = signal<Report | null>(null);
    readonly weeklyReviewsReport = signal<Report | null>(null);
    readonly weeklyPerformanceReport = signal<Report | null>(null);
    readonly monthlyPerformanceReport = signal<Report | null>(null);

    readonly currentUser$ = this._store.select(selectUserInfos);

    constructor(
        private readonly _store: Store,
        private readonly _usersService: UsersService,
        private readonly _reportsService: ReportsService
    ) {}

    ngOnInit(): void {
        this._setReports();
    }

    private _setReports(): void {
        this._getRestaurants$()
            .pipe(withLatestFrom(this._getCandidates$(), this._getReports$()))
            .subscribe({
                next: ([restaurants, candidates, reports]) => {
                    this.reports.set(reports);
                    this.dailyReviewsReport.set(reports.find((report) => report.type === ReportType.DAILY_REVIEWS) ?? null);
                    this.weeklyReviewsReport.set(reports.find((report) => report.type === ReportType.WEEKLY_REVIEWS) ?? null);
                    this.weeklyPerformanceReport.set(reports.find((report) => report.type === ReportType.WEEKLY_PERFORMANCE) ?? null);
                    this.monthlyPerformanceReport.set(reports.find((report) => report.type === ReportType.MONTHLY_PERFORMANCE) ?? null);
                    this.candidates.set(candidates ?? []);
                    this.activeRestaurants.set(restaurants ?? []);
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }

    private _getReports$(): Observable<Report[]> {
        return this.currentUser$.pipe(
            switchMap((user) => this._reportsService.getReportsForUser(user?.id ?? '')),
            map((res) => res.data ?? [])
        );
    }

    private _getCandidates$(): Observable<string[]> {
        const MAX_FETCHED_ORGANIZATION_COUNT = 20;
        return this.currentUser$.pipe(
            filter((currentUser) => !!currentUser),
            switchMap((currentUser: User): Observable<Partial<User>[][]> => {
                if (
                    currentUser?.organizationIds &&
                    currentUser.organizationIds.length > 0 &&
                    currentUser.organizationIds.length < MAX_FETCHED_ORGANIZATION_COUNT
                ) {
                    return forkJoin(
                        currentUser?.organizationIds.map((organizationId) => this._usersService.getOrganizationUsers(organizationId)) ?? []
                    );
                }
                return of([[currentUser]]);
            }),
            map((users) => {
                const flattenedUsers = users.flat();
                const uniqUsers = uniqBy(flattenedUsers, '_id');
                const candidatesEmails = uniqUsers.map((user) => user.email);
                return candidatesEmails.filter(Boolean) as string[];
            })
        );
    }

    private _getRestaurants$(): Observable<Restaurant[]> {
        return this._store
            .select(selectOwnRestaurants)
            .pipe(
                map(
                    (restaurants) =>
                        restaurants?.filter((restaurant) => restaurant.active).sort(sortRestaurantsByInternalNameThenName) ?? []
                )
            );
    }
}
