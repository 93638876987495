import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, input, viewChild } from '@angular/core';

import { ImageEditorUtils } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/upload-and-edit-medias/components/edit-media-modal/components/image-editor/image-editor-utils';

interface Data {
    url: string;
    dimensions?: {
        width: number;
        height: number;
    };
    transformData?: {
        rotationInDegrees: number;
        left: number;
        top: number;
        width: number;
        height: number;
    };
}

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    standalone: true,
    imports: [NgOptimizedImage, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerComponent {
    readonly data = input.required<Data>();
    readonly shouldLazyLoadMedia = input(true);

    readonly hasOptionalData = computed(() => !!this.data().dimensions && !!this.data().transformData);

    readonly containerElement = viewChild.required<ElementRef<HTMLDivElement>>('containerElement');
    readonly imgElement = viewChild.required<ElementRef<HTMLDivElement>>('imgElement');

    constructor() {
        effect(() => {
            const data = this.data();
            if (!data.dimensions || !data.transformData) {
                return;
            }
            const containerElement = this.containerElement();
            const imgElement = this.imgElement();
            this._computeStyles(data.dimensions, data.transformData, containerElement.nativeElement, imgElement.nativeElement);
        });
    }

    private _computeStyles(
        dimensions: Required<Data>['dimensions'],
        transformData: Required<Data>['transformData'],
        containerElement: HTMLDivElement,
        imgElement: HTMLDivElement
    ): void {
        const containerSize = { width: containerElement.clientWidth, height: containerElement.clientHeight };

        const is90DegreesRotated = transformData.rotationInDegrees % 180 === 90;
        const realImageWidth = is90DegreesRotated ? dimensions.height : dimensions.width;
        const realImageHeight = is90DegreesRotated ? dimensions.width : dimensions.height;
        const realImageSize = { width: realImageWidth, height: realImageHeight };

        const transformArea = ImageEditorUtils.scaleTransformArea(transformData, realImageSize);

        const zoomRatio = ImageEditorUtils.getZoomRatioToCover(transformArea, containerSize);

        const transformAreaZoomed = ImageEditorUtils.scaleTransformArea(transformArea, { width: zoomRatio, height: zoomRatio });

        const xTranslate = transformAreaZoomed.left + (transformAreaZoomed.width - containerSize.width) / 2;
        const yTranslate = transformAreaZoomed.top + (transformAreaZoomed.height - containerSize.height) / 2;

        imgElement.style.width = `${realImageSize.width}px`;
        imgElement.style.height = `${realImageSize.height}px`;

        // For the rotation, we need to keep transformOrigin = center (default).
        // But the scale will then move the image in a way that it's no longer aligned with the container top left border,
        // so we re-adjust the image position to be aligned with the container top left border
        // It's just about trade-off :
        // - with transformOrigin = center: the rotation does not move the image, but the scale does
        // - with transformOrigin = top left: the rotation move the image, but the scale does not
        // It's just easier to cancel the position shift of the scale compared to the rotation
        const adjustmentPercentage = (zoomRatio - 1) * 50;

        const translateXTransform = `translateX(calc(${adjustmentPercentage}% - ${xTranslate}px))`;
        const translateYTransform = `translateY(calc(${adjustmentPercentage}% - ${yTranslate}px))`;
        const scaleTransform = `scale(${zoomRatio})`;
        const rotateTransform = `rotate(${transformData.rotationInDegrees ?? 0}deg)`;
        imgElement.style.transform = `${translateXTransform} ${translateYTransform} ${scaleTransform} ${rotateTransform}`;
    }
}
