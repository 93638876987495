import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, firstValueFrom, map, tap } from 'rxjs';

import { AiSocialPostDuplicationCaptionResponseDto } from '@malou-io/package-dto';
import { generateDbId, HashtagType, PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { OriginalPostPreviewComponent } from ':shared/components/duplicate-post-preview-modal/components/original-post-preview/original-post-preview.component';
import { SchedulePostFormComponent } from ':shared/components/duplicate-post-preview-modal/components/schedule-post-form/schedule-post-form.component';
import { SocialPostCaptionPreviewCardComponent } from ':shared/components/duplicate-post-preview-modal/duplicate-social-post-with-text-generation-preview-modal/social-post-caption-preview-card/social-post-caption-preview-card.component';
import { buildPlus15RoundedMinutesDate } from ':shared/components/duplicate-post-preview-modal/helpers/plus-15-minutes-date';
import { onlyFutureDate } from ':shared/components/duplicate-post-preview-modal/validators/publication-date-in-the-future';
import { KeepSamePostCaptionToggleComponent } from ':shared/components/keep-same-post-caption-toggle/keep-same-post-caption-toggle.component';
import { MultipleStepsLoaderComponent } from ':shared/components/multiple-steps-loader/multiple-steps-loader.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { FbLocation, Hashtag, PostHashtags, PostWithJob, Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

interface InputData {
    selectedRestaurants: Restaurant[];
}

interface SharedData {
    postToDuplicate: PostWithJob;
}

const BaseStepDuplicatePostPreviewComponent = BaseStepComponent<InputData, SharedData>;

export interface DuplicateSocialPostPreviewModalSubmitData {
    restaurant: Restaurant;
    status: PostPublicationStatus;
    plannedPublicationDate: Date;
    hashtags: PostHashtags;
    text: string;
    location: FbLocation | null;
    platformKeys: PlatformKey[];
}

@Component({
    selector: 'app-duplicate-social-post-with-text-generation-preview-modal',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatDividerModule,
        MatIconModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatFormFieldModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        LazyLoadImageModule,
        IllustrationPathResolverPipe,
        SchedulePostFormComponent,
        OriginalPostPreviewComponent,
        SocialPostCaptionPreviewCardComponent,
        MultipleStepsLoaderComponent,
        KeepSamePostCaptionToggleComponent,
    ],
    templateUrl: './duplicate-social-post-with-text-generation-preview-modal.component.html',
    styleUrl: './duplicate-social-post-with-text-generation-preview-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateSocialPostWithTextGenerationPreviewModalComponent extends BaseStepDuplicatePostPreviewComponent implements OnInit {
    readonly Illustration = Illustration;

    readonly customizedDatePostForm = new FormGroup(
        {
            status: new FormControl<PostDateStatus>(PostDateStatus.LATER),
            plannedPublicationDate: new FormControl<Date>(new Date()),
            postTime: new FormControl(''),
        },
        {
            validators: [onlyFutureDate()],
        }
    );
    readonly willPostAllAtSameTime = signal(true);

    readonly restaurant = inject(RestaurantsService).restaurantSelected$.value;
    readonly _postsService = inject(PostsService);
    readonly _translateService = inject(TranslateService);

    readonly isCaptionsGenerationError = signal(false);
    readonly isLoading = signal(false);
    readonly isLoaderMinDurationReached = signal(false);
    readonly _LOADER_MIN_DURATION = 500;
    readonly loaderSteps = [
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_1'),
        this._translateService.instant('posts.duplicate_social_post_modal.loader_steps.step_2'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_3'),
    ];

    previewCaptionPostForms = new FormArray<
        FormGroup<{
            plannedPublicationDate: FormControl<Date>;
            postTime: FormControl<string>;
            postCaption: FormControl<string>;
            postCaptionTriggeredByKeepSameCaptionToggle: FormControl<string>;
            keys: FormControl<PlatformKey[]>;
            hashtagTexts: FormControl<string[]>;
            // wont be visible in the UI
            restaurant: FormControl<Restaurant>;
            location: FormControl<FbLocation | null>;
            fbPlatformName: FormControl<string | null>;
            hasSocialPlatformsConnected: FormControl<boolean>;
        }>
    >([]);

    readonly isStepValid = toSignal(
        combineLatest([this.customizedDatePostForm.valueChanges, this.previewCaptionPostForms.valueChanges]).pipe(
            map(() => this.customizedDatePostForm.valid && this.previewCaptionPostForms.valid && !this.isLoading()),
            tap(() => {
                this.valid.emit(this.customizedDatePostForm.valid && this.previewCaptionPostForms.valid && !this.isLoading());
            })
        ),
        { initialValue: false }
    );

    readonly shouldKeepSameCaptionForAllPosts = signal(false);

    constructor() {
        super();
        effect(() => {
            const shouldKeepSameCaptionForAllPosts = this.shouldKeepSameCaptionForAllPosts();
            this.previewCaptionPostForms.controls.forEach((form) => {
                if (shouldKeepSameCaptionForAllPosts) {
                    form.get('postCaption')!.disable();
                    form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.enable();
                } else {
                    form.get('postCaption')!.enable();
                    form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.disable();
                }
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._initializePostForm();
        this._startSocialPostDuplication();
    }

    protected _submitData(): DuplicateSocialPostPreviewModalSubmitData[] {
        return this.previewCaptionPostForms.controls
            .filter((form) => form.get('hasSocialPlatformsConnected')?.value)
            .map((form) => {
                const { status, plannedPublicationDate } = this._buildPostStatusAndDate(form);

                return {
                    restaurant: form.get('restaurant')!.value,
                    status,
                    plannedPublicationDate,
                    text: this.shouldKeepSameCaptionForAllPosts()
                        ? form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.value
                        : form.get('postCaption')!.value,
                    hashtags: {
                        selected: form.get('hashtagTexts')!.value.map(
                            (text) =>
                                new Hashtag({
                                    text,
                                    type: HashtagType.RESTAURANT,
                                    createdAt: new Date(),
                                    id: generateDbId().toHexString(),
                                    isCustomerInput: false,
                                    isMain: false,
                                    updatedAt: new Date(),
                                })
                        ),
                        suggested: [],
                    },
                    location: form.get('location')!.value,
                    platformKeys: form.get('keys')!.value,
                };
            });
    }

    private _buildPostStatusAndDate(
        form: FormGroup<{
            plannedPublicationDate: FormControl<Date>;
            postTime: FormControl<string>;
            postCaption: FormControl<string>;
            postCaptionTriggeredByKeepSameCaptionToggle: FormControl<string>;
            keys: FormControl<PlatformKey[]>;
            hashtagTexts: FormControl<string[]>;
            // wont be visible in the UI
            restaurant: FormControl<Restaurant>;
            location: FormControl<FbLocation | null>;
            fbPlatformName: FormControl<string | null>;
            hasSocialPlatformsConnected: FormControl<boolean>;
        }>
    ): { status: PostPublicationStatus; plannedPublicationDate: Date } {
        let plannedPublicationDate: Date;
        let status: PostPublicationStatus;

        if (!this.willPostAllAtSameTime()) {
            plannedPublicationDate = this._buildDate({
                plannedPublicationDate: form.get('plannedPublicationDate')!.value,
                postTime: form.get('postTime')!.value,
            });
            status = PostPublicationStatus.PENDING;
        } else {
            const statusForm = this.customizedDatePostForm.get('status')!.value;
            if (statusForm === PostDateStatus.NOW) {
                plannedPublicationDate = new Date();
                status = PostPublicationStatus.PENDING;
            } else {
                plannedPublicationDate = this._buildDate({
                    plannedPublicationDate: this.customizedDatePostForm.get('plannedPublicationDate')!.value!,
                    postTime: this.customizedDatePostForm.get('postTime')!.value!,
                });
                status = statusForm === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;
            }
        }
        return { status, plannedPublicationDate };
    }

    protected _isValid(): boolean {
        return this.isStepValid();
    }

    private _buildDate({ plannedPublicationDate, postTime }: { plannedPublicationDate: Date; postTime: string }): Date {
        const date = new Date(plannedPublicationDate);
        const [hours, minutes] = postTime.split(':').map((n) => parseInt(n, 10));
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }

    private async _startSocialPostDuplication(): Promise<void> {
        this.isLoading.set(true);
        setTimeout(() => {
            this.isLoaderMinDurationReached.set(true);
        }, this._LOADER_MIN_DURATION);
        const postId = this.sharedData.postToDuplicate._id;
        const restaurantIds = this.inputData.selectedRestaurants.map((restaurant) => restaurant._id.toString());

        try {
            const captions = await firstValueFrom(
                this._postsService.duplicateSocialPostTextsForRestaurants({ postIdToDuplicate: postId, restaurantIds })
            );
            this.isLoading.set(false);
            this._buildPreviewCaptionForms(captions);
        } catch (error) {
            this.isCaptionsGenerationError.set(true);
            this.isLoading.set(false);
        }
    }

    private _buildPreviewCaptionForms(captions: AiSocialPostDuplicationCaptionResponseDto): void {
        const restaurantCaptions = captions.map((caption) => {
            const restaurant = this.inputData.selectedRestaurants.find((rest) => rest._id === caption.restaurantId);
            return {
                restaurant,
                ...caption,
            };
        });
        for (const caption of restaurantCaptions) {
            // carousel with video is not supported on facebook
            const keys = this.sharedData.postToDuplicate.containsVideo()
                ? caption.keys.filter((key) => key !== PlatformKey.FACEBOOK)
                : caption.keys;
            const hasSocialPlatformsConnected = keys.length > 0;
            const form = new FormGroup(
                {
                    plannedPublicationDate: new FormControl<Date>(this.customizedDatePostForm.get('plannedPublicationDate')!.value!, {
                        nonNullable: true,
                    }),
                    postTime: new FormControl(this.customizedDatePostForm.get('postTime')!.value!, { nonNullable: true }),
                    postCaption: new FormControl(hasSocialPlatformsConnected ? caption.postCaption : '', {
                        nonNullable: true,
                        validators: hasSocialPlatformsConnected ? [Validators.required] : [],
                    }),
                    postCaptionTriggeredByKeepSameCaptionToggle: new FormControl(
                        hasSocialPlatformsConnected ? this.sharedData.postToDuplicate.text : '',
                        {
                            nonNullable: true,
                            validators: hasSocialPlatformsConnected ? [Validators.required] : [],
                        }
                    ),
                    keys: new FormControl<PlatformKey[]>(keys, { nonNullable: true }),
                    hashtagTexts: new FormControl<string[]>(hasSocialPlatformsConnected ? (caption.hashtags ?? []) : [], {
                        nonNullable: true,
                    }),
                    // wont be visible in the UI
                    restaurant: new FormControl<Restaurant>(caption.restaurant!, { nonNullable: true }),
                    location: new FormControl<FbLocation | null>(null),
                    fbPlatformName: new FormControl<string | null>(caption.fbPlatformName),
                    hasSocialPlatformsConnected: new FormControl<boolean>(hasSocialPlatformsConnected, { nonNullable: true }),
                },
                {
                    validators: [onlyFutureDate()],
                }
            );
            this.previewCaptionPostForms.push(form);
            if (!hasSocialPlatformsConnected) {
                form.disable();
            }
            form.get('postCaptionTriggeredByKeepSameCaptionToggle')?.disable();
        }
    }

    private _initializePostForm(): void {
        const postDate = this.sharedData.postToDuplicate.plannedPublicationDate;
        const status =
            this.sharedData.postToDuplicate.published === PostPublicationStatus.PENDING ? PostDateStatus.LATER : PostDateStatus.DRAFT;
        if (postDate >= new Date()) {
            const postTime = DateTime.fromJSDate(postDate).toFormat('HH:mm');
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: postDate,
                postTime,
            });
        } else {
            const plannedPublicationDate = buildPlus15RoundedMinutesDate();
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: plannedPublicationDate.toJSDate(),
                postTime: plannedPublicationDate.toFormat('HH:mm'),
            });
        }
    }
}
