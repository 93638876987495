import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-boosters-presentation',
    templateUrl: './boosters-presentation.component.html',
    styleUrls: ['./boosters-presentation.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, ButtonComponent, HtmlTagPipe, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostersPresentationComponent {
    private readonly _wheelsOfFortuneService = inject(WheelsOfFortuneService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly SvgIcon = SvgIcon;

    readonly isRequestSent = signal(false);
    readonly isSendingRequest = signal(false);

    constructor() {
        effect(
            () => {
                const restaurant = this._restaurantsService.selectedRestaurant();
                if (!restaurant) {
                    return;
                }

                const subscriptionRequestSentRestaurantIds = JSON.parse(
                    LocalStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
                );
                this.isRequestSent.set(subscriptionRequestSentRestaurantIds.includes(restaurant.id));
            },
            { allowSignalWrites: true }
        );
    }

    sendRequest(): void {
        const restaurant = this._restaurantsService.selectedRestaurant();
        if (!restaurant) {
            return;
        }

        this.isSendingRequest.set(true);

        this._wheelsOfFortuneService.sendSubscriptionRequest([restaurant]).subscribe(() => {
            this._toastService.openSuccessToast(
                this._translateService.instant('wheel_of_fortune.subscription_request_modal.request_successfully_sent')
            );
            this._updateStorageWithSentRestaurantIds([restaurant]);
            this.isRequestSent.set(true);
            this.isSendingRequest.set(false);
        });
    }

    private _updateStorageWithSentRestaurantIds(restaurants: Restaurant[]): void {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            LocalStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        restaurants.forEach((restaurant) => {
            subscriptionRequestSentRestaurantIds.push(restaurant.id);
        });
        const uniqSubscriptionRequestSentRestaurantIds = Array.from(new Set(subscriptionRequestSentRestaurantIds));
        const uniqSubscriptionRequestSentRestaurantIdsString = JSON.stringify(uniqSubscriptionRequestSentRestaurantIds);
        LocalStorage.setItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS, uniqSubscriptionRequestSentRestaurantIdsString);
    }
}
