import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { PlatformDisconnectedNotification } from ':core/components/notification-center/models/platform-disconnected-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { Restaurant } from ':shared/models';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-platform-disconnected-notification-item',
    templateUrl: './platform-disconnected-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, FormatDatePipe, MatButtonModule, PlatformLogoComponent, PlatformNamePipe],
})
export class PlatformDisconnectedNotificationItemComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);
    readonly notification = computed<PlatformDisconnectedNotification>(
        () => this.initialNotification() as PlatformDisconnectedNotification
    );
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );
    readonly restaurants = computed(() => this.notification()?.getAllRestaurant(this._restaurantService.restaurants()));
    readonly isListVisible = signal(false);

    onNotificationClick(restaurantId: string): void {
        this._notificationsCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_PLATFORM_DISCONNECTED_WEB_REDIRECT,
            notificationId: this.notification()?.id,
            properties: {
                restaurantId: this.notification().data.restaurantIds[0],
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToPlatform(restaurantId);
        this.notificationCenterContext.close();
    }

    toggleList(): void {
        this.isListVisible.set(!this.isListVisible());
    }

    private _navigateToPlatform(restaurantId: string): void {
        this._router.navigate(['restaurants', restaurantId, 'settings', 'platforms', 'connection'], {
            queryParams: {
                reconnectPlatform: this.notification().data.platform.key,
            },
        });
    }
}
