import { AsyncPipe, KeyValuePipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, Output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { startWith } from 'rxjs';

import { PartialRecord, ReviewAnalysisSentiment, ReviewAnalysisTag } from '@malou-io/package-utils';

import { SelectComponent } from ':shared/components/select/select.component';
import { NavBarTab, TabNavBarComponent } from ':shared/components/tab-nav-bar/tab-nav-bar.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    getDaysFromCurrentRange,
    getMonthsFromPeriod,
    getWeeksFromCurrentRange,
    isInDayList,
    isSameDay,
    Month,
    WeekRange,
} from ':shared/helpers';
import { DatesAndPeriod, ReviewWithAnalysis, SegmentWithReview } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { TagEvolutionData, TagsEvolutionChartComponent } from './tags-evolution-chart/tags-evolution-chart.component';

const analysisTagsSorted: ReviewAnalysisTag[] = [
    ReviewAnalysisTag.FOOD,
    ReviewAnalysisTag.SERVICE,
    ReviewAnalysisTag.ATMOSPHERE,
    ReviewAnalysisTag.PRICE,
    ReviewAnalysisTag.EXPEDITIOUSNESS,
    ReviewAnalysisTag.HYGIENE,
];

@Component({
    selector: 'app-tags-evolution',
    templateUrl: './tags-evolution.component.html',
    styleUrls: ['./tags-evolution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        TabNavBarComponent,
        TagsEvolutionChartComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        KeyValuePipe,
        ApplyPurePipe,
        LowerCasePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class TagsEvolutionComponent {
    @Input() shouldDetailTagsEvolutionCharts: boolean;
    @Output() viewByChange: EventEmitter<ViewBy> = new EventEmitter<ViewBy>();
    @Output() hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.WEEK) as FormControl<ViewBy>;

    _reviews: ReviewWithAnalysis[];
    _dates: DatesAndPeriod;
    currentTag: string;
    tabs: NavBarTab[] = [];
    labels: Date[];
    tagEvolutionData: Record<string, TagEvolutionData>;
    noData = true;
    readonly chartViewBy: WritableSignal<ViewBy> = signal(ViewBy.WEEK);

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {}

    get dates(): DatesAndPeriod {
        return this._dates;
    }

    get reviews(): ReviewWithAnalysis[] {
        return this._reviews;
    }

    @Input() set dates(value: DatesAndPeriod) {
        this._dates = value;
        if (this.reviews) {
            this._initTagEvolutionData(this.reviews, this.dates);
        }
    }

    @Input() set reviews(value: ReviewWithAnalysis[]) {
        this._reviews = this._getReviewWithAnalysisWithPositiveAndNegativeSentiments(value);
        if (this.dates) {
            this._initTagEvolutionData(this.reviews, this.dates);
        }
    }

    @Input() set viewBy(value: ViewBy) {
        if (value) {
            this.chartViewBy.set(value);
            this.viewByControl.setValue(value);
        }
    }

    onTabChange(tab: NavBarTab): void {
        this.currentTag = tab.data;
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    getChartTitleByKey = (key: string): string => this._translate.instant(`reviews.review_analyses.${key}`);

    private _getReviewWithAnalysisWithPositiveAndNegativeSentiments(reviews: ReviewWithAnalysis[]): ReviewWithAnalysis[] {
        return reviews.map((review) => ({
            ...review,
            semanticAnalysis: {
                ...review.semanticAnalysis,
                segmentAnalyses: review?.semanticAnalysis?.segmentAnalyses?.filter((segment) =>
                    [ReviewAnalysisSentiment.POSITIVE, ReviewAnalysisSentiment.NEGATIVE].includes(segment.sentiment)
                ),
            },
        })) as ReviewWithAnalysis[];
    }

    private _initTagEvolutionData(reviews: ReviewWithAnalysis[], dates: DatesAndPeriod): void {
        const tagsWithCount: PartialRecord<ReviewAnalysisTag, number> = this._getTagsWithCount(reviews);
        const allTags: string[] = Object.keys(tagsWithCount);
        this.tabs = Object.entries(tagsWithCount)
            .map(([key, value]: [ReviewAnalysisTag, number]) => ({
                title: this._translate.instant(`reviews.review_analyses.${key}`),
                subtitle: `(${value})`,
                data: key,
            }))
            .sort((a, b) => analysisTagsSorted.indexOf(a.data) - analysisTagsSorted.indexOf(b.data));

        if (this.tabs.length === 0) {
            this.noData = true;
            return;
        } else {
            this.noData = false;
        }
        if (!this.currentTag) {
            this.currentTag = this.tabs[0].data;
        }

        this.viewByControl.valueChanges.pipe(startWith(ViewBy.WEEK), takeUntilDestroyed(this._destroyRef)).subscribe((viewBy: ViewBy) => {
            this.chartViewBy.set(viewBy);
            this.viewByChange.emit(viewBy);
            if (!dates.startDate || !dates.endDate) {
                return;
            }
            switch (viewBy) {
                case ViewBy.DAY:
                    const days: Date[] = getDaysFromCurrentRange(dates.startDate, dates.endDate);
                    this.labels = days;
                    this.tagEvolutionData = this._computeDailyData(reviews, days, allTags);
                    break;
                case ViewBy.WEEK:
                    const weeks: WeekRange[] = getWeeksFromCurrentRange(dates.startDate, dates.endDate);
                    this.labels = weeks.map((week) => week.start);
                    this.tagEvolutionData = this._computeWeeklyData(reviews, weeks, allTags);
                    break;
                case ViewBy.MONTH:
                    const months: Month[] = getMonthsFromPeriod(dates.startDate, dates.endDate);
                    this.labels = months.map((e) => e.start);
                    this.tagEvolutionData = this._computeMonthlyData(reviews, months, allTags);
                    break;
                default:
                    break;
            }
        });
    }

    private _getTagsWithCount(reviews: ReviewWithAnalysis[]): PartialRecord<ReviewAnalysisTag, number> {
        return reviews
            .flatMap((review) => review.semanticAnalysis?.segmentAnalyses ?? [])
            .map((segment) => segment.tag)
            .reduce((acc, segmentTag) => {
                acc[segmentTag] = (acc[segmentTag] ?? 0) + 1;
                return acc;
            }, {});
    }

    private _getSegmentsWithReviewByTag(reviews: ReviewWithAnalysis[]): Record<string, SegmentWithReview[]> {
        const segmentsWithReview = reviews.flatMap((review) =>
            (review.semanticAnalysis?.segmentAnalyses ?? []).map((segmentAnalyses) => ({
                review: review,
                ...segmentAnalyses,
            }))
        );
        return groupBy(segmentsWithReview, 'tag');
    }

    private _computeDailyData(reviews: ReviewWithAnalysis[], days: Date[], allTags: string[]): Record<string, TagEvolutionData> {
        const segmentsWithReviewByTag: Record<string, SegmentWithReview[]> = this._getSegmentsWithReviewByTag(reviews);

        const dataByTag: Record<string, TagEvolutionData> = {};

        for (const tag of allTags) {
            const segmentsWithReview = segmentsWithReviewByTag[tag];
            const chartDataNegative: ChartDataArray = [];
            const chartDataPositive: ChartDataArray = [];
            for (const day of days) {
                chartDataNegative.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.NEGATIVE)
                        .filter((e) => isSameDay(new Date(e.review.socialCreatedAt), day)).length
                );
                chartDataPositive.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.POSITIVE)
                        .filter((e) => isSameDay(new Date(e.review.socialCreatedAt), day)).length
                );
            }
            dataByTag[tag] = {
                [ReviewAnalysisSentiment.NEGATIVE]: chartDataNegative,
                [ReviewAnalysisSentiment.POSITIVE]: chartDataPositive,
            };
        }
        return dataByTag;
    }

    private _computeWeeklyData(reviews: ReviewWithAnalysis[], weeks: WeekRange[], allTags: string[]): Record<string, TagEvolutionData> {
        const segmentsWithReviewByTag: Record<string, SegmentWithReview[]> = this._getSegmentsWithReviewByTag(reviews);

        const dataByTag: Record<string, TagEvolutionData> = {};

        for (const tag of allTags) {
            const segmentsWithReview = segmentsWithReviewByTag[tag];
            const chartDataNegative: ChartDataArray = [];
            const chartDataPositive: ChartDataArray = [];
            for (const week of weeks) {
                chartDataNegative.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.NEGATIVE)
                        .filter((e) => isInDayList(new Date(e.review.socialCreatedAt), week.days)).length
                );
                chartDataPositive.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.POSITIVE)
                        .filter((e) => isInDayList(new Date(e.review.socialCreatedAt), week.days)).length
                );
            }
            dataByTag[tag] = {
                [ReviewAnalysisSentiment.NEGATIVE]: chartDataNegative,
                [ReviewAnalysisSentiment.POSITIVE]: chartDataPositive,
            };
        }
        return dataByTag;
    }

    private _computeMonthlyData(reviews: ReviewWithAnalysis[], months: Month[], allTags: string[]): Record<string, TagEvolutionData> {
        const segmentsWithReviewByTag: Record<string, SegmentWithReview[]> = this._getSegmentsWithReviewByTag(reviews);

        const dataByTag: Record<string, TagEvolutionData> = {};

        for (const tag of allTags) {
            const segmentsWithReview = segmentsWithReviewByTag[tag];
            const chartDataNegative: ChartDataArray = [];
            const chartDataPositive: ChartDataArray = [];
            for (const month of months) {
                chartDataNegative.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.NEGATIVE)
                        .filter((e) => isInDayList(new Date(e.review.socialCreatedAt), month.days)).length
                );
                chartDataPositive.push(
                    segmentsWithReview
                        .filter((segment) => segment.sentiment === ReviewAnalysisSentiment.POSITIVE)
                        .filter((e) => isInDayList(new Date(e.review.socialCreatedAt), month.days)).length
                );
            }
            dataByTag[tag] = {
                [ReviewAnalysisSentiment.NEGATIVE]: chartDataNegative,
                [ReviewAnalysisSentiment.POSITIVE]: chartDataPositive,
            };
        }
        return dataByTag;
    }
}
