import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { initializeAggregatedFilters } from ':core/user-filters/store/user-filters.actions';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';

import { ReviewsDisplayMode } from '../reviews/store/reviews.reducer';

@Component({
    selector: 'app-aggregated-reputation',
    templateUrl: './aggregated-reputation.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AggregatedReputationComponent implements OnDestroy, OnInit {
    constructor(
        private readonly _store: Store,
        private readonly _destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this._store.dispatch(ReviewsActions.editReviewsFiltersCurrentView({ currentView: ReviewsDisplayMode.AGGREGATED_RESTAURANTS }));
        this._initAggregatedReviewsState();
    }

    _initAggregatedReviewsState(): void {
        this._store
            .select(selectUserInfos)
            .pipe(filter(isNotNil))
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((userInfos) => {
                this._store.dispatch({ type: initializeAggregatedFilters.type, userId: userInfos._id });
            });
    }

    ngOnDestroy(): void {
        this._store.dispatch(ReviewsActions.resetReviewsStateExceptFilters());
    }
}
