import { Pipe, PipeTransform } from '@angular/core';

export enum Emoji {
    ROCKET = 'rocket',
    CUTLERY = 'cutlery',
    HOURGLASS = 'hourglass',
    CRYING_FACE = 'crying_face',
    TROPHY = 'trophy',
    SECOND_PLACE_MEDAL = 'second_place_medal',
    THIRD_PLACE_MEDAL = 'third_place_medal',
    PARTY_FACE = 'party_face',
    HAPPY_FACE = 'happy_face',
    INDEX_POINTING_RIGHT = 'index_pointing_right',
    PARTYING_FACE = 'partying_face',
}

@Pipe({
    name: 'emojiPathResolver',
    standalone: true,
})
export class EmojiPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/emojis/${key}.png`;
    }
}
