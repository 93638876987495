import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { PostSuggestionNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-post-suggestion-notification-item',
    templateUrl: './post-suggestion-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, FormatDatePipe, MatButtonModule, ShortTextPipe],
})
export class PostSuggestionNotificationItemComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _notificationsContext = inject(NotificationCenterContext);
    readonly notification = computed(() => this.initialNotification() as PostSuggestionNotification);
    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);

    readonly isFolded = signal(true);
    readonly SHORT_TEXT_LENGTH = 100;

    onNotificationClick(): void {
        this._notificationsContext.trackNotification({
            notificationId: this.notification().id,
            heapEventName: HeapEventName.NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_WEB_REDIRECT,
            properties: {
                restaurantId: this.notification().data.restaurantIds[0],
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToPostsPage();
        this.notificationCenterContext.close();
    }

    toggleFolded(): void {
        this.isFolded.update((old) => !old);
    }

    private _navigateToPostsPage(): void {
        this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'social', 'socialposts'], {
            queryParams: { nid: this.notification().id, nchannel: this.notification().channel },
        });
    }
}
