<div class="malou-simple-card min-w-0 flex-1 px-6 py-7 @container">
    <div class="h-12.5">
        <div class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.platforms_ratings.marks' | translate }}
        </div>
    </div>
    <div class="flex flex-col gap-6 @xl:flex-row">
        <app-statistics-seo-platforms-ratings
            class="w-full"
            (hasDataChange)="hasPlatformRatingsData.set($event)"
            (isLoadingEvent)="isPlatformRatingsLoading.set($event)">
        </app-statistics-seo-platforms-ratings>
        <app-reviews-rating-calculator
            [ngClass]="{ 'w-full': hasReviewsCalculatorData() }"
            (hasDataChange)="hasReviewsCalculatorData.set($event)"
            (isLoadingEvent)="isReviewsCalculatorLoading.set($event)"></app-reviews-rating-calculator>
    </div>
</div>
