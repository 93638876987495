import { ChangeDetectionStrategy, Component, computed, effect, input, signal, untracked } from '@angular/core';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SidenavContentRouteComponent, SidenavContentRouteOptions } from './sidenav-content-route/sidenav-content-route.component';

@Component({
    selector: 'app-sidenav-content-route-group',
    templateUrl: './sidenav-content-route-group.component.html',
    standalone: true,
    imports: [SidenavContentRouteComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentRouteGroupComponent {
    readonly options = input.required<SidenavContentRouteOptions>();
    readonly childrenOptions = input<SidenavContentRouteOptions[]>([]);

    readonly isChildActiveByRouterLink = signal<Record<string, boolean>>({});
    readonly isAtLeastOneActiveChildren = computed(() => Object.values(this.isChildActiveByRouterLink()).some((e) => e));

    readonly isOpen = signal(false);

    readonly optionsComputed = computed<SidenavContentRouteOptions>(() => ({
        ...this.options(),
        notificationCount: this.childrenOptions()
            .map((options) => options.notificationCount ?? 0)
            .reduce((acc, cur) => acc + cur, 0),
        isActiveOverride: this.isAtLeastOneActiveChildren() || this.options().isActiveOverride,
        rightIconOnHover: this.isOpen() ? undefined : SvgIcon.PLUS,
    }));

    constructor() {
        effect(
            () => {
                const oldIsChildActiveByRouterLink = untracked(() => this.isChildActiveByRouterLink());
                const newIsChildActiveByRouterLink = this.childrenOptions().reduce((acc, cur) => {
                    const key = this._computeRouterLinkToKey(cur.routerLink);
                    return {
                        ...acc,
                        [key]: oldIsChildActiveByRouterLink[key] ?? cur,
                    };
                }, {});
                this.isChildActiveByRouterLink.set(newIsChildActiveByRouterLink);
            },
            { allowSignalWrites: true }
        );
        effect(
            () => {
                this.isOpen.set(this.isAtLeastOneActiveChildren());
            },
            { allowSignalWrites: true }
        );
    }

    onIsActive(value: boolean, routerLink: string[]): void {
        this.isChildActiveByRouterLink.update((isChildActiveByRouterLink) => {
            isChildActiveByRouterLink[this._computeRouterLinkToKey(routerLink)] = value;
            return { ...isChildActiveByRouterLink };
        });
    }

    private _computeRouterLinkToKey(routerLink: string[]): string {
        return routerLink.join('/');
    }
}
