import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, difference, isEqual, isEqualWith, uniq } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, EMPTY, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { AggregationTimeScale, isNotNil, isSameDay, MalouMetric, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { ReviewRatingInsightsByRestaurantId } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-rating-kpis/review-rating-insights-by-restaurant-id/review-rating-insights-by-restaurant-id';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsService } from ':modules/statistics/insights.service';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant, TimeScaleToMetricToDataValues } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface RatingKpisEvolution {
    currentRating?: number;
    previousRating?: number;
    diffRating?: number;
    hasData: boolean;
}

interface RestaurantRatingKpis {
    restaurant: {
        _id: string;
        name: string;
    };
    platformKpis: { [key in PlatformKey]?: RatingKpisEvolution };
}

enum RatingKpisTableColumns {
    RESTAURANT = 'restaurant',
}

@Component({
    selector: 'app-reviews-rating-kpis-v2',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
        TranslateModule,
        MatSortModule,
        NgTemplateOutlet,
        MatTableModule,
        ApplyPurePipe,
        NgClass,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    templateUrl: './reviews-rating-kpis.component.html',
    styleUrls: ['./reviews-rating-kpis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsRatingKpisV2Component implements OnInit {
    readonly tableSortOptions = input<Sort>();
    readonly displayedColumnsCount = output<number>();
    readonly tableSortOptionsChange = output<Sort>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _destroyRef = inject(DestroyRef);
    readonly Illustration = Illustration;
    readonly RESTAURANT_COLUMN = RatingKpisTableColumns.RESTAURANT;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)));
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter).pipe(
        distinctUntilChanged((prev, curr) => {
            const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
            const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
            return isSameStartDate && isSameEndDate;
        })
    );
    readonly restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqualWith(prev, curr, (c, d) => c.id === d.id))
    );

    currentReviewRatingInsightsByRestaurantId = signal(ReviewRatingInsightsByRestaurantId.fromDto({}));
    previousReviewRatingInsightsByRestaurantId = signal(ReviewRatingInsightsByRestaurantId.fromDto({}));

    readonly dataSource: WritableSignal<MatTableDataSource<RestaurantRatingKpis>> = signal(
        new MatTableDataSource<RestaurantRatingKpis>([])
    );
    readonly hasData: Signal<boolean> = computed(() => this.dataSource().data.length > 0);
    readonly displayedColumns: Signal<(RatingKpisTableColumns | PlatformKey)[]> = computed(() => [
        this.RESTAURANT_COLUMN,
        ...this._getPlatformsWithData(this.dataSource().data),
    ]);
    readonly highestEvolutionAndRegression: Signal<{ highestEvolution: number; worstRegression: number }> = computed(() =>
        this._getHighestEvolutionAndRegression(this.dataSource().data)
    );
    readonly highestEvolution: Signal<number> = computed(() => this.highestEvolutionAndRegression().highestEvolution);
    readonly worstRegression: Signal<number> = computed(() => this.highestEvolutionAndRegression().worstRegression);

    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly httpError: WritableSignal<string | undefined> = signal(undefined);
    selectedPlatforms: PlatformKey[] = [];

    sort: Sort = { active: this.RESTAURANT_COLUMN, direction: ChartSortBy.ASC };
    restaurants: Restaurant[] = [];

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource().sortingDataAccessor = (item, property): string | number => {
                const { active, direction } = sort;
                this.tableSortOptionsChange.emit({ active, direction });
                switch (property) {
                    case this.RESTAURANT_COLUMN:
                        return item[property].name;
                    default:
                        return item.platformKpis[property]?.currentRating ?? 0;
                }
            };
            this.dataSource().sort = sort;
        }
    }

    ngOnInit(): void {
        combineLatest([
            this.restaurants$,
            this.dates$.pipe(tap(() => this._resetChartData())),
            this.platformKeys$.pipe(tap(() => this._resetChartData())),
        ])
            .pipe(
                filter(
                    ([restaurants, dates, platforms]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0 && !!restaurants.length
                ),
                tap(() => {
                    this._reset();
                }),
                map(([restaurants, dates, platforms]) => [restaurants.filter((e) => !e.isBrandBusiness()), dates, platforms]),
                switchMap(([restaurants, dates, platforms]: [Restaurant[], DatesAndPeriod, PlatformKey[]]) => {
                    this.restaurants = restaurants;
                    const restaurantIds = restaurants.map((e) => e._id);
                    const { startDate, endDate } = dates;
                    this.selectedPlatforms = platforms;

                    const allPlatformsWithRating = PlatformDefinitions.getPlatformKeysWithRating();
                    const connectedPlatformsWithRating = platforms.filter((platform) => allPlatformsWithRating.includes(platform));

                    const alreadyFetchedRestaurantIds = uniq([
                        ...this.currentReviewRatingInsightsByRestaurantId().getPresentRestaurantIds(),
                        ...this.previousReviewRatingInsightsByRestaurantId().getPresentRestaurantIds(),
                    ]);
                    const restaurantIdsToFetch = difference(restaurantIds, alreadyFetchedRestaurantIds);
                    const restaurantIdsToDelete = difference(alreadyFetchedRestaurantIds, restaurantIds);
                    if (restaurantIdsToDelete.length) {
                        this.currentReviewRatingInsightsByRestaurantId.update((current) => current.deleteMany(restaurantIdsToDelete));
                        this.previousReviewRatingInsightsByRestaurantId.update((previous) => previous.deleteMany(restaurantIdsToDelete));
                    }
                    if (!restaurantIdsToFetch.length) {
                        return forkJoin([
                            of(ReviewRatingInsightsByRestaurantId.fromDto({})),
                            of(ReviewRatingInsightsByRestaurantId.fromDto({})),
                        ]);
                    }

                    const body = {
                        restaurantIds: restaurantIdsToFetch,
                        startDate,
                        endDate,
                        platformsKeys: connectedPlatformsWithRating,
                        metrics: [MalouMetric.PLATFORM_RATING],
                        aggregators: [AggregationTimeScale.BY_DAY],
                    };
                    return forkJoin([
                        this._insightsService.getInsights(body).pipe(map((res) => ReviewRatingInsightsByRestaurantId.fromDto(res.data))),
                        this._insightsService
                            .getInsights({
                                ...body,
                                previousPeriod: true,
                            })
                            .pipe(map((res) => ReviewRatingInsightsByRestaurantId.fromDto(res.data))),
                    ]).pipe(
                        catchError((error) => {
                            this.httpError.set(error);
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return EMPTY;
                        })
                    );
                }),
                map(([currentReviewRatingInsightsByRestaurantId, previousReviewRatingInsightsByRestaurantId]) => {
                    this.currentReviewRatingInsightsByRestaurantId.update((current) =>
                        current.merge(currentReviewRatingInsightsByRestaurantId)
                    );
                    this.previousReviewRatingInsightsByRestaurantId.update((previous) =>
                        previous.merge(previousReviewRatingInsightsByRestaurantId)
                    );
                    return {
                        currentReviewRatingInsightsByRestaurantId: this.currentReviewRatingInsightsByRestaurantId(),
                        previousReviewRatingInsightsByRestaurantId: this.previousReviewRatingInsightsByRestaurantId(),
                    };
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ({
                    currentReviewRatingInsightsByRestaurantId,
                    previousReviewRatingInsightsByRestaurantId,
                }: {
                    currentReviewRatingInsightsByRestaurantId: ReviewRatingInsightsByRestaurantId;
                    previousReviewRatingInsightsByRestaurantId: ReviewRatingInsightsByRestaurantId;
                }) => {
                    this._store.dispatch(
                        AggregatedStatisticsActions.editPlatformsRatingsByRestaurantData({
                            data: cloneDeep(Object.fromEntries(currentReviewRatingInsightsByRestaurantId.entries())),
                        })
                    );
                    const tableData = this._getTableData(
                        currentReviewRatingInsightsByRestaurantId,
                        previousReviewRatingInsightsByRestaurantId
                    );
                    if (!tableData?.length) {
                        this.isLoading.set(false);
                        this.hasDataChange.emit(false);
                        return;
                    }
                    this.dataSource.set(new MatTableDataSource<RestaurantRatingKpis>(tableData || []));
                    this._emitDisplayedColumnCount(this.displayedColumns().length);
                    this._initDataSourceSortOptions(this.tableSortOptions());
                    this._initDatSourceSort();
                    this.isLoading.set(false);
                },
            });
    }

    getTableColumnDisplayName = (column: RatingKpisTableColumns | PlatformKey): string => {
        if (this._isRestaurantColumn(column)) {
            return this._translateService.instant('statistics.e_reputation.reviews_kpis.restaurants');
        }
        return PlatformDefinitions.getPlatformDefinition(column)?.fullName ?? '';
    };

    private _initDataSourceSortOptions(sortOptions: Sort | undefined): void {
        if (sortOptions) {
            this.sort = sortOptions;
        }
    }

    private _isRestaurantColumn(column: RatingKpisTableColumns | PlatformKey): boolean {
        return column === this.RESTAURANT_COLUMN;
    }

    private _getTableData(
        currentRestaurantRatings: ReviewRatingInsightsByRestaurantId,
        previousRestaurantRatings: ReviewRatingInsightsByRestaurantId
    ): RestaurantRatingKpis[] | null {
        if (!currentRestaurantRatings.getPresentRestaurantIds().length) {
            return null;
        }
        return this.restaurants
            .map((restaurant) => {
                const currentRestaurantRating = currentRestaurantRatings.get(restaurant._id);
                const previousRestaurantRating = previousRestaurantRatings.get(restaurant._id);

                if (!currentRestaurantRating) {
                    return null;
                }

                const restaurantRatingKpi: RestaurantRatingKpis = {
                    restaurant: {
                        _id: restaurant._id,
                        name: restaurant.internalName ?? restaurant.name,
                    },
                    platformKpis: {},
                };

                this.selectedPlatforms.forEach((platformKey) => {
                    const currentPlatformKpis = currentRestaurantRating[platformKey];
                    const previousPlatformKpis = previousRestaurantRating?.[platformKey];
                    restaurantRatingKpi.platformKpis[platformKey] = this._getPlatformRatingEvolution(
                        currentPlatformKpis,
                        previousPlatformKpis
                    );
                });
                return restaurantRatingKpi;
            })
            .filter(isNotNil);
    }

    private _getPlatformRatingEvolution(currentPlatformRating, previousPlatformRating): RatingKpisEvolution {
        const currentRating = this._getPlatformLastRating(currentPlatformRating);
        if (!currentPlatformRating || !currentRating) {
            return { hasData: false };
        }
        const previousRating = this._getPlatformLastRating(previousPlatformRating);
        return {
            currentRating,
            previousRating,
            diffRating: previousRating ? currentRating - previousRating : undefined,
            hasData: true,
        };
    }

    private _getPlatformLastRating(platformRatings: TimeScaleToMetricToDataValues | undefined): number | undefined {
        if (platformRatings?.error) {
            return undefined;
        }
        const ratings = platformRatings?.[AggregationTimeScale.BY_DAY]?.[MalouMetric.PLATFORM_RATING];
        if (!ratings?.length) {
            return undefined;
        }
        return ratings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).filter((rating) => !!rating.value)[0]?.value;
    }

    private _getHighestEvolutionAndRegression(restaurantRatingKpis: RestaurantRatingKpis[]): {
        highestEvolution: number;
        worstRegression: number;
    } {
        return restaurantRatingKpis.reduce(
            (acc, restaurantKpis) => {
                const restaurantHighestEvolution = this._getEvolutionsForRestaurant(restaurantKpis.platformKpis);
                return {
                    highestEvolution: Math.max(restaurantHighestEvolution.highestEvolution, acc.highestEvolution),
                    worstRegression: Math.min(restaurantHighestEvolution.worstRegression, acc.worstRegression),
                };
            },
            { highestEvolution: 0, worstRegression: 0 }
        );
    }

    private _getEvolutionsForRestaurant(platformsKpis: { [key in PlatformKey]?: RatingKpisEvolution }): {
        highestEvolution: number;
        worstRegression: number;
    } {
        return Object.values(platformsKpis).reduce(
            (acc, platformKpi) => ({
                highestEvolution: Math.max(platformKpi?.diffRating || 0, acc.highestEvolution),
                worstRegression: Math.min(platformKpi?.diffRating || 0, acc.worstRegression),
            }),
            { highestEvolution: 0, worstRegression: 0 }
        );
    }

    private _getPlatformsWithData(dataSource: RestaurantRatingKpis[]): PlatformKey[] {
        return this.selectedPlatforms.filter((platformKey) =>
            dataSource.some((restaurantKpis) => restaurantKpis.platformKpis[platformKey]?.hasData)
        );
    }

    private _emitDisplayedColumnCount(count: number): void {
        this.displayedColumnsCount.emit(count);
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.isLoading.set(true);
        this.dataSource.set(new MatTableDataSource<RestaurantRatingKpis>([]));
    }

    private _resetChartData(): void {
        this.currentReviewRatingInsightsByRestaurantId.set(ReviewRatingInsightsByRestaurantId.fromDto({}));
        this.previousReviewRatingInsightsByRestaurantId.set(ReviewRatingInsightsByRestaurantId.fromDto({}));
    }

    private _initDatSourceSort() {
        const dataSource = this.dataSource();
        if (dataSource) {
            this.sort = {
                active: this.displayedColumns()[1],
                direction: ChartSortBy.DESC,
            };
            dataSource.sort?.sort({
                id: this.displayedColumns()[1],
                start: ChartSortBy.DESC,
                disableClear: true,
            });
        }
    }
}
