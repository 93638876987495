import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import _, { round } from 'lodash';
import { forkJoin, Observable, of, switchMap, take } from 'rxjs';

import { GIFT_PERCENTAGE_DECIMALS } from ':core/constants';
import { AggregatedWheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersAggregatedDataFetchingService } from ':modules/aggregated-statistics/boosters/services/get-boosters-aggregated-data.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';

import { AbstractCsvService, CsvAsStringArrays } from '../csv-service.abstract';

interface Data {
    restaurants: Restaurant[];
    giftsData: AggregatedWheelOfFortuneGiftsStatisticsData;
}

@Injectable({ providedIn: 'root' })
export class AggregatedBoostersGiftsCsvInsightService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        private readonly _getBoostersAggregatedDataService: BoostersAggregatedDataFetchingService
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin({
            restaurants: this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
            dates: this._store.select(AggregatedStatisticsSelectors.selectDatesFilter).pipe(take(1)),
        }).pipe(
            switchMap(({ restaurants, dates }) => {
                const restaurantsWithBoosterPackActivated = restaurants.filter((restaurant) => restaurant.boosterPack?.activated);
                return forkJoin({
                    restaurants: of(restaurantsWithBoosterPackActivated),
                    giftsData: this._getBoostersAggregatedDataService.getGiftsData(dates, restaurantsWithBoosterPackActivated),
                });
            })
        );
    }

    protected override _isDataValid({ giftsData, restaurants }: Data): boolean {
        return giftsData.giftsInsightsPerRestaurant.length > 0 && restaurants.length > 0;
    }

    protected override _getCsvHeaderRow(): string[] {
        return [
            'Location',
            'Location Internal Name',
            'Location Address',
            'Winners Count',
            'Retrieved Gifts Count',
            'Retrieved Gifts Ratio',
        ];
    }

    protected override _getCsvDataRows({ restaurants, giftsData }: Data): CsvAsStringArrays {
        const { giftsInsightsPerRestaurant } = giftsData;
        return _.orderBy(restaurants, ['name'], [ChartSortBy.ASC]).map(({ name, internalName, address }) => {
            const restaurantsGiftsData = giftsInsightsPerRestaurant.find(
                (giftStats) => giftStats.restaurant === name || internalName === giftStats.restaurant
            );
            return [
                name ?? '-',
                internalName ?? '-',
                address?.formattedAddress ?? address?.toString() ?? '-',
                restaurantsGiftsData?.giftDrawCount ? restaurantsGiftsData.giftDrawCount.toString() : '0',
                restaurantsGiftsData?.retrievedGiftDrawCount ? restaurantsGiftsData.retrievedGiftDrawCount.toString() : '0',
                restaurantsGiftsData?.retrievedGiftDrawCount
                    ? round(
                          (restaurantsGiftsData?.retrievedGiftDrawCount / restaurantsGiftsData?.giftDrawCount) * 100,
                          GIFT_PERCENTAGE_DECIMALS
                      ) + '%'
                    : '0%',
            ];
        });
        return [];
    }
}
