@if (gmbInsightsContext.isLoading()) {
    <app-skeleton skeletonClass="!h-[519px] secondary-bg"></app-skeleton>
} @else {
    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
        <div>
            <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
        </div>
        <div>
            <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
        </div>
        <div>
            <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
        </div>
    </div>
}

<ng-template #titleTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-center gap-2 pt-2">
            <span class="malou-text-section-title malou-color-text-1">
                {{ 'aggregated_statistics.seo.gmb_actions.actions_google' | translate }}
            </span>
            @if (gmbInsightsContext.warningTooltip()) {
                <div class="malou-status--waiting pdf-hidden" [matTooltip]="gmbInsightsContext.warningTooltip()!">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
            @if (gmbInsightsContext.errorTooltip()) {
                <div class="malou-status--error pdf-hidden" [matTooltip]="gmbInsightsContext.errorTooltip()!">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
        </div>
        @if (!showSortByTextInsteadOfSelector()) {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--medium md:hidden">{{ 'aggregated_statistics.seo.gmb_actions.sort_by' | translate }}</span>
                <app-select
                    class="w-[150px]"
                    [values]="SORT_BY_FILTER_VALUES"
                    [displayWith]="sortByDisplayWith"
                    [formControl]="sortByControl"
                    (selectChange)="sortByFilterSubject$.next($event)"></app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.sorted_by_order' | translate }}:
                    {{ sortByDisplayWith | applyPure: (sortByFilterSubject$ | async) ?? DEFAULT_SORT_BY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-gmb-actions-chart
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        [gmbActionsData]="gmbActionsData()"
        [previousGmbActionsData]="previousGmbActionsData()"
        [restaurants]="restaurants()"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-gmb-actions-chart>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div
                    class="malou-text-30--bold malou-color-text-1"
                    [matTooltip]="'aggregated_statistics.seo.gmb_actions.user_actions_number' | translate">
                    {{ totalActions() | shortNumber }}
                </div>
                @if (totalActions()) {
                    <app-number-evolution
                        [value]="actionsEvolutionPercentage()"
                        [displayedValue]="
                            actionsEvolutionPercentage() | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'aggregated_statistics.seo.gmb_actions.actions_on_period' | translate }}
            </div>
        </div>
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div
                    class="malou-text-30--bold malou-color-text-1"
                    [matTooltip]="'statistics.seo.gmb_actions.conversion_rate_tooltip' | translate">
                    {{ conversionRate() | shortNumber: { content: '%' } }}
                </div>
                @if (conversionRate()) {
                    <app-number-evolution
                        [value]="conversionRateEvolution()"
                        [displayedValue]="
                            conversionRateEvolution() | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.gmb_actions.conversion_rate' | translate }}
            </div>
        </div>
    </div>
</ng-template>
