import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { KeywordSearchImpressionsType } from '@malou-io/package-utils';

import { TopKeywordSearchImpressionsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { TopKeywordSearchModalComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-modal/top-keyword-search-modal.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateIndexArrayPipe } from ':shared/pipes/create-array.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface KeywordsToDisplayCountByType {
    [KeywordSearchImpressionsType.BRANDING]: number;
    [KeywordSearchImpressionsType.DISCOVERY]: number;
}

@Component({
    selector: 'app-top-keyword-search-impressions',
    standalone: true,
    imports: [CreateIndexArrayPipe, TranslateModule, ApplyPurePipe, ShortTextPipe, MatTooltip, NgTemplateOutlet],
    templateUrl: './top-keyword-search-impressions.component.html',
    styleUrl: './top-keyword-search-impressions.component.scss',
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopKeywordSearchImpressionsComponent {
    readonly topKeywordSearchImpressions = input.required<TopKeywordSearchImpressionsData>();

    private readonly _emojiPathResolverPipe = inject(EmojiPathResolverPipe);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;
    private readonly _KEYWORDS_TO_DISPLAY_LIMIT = 3;
    readonly SHORT_TEXT_LENGTH = 35;

    readonly keywordsToDisplayCountByType: Signal<{
        [KeywordSearchImpressionsType.BRANDING]: number;
        [KeywordSearchImpressionsType.DISCOVERY]: number;
    }> = computed(() => this._getKeywordsToDisplayCountByType(this.topKeywordSearchImpressions()));

    openTopKeywordSearchModal(type: KeywordSearchImpressionsType): void {
        this._customDialogService
            .open(TopKeywordSearchModalComponent, {
                width: '40vw',
                data: {
                    topKeywordSearchImpressions: this.topKeywordSearchImpressions(),
                    type,
                },
            })
            .afterClosed();
    }

    getEmojiFromPosition = (position: number): string =>
        [
            this._emojiPathResolverPipe.transform(Emoji.TROPHY),
            this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.PARTY_FACE),
        ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE);

    private _getKeywordsToDisplayCountByType(topKeywordSearchImpressions: TopKeywordSearchImpressionsData): KeywordsToDisplayCountByType {
        const brandingKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.BRANDING].length;
        const discoveryKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.DISCOVERY].length;

        return {
            [KeywordSearchImpressionsType.BRANDING]: Math.max(
                this._KEYWORDS_TO_DISPLAY_LIMIT,
                this._KEYWORDS_TO_DISPLAY_LIMIT * 2 - discoveryKeywordsCount
            ),
            [KeywordSearchImpressionsType.DISCOVERY]: Math.max(
                this._KEYWORDS_TO_DISPLAY_LIMIT,
                this._KEYWORDS_TO_DISPLAY_LIMIT * 2 - brandingKeywordsCount
            ),
        };
    }
}
