import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { catchError, forkJoin, of, Subject, switchMap } from 'rxjs';

import { isNotNil, MapstrCtaButtonType, PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { FacebookPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/facebook-preview/facebook-preview.component';
import { InstagramPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/instagram-preview/instagram-preview.component';
import { MapstrPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/mapstr-preview/mapstr-preview.component';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';

export interface PlatformOption {
    platformKey: PlatformKey;
    username: string;
    profilePictureUrl?: string;
}

@Component({
    selector: 'app-previews',
    templateUrl: './previews.component.html',
    styleUrls: ['./previews.component.scss'],
    standalone: true,
    imports: [SimpleSelectComponent, PlatformLogoComponent, FacebookPreviewComponent, InstagramPreviewComponent, MapstrPreviewComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewsComponent implements OnInit {
    readonly selectedPlatformOption = model.required<PlatformOption | null>();
    readonly fetchedPlatformOptions = model.required<PlatformOption[]>();
    readonly selectedPlatformKeys = input.required<PlatformKey[]>();

    private readonly _upsertModalContext = inject(UpsertSocialPostContext);
    private readonly _platformsService = inject(PlatformsService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly text = this._upsertModalContext.upsertSocialPostState.post.text;
    readonly title = this._upsertModalContext.upsertSocialPostState.post.title;
    readonly location = this._upsertModalContext.upsertSocialPostState.post.location;
    readonly attachments = this._upsertModalContext.upsertSocialPostState.post.attachments;
    readonly plannedPublicationDate = this._upsertModalContext.upsertSocialPostState.post.plannedPublicationDate;
    readonly callToActionType = computed(
        (): MapstrCtaButtonType | undefined => this._upsertModalContext.upsertSocialPostState.post().callToAction?.actionType
    );
    readonly selectedHashtagsText = computed((): string[] => {
        const hashtags = this._upsertModalContext.upsertSocialPostState.post.hashtags();
        return hashtags.selected.map((postHashtag) => postHashtag.text);
    });

    private readonly _selectedPlatformKeys$ = toObservable(this.selectedPlatformKeys);

    private readonly _sortedPlatformKeys = [PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK, PlatformKey.MAPSTR];
    readonly platformOptions = computed((): PlatformOption[] =>
        this.fetchedPlatformOptions()
            .filter((option) => this.selectedPlatformKeys().includes(option.platformKey))
            .sort(
                (platformOptionA, platformOptionB) =>
                    this._sortedPlatformKeys.indexOf(platformOptionA.platformKey) -
                    this._sortedPlatformKeys.indexOf(platformOptionB.platformKey)
            )
    );

    readonly currentRestaurant = this._restaurantsService.currentRestaurant;

    readonly _fetchProfilePictureUrls$ = new Subject<PlatformOption[]>();

    readonly PlatformKey = PlatformKey;

    constructor() {
        effect(
            () => {
                const platformOptions = this.fetchedPlatformOptions().filter((option) =>
                    this.selectedPlatformKeys().includes(option.platformKey)
                );
                const selectedPlatformOption = this.selectedPlatformOption();
                if (
                    (!selectedPlatformOption && platformOptions.length) ||
                    (selectedPlatformOption && !platformOptions.includes(selectedPlatformOption))
                ) {
                    this.selectedPlatformOption.set(platformOptions[0]);
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this._selectedPlatformKeys$
            .pipe(
                switchMap((platformKeys) => {
                    const missingPlatformOptions = platformKeys.filter(
                        (platformKey) => !this.fetchedPlatformOptions().some((option) => option.platformKey === platformKey)
                    );
                    return forkJoin(
                        missingPlatformOptions.map((platformKey) =>
                            this._platformsService.getPlatformSocialLink(this.currentRestaurant._id, platformKey)
                        )
                    );
                })
            )
            .subscribe((responses) => {
                const platformOptions = responses
                    .filter((response) => isNotNil(response.data))
                    .map((response) => this._mapPlatformToPlatformOption(response.data));
                this._fetchProfilePictureUrls$.next(platformOptions); // Always fetch profile pictures to have the latest
                this.fetchedPlatformOptions.update((prev) => [...prev, ...platformOptions]);
            });

        this._fetchProfilePictureUrls$.subscribe((platformOptions) => {
            platformOptions.forEach((platformOption) => {
                this._platformsService
                    .getProfilePictureUrl(this.currentRestaurant._id, platformOption.platformKey)
                    .pipe(
                        catchError((error) => {
                            console.error('Error fetching profile picture URL', { platformKey: platformOption.platformKey, error });
                            return of({ data: { profilePictureUrl: undefined } });
                        })
                    )
                    .subscribe((response) => {
                        this.fetchedPlatformOptions.update((prev) =>
                            prev.map((option) =>
                                option.platformKey === platformOption.platformKey
                                    ? { ...option, profilePictureUrl: response.data?.profilePictureUrl }
                                    : option
                            )
                        );
                    });
            });
        });
    }

    onPlatformChange(platformOption: PlatformOption | null): void {
        if (!platformOption) {
            return;
        }
        this.selectedPlatformOption.set(platformOption);
    }

    private _mapPlatformToPlatformOption(platform: {
        _id: string;
        key: PlatformKey;
        socialLink?: string;
        socialId: string;
        name: string;
        profilePictureUrl?: string;
    }): PlatformOption {
        let username = this.currentRestaurant.name;

        switch (platform.key) {
            case PlatformKey.INSTAGRAM:
                const matchedUsername = platform.socialLink?.match(/^https:\/\/www.instagram.com\/(.*)/)?.[1];
                username = matchedUsername ? `@${matchedUsername}` : '';
                break;
            case PlatformKey.FACEBOOK:
                username = platform.name;
        }
        return {
            platformKey: platform.key,
            username,
            profilePictureUrl: platform.profilePictureUrl,
        };
    }
}
