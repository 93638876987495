<ng-container *ngTemplateOutlet="isInitialLoading ? shimmer : page"></ng-container>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile overflow-x-hidden" [id]="CREATE_POST_MODAL_HTML_ID">
        <div class="malou-dialog__header">
            <span>
                {{ 'posts.seo.new_post.title' | translate }}
            </span>
            <div class="flex items-center">
                @if (data.allPostIds?.length) {
                    <div class="flex items-center">
                        <span class="malou-text-13--semibold !mr-3.5">
                            {{ postIndex + 1 }} / {{ data.allPostIds?.length }}
                            {{ 'social_posts.new_social_post.posts_not_completed' | pluralTranslate: data.allPostIds?.length ?? 1 }}
                        </span>
                        @if ((data.allPostIds?.length ?? 0) > 1) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-3.5"
                                data-cy="arrowBackIcon"
                                mat-icon-button
                                [disabled]="postIndex === 0"
                                (click)="changePost(-1)">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                            </button>
                        }
                        @if ((data.allPostIds?.length ?? 0) > 1) {
                            <button
                                class="malou-btn-icon--secondary btn-xl !mr-3.5"
                                mat-icon-button
                                [disabled]="postIndex === (data.allPostIds?.length ?? 1) - 1"
                                (click)="changePost(1)">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                            </button>
                        }
                    </div>
                }
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="openSaveBeforeClose()"></mat-icon>
            </div>
        </div>
        <hr class="border-malou-color-background-dark" />
        <div class="malou-dialog__body !m-0 flex h-auto grow flex-wrap gap-x-5 !pl-4 md:!flex-col md:!flex-nowrap md:pb-20">
            <ng-container *ngTemplateOutlet="picEditor"></ng-container>
            <ng-container *ngTemplateOutlet="postContent"></ng-container>
            <ng-container *ngTemplateOutlet="feedbacks"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="stepButtons"></ng-container>
    </div>
</ng-template>

<ng-template #picEditor>
    <section class="section-size flex-4 py-4" [hidden]="isLoading || !(showPicEditor$ | async)">
        <form class="h-full" [formGroup]="newPostModalContext.postForm">
            <div class="flex h-full flex-col" formGroupName="post">
                <app-media-editor
                    class="grow"
                    [maxMedia]="1"
                    [attachmentsName]="attachmentsName"
                    [acceptedMediaTypes]="[InputMediaType.IMAGE]"
                    [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
                    [initialMedias]="attachments"
                    [isTaggable]="false"
                    (fileChanged)="onMediaSelect($event)"
                    (mediasSelected)="onMediaSelect($event)"></app-media-editor>
                @if (attachments.length) {
                    <div class="malou-color-background-light mt-1 rounded-lg p-3">
                        <app-input-text
                            formControlName="attachmentsName"
                            [testId]="'seo-post-picture-name-input'"
                            [placeholder]="'posts.new_post.picture_name' | translate"
                            [title]="'posts.new_post.improve_ref' | translate">
                        </app-input-text>
                    </div>
                }
            </div>
        </form>
    </section>
</ng-template>
<ng-template #postContent>
    <div class="flex-7 max-h-[88%] grow py-4 md:h-auto" [hidden]="!(showPostForm$ | async)">
        <div class="flex h-full flex-col overflow-auto px-5">
            <form [formGroup]="newPostModalContext.postForm">
                <form formGroupName="post">
                    @if (isLoading) {
                        <mat-progress-bar data-cy="postProgressBar" mode="indeterminate"></mat-progress-bar>
                    }
                    <section>
                        <app-simple-select
                            formControlName="postTopic"
                            [testId]="'seo-post-type-input'"
                            [title]="'posts.new_post.post_type' | translate"
                            [values]="postTopics"
                            [displayWith]="displayWithPostTopic"></app-simple-select>
                    </section>
                    <section class="mt-8">
                        <app-post-caption
                            [postDescriptionFormControl]="postDescriptionControl"
                            [textAreaId]="'postText'"></app-post-caption>

                        <app-keywords-score-gauge
                            [text$]="postText$"
                            [shouldDisplayTips]="true"
                            [shouldDisplayKeywords]="true"
                            [shouldCacheScore]="false"
                            [restaurant$]="restaurant$"
                            [textType$]="gaugeTextType$"
                            [keywords$]="restaurantKeywords$"
                            [withLargeDetails]="true"
                            [lang$]="postLang$"
                            [parentElementId$]="postId$"
                            [title]="'posts.new_post.keyword_gauge_title' | translate"
                            (addKeyword)="addKeyword($event)"
                            (langChanged)="newPostModalContext.onChangePostLang($event)"
                            #keywordsScoreGauge>
                        </app-keywords-score-gauge>
                    </section>
                    @if (postTopic?.type !== SeoPostTopic.STANDARD) {
                        <section class="mt-8">
                            <form formGroupName="event">
                                <app-input-text
                                    class="mb-12"
                                    formControlName="title"
                                    [title]="'posts.new_post.add_event_title' | translate"
                                    [showMaxLength]="true"
                                    [maxLength]="EVENT_TITLE_TEXT_LIMIT">
                                </app-input-text>
                                <p class="malou-color-text-2 malou-text-10--regular mt-4">
                                    {{ 'posts.new_post.event_dates' | translate }}
                                </p>
                                <div class="flex justify-between gap-x-2">
                                    <app-input-date-picker
                                        class="grow-2"
                                        formControlName="startDate"
                                        [manualInputAvailable]="false"
                                        [min]="MIN_DATE"
                                        [max]="getCorrectEndDate()"></app-input-date-picker>
                                    <app-input-date-picker
                                        class="grow-2"
                                        formControlName="endDate"
                                        [manualInputAvailable]="false"
                                        [min]="newPostModalContext.postForm.get('post.event.startDate')?.value ?? DEFAULT_MIN_EVENT_DATE"
                                        [max]="DEFAULT_MAX_EVENT_DATE"></app-input-date-picker>
                                </div>
                            </form>
                        </section>
                    }
                    @if (postTopic?.type === SeoPostTopic.OFFER) {
                        <section class="mt-8">
                            <p class="malou-color-text-2 malou-text-10--regular mb-2">
                                {{ 'posts.new_post.add_offer_infos' | translate }}
                                <small class="post-new-optional">{{ 'posts.new_post.optional' | translate }}</small>
                            </p>
                            <form class="sm:flex sm:flex-col sm:gap-y-1" formGroupName="offer">
                                <app-input-text formControlName="couponCode" [placeholder]="'posts.new_post.code_placeholder' | translate">
                                </app-input-text>
                                <app-input-text formControlName="onlineUrl" [placeholder]="'posts.new_post.link_placeholder' | translate">
                                </app-input-text>
                                <app-input-text
                                    formControlName="termsConditions"
                                    [placeholder]="'posts.new_post.terms_placeholder' | translate">
                                </app-input-text>
                            </form>
                        </section>
                    }
                    @if (postTopic && [SeoPostTopic.STANDARD, SeoPostTopic.EVENT].includes(postTopic!.type)) {
                        <section class="mt-8">
                            <app-post-call-to-action
                                [restaurant]="restaurant"
                                [currentCallToActionType]="
                                    newPostModalContext.postForm.get('post.callToAction.actionType')?.value?.type ?? null
                                "
                                [currentCallToActionUrl]="newPostModalContext.postForm.get('post.callToAction.url')?.value ?? null"
                                [callToActions]="callToActions"
                                (onSelectUrl)="onSelectUrl($event)"
                                (onSelectCallToAction)="onSelectCallToAction($event)"
                                (onNavigateToInformations)="navigateToInformations()"></app-post-call-to-action>
                        </section>
                    }
                </form>
                <form class="mt-8" formGroupName="date">
                    @if (showDateSection) {
                        <section>
                            <div class="malou-text-10--regular mt-7.5 w-full">
                                <span class="text-malou-color-text-1">{{ 'posts.new_post.publish_date' | translate }}</span>
                                <mat-radio-group class="mt-4 flex gap-x-4" color="primary" formControlName="postDateStatus">
                                    <mat-radio-button data-testid="now-btn" [value]="PostDateStatus.NOW">
                                        <span>{{ 'posts.new_post.now' | translate }}</span>
                                    </mat-radio-button>
                                    <mat-radio-button data-testid="schedule-btn" [value]="PostDateStatus.LATER">
                                        <span> {{ 'posts.new_post.later' | translate }}</span>
                                    </mat-radio-button>
                                    <mat-radio-button data-testid="draft-btn" [value]="PostDateStatus.DRAFT">
                                        <span> {{ 'posts.new_post.draft' | translate }}</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            @if (
                                [PostDateStatus.LATER, PostDateStatus.DRAFT]
                                    | includes: newPostModalContext.postForm.get('date.postDateStatus')?.value
                            ) {
                                <div class="mt-4 flex w-full gap-x-4">
                                    <div class="grow">
                                        <app-input-date-picker
                                            formControlName="postDate"
                                            [testId]="'seo-post-date-input'"
                                            [manualInputAvailable]="false"
                                            [onTimeChange$]="newPostModalContext.postForm.get('date.postTime')?.valueChanges"
                                            [min]="MIN_DATE"
                                            [time]="newPostModalContext.postForm.get('date.postTime')?.value"></app-input-date-picker>
                                    </div>
                                    <div class="flex flex-col">
                                        <mat-select
                                            class="!m-0 !h-0 opacity-0"
                                            data-testid="seo-post-hours-input"
                                            formControlName="postTime"
                                            panelClass="malou-select-panel"
                                            placeholder="----"
                                            [hideSingleSelectionIndicator]="true"
                                            (selectionChange)="changeSelectedTime($event)">
                                            @for (time of times; track time) {
                                                <mat-option
                                                    [value]="time"
                                                    [disabled]="isPastHour | applyPure: { hourWithMinute: time, date: postDate }">
                                                    {{ time | formatTime }}
                                                </mat-option>
                                            }
                                        </mat-select>
                                        <app-input-text
                                            class="grow"
                                            [svgIcon]="SvgIcon.CLOCK"
                                            [errorMessage]="
                                                (isPastHour
                                                | applyPure
                                                    : {
                                                          hourWithMinute: newPostModalContext.postForm.get('date.postTime')?.value,
                                                          date: postDate,
                                                      })
                                                    ? ('common.invalid_time' | translate)
                                                    : ''
                                            "
                                            [formControlName]="'postTime'"
                                            [inputType]="'time'"></app-input-text>
                                    </div>
                                </div>
                            }
                        </section>
                    }
                </form>
                @if (!FROM_DUPLICATE) {
                    <div>
                        <div class="mt-4 flex items-center gap-x-2">
                            <mat-checkbox
                                data-testid="seo-duplicate-btn"
                                color="primary"
                                [checked]="shouldDuplicatePost"
                                (change)="toggleShouldDuplicatePost()">
                            </mat-checkbox>
                            <span class="malou-text-10--regular text-malou-color-text-1">{{
                                'posts.new_post.duplicate_in' | translate
                            }}</span>
                        </div>
                    </div>
                }
            </form>
        </div>
        <div class="sticky bottom-0 w-full bg-white p-4">
            <ng-container *ngTemplateOutlet="formFooter"></ng-container>
        </div>
    </div>
</ng-template>
<ng-template #feedbacks>
    @if (isSmallScreen()) {
        <app-post-caption-ai-generation
            class="absolute right-0 top-0"
            [(lastPrompt)]="newPostModalAiContext.lastPrompt"
            [(isGenerating)]="newPostModalAiContext.isGeneratingPostTextFromAI"
            [isSmallScreen]="true"
            [defaultPrompt]="newPostModalAiContext.defaultPrompt()"
            [promptFormControl]="newPostModalAiContext.aiTextGenerationFormControl"
            [displayState]="newPostModalAiContext.postCaptionAiGenerationDisplayState()"
            [isSeo]="true"
            (onClose)="newPostModalAiContext.closePostCaptionAiGeneration()"
            (onGeneratePromptClick)="
                newPostModalAiContext.onGeneratePromptChange($event, newPostModalAiContext.aiTextGenerationFormControl.value!)
            ">
        </app-post-caption-ai-generation>
    }
    <div
        class="section-size malou-color-background-light relative flex-3 overflow-hidden px-4 pt-2 md:overflow-visible"
        [hidden]="!(showFeedbackPanel$ | async)">
        <app-feedbacks-panel [post]="newPostModalContext.currentPost()!" [restaurantManagers]="restaurantManagers"></app-feedbacks-panel>
        @if (!isSmallScreen()) {
            <app-post-caption-ai-generation
                [(lastPrompt)]="newPostModalAiContext.lastPrompt"
                [(isGenerating)]="newPostModalAiContext.isGeneratingPostTextFromAI"
                [isSmallScreen]="false"
                [defaultPrompt]="newPostModalAiContext.defaultPrompt()"
                [promptFormControl]="newPostModalAiContext.aiTextGenerationFormControl"
                [displayState]="newPostModalAiContext.postCaptionAiGenerationDisplayState()"
                [isSeo]="true"
                (onClose)="newPostModalAiContext.closePostCaptionAiGeneration()"
                (onGeneratePromptClick)="
                    newPostModalAiContext.onGeneratePromptChange($event, newPostModalAiContext.aiTextGenerationFormControl.value!)
                ">
            </app-post-caption-ai-generation>
        }
    </div>
</ng-template>

<ng-template #formFooter>
    <div class="malou-text-12--regular flex w-full items-center justify-between text-malou-color-state-success md:hidden">
        @switch (autoSaveState) {
            @case (AutoSaveStates.SAVING) {
                <div class="flex items-center gap-x-2">
                    <mat-spinner class="stroke-malou-color-text-2" diameter="16"></mat-spinner>
                    <span class="text-malou-color-text-2">{{ 'social_posts.new_social_post.autosave_state_saving' | translate }}</span>
                </div>
            }
            @case (AutoSaveStates.SAVED) {
                <div class="flex gap-x-2" data-cy="autoSaveStatusDiv">
                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    <span>{{ 'social_posts.new_social_post.autosave_state_saving' | translate }}</span>
                </div>
            }
            @default {
                <div class="flex gap-x-2">
                    <mat-icon class="malou-color-state-error !h-4 !w-4" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    <span class="malou-color-state-error">{{ 'social_posts.new_social_post.autosave_state_error' | translate }}</span>
                </div>
            }
        }
        <div class="flex items-center gap-x-4">
            <button class="malou-btn-raised--secondary btn-xl !h-12.5 md:grow" mat-raised-button (click)="openSaveBeforeClose()">
                {{ 'common.cancel' | translate }}
            </button>
            <div class="wrapper relative">
                @if (formErrors.length) {
                    <ng-container [ngTemplateOutlet]="formErrorTooltip"></ng-container>
                }
                <button
                    class="malou-btn-raised--primary btn-xl md:grow !h-12.5 {{
                        getTrackingNameFromPublishButtonType
                            | applyPure: this.newPostModalContext.postForm.controls.date.controls.postDateStatus.value
                    }}"
                    data-testid="social-post-save-btn"
                    mat-raised-button
                    [disabled]="!canPublish()"
                    (click)="save()">
                    {{ getPublishButtonText() }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #formErrorTooltip>
    <div class="form-errors-tooltip flex flex-col gap-y-3">
        @for (error of formErrors; track error) {
            <span class="text-white">- {{ error }}</span>
        }
    </div>
</ng-template>

<ng-template #shimmer>
    <div class="flex h-full w-full gap-x-5">
        <div class="flex h-full flex-3 flex-col pl-6.5 pt-6.5">
            <app-skeleton
                skeletonClass="secondary-bg w-[50px] h-[50px]"
                flexDirection="flex-row"
                gapClass="gap-x-3"
                [count]="4"></app-skeleton>
            <div class="mt-5 grow">
                <app-skeleton skeletonClass="secondary-bg w-full h-full"></app-skeleton>
            </div>
            <app-skeleton skeletonClass="secondary-bg p-3 my-3">
                <app-skeleton skeletonClass="w-[70px] h-[70px]" flexDirection="flex-row" gapClass="gap-x-3" [count]="4"></app-skeleton>
            </app-skeleton>
        </div>
        <div class="flex-6 pt-6.5">
            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[150px] w-full mt-5" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[50px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[170px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <app-skeleton skeletonClass="secondary-bg h-[100px] w-full mt-3" [useContainer]="false"></app-skeleton>

            <div class="mt-7 flex w-full justify-end">
                <app-skeleton skeletonClass="secondary-bg h-[50px] w-[200px]"></app-skeleton>
            </div>
        </div>
        <div class="h-full flex-2">
            <app-skeleton skeletonClass="secondary-bg w-full h-full">
                <div class="h-full w-full p-6.5">
                    <div class="flex w-full items-center justify-between">
                        <app-skeleton skeletonClass="h-[30px] w-[170px]"></app-skeleton>
                        <mat-icon
                            class="!h-4 !w-4 cursor-pointer"
                            color="primary"
                            [svgIcon]="SvgIcon.CROSS"
                            (click)="openSaveBeforeClose()"></mat-icon>
                    </div>
                    @for (item of [1, 2]; track item) {
                        <div class="mt-7">
                            <div class="flex w-full items-center gap-x-3">
                                <app-skeleton appearance="circle" skeletonClass="h-[35px] w-[35px] rounded-full"></app-skeleton>
                                <app-skeleton skeletonClass="h-[25px] w-[170px] "></app-skeleton>
                            </div>
                            <app-skeleton skeletonClass="h-[25px] w-[200px] mt-3"></app-skeleton>
                            <app-skeleton skeletonClass="h-[25px] w-[160px] mt-3"></app-skeleton>
                            <app-skeleton skeletonClass="h-[25px] w-[200px] mt-3"></app-skeleton>
                        </div>
                    }
                </div>
            </app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #stepButtons>
    <ng-container>
        <div class="absolute bottom-0 hidden w-full items-center justify-between gap-x-2 bg-white p-4 md:!flex">
            @if ((dialogStep$ | async) === DialogStep.ONE) {
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="openSaveBeforeClose()">
                    {{ 'common.cancel' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.TWO) {
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="dialogStep$.next(DialogStep.ONE)">
                    {{ 'common.previous' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.ONE) {
                <button
                    class="malou-btn-raised--primary !h-11 md:grow"
                    color="primary"
                    mat-raised-button
                    (click)="dialogStep$.next(DialogStep.TWO)">
                    {{ 'common.next' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.TWO) {
                <button
                    class="malou-btn-raised--primary !h-11 md:grow {{
                        getTrackingNameFromPublishButtonType
                            | applyPure: this.newPostModalContext.postForm.controls.date.controls.postDateStatus.value
                    }}"
                    data-testid="social-post-step2-save-btn"
                    mat-raised-button
                    [disabled]="!canPublish()"
                    (click)="save()">
                    {{ getPublishButtonText() }}
                </button>
            }
        </div>
    </ng-container>
</ng-template>
