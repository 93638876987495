<div class="malou-dialog !h-[90vh] md:absolute md:bottom-0 md:left-0 md:!h-[90vh] md:!w-[100vw]">
    <div class="malou-dialog__header">
        <span> {{ 'gallery.edit' | translate }} {{ this.data.name }} </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body flex flex-col overflow-hidden">
        <div class="flex gap-x-3">
            <div
                class="crop-options-container flex items-center"
                [matTooltip]="'gallery.crop' | translate"
                [ngClass]="{
                    'dimensions-toggled': dimensionsButtonToggled,
                    'cursor-pointer': !dimensionsButtonToggled,
                }"
                (click)="!dimensionsButtonToggled && (dimensionsButtonToggled = !dimensionsButtonToggled)">
                <mat-icon
                    class="z-10 !h-3.5 !w-3.5 cursor-pointer"
                    color="primary"
                    [svgIcon]="SvgIcon.DIMENSION"
                    (click)="$event.stopPropagation(); toggleDimension()"></mat-icon>
                <div class="crop-options malou-text-12--regular flex cursor-pointer items-center gap-x-2 text-malou-color-border-secondary">
                    <span class="selected-crop-option" id="square" (click)="changeAspectRatio($event)">
                        {{ 'edit_image.square' | translate }}
                    </span>
                    <span id="portrait" (click)="changeAspectRatio($event)">
                        {{ 'edit_image.portrait' | translate }}
                    </span>
                    <span id="landscape" (click)="changeAspectRatio($event)">
                        {{ 'edit_image.landscape' | translate }}
                    </span>
                </div>
            </div>
            <div
                class="crop-options-container flex items-center"
                [matTooltip]="'gallery.rotation' | translate"
                [ngClass]="{
                    'dimensions-toggled': rotationButtonToggled,
                    'cursor-pointer': !rotationButtonToggled,
                }"
                (click)="!rotationButtonToggled && (rotationButtonToggled = !rotationButtonToggled)">
                <mat-icon
                    class="z-10 !h-3.5 !w-3.5 cursor-pointer"
                    color="primary"
                    [svgIcon]="SvgIcon.ROTATION"
                    (click)="$event.stopPropagation(); toggleRotation()"></mat-icon>
                <div class="crop-options flex items-center gap-x-2">
                    <mat-slider class="malou-mat-slider--no-thumb" color="primary" min="0" max="360" step="1" #ngSlider>
                        <input matSliderThumb (input)="onSliderInput($event)" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="malou-text-12--regular text-malou-color-chart-purple">{{ ngSliderThumb.value }}°</div>
                </div>
            </div>
        </div>
        <div class="mt-10 w-full grow">
            @if (showDefault) {
                <div class="w-full">
                    <app-skeleton skeletonClass="h-[40vh] w-full"></app-skeleton>
                </div>
            }
            @if (showError) {
                <div style="max-width: 100%; max-height: 40vh">
                    <p>{{ 'edit_image.could_not_load' | translate }}</p>
                </div>
            }
            @if (!showError && !showDefault) {
                <image-cropper
                    class="p-0"
                    style="max-height: 55vh"
                    [ngClass]="{ 'only-rotate': state === 'rotate' }"
                    [transform]="{ rotate: rotation }"
                    [imageURL]="data.imgUrl + '?x-request=foo'"
                    [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="state === 'rotate'"
                    [aspectRatio]="ratio"
                    [format]="format"
                    [imageQuality]="100"
                    [alignImage]="'center'"
                    [resizeToWidth]="256"
                    [cropperMinWidth]="128"
                    [onlyScaleDown]="true"
                    (imageCropped)="setCroppedImage($event)"
                    (imageLoaded)="this.showDefault = false"
                    #cropper>
                </image-cropper>
            }
        </div>
    </div>
    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="close(true)">
            {{ 'common.save' | translate }}
        </button>
    </div>
</div>
