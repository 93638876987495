import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    model,
    OnInit,
    output,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatCalendar,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

import { MalouComparisonPeriod } from '@malou-io/package-utils';

import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { CustomMatCalendarHeaderComponent } from ':shared/components/custom-mat-calendar-header/custom-mat-calendar-header.component';
import { ComparisonPeriodSelectorComponent } from ':shared/components/grouped-date-filters-v2/compare-period-selector/comparison-period-selector.component';
import { CustomLuxonDateAdapter } from ':shared/components/input-date-picker/custom-luxon-date-adapter';
import { DatesAndPeriod, MalouDateFilters, MalouPeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

const acceptedPeriodOptions = [
    MalouPeriod.LAST_SEVEN_DAYS,
    MalouPeriod.LAST_THIRTY_DAYS,
    MalouPeriod.LAST_AND_COMING_THIRTY_DAYS,
    MalouPeriod.LAST_THREE_MONTHS,
    MalouPeriod.LAST_SIX_MONTHS,
    MalouPeriod.LAST_TWELVE_MONTHS,
    MalouPeriod.ALL,
];

export interface FilterOption {
    label: string;
    key: string;
}

export interface BasicFilters {
    startDate: Date;
    endDate: Date;
    sortBy: string;
    sortOrder: number;
}

@Component({
    selector: 'app-grouped-date-filters-v2',
    templateUrl: './grouped-date-filters.component.html',
    styleUrls: ['./grouped-date-filters.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        {
            provide: DateAdapter,
            useClass: CustomLuxonDateAdapter,
        },
    ],
    standalone: true,
    imports: [
        MatRadioModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        NgClass,
        ApplyPurePipe,
        MatDatepickerModule,
        TranslateModule,
        MatExpansionModule,
        ComparisonPeriodSelectorComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedDateFiltersV2Component implements OnInit {
    readonly startDateCalendar = viewChild<MatCalendar<DateTime>>('startCalendar');
    readonly endDateCalendar = viewChild<MatCalendar<DateTime>>('endCalendar');
    readonly datePickerMenuTrigger = viewChild<MatMenuTrigger>('datepickermenutrigger');

    readonly haveMaxDate = input<boolean>(true);
    readonly startDate = model<Date | null>(null);
    readonly endDate = model<Date | null>(null);
    readonly defaultPeriod = input<MalouPeriod | undefined>(MalouPeriod.LAST_SIX_MONTHS);
    readonly periodOptions = input<MalouPeriod[]>([MalouPeriod.LAST_SIX_MONTHS]);
    readonly withLabel = input<boolean>(true);
    readonly blockFutureDates = input<boolean>(false);
    readonly placeholder = input<string>('');
    readonly isLeftMaxDateToday = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly shouldShowComparisonPeriodSelector = input<boolean>(false);
    readonly restaurantStartDate = input<Date | null>();

    readonly chooseBoundaryDateAndComparisonPeriod = output<{ datesAndPeriod: DatesAndPeriod; comparisonPeriod: MalouComparisonPeriod }>();

    private readonly _screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly customHeaderComponent = CustomMatCalendarHeaderComponent;
    readonly minDate = DateTime.now().minus({ months: 18 }).toJSDate();

    // Save entry state
    readonly shouldSaveEntryState = signal(true);
    readonly savedDateState: WritableSignal<{
        startDate: Date | null;
        endDate: Date | null;
        defaultPeriod: MalouPeriod | undefined;
    }> = signal({
        startDate: null,
        endDate: null,
        defaultPeriod: undefined,
    });

    readonly selectedPeriod = signal<MalouPeriod>(MalouPeriod.LAST_SIX_MONTHS);
    readonly selectedRange = signal<DateRange<DateTime> | null>(null);
    readonly startAtNextMonth = signal<DateTime>(DateTime.now().plus({ months: 1 }).startOf('month'));
    readonly selectedComparisonPeriod = signal<MalouComparisonPeriod>(MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly currentPeriodOptions = computed(() =>
        this.periodOptions().filter((periodOption) => acceptedPeriodOptions.includes(periodOption))
    );
    readonly maxDate = computed(() => (this.haveMaxDate() ? new Date() : null));
    readonly isSmallScreen = toSignal(this._screenSizeService.resize$.pipe(map((elt) => elt.size === ScreenSize.IsSmallScreen)), {
        initialValue: this._screenSizeService.isPhoneScreen,
    });
    readonly maxRightDate = computed(() => (this.blockFutureDates() ? DateTime.now() : undefined));
    readonly calendarStartAt = computed(() => {
        const selectedRange = this.selectedRange();
        return selectedRange ? selectedRange.end : this.startAtNextMonth();
    });
    readonly canShowComparisonPeriodSelector = computed(
        () => this.startDate() && this.endDate() && this.shouldShowComparisonPeriodSelector()
    );

    readonly selectedDatesPeriod = computed(() => {
        const startDate = this.startDate();
        const endDate = this.endDate();
        if (!startDate || !endDate) {
            return this.placeholder();
        }
        return `${DateTime.fromJSDate(startDate).toLocaleString(DateTime.DATE_MED)}-${DateTime.fromJSDate(endDate).toLocaleString(
            DateTime.DATE_MED
        )}`;
    });

    constructor() {
        effect(
            () => {
                if (this.shouldSaveEntryState() && this.endDate() && this.startDate()) {
                    this.selectedPeriod.set(this.defaultPeriod() ?? MalouPeriod.LAST_SIX_MONTHS);
                    this._updatedSavedState();
                    this.shouldSaveEntryState.set(false);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        let startDate = this.startDate();
        if (!startDate) {
            startDate = DateTime.local().minus({ months: 1 }).toJSDate();
            this.startDate.set(startDate);
        }
        this.selectedRange.set(
            new DateRange(
                DateTime.fromJSDate(startDate),
                DateTime.fromJSDate(this.endDate() ?? DateTime.fromJSDate(startDate).plus({ months: 1 }).toJSDate())
            )
        );
        this.startAtNextMonth.set(DateTime.fromJSDate(startDate).plus({ months: 1 }).startOf('month'));
        this.selectedPeriod.set(this.defaultPeriod() ?? MalouPeriod.LAST_SIX_MONTHS);
    }

    maxLeftDate = (): DateTime | null => {
        if (this.isSmallScreen()) {
            return null;
        }
        return this.isLeftMaxDateToday() ? DateTime.now() : this.endDateCalendar()?.activeDate?.startOf('month') || null;
    };

    minRightDate = (): DateTime | null => this.startDateCalendar()?.activeDate?.plus({ months: 1 }).startOf('month') || null;

    changeDate(date: DateTime): void {
        this._resetSelectedRange();
        this.selectedPeriod.set(MalouPeriod.CUSTOM);
        const startDate = this.startDate();
        const endDate = this.endDate();
        const hasNoStartDate = !startDate;
        const alreadyHadFullRange = !!startDate && !!endDate;
        const isBeforePreviousStartDate = !!startDate && date.toMillis() < startDate.getTime();
        if (hasNoStartDate || alreadyHadFullRange || isBeforePreviousStartDate) {
            const newStartDate = date.toJSDate();
            this.startDate.set(newStartDate);
            this.endDate.set(null);
            this.selectedRange.set(new DateRange(DateTime.fromJSDate(newStartDate), null));
        } else {
            const newEndDate = date.toJSDate();
            this.endDate.set(newEndDate);
            this.selectedRange.set(new DateRange(DateTime.fromJSDate(startDate), DateTime.fromJSDate(newEndDate)));
            if (!this.shouldShowComparisonPeriodSelector()) {
                this.validateDates();
            }
        }
    }

    emitChangePeriod(lastKey: MalouPeriod): void {
        const { period, startDate, endDate } = this._getFilter(lastKey);
        this.selectedPeriod.set(period);
        const newStartDate = startDate ?? this.minDate;
        this.startDate.set(newStartDate);
        this.endDate.set(endDate ?? this.maxDate());
        this.selectedRange.set(
            new DateRange(
                DateTime.fromJSDate(newStartDate),
                DateTime.fromJSDate(this.endDate() ?? DateTime.now().plus({ years: 1 }).toJSDate())
            )
        );
        if (!this.shouldShowComparisonPeriodSelector()) {
            this.validateDates();
        }
        this._goToMonthDate();
    }

    validateDates(isClickFromOutside?: boolean): void {
        if (!isClickFromOutside) {
            this.datePickerMenuTrigger()?.closeMenu();
        }
        this._updatedSavedState();
        this._emitBoundaryDate();
    }

    cancelDates(): void {
        this.resetDates();
        this.datePickerMenuTrigger()?.closeMenu();
    }

    resetDates(): void {
        this.startDate.set(this.savedDateState().startDate);
        this.endDate.set(this.savedDateState().endDate);
        this.selectedPeriod.set(this.savedDateState().defaultPeriod ?? MalouPeriod.LAST_SIX_MONTHS);
        this.selectedRange.set(
            new DateRange(
                DateTime.fromJSDate(this.startDate() ?? this.minDate),
                DateTime.fromJSDate(this.endDate() ?? DateTime.now().plus({ years: 1 }).toJSDate())
            )
        );
        this._goToMonthDate();
    }

    onComparisonPeriodChange(value: MalouComparisonPeriod): void {
        this.selectedComparisonPeriod.set(value);
    }

    private _resetSelectedRange(): void {
        this.selectedRange.set(null);
    }

    private _emitBoundaryDate(): void {
        const startDate = DateTime.fromJSDate(this.startDate() ?? this.minDate)
            .startOf('day')
            .toJSDate();
        const endDate = DateTime.fromJSDate(
            this.endDate() ??
                this.maxDate() ??
                DateTime.fromJSDate(this.startDate() ?? this.minDate)
                    .plus({ months: 1 })
                    .toJSDate()
        )
            .endOf('day')
            .toJSDate();
        this.chooseBoundaryDateAndComparisonPeriod.emit({
            datesAndPeriod: {
                startDate,
                endDate,
                period: this.selectedPeriod() ?? MalouPeriod.CUSTOM,
            },
            comparisonPeriod: this.selectedComparisonPeriod(),
        });
    }

    private _goToMonthDate(): void {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const startDate = this.startDate();
        const endDate = this.endDate();
        if (startDate && !(startDate.getFullYear() === currentYear && startDate.getMonth() === currentMonth)) {
            this.startDateCalendar()?._goToDateInView(DateTime.fromJSDate(startDate), 'month'); // Goes to the date's month view
        }
        if (endDate && endDate.getMonth() + 1 !== this.endDateCalendar()?.activeDate.month) {
            this.endDateCalendar()?._goToDateInView(DateTime.fromJSDate(endDate).minus({ months: 1 }), 'month');
        }
    }

    private _getFilter(period: MalouPeriod): DatesAndPeriod {
        const malouDateFilters = new MalouDateFilters();
        if (period === MalouPeriod.CUSTOM) {
            return malouDateFilters.getFilter({
                period: MalouPeriod.CUSTOM,
                startDate: this.startDate() as Date,
                endDate: this.endDate() as Date,
            });
        }
        return malouDateFilters.getFilter({ period });
    }

    private _updatedSavedState(): void {
        this.savedDateState.set({
            startDate: this.startDate(),
            endDate: this.endDate(),
            defaultPeriod: this.selectedPeriod(),
        });
    }
}
