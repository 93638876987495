import { inject, Injectable, Signal, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, mergeMap, Observable, Subject, tap } from 'rxjs';

import { GetMediaForEditionResponseDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';

// Add this service to the provider array of your component
@Injectable()
export class MediaUploaderService {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _mediaService = inject(MediaService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    private readonly _fileSubject = new Subject<File>();
    private readonly _fromGalleryMediaIdSubject = new Subject<string>();
    private _onSuccess$ = new Subject<GetMediaForEditionResponseDto>();

    private readonly _duplicateMediaMergeMap = mergeMap((mediaId: string) => this._mediaService.duplicateMedia({ mediaId }));
    private readonly _getMediaForEditionMergeMap = mergeMap(({ data: { duplicatedMediaId } }) =>
        this._mediaService.getMediaForEdition(duplicatedMediaId)
    );
    private readonly _subscribeFns = {
        next: (res: ApiResultV2<GetMediaForEditionResponseDto>): void => {
            this._uploadingMediaCount.update((value) => --value);
            this._onSuccess$.next(res.data);
        },
        error: (err: unknown): void => {
            console.warn(err);
            this._uploadingMediaCount.set(0);
            this._toastService.openErrorToast(this._translateService.instant('social_post_medias.upload_error'));
            this._listenToFileSubject();
        },
    };

    private readonly _uploadingMediaCount = signal(0);

    constructor() {
        this._listenToFileSubject();
        this._listenToFromGalleryMediaIdSubject();
    }

    private _listenToFileSubject(): void {
        this._fileSubject
            .pipe(
                tap(() => this._uploadingMediaCount.update((value) => ++value)),
                concatMap((file) =>
                    this._mediaService.uploadV2({
                        file,
                        restaurantId: this._restaurantsService.currentRestaurant._id,
                        onProgress: () => {},
                    })
                ),
                map((result) => {
                    if (result.success === false) {
                        throw new Error('result.success is false');
                    }
                    return result.mediaId;
                }),
                this._duplicateMediaMergeMap,
                this._getMediaForEditionMergeMap
            )
            .subscribe(this._subscribeFns);
    }

    private _listenToFromGalleryMediaIdSubject(): void {
        this._fromGalleryMediaIdSubject
            .pipe(
                tap(() => this._uploadingMediaCount.update((value) => ++value)),
                this._duplicateMediaMergeMap,
                this._getMediaForEditionMergeMap
            )
            .subscribe(this._subscribeFns);
    }

    uploadFromFile(file: File): void {
        this._fileSubject.next(file);
    }

    uploadFromGalleryMediaId(mediaId: string): void {
        this._fromGalleryMediaIdSubject.next(mediaId);
    }

    onSuccess(): Observable<GetMediaForEditionResponseDto> {
        return this._onSuccess$.asObservable();
    }

    uploadingMediaCount(): Signal<number> {
        return this._uploadingMediaCount.asReadonly();
    }
}
