import { Routes } from '@angular/router';

import { BoostersPresentationComponent } from ':modules/boosters/boosters-presentation/boosters-presentation.component';

export const BOOSTERS_PRESENTATION_ROUTES: Routes = [
    {
        path: '',
        component: BoostersPresentationComponent,
    },
];
