import { z } from 'zod';

import {
    ApplicationLanguage,
    AspectRatio,
    FileFormat,
    MediaCategory,
    MediaConvertedStatus,
    MediaTagCategory,
    MediaTagSubcategory,
    MediaType,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const UrlsValidator = z.object({
    original: z.string().url(),
    small: z.string().url(),
    cover: z.union([z.null(), z.string().url()]).optional(),
    smallCover: z.union([z.null(), z.string().url()]).optional(),
    igFit: z.union([z.null(), z.string().url()]).optional(),
    id: z.union([z.null(), z.string()]).optional(),
});

export const CoverValidator = z.object({
    id: z.string(),
    urls: UrlsValidator,
});

export type UrlsDto = z.infer<typeof UrlsValidator>;
export type CoverDto = z.infer<typeof CoverValidator>;

export const aiTagValidator = z.object({
    tag: z.string(),
    language: z.nativeEnum(ApplicationLanguage),
    category: z.nativeEnum(MediaTagCategory),
    subcategory: z.nativeEnum(MediaTagSubcategory).nullish(),
});

export type AiTagDto = z.infer<typeof aiTagValidator>;

export const mediaValidator = z
    .object({
        id: objectIdValidator,
        postIds: z.array(objectIdValidator).optional(),
        restaurantId: objectIdValidator.nullish(),
        duplicatedFromRestaurantId: objectIdValidator.nullish(),
        folderId: objectIdValidator.nullish(),
        userId: objectIdValidator.nullish(),
        name: z.string().nullish(),
        thumbnail: z.string().nullish(),
        title: z.string().nullish(),
        description: z.string().nullish(),
        category: z.nativeEnum(MediaCategory),
        format: z.nativeEnum(FileFormat),
        type: z.nativeEnum(MediaType),
        duration: z.number().optional(),
        urls: z.object({
            original: z.string().nullish(),
            small: z.string().nullish(),
            cover: z.string().nullish(),
            smallCover: z.string().nullish(),
            igFit: z.string().nullish(),
        }),
        sizes: z
            .object({
                original: z.number().nullish(),
                small: z.number().nullish(),
                cover: z.number().nullish(),
                smallCover: z.number().nullish(),
                igFit: z.number().nullish(),
            })
            .optional(),
        tagIds: z.array(objectIdValidator).optional(),
        originalMediaId: objectIdValidator.nullish(),
        socialId: z.string().optional(),
        dimensions: z
            .object({
                original: z.object({ width: z.number(), height: z.number() }).optional(),
                small: z.object({ width: z.number(), height: z.number() }).optional(),
                cover: z.object({ width: z.number(), height: z.number() }).optional(),
                smallCover: z.object({ width: z.number(), height: z.number() }).optional(),
                igFit: z.object({ width: z.number(), height: z.number() }).optional(),
            })
            .optional(),
        viewCount: z.number().optional(),
        convertedStatus: z.nativeEnum(MediaConvertedStatus).nullable().optional(),
        createdAt: z.string().datetime().optional(),
        updatedAt: z.string().datetime().optional(),
        aiDescription: z.string().nullish(),
        aiTags: z.array(aiTagValidator).nullish(),
        hasDisplayedText: z.boolean().nullish(),
        resizeMetadata: z
            .object({
                width: z.number(),
                height: z.number(),
                aspectRatio: z.number(),
                cropPosition: z.object({ left: z.number(), top: z.number() }),
            })
            .optional(),
    })
    .transform((data) => ({
        ...data,
        createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
        updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
    }));

export const lightMediaValidator = z.object({
    _id: objectIdValidator,
    urls: z.object({
        original: z.string().nullish(),
        small: z.string().nullish(),
        cover: z.string().nullish(),
        smallCover: z.string().nullish(),
        igFit: z.string().nullish(),
    }),
});

export interface MediaDimensionDto {
    width: number;
    height: number;
}

export interface MediaTransformDataDto {
    aspectRatio: AspectRatio;
    rotationInDegrees: number;
    left: number;
    top: number;
    width: number;
    height: number;
}
