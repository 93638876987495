import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import { AggregationTimeScale, InsightsChart, MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { InsightsService } from ':modules/statistics/insights.service';
import { GmbActionsV2Component } from ':modules/statistics/seo/gmb-insights/gmb-actions-v2/gmb-actions-v2.component';
import { GmbImpressionsV2Component } from ':modules/statistics/seo/gmb-insights/gmb-impressions-v2/gmb-impressions-v2.component';
import { GmbInsightsChartData } from ':modules/statistics/seo/models/gmb-insight-chart-data';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ViewBy } from ':shared/enums/view-by.enum';
import { GMB_METRICS, GmbInsights, KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant, TimeScaleToMetricToDataValues } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const GMB_DATA_FETCH_DELAY_IN_DAYS = 4;

@Component({
    selector: 'app-gmb-insights',
    standalone: true,
    imports: [
        IllustrationPathResolverPipe,
        TranslateModule,
        SkeletonComponent,
        NgTemplateOutlet,
        GmbActionsV2Component,
        GmbImpressionsV2Component,
        MatIconModule,
        MatTooltipModule,
    ],
    templateUrl: './gmb-insights.component.html',
    styleUrl: './gmb-insights.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class GmbInsightsComponent implements OnInit, KillSubscriptions {
    readonly shouldShowImpressionsChart = input<boolean | undefined>(true);
    readonly shouldShowActionsChart = input<boolean | undefined>(true);
    readonly actionsChartViewBy = input<ViewBy | undefined>(ViewBy.DAY);
    readonly impressionsChartViewBy = input<ViewBy | undefined>(ViewBy.DAY);
    readonly actionsChartHiddenDatasetIndexes = input<number[]>([]);
    readonly impressionsChartHiddenDatasetIndexes = input<number[]>([]);
    readonly showViewByTextInsteadOfSelectorImpressions = input<boolean | undefined>(false);
    readonly showViewByTextInsteadOfSelectorActions = input<boolean | undefined>(false);

    readonly isLoadingEvent = output<boolean>();
    readonly viewByChange = output<{ chart: InsightsChart; viewBy: ViewBy }>();
    readonly hiddenDatasetIndexesChange = output<{ chart: InsightsChart; hiddenDatasetIndexes: number[] }>();

    private readonly _insightsService = inject(InsightsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly Illustration = Illustration;
    readonly SvgIcon = SvgIcon;
    readonly DATE_NOW_MINUS_4_DAYS = DateTime.now().minus({ days: GMB_DATA_FETCH_DELAY_IN_DAYS }).toJSDate();
    readonly InsightsChart = InsightsChart;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter).pipe(distinctUntilChanged());
    readonly comparisonPeriod$: Observable<MalouComparisonPeriod> = this._store
        .select(StatisticsSelector.selectComparisonPeriodFilter)
        .pipe(distinctUntilChanged());
    readonly isGmbConnected = toSignal(
        this._store
            .select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB }))
            .pipe(map((platform) => !!platform?.credentials?.length && platform.credentials.length > 0))
    );
    readonly hasFoundGmbInsightsData = signal(true);

    isLoading = signal(true);

    readonly dailyInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);
    readonly dailyPreviousInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);
    readonly weeklyInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);
    readonly weeklyPreviousInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);
    readonly monthlyInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);
    readonly monthlyPreviousInsightsChartData = signal<GmbInsightsChartData>({} as GmbInsightsChartData);

    // TODO: Remove when the feature flag is removed
    readonly isReleaseNewCalendarEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-statistics-new-calendar'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-statistics-new-calendar'),
    });

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.comparisonPeriod$])
            .pipe(
                tap(() => {
                    this.isLoadingEvent.emit(true);
                    this.isLoading.set(true);
                }),
                filter(([restaurant, _datesAndPeriod]) => !!restaurant),
                map(([restaurant, datesAndPeriod, comparisonPeriod]: [Restaurant, DatesAndPeriod, MalouComparisonPeriod]) => {
                    const { startDate, endDate } = this._adjustEndDateBasedOnDelay(datesAndPeriod);
                    return [restaurant, { startDate, endDate }, comparisonPeriod];
                }),
                switchMap(
                    ([restaurant, dates, comparisonPeriod]: [Restaurant, { startDate: Date; endDate: Date }, MalouComparisonPeriod]) => {
                        const requestBody = {
                            restaurantIds: [restaurant._id],
                            platformKeys: [PlatformKey.GMB],
                            startDate: dates.startDate.toISOString(),
                            endDate: dates.endDate.toISOString(),
                            metrics: GMB_METRICS.map(({ metric }) => metric),
                        };

                        return forkJoin([
                            this._insightsService.getStoredInsights(requestBody),
                            this._insightsService.getStoredInsights({
                                ...requestBody,
                                comparisonPeriod,
                            }),
                            of(restaurant._id),
                        ]);
                    }
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([currentInsights, previousInsights, restaurantId]) => {
                    this.isLoadingEvent.emit(false);
                    this.isLoading.set(false);

                    const currentData = currentInsights.data?.[restaurantId]?.[PlatformKey.GMB];
                    const previousData = previousInsights.data?.[restaurantId]?.[PlatformKey.GMB];
                    this._setActionStoreData(currentData);

                    if (currentData?.hasData && previousData?.hasData) {
                        this.dailyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_DAY)
                        );
                        this.dailyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_DAY)
                        );
                        this.weeklyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_WEEK)
                        );
                        this.weeklyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_WEEK)
                        );
                        this.monthlyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_MONTH)
                        );
                        this.monthlyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_MONTH)
                        );
                    } else {
                        this.hasFoundGmbInsightsData.set(false);
                    }
                },
                error: () => {
                    this.isLoadingEvent.emit(false);
                    this.isLoading.set(false);
                },
            });
    }

    emitViewByChange(chart: InsightsChart, viewBy: ViewBy): void {
        this.viewByChange.emit({ chart, viewBy });
    }

    emitHiddenDatasetIndexesChange(chart: InsightsChart, hiddenDatasetIndexes: number[]): void {
        this.hiddenDatasetIndexesChange.emit({ chart, hiddenDatasetIndexes });
    }

    private _getInsightsChartDataByTimeScale(
        data: GmbInsights,
        restaurantId: string,
        aggregationTimeScale: AggregationTimeScale
    ): GmbInsightsChartData {
        let startDate = data.startDate as string;
        let endDate = data.endDate as string;
        const comparisonPeriod = data.comparisonPeriod;
        if (comparisonPeriod && comparisonPeriod !== MalouComparisonPeriod.PREVIOUS_PERIOD) {
            startDate = data[restaurantId]!.dateFilters!.startDate as string;
            endDate = data[restaurantId]!.dateFilters!.endDate as string;
        }
        return new GmbInsightsChartData({
            data: data[restaurantId]![PlatformKey.GMB]!.insights,
            startDate,
            endDate,
            aggregationTimeScale,
            comparisonPeriod,
        });
    }

    private _adjustEndDateBasedOnDelay(datesAndPeriod: DatesAndPeriod): { startDate: Date; endDate: Date } {
        const { startDate, endDate } = datesAndPeriod as { startDate: Date; endDate: Date };

        const delayDate = DateTime.now().minus({ days: GMB_DATA_FETCH_DELAY_IN_DAYS });
        const newEndDate = DateTime.fromJSDate(endDate) < delayDate ? endDate : delayDate.toJSDate();
        return { startDate, endDate: newEndDate };
    }

    private _setActionStoreData(currentData: DailyPlatformInsights | undefined): void {
        const insightsByDay = currentData?.insights;
        if (!insightsByDay) {
            return;
        }
        const data: TimeScaleToMetricToDataValues = {
            [AggregationTimeScale.BY_DAY]: {},
        };

        for (const [metric, values] of Object.entries(insightsByDay)) {
            data[AggregationTimeScale.BY_DAY]![metric] = Object.entries(values).map(([date, value]) => ({
                date,
                value,
            }));
        }

        this._store.dispatch(StatisticsActions.editActionsRawData({ data }));
    }
}
