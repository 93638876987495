import { NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, input, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { aspectRatioToNumberMap } from '@malou-io/package-utils';

import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-instagram-preview',
    templateUrl: './instagram-preview.component.html',
    styleUrls: ['./instagram-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgStyle, MatIconModule, TranslateModule, ImageViewerComponent, HtmlTagPipe, ImagePathResolverPipe],
})
export class InstagramPreviewComponent implements AfterViewInit {
    readonly text = input<string>('');
    readonly medias = input<EditionMedia[]>([]);
    readonly username = input<string>('');
    readonly profilePicture = input<string | undefined>();
    readonly hashtags = input<string[]>([]);

    readonly slider = viewChild<HTMLDivElement>('slider');

    readonly usernameWithoutAt = computed(() => this.username().replace('@', ''));

    readonly currentMediaIndex = signal(0);

    readonly aspectRatioStyle = computed(() => {
        const medias = this.medias();
        const index = medias.length - 1;

        let aspectRatio = 1;
        if (index !== -1 && medias[index]) {
            const aspectRatioFromTransformData = aspectRatioToNumberMap[medias[index].transformData?.aspectRatio];
            if (aspectRatioFromTransformData) {
                aspectRatio = aspectRatioFromTransformData;
            } else {
                aspectRatio = medias[index].aspectRatio ?? 1;
            }
        }
        return {
            aspectRatio,
        };
    });

    readonly formattedText = computed(() => this._formatText(this.text(), this.hashtags()));

    readonly isCarousel = computed(() => this.medias().length > 1);

    readonly SvgIcon = SvgIcon;
    readonly IMG_ID_PREFIX = 'instagram-preview-img-';

    constructor() {
        effect(
            () => {
                const medias = this.medias();
                this.currentMediaIndex.update((index) => Math.max(Math.min(index, medias.length - 1), 0));
                this._scrollToCurrentMedia();
            },
            { allowSignalWrites: true }
        );
    }

    ngAfterViewInit(): void {
        this._scrollToCurrentMedia('instant');
    }

    nextMedia(): void {
        this.currentMediaIndex.update((currentValue) => currentValue + 1);
        this._scrollToCurrentMedia();
    }

    previousMedia(): void {
        this.currentMediaIndex.update((currentValue) => currentValue - 1);
        this._scrollToCurrentMedia();
    }

    private _scrollToCurrentMedia(behavior?: ScrollBehavior): void {
        const currentMediaIndex = this.currentMediaIndex();
        const mediaElement = document.getElementById(`${this.IMG_ID_PREFIX}${currentMediaIndex}`);
        if (mediaElement) {
            mediaElement.scrollIntoView({ behavior: behavior ?? 'smooth', block: 'nearest', inline: 'center' });
        }
    }

    private _formatText(text: string, hashtags: string[]): string {
        const textWords = text.split(' ');

        const instagramMentionsAndHashtagsColor = '#00376b';
        const textWithAndMentions = textWords
            .map((word) =>
                word.startsWith('@') && word.length > 1 ? `<span style="color: ${instagramMentionsAndHashtagsColor}">${word}</span>` : word
            )
            .join(' ');

        const textWithLineBreaks = textWithAndMentions.replace(/\n/g, '<br />');

        if (hashtags.length === 0) {
            return textWithLineBreaks;
        }

        const hashtagsText = hashtags.join(' ');
        const hashtagsWithColor = `<span style="color: ${instagramMentionsAndHashtagsColor};">${hashtagsText}</span>`;
        return `${textWithLineBreaks}<br><br>${hashtagsWithColor}`;
    }
}
