import { InvalidPlatformReason } from '../../constants';
import { GmbErrorCode } from '../platforms';

export interface InformationUpdateStateError {
    reason: InformationUpdateErrorReason;
    fields?: string[];
}

export enum InformationUpdateErrorReason {
    ERROR_CODE_UNSPECIFIED = 'ERROR_CODE_UNSPECIFIED',
    INVALID_ATTRIBUTE_NAME = 'INVALID_ATTRIBUTE_NAME',
    ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION = 'ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION',
    ASSOCIATE_LOCATION_INVALID_PLACE_ID = 'ASSOCIATE_LOCATION_INVALID_PLACE_ID',
    LAT_LNG_UPDATES_NOT_PERMITTED = 'LAT_LNG_UPDATES_NOT_PERMITTED',
    PO_BOX_IN_ADDRESS_NOT_ALLOWED = 'PO_BOX_IN_ADDRESS_NOT_ALLOWED',
    BLOCKED_REGION = 'BLOCKED_REGION',
    MISSING_BOTH_PHONE_AND_WEBSITE = 'MISSING_BOTH_PHONE_AND_WEBSITE',
    MISSING_STOREFRONT_ADDRESS_OR_SAB = 'MISSING_STOREFRONT_ADDRESS_OR_SAB',
    LAT_LNG_TOO_FAR_FROM_ADDRESS = 'LAT_LNG_TOO_FAR_FROM_ADDRESS',
    LAT_LNG_REQUIRED = 'LAT_LNG_REQUIRED',
    INVALID_CHARACTERS = 'INVALID_CHARACTERS',
    FORBIDDEN_WORDS = 'FORBIDDEN_WORDS',
    INVALID_INTERCHANGE_CHARACTERS = 'INVALID_INTERCHANGE_CHARACTERS',
    FIELDS_REQUIRED_FOR_CATEGORY = 'FIELDS_REQUIRED_FOR_CATEGORY',
    STOREFRONT_REQUIRED_FOR_CATEGORY = 'STOREFRONT_REQUIRED_FOR_CATEGORY',
    ADDRESS_MISSING_REGION_CODE = 'ADDRESS_MISSING_REGION_CODE',
    ADDRESS_EDIT_CHANGES_COUNTRY = 'ADDRESS_EDIT_CHANGES_COUNTRY',
    SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS = 'SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS',
    INVALID_TIME_SCHEDULE = 'INVALID_TIME_SCHEDULE',
    INVALID_HOURS_VALUE = 'INVALID_HOURS_VALUE',
    OVERLAPPED_SPECIAL_HOURS = 'OVERLAPPED_SPECIAL_HOURS',
    INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY = 'INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY',
    DUPLICATE_CHILDREN_LOCATIONS = 'DUPLICATE_CHILDREN_LOCATIONS',
    INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY = 'INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY',
    INVALID_SERVICE_AREA_PLACE_ID = 'INVALID_SERVICE_AREA_PLACE_ID',
    INVALID_AREA_TYPE_FOR_SERVICE_AREA = 'INVALID_AREA_TYPE_FOR_SERVICE_AREA',
    OPENING_DATE_TOO_FAR_IN_THE_FUTURE = 'OPENING_DATE_TOO_FAR_IN_THE_FUTURE',
    OPENING_DATE_MISSING_YEAR_OR_MONTH = 'OPENING_DATE_MISSING_YEAR_OR_MONTH',
    OPENING_DATE_BEFORE_1AD = 'OPENING_DATE_BEFORE_1AD',
    TOO_MANY_ENTRIES = 'TOO_MANY_ENTRIES',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
    INVALID_PHONE_NUMBER_FOR_REGION = 'INVALID_PHONE_NUMBER_FOR_REGION',
    MISSING_PRIMARY_PHONE_NUMBER = 'MISSING_PRIMARY_PHONE_NUMBER',
    THROTTLED = 'THROTTLED',
    UNSUPPORTED_POINT_RADIUS_SERVICE_AREA = 'UNSUPPORTED_POINT_RADIUS_SERVICE_AREA',
    INVALID_CATEGORY = 'INVALID_CATEGORY',
    CANNOT_REOPEN = 'CANNOT_REOPEN',
    INVALID_BUSINESS_OPENING_DATE = 'INVALID_BUSINESS_OPENING_DATE',
    INVALID_LATLNG = 'INVALID_LATLNG',
    PROFILE_DESCRIPTION_CONTAINS_URL = 'PROFILE_DESCRIPTION_CONTAINS_URL',
    LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION = 'LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION',
    INVALID_URL = 'INVALID_URL',
    INVALID_ADDRESS = 'INVALID_ADDRESS',
    PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF = 'PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF',
    RELATION_CANNOT_BE_THE_LOCATION_ITSELF = 'RELATION_CANNOT_BE_THE_LOCATION_ITSELF',
    MISSING_ADDRESS_COMPONENTS = 'MISSING_ADDRESS_COMPONENTS',
    READ_ONLY_ADDRESS_COMPONENTS = 'READ_ONLY_ADDRESS_COMPONENTS',
    STRING_TOO_LONG = 'STRING_TOO_LONG',
    STRING_TOO_SHORT = 'STRING_TOO_SHORT',
    REQUIRED_FIELD_MISSING_VALUE = 'REQUIRED_FIELD_MISSING_VALUE',
    ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED = 'ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED',
    ATTRIBUTE_INVALID_ENUM_VALUE = 'ATTRIBUTE_INVALID_ENUM_VALUE',
    ATTRIBUTE_NOT_AVAILABLE = 'ATTRIBUTE_NOT_AVAILABLE',
    ATTRIBUTE_CANNOT_BE_REPEATED = 'ATTRIBUTE_CANNOT_BE_REPEATED',
    ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY = 'ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY',
    ADDRESS_REMOVAL_NOT_ALLOWED = 'ADDRESS_REMOVAL_NOT_ALLOWED',
    AMBIGUOUS_TITLE = 'AMBIGUOUS_TITLE',
    INVALID_CATEGORY_FOR_SAB = 'INVALID_CATEGORY_FOR_SAB',
    RELATION_ENDPOINTS_TOO_FAR = 'RELATION_ENDPOINTS_TOO_FAR',
    INVALID_SERVICE_ITEM = 'INVALID_SERVICE_ITEM',
    SERVICE_ITEM_LABEL_NO_DISPLAY_NAME = 'SERVICE_ITEM_LABEL_NO_DISPLAY_NAME',
    SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME = 'SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME',
    SERVICE_ITEM_LABEL_INVALID_UTF8 = 'SERVICE_ITEM_LABEL_INVALID_UTF8',
    FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID = 'FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID',
    FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL = 'FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL',
    SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID = 'SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID',
    INVALID_LANGUAGE = 'INVALID_LANGUAGE',
    PRICE_CURRENCY_MISSING = 'PRICE_CURRENCY_MISSING',
    PRICE_CURRENCY_INVALID = 'PRICE_CURRENCY_INVALID',
    SERVICE_TYPE_ID_DUPLICATE = 'SERVICE_TYPE_ID_DUPLICATE',
    PIN_DROP_REQUIRED = 'PIN_DROP_REQUIRED',
    STALE_DATA = 'STALE_DATA',
    PHONE_NUMBER_EDITS_NOT_ALLOWED = 'PHONE_NUMBER_EDITS_NOT_ALLOWED',
    MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION = 'MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION',
    SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY = 'SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY',
    LINK_ALREADY_EXISTS = 'LINK_ALREADY_EXISTS',
    URL_PROVIDER_NOT_ALLOWED = 'URL_PROVIDER_NOT_ALLOWED',
    TOO_MANY_UPDATES = 'TOO_MANY_UPDATES',
    INVALID_SOCIAL_MEDIA_PROFILE_URL = 'INVALID_SOCIAL_MEDIA_PROFILE_URL',
    UNVERIFIED_LOCATION = 'UNVERIFIED_LOCATION',
    UNKNOWN = 'UNKNOWN',
    GMB_PLATFORM_DISCONNECTED = 'GMB_PLATFORM_DISCONNECTED',
    GMB_PLATFORM_DISABLED = 'GMB_PLATFORM_DISABLED',
    GMB_PLATFORM_SUSPENDED = 'GMB_SUSPENDED',
    GMB_PLATFORM_PENDING_VERIFICATION = 'GMB_PLATFORM_PENDING_VERIFICATION',
    GMB_PLATFORM_NOT_VERIFIED = 'GMB_PLATFORM_NOT_VERIFIED',
    IG_PLATFORM_NOT_FOUND = 'IG_PLATFORM_NOT_FOUND',
    IG_PLATFORM_NOT_BUSINESS = 'IG_PLATFORM_NOT_BUSINESS',
    TRIPADVISOR_NOT_MANAGER = 'TRIPADVISOR_NOT_MANAGER',
    TRIPADVISOR_UNCLAIMED_PAGE = 'TRIPADVISOR_UNCLAIMED_PAGE',
    NEED_REVIEW = 'NEED_REVIEW',
    INVALID_LOCATION_CATEGORY = 'INVALID_LOCATION_CATEGORY',
}

// Add other platform errors here
export const InformationUpdateErrorReasonMapping: Record<GmbErrorCode | InvalidPlatformReason, InformationUpdateErrorReason> = {
    [GmbErrorCode.ERROR_CODE_UNSPECIFIED]: InformationUpdateErrorReason.ERROR_CODE_UNSPECIFIED,
    [GmbErrorCode.INVALID_ATTRIBUTE_NAME]: InformationUpdateErrorReason.INVALID_ATTRIBUTE_NAME,
    [GmbErrorCode.ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION]: InformationUpdateErrorReason.ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION,
    [GmbErrorCode.ASSOCIATE_LOCATION_INVALID_PLACE_ID]: InformationUpdateErrorReason.ASSOCIATE_LOCATION_INVALID_PLACE_ID,
    [GmbErrorCode.LAT_LNG_UPDATES_NOT_PERMITTED]: InformationUpdateErrorReason.LAT_LNG_UPDATES_NOT_PERMITTED,
    [GmbErrorCode.PO_BOX_IN_ADDRESS_NOT_ALLOWED]: InformationUpdateErrorReason.PO_BOX_IN_ADDRESS_NOT_ALLOWED,
    [GmbErrorCode.BLOCKED_REGION]: InformationUpdateErrorReason.BLOCKED_REGION,
    [GmbErrorCode.MISSING_BOTH_PHONE_AND_WEBSITE]: InformationUpdateErrorReason.MISSING_BOTH_PHONE_AND_WEBSITE,
    [GmbErrorCode.MISSING_STOREFRONT_ADDRESS_OR_SAB]: InformationUpdateErrorReason.MISSING_STOREFRONT_ADDRESS_OR_SAB,
    [GmbErrorCode.LAT_LNG_TOO_FAR_FROM_ADDRESS]: InformationUpdateErrorReason.LAT_LNG_TOO_FAR_FROM_ADDRESS,
    [GmbErrorCode.LAT_LNG_REQUIRED]: InformationUpdateErrorReason.LAT_LNG_REQUIRED,
    [GmbErrorCode.INVALID_CHARACTERS]: InformationUpdateErrorReason.INVALID_CHARACTERS,
    [GmbErrorCode.FORBIDDEN_WORDS]: InformationUpdateErrorReason.FORBIDDEN_WORDS,
    [GmbErrorCode.INVALID_INTERCHANGE_CHARACTERS]: InformationUpdateErrorReason.INVALID_INTERCHANGE_CHARACTERS,
    [GmbErrorCode.FIELDS_REQUIRED_FOR_CATEGORY]: InformationUpdateErrorReason.FIELDS_REQUIRED_FOR_CATEGORY,
    [GmbErrorCode.STOREFRONT_REQUIRED_FOR_CATEGORY]: InformationUpdateErrorReason.STOREFRONT_REQUIRED_FOR_CATEGORY,
    [GmbErrorCode.ADDRESS_MISSING_REGION_CODE]: InformationUpdateErrorReason.ADDRESS_MISSING_REGION_CODE,
    [GmbErrorCode.ADDRESS_EDIT_CHANGES_COUNTRY]: InformationUpdateErrorReason.ADDRESS_EDIT_CHANGES_COUNTRY,
    [GmbErrorCode.SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS]: InformationUpdateErrorReason.SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS,
    [GmbErrorCode.INVALID_TIME_SCHEDULE]: InformationUpdateErrorReason.INVALID_TIME_SCHEDULE,
    [GmbErrorCode.INVALID_HOURS_VALUE]: InformationUpdateErrorReason.INVALID_HOURS_VALUE,
    [GmbErrorCode.OVERLAPPED_SPECIAL_HOURS]: InformationUpdateErrorReason.OVERLAPPED_SPECIAL_HOURS,
    [GmbErrorCode.INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY]: InformationUpdateErrorReason.INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY,
    [GmbErrorCode.DUPLICATE_CHILDREN_LOCATIONS]: InformationUpdateErrorReason.DUPLICATE_CHILDREN_LOCATIONS,
    [GmbErrorCode.INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY]: InformationUpdateErrorReason.INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY,
    [GmbErrorCode.INVALID_SERVICE_AREA_PLACE_ID]: InformationUpdateErrorReason.INVALID_SERVICE_AREA_PLACE_ID,
    [GmbErrorCode.INVALID_AREA_TYPE_FOR_SERVICE_AREA]: InformationUpdateErrorReason.INVALID_AREA_TYPE_FOR_SERVICE_AREA,
    [GmbErrorCode.OPENING_DATE_TOO_FAR_IN_THE_FUTURE]: InformationUpdateErrorReason.OPENING_DATE_TOO_FAR_IN_THE_FUTURE,
    [GmbErrorCode.OPENING_DATE_MISSING_YEAR_OR_MONTH]: InformationUpdateErrorReason.OPENING_DATE_MISSING_YEAR_OR_MONTH,
    [GmbErrorCode.OPENING_DATE_BEFORE_1AD]: InformationUpdateErrorReason.OPENING_DATE_BEFORE_1AD,
    [GmbErrorCode.TOO_MANY_ENTRIES]: InformationUpdateErrorReason.TOO_MANY_ENTRIES,
    [GmbErrorCode.INVALID_PHONE_NUMBER]: InformationUpdateErrorReason.INVALID_PHONE_NUMBER,
    [GmbErrorCode.INVALID_PHONE_NUMBER_FOR_REGION]: InformationUpdateErrorReason.INVALID_PHONE_NUMBER_FOR_REGION,
    [GmbErrorCode.MISSING_PRIMARY_PHONE_NUMBER]: InformationUpdateErrorReason.MISSING_PRIMARY_PHONE_NUMBER,
    [GmbErrorCode.THROTTLED]: InformationUpdateErrorReason.THROTTLED,
    [GmbErrorCode.UNSUPPORTED_POINT_RADIUS_SERVICE_AREA]: InformationUpdateErrorReason.UNSUPPORTED_POINT_RADIUS_SERVICE_AREA,
    [GmbErrorCode.INVALID_CATEGORY]: InformationUpdateErrorReason.INVALID_CATEGORY,
    [GmbErrorCode.CANNOT_REOPEN]: InformationUpdateErrorReason.CANNOT_REOPEN,
    [GmbErrorCode.INVALID_BUSINESS_OPENING_DATE]: InformationUpdateErrorReason.INVALID_BUSINESS_OPENING_DATE,
    [GmbErrorCode.INVALID_LATLNG]: InformationUpdateErrorReason.INVALID_LATLNG,
    [GmbErrorCode.PROFILE_DESCRIPTION_CONTAINS_URL]: InformationUpdateErrorReason.PROFILE_DESCRIPTION_CONTAINS_URL,
    [GmbErrorCode.LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION]: InformationUpdateErrorReason.LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION,
    [GmbErrorCode.INVALID_URL]: InformationUpdateErrorReason.INVALID_URL,
    [GmbErrorCode.INVALID_ADDRESS]: InformationUpdateErrorReason.INVALID_ADDRESS,
    [GmbErrorCode.PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF]: InformationUpdateErrorReason.PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF,
    [GmbErrorCode.RELATION_CANNOT_BE_THE_LOCATION_ITSELF]: InformationUpdateErrorReason.RELATION_CANNOT_BE_THE_LOCATION_ITSELF,
    [GmbErrorCode.MISSING_ADDRESS_COMPONENTS]: InformationUpdateErrorReason.MISSING_ADDRESS_COMPONENTS,
    [GmbErrorCode.READ_ONLY_ADDRESS_COMPONENTS]: InformationUpdateErrorReason.READ_ONLY_ADDRESS_COMPONENTS,
    [GmbErrorCode.STRING_TOO_LONG]: InformationUpdateErrorReason.STRING_TOO_LONG,
    [GmbErrorCode.STRING_TOO_SHORT]: InformationUpdateErrorReason.STRING_TOO_SHORT,
    [GmbErrorCode.REQUIRED_FIELD_MISSING_VALUE]: InformationUpdateErrorReason.REQUIRED_FIELD_MISSING_VALUE,
    [GmbErrorCode.ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED]: InformationUpdateErrorReason.ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED,
    [GmbErrorCode.ATTRIBUTE_INVALID_ENUM_VALUE]: InformationUpdateErrorReason.ATTRIBUTE_INVALID_ENUM_VALUE,
    [GmbErrorCode.ATTRIBUTE_NOT_AVAILABLE]: InformationUpdateErrorReason.ATTRIBUTE_NOT_AVAILABLE,
    [GmbErrorCode.ATTRIBUTE_CANNOT_BE_REPEATED]: InformationUpdateErrorReason.ATTRIBUTE_CANNOT_BE_REPEATED,
    [GmbErrorCode.ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY]: InformationUpdateErrorReason.ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY,
    [GmbErrorCode.ADDRESS_REMOVAL_NOT_ALLOWED]: InformationUpdateErrorReason.ADDRESS_REMOVAL_NOT_ALLOWED,
    [GmbErrorCode.AMBIGUOUS_TITLE]: InformationUpdateErrorReason.AMBIGUOUS_TITLE,
    [GmbErrorCode.INVALID_CATEGORY_FOR_SAB]: InformationUpdateErrorReason.INVALID_CATEGORY_FOR_SAB,
    [GmbErrorCode.RELATION_ENDPOINTS_TOO_FAR]: InformationUpdateErrorReason.RELATION_ENDPOINTS_TOO_FAR,
    [GmbErrorCode.INVALID_SERVICE_ITEM]: InformationUpdateErrorReason.INVALID_SERVICE_ITEM,
    [GmbErrorCode.SERVICE_ITEM_LABEL_NO_DISPLAY_NAME]: InformationUpdateErrorReason.SERVICE_ITEM_LABEL_NO_DISPLAY_NAME,
    [GmbErrorCode.SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME]: InformationUpdateErrorReason.SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME,
    [GmbErrorCode.SERVICE_ITEM_LABEL_INVALID_UTF8]: InformationUpdateErrorReason.SERVICE_ITEM_LABEL_INVALID_UTF8,
    [GmbErrorCode.FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID]: InformationUpdateErrorReason.FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID,
    [GmbErrorCode.FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL]: InformationUpdateErrorReason.FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL,
    [GmbErrorCode.SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID]: InformationUpdateErrorReason.SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID,
    [GmbErrorCode.INVALID_LANGUAGE]: InformationUpdateErrorReason.INVALID_LANGUAGE,
    [GmbErrorCode.PRICE_CURRENCY_MISSING]: InformationUpdateErrorReason.PRICE_CURRENCY_MISSING,
    [GmbErrorCode.PRICE_CURRENCY_INVALID]: InformationUpdateErrorReason.PRICE_CURRENCY_INVALID,
    [GmbErrorCode.SERVICE_TYPE_ID_DUPLICATE]: InformationUpdateErrorReason.SERVICE_TYPE_ID_DUPLICATE,
    [GmbErrorCode.PIN_DROP_REQUIRED]: InformationUpdateErrorReason.PIN_DROP_REQUIRED,
    [GmbErrorCode.STALE_DATA]: InformationUpdateErrorReason.STALE_DATA,
    [GmbErrorCode.PHONE_NUMBER_EDITS_NOT_ALLOWED]: InformationUpdateErrorReason.PHONE_NUMBER_EDITS_NOT_ALLOWED,
    [GmbErrorCode.MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION]: InformationUpdateErrorReason.MULTIPLE_ORGANIZATIONALLY_PART_OF_RELATION,
    [GmbErrorCode.SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY]: InformationUpdateErrorReason.SCALABLE_DEEP_LINK_INVALID_MULTIPLICITY,
    [GmbErrorCode.LINK_ALREADY_EXISTS]: InformationUpdateErrorReason.LINK_ALREADY_EXISTS,
    [GmbErrorCode.URL_PROVIDER_NOT_ALLOWED]: InformationUpdateErrorReason.URL_PROVIDER_NOT_ALLOWED,
    [GmbErrorCode.TOO_MANY_UPDATES]: InformationUpdateErrorReason.TOO_MANY_UPDATES,
    [GmbErrorCode.INVALID_SOCIAL_MEDIA_PROFILE_URL]: InformationUpdateErrorReason.INVALID_SOCIAL_MEDIA_PROFILE_URL,
    [GmbErrorCode.UNVERIFIED_LOCATION]: InformationUpdateErrorReason.UNVERIFIED_LOCATION,
    [GmbErrorCode.INVALID_LOCATION_CATEGORY]: InformationUpdateErrorReason.INVALID_LOCATION_CATEGORY,
    [InvalidPlatformReason.GMB_DISCONNECTED]: InformationUpdateErrorReason.GMB_PLATFORM_DISCONNECTED,
    [InvalidPlatformReason.GMB_DISABLED]: InformationUpdateErrorReason.GMB_PLATFORM_DISABLED,
    [InvalidPlatformReason.GMB_SUSPENDED]: InformationUpdateErrorReason.GMB_PLATFORM_SUSPENDED,
    [InvalidPlatformReason.GMB_PENDING_VERIFICATION]: InformationUpdateErrorReason.GMB_PLATFORM_PENDING_VERIFICATION,
    [InvalidPlatformReason.GMB_NOT_VERIFIED]: InformationUpdateErrorReason.GMB_PLATFORM_NOT_VERIFIED,
    [InvalidPlatformReason.IG_NOT_FOUND]: InformationUpdateErrorReason.IG_PLATFORM_NOT_FOUND,
    [InvalidPlatformReason.IG_NOT_BUSINESS]: InformationUpdateErrorReason.IG_PLATFORM_NOT_BUSINESS,
    [InvalidPlatformReason.TRIPADVISOR_NOT_MANAGER]: InformationUpdateErrorReason.TRIPADVISOR_NOT_MANAGER,
    [InvalidPlatformReason.TRIPADVISOR_UNCLAIMED_PAGE]: InformationUpdateErrorReason.TRIPADVISOR_UNCLAIMED_PAGE,
    [InvalidPlatformReason.NEED_REVIEW]: InformationUpdateErrorReason.NEED_REVIEW,
};
