import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, Inject, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, Observable, of } from 'rxjs';

import { PostPublicationStatus } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { DuplicateAndSelectPlatformsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/duplicate-and-select-platforms/duplicate-and-select-platforms.component';
import { PreviewsFeedNotesComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/previews-feed-notes.component';
import { SocialPostContentFormComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-content-form.component';
import { UpsertSocialPostModalFooterComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/upsert-social-post-modal-footer/upsert-social-post-modal-footer.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostHashtagsContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-hashtags.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import {
    SubmitPublicationStatus,
    UpsertSocialPostModalProps,
    UpsertSocialPostModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-upsert-social-post-modal',
    templateUrl: './upsert-social-post-modal.component.html',
    styleUrls: ['./upsert-social-post-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        DuplicateAndSelectPlatformsComponent,
        PreviewsFeedNotesComponent,
        SocialPostContentFormComponent,
        UpsertSocialPostModalFooterComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalComponent {
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _upsertSocialPostHashtagsContext = inject(UpsertSocialPostHashtagsContext);

    readonly SvgIcon = SvgIcon;

    readonly shouldOpenFeedbacks = signal(false);

    private readonly _shouldAutoSave = computed(
        () => this._upsertSocialPostContext.initialPost()?.published === PostPublicationStatus.DRAFT
    );
    private _isFirstAutoSave = true;
    private readonly _postForAutoSave = toSignal(
        toObservable(this._upsertSocialPostContext.upsertSocialPostState.post).pipe(
            filter((post) => !!post.id),
            debounceTime(500)
        )
    );

    private readonly _hasErrorOccuredDuringInit = this._upsertSocialPostContext.hasErrorOccuredDuringInit;

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertSocialPostModalComponent, UpsertSocialPostModalResult>,
        @Inject(MAT_DIALOG_DATA)
        data: UpsertSocialPostModalProps
    ) {
        this._upsertSocialPostContext.init(data.postId);
        this._upsertSocialPostHashtagsContext.init();
        this._upsertSocialPostAiContext.initInteractions(data.postId);

        if (data.shouldOpenFeedbacks) {
            this.shouldOpenFeedbacks.set(true);
        }

        effect(() => {
            if (this._hasErrorOccuredDuringInit()) {
                this._toastService.openErrorToast(this._translateService.instant('social_posts.upsert_social_post_modal.init_error'));
                this.close();
            }
        });

        effect(
            () => {
                const shouldAutoSave = this._shouldAutoSave();
                if (shouldAutoSave) {
                    const post = this._postForAutoSave();
                    if (post) {
                        if (this._isFirstAutoSave) {
                            this._isFirstAutoSave = false;
                            return;
                        }
                        this._upsertSocialPostContext.autosave(post);
                    }
                }
            },
            { allowSignalWrites: true }
        );
    }

    onSavePost(submitPublicationStatus: SubmitPublicationStatus): void {
        const post = this._upsertSocialPostContext.upsertSocialPostState.post();
        if (!post) {
            return;
        }
        this._upsertSocialPostContext.savePost$(post, submitPublicationStatus).subscribe((res) => {
            this._confirmClose(res.toInterface());
        });
    }

    close(): void {
        // Send data to the parent component only if autosave is enabled
        const data = this._shouldAutoSave() ? (this._upsertSocialPostContext.upsertSocialPostState.post() ?? null) : null;
        this._confirmClose(data);
    }

    onCancel(): void {
        const initialPost = this._upsertSocialPostContext.initialPost();

        let actionBeforeClose$: Observable<UpsertSocialPost | null>;

        if (!initialPost) {
            actionBeforeClose$ = this._upsertSocialPostContext.deletePost();
        } else if (this._shouldAutoSave()) {
            actionBeforeClose$ = this._upsertSocialPostContext.savePost$(initialPost, SubmitPublicationStatus.DRAFT);
        } else {
            actionBeforeClose$ = of(null);
        }

        actionBeforeClose$.subscribe((res) => {
            this._confirmClose(res ?? null);
        });
    }

    private _confirmClose(post: IUpsertSocialPost | null): void {
        this._upsertSocialPostAiContext.closePostCaptionAiGeneration();
        const socialPost = post ? SocialPostItem.fromIUpsertSocialPost(post) : null;
        this._dialogRef.close({ post: socialPost });
    }
}
