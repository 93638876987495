import { Chart, LegendItem, Plugin } from 'chart.js';

import { malouChartColorBluePurple } from ':shared/helpers/chart';

export interface ComparisonLegendPluginMetadata {
    display: boolean;
    primaryColor?: string;
    secondaryColor?: string;
}

interface ComparisonLegendPlugin {
    id: string;
    elementId: string;
    onLegendItemClick: (chart: Chart, legendItem: LegendItem) => void;
    shouldOnlyShowPrimaryColor?: boolean;
}

export function comparisonLegendPlugin({ id, elementId, onLegendItemClick, shouldOnlyShowPrimaryColor }: ComparisonLegendPlugin): Plugin {
    return {
        id,
        afterUpdate(chart) {
            if (!chart.legend) {
                return;
            }
            const legendContainer = document.getElementById(elementId);
            let ul = legendContainer?.querySelector('ul');
            if (!ul) {
                ul = document.createElement('ul');
                ul.style.display = 'flex';
                ul.style.flexDirection = 'row';
                ul.style.justifyContent = 'end';
                ul.style.flexWrap = 'wrap';
                ul.style.margin = '0';
                ul.style.padding = '0';
                legendContainer?.appendChild(ul);
            }

            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            if (!chart?.options?.plugins?.legend?.labels?.generateLabels) {
                return;
            }
            const legendItems = chart?.options?.plugins?.legend?.labels?.generateLabels(chart);

            legendItems.forEach((item) => {
                const itemMetaData = chart.data.datasets[item.datasetIndex as number] as any;
                const legendItemMetaData = itemMetaData?.metadata?.comparisonLegendPluginMetadata as ComparisonLegendPluginMetadata;
                if (!legendItemMetaData || !legendItemMetaData.display) {
                    return;
                }
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    onLegendItemClick(chart, item);
                };

                // Color box
                const pointSpan = document.createElement('span');
                pointSpan.style.borderRadius = '50%';
                if (shouldOnlyShowPrimaryColor) {
                    pointSpan.style.background = legendItemMetaData.primaryColor ?? malouChartColorBluePurple;
                } else {
                    pointSpan.style.background = getLinearGradient(legendItemMetaData.primaryColor, legendItemMetaData.secondaryColor);
                }
                pointSpan.style.display = 'inline-block';
                pointSpan.style.flexShrink = '0';
                pointSpan.style.height = '16px';
                pointSpan.style.marginRight = '5px';
                pointSpan.style.width = '16px';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = '#4A5E73';
                textContainer.style.fontSize = '11px';
                textContainer.style.fontWeight = '400';
                textContainer.style.margin = '0';
                textContainer.style.padding = '0';
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(pointSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        },
    };
}

function getLinearGradient(primaryColor: string | undefined, secondaryColor: string | undefined) {
    if (!primaryColor || !secondaryColor) {
        return malouChartColorBluePurple;
    }
    return `linear-gradient(to right, ${primaryColor} 46%, white 50%, ${secondaryColor} 54%)`;
}
