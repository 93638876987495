import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';

import { ApplicationLanguage, KeywordScoreTextType } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { DuplicatePostRestaurantData } from ':shared/components/duplicate-post-preview-modal/duplicate-seo-post-with-text-generation-preview-modal/duplicate-seo-post-with-text-generation-preview-modal.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { InputTextTheme } from ':shared/components/input-text/input-text.interface';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { isPastHour } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export enum DuplicationInputChangeType {
    URL = 'URL',
    CAPTION = 'CAPTION',
}

@Component({
    selector: 'app-seo-post-caption-preview-card',
    templateUrl: './seo-post-caption-preview-card.component.html',
    styleUrl: './seo-post-caption-preview-card.component.scss',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        InputDatePickerComponent,
        InputTextComponent,
        KeywordsScoreGaugeComponent,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        ApplyPurePipe,
        FormatTimePipe,
    ],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoPostCaptionPreviewCardComponent {
    readonly shouldDisplayDateForm = input.required<boolean>();
    readonly restaurantsData = input.required<DuplicatePostRestaurantData>();
    readonly shouldDisableCallToActionUrlInput = input.required<boolean>();
    readonly callToActionUrl = input.required<string | null>();
    readonly missingCallToActionUrlError = input.required<string | null>();
    readonly restaurantIndex = input.required<number>();
    readonly postDateForm = input.required<FormGroup>();
    readonly initialCaption = input.required<string>();
    readonly postLang$ = input.required<BehaviorSubject<string | null>>();
    readonly shouldKeepSameCaptionForAllPosts = input.required<boolean>();
    readonly hasCallToActionUrl = input.required<boolean>();
    readonly onUpdateRestaurantsData = output<{ changeType: DuplicationInputChangeType; newValue: string; index: number }>();

    readonly TIMES = times;
    readonly MIN_DATE = new Date();
    readonly MAX_LENGTH_FOR_SEO_POST = 1500;
    readonly SvgIcon = SvgIcon;
    readonly InputTextTheme = InputTextTheme;
    readonly isPastHour = isPastHour;
    readonly textType$ = of(KeywordScoreTextType.POST);
    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);

    changeSelectedTime(event: MatSelectChange): void {
        this.postDateForm().patchValue({
            postTime: event.value,
        });
    }

    onFocusEditableContent(contentEditableDiv: HTMLDivElement, textarea: HTMLTextAreaElement): void {
        const contentDivHeight = contentEditableDiv?.getBoundingClientRect()?.height;
        contentEditableDiv.style.display = 'none';
        textarea.style.display = 'block';
        if (textarea) {
            textarea.style.height = `${contentDivHeight}px`;
            // timeout needed to let the DOM update the textarea before acting on it
            setTimeout(() => {
                textarea.focus();
                const length = textarea.value.length;
                textarea.setSelectionRange(length, length);
            }, 0);
        }
    }

    onBlurTextarea(index: number, contentEditableDiv: HTMLDivElement, textarea: HTMLTextAreaElement): void {
        this.onUpdateRestaurantsData.emit({ changeType: DuplicationInputChangeType.CAPTION, newValue: textarea.value, index });
        contentEditableDiv.style.display = 'block';
        textarea.style.display = 'none';
    }

    onCallToActionUrlChange(newUrl: string, index: number): void {
        this.onUpdateRestaurantsData.emit({ changeType: DuplicationInputChangeType.URL, newValue: newUrl, index });
    }
}
