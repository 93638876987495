import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BarElement, Chart, ChartDataset, ChartOptions, ChartType, Plugin, Tick, TooltipItem } from 'chart.js';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { APP_DEFAULT_LANGUAGE, KeywordSearchImpressionsType } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import {
    ChartDataArray,
    malouChartColorBluePurple,
    malouChartColorLighterBlue,
    malouChartColorLightPurple,
    malouChartColorText2,
} from ':shared/helpers';

type BarChartType = Extract<ChartType, 'bar'>;

@Component({
    selector: 'app-impressions-insights-chart',
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    templateUrl: './impressions-insights-chart.component.html',
    styleUrl: './impressions-insights-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpressionsInsightsChartComponent {
    readonly impressionsInsightsData = input.required<ImpressionsInsightsData[]>();

    private readonly _translateService = inject(TranslateService);

    readonly MISSING_DATA_COLUMN: Plugin = this._addTextOnMissingMonthColumnPlugin();
    readonly CHART_TYPE: BarChartType = 'bar';
    readonly chartData: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.impressionsInsightsData())
    );
    readonly chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.impressionsInsightsData()));
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions(this.chartLabels()));
    readonly emptyMonthsDatasetIndexes = computed(() => {
        const emptyMonthsIndexes: number[] = [];
        for (let i = 0; i < this.impressionsInsightsData().length; i++) {
            if (
                this.impressionsInsightsData()[i][KeywordSearchImpressionsType.BRANDING] === 0 &&
                this.impressionsInsightsData()[i][KeywordSearchImpressionsType.DISCOVERY] === 0
            ) {
                emptyMonthsIndexes.push(i);
            }
        }
        return emptyMonthsIndexes;
    });

    private _computeChartData(impressionsInisghtsData: ImpressionsInsightsData[]): ChartDataset<BarChartType, ChartDataArray>[] {
        const brandingValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.BRANDING]);
        const discoveryValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.DISCOVERY]);

        return [
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.branding'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: brandingValues,
            },
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.discovery'),
                borderColor: malouChartColorLightPurple,
                backgroundColor: malouChartColorLightPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: discoveryValues,
            },
        ];
    }

    private _computeChartLabels(impressionsInisghtsData: ImpressionsInsightsData[]): string[] {
        const lang = LocalStorage.getItem(LocalStorageKey.LANG) ?? APP_DEFAULT_LANGUAGE;
        return impressionsInisghtsData.map((data) =>
            DateTime.fromObject({ month: data.month, year: data.year }).setLocale(lang).toFormat('LLL yyyy')
        );
    }

    private _computeChartOptions(labels: string[]): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, index: number, _ticks: Tick[]): string => labels[index],
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display: true,
                        color: (_context): string | undefined => malouChartColorLighterBlue,
                    },
                    position: 'left',
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItem: TooltipItem<any>): string {
        return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
    }

    private _addTextOnMissingMonthColumnPlugin(): Plugin {
        return {
            id: 'customAddTextOnMissingMonthColumnPlugin',
            afterDraw: (chart: Chart): void => {
                const { ctx } = chart;
                ctx.save();
                for (const index of this.emptyMonthsDatasetIndexes()) {
                    const bar = chart.getDatasetMeta(0).data[index] as BarElement;
                    const centerX = bar.getCenterPoint().x;
                    const maxWidth = (bar as any).width + 40;

                    const fontSize = '10px';
                    const lineHeight = 15;
                    ctx.textAlign = 'center';
                    ctx.fillStyle = malouChartColorText2;
                    const textOptions = [
                        {
                            font: `bold ${fontSize} Poppins`,
                            text: this._translateService.instant('statistics.seo.keyword_search_impressions.missind_data'),
                            y: 120,
                        },
                    ];

                    ctx.textAlign = 'center';
                    textOptions.forEach(({ font, text, y }) => {
                        ctx.beginPath();
                        ctx.font = font;
                        this._fillWithLineBreak({ context: ctx, text, x: centerX, y, fitWidth: maxWidth, lineHeight });
                        ctx.fill();
                    });
                }

                ctx.restore();
            },
        };
    }

    private _fillWithLineBreak({
        context,
        text,
        x,
        y,
        fitWidth,
        lineHeight = 20,
    }: {
        context: CanvasRenderingContext2D;
        text: string;
        x: number;
        y: number;
        fitWidth: number;
        lineHeight: number;
    }): void {
        const words = text.split(' ');
        let currentLine = 0;
        let currentText = '';

        for (const word of words) {
            const tempText = currentText ? `${currentText} ${word}` : word;
            const tempWidth = context.measureText(tempText).width;

            if (tempWidth > fitWidth) {
                context.fillText(currentText, x, y + lineHeight * currentLine);
                currentText = word;
                currentLine++;
            } else {
                currentText = tempText;
            }
        }

        if (currentText) {
            context.fillText(currentText, x, y + lineHeight * currentLine);
        }
    }
}
