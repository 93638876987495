<div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
    <div>
        <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
    </div>
    <div>
        <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
    </div>
    <div>
        <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
    </div>
</div>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.gmb_impressions.impressions' | translate }}</span>
        @if (!showViewByTextInsteadOfSelector()) {
            <div class="flex items-center gap-2">
                <div class="malou-text-14--medium md:hidden">{{ 'statistics.seo.gmb_impressions.view_by' | translate }}</div>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-gmb-discoveries-chart
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        [gmbDiscoveriesData]="gmbDiscoveriesData()"
        [labels]="dateLabels()"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        [isMaxDataRangeReached]="false"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
    </app-gmb-discoveries-chart>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 xl:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div
                    class="malou-text-30--bold malou-color-text-1"
                    [matTooltip]="'statistics.seo.gmb_impressions.total_impressions_tooltip' | translate">
                    {{ dailyInsightsChartData().totalImpressions | shortNumber }}
                </div>
                @if (dailyInsightsChartData().totalImpressions) {
                    <app-number-evolution
                        [matTooltip]="comparedToKey() | translate"
                        [value]="impressionsEvolutionPercentage()"
                        [displayedValue]="
                            impressionsEvolutionPercentage() | shortNumber: { content: '%', shouldDisplayMinusSign: false }
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.gmb_impressions.total_impressions' | translate }}
            </div>
            <div
                class="malou-text-10--regular malou-color-text-2 mt-1 whitespace-nowrap italic"
                [matTooltip]="comparisonPeriodKey() | translate">
                ({{ dailyPreviousInsightsChartData().totalImpressions | shortNumber }})
            </div>
        </div>
    </div>
</ng-template>
