import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { GetKeywordRankingsForOneRestaurantV3ResponseDto, KeywordStatsV3Dto } from '@malou-io/package-dto';
import {
    errorReplacer,
    GeoSamplePlatform,
    isNotNil,
    RankingPosition,
    RankingPositionOutOf,
    RestaurantRankingFormat,
} from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { KeywordEvolutionMiniComponent } from ':modules/keywords/keyword-evolution-mini/keyword-evolution-mini.component';
import { KeywordEvolutionComponent } from ':modules/keywords/keyword-evolution/keyword-evolution.component';
import { KeywordsListSortBy } from ':modules/keywords/keywords.interface';
import { RankingsCompetitorsListComponent } from ':modules/keywords/rankings-competitors-list/rankings-competitors-list.component';
import { UpdateKeywordModalComponent } from ':modules/keywords/update-keyword-modal/update-keyword-modal.component';
import { RankingTableDataRowWithStats } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords.interface';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isSameDay } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { SortOption } from ':shared/interfaces/sort.interface';
import { DatesAndPeriod, Keyword, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface StatsSummary {
    count: {
        current: number;
        currentDetails: {
            posts: number;
            reviews: number;
        };
        diff: number;
    };
    average: {
        current: number;
        diff: number;
    };
    top20: {
        current: number;
        diff: number;
    };
}

/**
 * Returns undefined if we have no data.
 */
export const getMostRecentRank = ({ rankHistory }: KeywordStatsV3Dto): RankingPosition | undefined => {
    if (!rankHistory.length) {
        return undefined;
    }
    const p = rankHistory[rankHistory.length - 1];
    return {
        createdAt: new Date(p.fetchDate),
        position: {
            rank: p.rank ?? Infinity,
            outOf: p.outOf,
        },
    };
};

const countKeywordsInTop20 = (stats: GetKeywordRankingsForOneRestaurantV3ResponseDto): number =>
    stats.keywords.map((k): number => ((getMostRecentRank(k)?.position?.rank ?? Infinity) <= 19 ? 1 : 0)).reduce((a, b) => a + b, 0);

/**
 * This component is basically a more recent version of StatisticsSeoKeywordsComponent. We are
 * currently experimenting it in production with a feature flag.
 */
@Component({
    selector: 'app-statistics-seo-keywords-v3',
    templateUrl: './statistics-seo-keywords-v3.component.html',
    styleUrls: ['./statistics-seo-keywords.component.scss'],
    standalone: true,
    imports: [
        ApplyPurePipe,
        FlagPathResolverPipe,
        IllustrationPathResolverPipe,
        KeywordEvolutionMiniComponent,
        KeywordsPopularityComponent,
        LazyLoadImageModule,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        NgClass,
        NgTemplateOutlet,
        NumberEvolutionComponent,
        ShortNumberPipe,
        SkeletonComponent,
        StatisticsHttpErrorPipe,
        TranslateModule,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [EmojiPathResolverPipe],
})
export class StatisticsSeoKeywordsV3Component implements OnInit {
    @Input() isCompetitorsColumnShown = true;
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantKeywordId');

    keywords: WritableSignal<Keyword[] | null> = signal(null);
    keywordRankings: WritableSignal<GetKeywordRankingsForOneRestaurantV3ResponseDto | null> = signal(null);
    restaurant: Restaurant;
    statsSummary: WritableSignal<StatsSummary | null> = signal(null);

    isMalouAdmin: boolean;

    dataSource: MatTableDataSource<RankingTableDataRowWithStats>;
    selectedSortOption: SortOption<KeywordsListSortBy>;
    sortDirection = -1;
    sortOptions: SortOption<KeywordsListSortBy>[] = [];

    refreshStarted = false;
    httpError: any;
    isLoading = signal(true);

    endDate: WritableSignal<Date | null> = signal(null);

    doesPeriodEndToday: Signal<Boolean> = computed(() => {
        const endDate = this.endDate();
        return endDate ? isSameDay(endDate, new Date()) : false;
    });

    readonly userRole$ = this._store.select(selectUserInfos).pipe(
        filter(isNotNil),
        map((infos) => infos.role)
    );
    readonly isAdmin$: Observable<boolean> = this.userRole$.pipe(map((role) => role === 'admin'));

    displayedColumns: string[];
    defaultSort: Sort = { active: 'keyword', direction: ChartSortBy.ASC };

    private readonly _dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _refreshSubject$: Subject<void> = new Subject();

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        private readonly _keywordsService: KeywordsService,
        private readonly _restaurantsService: RestaurantsService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _store: Store,
        private readonly _emojiPathResolverPipe: EmojiPathResolverPipe
    ) {
        this.dataSource = new MatTableDataSource<RankingTableDataRowWithStats>();
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit(): void {
        this.displayedColumns = [
            'keyword',
            'language',
            'volume',
            'position',
            'evolution',
            ...(this.isCompetitorsColumnShown ? ['competitors'] : []),
        ];

        this.isAdmin$.subscribe((isAdmin) => (this.isMalouAdmin = isAdmin));

        this.sortOptions = [
            { value: KeywordsListSortBy.Keywords, text: this._translate.instant('keywords.keywords') },
            { value: KeywordsListSortBy.Language, text: this._translate.instant('keywords.validation.lang') },
            {
                value: KeywordsListSortBy.SearchVolume,
                text: this._translate.instant('keywords.popularity'),
            },
            { value: KeywordsListSortBy.Ranking, text: this._translate.instant('keywords.maps_position') },
        ];

        this.selectedSortOption = this.sortOptions[0];

        combineLatest([this._restaurantsService.restaurantSelected$, this._dates$, this._refreshSubject$])
            .pipe(
                filter(([_restaurant, dates]) => !!dates.startDate && !!dates.endDate),
                map(([restaurant, dates]) => [restaurant, dates.startDate, dates.endDate]),
                tap(() => this._reset()),
                debounceTime(500),
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) => {
                    const { _id: restaurantId } = restaurant;
                    this.endDate.set(endDate);

                    const intervalMs = +endDate - +startDate;
                    const previousPeriodStartDate = new Date(+startDate - intervalMs);

                    return forkJoin([
                        // currentCountAndAverage
                        this._keywordsService
                            .getRestaurantKeywordsCountAndAverageScore(restaurantId, startDate, endDate)
                            .pipe(map((res) => res.data)),

                        // previousCountAndAverage
                        this._keywordsService
                            .getRestaurantKeywordsCountAndAverageScore(restaurantId, startDate, endDate, true)
                            .pipe(map((res) => res.data)),

                        // keywords
                        this._keywordsService.getKeywordsByRestaurantId(restaurantId).pipe(map((res) => res.data)),

                        // currentKeywordRankings
                        this._keywordsService.getKeywordRankingsForOneRestaurantV3({
                            restaurantId,
                            startDate,
                            endDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                        }),

                        // previousKeywordRankings
                        this._keywordsService.getKeywordRankingsForOneRestaurantV3({
                            restaurantId,
                            startDate: previousPeriodStartDate,
                            endDate: startDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                            doNotFetchRecentSamples: true,
                        }),

                        // restaurant
                        of(restaurant),
                    ]).pipe(
                        catchError((error) => {
                            this.httpError = error;
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return EMPTY;
                        })
                    );
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([
                    currentCountAndAverage,
                    previousCountAndAverage,
                    keywords,
                    currentKeywordRankings,
                    previousKeywordRankings,
                    restaurant,
                ]) => {
                    // important for CSV exports:
                    this._store.dispatch(StatisticsActions.editKeywords({ keywords, stats: currentKeywordRankings.keywords }));

                    this.keywordRankings.set(currentKeywordRankings);
                    this.keywords.set(keywords);
                    this.restaurant = restaurant;
                    this.dataSource.data = this._mapKeywordsToRankingTableDataRows(currentKeywordRankings);
                    this.dataSource._updateChangeSubscription();

                    const currentTop20 = countKeywordsInTop20(currentKeywordRankings);
                    const previousTop20 = countKeywordsInTop20(previousKeywordRankings);
                    this.statsSummary.set(
                        this.getStatsSummary(currentCountAndAverage, previousCountAndAverage, currentTop20, previousTop20)
                    );

                    this.isLoading.set(false);
                }
            );

        if (this.tableSortOptions) {
            this.defaultSort = this.tableSortOptions;
        }

        this._refreshSubject$.next();
    }

    onSortChange(sort: Sort): void {
        if (sort.direction?.length) {
            this.tableSortOptionsChange.emit(sort);
        }
    }

    getStatsSummary(
        currentCountAndAverage: { average: number; count: { posts: number; reviews: number } },
        previousCountAndAverage: { average: number; count: { posts: number; reviews: number } },
        currentTop20: number,
        previousTop20: number
    ): StatsSummary {
        return {
            count: {
                current: Object.values(currentCountAndAverage?.count || {}).reduce((a, b) => a + b, 0),
                currentDetails: currentCountAndAverage?.count,
                diff: this._getEvolution(
                    Object.values(currentCountAndAverage?.count || {}).reduce((a, b) => a + b, 0),
                    Object.values(previousCountAndAverage?.count || {}).reduce((a, b) => a + b, 0)
                ),
            },
            average: {
                current: currentCountAndAverage?.average,
                diff: this._getEvolution(currentCountAndAverage?.average, previousCountAndAverage?.average),
            },
            top20: {
                current: currentTop20,
                diff: this._getEvolution(currentTop20, previousTop20),
            },
        };
    }

    changeSortOrder(): void {
        this.sortDirection = this.sortDirection === 1 ? -1 : 1;
        this.sortKeywords();
    }

    sortKeywords(sortOption: SortOption<KeywordsListSortBy> = this.selectedSortOption): void {
        this.selectedSortOption = sortOption;

        const sortedData = this.dataSource.data.sort((a, b) => {
            const sortDirectionCoef = this.sortDirection === 1 ? 1 : -1;
            return this._sortActiveColumn(this.selectedSortOption.value, a, b, sortDirectionCoef);
        });

        this.dataSource.data = sortedData;
    }

    isErrorFixable(error?: string): boolean {
        return !!error?.match(/exceeded your rate-limit/);
    }

    getErrorDetail = (error: string): string => {
        if (!error) {
            return this._translate.instant('keywords.no_information');
        }
        switch (error) {
            case 'results_too_far':
                return this._translate.instant('keywords.results_too_far');
            default:
                return error;
        }
    };

    isRowCurrentPositionValid(row: RankingTableDataRowWithStats): boolean {
        return row.currentPosition?.rank !== Infinity;
    }

    getPrettyLang = (lang: string): string => this._translate.instant(`header.langs.${lang}`);

    getEmojiAndTextFromPosition = (position: RankingPositionOutOf): { emojiSrc: string; title: string; caption: string } => {
        switch (true) {
            case position.rank === 1:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('trophy'),
                    title: this._translate.instant('keywords.positions.first'),
                    caption: this._translate.instant('keywords.positions.first_subtitle'),
                };
            case position.rank === 2:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('second_place_medal'),
                    title: this._translate.instant('keywords.positions.second', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.second_subtitle'),
                };
            case position.rank === 3:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('third_place_medal'),
                    title: this._translate.instant('keywords.positions.third', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.third_subtitle'),
                };
            case position.rank <= 10:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('flexed_biceps'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top10_subtitle'),
                };
            case position.rank <= 15:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('victory_hand'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top15_subtitle'),
                };
            case position.rank <= 20:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('raising_hands'),
                    title: this._translate.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translate.instant('keywords.positions.top20_subtitle'),
                };

            default:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('face_in_clouds'),
                    title: `+${position.outOf}`,
                    caption:
                        position.outOf === 20
                            ? this._translate.instant('keywords.positions.other_subtitle')
                            : this._translate.instant('keywords.positions.under20_results_subtitle', { position: position.outOf }),
                };
        }
    };

    clarifyError(err: any): string {
        if (err && err.error?.msg) {
            if (err.error.msg.includes('24 hours')) {
                return this._translate.instant('keywords.wait24h');
            }
            return err.error.msg;
        }
        if (typeof err === 'string') {
            return err;
        }
        if (err?.error?.message?.match(/Place ID is no longer valid/)) {
            return this._translate.instant('keywords.dead_restaurant');
        }
        return this._translate.instant('keywords.error_unknown') + ' : ' + JSON.stringify(err, errorReplacer) + String(err);
    }

    canRefresh(keyword: RankingTableDataRowWithStats): boolean {
        const does24HoursHavePassed = DateTime.local().diff(DateTime.fromJSDate(keyword.lastRefresh), 'hours').hours > 24;
        return (does24HoursHavePassed || !keyword.lastRefresh) && !keyword.isWaiting;
    }

    refreshKeyword(keywordRow: RankingTableDataRowWithStats): void {
        if (this.refreshStarted) {
            return;
        }
        this.refreshStarted = true;
        keywordRow.isWaiting = true;
        this.dataSource._updateChangeSubscription();
        const restaurantKeywordId = keywordRow.restaurantKeywordId;

        this._keywordsService.refreshRankingForKeywordId(restaurantKeywordId).subscribe({
            next: (result) => {
                this.refreshStarted = false;
                if (result?.length) {
                    this._refreshSubject$.next();
                } else {
                    keywordRow.isWaiting = false;
                }
            },
            error: (err) => {
                this.refreshStarted = false;
                this._toastService.openErrorToast(this.clarifyError(err));
                keywordRow.isWaiting = false;
            },
        });
    }

    openCustomVolumeModal(keyword: RankingTableDataRowWithStats): void {
        this._customDialogService
            .open(UpdateKeywordModalComponent, {
                data: {
                    keywordToUpdate: keyword,
                    keywords: this.keywords(),
                    restaurantId: this.restaurant._id,
                },
                height: '275px',
            })
            .afterClosed()
            .subscribe({
                next: (newValue) => {
                    if (newValue) {
                        keyword.volume = newValue.volume;
                        keyword.popularity = newValue.popularity;
                    }
                },
            });
    }

    openKeywordEvolution(keywordText: string): void {
        const keywordRankings = this.keywordRankings();
        if (!keywordRankings) {
            return;
        }
        const keywordStats = keywordRankings.keywords.find((k) => k.name === keywordText);
        if (!keywordStats) {
            return;
        }

        this._customDialogService
            .open(KeywordEvolutionComponent, {
                height: 'unset',
                data: {
                    positions: keywordStats.rankHistory.map(
                        (p): RankingPosition => ({
                            position: { rank: p.rank ?? Infinity, outOf: p.outOf },
                            createdAt: new Date(p.fetchDate),
                        })
                    ),
                    keywordText,
                    restaurantId: this.restaurant._id,
                    restaurantPlaceId: this.restaurant.placeId,
                    applyFilter: (event: Event) => {
                        const filterValue = (event.target as HTMLInputElement).value;
                        this.dataSource.filter = filterValue.trim().toLowerCase();
                    },
                },
            })
            .afterClosed();
    }

    openCompetitorsList(keyword: string, ranking: RestaurantRankingFormat[], indexPosition?: number): void {
        if (!indexPosition) {
            return;
        }
        this._customDialogService
            .open(RankingsCompetitorsListComponent, {
                height: 'unset',
                data: {
                    keyword,
                    ranking,
                    indexPosition,
                },
            })
            .afterClosed();
    }

    private _sortActiveColumn(
        value: KeywordsListSortBy,
        a: RankingTableDataRowWithStats,
        b: RankingTableDataRowWithStats,
        sortDirectionCoef: number
    ): number {
        switch (value) {
            case KeywordsListSortBy.Keywords:
                return a.keyword.toLowerCase().localeCompare(b.keyword.toLowerCase()) * sortDirectionCoef;
            case KeywordsListSortBy.Language:
                return a.language.toLowerCase().localeCompare(b.language.toLowerCase()) * sortDirectionCoef;
            case KeywordsListSortBy.SearchVolume:
                return ((a.volume ?? 0) - (b.volume ?? 0)) * sortDirectionCoef;
            case KeywordsListSortBy.Ranking:
                const aPosition = a.currentPosition && a.currentPosition.rank <= a.currentPosition.outOf ? a.currentPosition.rank : 999;
                const bPosition = b.currentPosition && b.currentPosition.rank <= b.currentPosition.outOf ? b.currentPosition.rank : 999;
                return (aPosition - bPosition) * sortDirectionCoef;
            default:
                return 0;
        }
    }

    private _getEvolution(now: number, before: number): number {
        if (!before) {
            return 0;
        }
        return now - before;
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.endDate.set(null);
    }

    private _mapKeywordsToRankingTableDataRows(
        keywordRankings: GetKeywordRankingsForOneRestaurantV3ResponseDto
    ): RankingTableDataRowWithStats[] {
        const keywords = this.keywords();
        if (!keywords) {
            return [];
        }

        const rankingTableDataRows = keywordRankings.keywords
            .map((keywordStats): RankingTableDataRowWithStats | null => {
                const keyword = keywords.find((k) => k.keywordId === keywordStats.keywordId);
                if (!keyword) {
                    return null;
                }
                return {
                    keywordId: keywordStats.keywordId,
                    restaurantKeywordId: keyword.restaurantKeywordId,
                    language: keyword.language,
                    keyword: keyword.text,
                    volumeFromAPI: keyword.volume,
                    volume: keyword.getNotNullVolume(),
                    currentPosition: getMostRecentRank(keywordStats)?.position ?? undefined,
                    isLoading: false,
                    ranking: keywordStats.localCompetitorsPodium.map(
                        (c) => new RestaurantRankingFormat(c.name, c.placeId, c.address, c.address)
                    ),
                    lastRefresh: keyword.lastRefresh,
                    isWaiting: false,
                    error: keywordStats.error,
                    errorData: keywordStats.issuesTechnicalDetails ?? '',
                    shouldRefetchVolume: keyword.shouldRefetchVolume(),
                    positions: keywordStats.rankHistory.map((p) => ({
                        position: { rank: p.rank ?? Infinity, outOf: p.outOf },
                        createdAt: new Date(p.fetchDate),
                    })),
                    popularity: keyword.getPopularity(keywords),
                };
            })
            .filter((r): r is RankingTableDataRowWithStats => !!r);

        return rankingTableDataRows;
    }
}
