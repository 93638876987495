import { DateTime } from 'luxon';

import { getNumberWithSpaces, getTimeDifferenceInDays, ReviewAnalysisSentiment, ReviewAnalysisTag } from '@malou-io/package-utils';

import { Review } from ':shared/models';
import { SegmentAnalysis } from ':shared/models/segment-analysis';

export const getReviewCsvRowData = (
    review: Review,
    rating: string,
    platform: string,
    isNewSemanticAnalysisFeatureEnabled: boolean = false
): string[] => {
    const date = review.socialCreatedAt.toISOString();
    const reviewText = review.text ?? '';
    const reviewer = review.reviewer?.displayName ?? '';
    const answer1 = review.comments[0]?.text ?? '';
    const answerDate1 = review.comments[0]?.socialUpdatedAt?.toISOString() ?? '';
    const answer2 = review.comments[1]?.text ?? '';
    const answerDate2 = review.comments[1]?.socialUpdatedAt?.toISOString() ?? '';
    const answer3 = review.comments[2]?.text ?? '';
    const answerDate3 = review.comments[2]?.socialUpdatedAt?.toISOString() ?? '';

    const segments: SegmentAnalysis[] | undefined = isNewSemanticAnalysisFeatureEnabled
        ? review.semanticAnalysisSegments
        : review.semanticAnalysis?.segmentAnalyses.map(
              (seg) =>
                  new SegmentAnalysis({
                      category: seg.tag,
                      sentiment: seg.sentiment,
                      segment: seg.segment,
                      aiFoundSegment: seg.segment,
                      platformKey: review.key,
                      reviewSocialId: review.socialId ?? review._id,
                      reviewSocialCreatedAt: review.socialCreatedAt,
                      platformSocialId: review.restaurantId, // not used & will be deleted with feature toggle 'new-semantic-analysis'
                  })
          );

    const atmospherePositive = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.ATMOSPHERE, ReviewAnalysisSentiment.POSITIVE);
    const atmosphereNegative = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.ATMOSPHERE, ReviewAnalysisSentiment.NEGATIVE);
    const servicePositive = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.SERVICE, ReviewAnalysisSentiment.POSITIVE);
    const serviceNegative = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.SERVICE, ReviewAnalysisSentiment.NEGATIVE);
    const foodPositive = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.FOOD, ReviewAnalysisSentiment.POSITIVE);
    const foodNegative = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.FOOD, ReviewAnalysisSentiment.NEGATIVE);
    const pricePositive = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.PRICE, ReviewAnalysisSentiment.POSITIVE);
    const priceNegative = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.PRICE, ReviewAnalysisSentiment.NEGATIVE);
    const hygienePositive = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.HYGIENE, ReviewAnalysisSentiment.POSITIVE);
    const hygieneNegative = _getJoinedSemanticAnalysisSegments(segments, ReviewAnalysisTag.HYGIENE, ReviewAnalysisSentiment.NEGATIVE);
    const expeditiousnessPositive = _getJoinedSemanticAnalysisSegments(
        segments,
        ReviewAnalysisTag.EXPEDITIOUSNESS,
        ReviewAnalysisSentiment.POSITIVE
    );
    const expeditiousnessNegative = _getJoinedSemanticAnalysisSegments(
        segments,
        ReviewAnalysisTag.EXPEDITIOUSNESS,
        ReviewAnalysisSentiment.NEGATIVE
    );
    return [
        date,
        reviewText,
        reviewer,
        rating,
        platform,
        answer1,
        answerDate1,
        answer2,
        answerDate2,
        answer3,
        answerDate3,
        atmospherePositive,
        atmosphereNegative,
        servicePositive,
        serviceNegative,
        foodPositive,
        foodNegative,
        pricePositive,
        priceNegative,
        hygienePositive,
        hygieneNegative,
        expeditiousnessPositive,
        expeditiousnessNegative,
    ];
};

const _getJoinedSemanticAnalysisSegments = (
    semanticAnalysisSegments: SegmentAnalysis[] | undefined,
    category: ReviewAnalysisTag,
    sentiment: ReviewAnalysisSentiment
): string =>
    semanticAnalysisSegments
        ?.filter((e) => e.category === category && e.sentiment === sentiment)
        .map((e) => e.segment)
        .join(' & ') ?? '';

export const createDateIntervalWithInDaysDurationCondition = (
    startDate: Date | null = null,
    endDate: Date | null = null,
    durationInDays: number
): { startDate: Date; endDate: Date } => {
    const now = DateTime.now();
    if (startDate && endDate) {
        if (endDate > now.toJSDate()) {
            endDate = now.toJSDate();
        }
        const diffInDays = getTimeDifferenceInDays(endDate, startDate);
        if (diffInDays > durationInDays) {
            startDate = DateTime.fromJSDate(endDate).minus({ days: durationInDays }).toJSDate();
        }
        return {
            startDate,
            endDate,
        };
    } else {
        return {
            startDate: now.minus({ days: durationInDays }).toJSDate(),
            endDate: now.toJSDate(),
        };
    }
};

export const displayRevenue = (revenue: { min: number; max: number }): string => {
    if (revenue.min === 2_000_000 && revenue.max === 2_000_000) {
        return `+ ${getNumberWithSpaces(revenue.min)}`;
    }
    return `${getNumberWithSpaces(revenue.min)} - ${getNumberWithSpaces(revenue.max)}`;
};
