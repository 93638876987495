<div class="relative h-[370px]">
    <div class="relative h-[320px]">
        <div [id]="legendContainerId"></div>
        <canvas
            baseChart
            [datasets]="chartDataSets()"
            [labels]="chartLabels()"
            [options]="chartOption()"
            [type]="CHART_TYPE"
            [plugins]="[LEGEND_PLUGIN()]">
        </canvas>
    </div>
</div>
