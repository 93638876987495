@if (isLoading()) {
    <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
} @else {
    @if (!httpError) {
        @if (!noResults) {
            <div class="malou-simple-card flex h-full flex-col gap-3 px-6 py-3 md:px-2">
                <div>
                    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                </div>
            </div>
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'statistics.e_reputation.reviews_ratings.no_reviews' | translate }}</span>
            </div>
        }
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
            <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
        </div>
    }
}

<ng-template #titleTemplate>
    <div class="flex items-center gap-2">
        <div class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_ratings.average_ratings_on_period' | translate }}
        </div>
        @if (isSomePlatformGotNoData) {
            <div class="malou-status--waiting pdf-hidden" [matTooltip]="somePlatformGotNoDataErrorMsg">
                <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-reviews-ratings-total-chart [reviewsRatings]="reviewsRatings" [labels]="labels"></app-reviews-ratings-total-chart>
</ng-template>
