import { AspectRatio, aspectRatioToNumberMap } from '../../../constants';

const PREFERRED_ASPECT_RATIOS = [
    { aspectRatio: AspectRatio.PORTRAIT, number: aspectRatioToNumberMap[AspectRatio.PORTRAIT] },
    { aspectRatio: AspectRatio.LANDSCAPE, number: aspectRatioToNumberMap[AspectRatio.LANDSCAPE] },
];

interface Area {
    left: number;
    top: number;
    width: number;
    height: number;
}

export class TransformDataComputerService {
    static computeArea(originalAspectRatio: number, targetAspectRatio: number): Area {
        let left: number, top: number, width: number, height: number;
        if (originalAspectRatio > targetAspectRatio) {
            top = 0;
            height = 1;
            width = targetAspectRatio / originalAspectRatio;
            left = (1 - width) / 2;
        } else if (originalAspectRatio < targetAspectRatio) {
            left = 0;
            width = 1;
            height = originalAspectRatio / targetAspectRatio;
            top = (1 - height) / 2;
        } else {
            top = 0;
            left = 0;
            width = 1;
            height = 1;
        }
        return {
            left,
            top,
            width,
            height,
        };
    }

    static computeClosestPreferredAspectRatio(originalAspectRatio: number): AspectRatio {
        // todo posts-v2 remove square special condition when posts v1 are removed
        const squareTolerance = 0.01;
        const isCloseToSquare = originalAspectRatio > 1 - squareTolerance && originalAspectRatio < 1 + squareTolerance;
        if (isCloseToSquare) {
            return AspectRatio.SQUARE;
        }
        const preferredAspectRatio = PREFERRED_ASPECT_RATIOS.reduce((acc, cur) => {
            if (!acc.number) {
                return cur;
            }
            if (!cur.number) {
                return acc;
            }
            const diffWithAcc = Math.abs(originalAspectRatio - acc.number);
            const diffWithCur = Math.abs(originalAspectRatio - cur.number);
            return diffWithAcc < diffWithCur ? acc : cur;
        })?.aspectRatio;

        return preferredAspectRatio ?? AspectRatio.PORTRAIT;
    }
}
