import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { catchError, EMPTY, Observable, tap } from 'rxjs';

import { MapstrCtaButtonType, MediaType, PlatformKey, PostFeedbacks, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import {
    SubmitPublicationStatus,
    WHITELISTED_PAGE_IDS,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { UpsertSocialPostState } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.interface';
import { UpsertSocialPostService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.service';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { FbLocation, Hashtag, Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostContext {
    private readonly _credentialService = inject(CredentialsService);
    private readonly _upsertSocialPostService = inject(UpsertSocialPostService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly upsertSocialPostState = signalState<UpsertSocialPostState>(this._getInitialState(null));
    readonly initialPost: WritableSignal<UpsertSocialPost | null> = signal(null);

    readonly postErrors = computed(() => this._upsertSocialPostService.getPostErrors(this.upsertSocialPostState.post()));

    readonly hasErrorOccuredDuringInit = signal(false);

    init(postId?: string): void {
        this._setIsLoadingPost(true);
        this.hasErrorOccuredDuringInit.set(false);
        const currentRestaurantId = this._restaurantsService.currentRestaurant._id;
        (postId
            ? this._upsertSocialPostService.getPost$(postId)
            : this._upsertSocialPostService.createPost$(currentRestaurantId)
        ).subscribe({
            next: (post) => {
                this.initialPost.set(post);
                patchState(this.upsertSocialPostState, this._getInitialState(post));
                this._initConnectedSocialPlatformsAndLocation({ isNewSocialPost: !postId });
                this._setIsLoadingPost(false);
            },
            error: (error) => {
                console.error(error);
                this.hasErrorOccuredDuringInit.set(true);
            },
        });
    }

    autosave(post: IUpsertSocialPost): void {
        this._setIsAutoSaving(true);
        this._updatePost$(post, SubmitPublicationStatus.DRAFT)
            .pipe(catchError((error) => this._handleError(error)))
            .subscribe(() => {
                this._setIsAutoSaving(false);
            });
    }

    savePost$(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): Observable<UpsertSocialPost> {
        this._setIsSubmitting(true);
        return this._updatePost$(post, submitPublicationStatus).pipe(tap(() => this._setIsSubmitting(false)));
    }

    deletePost(): Observable<null> {
        const postId = this.upsertSocialPostState.post.id();
        this._setIsSubmitting(true);
        return this._upsertSocialPostService.deletePost(postId).pipe(tap(() => this._setIsSubmitting(false)));
    }

    selectPlatforms(platformKeys: PlatformKey[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, platformKeys } }));
    }

    selectSeoPlatforms(platformKeys: PlatformKey[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, duplicateToPlatforms: platformKeys } }));
    }

    updateTitle(title: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, title } }));
    }

    updateCaption(caption: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, text: caption } }));
    }

    updateLocation(location: FbLocation | null): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, location } }));
    }

    updateSelectedHashtags(selectedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, selected: selectedHashtags } },
        }));
    }

    updateSuggestedHashtags(suggestedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, suggested: suggestedHashtags } },
        }));
    }

    updateCtaActionType(actionType: MapstrCtaButtonType | null): void {
        if (!actionType) {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: undefined },
            }));
        } else {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { url: '' }), actionType } },
            }));
        }
    }

    updateCtaUrl(url: string): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { actionType: MapstrCtaButtonType.SEE }), url } },
        }));
    }

    updateFeedbacks(feedbacks: null | PostFeedbacks): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, feedbacks } }));
    }

    updatePlannedPublicationDate(date: Date): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, plannedPublicationDate: date } }));
    }

    updateMedias(medias: EditionMedia[]): void {
        const postType = this._getPostType(medias);
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, attachments: medias, postType } }));
    }

    private _getInitialState(initialPost: UpsertSocialPost | null): UpsertSocialPostState {
        return cloneDeep({
            post: initialPost ? initialPost.toInterface() : UpsertSocialPost.create().toInterface(),
            isAutoSaving: false,
            isSubmitting: false,
            isLoadingLocation: false,
            duplicateToPlatforms: [],
            connectedSocialPlatforms: [],
        });
    }

    private _initConnectedSocialPlatformsAndLocation(options: { isNewSocialPost: boolean }): void {
        this._upsertSocialPostService.getConnectedSocialPlatforms$().subscribe((platforms) => {
            const connectedPlatformKeys = platforms.map((platform) => platform.key);
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                connectedSocialPlatforms: platforms,
            }));
            if (options.isNewSocialPost) {
                this.selectPlatforms(connectedPlatformKeys);

                // If Facebook is connected, we initialize the location with the one specified in the Facebook page
                const fbPlatform = platforms.find((platform) => platform.key === PlatformKey.FACEBOOK);
                if (fbPlatform) {
                    this._initLocation(fbPlatform);
                }
            }
        });
    }

    private _initLocation(fbPlatform: Platform): void {
        this._setIsLoadingLocation(true);

        // Special case, we whitelist the "Very French Beans" page because it does not contain location
        this._credentialService
            .pagesSearch(fbPlatform.name, {
                onlyWithLocation: true,
                whitelistedPageIds: WHITELISTED_PAGE_IDS,
            })
            .pipe(
                catchError((error) => {
                    this._setIsLoadingLocation(false);
                    return this._handleError(error);
                })
            )
            .subscribe((res) => {
                const locations = res.data;
                const currentLocation = locations.find((l) => l.id === fbPlatform.socialId) || null;

                if (currentLocation) {
                    this.updateLocation(currentLocation);
                }
                this._setIsLoadingLocation(false);
            });
    }

    private _updatePost$(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): Observable<UpsertSocialPost> {
        const postToUpdate = this._getPostToUpdate(post, submitPublicationStatus);
        return this._upsertSocialPostService.updatePost$(postToUpdate).pipe(catchError((error) => this._handleError(error)));
    }

    private _setIsAutoSaving(isAutoSaving: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isAutoSaving }));
    }

    private _setIsSubmitting(isSubmitting: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isSubmitting }));
    }

    private _setIsLoadingPost(isLoadingPost: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingPost }));
    }

    private _setIsLoadingLocation(isLoadingLocation: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingLocation }));
    }

    private _handleError(_error: unknown): Observable<never> {
        // TODO handle error with toast and error mapping
        return EMPTY;
    }

    private _getPostToUpdate(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): IUpsertSocialPost {
        switch (submitPublicationStatus) {
            case SubmitPublicationStatus.DRAFT:
                return { ...post, published: PostPublicationStatus.DRAFT };
            case SubmitPublicationStatus.SCHEDULE:
                return { ...post, published: PostPublicationStatus.PENDING };
            case SubmitPublicationStatus.NOW:
                return {
                    ...post,
                    published: PostPublicationStatus.PENDING,
                    plannedPublicationDate: DateTime.now().plus({ minutes: 2 }).toJSDate(),
                };
        }
    }

    private _getPostType(medias: EditionMedia[]): PostType {
        if (medias.length > 1) {
            return PostType.CAROUSEL;
        }
        if (medias.length === 1 && medias[0].type === MediaType.VIDEO) {
            return PostType.VIDEO;
        }
        return PostType.IMAGE;
    }
}
