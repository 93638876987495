import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const CREATE_POST_MODAL_HTML_ID = 'create-post-modal';

export const isNotNull: ValidatorFn = (fg: UntypedFormGroup) => {
    const callToAction = fg.parent;
    if (callToAction?.get('actionType')?.value && callToAction.get('actionType')?.value !== 'CALL') {
        if (!fg.value) {
            return { error: 'url must not be null ' };
        }
    }
    return null;
};

export const CrossCallToActionValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const actionType = fg.get('actionType')?.value;
    const url = fg.get('url')?.value;
    if (actionType === 'CALL') {
        return !url ? null : { error: 'url must be null' };
    }
    if (!!actionType) {
        return !!url ? null : { error: 'url must not be empty' };
    }
    return null;
};

export const EventDatesValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const startDate = new Date(fg.get('startDate')?.value);
    const endDate = new Date(fg.get('endDate')?.value);
    if (startDate && endDate && startDate > endDate) {
        return { error: 'event_end_before_start' };
    }
    return null;
};
