<app-text-area
    id="text"
    [textAreaId]="textAreaId()"
    [testId]="'social-post-caption-input'"
    [shouldDisplayAiButton]="true"
    [title]="
        newSocialPostContext.isSmallScreen()
            ? ('social_posts.new_social_post.add_caption_mobile' | translate)
            : ('social_posts.new_social_post.add_caption' | translate)
    "
    [required]="true"
    [formControl]="postDescriptionFormControl()"
    [placeholder]="'social_posts.new_social_post.write_here_or' | translate"
    [resizable]="false"
    [shouldAutosizeRows]="true"
    [maxLength]="isMapstrPlatformChecked() ? MAPSTR_POST_TEXT_LIMIT : POST_CAPTION_TEXT_LIMIT"
    [showMaxLength]="isMapstrPlatformChecked()"
    [isLoadingAnimationEnabled]="newSocialPostAiContext.isTextAreaLoadingAnimationEnabled()"
    [errorMessage]="
        isMapstrPlatformChecked() && postDescriptionFormControl().value.length > MAPSTR_POST_TEXT_LIMIT
            ? ('social_posts.new_social_post.mapstr_text_limit' | translate)
            : postDescriptionFormControl().invalid &&
                postDescriptionFormControl().touched &&
                !newSocialPostAiContext.isGeneratingPostTextFromAI()
              ? ('common.required_field' | translate)
              : null
    "
    [omitElement]="postHashtagsComponent()?.nativeElement"
    (appClickOutside)="handleClickOutside($event)"
    (click)="!newSocialPostContext.isSmallScreen() && newSocialPostAiContext.openPostCaptionAiGeneration()"
    (input)="onDescriptionChange($event)"
    (selectionChange)="onSelectionChange($event)">
    <ng-template #loadingAnimationTemplate>
        <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
        </app-infinite-text-slide>
    </ng-template>
    @if (!newSocialPostAiContext.isTextAreaLoadingAnimationEnabled() && postDescriptionFormControl().value.length === 0) {
        <div class="mt-3 h-full" afterTextAreaAiButtonTemplate>
            <button class="malou-btn-flat !ml-1 mb-0.5 !pl-0 !pr-0" id="aiBtnInsideTextarea" mat-button (click)="onWriteWithAiClick()">
                <mat-icon
                    class="h-4 !w-4 text-malou-color-chart-purple--accent"
                    [svgIcon]="SvgIcon.MAGIC_WAND"
                    [ngClass]="{ 'mr-1': !newSocialPostContext.isSmallScreen() }"></mat-icon>

                <span class="!malou-text-10--semibold text-malou-color-chart-purple--accent">{{
                    'social_posts.new_social_post.write_with_ai' | translate
                }}</span>
            </button>
        </div>
    }
    <div class="flex flex-col" footerTemplate>
        @if (newSocialPostAiContext.isGeneratingPostTextFromAI() || postDescriptionFormControl().value.length !== 0) {
            <app-ai-generation-actions
                optimizeButtonId="tracking_new_social_post_modal_optimize_post_with_ai_button"
                translateButtonIdPrefix="tracking_new_social_post_modal_translate_post_to_"
                [trackerId]="
                    'tracking_new_social_post_modal_translate_' +
                    (newSocialPostContext.isReel() ? 'reel' : 'post') +
                    '_to_custom_language_with_ai_button'
                "
                [disabled]="newSocialPostAiContext.isGeneratingPostTextFromAI()"
                [interactions]="newSocialPostAiContext.interactions()"
                [onReset]="newSocialPostAiContext.resetBrowser()"
                (onWandIconClick)="onWriteWithAiClick()"
                (onTranslate)="newSocialPostAiContext.translateText($event)"
                (onOptimize)="newSocialPostAiContext.optimizePostCaption()"
                (interactionChanged)="newSocialPostAiContext.onInteractionChanged($event)"></app-ai-generation-actions>
        }
        <app-post-hashtags [postText]="postDescriptionFormControl().value"></app-post-hashtags>
    </div>
</app-text-area>
