<div class="flex w-full cursor-pointer flex-col" (click)="onNotificationClick(notification().data.restaurantIds[0])">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{ notification().createdAt | formatDate: 'medium' }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>⛔️</div>
        <app-platform-logo [imgClasses]="'w-10'" [logo]="notification().data.platform.key"></app-platform-logo>
        <div class="flex flex-col">
            <div
                class="malou-text-11--semibold max-w-[80%] text-malou-color-text-1"
                [innerHTML]="
                    notification().data.restaurantIds.length > 1
                        ? ('notification-center.platform-disconnected.title_multiple'
                          | translate
                              : {
                                    platformName: notification().data.platform.key | platformName,
                                    restaurantNumber: notification().data.restaurantIds.length,
                                })
                        : ('notification-center.platform-disconnected.title'
                          | translate
                              : {
                                    platformName: notification().data.platform.key | platformName,
                                    restaurantName:
                                        restaurant()?.internalName ?? notification().data.mainRestaurantName ?? restaurant()?.name,
                                })
                "></div>
            <div
                class="malou-text-11 text-malou-color-text-2"
                [innerHTML]="'notification-center.platform-disconnected.description' | translate"></div>

            @if (isListVisible()) {
                @for (restaurant of restaurants(); track restaurant.id) {
                    <div class="flex gap-y-1">
                        <div class="flex w-full">
                            <span class="malou-text-11--semibold text-malou-color-text-1"> {{ restaurant.name }} </span>
                        </div>
                        <div class="flex">
                            <button
                                class="malou-btn-flat btn-sm !px-0"
                                mat-button
                                (click)="$event.stopPropagation(); onNotificationClick(restaurant.id)">
                                {{ 'common.reconnect' | translate }}
                            </button>
                        </div>
                    </div>
                }
            }
        </div>

        <div class="ml-auto">
            @if (notification().data.restaurantIds.length > 1) {
                <button class="malou-btn-flat btn-sm !px-0" mat-button (click)="$event.stopPropagation(); toggleList()">
                    {{ isListVisible() ? ('notification-center.hide_list' | translate) : ('notification-center.show_list' | translate) }}
                </button>
            } @else {
                <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.reconnect' | translate }}</button>
            }
        </div>
    </div>
</div>
