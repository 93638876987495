import { z } from 'zod';

import { MalouComparisonPeriod } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const getKeywordSearchImpressionsInsightsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetKeywordSearchImpressionsInsightsParamsDto = z.infer<typeof getKeywordSearchImpressionsInsightsParamsValidator>;

export const getKeywordSearchImpressionsInsightsQueryValidator = z
    .object({
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
        comparison_period: z.nativeEnum(MalouComparisonPeriod).optional(),
    })
    .transform((data) => ({
        startDate: data.start_date,
        endDate: data.end_date,
        comparisonPeriod: data.comparison_period,
    }));
export type GetKeywordSearchImpressionsInsightsQueryDto = z.infer<typeof getKeywordSearchImpressionsInsightsQueryValidator>;

// ------------------------------------------------------------

export const getTopKeywordSearchImpressionsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetTopKeywordSearchImpressionsParamsDto = z.infer<typeof getTopKeywordSearchImpressionsParamsValidator>;

export const getTopKeywordSearchImpressionsQueryValidator = z
    .object({
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
        limit: z.coerce.number(),
    })
    .transform((data) => ({
        startDate: data.start_date,
        endDate: data.end_date,
        limit: data.limit,
    }));

export type GetTopKeywordSearchImpressionsQueryDto = z.infer<typeof getTopKeywordSearchImpressionsQueryValidator>;
