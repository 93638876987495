import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import {
    CallToActionType,
    errorReplacer,
    FeedbackMessageVisibility,
    isNotNil,
    MalouErrorCode,
    MediaType,
    PlatformKey,
    PostError,
    PostPublicationStatus,
    PostSocialAttachment,
    PostSource,
    PostType,
    Role,
    SeoPostTopic,
    SocialAttachmentsMediaTypes,
    TiktokPrivacyStatus,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { environment } from ':environments/environment';
import { SimpleUserInterface } from ':modules/user/user';

import { ApiResult, Hashtag, KeywordAnalysis } from './';
import { AgendaJob, AgendaJobApiResponse } from './agenda-job';
import { Feedback } from './feedback';
import { Media, UserTag } from './media';

export enum PostDisplayFormat {
    CARD = 'card',
    ROW = 'row',
}

export interface FbLocation {
    id: string;
    name: string;
    link: string;
    location?: {
        city?: string;
        country?: string;
        latitude: number;
        longitude: number;
        street?: string;
        zip?: string;
    };
}
export interface PostDto {
    _id: string;
    socialId: string;
    language?: string;
    socialLink: string;
    socialCreatedAt: Date;
    socialUpdatedAt: Date;
    restaurantId: string;
    platformId: string;
    text: string;
    keywordAnalysis: KeywordAnalysis;
    key: PlatformKey;
    keys: PlatformKey[];
    author: SimpleUserInterface;
    published: PostPublicationStatus;
    errorData: string;
    plannedPublicationDate: Date;
    attachments: Media[];
    attachmentsName?: string;
    socialAttachments: PostSocialAttachment[];
    thumbnail: Media;
    thumbnailOffsetTimeInMs: number;
    callToAction?: {
        actionType: CallToActionType;
        url: string;
    };
    postTopic: SeoPostTopic;
    postType: PostType;
    event?: {
        title: string;
        startDate: Date;
        endDate: Date;
    };
    offer?: {
        couponCode: string;
        onlineUrl: string;
        termsConditions: string;
    };
    bindingId?: string;
    createdAt: Date;
    shouldDuplicateInOtherPlatforms?: boolean;
    userTags: UserTag[];
    userTagsList: UserTag[][];
    location?: FbLocation;
    feedbackId?: string;
    feedback?: Feedback;
    source: PostSource;
    isReelDisplayedInFeed: boolean;
    isStory: boolean;
    malouStoryId?: string;
    hashtags: PostHashtags;
}

export class Post {
    _id: string;
    id: string;
    socialId: string;
    language?: string;
    socialLink: string;
    socialCreatedAt: Date;
    socialUpdatedAt: Date;
    restaurantId: string;
    platformId: string;
    text: string;
    keywordAnalysis: KeywordAnalysis;
    key: PlatformKey;
    keys: PlatformKey[];
    author?: SimpleUserInterface;
    published: PostPublicationStatus;
    errorData: string;
    plannedPublicationDate: Date;
    attachments?: Media[];
    attachmentsName?: string;
    socialAttachments: PostSocialAttachment[];
    thumbnail: Media;
    thumbnailOffsetTimeInMs: number;
    http: HttpClient;
    callToAction?: {
        actionType: CallToActionType;
        url: string;
    };
    postTopic: SeoPostTopic;
    postType: PostType;
    event?: {
        title: string;
        startDate: Date;
        endDate: Date;
    };
    offer?: {
        couponCode: string;
        onlineUrl: string;
        termsConditions: string;
    };
    jobs?: AgendaJob[];
    hashtags: PostHashtags;
    bindingId?: string;
    createdAt: Date;
    updatedAt: Date;
    shouldDuplicateInOtherPlatforms?: boolean;
    userTags: UserTag[];
    userTagsList: UserTag[][];
    location?: FbLocation;
    feedbackId?: string;
    feedback?: Feedback;
    source: PostSource;
    isReelDisplayedInFeed: boolean;
    isStory: boolean;
    malouStoryId?: string;
    title?: string;
    duplicatedFromRestaurantId?: string;
    tiktokOptions: {
        privacyStatus: TiktokPrivacyStatus;
        interactionAbility: {
            duet: boolean;
            stitch: boolean;
            comment: boolean;
        };
        contentDisclosureSettings: {
            isActivated: boolean;
            yourBrand: boolean;
            brandedContent: boolean;
        };
    };

    private _hasInitWorkingPic = false;

    public constructor(init: Partial<Post> = {}) {
        Object.assign(this, init);
        this.id = this.id ?? this._id;

        if (!this.language) {
            delete this.language;
        }
        if (this.plannedPublicationDate) {
            this.plannedPublicationDate = new Date(this.plannedPublicationDate);
        }
        if (this.socialUpdatedAt) {
            this.socialUpdatedAt = new Date(this.socialUpdatedAt);
        }
        if (Array.isArray(this.attachments)) {
            this.attachments = this.attachments?.filter((a) => !!a).map((attachment) => new Media(attachment));
        }
        if (this.thumbnail) {
            this.thumbnail = new Media(this.thumbnail);
        }
        this.hashtags = !!init.hashtags
            ? {
                  selected: init.hashtags.selected?.filter((ht) => !!ht)?.map((ht) => new Hashtag(ht)) ?? [],
                  suggested: init.hashtags.suggested?.filter((ht) => !!ht)?.map((ht) => new Hashtag(ht)) ?? [],
              }
            : { selected: [], suggested: [] };

        this.text = this.removeHashtagsFromText();
        this.keys = this.keys ?? [];
    }

    getPostDate(): Date {
        return new Date(this.socialCreatedAt ?? this.plannedPublicationDate ?? this.updatedAt);
    }

    getAttachments(): PostSocialAttachment[] | Media[] {
        return this.attachments ?? this.socialAttachments;
    }

    getSocialMediaUrl(size: string = 'original'): string {
        return this.socialAttachments?.[0]?.urls?.[size];
    }

    async getThumbnailUrl(size: string = 'small'): Promise<string | null> {
        const postThumbnailUrl = this.thumbnail?.urls?.[size] ?? this.thumbnail?.urls?.['original'];
        if (postThumbnailUrl) {
            return postThumbnailUrl;
        }
        if (isNotNil(this.thumbnailOffsetTimeInMs) && (this.postType === PostType.VIDEO || this.postType === PostType.REEL)) {
            return (
                (await this.attachments?.[0]?.getVideoCoverUrl({
                    timestampSeconds: this.thumbnailOffsetTimeInMs / TimeInMilliseconds.SECOND,
                    resolutionPx: 110 * window.devicePixelRatio,
                })) ?? null
            );
        }

        const attachmentThumbnailUrl = this.attachments?.[0]?.thumbnail ?? this.getSocialMediaThumbnail();
        return attachmentThumbnailUrl;
    }

    getSocialMediaThumbnail(): string | null {
        return this.socialAttachments?.[0]?.thumbnailUrl ?? null;
    }

    getMalouMediaUrl(size: string = 'small'): string {
        return this.attachments?.[0]?.urls?.[size] ?? this.attachments?.[0]?.urls?.['original'];
    }

    getFirstMediumType(): MediaType {
        if (this.socialAttachments?.[0]) {
            return this.socialAttachments[0].type === 'image' ? MediaType.PHOTO : MediaType.VIDEO;
        } else {
            if (this.attachments?.[0]) {
                return this.attachments[0]?.type === 'photo' ? MediaType.PHOTO : MediaType.VIDEO;
            }
        }
        return MediaType.PHOTO;
    }

    refreshData(postData: Partial<Post>): void {
        const keys = Object.keys(postData);
        for (const key of keys) {
            this[key] = postData[key];
        }
        this.initWorkingPic('small');
    }

    initWorkingPic(size: string): void {
        if (this._hasInitWorkingPic) {
            return;
        }
        if (this.attachments?.[0]?.urls?.[size]) {
            this._hasInitWorkingPic = true;
            return;
        }
        if (!this.http) {
            return;
        }
        this._hasInitWorkingPic = true;
        const originalUrl = this.socialAttachments?.[0]?.urls?.original;
        if (originalUrl) {
            this.http.get(originalUrl, { responseType: 'text' }).subscribe({
                next: () => {},
                error: (error) => {
                    if (error?.status === 403) {
                        // some instagram urls expire and send 403, need to refresh the post
                        this.http.get<ApiResult>(`${environment.APP_MALOU_API_URL}/api/v1/posts/${this._id}/refresh`).subscribe();
                    }
                },
            });
        }
    }

    getPlannedPublicationDate(date: Date | string, thresholdInMs = 1000): Date {
        const tomorrow = DateTime.now().plus({ days: 1 }).toJSDate();
        if (new Date(date)?.getTime() + thresholdInMs < new Date().getTime()) {
            return tomorrow;
        }
        const plannedPublicationDate = new Date(date);
        return plannedPublicationDate instanceof Date && !isNaN(plannedPublicationDate.getTime()) ? plannedPublicationDate : tomorrow;
    }

    getCoreData(): Partial<Post> {
        return {
            attachments: this.attachments,
            attachmentsName: this.attachmentsName,
            language: this.language,
            text: this.text,
            hashtags: this.hashtags,
            author: this.author,
            plannedPublicationDate: this.getPlannedPublicationDate(this.plannedPublicationDate),
            duplicatedFromRestaurantId: this.duplicatedFromRestaurantId,
            ...(this.thumbnailOffsetTimeInMs && { thumbnailOffsetTimeInMs: this.thumbnailOffsetTimeInMs }),
            ...(this.thumbnail && { thumbnail: this.thumbnail }),
        };
    }

    getSeoPostData(): Partial<Post> {
        return {
            ...this.getCoreData(),
            attachments: [this.attachments?.[0]].filter(isNotNil), // select only first attachment for seo posts
            callToAction: this.callToAction,
            postTopic: this.postTopic,
            event: this.event,
            offer: this.offer,
            hashtags: {
                selected: [],
                suggested: [],
            },
            text: this.removeHashtagsFromText(),
        };
    }

    getSocialPostData(): Partial<Post> {
        return {
            ...this.getCoreData(),
            postType: this.postType,
            hashtags: this.hashtags,
            isReelDisplayedInFeed: this.isReelDisplayedInFeed,
            location: this.location,
        };
    }

    removeHashtagsFromText(): string {
        let textWithNoHashtags = this.text ?? '';

        // We want to remove the longest hashtags first
        // for the case where there are 2 hashtags the start with the same string : #paris and #paris20
        const sortedHashtags = this.hashtags.selected.sort((a, b) => b.text.length - a.text.length);
        for (const hashtag of sortedHashtags) {
            textWithNoHashtags = textWithNoHashtags.replace(hashtag.text, '');
        }

        this.hashtags.selected.forEach((h) => {
            textWithNoHashtags = textWithNoHashtags.replace(h.text, '');
        });

        return textWithNoHashtags.trim();
    }

    initMedias(): void {
        this.attachments = this.attachments?.map((attachment) => new Media(attachment));
    }

    isPublished(): boolean {
        return this.published === PostPublicationStatus.PUBLISHED;
    }

    filterOutFeedbackMessageDependingOnRole(userRole: Role): void {
        if (!this.feedback) {
            return;
        }
        if (userRole === Role.ADMIN) {
            return;
        }
        this.feedback.feedbackMessages = this.feedback.feedbackMessages?.filter((fm) => fm.visibility !== FeedbackMessageVisibility.ADMIN);
    }

    // META bug : reels with copyrighted audio don't have media_url
    // https://developers.facebook.com/support/bugs/404495981650858/
    isInstagramReelWithoutVideo(): boolean {
        const isInstagramReel = this.key === PlatformKey.INSTAGRAM && this.postType === PostType.REEL;
        const hasVideo = this.socialAttachments?.[0]?.type === SocialAttachmentsMediaTypes.VIDEO;
        return isInstagramReel && !hasVideo;
    }

    getAllSmallestAttachmentUrls(): { id: string; url: string }[] {
        if (this.attachments?.length) {
            return this.attachments.map((attachment) => ({
                id: attachment.id,
                url: attachment.thumbnail ?? attachment.urls?.small ?? attachment.urls?.igFit ?? attachment.urls?.original,
            }));
        }
        return this.socialAttachments.map((attachment) => ({
            id: attachment.socialId ?? '',
            url: attachment.thumbnailUrl ?? attachment.urls.original,
        }));
    }

    containsVideo(): boolean {
        return (this.attachments ?? []).some((a) => a.type === MediaType.VIDEO);
    }
}

export class PostWithInsights {
    socialId: string;
    key: string;
    username: string;
    permalink: string;
    caption: string;
    comments = 0;
    likes = 0;
    impressions = 0;
    plays = 0;
    shares = 0;
    saved = 0;
    reach = 0;
    url: string;
    backupUrl: string;
    createdAt: Date;
    thumbnail: string;
    insights?: any;
    carouselUrls?: {
        url: string;
        type: MediaType;
    }[];
    backupCarouselUrls: {
        url: string;
        type: MediaType;
    }[];
    nbFollowers: number;
    accountEngagement: number;
    postType: PostType;
    engagementRate: number | null;

    public constructor(init?: any) {
        Object.assign(this, init);
        if (init.mediaType) {
            // old bookmarkedPosts can have mediaType instead of type
            this.postType = init.mediaType;
        }
        if (this.caption) {
            this.caption = this.caption.replace(/#/g, ' #');
        }
        this.engagementRate = this.getEngagementRate();
    }

    public displayDate(withHours = true): string {
        return withHours
            ? DateTime.fromJSDate(new Date(this.createdAt)).toLocaleString(DateTime.DATETIME_SHORT)
            : DateTime.fromJSDate(new Date(this.createdAt)).toLocaleString(DateTime.DATE_SHORT);
    }

    getMetricByKey(key: string): string | number | null {
        switch (key) {
            case 'impression':
                return this.getImpressions();
            case 'engagement':
                return this.getEngagementRate();
            case 'like':
                return this.getLikes();
            case 'comment':
                return this.getComments();
            case 'createdAt':
                return this.displayDate(false);
            default:
                return null;
        }
    }

    getEngagementRate(): number | null {
        if (this.getNbFollowers() > 0) {
            return (this.getEngagement() / this.getNbFollowers()) * 100;
        }
        return null;
    }

    getImpressions(): number {
        return this.impressions;
    }

    getLikes(): number {
        return this.likes;
    }

    getComments(): number {
        return this.comments;
    }

    getShares(): number {
        return this.shares;
    }

    getSaves(): number {
        return this.saved;
    }

    getEngagement(): number {
        return this.likes + this.comments + this.saved + this.shares;
    }

    getNbFollowers(): number {
        return this.nbFollowers;
    }
}

export type PostsWithInsightsByPlatforms = { [key: string]: { data: PostWithInsights[]; error?: boolean; message?: string } }[];

export interface PostWithInsightsAndHoveredPosts extends PostWithInsights {
    hoveredPosts: { url: string }[];
}

export interface PostFilterOption {
    key: PostFilterType;
    label: string;
    sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) => number;
    selected?: boolean;
}

export enum PostFilterType {
    IMPRESSION = 'impression',
    ENGAGEMENT = 'engagement',
    LIKE = 'like',
    COMMENT = 'comment',
    CREATED_AT = 'createdAt',
}
export class PostFilterOptions {
    static get(translate: TranslateService, defaultKey: string, keys: string[] = Object.values(PostFilterType)): PostFilterOption[] {
        const postFilterOptions = [
            {
                key: PostFilterType.IMPRESSION,
                label: translate.instant('post_filter_options.impression'),
                sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) =>
                    (direction ?? 0) > 0 ? postA.impressions - postB.impressions : postB.impressions - postA.impressions,
                selected: false,
            },
            {
                key: PostFilterType.ENGAGEMENT,
                label: translate.instant('post_filter_options.engagement'),
                sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) =>
                    (direction ?? 0) > 0
                        ? (postA.getEngagementRate() || 0) - (postB.getEngagementRate() || 0)
                        : (postB.getEngagementRate() || 0) - (postA.getEngagementRate() || 0),
                selected: false,
            },
            {
                key: PostFilterType.LIKE,
                label: translate.instant('post_filter_options.like'),
                sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) =>
                    (direction ?? 0) > 0 ? postA.likes - postB.likes : postB.likes - postA.likes,
                selected: false,
            },
            {
                key: PostFilterType.COMMENT,
                label: translate.instant('post_filter_options.comment'),
                sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) =>
                    (direction ?? 0) > 0 ? postA.getComments() - postB.getComments() : postB.getComments() - postA.getComments(),
                selected: false,
            },
            {
                key: PostFilterType.CREATED_AT,
                label: translate.instant('post_filter_options.created_at'),
                sort: (postA: PostWithInsights, postB: PostWithInsights, direction?: number) =>
                    (direction ?? 0) > 0
                        ? new Date(postA.createdAt).getTime() - new Date(postB.createdAt).getTime()
                        : new Date(postB.createdAt).getTime() - new Date(postA.createdAt).getTime(),
                selected: false,
            },
        ];
        // @ts-ignore
        postFilterOptions.find((pFO) => pFO.key === defaultKey).selected = true;
        return postFilterOptions.filter((pFO) => keys.includes(pFO.key));
    }
}

export interface PostStatus {
    type: ExtendedPostPublicationStatus;
    icon: string;
    iconColor: string;
    subtext?: string;
    backgroundColor?: string;
    smallSubText?: string;
    subtextHours?: string;
}

const clarifySocialError = (error: string, _translate: TranslateService): string | null => {
    if (!error) {
        return null;
    }
    const clearerMediaErrors = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Invalid media': _translate.instant('social_posts.new_social_post.invalid_media'),
    };

    const fbErrorSubCodeToClearErrorMessage = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '2207019': _translate.instant('social_posts.new_social_post.invalid_location'),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '2207026': _translate.instant('social_posts.new_social_post.invalid_media'),
    };

    const errorFromSubCode = fbErrorSubCodeToClearErrorMessage[error?.split('fbSubcode::')?.[1]];

    if (errorFromSubCode) {
        return errorFromSubCode;
    }

    if (typeof error === 'object') {
        error = JSON.stringify(error, errorReplacer);
    }

    // See https://docs.google.com/spreadsheets/d/1HUoqlDCrCCv-dNho3t-lGQh_LuupyH_cMJkK3REMc3k/edit?gid=0#gid=0 for the detailed mapping
    if (
        error.match(/Cannot destructure property 'type' of 'post.attachments[0]' as it is undefined/) ||
        error === MalouErrorCode.POST_MUST_HAVE_MEDIA
    ) {
        return _translate.instant('posts.no_media');
    }
    if (error.match(/Error validating access token/)) {
        return _translate.instant('posts.reconnect_platform');
    }
    if (error.match(/user is not an Instagram Business/)) {
        return _translate.instant('posts.not_instagram_business');
    }
    if (
        error.match(/Service temporarily unavailable/) ||
        error.match(/Please retry your request later/) ||
        error.match(/ESOCKETTIMEDOUT/)
    ) {
        return _translate.instant('posts.service_temporarily_unavailable');
    }
    if (error.match(/platform not connected/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/does not have permission/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/Need credentialId/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/not authorized application/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/aspect ratio/)) {
        return _translate.instant('social_posts.ratio_error');
    }
    if (error.match(/hasn't authorized/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/session has been invalidated/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/(Platform not found|Cannot read property)/)) {
        return _translate.instant('social_posts.publish_error_platform_not_found');
    }
    if (error.match(/missing permissions/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(/Only photo or video can be accepted as media/)) {
        return _translate.instant('social_posts.media_error');
    }
    if (error.match(/Missing or invalid image file/)) {
        return _translate.instant('social_posts.media_error');
    }
    if (error.match(/first_comment_not_posted/)) {
        return _translate.instant('social_posts.first_comment_not_posted');
    }
    if (error.match(/User access is restricted/)) {
        return _translate.instant('social_posts.new_social_post.restricted_account');
    }
    if (error.match(/OAuthException/)) {
        return _translate.instant('social_posts.reconnect_platform');
    } // keep in last spot because it is not a clear information
    if (error.match(/Fatal/) || error.match(/post took too long/)) {
        return _translate.instant('social_posts.fatal_error');
    }
    if (error.match(/An unknown error occurred/) || error.match(/temporarily unavailable/)) {
        return _translate.instant('social_posts.unknown_error_occurred');
    }
    if (error.match(/ig_get_post_timeout_error/)) {
        return _translate.instant('social_posts.new_social_post.timed_out');
    }
    if (error?.match(/\(#200\) Permissions error/)) {
        return _translate.instant('social_posts.reconnect_platform');
    }
    if (error.match(PostError.STORY_NOT_PUBLISHED_BECAUSE_PREVIOUS_ONE_WAS_NOT_PUBLISHED)) {
        return _translate.instant('social_posts.previous_story_failed');
    }
    if (clearerMediaErrors[error]) {
        return clearerMediaErrors[error];
    }
    if (error.match(/\d::/)) {
        // message format is : message::1::error1__2::error2--fbCode::code--fbSubCode::subcode
        const errorMessage = error.match(/message::(.*)--fbCode/)?.[1];
        const toMediaError = (list: string[]): string =>
            list
                .map((mediaError: string) => {
                    const [num, err] = mediaError.split('::');
                    return 'Media ' + num + ': ' + clearerMediaErrors[err] || err;
                })
                .join('\r\n');
        if (errorMessage) {
            const mediaErrorsList = errorMessage.split('__');
            return toMediaError(mediaErrorsList);
        }
        toMediaError(error.split('__'));
    }
    return error;
};

const clarifySeoError = (error: any, _translate: TranslateService, publicationStatus: PostPublicationStatus): string | null => {
    if (![PostPublicationStatus.REJECTED, PostPublicationStatus.ERROR].includes(publicationStatus)) {
        return null;
    }
    if (!error) {
        return _translate.instant('posts.rejected_by_platform');
    }
    if (error.error?.config) {
        return _translate.instant('posts.gmb_not_connected');
    }
    if (error.error?.message?.includes('Missing credentialId')) {
        return _translate.instant('posts.management_mode');
    }
    if (typeof error === 'string') {
        if (error.match(/Forbidden/)) {
            return _translate.instant('posts.operation_failed');
        }
        if (error.match(/connect ECONNREFUSED/)) {
            return _translate.instant('posts.operation_failed_retry');
        }
        if (error.match(PostError.POST_STUCK_PROCESSING)) {
            return _translate.instant('posts.post_stuck_processing');
        }
        if (error.match(MalouErrorCode.CREDENTIALS_GMB_POST_SCHEDULE_END_DATE_IN_PAST)) {
            return _translate.instant('posts.gmb_post_schedule_end_date_in_past');
        }
        return error;
    }
    return String(error);
};

export class PostWithJob extends Post {
    job?: AgendaJob;
    clarifyError?: (err: any, translate: TranslateService) => string | null;
    postStatus: PostStatus;

    constructor(
        data:
            | Partial<PostWithJob>
            | (Partial<Post> & { job?: AgendaJobApiResponse; clarifyError?: (err: any, translate: TranslateService) => string })
    ) {
        super(data);
        if (data.jobs?.length) {
            this.job = this.initJob(data.jobs?.[0]);
        }
        if (data.job) {
            this.job = this.initJob(data.job);
        }
        this.clarifyError = data.clarifyError;
        this.postStatus = this.getPostStatus();
    }

    isForLater(): boolean {
        return !!this.job && !this.job?.lastRunAt && this.published !== PostPublicationStatus.DRAFT;
    }

    initJob(data: AgendaJobApiResponse | AgendaJob): AgendaJob {
        const nextRunAt = (!!data.nextRunAt && new Date(data.nextRunAt)) || undefined;
        const failedAt = new Date(data.failedAt) || undefined;
        const lastFinishedAt = new Date(data.lastFinishedAt) || undefined;
        const lastRunAt = (!!data.lastRunAt && new Date(data.lastRunAt)) || undefined;
        return {
            ...data,
            nextRunAt,
            failedAt,
            lastFinishedAt,
            lastRunAt,
        };
    }

    getPostStatus(): PostStatus {
        if (this.job?.failCount || this.published === PostPublicationStatus.REJECTED || this.published === PostPublicationStatus.ERROR) {
            return {
                type: ExtendedPostPublicationStatus.ERROR,
                icon: 'remove',
                iconColor: '#EE116E',
                backgroundColor: '#EE116E',
                subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
            };
        }
        if (this.isForLater() || (this.published === PostPublicationStatus.PENDING && this.plannedPublicationDate)) {
            const alarmIcon = {
                icon: 'alarm',
                iconColor: '#FFBA4C',
                backgroundColor: '#FFBA4C',
            };
            const errorIcon = {
                icon: 'remove',
                iconColor: '#EE116E',
                backgroundColor: '#EE116E',
            };
            const icon = this.hasInstagramServerError() ? errorIcon : alarmIcon;
            const laterDisplayedDate = DateTime.fromJSDate(new Date(this.job?.nextRunAt || this.plannedPublicationDate));
            return {
                type: ExtendedPostPublicationStatus.PENDING,
                subtext: laterDisplayedDate.toFormat('DDDD - t'),
                smallSubText: laterDisplayedDate.toFormat('D - t'),
                subtextHours: laterDisplayedDate.toFormat('t'),
                ...icon,
            };
        }
        if (
            (this.published === PostPublicationStatus.PENDING && !this.plannedPublicationDate) ||
            this.published === PostPublicationStatus.DRAFT
        ) {
            return {
                type: ExtendedPostPublicationStatus.DRAFT,
                icon: 'draft',
                iconColor: '#6A52FD',
                backgroundColor: '#6A52FD',
                subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
            };
        }
        const draftDisplayedDate = DateTime.fromJSDate(this.plannedPublicationDate || this.createdAt);
        if (this.published === PostPublicationStatus.PENDING && this.plannedPublicationDate && !this.job) {
            return {
                type: ExtendedPostPublicationStatus.DRAFT,
                icon: 'draft',
                iconColor: '#6A52FD',
                backgroundColor: '#6A52FD',
                subtext: draftDisplayedDate.toFormat('DDDD - t'),
                smallSubText: draftDisplayedDate.toFormat('D - t'),
                subtextHours: draftDisplayedDate.toFormat('t'),
            };
        }
        if (this.published === PostPublicationStatus.PUBLISHED && !this.job?.failCount) {
            return {
                type: ExtendedPostPublicationStatus.PUBLISHED,
                icon: 'check',
                iconColor: '#34B467',
                backgroundColor: '#34B467',
                subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
            };
        }
        return {
            type: ExtendedPostPublicationStatus.DRAFT,
            icon: 'remove',
            iconColor: '#EE116E',
            backgroundColor: '#EE116E',
            subtext: draftDisplayedDate.toFormat('DDDD - t'),
            smallSubText: draftDisplayedDate.toFormat('D - t'),
            subtextHours: draftDisplayedDate.toFormat('t'),
        };
    }

    hasInstagramServerError(): boolean {
        return this.key === PlatformKey.INSTAGRAM && this.errorData?.includes('2207001'); // TODO: A changer quand on aura passer le AppConstant en namespace
    }

    copyWith(
        props:
            | Partial<PostWithJob>
            | (Partial<Post> & { job?: AgendaJobApiResponse; clarifyError?: (err: any, translate: TranslateService) => string })
    ): PostWithJob {
        return new PostWithJob({ ...this, ...props });
    }

    canEditPost(): boolean {
        if (this.published === PostPublicationStatus.REJECTED) {
            return false;
        }
        if (this.key === PlatformKey.MAPSTR) {
            return false;
        }
        if (this.key === PlatformKey.FACEBOOK && this.postType === PostType.REEL) {
            return false;
        }
        return this.postStatus.type === ExtendedPostPublicationStatus.PUBLISHED ? this.key !== PlatformKey.INSTAGRAM : true;
    }

    canDeletePost(): boolean {
        if (this.key === PlatformKey.FACEBOOK && this.postType === PostType.REEL) {
            return false;
        }
        return (
            this.postStatus.type !== ExtendedPostPublicationStatus.PUBLISHED ||
            this.keys.includes(PlatformKey.FACEBOOK) ||
            this.key === PlatformKey.FACEBOOK
        );
    }
}

export class SocialPost extends PostWithJob {
    id: string;

    constructor(data: Partial<PostWithJob>) {
        super(data);
        // todo: remove when we remove _id from the backend
        this.id = this._id;
        this.clarifyError = clarifySocialError;
        this._addHashtagsToText();
    }

    copyWith(data: Partial<PostWithJob>): SocialPost {
        return new SocialPost({ ...this, ...data });
    }

    private _addHashtagsToText(): void {
        if (this.hashtags.selected.length) {
            this.text = this.text + ' ' + this.hashtags.selected.map((ht) => ht.text).join(' ');
        }
    }
}

export class SeoPost extends PostWithJob {
    clarifyError: (err: any, translate: TranslateService, postPublicationStatus?: PostPublicationStatus) => string | null;

    constructor(data: Partial<PostWithJob>) {
        super(data);
        this.clarifyError = clarifySeoError;
    }
}

export type StoryStatus = PostStatus & { borderColor: string };
export class Story extends Post {
    storyStatus: StoryStatus;
    isActive = false;
    remainingHours = 0;
    clarifyError?: (err: any, translate: TranslateService) => string | null;

    constructor(data: Partial<Post>) {
        super(data);
        this.isActive = this.getIsActive();
        this.storyStatus = this.getStoryStatus();
        this.remainingHours = this.getRemainingHours();
        this.clarifyError = clarifySocialError;
    }

    getStoryStatus(): StoryStatus {
        switch (this.published) {
            case PostPublicationStatus.PUBLISHED:
                return {
                    type: this.isActive ? ExtendedPostPublicationStatus.ACTIVE : ExtendedPostPublicationStatus.PUBLISHED,
                    icon: this.isActive ? 'timer' : 'check',
                    iconColor: '#34B467',
                    backgroundColor: '#FFFFFF',
                    subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                    smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                    subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
                    borderColor: '#FFFFFF',
                };
            case PostPublicationStatus.PENDING:
                return {
                    type: ExtendedPostPublicationStatus.PENDING,
                    icon: 'alarm',
                    iconColor: '#FFBA4C',
                    backgroundColor: '#F2F2FF',
                    subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                    smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                    subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
                    borderColor: '#F2F2FF',
                };
            case PostPublicationStatus.REJECTED:
            case PostPublicationStatus.ERROR:
                return {
                    type: ExtendedPostPublicationStatus.ERROR,
                    icon: 'remove',
                    iconColor: '#EE116E',
                    backgroundColor: '#FFFFFF',
                    subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                    smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                    subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
                    borderColor: '#EE116E',
                };
            default:
                return {
                    type: ExtendedPostPublicationStatus.DRAFT,
                    icon: 'draft',
                    iconColor: '#6A52FD',
                    backgroundColor: '#F2F2FF',
                    subtext: DateTime.fromJSDate(this.getPostDate()).toFormat('DDDD - t'),
                    smallSubText: DateTime.fromJSDate(this.getPostDate()).toFormat('D - t'),
                    subtextHours: DateTime.fromJSDate(this.getPostDate()).toFormat('t'),
                    borderColor: '#F2F2FF',
                };
        }
    }

    getIsActive(): boolean {
        if (this.published !== PostPublicationStatus.PUBLISHED) {
            return false;
        }
        const publishedDate = DateTime.fromJSDate(this.getPostDate());
        const endActiveStory = publishedDate.plus({ day: 1 });
        return new Date().getTime() < endActiveStory.toJSDate().getTime();
    }

    getRemainingHours(): number {
        if (!this.isActive) {
            return 0;
        }
        const publishedDate = DateTime.fromJSDate(this.getPostDate());
        const endActiveStory = publishedDate.plus({ day: 1 });
        const remainingHours = endActiveStory.diff(DateTime.now(), 'hours').toObject().hours;
        return remainingHours ? Math.floor(remainingHours) : 0;
    }
}

export interface ActionsAfterEditPostClosed {
    postId?: string | null;
    reload?: boolean;
    duplicatedPostId?: string | null;
    shouldDeleteAfterClose?: boolean;
    savedAsDraft?: boolean;
}

export interface ActionsAfterEditStoriesClosed {
    reload: boolean;
}

export interface PostHashtags {
    selected: Hashtag[];
    suggested: Hashtag[];
}
