import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { isSameDay, PlatformKey, ReviewAnalysisChartDataTag } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SegmentAnalysesService } from ':core/services/segment-analyses.service';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { TagsBarChartComponent } from ':shared/components/review-analyses-v2/tags-bar-chart/tags-bar-chart.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-semantic-analysis',
    standalone: true,
    imports: [NgTemplateOutlet, TranslateModule, SkeletonComponent, IllustrationPathResolverPipe, TagsBarChartComponent],
    templateUrl: './semantic-analysis.component.html',
    styleUrl: './semantic-analysis.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SemanticAnalysisComponent implements OnInit {
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _segmentAnalysesService = inject(SegmentAnalysesService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _store = inject(Store);

    readonly Illustration = Illustration;

    readonly isLoading = signal(true);
    readonly reviewAnalysesChartData = signal<ReviewAnalysesChartDataByRestaurantId>(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    readonly hasData = computed(() => this.reviewAnalysesChartData().hasData(ReviewAnalysisChartDataTag.TOTAL));

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    readonly dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(StatisticsSelectors.selectDatesFilter)
        .pipe(
            distinctUntilChanged((prev, curr) => {
                const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
                const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
                return isSameStartDate && isSameEndDate;
            })
        );

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)),
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );

    constructor() {
        effect(() => {
            this.isLoadingEvent.emit(this.isLoading());
        });
    }

    ngOnInit(): void {
        combineLatest([
            this.dates$.pipe(tap(() => this._resetChartData())),
            this.platformKeys$.pipe(tap(() => this._resetChartData())),
            this._restaurantsService.restaurantSelected$,
        ])
            .pipe(
                filter(([dates, platforms, restaurant]) => !!restaurant && !!dates.startDate && !!dates.endDate && platforms.length > 0),
                tap(() => this.isLoading.set(true)),
                switchMap(([dates, platforms, restaurant]) => {
                    const { startDate, endDate } = dates;
                    const restaurantIds = [restaurant!._id];
                    const emptyData = this._buildEmptyChartData(restaurantIds);
                    return this._segmentAnalysesService
                        .getSegmentAnalysesChartData({
                            startDate,
                            endDate,
                            keys: platforms,
                            restaurantIds,
                        })
                        .pipe(map((res) => emptyData.merge(ReviewAnalysesChartDataByRestaurantId.fromDto(res.data))));
                }),
                catchError((error) => {
                    this.isLoading.set(false);
                    console.error('Error while fetching review analyses chart data', error);
                    return of(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (newReviewAnalysesChartData: ReviewAnalysesChartDataByRestaurantId) => {
                    this.reviewAnalysesChartData.update((reviewAnalysesChartData) =>
                        reviewAnalysesChartData.merge(newReviewAnalysesChartData)
                    );
                    this.isLoading.set(false);
                },
            });
    }

    private _resetChartData(): void {
        this.reviewAnalysesChartData.set(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    }

    private _buildEmptyChartData(restaurantIds: string[]): ReviewAnalysesChartDataByRestaurantId {
        return ReviewAnalysesChartDataByRestaurantId.buildEmptyChartData(restaurantIds);
    }
}
