import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const getSegmentAnalysesChartDataBodyValidator = z
    .object({
        restaurantIds: z.array(objectIdValidator),
        startDate: z.string().datetime({ offset: true }).nullish(),
        endDate: z.string().datetime({ offset: true }).nullish(),
        keys: z.array(z.nativeEnum(PlatformKey)),
    })
    .transform((data) => ({
        restaurantIds: data.restaurantIds ?? [],
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        keys: data.keys ?? [],
    }));

export type GetSegmentAnalysesChartDataBodyDto = z.infer<typeof getSegmentAnalysesChartDataBodyValidator>;
