import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { isValidDate, MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { CommunityData } from ':modules/statistics/social-networks/community-v2/community.interface';
import { PeriodLegendComponent } from ':shared/components/period-legend/period-legend.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    computePointsRadius,
    DEFAULT_DASHED_LINE_OPTIONS,
    formatViewByDate,
    malouChartColorBluePurple,
    malouChartColorLighterBlue,
    malouChartColorPink,
    malouChartColorPinkLight60Percent,
    toggleVisibilityOnMultipleDatasets,
} from ':shared/helpers';
import { SelectionModel } from ':shared/helpers/selection-model';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';
const SMALL_TAB_SPACE = ' ';

type LineOrBarChartType = Extract<ChartType, 'line' | 'bar'>;

@Component({
    selector: 'app-community-chart-v2',
    templateUrl: './community-chart.component.html',
    styleUrls: ['./community-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, PeriodLegendComponent],
    providers: [ShortNumberPipe, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityChartV2Component {
    readonly communityData = input.required<CommunityData>();
    readonly labels = input.required<Date[]>();
    readonly previousPeriodDates = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly hiddenDatasetIndexes = input<number[]>([]);

    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly CHART_TYPE: LineOrBarChartType = 'line';

    readonly chartDataSets = computed((): ChartDataset<LineOrBarChartType, ChartDataArray>[] => {
        const communityData = this.communityData();
        const hiddenDatasetIndexes = this.hiddenDatasetIndexes();

        const chartDataSets = this._computeChartData(communityData);
        if (hiddenDatasetIndexes.length) {
            return chartDataSets.filter((_, index) => !hiddenDatasetIndexes.includes(index));
        }
        return chartDataSets;
    });

    readonly chartLabels = computed((): Date[] => this._computeChartLabels(this.labels()));
    readonly comparisonPeriod = computed(() => this.communityData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly chartOption = computed(
        (): ChartOptions<LineOrBarChartType> =>
            this._computeChartOptions(this.communityData(), this.viewBy(), this.labels(), this.previousPeriodDates())
    );

    hoveredElement: { datasetIndex: number; index: number } | null = null;
    readonly hiddenDatasetIndexesSelection: SelectionModel<number> = new SelectionModel<number>();

    private readonly _FACEBOOK_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key');
    private readonly _INSTAGRAM_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.INSTAGRAM, 'platform_key');
    private readonly _NO_DATA_TRAD = this._translateService.instant('common.no_data');

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {}

    private _computeChartData(data: CommunityData): ChartDataset<LineOrBarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('statistics.social_networks.community.followers'),
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                pointBorderColor: malouChartColorPink,
                pointBackgroundColor: malouChartColorPink,
                type: 'line',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: data.totalFollowers,
                pointRadius: computePointsRadius(data.totalFollowers),
            },
            {
                label: this._translateService.instant('statistics.social_networks.community.followers'),
                borderColor: malouChartColorPinkLight60Percent,
                backgroundColor: malouChartColorPinkLight60Percent,
                pointBorderColor: malouChartColorPinkLight60Percent,
                pointBackgroundColor: malouChartColorPinkLight60Percent,
                type: 'line',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: data.previousTotalFollowers,
                pointRadius: computePointsRadius(data.previousTotalFollowers),
                borderDash: DEFAULT_DASHED_LINE_OPTIONS,
            },
            {
                label: this._translateService.instant('statistics.social_networks.community.new_followers'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                data: data.totalNewFollowers,
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(
        communityData: CommunityData,
        viewBy: ViewBy,
        labels: Date[],
        previousPeriodDates: Date[]
    ): ChartOptions<LineOrBarChartType> {
        return {
            onHover: (_, element): void => {
                if (!element.length) {
                    this.hoveredElement = null;
                    return;
                }
                this.hoveredElement = {
                    datasetIndex: element[0]?.datasetIndex,
                    index: element[0]?.index,
                };
            },
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    position: 'nearest',
                    filter: (tooltipItem: TooltipItem<any>): boolean =>
                        this.hoveredElement ? this._isAssociatedDatasetIndex(this.hoveredElement, tooltipItem) : false,
                    callbacks: {
                        title: (): string => '',
                        label: (tooltipItem: TooltipItem<any>): string[] =>
                            this._computeTooltipLabel(tooltipItem, labels, viewBy, previousPeriodDates, communityData),
                    },
                },
                legend: {
                    align: 'end',
                    labels: {
                        usePointStyle: true,
                        filter: (legendItem: LegendItem): boolean => legendItem?.datasetIndex !== 1,
                    },
                    onClick: (chartEvent, legendItem, legend): void => {
                        const chart = legend.chart;

                        const datasetIndex = legendItem.datasetIndex as number;
                        const isDatasetVisible = chart.isDatasetVisible(datasetIndex);
                        if (datasetIndex !== 0) {
                            isDatasetVisible
                                ? this.hiddenDatasetIndexesSelection.select(datasetIndex)
                                : this.hiddenDatasetIndexesSelection.unselect(datasetIndex);
                            Chart.defaults.plugins.legend.onClick.bind(this)(chartEvent, legendItem, legend);
                            this.hiddenDatasetIndexesChange.emit(this.hiddenDatasetIndexesSelection.getSelection());
                            return;
                        }

                        const associatedDatasetIndex = datasetIndex + 1;
                        isDatasetVisible
                            ? this.hiddenDatasetIndexesSelection.select([datasetIndex, associatedDatasetIndex])
                            : this.hiddenDatasetIndexesSelection.unselect([datasetIndex, associatedDatasetIndex]);

                        toggleVisibilityOnMultipleDatasets(chart, [datasetIndex, associatedDatasetIndex], !isDatasetVisible);
                        this.hiddenDatasetIndexesChange.emit(this.hiddenDatasetIndexesSelection.getSelection());
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(this.viewBy()),
                        isoWeekday: true,
                        unit: this._mapViewByToTimeUnit(this.viewBy()),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis1: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
                yAxis2: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        stepSize: 1,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                    position: 'right',
                },
            },
        };
    }

    private _computeTooltipLabel(
        item: TooltipItem<LineOrBarChartType>,
        labels: Date[],
        viewBy: ViewBy,
        previousPeriodDates: Date[],
        communityData: CommunityData
    ): string[] {
        const date = this._computeTooltipLabelDate(item, labels, viewBy, previousPeriodDates);
        const values = this._computeTooltipLabelLines(item, communityData);
        return [`${SMALL_TAB_SPACE}${date}`, ...values];
    }

    private _computeTooltipLabelDate(
        item: TooltipItem<LineOrBarChartType>,
        labels: Date[],
        viewBy: ViewBy,
        previousPeriodDates: Date[]
    ): string {
        if (!item) {
            return '';
        }

        const currentDate = DateTime.fromJSDate(new Date(labels[item.dataIndex]));
        let title: string = formatViewByDate(currentDate.toJSDate(), viewBy);

        if (item.datasetIndex === 1) {
            const associatedDateInPreviousPeriod = previousPeriodDates[item.dataIndex];
            if (!isValidDate(associatedDateInPreviousPeriod)) {
                title = '-';
            }
            title = formatViewByDate(associatedDateInPreviousPeriod, viewBy);
        }
        if (viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.social_networks.community.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTooltipLabelLines(item: TooltipItem<LineOrBarChartType>, communityData: CommunityData): string[] {
        let facebookValue;
        let instagramValue;
        switch (item.datasetIndex) {
            case 1:
                facebookValue = communityData.previousFacebookFollowers[item.dataIndex];
                instagramValue = communityData.previousInstagramFollowers[item.dataIndex];
                break;
            case 2:
                facebookValue = communityData.facebookNewFollowers[item.dataIndex];
                instagramValue = communityData.instagramNewFollowers[item.dataIndex];
                break;
            case 0:
            default:
                facebookValue = communityData.facebookFollowers[item.dataIndex];
                instagramValue = communityData.instagramFollowers[item.dataIndex];
        }

        const facebookLabel = `    ${this._FACEBOOK_KEY_TRAD}: ${facebookValue ?? this._NO_DATA_TRAD}`;
        const instagramLabel = `    ${this._INSTAGRAM_KEY_TRAD}: ${instagramValue ?? this._NO_DATA_TRAD}`;

        return [facebookLabel, instagramLabel];
    }

    private _mapViewByToTimeUnit(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    // dataset 1 is the previous period of dataset 0, dataset 2 on its own
    private _isAssociatedDatasetIndex(item1: { datasetIndex: number }, item2: { datasetIndex: number }): boolean {
        const ownDatasetIndex = 2;
        return (
            (item1.datasetIndex === ownDatasetIndex && item2.datasetIndex === ownDatasetIndex) ||
            (item1.datasetIndex !== ownDatasetIndex && item2.datasetIndex !== ownDatasetIndex)
        );
    }
}
