@if (!isLoading()) {
    @if (hasData()) {
        <div class="flex w-full gap-6 md:flex-col">
            <div class="malou-simple-card flex w-[50%] break-inside-avoid flex-col gap-3 px-6 py-3 pb-12 md:w-full md:px-2">
                <div class="flex flex-col gap-1">
                    <div class="malou-text-section-title malou-color-text-1">
                        {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis' | translate }}
                    </div>
                    @if (isNewSemanticAnalysisFeatureEnabled()) {
                        <div class="malou-text-12--regular malou-color-text-2">
                            {{ 'statistics.e_reputation.reviews_analysis.on_analyzed_reviews' | translate }}
                        </div>
                    }
                </div>
                <div class="justify-content-between flex lg:flex-col-reverse">
                    <div class="h-60 min-w-0 flex-1">
                        <app-tags-bar-chart
                            [chartData]="reviewAnalysesChartData()"
                            [reviewAnalysesFilter]="null"
                            [shouldDisplayAnalysesTagClickableLabels]="false"
                            [isFromAggregatedStatistics]="false">
                        </app-tags-bar-chart>
                    </div>
                </div>
            </div>
            <div class="w-[50%] md:w-full">
                <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
            </div>
        </div>
    } @else {
        <ng-container
            [ngTemplateOutlet]="emptyTemplate"
            [ngTemplateOutletContext]="{
                text: 'aggregated_statistics.e_reputation.reviews_analysis.semantic_analysis_not_available' | translate,
                subtext: 'aggregated_statistics.e_reputation.reviews_analysis.edit_filters' | translate,
            }">
        </ng-container>
    }
} @else {
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
}

<ng-template let-text="text" let-subtext="subtext" #emptyTemplate>
    <div class="my-4 flex flex-col items-center py-4">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">
            {{ text }}
        </span>
        <span class="malou-text-10--regular">
            {{ subtext }}
        </span>
    </div>
</ng-template>

<ng-template #skeletonTemplate>
    <div class="flex w-full gap-4 md:flex-col">
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
    </div>
</ng-template>
