import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, take } from 'rxjs';

import { MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { CommunityChartData } from ':modules/statistics/social-networks/social-networks.interfaces';
import { selectDatesFilter, selectFollowersDataV2 } from ':modules/statistics/store/statistics.selectors';
import { formatDateToISO, getDaysFromCurrentRange } from ':shared/helpers';
import { DatesAndPeriod } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface FollowersData {
    dates: DatesAndPeriod;
    followers: CommunityChartData;
}
interface Options {
    platformKeys: PlatformKey[];
}

@Injectable({ providedIn: 'root' })
export class FollowersCsvInsightsV2Service extends AbstractCsvService<FollowersData, Options> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected override _getData$(): Observable<FollowersData> {
        return forkJoin([
            this._store.select(selectFollowersDataV2).pipe(take(1)),
            this._store.select(selectDatesFilter).pipe(take(1)),
        ]).pipe(
            map(([followersData, datesFilter]) => ({
                dates: datesFilter,
                followers: followersData,
            }))
        );
    }

    protected override _isDataValid(data: FollowersData, options: Options): boolean {
        if (!data?.dates?.startDate) {
            return false;
        }
        if (options.platformKeys.length === 1) {
            const platformKey = options.platformKeys[0];
            return !!data?.followers?.[platformKey];
        }
        return !!data?.followers?.facebook && !!data?.followers?.instagram;
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Followers'];
    }

    protected override _getCsvDataRows({ dates, followers }: FollowersData, { platformKeys }: Options): CsvAsStringArrays {
        const { startDate, endDate } = dates;
        if (!startDate || !endDate) {
            return [];
        }
        const days = getDaysFromCurrentRange(startDate, endDate);
        return days.map((day) => {
            const localDate = day?.toLocaleDateString();
            const formattedDay = formatDateToISO(day);
            const platformsFollowers = platformKeys
                .map((platformKey) => followers[platformKey]?.[MalouMetric.FOLLOWERS]?.[formattedDay] ?? 0)
                .reduce((acc, next) => acc + next, 0)
                .toString();
            return [localDate, platformsFollowers];
        });
    }
}
