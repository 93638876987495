import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getRankingsByRestaurantIdParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetRankingsByRestaurantIdParamsDto = z.infer<typeof getRankingsByRestaurantIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getRankingsByRestaurantIdQueryValidator = z
    .object({
        start_date: z.string().datetime({ offset: true }).nullish().optional(),
        end_date: z.string().datetime({ offset: true }).nullish().optional(),
        previous_period: z
            .string()
            .refine((value) => ['true', 'false'].includes(value))
            .optional(),
    })
    .transform((data) => ({
        startDate: data.start_date ? new Date(data.start_date) : undefined,
        endDate: data.end_date ? new Date(data.end_date) : undefined,
        previousPeriod: data.previous_period ? data.previous_period === 'true' : undefined,
    }));
export type GetRankingsByRestaurantIdQueryDto = z.infer<typeof getRankingsByRestaurantIdQueryValidator>;

// ------------------------------------------------------------------------------------------

export const updateKeywordVolumeFromAdminParamsValidator = z
    .object({
        keyword_id: objectIdValidator,
    })
    .transform((data) => ({
        keywordId: data.keyword_id,
    }));
export type UpdateKeywordVolumeFromAdminParamsDto = z.infer<typeof updateKeywordVolumeFromAdminParamsValidator>;

export const updateKeywordVolumeFromAdminBodyValidator = z.object({
    volumeFromAdmin: z.number(),
    restaurantId: objectIdValidator,
});
export type UpdateKeywordVolumeFromAdminBodyDto = z.infer<typeof updateKeywordVolumeFromAdminBodyValidator>;
