import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ReviewsRatingCalculatorComponent } from ':modules/statistics/e-reputation/ratings/reviews-rating-calculator/reviews-rating-calculator.component';
import { PlatformsRatingsComponent } from ':shared/components/platforms-ratings/platforms-ratings.component';

@Component({
    selector: 'app-statistics-seo-ratings',
    templateUrl: './ratings.component.html',
    styleUrls: ['./ratings.component.scss'],
    standalone: true,
    imports: [NgClass, PlatformsRatingsComponent, ReviewsRatingCalculatorComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsSeoRatingsComponent {
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    readonly hasPlatformRatingsData = signal(true);
    readonly hasReviewsCalculatorData = signal(true);
    readonly hasData = computed(() => this.hasPlatformRatingsData() && this.hasReviewsCalculatorData());

    readonly isPlatformRatingsLoading = signal(false);
    readonly isReviewsCalculatorLoading = signal(false);
    readonly isLoading = computed(() => this.isPlatformRatingsLoading() || this.isReviewsCalculatorLoading());

    constructor() {
        effect(() => this.hasDataChange.emit(this.hasData()));
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }
}
