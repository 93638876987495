<div class="b w-full rounded-[10px] border border-solid border-malou-color-background-dark p-4">
    <div class="malou-text-14--bold text-malou-color-text-1">
        {{ 'date_filter.comparison_period.title' | translate }}
    </div>
    <mat-radio-group
        class="flex flex-col gap-2 px-3 py-3"
        color="primary"
        [value]="selectedComparisonPeriod()"
        (change)="onComparePeriodChange($event.value)">
        @for (period of comparisonOptions(); track $index) {
            <div class="flex items-baseline" [class.cursor-not-allowed]="period.disabled" [class.opacity-50]="period.disabled">
                <mat-radio-button
                    class="malou-small-radio-button"
                    data-testid="general-ai-settings-formal-input"
                    [disabled]="period.disabled"
                    [value]="period.key">
                </mat-radio-button>
                <div class="flex flex-col">
                    <span class="malou-text-14 text-malou-color-text-1">
                        {{ 'date_filter.comparison_period.' + period.key | translate }}
                    </span>
                    <span class="malou-text-12 sm:malou-text-11 italic text-malou-color-text-2">
                        @if (period.disabled) {
                            {{ 'date_filter.comparison_period.unavailable' | translate }}
                        } @else {
                            ({{ period.label }})
                        }
                    </span>
                </div>
            </div>
        }
    </mat-radio-group>
</div>
