import { intersection, isEqual } from 'lodash';

import { ApplicationLanguage, PlatformKey, punctuationRegex } from '@malou-io/package-utils';

import { days } from ':core/constants';

import { Address } from './address';
import { MyDate, Period, TimePeriod } from './periods';
import { Platform } from './platform';
import { Restaurant } from './restaurant';

export enum ComparisonKey {
    NAME = 'name',
    DESCRIPTIONS = 'descriptions',
    PHONE = 'phone',
    POSTAL_CODE = 'postalCode',
    FORMATTED_ADDRESS = 'formattedAddress',
    COUNTRY = 'country',
    LOCALITY = 'locality',
    ADDRESS = 'address',
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
    WEBSITE = 'website',
    MENU_URL = 'menuUrl',
    IS_CLAIMED = 'isClaimed',
    IS_CLOSED_TEMPORARILY = 'isClosedTemporarily',
    ATTRIBUTES = 'attributes',
    SPECIAL_HOURS = 'specialHours',
    REGULAR_HOURS = 'regularHours',
    CATEGORY = 'category',
    CATEGORY_LIST = 'categoryList',
}

export interface Comparison {
    key: ComparisonKey;
    niceKey: string;
    reference: any;
    compared: any;
    differences?: any[];
    isDifferent?: boolean;
}

interface AttributeDifference {
    text: string;
    value: string;
}

enum ComparisonType {
    REFERENCE = 'reference',
    COMPARED = 'compared',
}

export const DEFAULT_COMPARISON_KEYS = [
    ComparisonKey.NAME,
    ComparisonKey.IS_CLOSED_TEMPORARILY,
    ComparisonKey.WEBSITE,
    ComparisonKey.MENU_URL,
    ComparisonKey.SPECIAL_HOURS,
    ComparisonKey.REGULAR_HOURS,
    ComparisonKey.ADDRESS,
    ComparisonKey.PHONE,
    ComparisonKey.CATEGORY,
    ComparisonKey.CATEGORY_LIST,
];

export const ADMIN_COMPARISON_KEYS = [
    ComparisonKey.NAME,
    ComparisonKey.PHONE,
    ComparisonKey.POSTAL_CODE,
    ComparisonKey.FORMATTED_ADDRESS,
    ComparisonKey.COUNTRY,
    ComparisonKey.LOCALITY,
    ComparisonKey.IS_CLOSED_TEMPORARILY,
    ComparisonKey.SPECIAL_HOURS,
    ComparisonKey.MONDAY,
    ComparisonKey.TUESDAY,
    ComparisonKey.WEDNESDAY,
    ComparisonKey.THURSDAY,
    ComparisonKey.FRIDAY,
    ComparisonKey.SATURDAY,
    ComparisonKey.SUNDAY,
    ComparisonKey.DESCRIPTIONS,
    ComparisonKey.WEBSITE,
    ComparisonKey.MENU_URL,
    ComparisonKey.ATTRIBUTES,
];

export const DISPLAYED_NULLISH_VALUE = ' - ';

export class PlatformComparison {
    referenceData: Restaurant;
    comparedData: Platform;

    name: Comparison;
    descriptions: Comparison;
    phone: Comparison;
    postalCode: Comparison;
    formattedAddress: Comparison;
    country: Comparison;
    locality: Comparison;
    address: Comparison;
    hoursMonday: Comparison;
    hoursTuesday: Comparison;
    hoursWednesday: Comparison;
    hoursThursday: Comparison;
    hoursFriday: Comparison;
    hoursSaturday: Comparison;
    hoursSunday: Comparison;
    website: Comparison;
    menuUrl: Comparison;
    isClaimed: Comparison;
    isClosedTemporarily: Comparison;
    attributes: Comparison;
    specialHours: Comparison;
    regularHours: Comparison;
    category: Comparison;
    categoryList: Comparison;

    constructor(
        readonly refData: Restaurant,
        readonly compareData: Platform,
        private readonly _comparisonKeys: ComparisonKey[] = Object.values(ComparisonKey)
    ) {
        this.referenceData = new Restaurant(refData);
        this.comparedData = new Platform(compareData);
        this._startComparison();
    }

    public getComparisonsByKeys(keys: ComparisonKey[]): Comparison[] {
        const comparisonKeys = this._filterComparisonFieldsByPlatform(intersection(keys, this._comparisonKeys));
        return comparisonKeys.map((key) => this._getFieldByKey(key));
    }

    public getDifferentComparisonsByKeys(keys: ComparisonKey[]): Comparison[] {
        return this.getComparisonsByKeys(keys).filter((comparison) => comparison.isDifferent);
    }

    private _filterComparisonFieldsByPlatform(comparisonKeys: ComparisonKey[]): ComparisonKey[] {
        switch (this.comparedData.key) {
            case PlatformKey.FACEBOOK:
                return comparisonKeys.filter((comparisonKey) => comparisonKey !== ComparisonKey.MENU_URL);
            case PlatformKey.TRIPADVISOR:
                return comparisonKeys.filter((comparisonKey) => comparisonKey !== ComparisonKey.SPECIAL_HOURS);
            case PlatformKey.LAFOURCHETTE:
            case PlatformKey.FOURSQUARE:
            case PlatformKey.PAGESJAUNES:
                return comparisonKeys.filter(
                    (comparisonKey) => ![ComparisonKey.MENU_URL, ComparisonKey.IS_CLOSED_TEMPORARILY].includes(comparisonKey)
                );
            case PlatformKey.INSTAGRAM:
                return comparisonKeys.filter((comparisonKey) => [ComparisonKey.NAME, ComparisonKey.WEBSITE].includes(comparisonKey));
            case PlatformKey.UBEREATS:
                return comparisonKeys.filter((comparisonKey) =>
                    [ComparisonKey.MENU_URL, ComparisonKey.WEBSITE, ComparisonKey.REGULAR_HOURS, ComparisonKey.PHONE].includes(
                        comparisonKey
                    )
                );
            default:
                return comparisonKeys;
        }
    }

    private _getFieldByKey(key: ComparisonKey): Comparison {
        const comparisonsByKey: Record<ComparisonKey, Comparison> = {
            [ComparisonKey.NAME]: this.name,
            [ComparisonKey.DESCRIPTIONS]: this.descriptions,
            [ComparisonKey.PHONE]: this.phone,
            [ComparisonKey.POSTAL_CODE]: this.postalCode,
            [ComparisonKey.FORMATTED_ADDRESS]: this.formattedAddress,
            [ComparisonKey.COUNTRY]: this.country,
            [ComparisonKey.LOCALITY]: this.locality,
            [ComparisonKey.ADDRESS]: this.address,
            [ComparisonKey.MONDAY]: this.hoursMonday,
            [ComparisonKey.TUESDAY]: this.hoursTuesday,
            [ComparisonKey.WEDNESDAY]: this.hoursWednesday,
            [ComparisonKey.THURSDAY]: this.hoursThursday,
            [ComparisonKey.FRIDAY]: this.hoursFriday,
            [ComparisonKey.SATURDAY]: this.hoursSaturday,
            [ComparisonKey.SUNDAY]: this.hoursSunday,
            [ComparisonKey.SPECIAL_HOURS]: this.specialHours,
            [ComparisonKey.REGULAR_HOURS]: this.regularHours,
            [ComparisonKey.WEBSITE]: this.website,
            [ComparisonKey.MENU_URL]: this.menuUrl,
            [ComparisonKey.IS_CLAIMED]: this.isClaimed,
            [ComparisonKey.IS_CLOSED_TEMPORARILY]: this.isClosedTemporarily,
            [ComparisonKey.ATTRIBUTES]: this.attributes,
            [ComparisonKey.CATEGORY]: this.category,
            [ComparisonKey.CATEGORY_LIST]: this.categoryList,
        };
        return comparisonsByKey[key];
    }

    private _startComparison(): void {
        this.name = this._compareName();
        this.descriptions = this._compareDescriptions();
        this.phone = this._comparePhone();
        this.postalCode = this._comparePostalCode();
        this.formattedAddress = this._compareFormattedAddress();
        this.country = this._compareCountry();
        this.locality = this._compareLocality();
        this.address = this._compareAddress();
        this.hoursMonday = this._compareHours('MONDAY');
        this.hoursTuesday = this._compareHours('TUESDAY');
        this.hoursThursday = this._compareHours('THURSDAY');
        this.hoursWednesday = this._compareHours('WEDNESDAY');
        this.hoursFriday = this._compareHours('FRIDAY');
        this.hoursSaturday = this._compareHours('SATURDAY');
        this.hoursSunday = this._compareHours('SUNDAY');
        this.specialHours = this._compareSpecialHours();
        this.regularHours = this._compareRegularHours();
        this.website = this._compareWebSite();
        this.menuUrl = this._compareMenuUrl();
        this.isClaimed = this._compareIsClaimed();
        this.isClosedTemporarily = this._compareIsClosedTemporarily();
        this.attributes = this._compareAttributes();
        this.category = this._compareCategory();
        this.categoryList = this._compareCategoryList();
    }

    private _compareName(): Comparison {
        const reference = this.referenceData.name || DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.name || DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.NAME,
            // eslint-disable-next-line @typescript-eslint/quotes
            niceKey: "Nom de l'établissement",
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareDescriptions(): Comparison {
        const reference = this.referenceData.descriptions?.map((d) => ({
            ...d,
            text: d.text?.replace(/\n/g, ' ') ?? DISPLAYED_NULLISH_VALUE,
        })) ?? [{ language: ApplicationLanguage.FR, size: 'long', text: DISPLAYED_NULLISH_VALUE }];
        const compared = this.comparedData.descriptions?.map((d) => ({
            ...d,
            text: d.text?.replace(/\n/g, ' ') ?? DISPLAYED_NULLISH_VALUE,
        })) ?? [{ language: ApplicationLanguage.FR, size: 'long', text: DISPLAYED_NULLISH_VALUE }];
        return {
            key: ComparisonKey.DESCRIPTIONS,
            niceKey: 'Description',
            reference,
            compared,
            isDifferent: !isEqual(reference, compared),
        };
    }

    private _comparePhone(): Comparison {
        const reference = this.referenceData.phone?.toString() ?? DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.phone?.toString() ?? DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.PHONE,
            niceKey: 'Téléphone',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _comparePostalCode(): Comparison {
        const reference = this.referenceData.address?.postalCode?.toString() ?? DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.address?.postalCode?.toString() ?? DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.POSTAL_CODE,
            niceKey: 'Code postal',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareFormattedAddress(): Comparison {
        const reference =
            this.referenceData.address?.formattedAddress?.toLowerCase().replace(punctuationRegex, ' ') ?? DISPLAYED_NULLISH_VALUE;
        const compared =
            this.comparedData.address?.formattedAddress?.toLowerCase().replace(punctuationRegex, ' ') ?? DISPLAYED_NULLISH_VALUE;

        return {
            key: ComparisonKey.FORMATTED_ADDRESS,
            niceKey: 'Adresse',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareLocality(): Comparison {
        const reference = this.referenceData.address?.locality?.toLowerCase().replace(punctuationRegex, ' ') ?? DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.address?.locality?.toLowerCase().replace(punctuationRegex, ' ') ?? DISPLAYED_NULLISH_VALUE;

        return {
            key: ComparisonKey.LOCALITY,
            niceKey: 'Ville',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareCountry(): Comparison {
        const reference = this.referenceData.address?.country?.toLowerCase() ?? DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.address?.country?.toLowerCase() ?? DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.COUNTRY,
            niceKey: 'Pays',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareAddress(): Comparison {
        const referenceAddress = this.referenceData.address || null;
        const comparedAddress = this.comparedData.address || null;
        return {
            key: ComparisonKey.ADDRESS,
            niceKey: 'Adresse',
            reference: referenceAddress ? this._mapAddress(ComparisonType.REFERENCE) : DISPLAYED_NULLISH_VALUE,
            compared: comparedAddress ? this._mapAddress(ComparisonType.COMPARED) : DISPLAYED_NULLISH_VALUE,
            isDifferent: !isEqual(referenceAddress, comparedAddress),
        };
    }

    private _compareRegularHours(): Comparison {
        const sortByDay = (a: TimePeriod, b: TimePeriod): number => days[a.openDay].digit - days[b.openDay].digit;
        const referenceHours = this.referenceData.regularHours.sort(sortByDay) || [];
        const comparedHours = this.comparedData.regularHours.sort(sortByDay) || [];

        return {
            key: ComparisonKey.REGULAR_HOURS,
            // eslint-disable-next-line @typescript-eslint/quotes
            niceKey: "Heures d'ouverture",
            reference: this._cleanHours(referenceHours),
            compared: this._cleanHours(comparedHours),
            isDifferent: !isEqual(referenceHours, comparedHours),
        };
    }

    private _compareSpecialHours(): Comparison {
        const referenceHours = this.referenceData.specialHours.filter((regularHour) => this._isFutureDate(regularHour.startDate)) || [];
        const comparedHours = this.comparedData.specialHours.filter((regularHour) => this._isFutureDate(regularHour.startDate)) || [];

        return {
            key: ComparisonKey.SPECIAL_HOURS,
            niceKey: 'Horaires exceptionnels',
            reference: this._cleanHours(referenceHours),
            compared: this._cleanHours(comparedHours),
            isDifferent: !this._deepCompare(referenceHours, comparedHours),
        };
    }

    private _compareWebSite(): Comparison {
        const compared = this.comparedData.website || DISPLAYED_NULLISH_VALUE;
        const reference = this.referenceData.website || DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.WEBSITE,
            niceKey: 'Site web',
            reference,
            compared,
            isDifferent: !this._compareUrl(reference, compared),
        };
    }

    private _compareMenuUrl(): Comparison {
        const compared = this.comparedData.menuUrl || DISPLAYED_NULLISH_VALUE;
        const reference = this.referenceData.menuUrl || DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.MENU_URL,
            niceKey: 'Lien du menu',
            reference,
            compared,
            isDifferent: !this._compareUrl(reference, compared),
        };
    }

    private _compareIsClaimed(): Comparison {
        const reference = true;
        const compared = this.comparedData.isClaimed || null;
        return {
            key: ComparisonKey.IS_CLAIMED,
            niceKey: 'Revendiqué',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareIsClosedTemporarily(): Comparison {
        const reference = this.referenceData.isClosedTemporarily || null;
        const compared = this.comparedData.isClosedTemporarily || null;
        return {
            key: ComparisonKey.IS_CLOSED_TEMPORARILY,
            niceKey: 'Fermeture temporaire',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareAttributes(): Comparison {
        const reference =
            this.referenceData.attributeList
                ?.map((attr) => {
                    const platformAttribute = attr.attribute?.platformAttributes?.find((a) => a.platformKey === this.comparedData.key);
                    return { text: platformAttribute?.attributeName?.fr, value: attr.attributeValue };
                })
                ?.filter((a) => !!a) || [];
        const compared = this.comparedData.attributes || [];
        const differences = this._getAttributesDifferences(reference, compared);
        return {
            key: ComparisonKey.ATTRIBUTES,
            niceKey: 'Attributs',
            reference,
            compared,
            differences,
            isDifferent: differences.length > 0,
        };
    }

    private _compareCategory(): Comparison {
        const reference = this.referenceData.category || DISPLAYED_NULLISH_VALUE;
        const compared = this.comparedData.category || DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.CATEGORY,
            niceKey: 'Catégorie principale',
            reference,
            compared,
            isDifferent: reference !== compared,
        };
    }

    private _compareCategoryList(): Comparison {
        const reference =
            this.referenceData.categoryList?.sort((a, b) => a.categoryId?.localeCompare(b.categoryId)) ?? DISPLAYED_NULLISH_VALUE;
        const compared =
            this.comparedData.categoryList?.sort((a, b) => a.categoryId?.localeCompare(b.categoryId)) ?? DISPLAYED_NULLISH_VALUE;
        return {
            key: ComparisonKey.CATEGORY_LIST,
            niceKey: 'Catégories secondaires',
            reference,
            compared,
            isDifferent: !isEqual(reference, compared),
        };
    }

    private _compareHours(day: string): Comparison {
        const referenceHours = this.referenceData.regularHours
            ? this.referenceData.regularHours
                  .filter((el) => el.openDay === day)
                  .map((hour) => new TimePeriod({ ...hour, closeTime: hour.closeTime === '24:00' ? '00:00' : hour.closeTime }))
            : null;
        const comparedHours = this.comparedData.regularHours ? this.comparedData.regularHours.filter((el) => el.openDay === day) : null;
        return {
            key: ComparisonKey[day],
            niceKey: this._upperCaseFirstLetter(days[day].fr),
            reference: this._mapHours(referenceHours),
            compared: this._mapHours(comparedHours),
            isDifferent: !isEqual(referenceHours, comparedHours),
        };
    }

    private _isFutureDate(date: MyDate): boolean {
        if (!date) {
            return false;
        }
        return new Date(date.year, date.month, date.day) > new Date();
    }

    private _cleanHours(hours: Period[]): Period[] {
        return hours.map((hour) => {
            if (hour.shouldBeClosed()) {
                hour.close();
            }
            return hour;
        });
    }

    private _mapAddress(comparisonType: ComparisonType): Partial<Address> {
        return {
            formattedAddress: this.formattedAddress[comparisonType],
            postalCode: this.postalCode[comparisonType],
            locality: this.locality[comparisonType],
            country: this.country[comparisonType],
            regionCode: this.comparedData.address.regionCode,
        };
    }

    private _mapHours(hours: TimePeriod[] | null): string {
        if (!hours || hours.length === 0) {
            return DISPLAYED_NULLISH_VALUE;
        }
        return hours
            .map((hour) => {
                if (hour.isClosed) {
                    return 'fermé';
                }
                return hour.openTime + DISPLAYED_NULLISH_VALUE + hour.closeTime;
            })
            .join(' | ');
    }

    private _upperCaseFirstLetter(str: string): string {
        if (!str) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    private _deepCompare(json1: Object, json2: Object): boolean {
        return JSON.stringify(json1) === JSON.stringify(json2);
    }

    private _captureFromDomainName(url: string): string {
        if (!url) {
            return DISPLAYED_NULLISH_VALUE;
        }
        const regExp = new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9].*/);
        const match = url.match(regExp);
        if (match) {
            const matchUrl = match[0].replace('www.', '');
            return matchUrl.charAt(matchUrl.length - 1) === '/' ? matchUrl.slice(0, -1) : matchUrl || '';
        }
        return DISPLAYED_NULLISH_VALUE;
    }

    private _compareUrl(url1: string, url2: string): boolean {
        return this._captureFromDomainName(url1) === this._captureFromDomainName(url2);
    }

    private _getAttributesDifferences(referenceAttributes: any[], comparedAttributes: any[]): AttributeDifference[] {
        const differences: AttributeDifference[] = [];
        const comparedAttributesCopy = [...comparedAttributes];
        for (const referenceAttribute of referenceAttributes) {
            if (referenceAttribute.text) {
                const comparedAttribute = comparedAttributesCopy.find((a) => a === referenceAttribute.text);
                if (
                    (!comparedAttribute && referenceAttribute.value === 'yes') ||
                    (comparedAttribute && referenceAttribute.value !== 'yes')
                ) {
                    differences.push(referenceAttribute);
                }
                if (comparedAttribute) {
                    comparedAttributesCopy.splice(comparedAttributesCopy.indexOf(comparedAttribute), 1);
                }
            }
        }
        for (const attrComp of comparedAttributesCopy) {
            differences.push({ text: attrComp, value: 'no' });
        }
        return differences;
    }
}
