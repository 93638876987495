<div class="relative h-full overflow-hidden border-l border-malou-color-border-primary bg-malou-color-background-light px-6 pb-4 pt-2">
    <div class="flex h-full w-full flex-col items-center justify-center gap-5">
        <mat-tab-group
            class="h-full w-full"
            animationDuration="5ms"
            mat-align-tabs="start"
            [preserveContent]="true"
            [selectedIndex]="selectedTabIndex()"
            (selectedIndexChange)="handleTabChange($event)">
            @for (tab of TABS; track tab.key) {
                <mat-tab bodyClass="h-full" [labelClass]="'tracking_edit_custom_ai_settings_modal_' + tab.key + '_tab'">
                    <ng-template mat-tab-label>
                        <div class="malou-text-14--regular flex items-center text-malou-color-text-2">
                            <span class="tab-title" [class]="{ 'malou-text-14--semibold': selectedTab().key === tab.key }">
                                {{ tab.label }}
                            </span>
                        </div>
                    </ng-template>
                    @if (selectedTab().key === tab.key) {
                        <div class="flex h-full flex-col justify-between pt-4">
                            @switch (selectedTab().key) {
                                @case (TabKeys.PREVIEWS) {
                                    <app-previews
                                        class="h-full"
                                        [(selectedPlatformOption)]="selectedPlatformOption"
                                        [(fetchedPlatformOptions)]="fetchedPlatformOptions"
                                        [selectedPlatformKeys]="selectedPlatformKeys()"></app-previews>
                                }
                                @case (TabKeys.FEED) {
                                    <app-feed
                                        class="h-full"
                                        [feed]="feedWithCurrentPost()"
                                        [shouldShowLoading]="isFetchingFeed()"
                                        [shouldShowLoadingMore]="isFetchingMoreFeed()"
                                        [draggable]="false"></app-feed>
                                }
                                @case (TabKeys.NOTES) {
                                    <app-notes
                                        class="h-full"
                                        trackingId="tracking_social_send_feedback"
                                        [feedbacks]="feedbacks()"
                                        [restaurantManagers]="restaurantManagers() ?? []"
                                        [postId]="postId()"
                                        [isPostPublished]="isPostPublished()"
                                        (feedbacksChange)="onFeedbacksChange($event)"></app-notes>
                                }
                            }
                        </div>
                    }
                </mat-tab>
            }
        </mat-tab-group>
    </div>

    <!-- Panel triggered by UpsertSocialPostAiContext -->
    <app-social-post-caption-ai-generation
        [(lastPrompt)]="upsertSocialPostAiContext.lastPrompt"
        [(isGenerating)]="upsertSocialPostAiContext.isGeneratingPostTextFromAI"
        [isSmallScreen]="false"
        [defaultPrompt]="defaultPrompt()"
        [promptFormControl]="upsertSocialPostAiContext.aiTextGenerationFormControl"
        [displayState]="upsertSocialPostAiContext.postCaptionAiGenerationDisplayState()"
        (onClose)="upsertSocialPostAiContext.closePostCaptionAiGeneration()"
        (onGeneratePromptClick)="upsertSocialPostAiContext.generatePost($event)">
    </app-social-post-caption-ai-generation>
    <!-- Panel triggered by UpsertSocialPostHashtagsContext -->
    <app-social-post-choose-hashtags-panel></app-social-post-choose-hashtags-panel>
</div>
