@if (isGmbConnected() && hasFoundGmbInsightsData()) {
    <ng-container [ngTemplateOutlet]="isLoading() ? loadingTemplate : chartsTemplate"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="gmbNotConnectedTemplate"></ng-container>
}

<ng-template #chartsTemplate>
    <div class="flex items-center gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.gmb_impressions.gmb' | translate }}</span>
        <mat-icon
            class="pdf-hidden !h-5 !w-5"
            [svgIcon]="SvgIcon.INFO"
            [matTooltip]="'statistics.seo.gmb_impressions.info_gmb_data_take_time' | translate: { date: DATE_NOW_MINUS_4_DAYS }"></mat-icon>
    </div>
    <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
        @if (shouldShowImpressionsChart()) {
            <div class="min-w-0 flex-1 break-inside-avoid">
                <app-gmb-impressions-v2
                    [dailyInsightsChartData]="dailyInsightsChartData()"
                    [dailyPreviousInsightsChartData]="dailyPreviousInsightsChartData()"
                    [weeklyInsightsChartData]="weeklyInsightsChartData()"
                    [weeklyPreviousInsightsChartData]="weeklyPreviousInsightsChartData()"
                    [monthlyInsightsChartData]="monthlyInsightsChartData()"
                    [monthlyPreviousInsightsChartData]="monthlyPreviousInsightsChartData()"
                    [viewBy]="impressionsChartViewBy()"
                    [hiddenDatasetIndexes]="impressionsChartHiddenDatasetIndexes()"
                    [showViewByTextInsteadOfSelector]="showViewByTextInsteadOfSelectorImpressions()"
                    (viewByChange)="emitViewByChange(InsightsChart.APPARITIONS, $event)"
                    (hiddenDatasetIndexesChange)="emitHiddenDatasetIndexesChange(InsightsChart.APPARITIONS, $event)">
                </app-gmb-impressions-v2>
            </div>
        }
        @if (shouldShowActionsChart()) {
            <div class="min-w-0 flex-1 break-inside-avoid">
                <app-gmb-actions-v2
                    [dailyInsightsChartData]="dailyInsightsChartData()"
                    [dailyPreviousInsightsChartData]="dailyPreviousInsightsChartData()"
                    [weeklyInsightsChartData]="weeklyInsightsChartData()"
                    [weeklyPreviousInsightsChartData]="weeklyPreviousInsightsChartData()"
                    [monthlyInsightsChartData]="monthlyInsightsChartData()"
                    [monthlyPreviousInsightsChartData]="monthlyPreviousInsightsChartData()"
                    [viewBy]="actionsChartViewBy()"
                    [hiddenDatasetIndexes]="actionsChartHiddenDatasetIndexes()"
                    [showViewByTextInsteadOfSelector]="showViewByTextInsteadOfSelectorActions()"
                    (viewByChange)="emitViewByChange(InsightsChart.ACTIONS, $event)"
                    (hiddenDatasetIndexesChange)="emitHiddenDatasetIndexesChange(InsightsChart.ACTIONS, $event)">
                </app-gmb-actions-v2>
            </div>
        }
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="flex gap-6">
        <div class="min-w-0 flex-1">
            <app-skeleton skeletonClass="!h-[542px] secondary-bg w-full"></app-skeleton>
        </div>
        <div class="min-w-0 flex-1">
            <app-skeleton skeletonClass="!h-[542px] secondary-bg w-full"></app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #gmbNotConnectedTemplate>
    <div class="flex-grow items-center">
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'statistics.errors.gmb_not_connected' | translate }}</span>
        </div>
    </div>
</ng-template>
