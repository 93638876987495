import { IDisplayable } from ':shared/interfaces';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { AppInjectorService } from ':shared/services/app-injector.service';

export const citiesWithDistrict = ['Paris', 'Marseille', 'Lyon'];

export class Address implements IDisplayable {
    streetNumber?: string; // number
    route?: string; // street
    locality?: string; // city
    regionCode: string; // country code
    country?: string; // country name
    postalCode?: string; // zipcode
    formattedAddress?: string; // formatted string
    administrativeArea?: string; // formatted string

    public constructor(init?: Partial<Address>) {
        Object.assign(this, init);
    }

    getDisplayedValue(): string {
        const enumTranslatePipe = AppInjectorService.getInjector()?.get(EnumTranslatePipe);
        const prettyCountry =
            this.regionCode && enumTranslatePipe ? enumTranslatePipe?.transform(this.regionCode?.toLowerCase(), 'countries') : this.country;
        return [this.formattedAddress, this.locality, prettyCountry].filter(Boolean).join(', ');
    }

    toString(): string | null {
        return this.formattedAddress ? `${this.formattedAddress}` : null;
    }

    getAddressWithDistrict(maxLength?: number): string {
        let city = this.locality;
        if (city && citiesWithDistrict.includes(city) && this.country === 'France') {
            const district = this.postalCode?.substr(3, 2) ?? '0';
            city += ' ' + parseInt(district, 10);
        }

        const fullAddress = this.formattedAddress + ', ' + city;
        if (maxLength) {
            return fullAddress.length > maxLength
                ? this.formattedAddress?.slice(0, -(fullAddress.length - maxLength + 3)) + '..., ' + city
                : fullAddress;
        }
        return fullAddress;
    }

    getFullFormattedAddress(): string {
        const enumTranslatePipe = AppInjectorService.getInjector()?.get(EnumTranslatePipe);
        const prettyCountry =
            this.regionCode && enumTranslatePipe
                ? enumTranslatePipe?.transform(this.regionCode?.toLowerCase()?.trim(), 'countries')
                : this.country;
        return [this.formattedAddress, this.postalCode, this.locality, prettyCountry].filter(Boolean).join(', ');
    }

    getFormattedAddressWithPostalCodeAndLocality(): string {
        return [this.formattedAddress, this.postalCode, this.locality].filter(Boolean).join(', ');
    }
}
