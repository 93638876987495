import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { FileFormat, MimeType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import {
    EditMediaModalComponent,
    EditMediaModalDialogData,
    EditMediaModalDialogResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/upload-and-edit-medias/components/edit-media-modal/edit-media-modal.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { BodyDragAndDropEventsService } from ':shared/services/body-drag-and-drop-events.service';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaThumbnailListComponent } from './components/media-thumbnail-list/media-thumbnail-list.component';

@Component({
    selector: 'app-upload-and-edit-medias',
    templateUrl: './upload-and-edit-medias.component.html',
    standalone: true,
    imports: [NgTemplateOutlet, MatIconModule, TranslateModule, MediaThumbnailListComponent, NgClass, MatMenuModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAndEditMediasComponent {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly medias = model.required<EditionMedia[]>();
    readonly maxMediaSizeInMo = input<number | undefined>();
    readonly mediaFormatAccepted = input<FileFormat[]>([]);
    readonly showEditMediaButton = input(true);
    readonly uploadingMediaCount = input(0);
    readonly mediaClicked = output<string>();
    readonly fileAdded = output<File>();
    readonly importMediaFromGallery = output<string>();

    private readonly _translateService = inject(TranslateService);
    private readonly _bodyDragAndDropEventsService = inject(BodyDragAndDropEventsService);

    private readonly _isModalOpen = signal(false);

    readonly SvgIcon = SvgIcon;
    // This list is given to the <input type='file'>, remember that it's only a suggestion, the user can bypass this.
    readonly ACCEPT = [MimeType.IMAGE_PNG, MimeType.IMAGE_JPEG, MimeType.IMAGE_HEIC, MimeType.IMAGE_HEIF];

    readonly mediaRequirementsText = computed((): string => {
        const requirements: string[] = [];

        const maxMediaSize = this.maxMediaSizeInMo();
        if (maxMediaSize) {
            const maxMediaSizeRequirement = this._translateService.instant('upload_and_edit_medias.requirements.max_media_size', {
                maxMediaSize,
            });
            requirements.push(maxMediaSizeRequirement);
        }

        const mediaFormatAccepted = this.mediaFormatAccepted();
        if (mediaFormatAccepted.length > 0) {
            const mediaFormatAcceptedRequirement = mediaFormatAccepted.join(', ');
            requirements.push(mediaFormatAcceptedRequirement);
        }

        return requirements.join(', ');
    });

    readonly isDragging = signal(false);
    readonly mediasForThumbnailList = computed(() =>
        this.medias().map((media) => ({
            id: media.id,
            url: media.thumbnail256OutsideUrl,
            dimensions: media.thumbnail256OutsideDimensions,
            transformData: media.transformData,
        }))
    );

    constructor() {
        this._bodyDragAndDropEventsService.dragEnter
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragEnter);
        this._bodyDragAndDropEventsService.dragOver
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragOver);
        this._bodyDragAndDropEventsService.dragLeave
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDragLeave);
        this._bodyDragAndDropEventsService.drop
            .pipe(filter(this._isDragAndDropActive), filter(this._hasFile), takeUntilDestroyed())
            .subscribe(this._onDrop);
    }

    onFileInputChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        for (const file of Array.from(files)) {
            this.fileAdded.emit(file);
        }
    }

    onEditMedia(mediaId: string): void {
        this._isModalOpen.set(true);
        this._customDialogService
            .open<EditMediaModalComponent, EditMediaModalDialogData, EditMediaModalDialogResult>(EditMediaModalComponent, {
                width: '700px',
                data: {
                    medias: this.medias(),
                    selectedMediaId: mediaId,
                },
            })
            .afterClosed()
            .subscribe((data) => {
                this._isModalOpen.set(false);
                if (data) {
                    this.medias.set(data.medias);
                }
            });
    }

    onRemoveMedia(mediaId: string): void {
        const medias = this.medias().filter((m) => m.id !== mediaId);
        this.medias.set(medias);
    }

    onDropMedia(event: { previousIndex: number; currentIndex: number }): void {
        const medias = [...this.medias()];
        moveItemInArray(medias, event.previousIndex, event.currentIndex);
        this.medias.set(medias);
    }

    onImportMediaFromGallery(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: true,
                    filter: '',
                    selectedMedias: [],
                },
            })
            .afterClosed()
            .subscribe({
                next: (medias: Media[]) => {
                    medias.forEach((media) => {
                        this.importMediaFromGallery.emit(media.id);
                    });
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    private _isDragAndDropActive = (): boolean => !this._isModalOpen();

    private _hasFile(event: DragEvent): boolean {
        return (event.dataTransfer?.types ?? []).includes('Files');
    }

    private _onDragEnter = (): void => {
        this.isDragging.set(true);
    };

    private _onDragOver = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    };

    private _onDragLeave = (event: DragEvent): void => {
        event.preventDefault();
        this.isDragging.set(false);
    };

    private _onDrop = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        this.isDragging.set(false);
        for (const file of Array.from(event.dataTransfer?.files ?? [])) {
            this.fileAdded.emit(file);
        }
    };
}
