import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { MalouComparisonPeriod } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-period-legend',
    templateUrl: './period-legend.component.html',
    styleUrls: ['./period-legend.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatTooltipModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodLegendComponent {
    readonly showMaxRangeError = input<boolean>(false);
    readonly comparisonPeriod = input<MalouComparisonPeriod>(MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly comparisonPeriodKey = computed(() => `statistics.common.${this.comparisonPeriod()}`);

    readonly SvgIcon = SvgIcon;
}
