import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, difference, intersection } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { isNotNil, MalouErrorCode, TemplateStatus, TemplateTranslationLang, TimeInMilliseconds } from '@malou-io/package-utils';

import { selectOpenedFooterCount } from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { AutomationsService } from ':core/services/automations.service';
import { DialogService } from ':core/services/dialog.service';
import { KeywordsService } from ':core/services/keywords.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TemplatesService } from ':core/services/templates.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { EditCreateTemplateModalComponent } from ':shared/components/edit-create-template-modal/edit-create-template-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { RestaurantsSelectionModalComponent } from ':shared/components/restaurants-selection-modal/restaurants-selection-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StarWithTextChipComponent } from ':shared/components/star-with-text-chip/star-with-text-chip.component';
import { TemplateInfoModalComponent } from ':shared/components/template-info-modal/template-info-modal.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { TemplateType } from ':shared/enums/template-type.enum';
import { CommentOptionValue } from ':shared/enums/with-comment.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { MediumTemplate, Template } from ':shared/models';
import { ReviewReplyAutomation } from ':shared/models/automations';
import { ReviewTemplatesFilters } from ':shared/models/template-filters';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { DuplicateTooltipPipe } from '../pipe/duplicate-tooltip.pipe';
import { WithCommentPipe } from '../pipe/with-comment.pipe';
import { AVAILABLE_REVIEW_TEMPLATE_VARIABLES, TemplateReplacer, TemplateReplacerType } from '../template-replacer/template-replacer';
import { GenerateReviewTemplatesComponent } from './generate-review-templates/generate-review-templates.component';
import { ReviewTemplateActionsHeaderComponent } from './review-template-actions-header/review-template-actions-header.component';
import * as ReviewTemplatesActions from './store/review-templates.actions';
import { selectReviewTemplatesFilters, selectReviewTemplatesSort } from './store/review-templates.selectors';

enum ReviewTemplateColumns {
    SELECT = 'select',
    NAME = 'name',
    TEXT = 'text',
    WITH_COMMENT = 'withComment',
    RATING = 'rating',
    AUTOMATED = 'automated',
    ACTIONS = 'actions',
}

const defaultFilter: Partial<ReviewTemplatesFilters> = {
    ratings: [0, 1, 2, 3, 4, 5],
    automated: true,
    manual: true,
    withText: true,
    withoutText: true,
};

const sortData =
    () =>
    (items: MediumTemplate[], sort: MatSort): MediumTemplate[] => {
        if (!sort.active || sort.direction === '') {
            return items;
        }
        return sortTemplates(items, sort);
    };

const sortTemplates = (templates: MediumTemplate[], sort: Sort): MediumTemplate[] =>
    templates.sort((a: MediumTemplate, b: MediumTemplate) => {
        const comparatorResult = sortByKey(sort.active)(a, b);
        return comparatorResult * (sort.direction === ChartSortBy.ASC ? 1 : -1);
    });

const sortByKey =
    (key: string) =>
    (a: Template, b: Template): number => {
        switch (key) {
            case ReviewTemplateColumns.RATING:
                return Math.max(...a.rating) < Math.max(...b.rating) ? -1 : 1;
            case ReviewTemplateColumns.AUTOMATED:
                return a.automationConfig.automated === b.automationConfig.automated ? 0 : a.automationConfig.automated ? -1 : 1;
            default:
                return a[key]?.localeCompare(b[key]);
        }
    };

@Component({
    selector: 'app-review-templates',
    templateUrl: './review-templates.component.html',
    styleUrls: ['./review-templates.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        GenerateReviewTemplatesComponent,
        NoopMatCheckboxComponent,
        ReviewTemplateActionsHeaderComponent,
        ScrollToTopComponent,
        SlideToggleComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        DuplicateTooltipPipe,
        EnumTranslatePipe,
        IncludesPipe,
        PluralTranslatePipe,
        WithCommentPipe,
        ApplySelfPurePipe,
        LazyLoadImageModule,
        StarWithTextChipComponent,
        ApplyPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [PluralTranslatePipe],
})
export class ReviewTemplatesComponent implements OnInit {
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly DuplicationDestination = DuplicationDestination;
    readonly CommentOptionValue = CommentOptionValue;
    readonly ReviewTemplateColumns = ReviewTemplateColumns;
    readonly Illustration = Illustration;
    readonly TemplateStatus = TemplateStatus;
    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly DISPLAYED_COLUMNS: string[] = Object.values(ReviewTemplateColumns);
    readonly SCROLL_CONTAINER_ID = 'review-templates';
    readonly SCROLL_CONTAINER_SELECTOR = `#${this.SCROLL_CONTAINER_ID}`;
    readonly _TEMPLATE_REPLACER = new TemplateReplacer(this._translate, AVAILABLE_REVIEW_TEMPLATE_VARIABLES);

    readonly filters$: BehaviorSubject<Partial<ReviewTemplatesFilters>> = new BehaviorSubject(defaultFilter);

    selection = new SelectionModel<MediumTemplate>(true, []);
    dataSource: WritableSignal<MatTableDataSource<MediumTemplate>> = signal(new MatTableDataSource<MediumTemplate>());
    restaurantHasAtLeastOneTemplate = computed(() => this.dataSource().data.length > 0);
    atLeastOneTemplateToDisplay = computed(() => this.dataSource().filteredData.length > 0);

    lastSelectedSegmentRow = 0;

    restaurantId: string;

    sort: Sort;
    restaurantKeywordsChecked = false;

    hasFetchedTemplates = signal(false);
    footersVisibilityCount = 0;
    automaticReplyCount = 0;
    duplicatedTemplateIds: string[] = [];
    automations: ReviewReplyAutomation[] = [];

    readonly isGenerating = signal(false);
    readonly isLoading = computed(() => this.isGenerating() || !this.hasFetchedTemplates());

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinner: SpinnerService,
        private readonly _templatesService: TemplatesService,
        private readonly _keywordsService: KeywordsService,
        private readonly _translate: TranslateService,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _router: Router,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _automationsService: AutomationsService,
        public screenSizeService: ScreenSizeService
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.update((dataSource) => {
            dataSource.sort = sort;
            dataSource.sortData = sortData();
            return dataSource;
        });
    }

    ngOnInit(): void {
        this.dataSource.update((dataSource) => {
            dataSource.filterPredicate = this.customFilterPredicate;
            return cloneDeep(dataSource);
        });

        this._initLoadingTemplates();

        this._store
            .select(selectOpenedFooterCount)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((count) => {
                this.footersVisibilityCount = count;
            });

        this._store
            .select(selectReviewTemplatesFilters)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((filters) => this.filters$.next(filters));

        this.filters$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((filters) => {
            this.dataSource.update((dataSource) => {
                dataSource.filter = JSON.stringify({ ...filters });
                return cloneDeep(dataSource);
            });
            this._setTemplateCount(this.dataSource().filteredData.length);
        });
    }

    customFilterPredicate = (mediumTemplate: MediumTemplate, inputFilter: string): boolean => {
        const filters: ReviewTemplatesFilters = JSON.parse(inputFilter);
        const filterSearch = filters.search;

        const isSearchMatch = isNotNil(filterSearch)
            ? [mediumTemplate.name, mediumTemplate.text].some((str) => str?.toLowerCase().includes(filterSearch.toLowerCase()))
            : true;

        const automatedResponses = filters.automated ? !!mediumTemplate.automationConfig?.automated : false;
        const isManual = filters.manual ? !mediumTemplate.automationConfig?.automated : false;
        const isAutomatedMatch = automatedResponses || isManual;

        const isWithCommentMatch = this.getCommentMatch(mediumTemplate, filters);
        const isRatingMatch = !!mediumTemplate.rating?.filter((el) => filters.ratings?.includes(el)).length;

        return isSearchMatch && isWithCommentMatch && isRatingMatch && isAutomatedMatch;
    };

    getCommentMatch(inputData: MediumTemplate, filters: ReviewTemplatesFilters): boolean {
        if (filters.withText && filters.withoutText) {
            return true;
        }
        if (filters.withText) {
            return [CommentOptionValue.WITH, CommentOptionValue.WITH_OR_WITHOUT].includes(inputData.withComment);
        }
        if (filters.withoutText) {
            return [CommentOptionValue.WITHOUT, CommentOptionValue.WITH_OR_WITHOUT].includes(inputData.withComment);
        }
        return true;
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource().data.length;

        return numSelected === numRows;
    }

    isAllFilteredSelected(): boolean {
        return this.dataSource().filteredData.every((template) => this.selection.isSelected(template));
    }

    toggleAllFiltered(): void {
        if (this.isAllFilteredSelected()) {
            this.selection.deselect(...this.dataSource().filteredData);
            return;
        }
        this.selection.select(...this.dataSource().filteredData);
        this.lastSelectedSegmentRow = 0;
    }

    deleteTemplatesByIds(templateIds: string[]): void {
        if (!templateIds.length) {
            return;
        }
        this._dialogService.open({
            variant: DialogVariant.ALERT,
            title: this._pluralTranslatePipe.transform('templates.delete_prompt', templateIds.length),
            primaryButton: {
                label: this._translate.instant('common.delete'),
                action: () => {
                    this._spinner.show();
                    this._deleteTemplatesById(templateIds);
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
                action: () => {
                    this._spinner.hide();
                },
            },
        });
    }

    duplicateTemplates(to: string, singleTemplate = null): void {
        const templatesToDuplicate = singleTemplate ? [singleTemplate] : this.selection.selected;
        if (to === DuplicationDestination.HERE) {
            return this._duplicateTemplates(templatesToDuplicate, [this.restaurantId]);
        }
        this._customDialogService
            .open(RestaurantsSelectionModalComponent, {
                width: '600px',
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.ids) {
                        this._duplicateTemplates(templatesToDuplicate, result.ids);
                    } else {
                        this.selection.clear();
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.selection.clear();
                },
            });
    }

    openEditTemplateModal(template: MediumTemplate | null = null): void {
        this._customDialogService
            .open(EditCreateTemplateModalComponent, {
                panelClass: 'malou-dialog-panel--animated',
                width: '650px',
                height: 'unset',
                data: {
                    template,
                    type: TemplateType.REVIEW,
                },
            })
            .afterClosed()
            .pipe(
                switchMap((createTemplateRes) =>
                    this._shouldOpenInfoModal(createTemplateRes)
                        ? this.openInfoModal(createTemplateRes)
                        : of({ createOtherTemplate: false, firstTemplate: createTemplateRes })
                )
            )
            .subscribe({
                next: (res: { createOtherTemplate: boolean; firstTemplate: MediumTemplate }) => {
                    this._removeTemplatesFromDataSource([res.firstTemplate._id]);
                    this._addTemplatesToDataSource([res.firstTemplate], { shouldSort: this.screenSizeService.isPhoneScreen });

                    if (!res.createOtherTemplate) {
                        return;
                    } else {
                        this.openEditTemplateModal();
                    }
                },
            });
    }

    openInfoModal(template: MediumTemplate): Observable<MediumTemplate> {
        return this._customDialogService
            .open(TemplateInfoModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: '45vh',
                data: {
                    template,
                },
            })
            .afterClosed();
    }

    selectMultiple(event: MouseEvent, lastRow: number, mediumTemplate: MediumTemplate): void {
        if (event.shiftKey) {
            const sortedTemplates = sortTemplates(this.dataSource().data, this.sort);
            const templatesToSelect = sortedTemplates.filter(
                (template, i) => template.type === TemplateType.REVIEW && i >= this.lastSelectedSegmentRow && i <= lastRow
            );

            this.selection.select(...templatesToSelect);
        } else {
            this.selection.toggle(mediumTemplate);
        }
        this.lastSelectedSegmentRow = lastRow;
    }

    onSearchChange(search: string): void {
        this.filters$.next({ ...this.filters$.value, search });
    }

    onDuplicateSelected(duplicationDestination: string): void {
        this.duplicateTemplates(duplicationDestination);
    }

    onDeleteSelected(): void {
        const templateIds = this.selection.selected.map((t) => t._id);
        this.deleteTemplatesByIds(templateIds);
    }

    replaceVariableTextToChipText = (text: string): string =>
        this._TEMPLATE_REPLACER.replaceText(text, {
            from: TemplateReplacerType.VARIABLE_TEXT,
            to: TemplateReplacerType.CHIP_TEXT,
        });

    getAutomatedColumnToolip = (template: Template): string => {
        const ratingDiffernce = difference(template.rating, template.automationConfig.ratings);
        const isWithCommentDifferent = template.withComment !== template.automationConfig.withComment;
        let tooltip = this._translate.instant('templates.review.automated_tooltip.default_text');
        if (ratingDiffernce.length) {
            tooltip = `${tooltip} ${this._translate.instant('templates.review.automated_tooltip.rating_difference', {
                rating: template.automationConfig.ratings.join(', '),
            })}`;
        }
        if (isWithCommentDifferent) {
            tooltip = `${tooltip} ${this._translate.instant(
                `templates.review.automated_tooltip.with_comment_difference.${template.automationConfig.withComment}`
            )}`;
        }

        if (ratingDiffernce.length || isWithCommentDifferent) {
            return tooltip;
        }
        return '';
    };

    generateTemplates(langs: TemplateTranslationLang[]): void {
        this.isGenerating.set(true);
        this._templatesService.generateTemplates(langs, this.restaurantId).subscribe({
            next: (templates) => {
                this._addTemplatesToDataSource(templates, { shouldSort: this.screenSizeService.isPhoneScreen });
                this.isGenerating.set(false);
            },
            error: (err) => {
                console.warn('err :>>', err);
                this.isGenerating.set(false);
                if (err.status === 403) {
                    return;
                }
                let errorMessage: string;
                if (err.error.malouErrorCode === MalouErrorCode.DEFAULT_TEMPLATES_GENERATION_FAILED) {
                    errorMessage = this._translate.instant('templates.review.generation_failed');
                } else {
                    errorMessage = this._httpErrorPipe.transform(err);
                }
                this._openErrorModal(errorMessage);
            },
        });
    }

    backToDraft(template: MediumTemplate): void {
        this._templatesService.updateTemplateToPendingStatus$(template._id).subscribe({
            next: () => {
                this._removeTemplatesFromDataSource([template._id]);
                template.setStatus(TemplateStatus.PENDING);
                template.resetAutomationConfig();
                this._addTemplatesToDataSource([template], { shouldSort: this.screenSizeService.isPhoneScreen });
            },
            error: (err) => {
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._openErrorModal(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _duplicateTemplates(selectedTemplates: MediumTemplate[], restaurantIds: string[]): void {
        const sameRestaurant = restaurantIds.length === 1 && restaurantIds[0] === this.restaurantId;
        const mappedSelectedTemplates = selectedTemplates.map((template) => ({
            category: template.category,
            name: sameRestaurant ? this._translate.instant('templates.copy_prefix', { name: template.name }) : template.name,
            rating: template.rating,
            language: template.language,
            withComment: template.withComment,
            text: template.text,
            type: template.type,
        }));
        this._spinner.show();
        this._templatesService.duplicateTemplatesForRestaurants(this.restaurantId, mappedSelectedTemplates, restaurantIds).subscribe({
            next: (templates) => {
                this._openDuplicateSuccessToast(mappedSelectedTemplates.length);

                if (restaurantIds.includes(this.restaurantId)) {
                    const restaurantTemplates = templates
                        .filter((template) => template.restaurantId === this.restaurantId)
                        .map((template) => {
                            template.setAutomationConfig(this.automations);
                            return template;
                        });
                    this._addTemplatesToDataSource(restaurantTemplates, { shouldSort: this.screenSizeService.isPhoneScreen });
                    this.duplicatedTemplateIds = restaurantTemplates.map((t) => t._id);
                    setTimeout(() => this._scrollToTemplate(this.duplicatedTemplateIds[0]));
                    setTimeout(() => {
                        this.duplicatedTemplateIds = [];
                    }, 10 * TimeInMilliseconds.SECOND);
                }
                this.selection.clear();
                this._spinner.hide();
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._spinner.hide();
                if (err.status === 403) {
                    return;
                }
                this._openErrorModal(this._httpErrorPipe.transform(err));
                this.selection.clear();
            },
        });
    }

    private _shouldOpenInfoModal(result: Template | boolean): boolean {
        if (!(result instanceof Template)) {
            return false;
        }
        const { rating, withComment } = result;
        const BAD_RATINGS = [1, 2, 3];
        const WITH_COMMENT = [CommentOptionValue.WITH, CommentOptionValue.WITH_OR_WITHOUT];
        if (intersection(rating, BAD_RATINGS).length === 0 || !WITH_COMMENT.includes(withComment)) {
            return false;
        }
        // display modal if there is no template (current one excluded) for bad reviews with comments
        return !this.dataSource().data.some(
            (temp) =>
                temp._id !== result._id && intersection(temp.rating, BAD_RATINGS).length > 0 && WITH_COMMENT.includes(temp.withComment)
        );
    }

    private _initLoadingTemplates(): void {
        combineLatest([
            this._store.select(selectReviewTemplatesSort),
            this._restaurantsService.restaurantSelected$.pipe(
                filter(isNotNil),
                switchMap((restaurant) => {
                    this.restaurantId = restaurant._id;
                    if (!this.restaurantKeywordsChecked) {
                        this._checkIfHasKeywords();
                    }
                    return forkJoin([
                        this._templatesService.getTemplatesByRestaurantId(restaurant._id, TemplateType.REVIEW),
                        this._automationsService.getRestaurantReviewReplyAutomations(restaurant._id),
                    ]);
                }),
                map(([templates, automations]) => {
                    this.automations = automations;
                    templates.forEach((template) => template.setAutomationConfig(automations));
                    return templates;
                })
            ),
        ])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(([{ sortBy, sortOrder }, templates]) => {
                this.sort = {
                    active: sortBy,
                    direction: sortOrder === ChartSortBy.ASC ? ChartSortBy.ASC : ChartSortBy.DESC,
                };

                this.automaticReplyCount = templates.filter((template) => template.automationConfig.automated).length;
                this._setTemplateCount(templates.length);
                this.dataSource.update((dataSource) => {
                    dataSource.data = [];
                    return cloneDeep(dataSource);
                });
                this._addTemplatesToDataSource(templates, { shouldSort: this.screenSizeService.isPhoneScreen });
                this.hasFetchedTemplates.set(true);
            });
    }

    private _scrollToTemplate(templateId: string): void {
        const duplicatedTemplatesElement = document.getElementById(templateId);
        if (duplicatedTemplatesElement) {
            duplicatedTemplatesElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    private _checkIfHasKeywords(): void {
        this._keywordsService.getKeywordsCountForRestaurant(this.restaurantId).subscribe({
            next: (res) => {
                if (res.data > 0) {
                    return;
                }
                this.restaurantKeywordsChecked = true;
                if (!this._restaurantsService.currentRestaurant.isBrandBusiness()) {
                    this._dialogService.open({
                        variant: DialogVariant.ALERT,
                        title: this._translate.instant('templates.keywords.title'),
                        message: this._translate.instant('templates.keywords.text'),
                        primaryButton: {
                            label: this._translate.instant('templates.keywords.confirm'),
                            action: () => this._router.navigate(['/restaurants', this.restaurantId, 'resources', 'keywords', 'list']),
                        },
                        secondaryButton: {
                            label: this._translate.instant('common.cancel'),
                        },
                    });
                }
            },
        });
    }

    private _setTemplateCount(count: number): void {
        this._store.dispatch(ReviewTemplatesActions.setTemplateCount({ count }));
    }

    private _deleteTemplatesById(templateIds: string[]): void {
        this._templatesService
            .deleteManyTemplates(templateIds)
            .pipe(switchMap(() => this._templatesService.getTemplatesByRestaurantId(this.restaurantId, TemplateType.REVIEW)))
            .subscribe({
                next: () => {
                    this.selection.clear();
                    this._removeTemplatesFromDataSource(templateIds);
                    this._spinner.hide();
                    this._openDeleteSuccessToast(templateIds.length);
                },
                error: (err) => {
                    this.selection.clear();
                    this._spinner.hide();
                    if (err.status === 403) {
                        return;
                    }
                    this._openErrorModal(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _addTemplatesToDataSource(templates: MediumTemplate[] = [], options: { shouldSort?: boolean } = {}): void {
        this.dataSource.update((dataSource) => {
            dataSource.data.push(...templates);
            return cloneDeep(dataSource);
        });
        if (options.shouldSort && this.sort) {
            const data = this.dataSource().data;
            this.dataSource.update((dataSource) => {
                dataSource.data = sortTemplates(data, this.sort);
                return cloneDeep(dataSource);
            });
        }
        this.dataSource()._updateChangeSubscription();
        this._setTemplateCount(this.dataSource().filteredData.length);
    }

    private _removeTemplatesFromDataSource(templateIds: string[]): void {
        templateIds.forEach((templateId) => {
            const index = this.dataSource().data.findIndex((template) => template._id === templateId);
            if (index >= 0) {
                this.dataSource.update((dataSource) => {
                    dataSource.data.splice(index, 1);
                    return cloneDeep(dataSource);
                });
            }
        });
        this.dataSource()._updateChangeSubscription();
        this._setTemplateCount(this.dataSource().filteredData.length);
    }

    private _openErrorModal(message: string): void {
        this._dialogService.open({
            variant: DialogVariant.ERROR,
            title: this._translate.instant('common.error'),
            message,
            primaryButton: {
                label: this._translate.instant('common.close'),
            },
        });
    }

    private _openDuplicateSuccessToast(duplicatedTemplateCount: number): void {
        if (duplicatedTemplateCount === 0) {
            return;
        }
        this._toastService.openSuccessToast(this._pluralTranslatePipe.transform('templates.duplicate_success', duplicatedTemplateCount));
    }

    private _openDeleteSuccessToast(deletedTemplateCount: number): void {
        if (deletedTemplateCount === 0) {
            return;
        }
        this._toastService.openSuccessToast(this._pluralTranslatePipe.transform('templates.delete_success', deletedTemplateCount));
    }
}
