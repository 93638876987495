import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    Injector,
    input,
    OnDestroy,
    output,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import Cropper from 'cropperjs';
import { debounceTime, Subject, tap } from 'rxjs';

import { ImageEditorUtils } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/upload-and-edit-medias/components/edit-media-modal/components/image-editor/image-editor-utils';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';

/**
 * Values are ratio between 0 and 1 of image width/height
 */
export type AreaRatio = ImageEditorUtils.Area;

type TransformData = AreaRatio & { rotationInDegrees: number };

/**
 * https://github.com/fengyuanchen/cropperjs/blob/main/docs/images/layers.jpg
 * Lexicon :
 *   - size : check the interface Size
 *   - area : check the interface Area
 *   - Container : size in pixels of the parent container.
 *   - Canvas : area of the image (if not rotated it's size equal to the image, if rotated it's the bounding box of the image). origin : container
 *   - Image : size in pixels of the image (+ some other information)
 *   - CropBox : area in pixels of the crop box. origin : container
 *   - natural : a term used when the value is relative to the real image size (not the one displayed)
 */
@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrl: './image-editor.component.scss',
    standalone: true,
    imports: [NgOptimizedImage, SkeletonComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditorComponent implements AfterViewInit, OnDestroy {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _injector = inject(Injector);

    readonly url = input.required<string>();
    readonly transformData = input.required<TransformData>();
    readonly transformAreaChange = output<AreaRatio>();

    readonly imageElementRef = viewChild.required<ElementRef<HTMLImageElement>>('image');
    readonly containerDivElementRef = viewChild.required<ElementRef<HTMLDivElement>>('containerDiv');

    private _cropperDataSubject = new Subject<{ data: Cropper.Data; canvasData: Cropper.CanvasData }>();

    private _outputtedTransformData: AreaRatio | null = null;
    private readonly _isCropperInitializing = signal(true);
    private readonly _isCropperHardReloading = signal(false);
    private readonly _isCropperHotReloading = signal(false);
    readonly isLoading = computed(() => this._isCropperInitializing() || this._isCropperHardReloading() || this._isCropperHotReloading());

    private _isFirstUrlChange = true;
    private _isFirstTransformDataChange = true;
    private _resizeObserver: ResizeObserver | undefined;

    ngAfterViewInit(): void {
        const cropper = this._initCropper();
        this._emitTransformDataOnCropperDataChange();
        this._hardReloadCropperOnUrlChange(cropper);
        this._hotReloadOnTransformDataChange(cropper);
        this._hotReloadCropperOnResize(cropper);
    }

    ngOnDestroy(): void {
        this._resizeObserver?.disconnect();
    }

    private _initCropper(): Cropper {
        const options: Cropper.Options<HTMLImageElement> = {
            viewMode: 1,
            dragMode: 'move',
            autoCrop: false,
            background: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            ready: (event) => {
                this._updateCropper(event.currentTarget.cropper, this.transformData());
                this._isCropperInitializing.set(false);
                this._isCropperHardReloading.set(false);
            },
            cropend: (event) => {
                // This condition prevents this event to be processed if the cropper is initializing
                // We untrack this signal because the caller is an effect
                if (untracked(() => this.isLoading())) {
                    return;
                }

                const cropperFromEvent = event.currentTarget.cropper;
                this._cropperDataSubject.next({ data: cropperFromEvent.getData(), canvasData: cropperFromEvent.getCanvasData() });
            },
            zoom: (event) => {
                // This condition prevents this event to be processed if the cropper is initializing
                // We untrack this signal because the caller is an effect
                if (untracked(() => this.isLoading())) {
                    return;
                }

                const cropperFromEvent = event.currentTarget.cropper;
                // This event corresponds to the zoom start, so we add a setTimeout to get data after zoom update
                setTimeout(() => {
                    this._cropperDataSubject.next({ data: cropperFromEvent.getData(), canvasData: cropperFromEvent.getCanvasData() });
                }, 0);
            },
        };
        const cropper = new Cropper(this.imageElementRef().nativeElement, options);
        return cropper;
    }

    private _emitTransformDataOnCropperDataChange(): void {
        this._cropperDataSubject.pipe(debounceTime(200), takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
            this._outputtedTransformData = {
                left: value.data.x / value.canvasData.naturalWidth,
                top: value.data.y / value.canvasData.naturalHeight,
                width: value.data.width / value.canvasData.naturalWidth,
                height: value.data.height / value.canvasData.naturalHeight,
            };
            return this.transformAreaChange.emit(this._outputtedTransformData);
        });
    }

    private _hardReloadCropperOnUrlChange(cropper: Cropper): void {
        effect(
            () => {
                const url = this.url();

                // Mini optimization : Skip the first effect run (keep signals above this)
                if (this._isFirstUrlChange) {
                    this._isFirstUrlChange = false;
                    return;
                }

                this._isCropperHardReloading.set(true);

                // This will emit the ready event when ready
                cropper.replace(url);
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    private _hotReloadOnTransformDataChange(cropper: Cropper): void {
        effect(
            () => {
                const transformData = this.transformData();
                const isCropperHardReloading = this._isCropperHardReloading();

                // Mini optimization : Skip the first effect run (keep signals above this)
                if (this._isFirstTransformDataChange) {
                    this._isFirstTransformDataChange = false;
                    return;
                }

                if (isCropperHardReloading) {
                    return;
                }

                this._isCropperHotReloading.set(true);
                this._updateCropper(cropper, transformData);
                this._isCropperHotReloading.set(false);
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    private _hotReloadCropperOnResize(cropper: Cropper): void {
        const resizeSubject$ = new Subject<void>();
        this._resizeObserver = new ResizeObserver(() => {
            if (!this._isCropperInitializing() && !this._isCropperHardReloading()) {
                resizeSubject$.next();
            }
        });
        this._resizeObserver.observe(this.containerDivElementRef().nativeElement);
        resizeSubject$
            .pipe(
                tap(() => this._isCropperHotReloading.set(true)),
                debounceTime(500),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(() => {
                const transformData = {
                    ...(this._outputtedTransformData ?? this.transformData()),
                    rotationInDegrees: this.transformData().rotationInDegrees,
                };
                this._updateCropper(cropper, transformData);
                this._isCropperHotReloading.set(false);
            });
    }

    private _updateCropper(cropper: Cropper, transformData: TransformData): void {
        cropper.reset();

        // Reminder : viewMode 1 (set in Cropper constructor) re-compute the canvas area if it not covers entirely the crop box
        // and that a thing that can happen during our setup in this function, it's totally normal,
        // so we deactivate temporary the viewMode 1 by removing the crop box from the cropper with cropper.clear().
        // At the end of this function, the canvas/crop box positions should be good
        // so we re-activate viewMode 1 by calling cropper.crop()
        cropper.clear();

        // First rotate the canvas, it can switch the canvas width and height if rotation %90 === 0
        cropper.rotateTo(transformData.rotationInDegrees);

        // We take canvas natural size instead of image natural size because canvas natural size reflect the rotation
        // Ex : if rotation % 90 === 0, width/height are switched
        const naturalCanvasSize: ImageEditorUtils.Size = {
            width: cropper.getCanvasData().naturalWidth,
            height: cropper.getCanvasData().naturalHeight,
        };

        const containerSize = cropper.getContainerData();
        const transformAreaInCanvas = ImageEditorUtils.scaleTransformArea(transformData, naturalCanvasSize);
        const transformAreaInCanvasAspectRatio = transformAreaInCanvas.width / transformAreaInCanvas.height;
        const cropBoxArea = this._getCropBoxArea(containerSize, transformAreaInCanvasAspectRatio);

        const zoomRatio = ImageEditorUtils.getZoomRatioToCover(transformAreaInCanvas, cropBoxArea);

        // cropper.zoomTo(1) is equivalent to showing the natural canvas size
        cropper.zoomTo(zoomRatio);

        const canvasArea = cropper.getCanvasData();
        const originX = transformAreaInCanvas.left * (canvasArea.width / naturalCanvasSize.width);
        const originY = transformAreaInCanvas.top * (canvasArea.height / naturalCanvasSize.height);
        const newCanvasArea = {
            left: cropBoxArea.left - originX,
            top: cropBoxArea.top - originY,
        };
        // This part move the canvas depending on the transformArea
        cropper.setCanvasData(newCanvasArea);

        // This line show the cropper with default position that we will override next line
        cropper.crop();

        // This need to be the last operation because of viewMode: 1 that restrict the crop box depending on the canvas
        // So we first place the canvas and then add the crop box
        cropper.setCropBoxData(cropBoxArea);
    }

    /**
     * Basically, it compute an area inside the container with a specific aspect ratio and with the css behavior object-fit: contains.
     */
    private _getCropBoxArea(containerSize: ImageEditorUtils.Size, cropBoxAspectRatio: number): ImageEditorUtils.Area {
        const cropBoxArea = { left: 0, top: 0, width: 0, height: 0 };
        const containerAspectRatio = containerSize.width / containerSize.height;
        if (containerAspectRatio > cropBoxAspectRatio) {
            cropBoxArea.top = 0;
            cropBoxArea.height = containerSize.height;
            cropBoxArea.width = cropBoxArea.height * cropBoxAspectRatio;
            cropBoxArea.left = (containerSize.width - cropBoxArea.width) / 2;
        } else if (containerAspectRatio < cropBoxAspectRatio) {
            cropBoxArea.left = 0;
            cropBoxArea.width = containerSize.width;
            cropBoxArea.height = cropBoxArea.width / cropBoxAspectRatio;
            cropBoxArea.top = (containerSize.height - cropBoxArea.height) / 2;
        } else {
            cropBoxArea.left = 0;
            cropBoxArea.width = containerSize.width;
            cropBoxArea.top = 0;
            cropBoxArea.height = containerSize.height;
        }
        return cropBoxArea;
    }
}
