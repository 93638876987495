import { inject, Injectable, signal } from '@angular/core';
import { combineLatest, filter, Subject, switchMap } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';

@Injectable({
    providedIn: 'root',
})
export class AiSettingsContext {
    readonly _keywordsService = inject(KeywordsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);

    readonly restaurantAiSettings = signal<RestaurantAiSettings | null>(null);
    private readonly _fetchRestaurantAiSettings$ = new Subject<void>();

    constructor() {
        combineLatest([this._restaurantsService.restaurantSelected$, this._fetchRestaurantAiSettings$])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(([restaurant]) => this._restaurantAiSettingsService.getRestaurantAiSettings(restaurant!.id))
            )
            .subscribe({
                next: (restaurantAiSettings) => {
                    this.restaurantAiSettings.set(restaurantAiSettings);
                },
            });

        this.fetchRestaurantAiSettings();
    }

    fetchRestaurantAiSettings(): void {
        this._fetchRestaurantAiSettings$.next();
    }

    async updateAiSettings({
        restaurantAiSettings,
        dispatcher,
    }: {
        restaurantAiSettings: RestaurantAiSettings;
        dispatcher: RestaurantAiSettingsDispatch;
    }): Promise<void> {
        this.restaurantAiSettings.set(restaurantAiSettings);

        await this._restaurantAiSettingsService.updateRestaurantAiSettings({
            restaurantAiSettings,
            restaurantId: restaurantAiSettings.restaurantId,
            restaurantAiSettingsDispatch: dispatcher,
        });
    }
}
