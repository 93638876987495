import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { compact } from 'lodash';
import { BehaviorSubject, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { FileFormat, PictureSize, PictureSizeRecord } from '@malou-io/package-utils';

import { FoldersService } from ':core/services/folders.service';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { CircleProgressComponent } from ':shared/components-v3/circle-progress/circle-progress.component';
import { IFolder, Media } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

@Component({
    selector: 'app-download',
    standalone: true,
    imports: [CircleProgressComponent, TranslateModule],
    templateUrl: './download-medias-and-folders.component.html',
    styleUrls: ['./download-medias-and-folders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadMediasAndFoldersComponent implements OnInit {
    readonly isDownloading = signal(false);
    readonly downloadProgress$ = new BehaviorSubject(0);
    readonly filesToDownloadLength = signal(0);

    private _medias: Media[] = [];
    private _restaurant: string | null;
    private _restaurantId: string | null;
    private _folders: IFolder[] = [];

    constructor(
        private readonly _mediaService: MediaService,
        private readonly _foldersService: FoldersService,
        private readonly _toastService: ToastService,
        private readonly _route: ActivatedRoute,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    ngOnInit(): void {
        this._route.queryParamMap
            .pipe(
                map((queryParams: ParamMap) => {
                    const mediaUrls = queryParams.get('mediaUrls')?.split(',') ?? [];
                    const mediaNames = queryParams.get('mediaNames')?.split(',') ?? [];
                    const folderIds = queryParams.get('folderIds')?.split(',') ?? [];
                    this._restaurant = queryParams.get('restaurant');
                    this._restaurantId = queryParams.get('restaurantId');

                    if (mediaUrls.length && mediaNames.length && mediaUrls.length === mediaNames.length) {
                        this._medias = compact(
                            mediaUrls.map((url: string, index: number) => {
                                const urls: PictureSizeRecord<string> = { [PictureSize.ORIGINAL]: url };
                                const [name, format] = mediaNames[index].split('.');
                                if (this._isFormatHandled(format)) {
                                    return new Media({ urls, name, format });
                                } else {
                                    return undefined;
                                }
                            })
                        );
                    }

                    return folderIds;
                }),
                switchMap((folderIds) => {
                    if (folderIds.length === 0) {
                        return of([]);
                    }
                    const folders$ = folderIds.map((id) => this._foldersService.getFolderById(id));
                    return forkJoin(folders$).pipe(map((folders) => folders.map(({ data }) => data)));
                }),
                tap((folders) => {
                    this._folders = folders;
                    const foldersMediaCount = this._folders.reduce((totalMediaCount, folder) => totalMediaCount + folder.mediaCount, 0);
                    this.filesToDownloadLength.set(this._medias.length + foldersMediaCount);
                })
            )
            .subscribe(() => {
                this._downloadMediaAndFoldersAsZip();
            });
    }

    private _downloadMediaAndFoldersAsZip(): void {
        this.isDownloading.set(true);
        const zip = new JSZip();

        this._downloadMedias$(this._medias, zip)
            .pipe(
                switchMap(() => {
                    if (this._folders.length === 0) {
                        return of([]);
                    }
                    return forkJoin(this._folders.map((folder) => this._addFolderToZipAndDownloadMedia$(folder, zip)));
                })
            )
            .subscribe({
                next: () => {
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        const objectUrl = URL.createObjectURL(content);
                        saveAs(objectUrl, `${this._restaurant} - MalouApp.zip`);
                        this.isDownloading.set(false);
                        this.filesToDownloadLength.set(0);
                        this.downloadProgress$.next(0);

                        setTimeout(() => {
                            this._closeWindow();
                        }, 100);
                    });
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    this.isDownloading.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _addFolderToZipAndDownloadMedia$(folder: IFolder, zip: JSZip): Observable<JSZip> {
        const cleanFolderNameForZip = this._getCleanFolderNameForZip(folder.name);
        const nestedFolderZip = zip.folder(cleanFolderNameForZip);
        if (!nestedFolderZip || !this._restaurantId) {
            return of(zip);
        }

        return this._foldersService.getFolderMedia(folder.id).pipe(
            switchMap((medias) => this._downloadMedias$(medias, nestedFolderZip)),
            map(() => zip)
        );
    }

    private _downloadMedias$(medias: Media[], zip: JSZip): Observable<Blob[]> {
        if (medias.length === 0) {
            return of([]);
        }

        const mediaWithDifferentNames = this._getMediaWithDifferentNames(medias);

        return forkJoin(
            mediaWithDifferentNames.map((media) =>
                this._mediaService.downloadSingleMedia(media).pipe(
                    tap(() => {
                        this.downloadProgress$.next(this.downloadProgress$.value + Math.round((1 / this.filesToDownloadLength()) * 100));
                    })
                )
            )
        ).pipe(
            tap((mediaDownloaded) => {
                mediaDownloaded.forEach((mediumDownloaded, index) => {
                    zip.file(mediaWithDifferentNames[index].name, mediumDownloaded, { binary: true });
                });
            })
        );
    }

    private _getCleanFolderNameForZip(folderName: string): string {
        return folderName.replace(/(\/|-)/g, '_');
    }

    private _getMediaWithDifferentNames(medias: Media[]): Media[] {
        const mediaNames = medias.map((media) => media.getFullnameWithFormat());

        return medias.map((media, index) => {
            const count = this._countNameOccurences(mediaNames, index);
            return new Media({ urls: media.urls, name: this._getNameWithCount(media.getFullnameWithFormat(), count) });
        });
    }

    private _countNameOccurences(names: string[], index: number): number {
        const name = names[index];
        let count = 0;

        for (let i = 0; i < index; i++) {
            if (names[i] === name) {
                count++;
            }
        }

        return count;
    }

    private _getNameWithCount(name: string, count: number): string {
        if (count) {
            const [title, type] = name.split('.');
            return `${title} (${count}).${type}`;
        }

        return name;
    }

    private _closeWindow(): void {
        window.self.close();
    }

    private _isFormatHandled(format: string): format is FileFormat {
        return Object.values(FileFormat).includes(format as FileFormat);
    }
}
