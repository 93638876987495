<app-reviews-kpis-card
    [title]="'statistics.e_reputation.reviews_kpis.average_reviews_rating' | translate"
    [icon]="SvgIcon.STAR_BORDER"
    [currentValue]="currentAverageRating() | shortNumber"
    [currentValueRaw]="currentAverageRating()"
    [diffValue]="diffAverageRating()"
    [diffDisplayedValue]="diffAverageRating() | shortNumber: { shouldDisplayMinusSign: false }"
    [isLoading]="isLoading()"
    [error]="httpError()">
</app-reviews-kpis-card>
