import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    Injector,
    input,
    model,
    OnInit,
    output,
    signal,
    untracked,
    WritableSignal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, interval, of, Subscription, switchMap, takeWhile } from 'rxjs';

import { FAILED_MEDIA_AI_DESCRIPTION, MediaType, PostType, TimeInMilliseconds } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    AiPostSettingsTab,
    EditAIPostSettingsModalComponent,
} from ':modules/ai-settings/ai-post-settings/edit-ai-post-settings-modal/edit-ai-post-settings-modal.component';
import { MediaService } from ':modules/media/media.service';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { DefaultPrompt } from ':shared/openai-prompt/openai-prompt.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export enum PostCaptionDisplayState {
    OPEN = 'open',
    CLOSED = 'closed',
}

@Component({
    selector: 'app-social-post-caption-ai-generation',
    templateUrl: './social-post-caption-ai-generation.component.html',
    styleUrls: ['./social-post-caption-ai-generation.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        CdkTextareaAutosize,
        MatButtonModule,
        ReactiveFormsModule,
        MalouSpinnerComponent,
        SlideToggleComponent,
        InfiniteTextSlideComponent,
    ],
})
export class SocialPostCaptionAiGenerationComponent implements OnInit {
    readonly displayState = input.required<PostCaptionDisplayState>();
    readonly defaultPrompt = input.required<DefaultPrompt>();
    readonly promptFormControl = input(new FormControl<string>(''));
    readonly isSmallScreen = input(false);
    readonly onClose = output<void>();
    readonly onGeneratePromptClick = output<{ shouldUseImageAnalysis: boolean }>();
    readonly lastPrompt = model('');
    readonly isGenerating = model(false);

    readonly upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _translateService = inject(TranslateService);
    private readonly _injector = inject(Injector);
    private readonly _mediaService = inject(MediaService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _matDialog = inject(MatDialog);

    readonly SvgIcon = SvgIcon;
    readonly isOpen = computed(() => this.displayState() === PostCaptionDisplayState.OPEN);
    readonly BasePrompt = DefaultPrompt;

    private readonly _postAttachments = this._upsertSocialPostContext.upsertSocialPostState.post.attachments;
    readonly isPostWithAttachments = computed<boolean>(() => this._postAttachments().length > 0);
    readonly usablePhotoForImageAnalysis = computed<EditionMedia | undefined>(() =>
        this._postAttachments().find((attachment) => attachment.type === MediaType.PHOTO)
    );

    readonly shouldUseImageAnalysis = signal<boolean>(true);
    readonly shouldHideImageAnalysisToggle = computed(
        () => this._upsertSocialPostContext.upsertSocialPostState.post.postType() === PostType.REEL
    );
    readonly usableAiDescriptionForImageAnalysis = signal<string | null>(null);
    readonly hasFailedToGetAiDescription = signal(false);
    readonly _waitingForAnalysisText = this._translateService.instant(
        'social_posts.new_social_post.image_analysis.waiting_for_image_analysis'
    );
    readonly disableImageAnalysisTooltipText = computed(() => {
        if (this.hasFailedToGetAiDescription()) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_this_image');
        }
        if (!this.isPostWithAttachments()) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.add_image_to_use');
        }
        const usablePhotoForImageAnalysis = this.usablePhotoForImageAnalysis();
        if (!usablePhotoForImageAnalysis) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_videos');
        }
        const usableAiDescriptionForImageAnalysis = this.usableAiDescriptionForImageAnalysis();
        if (!usableAiDescriptionForImageAnalysis) {
            return this._waitingForAnalysisText;
        }
        if (usableAiDescriptionForImageAnalysis === FAILED_MEDIA_AI_DESCRIPTION) {
            return this._translateService.instant('social_posts.new_social_post.image_analysis.cant_analyze_this_image');
        }
        return '';
    });

    readonly isLoadingImageAnalysis = computed(() => this.disableImageAnalysisTooltipText() === this._waitingForAnalysisText);

    readonly isImageAnalysisToggleChecked = computed(() => !this.disableImageAnalysisTooltipText() && this.shouldUseImageAnalysis());
    readonly previousUsableImageId = signal<string | null>(null);

    private _waitForIntervalSubscription: Subscription | null = null;
    private readonly _hasAlreadyRetriedFetchAiDescription: WritableSignal<boolean> = signal(false);

    ngOnInit(): void {
        const localStorageDefaultOption = LocalStorage.getItem(LocalStorageKey.SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT);
        if (localStorageDefaultOption) {
            this.shouldUseImageAnalysis.set(localStorageDefaultOption === 'true');
        }

        effect(
            () => {
                const media = this.usablePhotoForImageAnalysis();
                if (!media) {
                    this.usableAiDescriptionForImageAnalysis.set(null);
                    return;
                }
                if (media.aiDescription) {
                    this.usableAiDescriptionForImageAnalysis.set(media.aiDescription);
                    return;
                }

                const isSameMediaAsBefore = untracked(() => !this.previousUsableImageId() || media.id === this.previousUsableImageId());
                untracked(() => this.previousUsableImageId.set(media.id));
                if (!isSameMediaAsBefore) {
                    this.usableAiDescriptionForImageAnalysis.set(null);
                }

                this._waitForAiDescription(media.id);
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    toggleUseImageAnalysis(): void {
        this.shouldUseImageAnalysis.update((shouldUseImageAnalysis) => !shouldUseImageAnalysis);
        LocalStorage.setItem(LocalStorageKey.SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT, this.shouldUseImageAnalysis().toString());
    }

    close(): void {
        this.onClose.emit();
    }

    generatePrompt(): void {
        this.isGenerating.set(true);

        this.onGeneratePromptClick.emit({ shouldUseImageAnalysis: this.shouldUseImageAnalysis() });
        if (this.isSmallScreen()) {
            this.close();
        }
    }

    getObjectKeys = (obj: object): string[] => Object.keys(obj);

    onCaptionProposalClick(caption: string): void {
        this.upsertSocialPostAiContext.onCaptionProposalSelection(caption);
    }

    openAiSettingsModal(): void {
        this._customDialogService
            .open<EditAIPostSettingsModalComponent>(EditAIPostSettingsModalComponent, {
                height: 'unset',
                maxHeight: '90vh',
                width: '950px',
                maxWidth: '80vw',
                data: {
                    uniqueTab: AiPostSettingsTab.SOCIAL,
                },
            })
            .afterClosed();
    }

    private _waitForAiDescription(mediaId: string): void {
        const MAX_TIME = 20 * TimeInMilliseconds.SECOND;
        const CHECK_INTERVAL = 2 * TimeInMilliseconds.SECOND;

        if (this._waitForIntervalSubscription) {
            this._waitForIntervalSubscription.unsubscribe();
            this._waitForIntervalSubscription = null;
        }

        this._waitForIntervalSubscription = interval(CHECK_INTERVAL)
            .pipe(
                takeWhile((_, index) => index * CHECK_INTERVAL < MAX_TIME),
                switchMap(() =>
                    this._mediaService.getMediumById(mediaId).pipe(
                        catchError((error) => {
                            console.error('Fetch error:', error);
                            this.hasFailedToGetAiDescription.set(true);
                            return of(null);
                        })
                    )
                ),
                takeWhile((fetchedMedia) => !fetchedMedia?.data?.aiDescription, true)
            )
            .subscribe({
                next: (fetchedMedia) => {
                    if (fetchedMedia?.data?.aiDescription) {
                        this.hasFailedToGetAiDescription.set(false);
                        this.usableAiDescriptionForImageAnalysis.set(fetchedMedia.data.aiDescription);
                    }
                },
                complete: () => {
                    if (!this._hasAlreadyRetriedFetchAiDescription()) {
                        this._retryFetchAiDescriptionOnce(mediaId);
                        return;
                    }
                    this.hasFailedToGetAiDescription.set(!this.usableAiDescriptionForImageAnalysis());
                },
            });
    }

    private readonly _retryFetchAiDescriptionOnce = (mediaId: string): void => {
        this._hasAlreadyRetriedFetchAiDescription.set(true);
        this._mediaService.fetchMediaDescription([mediaId]).subscribe({
            next: () => this._waitForAiDescription(mediaId),
            error: (err) => {
                console.warn('err :>>', err);
                this.hasFailedToGetAiDescription.set(true);
            },
        });
    };
}
