import { PartialRecord } from '../utility-types';
import { CountryCode } from './countries';

export enum LanguageCodeISO_1 {
    AA = 'aa',
    AB = 'ab',
    AE = 'ae',
    AF = 'af',
    AK = 'ak',
    AM = 'am',
    AN = 'an',
    AR = 'ar',
    AS = 'as',
    AV = 'av',
    AY = 'ay',
    AZ = 'az',
    BA = 'ba',
    BE = 'be',
    BG = 'bg',
    BH = 'bh',
    BI = 'bi',
    BM = 'bm',
    BN = 'bn',
    BO = 'bo',
    BR = 'br',
    BS = 'bs',
    CA = 'ca',
    CE = 'ce',
    CH = 'ch',
    CO = 'co',
    CR = 'cr',
    CS = 'cs',
    CU = 'cu',
    CV = 'cv',
    CY = 'cy',
    DA = 'da',
    DE = 'de',
    DV = 'dv',
    DZ = 'dz',
    EE = 'ee',
    EL = 'el',
    EN = 'en',
    EO = 'eo',
    ES = 'es',
    ET = 'et',
    EU = 'eu',
    FA = 'fa',
    FF = 'ff',
    FI = 'fi',
    FJ = 'fj',
    FO = 'fo',
    FR = 'fr',
    FY = 'fy',
    GA = 'ga',
    GD = 'gd',
    GL = 'gl',
    GN = 'gn',
    GU = 'gu',
    GV = 'gv',
    HA = 'ha',
    HAW = 'haw',
    HE = 'he',
    HI = 'hi',
    HO = 'ho',
    HR = 'hr',
    HT = 'ht',
    HU = 'hu',
    HY = 'hy',
    HZ = 'hz',
    IA = 'ia',
    IN = 'in',
    ID = 'id',
    IE = 'ie',
    IG = 'ig',
    II = 'ii',
    IK = 'ik',
    IO = 'io',
    IS = 'is',
    IT = 'it',
    IU = 'iu',
    IW = 'iw',
    JA = 'ja',
    JV = 'jv',
    KA = 'ka',
    KG = 'kg',
    KI = 'ki',
    KJ = 'kj',
    KK = 'kk',
    KL = 'kl',
    KM = 'km',
    KN = 'kn',
    KO = 'ko',
    KR = 'kr',
    KS = 'ks',
    KU = 'ku',
    KV = 'kv',
    KW = 'kw',
    KY = 'ky',
    LA = 'la',
    LB = 'lb',
    LG = 'lg',
    LI = 'li',
    LN = 'ln',
    LO = 'lo',
    LT = 'lt',
    LU = 'lu',
    LV = 'lv',
    MG = 'mg',
    MH = 'mh',
    MI = 'mi',
    MK = 'mk',
    ML = 'ml',
    MN = 'mn',
    MR = 'mr',
    MS = 'ms',
    MT = 'mt',
    MY = 'my',
    NA = 'na',
    NB = 'nb',
    ND = 'nd',
    NE = 'ne',
    NG = 'ng',
    NL = 'nl',
    NN = 'nn',
    NO = 'no',
    NR = 'nr',
    NV = 'nv',
    NY = 'ny',
    OC = 'oc',
    OJ = 'oj',
    OM = 'om',
    OR = 'or',
    OS = 'os',
    PA = 'pa',
    PI = 'pi',
    PL = 'pl',
    PS = 'ps',
    PT = 'pt',
    PT_PT = 'pt-PT',
    QU = 'qu',
    RM = 'rm',
    RN = 'rn',
    RO = 'ro',
    RU = 'ru',
    RW = 'rw',
    SA = 'sa',
    SC = 'sc',
    SD = 'sd',
    SE = 'se',
    SG = 'sg',
    SI = 'si',
    SK = 'sk',
    SL = 'sl',
    SM = 'sm',
    SN = 'sn',
    SO = 'so',
    SQ = 'sq',
    SR = 'sr',
    SS = 'ss',
    ST = 'st',
    SU = 'su',
    SV = 'sv',
    SW = 'sw',
    TA = 'ta',
    TE = 'te',
    TG = 'tg',
    TH = 'th',
    TI = 'ti',
    TK = 'tk',
    TL = 'tl',
    TN = 'tn',
    TO = 'to',
    TR = 'tr',
    TS = 'ts',
    TT = 'tt',
    TW = 'tw',
    TY = 'ty',
    UG = 'ug',
    UK = 'uk',
    UR = 'ur',
    UZ = 'uz',
    VE = 've',
    VI = 'vi',
    VO = 'vo',
    WA = 'wa',
    WO = 'wo',
    XH = 'xh',
    YI = 'yi',
    YO = 'yo',
    ZA = 'za',
    ZH = 'zh',
    ZH_CN = 'zh-CN',
    ZHTW = 'zhTW',
    ZU = 'zu',
}

export const languagesByCountryCodes: PartialRecord<CountryCode, LanguageCodeISO_1> = {
    [CountryCode.FRANCE]: LanguageCodeISO_1.FR,
    [CountryCode.UNITED_STATES]: LanguageCodeISO_1.EN,
    [CountryCode.BELGIUM]: LanguageCodeISO_1.FR,
    [CountryCode.NETHERLANDS]: LanguageCodeISO_1.NL,
    [CountryCode.GERMANY]: LanguageCodeISO_1.DE,
    [CountryCode.SWITZERLAND]: LanguageCodeISO_1.FR,
    [CountryCode.DENMARK]: LanguageCodeISO_1.DA,
    [CountryCode.NORWAY]: LanguageCodeISO_1.NO,
    [CountryCode.SWEDEN]: LanguageCodeISO_1.SV,
    [CountryCode.CROATIA]: LanguageCodeISO_1.HR,
    [CountryCode.CZECH_REPUBLIC]: LanguageCodeISO_1.CS,
    [CountryCode.BULGARIA]: LanguageCodeISO_1.BG,
    [CountryCode.GREECE]: LanguageCodeISO_1.EL,
    [CountryCode.IRELAND]: LanguageCodeISO_1.EN,
    [CountryCode.UNITED_KINGDOM]: LanguageCodeISO_1.EN,
    [CountryCode.UNITED_ARAB_EMIRATES]: LanguageCodeISO_1.EN,
    [CountryCode.LUXEMBOURG]: LanguageCodeISO_1.FR,
    [CountryCode.LITHUANIA]: LanguageCodeISO_1.LT,
    [CountryCode.ICELAND]: LanguageCodeISO_1.EN,
    [CountryCode.PORTUGAL]: LanguageCodeISO_1.PT,
    [CountryCode.POLAND]: LanguageCodeISO_1.PL,
    [CountryCode.ROMANIA]: LanguageCodeISO_1.RO,
    [CountryCode.SLOVENIA]: LanguageCodeISO_1.SL,
    [CountryCode.SAUDI_ARABIA]: LanguageCodeISO_1.EN,
    [CountryCode.MOROCCO]: LanguageCodeISO_1.FR,
    [CountryCode.ALGERIA]: LanguageCodeISO_1.FR,
};

export enum ApplicationLanguage {
    FR = 'fr',
    EN = 'en',
    ES = 'es',
    IT = 'it',
}

export enum Locale {
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
}

export const APP_DEFAULT_LANGUAGE = ApplicationLanguage.EN;

export const APP_DEFAULT_LOCALE = Locale.EN;

export const mapApplicationLanguageToLocale = (lang: ApplicationLanguage): Locale => {
    switch (lang) {
        case ApplicationLanguage.EN:
            return Locale.EN;
        case ApplicationLanguage.FR:
            return Locale.FR;
        case ApplicationLanguage.IT:
            return Locale.IT;
        case ApplicationLanguage.ES:
            return Locale.ES;
        default:
            return APP_DEFAULT_LOCALE;
    }
};

export const applicationLanguageDisplayName: Record<ApplicationLanguage | string, { en: string }> = {
    [ApplicationLanguage.FR]: {
        en: 'French',
    },
    [ApplicationLanguage.EN]: {
        en: 'English',
    },
    [ApplicationLanguage.ES]: {
        en: 'Spanish',
    },
    [ApplicationLanguage.IT]: {
        en: 'Italian',
    },
    af: { en: 'Afrikaans' },
    am: { en: 'Amharic' },
    ar: { en: 'Arabic' },
    az: { en: 'Azerbaijani' },
    be: { en: 'Belarusian' },
    bg: { en: 'Bulgarian' },
    bn: { en: 'Bengali' },
    bs: { en: 'Bosnian' },
    ca: { en: 'Catalan' },
    ceb: { en: 'Cebuano' },
    co: { en: 'Corsican' },
    cs: { en: 'Czech' },
    cy: { en: 'Welsh' },
    da: { en: 'Danish' },
    de: { en: 'German' },
    el: { en: 'Greek' },
    eo: { en: 'Esperanto' },
    et: { en: 'Estonian' },
    eu: { en: 'Basque' },
    fa: { en: 'Persian' },
    fi: { en: 'Finnish' },
    fy: { en: 'Frisian' },
    ga: { en: 'Irish' },
    gd: { en: 'Scots gaelic' },
    gl: { en: 'Galician' },
    gu: { en: 'Gujarati' },
    ha: { en: 'Hausa' },
    haw: { en: 'Hawaiian' },
    he: { en: 'Hebrew' },
    hi: { en: 'Hindi' },
    hmn: { en: 'Hmong' },
    hr: { en: 'Croatian' },
    ht: { en: 'Haitian creole' },
    hu: { en: 'Hungarian' },
    hy: { en: 'Armenian' },
    id: { en: 'Indonesian' },
    ig: { en: 'Igbo' },
    is: { en: 'Icelandic' },
    iw: { en: 'Hebrew' },
    ja: { en: 'Japanese' },
    jw: { en: 'Javanese' },
    ka: { en: 'Georgian' },
    kk: { en: 'Kazakh' },
    km: { en: 'Khmer' },
    kn: { en: 'Kannada' },
    ko: { en: 'Korean' },
    ku: { en: 'Kurdish (kurmanji)' },
    ky: { en: 'Kyrgyz' },
    la: { en: 'Latin' },
    lb: { en: 'Luxembourgish' },
    lo: { en: 'Lao' },
    lt: { en: 'Lithuanian' },
    lv: { en: 'Latvian' },
    mg: { en: 'Malagasy' },
    mi: { en: 'Maori' },
    mk: { en: 'Macedonian' },
    ml: { en: 'Malayalam' },
    mn: { en: 'Mongolian' },
    mr: { en: 'Marathi' },
    ms: { en: 'Malay' },
    mt: { en: 'Maltese' },
    my: { en: 'Myanmar (burmese)' },
    ne: { en: 'Nepali' },
    nl: { en: 'Dutch' },
    no: { en: 'Norwegian' },
    ny: { en: 'Chichewa' },
    or: { en: 'Odia' },
    pa: { en: 'Punjabi' },
    pl: { en: 'Polish' },
    ps: { en: 'Pashto' },
    pt: { en: 'Portuguese' },
    ro: { en: 'Romanian' },
    ru: { en: 'Russian' },
    sd: { en: 'Sindhi' },
    si: { en: 'Sinhala' },
    sk: { en: 'Slovak' },
    sl: { en: 'Slovenian' },
    sm: { en: 'Samoan' },
    sn: { en: 'Shona' },
    so: { en: 'Somali' },
    sq: { en: 'Albanian' },
    sr: { en: 'Serbian' },
    st: { en: 'Sesotho' },
    su: { en: 'Sundanese' },
    sv: { en: 'Swedish' },
    sw: { en: 'Swahili' },
    ta: { en: 'Tamil' },
    te: { en: 'Telugu' },
    tg: { en: 'Tajik' },
    th: { en: 'Thai' },
    tl: { en: 'Filipino' },
    tr: { en: 'Turkish' },
    ug: { en: 'Uyghur' },
    uk: { en: 'Ukrainian' },
    undetermined: { en: 'Undetermined' },
    ur: { en: 'Urdu' },
    uz: { en: 'Uzbek' },
    vi: { en: 'Vietnamese' },
    xh: { en: 'Xhosa' },
    yi: { en: 'Yiddish' },
    yo: { en: 'Yoruba' },
    'zh-CN': { en: 'Chinese (simplified)' },
    'zh-TW': { en: 'Chinese (traditional)' },
    zu: { en: 'Zulu' },
};

export enum MaloupeLocale {
    EN = 'en',
    FR = 'fr',
}

export const mapMaloupeLocaleToLocale = (lang: MaloupeLocale): Locale => {
    switch (lang) {
        case MaloupeLocale.EN:
            return Locale.EN;
        case MaloupeLocale.FR:
        default:
            return Locale.FR;
    }
};
