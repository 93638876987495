@if (isKeywordsInsightsV2Enabled()) {
    <ng-container [ngTemplateOutlet]="seoTemplateV2"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="seoTemplate"></ng-container>
}

<ng-template #seoTemplate>
    <div class="flex h-full flex-col gap-y-6 overflow-y-auto py-4" #topOfComponent>
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        <div class="flex h-full flex-col gap-y-4 overflow-scroll px-8.5">
            @if (screenSizeService.isPhoneScreen) {
                <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
            }
            <div class="mb-4 flex gap-6 md:flex-col">
                <div class="min-w-0 flex-1">
                    <app-statistics-seo-keywords-v3
                        (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.KEYWORDS, $event)"
                        (isLoadingEvent)="isKeywordsLoading.set($event)">
                    </app-statistics-seo-keywords-v3>
                </div>
            </div>

            @if (isGmbInsightsChartsV2Enabled()) {
                <div class="min-w-0 flex-1">
                    <app-gmb-insights
                        (isLoadingEvent)="isGmbInsightsLoading.set($event)"
                        (viewByChange)="onViewByChange($event.chart, $event.viewBy)"
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange($event.chart, $event.hiddenDatasetIndexes)">
                    </app-gmb-insights>
                </div>
            } @else {
                <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
                    <div class="min-w-0 flex-1">
                        <app-statistics-seo-gmb-discoveries
                            (viewByChange)="onViewByChange(InsightsChart.APPARITIONS, $event)"
                            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.APPARITIONS, $event)"
                            (isLoadingEvent)="isImpressionsLoading.set($event)">
                        </app-statistics-seo-gmb-discoveries>
                    </div>
                    <div class="min-w-0 flex-1">
                        <app-statistics-seo-gmb-actions
                            (viewByChange)="onViewByChange(InsightsChart.ACTIONS, $event)"
                            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ACTIONS, $event)"
                            (isLoadingEvent)="isActionsLoading.set($event)">
                        </app-statistics-seo-gmb-actions>
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-center justify-between px-8.5 sm:flex-col sm:items-center md:px-0">
        <app-statistics-filters [platformFilterPage]="PlatformFilterPage.SEO"></app-statistics-filters>
        <button
            class="malou-btn-raised--primary !h-12 sm:mt-2"
            mat-raised-button
            [disabled]="isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #seoTemplateV2>
    <div class="flex h-full flex-col gap-y-4" #topOfComponent>
        <div class="flex h-full flex-col">
            <mat-tab-group class="malou-tab-group h-full" [disableRipple]="true" (selectedIndexChange)="handleTabChange($event)">
                <mat-tab label="{{ 'statistics.seo.tabs.keywords' | translate }}">
                    <ng-container
                        [ngTemplateOutlet]="scrollableTab"
                        [ngTemplateOutletContext]="{
                            template: keywordsTemplateV2,
                        }"></ng-container>
                </mat-tab>
                <mat-tab label=" {{ 'statistics.seo.tabs.gmb_visibility' | translate }}">
                    <ng-container
                        [ngTemplateOutlet]="scrollableTab"
                        [ngTemplateOutletContext]="{
                            template: gmbTemplateV2,
                        }"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-template>

<ng-template #filtersTemplateV2>
    <div class="flex items-center justify-between sm:flex-col sm:items-center">
        <app-statistics-filters [platformFilterPage]="PlatformFilterPage.SEO" [showTimeScaleFilter]="shouldShowTimeScaleFilter()">
        </app-statistics-filters>
        <button
            class="malou-btn-raised--primary !h-12 sm:mt-2"
            mat-raised-button
            [disabled]="isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template let-template="template" #scrollableTab>
    <div class="flex h-full flex-col gap-4 px-8.5 py-4 sm:px-4">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplateV2"></ng-container>
        }
        <div class="h-full overflow-y-auto">
            <div class="flex flex-col gap-4">
                @if (screenSizeService.isPhoneScreen) {
                    <ng-container [ngTemplateOutlet]="filtersTemplateV2"></ng-container>
                }
                <ng-container [ngTemplateOutlet]="template"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #keywordsTemplateV2>
    <app-keyword-search-impressions></app-keyword-search-impressions>
    <app-statistics-keywords-detail
        (tableSortChange)="onTableSortOptionsChange(InsightsChart.KEYWORDS, $event)"
        (isLoadingEvent)="isKeywordsLoading.set($event)"></app-statistics-keywords-detail>
</ng-template>

<ng-template #gmbTemplateV2>
    @if (isGmbInsightsChartsV2Enabled()) {
        <div class="min-w-0 flex-1">
            <app-gmb-insights
                (isLoadingEvent)="isGmbInsightsLoading.set($event)"
                (viewByChange)="onViewByChange($event.chart, $event.viewBy)"
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange($event.chart, $event.hiddenDatasetIndexes)">
            </app-gmb-insights>
        </div>
    } @else {
        <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
            <div class="min-w-0 flex-1">
                <app-statistics-seo-gmb-discoveries
                    (viewByChange)="onViewByChange(InsightsChart.APPARITIONS, $event)"
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.APPARITIONS, $event)"
                    (isLoadingEvent)="isImpressionsLoading.set($event)">
                </app-statistics-seo-gmb-discoveries>
            </div>
            <div class="min-w-0 flex-1">
                <app-statistics-seo-gmb-actions
                    (viewByChange)="onViewByChange(InsightsChart.ACTIONS, $event)"
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ACTIONS, $event)"
                    (isLoadingEvent)="isActionsLoading.set($event)">
                </app-statistics-seo-gmb-actions>
            </div>
        </div>
    }
</ng-template>
