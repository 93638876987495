export const CREATE_SOCIAL_POST_MODAL_HTML_ID = 'create-social-post-modal';

export const extractHashtags = (text?: string): { textWithoutHashtag: string; hashtags: string[] } => {
    const hashtagsRegex = /#(\w|[À-ž])+/g;
    const hashtags = text?.match(hashtagsRegex) ?? [];
    const cleanedHashtags = hashtags.map((ht) => ht.replace('#', ''));

    const textWithoutHashtag = text?.replace(hashtagsRegex, '').trim() || '';

    return { textWithoutHashtag, hashtags: cleanedHashtags };
};
