import { SocialPostMediaDto } from '@malou-io/package-dto';
import { AspectRatio, MediaType, RemoveMethodsFromClass } from '@malou-io/package-utils';

interface TransformData {
    aspectRatio: AspectRatio;
    rotationInDegrees: number;
    left: number;
    top: number;
    width: number;
    height: number;
}

interface Dimensions {
    width: number;
    height: number;
}

export type ISocialPostMedia = RemoveMethodsFromClass<SocialPostMedia>;

export class SocialPostMedia {
    url: string;
    type: MediaType;
    thumbnailUrl?: string;
    thumbnailDimensions?: Dimensions;
    transformData?: TransformData;

    constructor(data: ISocialPostMedia) {
        this.url = data.url;
        this.type = data.type;
        this.thumbnailUrl = data.thumbnailUrl;
        this.thumbnailDimensions = data.thumbnailDimensions;
        this.transformData = data.transformData;
    }

    static fromDto(dto: SocialPostMediaDto): SocialPostMedia {
        return new SocialPostMedia({
            url: dto.url,
            type: dto.type,
            thumbnailUrl: dto.thumbnailUrl,
            thumbnailDimensions: dto.thumbnailDimensions,
            transformData: dto.transformData,
        });
    }
}
