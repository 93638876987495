import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart, MalouComparisonPeriod } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

import { CommunityComponent } from '../../statistics/social-networks/community/community.component';
import { EngagementComponent } from '../../statistics/social-networks/engagement/engagement.component';
import { PostsInsightsTableComponent } from '../../statistics/social-networks/posts-insights-table/posts-insights-table.component';
import * as StatisticsActions from '../../statistics/store/statistics.actions';
import { PlatformFilterPage } from '../../statistics/store/statistics.interface';

@Component({
    selector: 'app-social-network-pdf',
    standalone: true,
    imports: [
        CommonModule,
        CommunityComponent,
        EngagementComponent,
        PostsInsightsTableComponent,
        AsyncPipe,
        TranslateModule,
        FromToDateFormatterPipe,
        IncludesPipe,
        AsyncPipe,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './social-network-pdf.component.html',
    styleUrls: ['./social-network-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialNetworkPdfComponent {
    readonly InsightsChart = InsightsChart;

    chartOptions: ChartOptions = {};
    displayedCharts: InsightsChart[] = [];
    displayedPlatforms: string;

    startDate: Date;
    endDate: Date;

    communityHasData = true;
    engagementHasData = true;

    readonly selectedRestaurantTitle$: Observable<string>;

    constructor(
        private _store: Store,

        private readonly _enumTranslatePipe: EnumTranslatePipe,
        public readonly translateService: TranslateService,
        private readonly _restaurantsService: RestaurantsService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { startDate, endDate, displayedCharts, chartOptions, platformKeys, comparisonPeriod } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.displayedCharts = displayedCharts;

        this.startDate = startDate;
        this.endDate = endDate;

        this.displayedPlatforms = this.translateService.instant('statistics_pdf.e_reputation_pdf.platforms', {
            platforms: (platformKeys ?? []).map((platformKey) => this._enumTranslatePipe.transform(platformKey, 'platform_key')).join(', '),
        });

        this._store.dispatch(StatisticsActions.editPlatforms({ page: PlatformFilterPage.SOCIAL_NETWORKS, platforms: platformKeys ?? [] }));

        this._store.dispatch(
            StatisticsActions.editComparisonPeriod({ comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD })
        );

        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }
}
