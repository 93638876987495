import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Subject } from 'rxjs';

import { InformationKeySent, PlatformDefinition, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { PlatformState } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-platforms-connection-help-modal',
    templateUrl: './platforms-connection-help-modal.component.html',
    styleUrls: ['./platforms-connection-help-modal.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        PlatformLogoComponent,
        EmojiPathResolverPipe,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class PlatformsConnectionHelpModalComponent implements KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly PlatformState = PlatformState;

    readonly platformKey: WritableSignal<PlatformKey> = signal(PlatformKey.GMB);
    readonly platformState: WritableSignal<PlatformState | null> = signal(null);
    readonly platformHelp: Signal<PlatformDefinition | undefined> = computed(() =>
        PlatformDefinitions.getPlatformDefinition(this.platformKey())
    );
    readonly platformHelpMappedInformationSent: Signal<string[]> = computed(
        () =>
            this.platformHelp()
                ?.informationSent.filter((info) => info !== InformationKeySent.isClosedTemporarily)
                .map((info) => this._mapInformationKeySentToTranslateEnum(info)) ?? []
    );

    private readonly _DEFAULT_FEATURE_COUNT_BY_COL = 4;
    readonly featureCountByCol = computed<number>(() => {
        const platformHelpFeatures = this.platformHelp()?.features;
        if (platformHelpFeatures && platformHelpFeatures.length > this._DEFAULT_FEATURE_COUNT_BY_COL) {
            return Math.ceil(platformHelpFeatures.length / 2);
        }
        return this._DEFAULT_FEATURE_COUNT_BY_COL;
    });

    private readonly _DEFAULT_INFORMATION_COUNT_BY_COL = 3;
    readonly informationCountByCol = computed<number>(() => {
        const platformHelpInformationSent = this.platformHelp()?.informationSent;
        if (platformHelpInformationSent) {
            return Math.ceil(platformHelpInformationSent.length / 2);
        }
        return this._DEFAULT_INFORMATION_COUNT_BY_COL;
    });

    constructor(
        private readonly _dialogRef: MatDialogRef<PlatformsConnectionHelpModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            platformKey: PlatformKey;
            platformState: PlatformState;
        }
    ) {
        this.platformKey.set(this.data.platformKey);
        this.platformState.set(this.data.platformState);
    }

    close(openPlatformConnectModal?: boolean): void {
        this._dialogRef.close(openPlatformConnectModal);
    }

    // key of platform_connection_help_information in translation files
    private _mapInformationKeySentToTranslateEnum(key: InformationKeySent): string {
        switch (key) {
            case InformationKeySent.coverUrl:
                return 'cover';
            case InformationKeySent.logoUrl:
                return 'logo_url';
            case InformationKeySent.categoryName:
                return 'main_category';
            case InformationKeySent.secondaryCategoriesNames:
                return 'secondary_categories_names';
            case InformationKeySent.openingDate:
                return 'opening_date';
            case InformationKeySent.regularHours:
                return 'regular_hours';
            case InformationKeySent.specialHours:
                return 'special_hours';
            case InformationKeySent.otherHours:
                return 'other_hours';
            case InformationKeySent.isClosedTemporarily:
                return 'is_closed_temporarily';
            case InformationKeySent.shortDescription:
                return 'short_description';
            case InformationKeySent.longDescription:
                return 'long_description';
            case InformationKeySent.reservationUrl:
                return 'reservation_url';
            case InformationKeySent.orderUrl:
                return 'order_url';
            case InformationKeySent.menuUrl:
                return 'menu_url';
            case InformationKeySent.instagramUrl:
                return 'instagram_url';
            case InformationKeySent.facebookUrl:
                return 'facebook_url';
            case InformationKeySent.linkedinUrl:
                return 'linkedin_url';
            case InformationKeySent.tiktokUrl:
                return 'tiktok_url';
            case InformationKeySent.youtubeUrl:
                return 'youtube_url';
            case InformationKeySent.pinterestUrl:
                return 'pinterest_url';
            case InformationKeySent.xUrl:
                return 'x_url';
            default:
                return key;
        }
    }
}
