import { MAX_KEYWORD_LENGTH, MAX_WORDS_COUNT, MIN_KEYWORD_LENGTH } from './constants';
import { RankHistoryItem, RankingPosition } from './keywords.interface';

export function isValidKeywordText(keyword: string): boolean {
    if (!keyword?.trim()?.length) {
        return false;
    }
    const hasKeywordTooManyWords = keyword.split(' ').length > MAX_WORDS_COUNT;
    const isKeywordTooShort = keyword.length < MIN_KEYWORD_LENGTH;
    const isKeywordTooLong = keyword.length > MAX_KEYWORD_LENGTH;

    return !hasKeywordTooManyWords && !isKeywordTooShort && !isKeywordTooLong;
}

/**
 * Returns undefined if we have no data.
 */
export const getMostRecentRank = ({ rankHistory }: { rankHistory: RankHistoryItem[] }): RankingPosition | undefined => {
    if (!rankHistory.length) {
        return undefined;
    }
    const p = rankHistory[rankHistory.length - 1];
    return {
        createdAt: new Date(p.fetchDate),
        position: {
            rank: p.rank ?? Infinity,
            outOf: p.outOf,
        },
    };
};
