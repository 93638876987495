import { z } from 'zod';

import { AspectRatio, MediaType, PlatformKey, PostPublicationStatus, PostType, SocialPostsListFilter } from '@malou-io/package-utils';

import { restaurantIdParamsTransformValidator } from '../common';
import { MediaDimensionDto } from '../media';
import { PostHashtagsDto } from './post-hashtags.dto';

export interface SocialPostItemDto {
    id: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    postType: PostType;
    feedbackMessageCount: number;
    plannedPublicationDate: Date | null;
    media: SocialPostMediaDto | null;
    hashtags: PostHashtagsDto;
    error: {
        code: string;
        rawData: string;
    } | null;
    socialLink?: string;
    socialCreatedAt?: Date;
    sortDate?: Date;
    author?: PostAuthorDto;
}

export interface TransformDto {
    aspectRatio: AspectRatio;
    rotationInDegrees: number;
    left: number;
    top: number;
    width: number;
    height: number;
}

export interface SocialPostMediaDto {
    url: string;
    type: MediaType;
    thumbnailUrl?: string; // some v1 media video don't have thumbnail
    thumbnailDimensions?: MediaDimensionDto;
    transformData: TransformDto;
}

export interface PostAuthorDto {
    id: string;
    name?: string;
    lastname?: string;
    picture?: string;
}

export const getSocialPostsQueryValidator = z
    .object({
        cursor: z.string().datetime().nullish(),
        limit: z.coerce.number().int().positive().nullish(),
        filter: z.nativeEnum(SocialPostsListFilter).nullish(),
    })
    .transform((data) => ({
        cursor: data.cursor ? new Date(data.cursor) : null,
        limit: data.limit ?? null,
        filter: data.filter ?? SocialPostsListFilter.ALL,
    }));

export type GetSocialPostsQueryDto = z.infer<typeof getSocialPostsQueryValidator>;

export const getSocialPostsParamsValidator = restaurantIdParamsTransformValidator;
export type GetSocialPostsParamsDto = z.infer<typeof getSocialPostsParamsValidator>;
