<div
    class="malou-color-background-purple-light mb-4 flex items-center justify-between !rounded-[5px] border border-malou-color-border-secondary p-4">
    <div>
        <p class="malou-text-12--semibold mb-1 text-malou-color-text-1">
            {{ 'reviews.reply_modal.platform_disconnected' | translate: { platform: prettyPlatformKey() } }}
        </p>
        <p class="malou-text-12--regular text-malou-color-text-2">{{ 'reviews.reply_modal.cant_reply' | translate }}</p>
    </div>
    <button
        class="malou-btn-raised--primary !h-12 !rounded-[5px] md:grow"
        id="tracking_answer_review_modal_disconnected_platform_click"
        mat-raised-button
        (click)="goToConnectPlatform()">
        {{ 'reviews.reply_modal.reconnect_platform' | translate: { platform: prettyPlatformKey() } }}
    </button>
</div>
