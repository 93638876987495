@if (isLoading()) {
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
} @else if (httpError()) {
    <ng-container [ngTemplateOutlet]="httpErrorTemplate"></ng-container>
} @else if (!hasData()) {
    <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
}

<ng-template #dataTemplate>
    <div class="malou-simple-card flex flex-1 gap-4 px-6 py-3 md:flex-col">
        <div class="flex-5 grow">
            <app-impressions-insights
                [currentKeywordSearchImpressionsInsights]="currentKeywordSearchImpressionsInsights()"
                [previousKeywordSearchImpressionsInsights]="previousKeywordSearchImpressionsInsights()"
                [dateInterval]="dateInterval()">
            </app-impressions-insights>
        </div>
        <div class="flex-2 grow">
            <app-top-keyword-search-impressions [topKeywordSearchImpressions]="topKeywordSearchImpressions()">
            </app-top-keyword-search-impressions>
        </div>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="min-w-0 flex-1">
        <app-skeleton skeletonClass="!h-[500px] secondary-bg w-full"></app-skeleton>
    </div>
</ng-template>

<ng-template #emptyState>
    <div class="malou-simple-card flex !h-[500px] flex-1 flex-col">
        <div class="malou-text-18--bold malou-color-text-1 m-8">
            {{ 'statistics.seo.keyword_search_impressions.insights.title' | translate }}
        </div>
        <div class="flex flex-1 flex-col items-center justify-center">
            <img class="mb-6 h-50" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
            <div class="malou-text-14--bold malou-color-text-1 mb-2">
                {{ 'statistics.seo.keyword_search_impressions.no_results.title' | translate }}
            </div>
            <div class="malou-text-10--regular malou-color-text-2">
                {{ 'statistics.seo.keyword_search_impressions.no_results.text' | translate }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #httpErrorTemplate>
    <div class="malou-simple-card flex !h-[500px] flex-1 flex-col">
        <div class="malou-text-18--bold malou-color-text-1 m-3">
            {{ 'statistics.seo.keyword_search_impressions.insights.title' | translate }}
        </div>
        <div class="flex flex-1 flex-col items-center justify-center">
            <div>
                <img class="mb-6 h-50" alt="Search illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                <span class="malou-text-14--bold mt-2">{{ 'common.server_is_not_responding' | translate }}</span>
            </div>
        </div>
    </div>
</ng-template>
