import { DateTime } from 'luxon';

import { MalouComparisonPeriod } from './interface';

interface GetDateRangeFromMalouComparisonPeriodInput {
    restaurantStartDate?: Date;
    dateFilters: {
        startDate: Date;
        endDate: Date;
    };
    comparisonPeriod: MalouComparisonPeriod;
}

interface GetDateRangeFromMalouComparisonPeriodOutput {
    startDate: Date | null;
    endDate: Date | null;
}

export const getDateRangeFromMalouComparisonPeriod = ({
    restaurantStartDate,
    dateFilters,
    comparisonPeriod,
}: GetDateRangeFromMalouComparisonPeriodInput): GetDateRangeFromMalouComparisonPeriodOutput => {
    const offset = DateTime.local().offset;
    const startDateLuxon = DateTime.fromJSDate(dateFilters.startDate).plus({ minutes: offset }).setZone('utc');
    const endDateLuxon = DateTime.fromJSDate(dateFilters.endDate).plus({ minutes: offset }).setZone('utc');
    const duration = endDateLuxon.diff(startDateLuxon).as('milliseconds');

    if (!restaurantStartDate || comparisonPeriod === MalouComparisonPeriod.PREVIOUS_PERIOD) {
        const startDateForPreviousPeriod = startDateLuxon.minus({ days: 1 });
        return {
            startDate: startDateForPreviousPeriod.minus(duration).toJSDate(),
            endDate: startDateForPreviousPeriod.toJSDate(),
        };
    }
    const restaurantStartDateLuxon = DateTime.fromJSDate(restaurantStartDate).setZone('utc');
    switch (comparisonPeriod) {
        case MalouComparisonPeriod.SAME_PERIOD_FIRST_YEAR:
            const restaurantStartMonth = restaurantStartDateLuxon.month;
            let comparisonYear = restaurantStartDateLuxon.year;
            if (startDateLuxon.month < restaurantStartMonth) {
                comparisonYear++;
            }
            const firstYearStartDate = DateTime.fromObject({
                year: comparisonYear,
                month: startDateLuxon.month,
                day: startDateLuxon.day,
                hour: 0,
                minute: 0,
                second: 0,
            });

            if (firstYearStartDate.valueOf() < restaurantStartDateLuxon.valueOf()) {
                return {
                    startDate: null,
                    endDate: null,
                };
            }
            const firstYearEndDate = firstYearStartDate.plus(duration);

            return {
                startDate: firstYearStartDate.toJSDate(),
                endDate: firstYearEndDate.toJSDate(),
            };
        case MalouComparisonPeriod.SINCE_START:
            return {
                startDate: restaurantStartDateLuxon.toJSDate(),
                endDate: restaurantStartDateLuxon.plus(duration).toJSDate(),
            };
        default:
            throw new Error('Invalid comparison period');
            break;
    }
};

/**
 * Be careful, this function is only handling the previous_period comparison for now
 * previous period for { startDate: 2021-01-03, endDate: 2021-03-15 } will be { startDate: 2020-10-03, endDate: 2020-12-15 }
 */
export const getDateRangeFromMalouComparisonPeriodByMonth = ({
    dateFilters,
    comparisonPeriod,
}: GetDateRangeFromMalouComparisonPeriodInput): GetDateRangeFromMalouComparisonPeriodOutput => {
    if (comparisonPeriod !== MalouComparisonPeriod.PREVIOUS_PERIOD) {
        throw new Error('Invalid comparison period');
    }

    const startDateLuxon = DateTime.fromJSDate(dateFilters.startDate).setZone('utc');
    const endDateLuxon = DateTime.fromJSDate(dateFilters.endDate).setZone('utc');

    const duration = endDateLuxon.diff(startDateLuxon, ['months']).toObject().months ?? 0;

    const monthsDiff = Math.ceil(duration);

    return {
        startDate: startDateLuxon.minus({ months: monthsDiff }).toJSDate(),
        endDate: endDateLuxon.minus({ months: monthsDiff }).toJSDate(),
    };
};
