import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PostDatePickerModalComponent } from ':modules/posts-v2/post-date-picker/post-date-picker-modal/post-date-picker-modal.component';
import {
    PostDatePickerModalProps,
    PostDatePickerModalResult,
} from ':modules/posts-v2/post-date-picker/post-date-picker-modal/post-date-picker-modal.interface';
import { PostDatePickerSize } from ':modules/posts-v2/post-date-picker/post-date-picker.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-post-date-picker',
    templateUrl: './post-date-picker.component.html',
    styleUrls: ['./post-date-picker.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, FormatDatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostDatePickerComponent {
    readonly selectedDate = model<Date | null>(null);
    readonly disabled = input<boolean>(false);
    readonly size = input<PostDatePickerSize>(PostDatePickerSize.SMALL);
    readonly withBorder = input<boolean>(false);

    private readonly _customDialogService = inject(CustomDialogService);

    readonly SvgIcon = SvgIcon;
    readonly PostDatePickerSize = PostDatePickerSize;

    openCalendar(): void {
        this._customDialogService
            .open<PostDatePickerModalComponent, PostDatePickerModalProps, PostDatePickerModalResult>(PostDatePickerModalComponent, {
                data: { selectedDate: this.selectedDate() },
                disableClose: false,
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.selectedDate) {
                    this.selectedDate.set(result.selectedDate);
                }
            });
    }
}
