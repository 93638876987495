import { mean, sum } from 'lodash';
import { DateTime } from 'luxon';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    getMonthsFromPeriod,
    getWeeksFromPeriod,
    isNotNil,
    isSameDay,
    MalouComparisonPeriod,
    MalouMetric,
    Month,
    PlatformKey,
    PostType,
    Week,
} from '@malou-io/package-utils';

import { SplittedEngagementData } from ':modules/statistics/social-networks/engagement-v2/engagement.interface';
import { formatDateToISO, getDaysFromCurrentRange } from ':shared/helpers';
import { PostsWithInsightsByPlatforms, PostWithInsights } from ':shared/models';

interface EngagementChartData {
    postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms;
    followersCountByPlatformAndDay: Partial<Record<PlatformKey, DailyPlatformInsights>>;
}

type PostsByDate = Record<string, PostWithInsights[]>;

interface PostsByPlatformAndType {
    instagram: {
        reels: PostsByDate;
        posts: PostsByDate;
    };
    facebook: PostsByDate;
}

interface InsightPerAggregation {
    [AggregationTimeScale.BY_DAY]: SplittedEngagementData;
    [AggregationTimeScale.BY_WEEK]: SplittedEngagementData;
    [AggregationTimeScale.BY_MONTH]: SplittedEngagementData;
}

const DEFAULT_SPLITTED_DATA: SplittedEngagementData = {
    facebookData: [],
    instagramData: [],
    total: [],
};
export class EngagementInsightsChartData {
    private _startDate: Date;
    private _endDate: Date;

    comparisonPeriod?: MalouComparisonPeriod;

    partialEngagementData: InsightPerAggregation = {
        [AggregationTimeScale.BY_DAY]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_WEEK]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_MONTH]: DEFAULT_SPLITTED_DATA,
    };

    partialImpressionsData: InsightPerAggregation = {
        [AggregationTimeScale.BY_DAY]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_WEEK]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_MONTH]: DEFAULT_SPLITTED_DATA,
    };
    postCount: InsightPerAggregation = {
        [AggregationTimeScale.BY_DAY]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_WEEK]: DEFAULT_SPLITTED_DATA,
        [AggregationTimeScale.BY_MONTH]: DEFAULT_SPLITTED_DATA,
    };

    dates: {
        [AggregationTimeScale.BY_DAY]: Date[];
        [AggregationTimeScale.BY_WEEK]: Date[];
        [AggregationTimeScale.BY_MONTH]: Date[];
    } = {
        [AggregationTimeScale.BY_DAY]: [],
        [AggregationTimeScale.BY_WEEK]: [],
        [AggregationTimeScale.BY_MONTH]: [],
    };

    postsWithInsights: PostWithInsights[] = [];
    previousPostsWithInsights: PostWithInsights[] = [];

    postsByDay: PostsByPlatformAndType = {
        instagram: {
            reels: {},
            posts: {},
        },
        facebook: {},
    };

    constructor({
        data,
        previousData,
        startDate,
        endDate,
        comparisonPeriod,
    }: {
        data: EngagementChartData;
        previousData: EngagementChartData | null;
        startDate: string;
        endDate: string;
        comparisonPeriod?: MalouComparisonPeriod;
    }) {
        this._startDate = new Date(startDate);
        this._endDate = new Date(endDate);
        this.comparisonPeriod = comparisonPeriod;

        this.postsWithInsights = this._initPostsInsights(data);
        if (previousData) {
            this.previousPostsWithInsights = this._initPostsInsights(previousData);
        }
        this._getPostsByIsoDate();
        this._initInsightsAndDates(AggregationTimeScale.BY_DAY);
        this._initInsightsAndDates(AggregationTimeScale.BY_WEEK);
        this._initInsightsAndDates(AggregationTimeScale.BY_MONTH);
    }

    private _initPostsInsights(data: EngagementChartData): PostWithInsights[] {
        return data.postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform)
                    .map(([key, value]) =>
                        (value.data ?? []).map(
                            (post) =>
                                new PostWithInsights({
                                    ...post,
                                    key,
                                    nbFollowers: this._getFollowerCountByDate(
                                        new Date(post.createdAt),
                                        data.followersCountByPlatformAndDay[key]?.insights
                                    ),
                                })
                        )
                    )
                    .flat()
            )
            .flat();
    }

    private _getFollowerCountByDate(date: Date, followersByDay: DailyPlatformInsights['insights']): number | null {
        const formattedTargetDate = formatDateToISO(
            DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate()
        );
        return formattedTargetDate ? (followersByDay?.[MalouMetric.FOLLOWERS]?.[formattedTargetDate] ?? null) : null;
    }

    private _initInsightsAndDates(aggregationTimeScale: AggregationTimeScale): void {
        switch (aggregationTimeScale) {
            case AggregationTimeScale.BY_MONTH:
                const months = getMonthsFromPeriod(this._startDate, this._endDate);
                this.dates[aggregationTimeScale] = months.map((month) => month.start);
                this._initInsightsAggregatedByMonth(months);
                break;

            case AggregationTimeScale.BY_WEEK:
                const weeks = getWeeksFromPeriod(this._startDate, this._endDate);
                this.dates[aggregationTimeScale] = weeks.map((week) => week.start);
                this._initInsightsAggregatedByWeek(weeks);
                break;

            case AggregationTimeScale.BY_DAY:
            default:
                const days = getDaysFromCurrentRange(this._startDate, this._endDate);
                this.dates[aggregationTimeScale] = days;
                this._initInsightsAggregatedByDay(days);
                break;
        }
    }

    private _initInsightsAggregatedByDay(days: Date[]): void {
        this.partialImpressionsData[AggregationTimeScale.BY_DAY] = this._mapPostsToDailyForChart({
            key: 'impressions',
            days: days,
            aggregationFunction: sum,
        });
        this.partialEngagementData[AggregationTimeScale.BY_DAY] = this._mapPostsToDailyForChart({
            key: 'engagementRate',
            days: days,
            aggregationFunction: mean,
        });
        this.postCount[AggregationTimeScale.BY_DAY] = this._mapPostsToDailyPostsCount(days);
    }

    private _initInsightsAggregatedByWeek(weeks: Week[]): void {
        this._initInsightsAggregatedByPeriod(AggregationTimeScale.BY_WEEK, weeks);
    }

    private _initInsightsAggregatedByMonth(months: Month[]): void {
        this._initInsightsAggregatedByPeriod(AggregationTimeScale.BY_MONTH, months);
    }

    private _initInsightsAggregatedByPeriod(aggregationTimeScale: AggregationTimeScale, periods: { days: Date[] }[]): void {
        this.partialImpressionsData[aggregationTimeScale] = this._mapPostsToWeeklyOrMonthlyForChart({
            key: 'impressions',
            periods,
            aggregationFunction: sum,
        });
        this.partialEngagementData[aggregationTimeScale] = this._mapPostsToWeeklyOrMonthlyForChart({
            key: 'engagementRate',
            periods,
            aggregationFunction: mean,
        });
        this.postCount[aggregationTimeScale] = this._mapPostsToWeeklyOrMonthlyPostsCount(periods);
    }

    private _getPostsByIsoDate(): void {
        const days = getDaysFromCurrentRange(this._startDate, this._endDate);

        const postsByDay: PostsByPlatformAndType = {
            instagram: {
                reels: {},
                posts: {},
            },
            facebook: {},
        };

        for (const day of days) {
            const facebookPosts = this.postsWithInsights.filter(
                (post) => post.key === PlatformKey.FACEBOOK && isSameDay(new Date(post.createdAt), day)
            );

            const instagramPosts = this.postsWithInsights.filter(
                (post) => post.key === PlatformKey.INSTAGRAM && isSameDay(new Date(post.createdAt), day) && post.postType !== PostType.REEL
            );

            const instagramReels = this.postsWithInsights.filter(
                (post) => post.key === PlatformKey.INSTAGRAM && isSameDay(new Date(post.createdAt), day) && post.postType === PostType.REEL
            );

            postsByDay.facebook[formatDateToISO(day)] = facebookPosts;
            postsByDay.instagram.posts[formatDateToISO(day)] = instagramPosts;
            postsByDay.instagram.reels[formatDateToISO(day)] = instagramReels;
        }
        this.postsByDay = postsByDay;
    }

    private _mapPostsToDailyPostsCount(days: Date[]): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        for (const day of days) {
            const filteredFacebookPosts = this.postsByDay.facebook[formatDateToISO(day)];
            chartData.facebookData?.push(filteredFacebookPosts?.length);

            const filteredInstagramPosts = this.postsByDay.instagram.posts[formatDateToISO(day)];
            const filteredInstagramReels = this.postsByDay.instagram.reels[formatDateToISO(day)];
            chartData.instagramData?.push(filteredInstagramPosts?.length + filteredInstagramReels?.length);

            chartData.total?.push(filteredFacebookPosts?.length + filteredInstagramPosts?.length + filteredInstagramReels?.length);
        }
        return chartData;
    }

    private _mapPostsToDailyForChart({
        key = 'impressions',
        days,
        aggregationFunction = sum,
    }: {
        key: keyof Pick<PostWithInsights, 'engagementRate' | 'impressions'>;
        days: Date[];
        aggregationFunction: Function;
    }): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        const reelKey = key === 'impressions' ? 'plays' : 'engagementRate';

        for (const day of days) {
            const facebookPosts = this.postsByDay.facebook[formatDateToISO(day)].map((post) => post[key]) || [];
            const instagramPosts = this.postsByDay.instagram.posts[formatDateToISO(day)].map((post) => post[key]) || [];
            const instagramReels = this.postsByDay.instagram.reels[formatDateToISO(day)].map((post) => post[reelKey]) || [];

            const facebookAggregated = aggregationFunction(facebookPosts);
            chartData.facebookData?.push(isFinite(facebookAggregated) ? facebookAggregated : 0);

            const instagramAggregated = aggregationFunction([...instagramPosts, ...instagramReels]);
            chartData.instagramData?.push(isFinite(instagramAggregated) ? instagramAggregated : 0);

            const total = aggregationFunction([...facebookPosts, ...instagramPosts, ...instagramReels]);
            chartData.total?.push(isFinite(total) ? total : 0);
        }

        return chartData;
    }

    private _mapPostsToWeeklyOrMonthlyForChart({
        key = 'impressions',
        periods,
        aggregationFunction = sum,
    }: {
        key: keyof PostWithInsights;
        periods: { days: Date[] }[];
        aggregationFunction: Function;
    }): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        const reelKey = key === 'impressions' ? 'plays' : 'engagementRate';

        for (const period of periods) {
            const periodDateToISO = period.days.map((day) => formatDateToISO(day));
            const facebookPosts =
                periodDateToISO
                    .map((date) => this.postsByDay.facebook[date])
                    .flat(1)
                    .filter(isNotNil)
                    .map((post) => post[key]) || [];

            const instagramPosts =
                periodDateToISO
                    .map((date) => this.postsByDay.instagram.posts[date])
                    .flat(1)
                    .filter(isNotNil)
                    .map((post) => post[key]) || [];

            const instagramReels =
                periodDateToISO
                    .map((date) => this.postsByDay.instagram.reels[date])
                    .flat(1)
                    .filter(isNotNil)
                    .map((post) => post[reelKey]) || [];

            const facebookAggregated = aggregationFunction(facebookPosts);
            chartData.facebookData?.push(isFinite(facebookAggregated) ? facebookAggregated : 0);

            const instagram = aggregationFunction([...instagramPosts, ...instagramReels]);
            chartData.instagramData?.push(isFinite(instagram) ? instagram : 0);

            const total = aggregationFunction([...facebookPosts, ...instagramPosts, ...instagramReels]);
            chartData.total?.push(isFinite(total) ? total : 0);
        }

        return chartData;
    }

    private _mapPostsToWeeklyOrMonthlyPostsCount(periods: { days: Date[] }[]): SplittedEngagementData {
        const chartData: SplittedEngagementData = {
            facebookData: [],
            instagramData: [],
            total: [],
        };

        for (const period of periods) {
            const periodDateToISO = period.days.map((day) => formatDateToISO(day));

            const filteredFacebookPosts = periodDateToISO
                .map((date) => this.postsByDay.facebook[date])
                .flat(1)
                .filter(isNotNil);
            chartData.facebookData?.push(filteredFacebookPosts?.length);

            const filteredInstagramPosts = periodDateToISO
                .map((date) => this.postsByDay.instagram.posts[date])
                .flat(1)
                .filter(isNotNil);
            const filteredInstagramReels = periodDateToISO
                .map((date) => this.postsByDay.instagram.reels[date])
                .flat(1)
                .filter(isNotNil);

            chartData.instagramData?.push(filteredInstagramPosts?.length + filteredInstagramReels?.length);

            chartData.total?.push(filteredFacebookPosts?.length + filteredInstagramPosts?.length + filteredInstagramReels?.length);
        }
        return chartData;
    }
}
