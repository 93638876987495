<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{ notification().createdAt | formatDate: 'medium' }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>⛔️</div>
        <img
            class="h-[45px] w-[25px] rounded-[3px] object-cover"
            [src]="
                notification().data.post.attachments?.small ??
                notification().data.post.attachments?.igFit ??
                (Illustration.GreyBurger | illustrationPathResolver)
            " />
        @if (notification().data.post.isStory) {
            <div
                class="malou-text-11--semibold max-w-[80%] text-malou-color-text-1"
                [innerHTML]="
                    'notification-center.post-error.story.title' | translate: { restaurantName: restaurant()?.getDisplayName() }
                "></div>
        } @else {
            <div
                class="malou-text-11--semibold max-w-[80%] text-malou-color-text-1"
                [innerHTML]="'notification-center.post-error.title' | translate: { restaurantName: restaurant()?.getDisplayName() }"></div>
        }

        <div class="ml-auto">
            <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.retry' | translate }}</button>
        </div>
    </div>
</div>
