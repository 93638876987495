import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, Observable, shareReplay } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PlatformKey, Role } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { UserRestaurant } from ':modules/user/user';
import { NoResultsComponent } from ':shared/components/no-results/no-results.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { Restaurant } from ':shared/models';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

interface AppState {
    user: {
        infos: {
            role: string;
            restaurants: UserRestaurant[];
        };
    };
}

export interface RestaurantsSelectionData {
    skipOwnRestaurant: boolean;
    withoutBrandBusiness: boolean;
    selectedRestaurants?: Restaurant[];
    hasPlatform?: PlatformKey[];
    disableRestaurantWithoutMapstrPremium?: boolean;
}

const DEFAULT_RESTAURANT_PAGINATION = 5;
const DEFAULT_ORGANIZATION_PAGINATION = 10;
const DEFAULT_ORGANIZATION_NAME = 'Other';

@Component({
    selector: 'app-restaurants-selection',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        PaginatorComponent,
        SearchComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        LazyLoadImageModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        ImagePathResolverPipe,
        NoResultsComponent,
        IllustrationPathResolverPipe,
    ],
    templateUrl: './restaurants-selection.component.html',
    styleUrls: ['./restaurants-selection.component.scss'],
})
export class RestaurantsSelectionComponent extends BaseStepComponent<RestaurantsSelectionData, unknown> implements OnInit {
    DEFAULT_RESTAURANT_PAGINATION = DEFAULT_RESTAURANT_PAGINATION;
    DEFAULT_ORGANIZATION_PAGINATION = DEFAULT_ORGANIZATION_PAGINATION;

    readonly Illustration = Illustration;

    readonly _experimentationService = inject(ExperimentationService);

    displayedColumns: string[] = ['restaurant'];
    restaurantDataSource: MatTableDataSource<Restaurant> = new MatTableDataSource<Restaurant>([]);
    restaurantSelection: SelectionModel<Restaurant> = new SelectionModel<Restaurant>(true, []);
    organizationDataSource: MatTableDataSource<string> = new MatTableDataSource<string>([]);
    paginatedOrganizations: string[] = [];
    organizationSelection = new SelectionModel<string>(true, []);

    restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);
    restaurantsGroupedByOrganization: Record<string, Restaurant[]> = {};
    isAdmin$ = new BehaviorSubject(false);
    hasOrganizations$ = new BehaviorSubject(false);
    displayOrganizations = false;
    restaurantsIdsWithMapstrPremium$: Observable<string[]>;

    readonly isNewDuplicationFeatureEnabled = this._experimentationService.isFeatureEnabled('big-duplication-modal-post-preview');

    constructor(
        private readonly _store: Store<AppState>,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _platformsService: PlatformsService
    ) {
        super();
    }

    @ViewChild('RestaurantPaginator') set restaurantPaginator(paginatorComponent: PaginatorComponent) {
        this.restaurantDataSource.paginator = paginatorComponent.matPaginator;
    }

    @ViewChild('OrganizationPaginator') set organizationPaginator(paginatorComponent: PaginatorComponent) {
        this.organizationDataSource.paginator = paginatorComponent.matPaginator;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.inputData = Object.assign({
            withoutBrandBusiness: this.inputData?.withoutBrandBusiness ?? false,
            skipOwnRestaurant: this.inputData?.skipOwnRestaurant ?? false,
            selectedRestaurants: this.inputData?.selectedRestaurants ?? [],
            hasPlatform: this.inputData?.hasPlatform ?? [],
            disableRestaurantWithoutMapstrPremium: this.inputData?.disableRestaurantWithoutMapstrPremium ?? false,
        });

        this.restaurantsIdsWithMapstrPremium$ = this.restaurants$.pipe(
            switchMap((restaurants: Restaurant[]) => {
                const restaurantIds = restaurants.map((r) => r._id);
                const mapstrPlatforms$ = this._platformsService
                    .getPlatformsForMultipleRestaurants({ restaurantIds })
                    .pipe(map((result) => result.data.filter((e) => e.key === PlatformKey.MAPSTR)));
                const restaurantsWithMapstrPremium$ = mapstrPlatforms$.pipe(
                    map((platforms) =>
                        restaurants.filter((restaurant) => {
                            const mapstrPlatform = platforms.find(
                                (platform) => platform.restaurantId === restaurant._id && platform.key === PlatformKey.MAPSTR
                            );
                            return mapstrPlatform?.credentials && mapstrPlatform.credentials.length > 0;
                        })
                    )
                );
                return restaurantsWithMapstrPremium$;
            }),
            map((restaurants) => restaurants.map((r) => r._id)),
            shareReplay(1)
        );

        combineLatest([this.restaurants$, this._restaurantsService.restaurantSelected$]).subscribe(([restaurants, selectedRestaurant]) => {
            const filteredRestaurants = this._getFilteredRestaurants(restaurants, this.inputData, selectedRestaurant?._id);
            this.restaurantDataSource.data = filteredRestaurants.sort((a, b) => a.name.localeCompare(b.name));
            this._updateOrganizationDataSource(filteredRestaurants);

            const restaurantIds = this.restaurantDataSource.data.map((restaurant) => restaurant._id);
            const selectedRestaurants =
                this.inputData?.selectedRestaurants?.filter((restaurant) => restaurantIds.includes(restaurant._id)) ?? [];
            this.inputData.selectedRestaurants = selectedRestaurants;
            this.restaurantSelection = new SelectionModel<Restaurant>(true, selectedRestaurants);
            this.valid.emit(this._isValid());
        });

        this._store.select(selectUserInfos).subscribe((user) => {
            this.isAdmin$.next(user?.role === Role.ADMIN);
        });

        combineLatest([this.isAdmin$, this.hasOrganizations$]).subscribe(([isAdmin, hasOrganizations]) => {
            this.displayOrganizations = isAdmin && hasOrganizations;
        });

        this.organizationDataSource.filterPredicate = (organizationName: string, filter: string): boolean =>
            organizationName.toLowerCase().includes(filter) || organizationName.includes(filter);
        this.organizationDataSource.connect().subscribe((data) => {
            this.paginatedOrganizations = data.slice(0, DEFAULT_ORGANIZATION_PAGINATION);
            this.displayOrganizations = this.paginatedOrganizations.length !== 0;
            this.hasOrganizations$.next(data.length > 0);
        });
    }

    onSearchChange(search: string): void {
        this.restaurantDataSource.filter = search.trim().toLowerCase();
        this.organizationDataSource.filter = search.trim();
    }

    toDataSource(data: Restaurant[]): MatTableDataSource<Restaurant> {
        return new MatTableDataSource<Restaurant>(data);
    }

    toggleAllRows(): void {
        if (this.isAllRestaurantsSelected()) {
            this.restaurantSelection.clear();
            this.organizationSelection.clear();
            return;
        }

        this.restaurantSelection.select(...this.restaurantDataSource.data);
        this.organizationSelection.select(...Object.keys(this.restaurantsGroupedByOrganization));
        this.valid.emit(this._isValid());
    }

    isRestaurantSelected = (restaurant: Restaurant): boolean => this.restaurantSelection.isSelected(restaurant);

    hasRestaurantMapstrPremium(restaurant: Restaurant, restaurantsIdsWithMapstrPremium: string[] | null): boolean {
        return restaurantsIdsWithMapstrPremium?.includes(restaurant._id) ?? false;
    }

    selectRestaurant(restaurant: Restaurant): void {
        const organizationName = this._getOrganizationName(restaurant);
        if (this.isRestaurantSelected(restaurant)) {
            this.restaurantSelection.deselect(restaurant);
            this.organizationSelection.deselect(organizationName);
        } else {
            this.restaurantSelection.select(restaurant);
            if (this.isAllRestaurantsSelectedInOrganization(organizationName)) {
                this.organizationSelection.select(organizationName);
            }
        }
        this.valid.emit(this._isValid());
    }

    isAllRestaurantsSelected(): boolean {
        return this.restaurantSelection.selected.length === this.restaurantDataSource.data.length;
    }

    isOneOrMoreRestaurantSelected(): boolean {
        return this.restaurantSelection.selected.length > 0;
    }

    isOrganisationSelected = (organizationName: string): boolean => this.organizationSelection.isSelected(organizationName);

    selectOrganisation(organizationName: string): void {
        if (this.isOrganisationSelected(organizationName)) {
            this.organizationSelection.deselect(organizationName);
            this.restaurantSelection.deselect(...this.restaurantsGroupedByOrganization[organizationName]);
        } else {
            this.organizationSelection.select(organizationName);
            this.restaurantSelection.select(...this.restaurantsGroupedByOrganization[organizationName]);
        }
        this.valid.emit(this._isValid());
    }

    isOneOrMoreRestaurantSelectedInOrganization = (organizationName: string): boolean =>
        this.restaurantsGroupedByOrganization[organizationName].some((restaurant) => this.restaurantSelection.isSelected(restaurant));

    isAllRestaurantsSelectedInOrganization = (organizationName: string): boolean =>
        this.restaurantsGroupedByOrganization[organizationName].every((restaurant) => this.restaurantSelection.isSelected(restaurant));

    protected _submitData(): RestaurantsSelectionData {
        return {
            skipOwnRestaurant: this.inputData.skipOwnRestaurant,
            withoutBrandBusiness: this.inputData.withoutBrandBusiness,
            selectedRestaurants: this.restaurantSelection.selected,
        };
    }

    protected _isValid(): boolean {
        return this.restaurantSelection.selected.length > 0;
    }

    private _updateOrganizationDataSource(restaurants: Restaurant[]): void {
        const grouped = restaurants.reduce((acc, restaurant) => {
            const organizationName = this._getOrganizationName(restaurant);
            if (!acc[organizationName]) {
                acc[organizationName] = [];
            }
            acc[organizationName].push(restaurant);
            return acc;
        }, {});
        this.restaurantsGroupedByOrganization = grouped;
        this.organizationDataSource.data = Object.keys(grouped).sort((a, b) => a.localeCompare(b));
    }

    private _getOrganizationName(restaurant: Restaurant): string {
        return restaurant.organization?.name ?? DEFAULT_ORGANIZATION_NAME;
    }

    private _getFilteredRestaurants(restaurants: Restaurant[], inputData: RestaurantsSelectionData, restaurantId?: string): Restaurant[] {
        if (!inputData?.skipOwnRestaurant && !inputData?.withoutBrandBusiness && !inputData?.hasPlatform?.length) {
            return restaurants;
        }
        return restaurants.filter((restaurant) => {
            const withoutBrandBusiness = inputData?.withoutBrandBusiness ? !restaurant.isBrandBusiness() : true;
            const skipOwnRestaurant = inputData?.skipOwnRestaurant ? restaurant._id !== restaurantId : true;
            const hasPlatform = inputData?.hasPlatform?.length
                ? inputData.hasPlatform.some((platform) => restaurant.platformKeys.includes(platform))
                : true;
            return withoutBrandBusiness && skipOwnRestaurant && hasPlatform;
        });
    }
}
