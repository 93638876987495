import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ApplicationLanguage, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import {
    ACCOUNT_MANAGED_SHARED_DATA_TYPE,
    AccountManagedConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import { UpdatePlatformAccessService } from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-step-3/update-platform-access.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-account-managed-connection-step-3',
    templateUrl: './account-managed-connection-step-3.component.html',
    styleUrls: ['./account-managed-connection-step-3.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent, ImagePathResolverPipe, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountManagedConnectionStep3Component extends BaseStepComponent<
    ACCOUNT_MANAGED_SHARED_DATA_TYPE,
    AccountManagedConnectionModalResult
> {
    private readonly _toastService = inject(ToastService);
    private readonly _updatePlatformAccessService = inject(UpdatePlatformAccessService);
    private readonly _translateService = inject(TranslateService);

    readonly ButtonStyle = ButtonStyle;
    readonly imagesClasses: Record<ACCOUNT_MANAGED_SHARED_DATA_TYPE['platformKey'], string> = {
        [PlatformKey.FOURSQUARE]: 'h-auto w-full',
        [PlatformKey.RESY]: 'h-auto w-full',
        [PlatformKey.TRIPADVISOR]: 'h-[200px] w-auto',
        [PlatformKey.YELP]: 'h-auto w-full',
    };

    readonly platformKey = computed(() => this.sharedData().platformKey);
    readonly currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    readonly imageLang = computed(() =>
        this.currentLang() === ApplicationLanguage.FR.toString() ? ApplicationLanguage.FR : ApplicationLanguage.EN
    );
    readonly translations = computed(() => {
        const base = 'platforms.connection_new.account_managed';
        const platformKey = this.platformKey();
        return {
            title: `${base}.${platformKey}.step_3.step_name`,
            description1: `${base}.${platformKey}.step_3.description_1`,
            description2: `${base}.${platformKey}.step_3.description_2`,
            description3: `${base}.${platformKey}.step_3.description_3`,
            primaryButton: `${base}.${platformKey}.step_3.primary_button`,
            connected: `${base}.${platformKey}.step_3.connected`,
            updateFailed: `${base}.${platformKey}.step_3.update_failed`,
        };
    });

    readonly imageName = computed(() => {
        const platformKey = this.platformKey();
        return `${platformKey}-add-manager`;
    });

    readonly isUpdatingPlatform = signal<boolean>(false);

    onPrimaryClick(): void {
        this.isUpdatingPlatform.set(true);
        this._updatePlatformAccessService.execute(this.platformKey()).subscribe({
            next: () => {
                this.isUpdatingPlatform.set(false);
                this._toastService.openSuccessToast(this._translateService.instant(this.translations().connected));
                this.close.emit({ hasDataChanged: true });
            },
            error: (err) => {
                console.error(err);
                this.isUpdatingPlatform.set(false);
                this._toastService.openErrorToast(this._translateService.instant(this.translations().updateFailed));
            },
        });
    }
}
