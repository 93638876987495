import { ChangeDetectionStrategy, Component, computed, inject, input, NgZone, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import {
    BubbleDataPoint,
    Chart,
    ChartDataset,
    ChartEvent,
    ChartOptions,
    ChartType,
    ChartTypeRegistry,
    Plugin,
    Point,
    Tick,
    TooltipItem,
} from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { ReviewAnalysisChartDataSentiment, ReviewAnalysisChartDataTag } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { SegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/tags-bar-chart/segment-analysis-modal/segment-analysis-modal.component';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorGreen, malouChartColorRed, malouChartColorText2 } from ':shared/helpers';
import { SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_DISPLAYED_TAGS: ReviewAnalysisChartDataTag[] = [
    ReviewAnalysisChartDataTag.FOOD,
    ReviewAnalysisChartDataTag.SERVICE,
    ReviewAnalysisChartDataTag.ATMOSPHERE,
    ReviewAnalysisChartDataTag.PRICE,
    ReviewAnalysisChartDataTag.EXPEDITIOUSNESS,
    ReviewAnalysisChartDataTag.HYGIENE,
];

type BarChartType = Extract<ChartType, 'bar'>;

@Component({
    selector: 'app-tags-bar-chart',
    templateUrl: './tags-bar-chart.component.html',
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsBarChartComponent implements OnInit {
    chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();
    reviewAnalysesFilter = input.required<ReviewAnalysesChartFilter | null>();
    shouldDisplayAnalysesTagClickableLabels = input.required<boolean>();
    isFromAggregatedStatistics = input.required<boolean>();

    private readonly _experimentationService = inject(ExperimentationService);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly LABEL_CLICK_PLUGIN: Plugin = this._getLabelClickPlugin();

    readonly displayedTags: Signal<ReviewAnalysisChartDataTag[]> = computed(() => {
        const chartData = this.chartData();
        return DEFAULT_DISPLAYED_TAGS.filter((tag) => chartData.hasData(tag));
    });
    readonly chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.displayedTags(), this.chartData())
    );
    readonly chartLabels: Signal<string[][]> = computed(() => this._computeChartLabels(this.displayedTags(), this.chartData()));

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    chartOption: ChartOptions<BarChartType>;

    constructor(
        private readonly _zone: NgZone,
        private readonly _translateService: TranslateService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    private _computeChartLabels(displayedTags: ReviewAnalysisChartDataTag[], chartData: ReviewAnalysesChartDataByRestaurantId): string[][] {
        return this.shouldDisplayAnalysesTagClickableLabels()
            ? displayedTags.map((tag) => [
                  this._computeChartTagLabel(tag, chartData),
                  this._translateService.instant('statistics.e_reputation.reviews_analysis.see_detail'),
              ])
            : displayedTags.map((tag) => [this._computeChartTagLabel(tag, chartData)]);
    }

    private _computeChartTagLabel(tag: ReviewAnalysisChartDataTag, chartData: ReviewAnalysesChartDataByRestaurantId): string {
        const translatedTag = this._translateService.instant(`review_analysis_tags.${tag}`);
        const tagCount = chartData.getCount(tag, ReviewAnalysisChartDataSentiment.TOTAL);
        return `${translatedTag}  (${tagCount})`;
    }

    private _computeChartData(
        displayedTags: ReviewAnalysisChartDataTag[],
        chartData: ReviewAnalysesChartDataByRestaurantId
    ): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: displayedTags.map((tag) => chartData.getCount(tag, ReviewAnalysisChartDataSentiment.POSITIVE)),
            },
            {
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: displayedTags.map((tag) => chartData.getCount(tag, ReviewAnalysisChartDataSentiment.NEGATIVE)),
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: this.isNewSemanticAnalysisFeatureEnabled() ? 'index' : undefined,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItems: TooltipItem<any>) => this._computeTooltipLabel(tooltipItems),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        crossAlign: 'center',
                        color: this.shouldDisplayAnalysesTagClickableLabels() ? malouChartColorBluePurple : malouChartColorText2,
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItems: TooltipItem<any>): string {
        const value = parseInt(tooltipItems.formattedValue, 10);
        if (value <= 1) {
            const singularSentiment =
                tooltipItems.datasetIndex === 0
                    ? this._translateService.instant('statistics.e_reputation.reviews_analysis.positive_singular')
                    : this._translateService.instant('statistics.e_reputation.reviews_analysis.negative_singular');
            return (
                SMALL_TOOLTIP_TAB +
                this._translateService.instant('statistics.e_reputation.reviews_analysis.feeling_singular', {
                    sentimentsNum: tooltipItems.formattedValue,
                    sentiment: singularSentiment,
                })
            );
        }
        const sentiment =
            tooltipItems.datasetIndex === 0
                ? this._translateService.instant('statistics.e_reputation.reviews_analysis.positive')
                : this._translateService.instant('statistics.e_reputation.reviews_analysis.negative');
        return (
            SMALL_TOOLTIP_TAB +
            this._translateService.instant('statistics.e_reputation.reviews_analysis.feeling', {
                sentimentsNum: tooltipItems.formattedValue,
                sentiment,
            })
        );
    }

    private _openSegmentAnalysisModal(tag: ReviewAnalysisChartDataTag): void {
        if (!this.reviewAnalysesFilter() || !this.shouldDisplayAnalysesTagClickableLabels()) {
            return;
        }
        this._customDialogService.open(SegmentAnalysisModalComponent, {
            width: '80vw',
            height: '80vh',
            disableClose: true,
            data: {
                tag,
                reviewAnalysesFilter: this.reviewAnalysesFilter(),
                isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
            },
        });
    }

    private _getLabelClickPlugin(): Plugin {
        return {
            id: 'labelClick',
            afterEvent: (chart: Chart, args: { event: ChartEvent }): void => {
                const { event } = args;
                const detailsBtnOffset = 27;
                const datasets = chart.data.datasets;
                if (event.type !== 'click') {
                    return;
                }
                const { x, y } = args.event;

                if (
                    !x ||
                    x < chart.scales.xAxis.left ||
                    x > chart.scales.xAxis.right ||
                    !y ||
                    y < chart.scales.xAxis.top - detailsBtnOffset
                ) {
                    return;
                }

                const index = chart.scales.xAxis.getValueForPixel(x);

                if (index !== undefined && !this._isDataPointEqualToZero(datasets, index)) {
                    const clickedTag = this.displayedTags()[index];
                    this._zone.run(() => this._openSegmentAnalysisModal(clickedTag));
                }
            },
        };
    }

    private _isDataPointEqualToZero(
        datasets: ChartDataset<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[]>[],
        index: number
    ): boolean {
        if (index < 0) {
            return false;
        }
        return datasets.reduce((acc, next) => (next.data[index] as number) + acc, 0) === 0;
    }
}
