import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import {
    EditAiReviewSettingsModalComponent,
    EditRestaurantAiSettingsModalInputData,
} from ':modules/ai-settings/ai-review-settings/edit-ai-review-settings-modal/edit-ai-review-settings-modal.component';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Keyword } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-ai-review-settings',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SkeletonComponent,
        EnumTranslatePipe,
        FlagPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './ai-review-settings.component.html',
    styleUrl: './ai-review-settings.component.scss',
})
export class AiReviewSettingsComponent implements OnInit {
    readonly aiSettingsContext = inject(AiSettingsContext);

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _keywordsService = inject(KeywordsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    readonly selectedRestaurantKeywords = signal<Keyword[]>([]);

    readonly showDetails = signal(false);
    readonly isLoading = signal(false);

    readonly DEFAULT_DISPLAY = '-';

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;

    ngOnInit(): void {
        this.restaurant$
            .pipe(
                filter(isNotNil),
                switchMap((rest) =>
                    this._keywordsService
                        .getKeywordsByRestaurantId(rest._id)
                        .pipe(map((result) => result.data.filter((keyword) => keyword.selected)))
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((keywords) => {
                this.selectedRestaurantKeywords.set(keywords);
            });
    }

    toggleShowDetails(): void {
        this.showDetails.update((currentShowDetails) => !currentShowDetails);
    }

    openRestaurantAiSettingsModal(): void {
        const restaurantAiSettings = this.aiSettingsContext.restaurantAiSettings();
        if (!restaurantAiSettings) {
            return;
        }

        const data: EditRestaurantAiSettingsModalInputData = {
            restaurantAiSettings,
            selectedKeywords: this.selectedRestaurantKeywords(),
        };

        this._customDialogService
            .open<EditAiReviewSettingsModalComponent, EditRestaurantAiSettingsModalInputData>(EditAiReviewSettingsModalComponent, {
                height: 'unset',
                maxHeight: '90vh',
                width: '950px',
                maxWidth: '80vw',
                data,
            })
            .afterClosed();
    }
}
