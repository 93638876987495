import { ChartDataArray } from ':shared/helpers';

export interface SplittedEngagementData {
    facebookData: ChartDataArray;
    instagramData: ChartDataArray;
    total: ChartDataArray;
}

export enum EngagementDataType {
    ENGAGEMENT = 'engagement',
    IMPRESSIONS = 'impressions',
}

export interface EngagementData {
    [EngagementDataType.ENGAGEMENT]: SplittedEngagementData;
    [EngagementDataType.IMPRESSIONS]: SplittedEngagementData;
    postsCount: SplittedEngagementData;
}
