import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, input, OnInit, output, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { intersection, isNumber, xor } from 'lodash';
import { combineLatest, map, of, startWith } from 'rxjs';

import { AVAILABLE_REVIEW_TEMPLATE_VARIABLES, TemplateReplacer } from ':modules/templates/template-replacer/template-replacer';
import { RatingsFiltersComponent } from ':shared/components/ratings-filters/ratings-filters.component';
import { SelectTemplatesComponent } from ':shared/components/select-templates/select-templates.component';
import { CommentOptionValue } from ':shared/enums/with-comment.enum';
import { Sort } from ':shared/helpers';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord, INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { CommentReviewsFilters, PrivateReview, Review, Template } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import {
    DEFAULT_AVAILABLE_COMMENTS,
    DEFAULT_AVAILABLE_RATINGS,
    DEFAULT_REVIEW_FILTERS,
    ReviewFiltersForm,
    TemplateFilters,
} from '../../answer-review.interface';

@Component({
    selector: 'app-select-review-reply-template',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        RatingsFiltersComponent,
        SelectTemplatesComponent,
    ],
    templateUrl: './select-review-reply-template.component.html',
    styleUrls: ['./select-review-reply-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectReviewReplyTemplateComponent implements OnInit {
    readonly review = input.required<Review | PrivateReview | undefined>();
    readonly templates = input.required<Template[]>();
    readonly disabled = input<boolean>(false);
    readonly templateChange = output<Template | null>();

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _translateService = inject(TranslateService);

    readonly templatesAutocompleteOptions = signal<Template[]>([]);
    readonly shouldDisplayTemplates = signal(false);
    readonly filterCount = signal(0);
    readonly templates$ = toObservable(this.templates);

    readonly AVAILABLE_RATINGS: number[] = DEFAULT_AVAILABLE_RATINGS;
    readonly AVAILABLE_COMMENTS_FILTERS: CommentReviewsFilters[] = DEFAULT_AVAILABLE_COMMENTS;
    readonly templateReplacer = new TemplateReplacer(this._translateService, AVAILABLE_REVIEW_TEMPLATE_VARIABLES);

    readonly SvgIcon = SvgIcon;

    ratings: Signal<number[]>;

    readonly templateForm = this._initTemplateForm();
    private readonly _filtersForm = this._initFiltersForm();

    constructor() {
        this._initRatings();

        effect(() => {
            const review = this.review();
            this.templateForm.reset('');
            if (!review) {
                this._filtersForm.reset(DEFAULT_REVIEW_FILTERS);
                return;
            }
            this._setTemplateFilters(review);
        });
    }

    ngOnInit(): void {
        this._initFilterTemplates();
        this.filterCount.set(this._computeFilterCount());
    }

    toggleDisplayTemplates(): void {
        if (this.disabled()) {
            return;
        }
        this.shouldDisplayTemplates.update((shouldDisplayTemplates) => !shouldDisplayTemplates);
    }

    onTemplateChange(template: Template | null): void {
        this.templateChange.emit(template);
    }

    isCommentFilterChecked(commentFilter: CommentReviewsFilters): boolean {
        return this._filtersForm.value.withCommentFilter?.includes(commentFilter) || false;
    }

    toggleCommentFilter(commentFilter: CommentReviewsFilters): void {
        const withCommentFilter = xor(this._filtersForm.value.withCommentFilter, [commentFilter]);
        this._filtersForm.patchValue({
            withCommentFilter,
        });
        this.filterCount.set(this._computeFilterCount());
    }

    toggleRating(rating: number): void {
        const ratingFilter = xor(this._filtersForm.value.ratingFilter, [rating]);
        this._filtersForm.patchValue({
            ratingFilter,
        });
        this.filterCount.set(this._computeFilterCount());
    }

    private _computeFilterCount(): number {
        const defaultWithComment = this.review()?.getCommentOption() ?? null;
        const defaultRatings = Review.getRatingRange(this.review()?.rating) ?? [];
        const isDefaultRatings =
            this._filtersForm.value.ratingFilter?.every((rating) => defaultRatings.includes(rating)) &&
            this._filtersForm.value.ratingFilter?.length === defaultRatings.length;
        const isDefaultCommentOption =
            (this._filtersForm.value.withCommentFilter?.length === 1 &&
                defaultWithComment === this._filtersForm.value.withCommentFilter[0]) ||
            (this._filtersForm.value.withCommentFilter?.every((withCommentOption) => defaultWithComment?.includes(withCommentOption)) &&
                defaultWithComment?.length === this._filtersForm.value.withCommentFilter?.length);

        return [!isDefaultRatings, !isDefaultCommentOption].filter(Boolean).length;
    }

    private _initFiltersForm(): INullableFormGroup<ReviewFiltersForm> {
        const reviewFiltersForm: INullableFormControlRecord<ReviewFiltersForm> =
            getFormControlRecordFromDefaultValue(DEFAULT_REVIEW_FILTERS);
        return this._formBuilder.group(reviewFiltersForm);
    }

    private _initTemplateForm(): FormControl<string | null> {
        return new FormControl('');
    }

    private _initFilterTemplates(): void {
        combineLatest([
            this.templates$,
            this._filtersForm.valueChanges.pipe(startWith(this._filtersForm.value)),
            this.templateForm.valueChanges.pipe(startWith(this.templateForm.value)),
        ])
            .pipe(
                map(([templates, filterValue, searchText]) => {
                    const templateFilters: TemplateFilters = {
                        withCommentFilter: filterValue.withCommentFilter ?? [],
                        ratingFilter: filterValue.ratingFilter ?? [],
                    };
                    const validatedTemplates = templates.filter((template) => template.status !== 'pending');
                    const filteredTemplates = this._getFilteredTemplates(validatedTemplates, templateFilters, searchText ?? '');
                    if (filteredTemplates.length > 0) {
                        return this._getSortedTemplates(filteredTemplates);
                    }
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((templates) => {
                this.templatesAutocompleteOptions.set(templates ?? []);
            });
    }

    private _getFilteredTemplates(
        templates: Template[],
        { withCommentFilter, ratingFilter }: TemplateFilters,
        searchText: string
    ): Template[] {
        return templates.filter((template) => {
            const commentFilterValue = this._getCommentReviewsFilters(template.withComment);
            const isWithComment = !withCommentFilter.length || intersection(withCommentFilter, commentFilterValue).length > 0;
            const isRating = !ratingFilter.length || isNumber(template.rating?.find((rating) => ratingFilter.includes(rating)));
            const isSearchText = !searchText.length || (template.name || '').toLocaleLowerCase().includes(searchText.toLocaleLowerCase());

            return isWithComment && isRating && isSearchText;
        });
    }

    private _getSortedTemplates(templates: Template[]): Template[] {
        return templates.sort(Sort.sortByDate('createdAt'));
    }

    private _getCommentReviewsFilters(commentOption: CommentOptionValue): CommentReviewsFilters[] {
        const commentOptionMapper = {
            [CommentOptionValue.WITH_OR_WITHOUT]: [CommentReviewsFilters.WITH_TEXT, CommentReviewsFilters.WITHOUT_TEXT],
            [CommentOptionValue.WITH]: [CommentReviewsFilters.WITH_TEXT],
            [CommentOptionValue.WITHOUT]: [CommentReviewsFilters.WITHOUT_TEXT],
        };
        return commentOptionMapper[commentOption];
    }

    private _initRatings(): void {
        const ratings$ =
            this._filtersForm.get('ratingFilter')?.valueChanges.pipe(
                startWith(this._filtersForm.get('ratingFilter')?.value ?? this.AVAILABLE_RATINGS),
                map((ratings) => ratings ?? []),
                takeUntilDestroyed()
            ) ?? of(this.AVAILABLE_RATINGS);

        this.ratings = toSignal(ratings$, { initialValue: this._filtersForm.get('ratingFilter')?.value ?? this.AVAILABLE_RATINGS });
    }

    private _setTemplateFilters(review: Review | PrivateReview): void {
        const withComment = review.getCommentOption();
        const ratings = Review.getRatingRange(review?.rating);
        this._filtersForm.setValue({
            withCommentFilter: withComment ? [withComment] : [],
            ratingFilter: ratings,
        });
    }
}
