<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{ notification().createdAt | formatDate: 'medium' }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div class="flex items-center justify-center rounded-[5px] bg-malou-color-state-error--light bg-opacity-20 p-1">
            <mat-icon class="!h-4 !w-4 !text-malou-color-state-error" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </div>
        <div class="flex grow flex-col">
            <div
                class="malou-text-11--semibold text-malou-color-text-1"
                [innerHTML]="
                    'notification-center.information-update-error.title'
                        | translate
                            : {
                                  restaurantName:
                                      restaurant()?.internalName ?? notification().data.mainRestaurantName ?? restaurant()?.name,
                              }
                "></div>
        </div>

        <div class="ml-auto">
            <button class="malou-btn-flat btn-sm !px-0" mat-button>{{ 'common.see' | translate }}</button>
        </div>
    </div>
</div>
