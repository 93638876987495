import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { capitalize, isNumber, sum, sumBy } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ChartDataArray, malouChartColorBluePurple, malouChartColorPurple } from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

type BarChartType = Extract<ChartType, 'bar'>;

export interface CountByChipName {
    chipName: string;
    total: number;
    countPerPlatform: Partial<Record<PlatformKey, number>>;
}
export type ReviewCountDataTotemsDetails = CountByChipName[];

export type ReviewCountData = {
    star: string;
    starValue: number | undefined;
    total: number;
    count: CountByChipName[];
}[];

export type ReviewsCountData = { name: string; data: ReviewCountData }[];

@Component({
    selector: 'app-statistics-totems-estimated-review-count-chart',
    templateUrl: './totems-estimated-review-count-chart.component.html',
    styleUrls: ['./totems-estimated-review-count-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotemsEstimatedReviewCountChartComponent {
    reviewsCountData = input.required<ReviewsCountData>();
    hiddenDatasetIndexes = input<number[]>([]);
    hiddenDatasetIndexesChange = output<number[]>();

    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly STAR_CLICKED_BY_TRANSLATION = {
        [this._translateService.instant('statistics.totems.unknown_star')]: -1,
        [this._translateService.instant('statistics.totems.one_star')]: 1,
        [this._translateService.instant('statistics.totems.two_stars')]: 2,
        [this._translateService.instant('statistics.totems.three_stars')]: 3,
        [this._translateService.instant('statistics.totems.four_stars')]: 4,
        [this._translateService.instant('statistics.totems.five_stars')]: 5,
    };

    readonly chartDataSets: Signal<(ChartDataset<BarChartType, ChartDataArray> & { metadata: ReviewCountDataTotemsDetails[] })[]> =
        computed(() => {
            let dataset = this._computeChartData(this.reviewsCountData(), this.chartLabels());
            if (this.hiddenDatasetIndexes.length) {
                dataset = dataset.filter((_, index) => !this.hiddenDatasetIndexes().includes(index));
            }
            return dataset;
        });
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());
    readonly chartLabels: Signal<string[]> = computed(() =>
        this._computeChartLabels(this.STAR_CLICKED_BY_TRANSLATION, this.reviewsCountData())
    );

    private _hiddenDatasetIndexes: number[] = [];

    private _computeChartLabels(labels: Record<string, number | undefined>, reviewCountData: ReviewsCountData): string[] {
        let filteredLabels = Object.keys(labels);
        Object.entries(labels).forEach(([starName, starValue]) => {
            const totalForAllDatasets = sum(
                reviewCountData?.map(
                    (reviewType) => reviewType.data?.find(({ starValue: dataStarValue }) => starValue === dataStarValue)?.total ?? 0
                )
            );
            if (!totalForAllDatasets) {
                filteredLabels = filteredLabels.filter((label) => label !== starName);
            }
        });
        return filteredLabels;
    }

    private _computeChartData(
        reviewCountData: ReviewsCountData,
        labels: string[]
    ): (ChartDataset<BarChartType, ChartDataArray> & { metadata: ReviewCountDataTotemsDetails[]; star: number })[] {
        return reviewCountData
            ?.filter(({ data }) => sumBy(data, 'total') > 0)
            ?.map(({ name, data }) => ({
                label: name,
                borderColor: this._getChartColor(name),
                backgroundColor: this._getChartColor(name),
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: data?.filter(({ star }) => labels.includes(star))?.map(({ total }) => total ?? null),
                metadata: data?.filter(({ star }) => labels.includes(star))?.map(({ count }) => count),
                star: data?.find(({ star }) => labels.includes(star))?.starValue ?? -1,
            }));
    }

    private _getChartColor(name: string): string {
        return name === this._translateService.instant('statistics.totems.wheel_of_fortune')
            ? malouChartColorPurple
            : malouChartColorBluePurple;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<any>): boolean => tooltipItem.formattedValue !== '0',
                    itemSort: (a: TooltipItem<any>, b: TooltipItem<any>): number => b.datasetIndex - a.datasetIndex,
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        afterTitle: (tooltipItem: TooltipItem<any>[]) => this._computeTooltipAfterTitle(tooltipItem),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                    xAlign: 'right',
                    yAlign: 'center',
                    boxWidth: 0,
                    enabled: false,
                    external: (context) => {
                        this._renderCustomTooltip(context);
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, index: number, _ticks: Tick[]): string => this.chartLabels()[index],
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    offset: false,
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(items: TooltipItem<any>[]): string {
        return items[0].label ?? '';
    }

    private _computeTooltipAfterTitle(item: TooltipItem<any>[]): string[] {
        if (item[0].dataset.star === -1) {
            const description = this._translateService.instant('statistics.totems.unknown_star_description');
            const middle = description.lastIndexOf(' ', Math.floor(description.length / 2));
            return [`(${description.slice(0, middle)}`, `${description.slice(middle + 1)})`];
        }
        return [];
    }

    private _computeTooltipLabel(item: TooltipItem<any>): string[] {
        const details = item.dataset.metadata?.[item.dataIndex];
        if (!details || !Object.keys(details).length) {
            return [];
        }
        const tooltipContent: string[] = [`${item.dataset.label ?? ''} (${item.raw})`];
        for (const detail of details) {
            tooltipContent.push(`${SMALL_TOOLTIP_TAB}${capitalize(detail.chipName)} (${detail.total})`);
            Object.entries(detail.countPerPlatform).forEach(([key, value]) =>
                tooltipContent.push(`${LARGE_TOOLTIP_TAB}${this._enumTranslatePipe.transform(key, 'platform_key')} : ${value}`)
            );
        }
        return tooltipContent;
    }

    private _renderCustomTooltip(context): void {
        const { chart, tooltip } = context;
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create tooltip if it doesn't exist
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.top = '100%';
            tooltipEl.style.transform = 'translate(0, 20%)';
            tooltipEl.style.background = '#333';
            tooltipEl.style.color = 'white';
            tooltipEl.style.borderRadius = '8px';
            tooltipEl.style.padding = '8px 12px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.fontSize = '14px';
            tooltipEl.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
            tooltipEl.style.transition = 'opacity 0.2s';
            tooltipEl.style.zIndex = '9999';
            document.body.appendChild(tooltipEl);
        }

        // Hide if tooltip is not active
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
        }

        // Format tooltip content
        let tooltipHTML = /* HTML */ `<strong>${tooltip.title[0]}</strong><br />`;
        tooltip.body.forEach((item, itemIndex) => {
            item.lines.forEach((line, lineIndex) => {
                if (itemIndex === 0 && lineIndex === 0) {
                    tooltipHTML += /* HTML */ `<span style="font-size: 10px !important;">●</span>${line}<br />`;
                } else {
                    const parts = line.split(':');
                    // eslint-disable-next-line max-len
                    if ((parts[0] as string).includes('(')) {
                        tooltipHTML += /* HTML */ `<span style="margin-left: 10px; font-size: 10px !important;">●</span>${line}<br />`;
                    } else {
                        tooltipHTML += /* HTML */ `<div
                            style="display: flex; align-items: center; padding-left: 20px; font-size: 10px !important;">
                            <span>${parts[0]}:</span>
                            <strong style="margin-left: 5px">${parts[1]}</strong><br />
                        </div>`;
                    }
                }
            });
        });

        tooltipEl.innerHTML = tooltipHTML;

        // Position tooltip
        const chartRect = chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = '1';
        tooltipEl.style.left = `${chartRect.left + tooltip.caretX + 10}px`;
        tooltipEl.style.top = `${chartRect.top + tooltip.caretY - tooltipEl.clientHeight / 2}px`;
    }
}
