import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { getMonthAndYearBetweenDates, KeywordSearchImpressionsType, MonthAndYear } from '@malou-io/package-utils';

import { ImpressionsInsightsChartComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights-chart/impressions-insights-chart.component';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface ImpressionsInsightsEvolutionData {
    [KeywordSearchImpressionsType.BRANDING]: {
        total: number;
        evolution: number;
    };
    [KeywordSearchImpressionsType.DISCOVERY]: {
        total: number;
        evolution: number;
    };
}

@Component({
    selector: 'app-impressions-insights',
    standalone: true,
    imports: [ImpressionsInsightsChartComponent, NgTemplateOutlet, NumberEvolutionComponent, ShortNumberPipe, TranslateModule],
    templateUrl: './impressions-insights.component.html',
    styleUrl: './impressions-insights.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpressionsInsightsComponent {
    readonly currentKeywordSearchImpressionsInsights = input.required<ImpressionsInsightsData[]>();
    readonly previousKeywordSearchImpressionsInsights = input.required<ImpressionsInsightsData[]>();
    readonly dateInterval = input.required<{ startDate: Date; endDate: Date }>();

    readonly impressionsInsightsData: Signal<ImpressionsInsightsData[]> = computed(() =>
        this._getImpressionsInsightsData({
            currentImpressionsData: this.currentKeywordSearchImpressionsInsights(),
            startDate: this.dateInterval().startDate,
            endDate: this.dateInterval().endDate,
        })
    );

    readonly impressionsInsightsEvolutionData: Signal<ImpressionsInsightsEvolutionData> = computed(() =>
        this._getImpressionsInsightsEvolutionData(
            this.currentKeywordSearchImpressionsInsights(),
            this.previousKeywordSearchImpressionsInsights()
        )
    );

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;

    private _getImpressionsInsightsData({
        currentImpressionsData,
        startDate,
        endDate,
    }: {
        currentImpressionsData: ImpressionsInsightsData[];
        startDate: Date;
        endDate: Date;
    }): ImpressionsInsightsData[] {
        const monthAndYearRange: MonthAndYear[] = getMonthAndYearBetweenDates(startDate, endDate);

        return monthAndYearRange.map((monthAndYear) => {
            const monthInsights = currentImpressionsData.find(
                (insight) => insight.month === monthAndYear.month && insight.year === monthAndYear.year
            );
            return {
                month: monthAndYear.month,
                year: monthAndYear.year,
                [KeywordSearchImpressionsType.BRANDING]: monthInsights?.[KeywordSearchImpressionsType.BRANDING] ?? 0,
                [KeywordSearchImpressionsType.DISCOVERY]: monthInsights?.[KeywordSearchImpressionsType.DISCOVERY] ?? 0,
            };
        });
    }

    private _getImpressionsInsightsEvolutionData(
        currentImpressionsData: ImpressionsInsightsData[],
        previousImpressionsData: ImpressionsInsightsData[]
    ): ImpressionsInsightsEvolutionData {
        const _reduceFun = (
            acc,
            current
        ): { [KeywordSearchImpressionsType.BRANDING]: number; [KeywordSearchImpressionsType.DISCOVERY]: number } => {
            acc[KeywordSearchImpressionsType.BRANDING] += current[KeywordSearchImpressionsType.BRANDING];
            acc[KeywordSearchImpressionsType.DISCOVERY] += current[KeywordSearchImpressionsType.DISCOVERY];
            return acc;
        };

        const total = currentImpressionsData.reduce(_reduceFun, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        const previousTotal = previousImpressionsData.reduce(_reduceFun, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        return {
            [KeywordSearchImpressionsType.BRANDING]: {
                total: total[KeywordSearchImpressionsType.BRANDING],
                evolution: total[KeywordSearchImpressionsType.BRANDING] - previousTotal[KeywordSearchImpressionsType.BRANDING],
            },
            [KeywordSearchImpressionsType.DISCOVERY]: {
                total: total[KeywordSearchImpressionsType.DISCOVERY],
                evolution: total[KeywordSearchImpressionsType.DISCOVERY] - previousTotal[KeywordSearchImpressionsType.DISCOVERY],
            },
        };
    }
}
