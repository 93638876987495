<div class="flex h-full flex-col gap-y-4" #topOfComponent>
    <div class="flex h-full flex-col">
        <mat-tab-group class="malou-tab-group h-full" [disableRipple]="true" (selectedIndexChange)="handleTabChange($event)">
            <mat-tab label="{{ 'statistics.e_reputation.tabs.reviews' | translate }}">
                <ng-container
                    [ngTemplateOutlet]="scrollableTab"
                    [ngTemplateOutletContext]="{
                        template: reviewsTemplate,
                    }"></ng-container>
            </mat-tab>
            <mat-tab label=" {{ 'statistics.e_reputation.tabs.semantic_analysis' | translate }}">
                <ng-container
                    [ngTemplateOutlet]="scrollableTab"
                    [ngTemplateOutletContext]="{
                        template: semanticAnalysisTemplate,
                    }"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-template let-template="template" #scrollableTab>
    <div class="flex h-full flex-col gap-4 px-8.5 py-4 sm:px-4">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        <div class="h-full overflow-y-auto">
            <div class="flex flex-col gap-4">
                @if (screenSizeService.isPhoneScreen) {
                    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
                }
                @if (hasAtLeastOnePlatformConnected()) {
                    <ng-container [ngTemplateOutlet]="template"></ng-container>
                } @else {
                    <ng-container [ngTemplateOutlet]="noPlatformsConnectedTemplate"></ng-container>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 sm:flex-col sm:items-center md:px-0">
        <div class="flex-1">
            <app-statistics-filters [showPlatformsFilter]="true" [platformFilterPage]="PlatformFilterPage.E_REPUTATION">
            </app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="(platformKeys$ | async)?.length === 0 || isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #reviewsTemplate>
    <app-statistics-seo-ratings (isLoadingEvent)="isSeoRatingLoading.set($event)"></app-statistics-seo-ratings>
    <app-reviews-kpis (isLoadingEvent)="isReviewsKpisLoading.set($event)"></app-reviews-kpis>
    <div class="flex gap-4 md:flex-col">
        <div class="min-w-0 flex-1">
            <app-reviews-ratings-evolution
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.REVIEW_RATING_EVOLUTION, $event)"
                (viewByChange)="onViewByChange(InsightsChart.REVIEW_RATING_EVOLUTION, $event)"
                (isLoadingEvent)="isReviewsRatingsEvolutionLoading.set($event)">
            </app-reviews-ratings-evolution>
        </div>
        <div class="min-w-0 flex-1">
            <app-reviews-ratings-total (isLoadingEvent)="isReviewsRatingsTotalLoading.set($event)"></app-reviews-ratings-total>
        </div>
    </div>
</ng-template>

<ng-template #semanticAnalysisTemplate>
    @if (isNewSemanticAnalysisFeatureEnabled()) {
        <app-semantic-analysis></app-semantic-analysis>
    } @else {
        <app-review-analyses
            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
            (viewByChange)="onViewByChange(InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
            (isLoadingEvent)="isReviewAnalysesLoading.set($event)">
        </app-review-analyses>
    }
</ng-template>

<ng-template #noPlatformsConnectedTemplate>
    <div class="flex flex-col items-center px-8.5 py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
        <span class="malou-text-10--regular">{{ 'statistics.errors.platforms_not_connected' | translate }}</span>
    </div>
</ng-template>
