@if (isKeywordsInsightsV2Enabled()) {
    <ng-container [ngTemplateOutlet]="seoTemplateV2"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="seoTemplate"></ng-container>
}

<ng-template #seoTemplate>
    <div class="flex flex-col gap-y-4 py-4">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }

        <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
            @if (screenSizeService.isPhoneScreen) {
                <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
            }
            @if (hasEnoughRestaurantsSelected()) {
                <div class="mb-4 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded()">
                    <div class="h-[520px] flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded()">
                        <app-keywords-v3
                            (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_RANKINGS, $event)"
                            (isLoadingEvent)="isKeywordsLoading.set($event)">
                        </app-keywords-v3>
                    </div>
                    <div class="h-full min-w-0 flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded()">
                        @if (isAggregatedGmbInsightsChartsV2Enabled()) {
                            <app-gmb-impressions-v2
                                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
                                (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"></app-gmb-impressions-v2>
                        } @else {
                            <app-gmb-impressions
                                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
                                (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
                                (isLoadingEvent)="isImpressionsLoading.set($event)">
                            </app-gmb-impressions>
                        }
                    </div>
                </div>
                <div class="h-full">
                    @if (isAggregatedGmbInsightsChartsV2Enabled()) {
                        <app-gmb-actions-v2
                            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
                            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_ACTIONS, $event)">
                        </app-gmb-actions-v2>
                    } @else {
                        <app-gmb-actions
                            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
                            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
                            (isLoadingEvent)="isActionsLoading.set($event)">
                        </app-gmb-actions>
                    }
                </div>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{
                        'aggregated_statistics.errors.select_at_least_2_businesses' | translate
                    }}</span>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 sm:flex-col sm:items-center md:px-0">
        <div class="flex-1 sm:w-full">
            <app-statistics-filters [showPlatformsFilter]="false" [page]="PlatformFilterPage.SEO"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12 sm:w-full"
            mat-raised-button
            [disabled]="!hasEnoughRestaurantsSelected() || isLoading()"
            (click)="openDownloadStatisticsModal()">
            {{ 'aggregated_statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #seoTemplateV2>
    <div class="flex h-full flex-col gap-y-4">
        <div class="flex h-full flex-col">
            <mat-tab-group class="malou-tab-group h-full" [disableRipple]="true" (selectedIndexChange)="handleTabChange($event)">
                <mat-tab label="{{ 'statistics.seo.tabs.keywords' | translate }}">
                    <ng-container
                        [ngTemplateOutlet]="scrollableTab"
                        [ngTemplateOutletContext]="{
                            template: keywordsTemplate,
                        }"></ng-container>
                </mat-tab>
                <mat-tab label=" {{ 'statistics.seo.tabs.gmb_visibility' | translate }}">
                    <ng-container
                        [ngTemplateOutlet]="scrollableTab"
                        [ngTemplateOutletContext]="{
                            template: gmbTemplate,
                        }"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-template>

<ng-template #filtersTemplateV2>
    <div class="flex items-end gap-4 sm:flex-col sm:items-center">
        <div class="flex-1 sm:w-full">
            <app-statistics-filters [showPlatformsFilter]="false" [page]="PlatformFilterPage.SEO"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12 sm:w-full"
            mat-raised-button
            [disabled]="!hasEnoughRestaurantsSelected() || isLoading()"
            (click)="openDownloadStatisticsModal()">
            {{ 'aggregated_statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template let-template="template" #scrollableTab>
    <div class="flex h-full flex-col gap-4 px-8.5 py-4 sm:px-4">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplateV2"></ng-container>
        }
        <div class="h-full overflow-y-auto">
            <div class="flex flex-col gap-4">
                @if (screenSizeService.isPhoneScreen) {
                    <ng-container [ngTemplateOutlet]="filtersTemplateV2"></ng-container>
                }
                @if (hasEnoughRestaurantsSelected()) {
                    <ng-container [ngTemplateOutlet]="template"></ng-container>
                } @else {
                    <div class="flex flex-col items-center py-6">
                        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                        <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                        <span class="malou-text-10--regular">{{
                            'aggregated_statistics.errors.select_at_least_2_businesses' | translate
                        }}</span>
                    </div>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #keywordsTemplate>
    <div class="h-[520px] flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded()">
        <app-keywords-v3
            (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_RANKINGS, $event)"
            (isLoadingEvent)="isKeywordsLoading.set($event)">
        </app-keywords-v3>
    </div>
</ng-template>

<ng-template #gmbTemplate>
    <div class="mb-4 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded()">
        <div class="h-full min-w-0 flex-1 xl:!flex-none" [class.!flex-none]="isRestaurantsCountUiLimitExceeded()">
            <ng-container [ngTemplateOutlet]="gmbImpressionsTemplate"></ng-container>
        </div>
    </div>
    <div class="h-full">
        <ng-container [ngTemplateOutlet]="gmbActionsTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #gmbImpressionsTemplate>
    @if (isAggregatedGmbInsightsChartsV2Enabled()) {
        <app-gmb-impressions-v2
            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"></app-gmb-impressions-v2>
    } @else {
        <app-gmb-impressions
            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_APPARITIONS, $event)"
            (isLoadingEvent)="isImpressionsLoading.set($event)">
        </app-gmb-impressions>
    }
</ng-template>

<ng-template #gmbActionsTemplate>
    @if (isAggregatedGmbInsightsChartsV2Enabled()) {
        <app-gmb-actions-v2
            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_ACTIONS, $event)">
        </app-gmb-actions-v2>
    } @else {
        <app-gmb-actions
            (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
            (sortByChange)="onSortByChange(InsightsChart.AGGREGATED_ACTIONS, $event)"
            (isLoadingEvent)="isActionsLoading.set($event)">
        </app-gmb-actions>
    }
</ng-template>
