<div class="flex flex-col">
    <div class="flex h-12.5 flex-col md:h-fit">
        <div class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.keywords.your_keywords' | translate }}</div>
    </div>
    @if (isLoading()) {
        <app-skeleton skeletonClass="!h-20 secondary-bg"></app-skeleton>
    } @else {
        @if (!httpError) {
            @if (dataSource.data.length > 0) {
                <ng-container *ngTemplateOutlet="isPhoneScreen() ? mobileList : desktopTable"></ng-container>
            } @else {
                <ng-container [ngTemplateOutlet]="emptyStateTemplate"></ng-container>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError | statisticsHttpError }}</span>
            </div>
        }
    }
</div>

<ng-template #desktopTable>
    <mat-table
        class="malou-mat-table"
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        (matSortChange)="onSortChange($event)"
        #table="matTable">
        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.KEYWORD">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xlarge" mat-sort-header>
                {{ 'statistics.seo.keywords.keywords_column.header_title' | translate }}
            </mat-header-cell>
            <mat-cell
                *matCellDef="let row; table: table"
                class="malou-text-13--semibold malou-mat-table-cell-xlarge truncate !text-malou-color-text-1">
                <div class="truncate" [matTooltip]="row.keyword">
                    {{ row.keyword }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.POSITION">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <span class="flex gap-x-1">
                    <mat-icon
                        class="pdf-hidden !h-4 !w-4"
                        matTooltip="{{ 'statistics.seo.keywords.position_column.header_tooltip' | translate }}"
                        color="primary"
                        [svgIcon]="SvgIcon.INFO"></mat-icon>
                    <span>{{ 'statistics.seo.keywords.position_column.header_title' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                @if (row.error && !(isRowCurrentPositionValid | applyPure: row) && !(isErrorFixable | applyPure: row?.errorData)) {
                    <div class="flex items-center">
                        <span class="mr-2">
                            {{ 'statistics.seo.keywords.position_column.cell.no_info' | translate }}
                        </span>
                        <mat-icon
                            class="malou-text--medium pdf-hidden !h-4 !w-4"
                            color="primary"
                            [svgIcon]="SvgIcon.INFO"
                            [matTooltip]="getErrorDetail | applyPure: row.errorData"></mat-icon>
                    </div>
                } @else {
                    @if (!row.currentPosition) {
                        <div
                            class="custom-tooltip"
                            [matTooltip]="
                                (canRefresh | applyPure: row) && doesPeriodEndToday()
                                    ? ''
                                    : doesPeriodEndToday()
                                      ? ('statistics.seo.keywords.position_column.cell.no_results_try_24_hours' | translate)
                                      : ('statistics.seo.keywords.position_column.cell.no_results_try_other_period' | translate)
                            ">
                            @if (!row.isWaiting) {
                                <button
                                    class="malou-btn-flat disabled:cursor-default"
                                    mat-flat-button
                                    [disabled]="!doesPeriodEndToday() || !(canRefresh | applyPure: row) || row.isWaiting || refreshStarted"
                                    (click)="refreshKeyword(row)">
                                    {{ 'statistics.seo.keywords.position_column.cell.no_info' | translate }}
                                    @if (doesPeriodEndToday()) {
                                        <mat-icon class="pdf-hidden" color="primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
                                    }
                                </button>
                            }
                        </div>
                    }
                    @if (row.currentPosition) {
                        <ng-container
                            [ngTemplateOutlet]="positionCell"
                            [ngTemplateOutletContext]="{
                                positionDetails: getEmojiAndTextFromPosition | applyPure: row.currentPosition,
                                errorData: row.errorData,
                            }"></ng-container>
                    }
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.EVOLUTION">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall">
                <span class="flex items-center gap-x-1">
                    <span>{{ 'statistics.seo.keywords.evolution_column.header_title' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let row; table: table"
                class="malou-mat-table-cell-xsmall"
                mat-cell
                [matTooltip]="'statistics.seo.keywords.evolution_column.cell.evolution_tooltip' | translate: { text: row.keyword }"
                (click)="openKeywordEvolution(row.keyword)">
                <div class="h-12 w-full justify-center px-2">
                    @if (row.positions && row.positions.length) {
                        <app-keyword-evolution-mini [positions]="row.positions"></app-keyword-evolution-mini>
                    } @else {
                        <button class="malou-btn-flat !p-0">
                            {{ 'common.see' | translate }}
                        </button>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.APPARITION">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small">
                <span class="flex items-center gap-x-1">
                    <mat-icon
                        class="pdf-hidden !h-4 !w-4"
                        matTooltip="{{ 'statistics.seo.keywords.position_column.header_tooltip' | translate }}"
                        color="primary"
                        [svgIcon]="SvgIcon.INFO"></mat-icon>
                    <span>{{ 'statistics.seo.keywords.apparition_column.header_title' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-small" mat-cell>
                <div class="malou-text-13--semibold malou-color-text-1 flex h-12 w-full items-center justify-center gap-x-1.5">
                    <span>{{ row.impression > 0 ? '≃ ' : '' }}{{ row.impression }} </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.ADVICE">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xlarge">
                <span class="flex items-center gap-x-1">
                    <span>{{ 'statistics.seo.keywords.advice_column.header_title' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table; let i = index" class="malou-mat-table-cell-xlarge" mat-cell>
                @if (i === 0) {
                    <img class="mr-4 h-4.5 w-4.5 sm:mb-5" [src]="Emoji.ROCKET | emojiPathResolver" />
                    <div class="malou-text-10--regular flex w-full items-center">
                        {{ 'statistics.seo.keywords.evolution_column.cell.good' | translate }}
                    </div>
                } @else if (i === 1) {
                    <img class="mr-4 h-4.5 w-4.5 sm:mb-5" [src]="Emoji.HOURGLASS | emojiPathResolver" />
                    <div class="malou-text-10--regular flex w-full items-center">
                        {{ 'statistics.seo.keywords.evolution_column.cell.wait' | translate }}
                    </div>
                } @else {
                    <img class="mr-4 h-4.5 w-4.5 sm:mb-5" [src]="Emoji.CRYING_FACE | emojiPathResolver" />
                    <div class="flex flex-col items-start">
                        <div class="malou-text-10--regular flex w-full items-center">
                            {{ 'statistics.seo.keywords.evolution_column.cell.bad' | translate }}
                        </div>
                        <button
                            class="malou-btn-flat !malou-text-10--semibold !p-0"
                            [disabled]="row.isLoading || !row.currentPosition?.rank"
                            (click)="redirectToKeywordsPage()">
                            {{ 'statistics.seo.keywords.evolution_column.cell.change_my_keyword' | translate }}
                        </button>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordsDetailStatsColumn.COMPETITORS">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall !pr-0">
                <span class="flex w-full items-center justify-center gap-x-1">
                    <span>{{ 'statistics.seo.keywords.competitors_column.header_title' | translate }}</span>
                </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xsmall justify-end !pr-0">
                <button
                    class="malou-btn-flat malou-text-13--semibold !p-0 !pr-5"
                    [disabled]="row.isLoading || !row.currentPosition?.rank"
                    (click)="openCompetitorsList(row.keyword, row.ranking, row.currentPosition?.rank)">
                    {{ 'common.see' | translate }}
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns(); table: table"></mat-row>
    </mat-table>
</ng-template>

<ng-template #mobileList>
    <div class="malou-card !m-0 !my-5 max-h-12 !flex-row gap-x-3 !py-4">
        <div class="flex w-full items-center gap-x-2" [matMenuTriggerFor]="sortMenu">
            <mat-icon
                class="!h-4 !w-4"
                color="primary"
                [svgIcon]="sortDirection === 1 ? 'arrow-up' : 'arrow-down'"
                (click)="$event.stopPropagation(); changeSortOrder()"></mat-icon>
            <span class="malou-text-13--medium">{{ 'common.sort' | translate }} : </span>
            <span class="malou-text-13--bold max-w-[50%] truncate">{{ selectedSortOption.text }}</span>
            <mat-icon class="ml-auto !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </div>

        <mat-menu class="malou-mat-menu malou-box-shadow !mt-3.5 !w-[100vw] !rounded-xl" #sortMenu="matMenu">
            @for (sort of sortOptions; track sort) {
                <button class="custom-menu-item" mat-menu-item (click)="sortKeywords(sort)">
                    <span class="malou-text-13--regular !text-malou-color-text-1">{{ sort.text }}</span>
                </button>
            }
        </mat-menu>
    </div>
    <div class="mb-12 flex w-full flex-col gap-y-2">
        @for (row of dataSource.data; track row.restaurantKeywordId) {
            <div class="malou-card !m-0 !flex-none gap-y-5 !p-5">
                <div class="flex justify-between">
                    <span class="malou-text-13--semibold truncate text-malou-color-text-1">{{ row.keyword }}</span>
                    <button
                        class="malou-btn-flat !p-0"
                        [disabled]="row.isLoading"
                        (click)="openCompetitorsList(row.keyword, row.ranking, row.currentPosition?.rank)">
                        {{ 'statistics.seo.keywords.competitors_column.header_title' | translate }}
                    </button>
                </div>
                @if (row.currentPosition || row.positions) {
                    <div class="flex h-12">
                        @if (row.currentPosition) {
                            <span [ngClass]="{ 'w-2/3': row.positions }">
                                <ng-container
                                    [ngTemplateOutlet]="positionCell"
                                    [ngTemplateOutletContext]="{
                                        positionDetails: getEmojiAndTextFromPosition | applyPure: row.currentPosition,
                                        errorData: row.errorData,
                                    }">
                                </ng-container>
                            </span>
                        }
                        @if (row.positions) {
                            <div
                                class="pointer px-2"
                                [ngClass]="{ 'w-1/3': row.currentPosition }"
                                (click)="openKeywordEvolution(row.keyword)">
                                <app-keyword-evolution-mini [positions]="row.positions"></app-keyword-evolution-mini>
                            </div>
                        }
                    </div>
                }
                <div class="flex">
                    <div class="malou-text-12--medium w-1/3">
                        {{ 'statistics.seo.keywords.apparition_column.header_title' | translate }}
                    </div>
                    <div class="w-2/3 text-malou-color-text-1">{{ row.impression > 0 ? '≃ ' : '' }}{{ row.impression }}</div>
                </div>
                <div class="flex">
                    <div class="malou-text-12--medium w-1/3">
                        {{ 'statistics.seo.keywords.advice_column.header_title' | translate }}
                    </div>
                    <div class="flex w-2/3 text-malou-color-text-1">
                        <img class="mr-4 h-4.5 w-4.5" [src]="Emoji.ROCKET | emojiPathResolver" />
                        <div class="malou-text-10--regular flex w-full items-center justify-center">
                            {{ 'statistics.seo.keywords.evolution_column.cell.good' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-positionDetails="positionDetails" let-errorData="errorData" #positionCell>
    <div class="flex items-center gap-x-2">
        <div class="emoji-container flex items-center gap-x-2 text-lg">
            @if (positionDetails?.emojiSrc) {
                <div class="w-[22px]">
                    <img class="w-full" [src]="positionDetails.emojiSrc" />
                </div>
            }
            @if (errorData === RESULTS_TOO_FAR_ERROR) {
                <mat-icon
                    class="malou-color-warning"
                    [svgIcon]="SvgIcon.WARNING"
                    [matTooltip]="'statistics.seo.keywords.position_column.cell.results_too_far_warning' | translate">
                </mat-icon>
            }
        </div>
        <div class="position">
            <div class="malou-text-13--semibold">{{ positionDetails.title }}</div>
            <div class="malou-text-10--regular">{{ positionDetails.caption }}</div>
        </div>
    </div>
</ng-template>

<ng-template #emptyStateTemplate>
    <div class="flex flex-col items-center gap-y-1 rounded-[10px] bg-malou-color-background-white py-6">
        <img class="mb-4 h-40 w-40" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
        <span class="malou-text-14--bold text-malou-color-text-1">{{ 'statistics.seo.keywords.no_keywords_found' | translate }}</span>
        <span class="malou-text-10--regular mb-4">{{ 'statistics.seo.keywords.no_keywords_found_description' | translate }}</span>
        <button class="malou-btn-raised--primary !h-12" mat-raised-button (click)="redirectToKeywordsPage()">
            {{ 'statistics.seo.keywords.add_keywords' | translate }}
        </button>
    </div>
</ng-template>
