import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, inject, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, switchMap, take } from 'rxjs';

import { HeapEventName, InsightsChart, InsightsTab } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import { GmbActionsComponent } from ':modules/statistics/seo/gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from ':modules/statistics/seo/gmb-impressions/gmb-impressions.component';
import { GmbInsightsComponent } from ':modules/statistics/seo/gmb-insights/gmb-insights.component';
import { KeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.component';
import { StatisticsKeywordsDetailComponent } from ':modules/statistics/seo/statistics-keywords-detail/statistics-keywords-detail.component';
import { StatisticsSeoKeywordsV3Component } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import { selectComparisonPeriodFilter, selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum SeoInsightsTabs {
    KEYWORDS,
    GMB,
}
@Component({
    selector: 'app-statistics-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FiltersComponent,
        GmbActionsComponent,
        GmbImpressionsComponent,
        GmbInsightsComponent,
        StatisticsKeywordsDetailComponent,
        StatisticsSeoKeywordsV3Component,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        KeywordSearchImpressionsComponent,
    ],
})
export class SeoComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;
    readonly screenSizeService = inject(ScreenSizeService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _heapService = inject(HeapService);
    private readonly _store = inject(Store);

    readonly InsightsChart = InsightsChart;

    readonly isKeywordsLoading = signal(true);
    readonly isImpressionsLoading = signal(true);
    readonly isActionsLoading = signal(true);
    readonly isGmbInsightsLoading = signal(true);

    readonly isLoading = computed(
        () =>
            this.isKeywordsLoading() ||
            (this.isGmbInsightsChartsV2Enabled() ? this.isGmbInsightsLoading() : this.isImpressionsLoading() || this.isActionsLoading())
    );

    readonly isGmbInsightsChartsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-gmb-insights-charts-v2'),
        {
            initialValue: false,
        }
    );
    readonly isKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );

    readonly shouldShowTimeScaleFilter: WritableSignal<boolean> = signal(true);

    chartOptions: ChartOptions = {
        [InsightsChart.ACTIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.APPARITIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.KEYWORDS]: {
            tableSortOptions: undefined,
        },
    };

    readonly PlatformFilterPage = PlatformFilterPage;

    ngAfterViewInit(): void {
        setTimeout(() => this.topOfComponent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }));
    }

    openStatisticsDownload(): void {
        combineLatest([this._store.select(selectDatesFilter).pipe(take(1)), this._store.select(selectComparisonPeriodFilter).pipe(take(1))])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, comparisonPeriod]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.SEO,
                                filters: {
                                    dates: { startDate, endDate },
                                    comparisonPeriod,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }

    handleTabChange(event: number): void {
        switch (event) {
            case SeoInsightsTabs.KEYWORDS:
                this._heapService.track(HeapEventName.TRACKING_CLICK_SEO_INSIGHTS_KEYWORDS_TAB);
                this.shouldShowTimeScaleFilter.set(true);
                break;
            case SeoInsightsTabs.GMB:
                this._heapService.track(HeapEventName.TRACKING_CLICK_SEO_INSIGHTS_GMB_TAB);
                this.shouldShowTimeScaleFilter.set(false);
                break;
        }
    }
}
