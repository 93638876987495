import { NgTemplateOutlet } from '@angular/common';
import { Component, effect, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { difference, uniq } from 'lodash';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsRating, ReviewsRatingsWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsRatingsTotalChartComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total-chart/reviews-ratings-total-chart.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-reviews-ratings-total',
    templateUrl: './reviews-ratings-total.component.html',
    styleUrls: ['./reviews-ratings-total.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        ReviewsRatingsTotalChartComponent,
        MatProgressSpinnerModule,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewsRatingsTotalComponent implements OnInit, KillSubscriptions {
    @Output() hasDataChange = new EventEmitter<boolean>();
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    labels: string[] = [];
    reviewsRatings: ReviewsRating[];

    httpError: any;
    isLoading = signal(true);
    noResults = false;
    isSomePlatformGotNoData = false;
    somePlatformGotNoDataErrorMsg: string;

    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this._restaurantsService.restaurantSelected$])
            .pipe(
                filter(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]): Observable<
                        [ReviewsRatingsWithRange, string[]]
                    > => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;

                        return forkJoin([
                            this._reviewsService.getChartRestaurantsReviewsRatings(restaurantId, platforms, startDate, endDate).pipe(
                                catchError((error) => {
                                    this.httpError = error;
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            of(platforms),
                        ]);
                    }
                ),
                map(([reviewsRatings, platforms]: [ReviewsRatingsWithRange, string[]]) => {
                    if (!reviewsRatings || reviewsRatings.results.length === 0) {
                        this.isLoading.set(false);
                        this.noResults = true;
                        this.hasDataChange.emit(false);
                        return null;
                    }
                    return [reviewsRatings, platforms];
                }),
                filter((dataOrNull: [ReviewsRatingsWithRange, string[]] | null) => !!dataOrNull),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([reviewsRatings, platforms]: [ReviewsRatingsWithRange, string[]]) => {
                this._store.dispatch(StatisticsActions.editReviewsRatingsTotalData({ data: [...reviewsRatings.results] }));
                const { results } = reviewsRatings;

                this._checkIfPlatformsGoNoData(results, platforms);

                this.reviewsRatings = results.sort((a, b) => {
                    if (!a.rating) {
                        return 1;
                    }
                    if (!b.rating) {
                        return -1;
                    }
                    return b.rating - a.rating;
                });
                this.labels = this.reviewsRatings.map((e) => (e.rating ?? 0).toString());

                this.isLoading.set(false);
            });
    }

    private _checkIfPlatformsGoNoData(reviewsRatings: ReviewsRating[], platforms: string[]): void {
        const platformsWithData: string[] = uniq(reviewsRatings.flatMap((e) => e.platforms).map((e) => e.key));
        const platformsWithNoData: string[] = difference(platforms, platformsWithData).map((platformKey) =>
            this._enumTranslate.transform(platformKey, 'platform_key')
        );
        if (platformsWithNoData.length > 0) {
            const baseMsg = this._translateService.instant('statistics.e_reputation.reviews_ratings.some_platforms_got_no_data');
            this.somePlatformGotNoDataErrorMsg = `${baseMsg} ${platformsWithNoData.join(', ')}`;
            this.isSomePlatformGotNoData = true;
        } else {
            this.isSomePlatformGotNoData = false;
        }
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.noResults = false;
    }
}
