import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { ApplicationLanguage, langDomaineMapping, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { MALOU_MANAGER_EMAIL_ADDRESS } from ':core/constants';
import { ToastService } from ':core/services/toast.service';
import {
    ACCOUNT_MANAGED_SHARED_DATA_TYPE,
    AccountManagedConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-account-managed-connection-step-2',
    templateUrl: './account-managed-connection-step-2.component.html',
    styleUrls: ['./account-managed-connection-step-2.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, MalouSpinnerComponent, ImagePathResolverPipe, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountManagedConnectionStep2Component extends BaseStepComponent<
    ACCOUNT_MANAGED_SHARED_DATA_TYPE,
    AccountManagedConnectionModalResult
> {
    private readonly _clipboard = inject(Clipboard);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly ButtonStyle = ButtonStyle;
    readonly MALOU_MANAGER_EMAIL_ADDRESS = MALOU_MANAGER_EMAIL_ADDRESS;
    readonly imagesClasses: Record<ACCOUNT_MANAGED_SHARED_DATA_TYPE['platformKey'], string> = {
        [PlatformKey.FOURSQUARE]: 'h-auto w-full',
        [PlatformKey.RESY]: 'h-auto w-full',
        [PlatformKey.TRIPADVISOR]: 'h-[200px] w-auto',
        [PlatformKey.YELP]: 'h-auto w-full',
    };

    readonly platformKey = computed(() => this.sharedData().platformKey);

    readonly currentLang = toSignal(this._translateService.onLangChange.pipe(map((v) => v.lang)), {
        initialValue: this._translateService.currentLang,
    });
    readonly imageLang = computed(() =>
        this.currentLang() === ApplicationLanguage.FR.toString() ? ApplicationLanguage.FR : ApplicationLanguage.EN
    );

    readonly socialId = computed(() => this.sharedData().socialId);

    readonly translations = computed(() => {
        const base = 'platforms.connection_new.account_managed';
        const platformKey = this.platformKey();
        return {
            title: `${base}.${platformKey}.step_2.step_name`,
            description1: `${base}.${platformKey}.step_2.description_1`,
            description2: `${base}.${platformKey}.step_2.description_2`,
            primaryButton: `${base}.${platformKey}.step_2.primary_button`,
        };
    });

    readonly imageName = computed(() => {
        const platformKey = this.platformKey();
        return `${platformKey}-add-manager`;
    });

    readonly tutorialLink = computed<string | null>(() => {
        const platformTutorial = PlatformDefinitions.getPlatformDefinition(this.platformKey());
        return platformTutorial?.tutorialLink ? platformTutorial.tutorialLink(this.currentLang() as ApplicationLanguage) : null;
    });

    readonly hasTutorialLink = computed<boolean>(() => !!this.tutorialLink());

    onPrimaryClick(): void {
        const url = this._getUrl();
        if (url) {
            window.open(url, '_blank');
        }
        this.goToStep.emit({ type: 'relative', value: 1 });
    }

    onTertiaryClick(): void {
        const tutorialLink = this.tutorialLink();
        if (tutorialLink) {
            window.open(tutorialLink, '_blank');
        }
    }

    onCopyButtonClick(): void {
        this._clipboard.copy(this.MALOU_MANAGER_EMAIL_ADDRESS);
        this._toastService.openSuccessToast(this._translateService.instant('common.copied_to_the_clipboard'));
    }

    private _getUrl(): string | null {
        const platformKey = this.platformKey();
        const socialId = this.sharedData().socialId;
        if (!socialId) {
            return null;
        }
        switch (platformKey) {
            case PlatformKey.TRIPADVISOR:
                return this._getTripadvisorUrl(socialId);
            case PlatformKey.YELP:
                return this._getYelpUrl();
            case PlatformKey.RESY:
                return this._getResyUrl(socialId);
            case PlatformKey.FOURSQUARE:
                return this._getFoursquareUrl(socialId);
            default:
                return null;
        }
    }

    private _getTripadvisorUrl(socialId: string): string {
        return `https://www.tripadvisor.${this._getTldByCurrentLang()}/ManageUserAccess-d${socialId}`;
    }

    private _getFoursquareUrl(socialId: string): string {
        return `https://www.foursquare.com/${socialId}/home`;
    }

    private _getTldByCurrentLang(): string {
        return langDomaineMapping[this._translateService.currentLang]?.tld ?? langDomaineMapping.fr.tld;
    }

    private _getYelpUrl(): string | null {
        const baseUrl = PlatformDefinitions.getPlatformDefinition(PlatformKey.YELP)?.bizUrl;
        if (!baseUrl) {
            return null;
        }
        return `${baseUrl}/settings/user_management`;
    }

    private _getResyUrl(socialId: string): string | null {
        const baseUrl = PlatformDefinitions.getPlatformDefinition(PlatformKey.RESY)?.bizUrl;
        if (!baseUrl) {
            return null;
        }
        const additionalParams = this._getResyAdditionalParams(socialId);
        return `${baseUrl}${additionalParams}`;
    }

    private _getResyAdditionalParams(resySocialId: string): string {
        const extractRegex = /[a-z-]+-([a-z-]+)\/venues\/([a-z-]+)/;
        const extractedRegionAndRestaurantName = resySocialId.match(extractRegex) || [];
        const region = extractedRegionAndRestaurantName[1];
        const restaurantName = extractedRegionAndRestaurantName[2];
        return region && restaurantName ? `${region}/${restaurantName}/user-permissions/users` : '';
    }
}
