import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MentionConfig } from 'angular-mentions';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map, of, switchMap } from 'rxjs';

import {
    FeedbackMessageVisibility,
    MalouErrorCode,
    PostFeedbackMessage,
    PostFeedbacks,
    PostFeedbacksParticipant,
    Role,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { FeedbacksService } from ':core/services/feedbacks.service';
import { ToastService } from ':core/services/toast.service';
import { FeedbackMessageComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/notes/feedback-message/feedback-message.component';
import {
    FAKE_ID,
    USER_TAGGED_TEMPLATE,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/notes/notes.interface';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { ShowIfAdminDirective } from ':shared/directives/show-if-admin.directive';
import { FeedbackMessageType } from ':shared/models/feedback';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatProgressSpinnerModule,
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FeedbackMessageComponent,
        SlideToggleComponent,
        TextAreaComponent,
        ShowIfAdminDirective,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent {
    readonly feedbacks = model.required<PostFeedbacks | null>();
    readonly trackingId = input.required<string>();
    readonly restaurantManagers = input.required<User[]>();
    readonly postId = input.required<string>();
    readonly isPostPublished = input.required<boolean>();

    private readonly _feedbacksService = inject(FeedbacksService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _store = inject(Store);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);
    private readonly _fb = inject(FormBuilder);

    readonly feedbacksAreClosed = computed(() => this.feedbacks()?.isOpen === false);

    readonly currentUser$ = this._store.select(selectUserInfos);
    readonly currentUser = toSignal<User | null>(this.currentUser$, { initialValue: null });
    readonly userRole$ = this.currentUser$.pipe(map((user) => user?.role));
    readonly userRole = toSignal<Role | undefined>(this.userRole$);
    readonly feedbackMessages = computed(() => this._getDisplayedMessages(this.feedbacks(), this.userRole()));

    readonly editingMessageId = signal<string | null>(null);
    readonly loading = signal<boolean>(false);

    readonly mentionConfig = computed<MentionConfig>(() => ({
        mentions: [
            {
                items: this.restaurantManagers(),
                labelKey: 'fullname',
                dropUp: true,
                mentionSelect: (user: User): string => {
                    this._taggedUsers.push({
                        id: user.id ?? user._id,
                        name: user.name,
                        role: user.role,
                        email: user.email,
                        userId: user.id ?? user._id,
                        lastname: user.lastname,
                    });
                    return `@${user?.name} `;
                },
            },
        ],
    }));

    readonly messagesForm: FormGroup<{
        newMessage: FormControl<string | null>;
    }> = this._fb.group({
        newMessage: '',
    });

    readonly FEEDBACK_MESSAGE_LIST_CONTAINER_ID = 'feedbackMessages';

    readonly Illustration = Illustration;

    private _taggedUsers: PostFeedbacksParticipant[] = [];

    readonly adminVisibility = signal(false);
    readonly newMessageVisibility = computed(
        (): FeedbackMessageVisibility => (this.adminVisibility() ? FeedbackMessageVisibility.ADMIN : FeedbackMessageVisibility.BASIC)
    );

    constructor() {
        effect(() => {
            if (this.feedbacks()) {
                this._scrollFeedbacksToBottom();
            }
        });
    }

    get newMessage(): string {
        return this.messagesForm.get('newMessage')?.value ?? '';
    }

    onChangeMessageVisibility(event: MatCheckboxChange): void {
        this.adminVisibility.set(event.checked);
    }

    updateMessage({ message, editingMessage }: { message: PostFeedbackMessage; editingMessage: string }): void {
        const feedbacks = this.feedbacks();
        if (!feedbacks) {
            return;
        }
        this.loading.set(true);
        this._feedbacksService
            .updateFeedbackMessageV2(
                feedbacks.id,
                message.id,
                { text: this._formatMessageToSend(editingMessage), visibility: message.visibility },
                this._taggedUsers.filter((user) => user.id !== FAKE_ID)
            )
            .subscribe({
                next: (res) => {
                    this.loading.set(false);
                    this._taggedUsers = [];
                    this.feedbacks.set(res.data);
                },
                error: (err) => {
                    this.loading.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    deleteMessage(message: PostFeedbackMessage): void {
        const feedbacks = this.feedbacks();
        if (!feedbacks) {
            return;
        }
        this.loading.set(true);
        this._feedbacksService.deleteFeedbackMessageV2(feedbacks.id, message.id).subscribe({
            next: (res) => {
                this.loading.set(false);
                this.feedbacks.set(res.data);
            },
            error: (err) => {
                this.loading.set(false);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    changeMessageVisibility(message: PostFeedbackMessage): void {
        this.feedbacks.update((currentFeedbacks) => {
            if (!currentFeedbacks) {
                return currentFeedbacks;
            }
            const index = currentFeedbacks.feedbackMessages.findIndex((feedbackMessage) => feedbackMessage.id === message.id);
            if (index === -1) {
                return currentFeedbacks;
            }
            if (message.visibility === FeedbackMessageVisibility.ADMIN) {
                currentFeedbacks.feedbackMessages[index] = { ...message, visibility: FeedbackMessageVisibility.BASIC };
            }
            if (message.visibility === FeedbackMessageVisibility.BASIC) {
                currentFeedbacks.feedbackMessages[index] = { ...message, visibility: FeedbackMessageVisibility.ADMIN };
            }
            return { ...currentFeedbacks };
        });
    }

    createMessage(): void {
        const currentUser = this.currentUser();
        if (!this.newMessage || !currentUser) {
            return;
        }
        const messageToSend = this._formatMessageToSend(this.newMessage);
        const newFeedbackMessage = {
            author: {
                ...currentUser,
                profilePictureUrl: currentUser.profilePicture?.urls?.small as string,
            },
            createdAt: new Date(),
            updatedAt: new Date(),
            type: FeedbackMessageType.TEXT,
            text: messageToSend,
            visibility: this.newMessageVisibility(),
        };
        this.loading.set(true);

        const feedbacks = this.feedbacks();

        const shouldCreateFeedbackFirst$ = feedbacks?.id ? of({ data: feedbacks }) : this._feedbacksService.createFeedbackV2(this.postId());
        shouldCreateFeedbackFirst$
            .pipe(
                switchMap((res) =>
                    this._feedbacksService.addFeedbackMessageV2(
                        res.data.id,
                        newFeedbackMessage,
                        this._taggedUsers.filter((user) => user.id !== FAKE_ID)
                    )
                )
            )
            .subscribe({
                next: (res) => {
                    this.loading.set(false);
                    this._taggedUsers = [];
                    this.feedbacks.set(res.data);
                    this.messagesForm.patchValue({ newMessage: null });
                    this.adminVisibility.set(false);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.loading.set(false);
                    if (err.status === 403) {
                        return;
                    }
                    if (err?.error?.malouErrorCode === MalouErrorCode.EMAIL_NOT_SENT) {
                        this._taggedUsers = [];
                        this.feedbacks.update((currentValue) => {
                            if (currentValue) {
                                currentValue.feedbackMessages.push({ id: 'tempId', ...newFeedbackMessage });
                                return { ...currentValue };
                            }
                            return currentValue;
                        });
                        this.messagesForm.patchValue({ newMessage: null });
                        this.adminVisibility.set(false);
                        this._toastService.openErrorToast(
                            this._httpErrorPipe.transform(this._translateService.instant('feedbacks.email_not_sent'))
                        );
                        return;
                    }
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    toggleFeedbackStatus(shouldOpen: boolean): void {
        const currentUser = this.currentUser();
        const feedbacks = this.feedbacks();
        if (!feedbacks || !currentUser) {
            return;
        }
        const newFeedbackMessage = {
            author: {
                ...currentUser,
                profilePictureUrl: currentUser.profilePicture?.urls?.small as string,
            },
            createdAt: new Date(),
            type: shouldOpen ? FeedbackMessageType.REOPEN : FeedbackMessageType.CLOSE,
            visibility: FeedbackMessageVisibility.BASIC,
            text: '',
        };

        const shouldCreateFeedbackFirst$ = feedbacks.id ? of({ data: feedbacks }) : this._feedbacksService.createFeedbackV2(this.postId());
        shouldCreateFeedbackFirst$
            .pipe(
                switchMap(() => this._feedbacksService.addFeedbackMessageV2(feedbacks.id, newFeedbackMessage, [])),
                switchMap(() => this._feedbacksService.updateFeedbackIsOpenV2(feedbacks.id, !feedbacks.isOpen))
            )
            .subscribe({
                next: (res) => {
                    this.feedbacks.set(res.data);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _getDisplayedMessages = (feedback: PostFeedbacks | null, userRole: Role | undefined): PostFeedbackMessage[] => {
        const messages =
            feedback?.feedbackMessages.filter((message) => this._shouldDisplayMessage(message, userRole ?? Role.MALOU_BASIC)) ?? [];
        return messages;
    };

    private _shouldDisplayMessage(message: PostFeedbackMessage, userRole: Role): boolean {
        return message.visibility !== FeedbackMessageVisibility.ADMIN || userRole === Role.ADMIN;
    }

    private _formatMessageToSend(message: string): string {
        let formattedMessage = message;
        const taggedUsersValue = this._taggedUsers.map((user) => `@${user.name}`);
        taggedUsersValue.forEach((taggedUserValue) => {
            formattedMessage = formattedMessage.replace(taggedUserValue, `${USER_TAGGED_TEMPLATE}${taggedUserValue.replace('@', '')}`);
        });
        return formattedMessage;
    }

    private _scrollFeedbacksToBottom(): void {
        const DELAY_BEFORE_SCROLLING = 500 * TimeInMilliseconds.MILLISECOND;
        setTimeout(() => {
            const feedbackMessagesList = document.getElementById(this.FEEDBACK_MESSAGE_LIST_CONTAINER_ID);
            feedbackMessagesList?.scrollTo({
                top: feedbackMessagesList.scrollHeight,
                behavior: 'smooth',
            });
        }, DELAY_BEFORE_SCROLLING);
    }
}
