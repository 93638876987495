import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, debounceTime, EMPTY, filter, forkJoin, map, Observable, switchMap, tap } from 'rxjs';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import { isNotNil, MalouMetric, PartialRecord, PlatformKey, roundToDecimals } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const MAXIMUM_RATING = 5;
const ADJUSTED_MAXIMUM_RATING = 4.96;
const DECIMAL_PRECISION = 1;
const DEFAULT_INCREMENT = Math.pow(10, -DECIMAL_PRECISION);

@Component({
    selector: 'app-reviews-rating-calculator',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        SkeletonComponent,
        MatIconModule,
        MatSliderModule,
        TranslateModule,
        EmojiPathResolverPipe,
        IllustrationPathResolverPipe,
    ],
    templateUrl: './reviews-rating-calculator.component.html',
    styleUrl: './reviews-rating-calculator.component.scss',
})
export class ReviewsRatingCalculatorComponent implements OnInit {
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly Illustration = Illustration;
    readonly Emoji = Emoji;
    readonly MAXIMUM_RATING = MAXIMUM_RATING;
    readonly DEFAULT_INCREMENT = DEFAULT_INCREMENT;

    readonly selectedRestaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(
        filter(isNotNil),
        tap((restaurant) => {
            this.ratingGoal.set(this._getDefaultRatingGoal(restaurant._id));
            this.restaurantId.set(restaurant._id);
        })
    );
    readonly dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(StatisticsSelectors.selectDatesFilter)
        .pipe(map(({ startDate, endDate }) => ({ startDate, endDate })));
    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );

    readonly initialRating: WritableSignal<number | null> = signal(null);
    readonly rating: WritableSignal<number> = signal(MAXIMUM_RATING);
    readonly hasMaximumStarRating = computed(() => this.rating() === MAXIMUM_RATING);
    readonly ratingGoal: WritableSignal<number> = signal(MAXIMUM_RATING);
    readonly hasReachedGoal = computed(() => this.rating() === this.ratingGoal());
    readonly reviewCount: WritableSignal<number | null> = signal(null);
    readonly numberOfReviewsToReachGoal = computed(() => this._computeNumberOfReviewsToReachGoal(this.ratingGoal(), this.reviewCount()));
    readonly restaurantId: WritableSignal<string | null> = signal(null);

    readonly isLoading = signal(false);
    readonly hasData = signal(true);
    readonly httpError = signal<string | null>(null);

    constructor() {
        effect(() => this.hasDataChange.emit(this.hasData()));
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.selectedRestaurant$, this.dates$, this.platformKeys$])
            .pipe(
                filter(([_restaurant, dates, _platforms]) => !!dates.startDate && !!dates.endDate),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, platformKeys]: [Restaurant, { startDate: Date; endDate: Date }, PlatformKey[]]) => {
                    const { startDate, endDate } = dates;

                    const isGmbConnected = platformKeys.includes(PlatformKey.GMB);
                    if (!isGmbConnected) {
                        this.isLoading.set(false);
                        this.hasData.set(false);
                        return EMPTY;
                    }

                    return forkJoin([
                        this._insightsService
                            .getStoredInsights({
                                restaurantIds: [restaurant._id],
                                startDate: startDate.toISOString(),
                                endDate: endDate.toISOString(),
                                platformKeys: [PlatformKey.GMB],
                                metrics: [MalouMetric.PLATFORM_RATING],
                            })
                            .pipe(map((res) => this._getLatestGmbRating(res?.data[restaurant._id]))),
                        this._reviewsService.getReviewCount({
                            restaurantIds: [restaurant._id],
                            platforms: [PlatformKey.GMB],
                            endDate,
                            answered: true,
                            notAnswered: true,
                            pending: true,
                            notAnswerable: false,
                            archived: true,
                            unarchived: true,
                            showPrivate: false,
                            withText: true,
                            withoutText: true,
                        }),
                    ]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([latestRating, reviewCount]: [number | null, number]) => {
                    this.isLoading.set(false);
                    this.hasData.set(latestRating !== null);
                    this.reviewCount.set(reviewCount);
                    if (latestRating === null) {
                        return;
                    }
                    const roundedLatestRating = roundToDecimals(latestRating, DECIMAL_PRECISION);
                    const roundedIncrementedRating = roundToDecimals(latestRating + DEFAULT_INCREMENT, DECIMAL_PRECISION);
                    this.rating.set(roundedLatestRating);
                    this.initialRating.set(roundedIncrementedRating);
                },
            });
    }

    onSliderInput(event: Event): void {
        const sliderValue = (event.target as HTMLInputElement).value;
        this.ratingGoal.set(parseFloat(sliderValue));
        const restaurantId = this.restaurantId();
        if (restaurantId) {
            this._storeRestaurantRatingGoal(restaurantId, this.ratingGoal());
        }
    }

    private _getStoredRatingGoals(): Record<string, number> {
        const storedRatingGoal = LocalStorage.getItem(LocalStorageKey.REVIEWS_CALCULATOR_RATING_GOALS);
        if (!storedRatingGoal) {
            return {};
        }
        return JSON.parse(storedRatingGoal);
    }

    private _storeRestaurantRatingGoal(restaurantId: string, ratingGoal: number): void {
        const storedRatingGoals = this._getStoredRatingGoals();
        LocalStorage.setItem(
            LocalStorageKey.REVIEWS_CALCULATOR_RATING_GOALS,
            JSON.stringify({
                ...storedRatingGoals,
                [restaurantId]: ratingGoal,
            })
        );
    }

    private _getDefaultRatingGoal(restaurantId?: string): number {
        if (!restaurantId) {
            return MAXIMUM_RATING;
        }
        const storedRatingGoals = this._getStoredRatingGoals();
        return storedRatingGoals[restaurantId] ?? MAXIMUM_RATING;
    }

    private _getLatestGmbRating(insightsByPlatformKey?: PartialRecord<PlatformKey, DailyPlatformInsights>): number | null {
        if (!insightsByPlatformKey) {
            return null;
        }
        const gmbPlatformInsights = insightsByPlatformKey[PlatformKey.GMB];
        if (!gmbPlatformInsights?.hasData) {
            return null;
        }
        const platformInsightsByDayRatings = gmbPlatformInsights.insights![MalouMetric.PLATFORM_RATING];
        if (!platformInsightsByDayRatings) {
            return null;
        }
        const sortedRatingsByDate: number[] = Object.entries(platformInsightsByDayRatings)
            .filter(([, rating]) => isNotNil(rating))
            .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
            .map(([, rating]) => rating);
        return sortedRatingsByDate[0];
    }

    private _computeNumberOfReviewsToReachGoal(wantedGoalRating: number, reviewCount: number | null): number {
        const actualRating = this.rating();
        if (!actualRating || !reviewCount) {
            return 0;
        }

        const goalRating = this._getGoalRating(wantedGoalRating);
        const numberOfReviewsToReachGoal = ((goalRating - actualRating) * reviewCount) / (5 - goalRating);
        return Math.round(numberOfReviewsToReachGoal);
    }

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
    }

    /** It's impossible to reach 5 stars, it's seams that with a 4.96 rating, Google will round it and display 5 stars */
    private _getGoalRating(wantedGoalRating: number): number {
        return wantedGoalRating === MAXIMUM_RATING ? ADJUSTED_MAXIMUM_RATING : wantedGoalRating;
    }
}
