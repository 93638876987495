import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';

import { Role } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { NfcService } from ':core/services/nfc.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';
import {
    ROUTER_LINK_ACTIVE_OPTIONS,
    SidenavContentRouteOptions,
} from '../../sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';

@Component({
    selector: 'app-sidenav-content-aggregated-insights-routes',
    templateUrl: './sidenav-content-aggregated-insights-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentAggregatedInsightsRoutesComponent {
    private readonly _store = inject(Store);
    private readonly _wheelsOfFortuneService = inject(WheelsOfFortuneService);
    private readonly _nfcService = inject(NfcService);
    private readonly _translateService = inject(TranslateService);
    private readonly _router = inject(Router);
    private readonly _experimentationService = inject(ExperimentationService);

    private readonly _ROI_OPTIONS = {
        text: this._translateService.instant('sidenav_content.roi'),
        routerLink: ['/groups', 'statistics', 'roi'],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    };
    private readonly _SEO_OPTIONS = {
        text: this._translateService.instant('sidenav_content.seo'),
        routerLink: ['/groups', 'statistics', 'seo'],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    };
    private readonly _E_REPUTATION_OPTIONS = {
        text: this._translateService.instant('sidenav_content.e_reputation'),
        routerLink: ['/groups', 'statistics', 'e-reputation'],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    };
    private readonly _SOCIAL_NETWORKS_OPTIONS = {
        text: this._translateService.instant('sidenav_content.social_network'),
        routerLink: ['/groups', 'statistics', 'social-networks'],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    };
    private readonly _BOOSTERS_OPTIONS = {
        text: this._translateService.instant('sidenav_content.boosters'),
        routerLink: ['/groups', 'statistics', 'boosters'],
        svgIcon: SvgIcon.DOT,
        isSmallSvgIcon: true,
        shouldHideSvgIconOnOpenedSidenav: true,
    };

    private readonly _ownRestaurants$ = this._store.select(selectOwnRestaurants);
    private readonly _ownRestaurantIds$ = this._ownRestaurants$.pipe(map((ownRestaurants) => ownRestaurants.map((e) => e._id)));
    private readonly _haveAtLeastOneWheelOfFortune$ = this._ownRestaurantIds$.pipe(
        switchMap((ownRestaurantIds) =>
            ownRestaurantIds.length ? this._wheelsOfFortuneService.haveAtLeastOneWheelOfFortune(ownRestaurantIds) : of({ data: false })
        ),
        map((res) => res.data)
    );
    private readonly _haveAtLeastOneNfc$ = this._ownRestaurantIds$.pipe(
        switchMap((ownRestaurantIds) =>
            ownRestaurantIds.length ? this._nfcService.search({ limit: 1 }, ownRestaurantIds) : of({ data: [] })
        ),
        map((res) => res.data),
        map((nfcs) => nfcs.length > 0)
    );
    private readonly _haveAtLeastTwoBoosterPackActivated$ = this._ownRestaurants$.pipe(
        map((ownRestaurants) => ownRestaurants.filter((r) => r.boosterPack?.activated).length > 2)
    );
    private readonly _shouldShowBoostersNavigation$ = combineLatest({
        haveAtLeastOneNfc: this._haveAtLeastOneNfc$,
        haveAtLeastOneWheelOfFortune: this._haveAtLeastOneWheelOfFortune$,
        haveAtLeastTwoBoosterPackActivated: this._haveAtLeastTwoBoosterPackActivated$,
        isFeatureBoostersV2Enabled: this._experimentationService.isFeatureEnabled$('release-boosters-v2'),
    }).pipe(
        map(
            ({ haveAtLeastOneNfc, haveAtLeastOneWheelOfFortune, haveAtLeastTwoBoosterPackActivated, isFeatureBoostersV2Enabled }) =>
                (haveAtLeastOneNfc || haveAtLeastOneWheelOfFortune) && (!isFeatureBoostersV2Enabled || haveAtLeastTwoBoosterPackActivated)
        )
    );

    private readonly _isRoiActivatedForAtLeastTwoRestaurants$ = this._ownRestaurants$.pipe(
        map((ownRestaurants) => ownRestaurants.filter((r) => r.roiActivated).length > 2)
    );
    private readonly _user$ = this._store.select(selectUserInfos);
    private readonly _isUserAdmin$ = this._user$.pipe(map((user) => user?.role === Role.ADMIN));
    private readonly _shouldShowRoiNavigation$ = combineLatest({
        isUserAdmin: this._isUserAdmin$,
        isRoiActivatedForAtLeast2Restaurants: this._isRoiActivatedForAtLeastTwoRestaurants$,
    }).pipe(map(({ isUserAdmin, isRoiActivatedForAtLeast2Restaurants }) => isUserAdmin || isRoiActivatedForAtLeast2Restaurants));

    private readonly _shouldShowBoostersNavigation = toSignal(this._shouldShowBoostersNavigation$);
    private readonly _shouldShowRoiNavigation = toSignal(this._shouldShowRoiNavigation$);
    readonly childrenOptions = computed(() => {
        const options: SidenavContentRouteOptions[] = [];
        options.push(this._SEO_OPTIONS);
        options.push(this._E_REPUTATION_OPTIONS);
        options.push(this._SOCIAL_NETWORKS_OPTIONS);
        if (this._shouldShowBoostersNavigation()) {
            options.push(this._BOOSTERS_OPTIONS);
        }
        if (this._shouldShowRoiNavigation()) {
            options.push(this._ROI_OPTIONS);
        }
        return options;
    });

    readonly SvgIcon = SvgIcon;

    private readonly _onNavigationEnd$ = this._router.events.pipe(filter((event) => event.type === EventType.NavigationEnd));
    private readonly _onNavigationEnd = toSignal(this._onNavigationEnd$);

    constructor() {
        this._initEffectRedirection();
    }

    private _initEffectRedirection(): void {
        effect(() => {
            this._onNavigationEnd(); // Used to trigger this effect
            const roiUrl = ['/groups', 'statistics', 'roi'].join('/');
            const isRoiRouteActive = this._router.isActive(roiUrl, ROUTER_LINK_ACTIVE_OPTIONS);
            const shouldRedirectFromRoi = !this._shouldShowRoiNavigation() && isRoiRouteActive;

            const boostersUrl = ['/groups', 'statistics', 'boosters'].join('/');
            const isBoostersRouteActive = this._router.isActive(boostersUrl, ROUTER_LINK_ACTIVE_OPTIONS);
            const shouldRedirectFromBoosters = !this._shouldShowBoostersNavigation() && isBoostersRouteActive;
            if (shouldRedirectFromRoi || shouldRedirectFromBoosters) {
                const defaultRedirectRouterLink = ['/groups', 'statistics', 'seo']; // because it's the one of the routes accessible without condition
                this._router.navigate(defaultRedirectRouterLink ?? []);
            }
        });
    }
}
