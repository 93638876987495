import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    Inject,
    Injector,
    OnInit,
    runInInjectionContext,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { isSameDay, PlatformKey } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    PostDatePickerModalProps,
    PostDatePickerModalResult,
} from ':modules/posts-v2/post-date-picker/post-date-picker-modal/post-date-picker-modal.interface';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CustomMatCalendarHeaderComponent } from ':shared/components/custom-mat-calendar-header/custom-mat-calendar-header.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import {
    capitalize,
    getTimeStringFromDate,
    isPastHour,
    malouChartColorFacebook,
    malouChartColorInstagram,
    malouChartColorMapstr,
    malouChartColorText1,
    malouChartColorTikTok,
} from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

@Component({
    selector: 'app-post-date-picker-modal',
    templateUrl: './post-date-picker-modal.component.html',
    styleUrls: ['./post-date-picker-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDatepickerModule,
        MatIconModule,
        MatSelectModule,
        TranslateModule,
        ButtonComponent,
        SimpleSelectComponent,
        ApplyPurePipe,
        FormatTimePipe,
    ],
    providers: [provideNativeDateAdapter(), EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostDatePickerModalComponent implements OnInit, AfterViewInit {
    readonly timeSelect = viewChild<MatSelect>('timeSelect');
    readonly calendar = viewChild.required<MatCalendar<Date>>('calendar');

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _injector = inject(Injector);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);

    readonly SvgIcon = SvgIcon;
    readonly CustomMatCalendarHeaderComponent = CustomMatCalendarHeaderComponent;

    readonly isPastHour = isPastHour;

    readonly TIMES: string[] = times;
    readonly locale = LocalStorage.getLang();

    readonly now = new Date();

    readonly selectedDate = signal(this.now);
    readonly formattedDate = computed(() => this.displayDate(this.selectedDate()));

    readonly selectedTime = signal(this._getTimeStringFromDate(this.now));

    readonly isDisabled = computed((): boolean => {
        const selectedDate = this.selectedDate();
        const time = this.selectedTime();
        return !selectedDate || !time || isPastHour({ date: selectedDate, hourWithMinute: time });
    });

    private _programmedSocialPostPlatformKeys: { date: Date; platformKeys: PlatformKey[] }[] = [];

    constructor(
        private readonly _dialogRef: MatDialogRef<PostDatePickerModalComponent, PostDatePickerModalResult>,
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: PostDatePickerModalProps
    ) {
        if (this._data.selectedDate) {
            this.selectedDate.set(this._data.selectedDate);
            this.selectedTime.set(this._getTimeStringFromDate(this._data.selectedDate, false));
        }

        // If the selected date is today and the selected time is in the past, set the time to the current time
        effect(
            () => {
                const selectedDate = this.selectedDate();
                const now = new Date();
                if (isSameDay(selectedDate, now) && this.isPastHour({ date: selectedDate, hourWithMinute: this.selectedTime() })) {
                    this.selectedTime.set(this._getTimeStringFromDate(now));
                }
            },
            { allowSignalWrites: true }
        );

        runInInjectionContext(this._injector, () => {
            effect(() => {
                const calendar = this.calendar();
                if (calendar) {
                    calendar.stateChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => this._updateCalendarCellContent());
                }
            });
        });
    }

    ngAfterViewInit(): void {
        this._updateCalendarCellContent();
        this.calendar()._goToDateInView(this.selectedDate(), 'month');
    }

    ngOnInit(): void {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        this._socialPostsService.getProgrammedSocialPostPlatformKeysByDate$(restaurantId).subscribe((result) => {
            this._programmedSocialPostPlatformKeys = result.map((item) => ({ date: new Date(item.date), platformKeys: item.platformKeys }));
            this._updateCalendarCellContent();
        });
    }

    close(): void {
        this._dialogRef.close(undefined);
    }

    save(): void {
        if (this.isDisabled()) {
            return;
        }
        const selectedDate = this.selectedDate();
        const time = this.selectedTime();
        const timeParts = time.split(':');
        selectedDate.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10));
        this._dialogRef.close({ selectedDate });
    }

    displayDate(date: Date): string {
        const formattedDate = DateTime.fromJSDate(date).setLocale(this.locale).toLocaleString(DateTime.DATE_HUGE);
        return capitalize(formattedDate);
    }

    onInputTextChange(value: string | null): void {
        if (!value) {
            return;
        }
        this.selectedTime.set(value);
    }

    openSelect(): void {
        this.timeSelect()?.open();
    }

    private _getTimeStringFromDate(date: Date, rounded = true): string {
        const dateCopy = new Date(date);
        const nearestQuarter = rounded ? Math.ceil(dateCopy.getMinutes() / 15) * 15 : dateCopy.getMinutes();
        dateCopy.setMinutes(nearestQuarter);
        const formattedTime = getTimeStringFromDate(dateCopy).split(' ')[0];
        return formattedTime;
    }

    private _updateCalendarCellContent(): void {
        this._changeDetectorRef.detectChanges();

        const cells = document.querySelectorAll('.mat-calendar-body-cell-container');
        const activeDate = this.calendar()?.activeDate;
        if (!activeDate) {
            return;
        }

        const activeDateTime = DateTime.fromJSDate(activeDate);

        cells.forEach((cell) => {
            const button = cell.querySelector('.mat-calendar-body-cell');
            if (button) {
                const dayString = (button as any).innerText?.trim();
                const day = dayString ? parseInt(dayString, 10) : undefined;
                if (!day) {
                    return;
                }

                const date = activeDateTime.set({ day }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                const now = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                if (date < now) {
                    return;
                }

                // Remove the container if it exists in order to only have one per cell at a time
                const id = `cell-${day}-container`;
                cell.querySelector(`#${id}`)?.remove();

                // Get the platform keys for the date
                const platformKeys = this._getPlatformKeysByDate(date.toJSDate());
                if (platformKeys.length === 0) {
                    return;
                }

                // Create the container and add the platform divs
                const container = document.createElement('div');
                container.id = id;
                container.classList.value = 'absolute top-[100%] left-1/2 -translate-x-1/2 flex gap-x-[1px]';

                const platformKeysWithColor: PlatformKey[] = [];
                platformKeys.forEach((platformKey) => {
                    const color = this._getDivBgColorFromPlatformKey(platformKey);
                    if (color) {
                        platformKeysWithColor.push(platformKey);
                        const platformDiv = document.createElement('div');
                        platformDiv.classList.value = 'w-[3px] h-[3px] rounded-full';
                        platformDiv.style.backgroundColor = color;
                        container.appendChild(platformDiv);
                    }
                });

                // Tooltip
                cell.addEventListener('mouseover', () => {
                    const tooltip = document.createElement('div');

                    tooltip.classList.value =
                        'absolute top-0 z-10 left-1/2 -translate-x-1/2 text-white malou-text-9--medium p-2 w-max rounded-[5px] shadow-md fade-in-out';
                    tooltip.style.backgroundColor = malouChartColorText1;
                    tooltip.style.display = 'ruby';
                    tooltip.id = 'tooltip';
                    tooltip.innerText = platformKeysWithColor
                        .map((platformKey) => this._enumTranslatePipe.transform(platformKey, 'platform_key'))
                        .join(', ');
                    cell.appendChild(tooltip);
                });

                cell.addEventListener('mouseout', () => {
                    const tooltip = cell.querySelector('#tooltip') as HTMLDivElement;

                    if (tooltip) {
                        cell.removeChild(tooltip);
                    }
                });

                cell.appendChild(container);
            }
        });
    }

    private _getPlatformKeysByDate(date: Date): PlatformKey[] {
        return this._programmedSocialPostPlatformKeys.find((item) => isSameDay(item.date, date))?.platformKeys ?? [];
    }

    private _getDivBgColorFromPlatformKey(platformKey: PlatformKey): string | undefined {
        switch (platformKey) {
            case PlatformKey.FACEBOOK:
                return malouChartColorFacebook;
            case PlatformKey.MAPSTR:
                return malouChartColorMapstr;
            case PlatformKey.TIKTOK:
                return malouChartColorTikTok;
            case PlatformKey.INSTAGRAM:
                return malouChartColorInstagram;
            default:
                return undefined;
        }
    }
}
