import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, Observable, tap } from 'rxjs';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsKpisAnswerRateComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-answer-rate/reviews-kpis-answer-rate.component';
import { ReviewsKpisAnswerTimeComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-answer-time/reviews-kpis-answer-time.component';
import { ReviewsKpisAverageRatingComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-average-rating/reviews-kpis-average-rating.component';
import { ReviewsKpisCountEvolutionComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-count-evolution/reviews-kpis-count-evolution.component';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-reviews-kpis',
    templateUrl: './reviews-kpis.component.html',
    styleUrls: ['./reviews-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ReviewsKpisAnswerRateComponent,
        ReviewsKpisAnswerTimeComponent,
        ReviewsKpisCountEvolutionComponent,
        ReviewsKpisAverageRatingComponent,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
    ],
    providers: [PluralTranslatePipe],
})
export class ReviewsKpisComponent implements OnInit {
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);

    readonly Illustration = Illustration;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);
    readonly filters$: Observable<[DatesAndPeriod, PlatformKey[], Restaurant]> = combineLatest([
        this.dates$,
        this.platformKeys$,
        this._restaurantsService.restaurantSelected$,
    ]).pipe(
        filter(
            ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) =>
                isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
        ),
        tap(() => this._resetData()),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly isReviewCountKpiLoading = signal(false);
    readonly isReviewAnswerRateKpiLoading = signal(false);
    readonly isReviewAnswerTimeKpiLoading = signal(false);
    readonly isReviewAverageRatingKpiLoading = signal(false);
    readonly isLoading = computed(
        () =>
            this.isReviewCountKpiLoading() ||
            this.isReviewAnswerRateKpiLoading() ||
            this.isReviewAnswerTimeKpiLoading() ||
            this.isReviewAverageRatingKpiLoading()
    );

    readonly hasReviewCountKpiData = signal(true);
    readonly hasReviewAnswerRateKpiData = signal(true);
    readonly hasReviewAnswerTimeKpiData = signal(true);
    readonly hasReviewAverageRatingKpiData = signal(true);
    readonly hasData = computed(
        () =>
            this.hasReviewCountKpiData() ||
            this.hasReviewAnswerRateKpiData() ||
            this.hasReviewAnswerTimeKpiData() ||
            this.hasReviewAverageRatingKpiData()
    );

    constructor() {
        effect(() => {
            this.isLoadingEvent.emit(this.isLoading());
        });
        effect(() => {
            this.hasDataChange.emit(this.hasData());
        });
    }

    private _resetData(): void {
        this.isReviewCountKpiLoading.set(false);
        this.isReviewAnswerRateKpiLoading.set(false);
        this.isReviewAnswerTimeKpiLoading.set(false);
        this.hasReviewCountKpiData.set(true);
        this.hasReviewAnswerRateKpiData.set(true);
        this.hasReviewAnswerTimeKpiData.set(true);
    }

    ngOnInit(): void {
        this.filters$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(); // Used to trigger the reset of the data when changing filters
    }
}
