<div class="relative h-full w-full overflow-hidden" #containerElement>
    <img
        class="max-w-unset"
        fill
        [ngClass]="{
            'object-none': hasOptionalData(),
            'object-cover': !hasOptionalData(),
        }"
        [priority]="!shouldLazyLoadMedia()"
        [ngSrc]="data().url"
        #imgElement />
</div>
