<div class="flex items-baseline gap-2">
    @if (withLabel()) {
        <span class="malou-text-14--medium">{{ 'date_filter.period' | translate }}</span>
    }
    <button
        class="malou-border-primary min-w-[200px] !px-2.5"
        mat-button
        [matMenuTriggerFor]="datepickermenu"
        [disabled]="disabled()"
        #datepickermenutrigger="matMenuTrigger">
        <div class="flex justify-between">
            <div>
                <span
                    class="relative top-px ml-2"
                    [ngClass]="
                        startDate() && endDate()
                            ? 'malou-text-14--medium malou-color-text-1'
                            : 'malou-text-12--medium text-malou-color-text-2--light'
                    ">
                    {{ selectedDatesPeriod() }}
                </span>
            </div>
            <div class="flex items-center">
                <mat-icon class="malou-color-primary mx-2 !h-4 !w-4" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
            </div>
        </div>
    </button>
    <mat-menu class="malou-mat-menu malou-box-shadow sm:h-[500px]" (closed)="validateDates(true)" #datepickermenu="matMenu">
        <div
            class="flex w-full items-center justify-between border-b border-b-malou-color-border-primary p-4"
            (click)="$event.stopPropagation()">
            <span class="malou-text-14--semibold text-malou-color-text-1">{{ 'date_filter.select_period' | translate }}</span>
            <button
                class="malou-btn-flat !malou-text-12--semibold !h-10 !text-malou-color-primary sm:mt-2"
                mat-flat-button
                (click)="resetDates()">
                {{ 'date_filter.reset' | translate }}
            </button>
        </div>
        <div class="calendar-container flex justify-between md:flex-col" (click)="$event.stopPropagation()">
            <div class="flex w-52 flex-col justify-center p-4 md:w-full">
                <mat-action-list class="!flex h-[254px] flex-col justify-around md:!grid md:h-fit md:grid-cols-2 md:gap-2">
                    @for (currentPeriod of currentPeriodOptions(); track currentPeriod; let index = $index) {
                        <button
                            class="malou-btn-flat btn-normal !flex min-h-[43px] w-full justify-center rounded-lg border-[1px] border-solid border-gray-100 leading-[43px]"
                            mat-btn
                            [attr.data-testid]="'period-date-' + index + '-btn'"
                            [ngClass]="selectedPeriod() === currentPeriod ? 'selected-period' : ''"
                            (click)="$event.stopPropagation(); emitChangePeriod(currentPeriod)">
                            {{ 'date_overview.' + currentPeriod | translate }}
                        </button>
                    }
                </mat-action-list>
            </div>
            @if (!isSmallScreen()) {
                <div class="flex flex-col justify-center">
                    <div class="h-[240px] w-[1px] border-l border-l-malou-color-border-primary"></div>
                </div>
            }
            <div class="date-picker">
                <div class="w-48"></div>
                <mat-calendar
                    class="h-[320px] p-4"
                    [headerComponent]="customHeaderComponent"
                    [startAt]="startDate()"
                    [selected]="selectedRange()"
                    [minDate]="minDate"
                    [maxDate]="maxLeftDate | applyPure: isSmallScreen() : isLeftMaxDateToday() : endDateCalendar()?.activeDate"
                    (selectedChange)="changeDate($event)"
                    #startCalendar></mat-calendar>
            </div>
            <div class="date-picker" [hidden]="isSmallScreen()">
                <mat-calendar
                    class="h-[320px] p-4"
                    id="end-calendar"
                    [startAt]="calendarStartAt()"
                    [headerComponent]="customHeaderComponent"
                    [minDate]="minRightDate | applyPure: startDateCalendar()?.activeDate"
                    [maxDate]="maxRightDate()"
                    [selected]="selectedRange()"
                    (selectedChange)="changeDate($event)"
                    #endCalendar></mat-calendar>
            </div>
        </div>
        @if (shouldShowComparisonPeriodSelector() && canShowComparisonPeriodSelector()) {
            <div class="flex w-full flex-col justify-between gap-y-5 px-4 py-0 pb-4" (click)="$event.stopPropagation()">
                <app-comparison-period-selector
                    [startDate]="startDate()!"
                    [endDate]="endDate()!"
                    [restaurantStartDate]="restaurantStartDate()!"
                    (onComparisonPeriodChange)="onComparisonPeriodChange($event)"></app-comparison-period-selector>
                <div class="mb-5 flex w-full justify-end gap-2">
                    <button class="malou-btn-raised--secondary !malou-text-12 !h-12 sm:mt-2" mat-raised-button (click)="cancelDates()">
                        {{ 'date_filter.cancel' | translate }}
                    </button>
                    <button class="malou-btn-raised--primary !malou-text-12 !h-12 sm:mt-2" mat-raised-button (click)="validateDates()">
                        {{ 'date_filter.validate' | translate }}
                    </button>
                </div>
            </div>
        }
    </mat-menu>
</div>
