<div class="flex flex-col">
    <div class="h-12.5">
        <div class="malou-text-section-title malou-color-text-1">{{ 'statistics.e_reputation.reviews_kpis.title' | translate }}</div>
    </div>
    @if (hasData()) {
        <div class="grid grid-cols-4 gap-4 sm:!grid-cols-1 xl:grid-cols-2">
            <app-reviews-kpis-count-evolution
                [filters$]="filters$"
                (hasDataChange)="hasReviewCountKpiData.set($event)"
                (isLoadingEvent)="isReviewCountKpiLoading.set($event)">
            </app-reviews-kpis-count-evolution>

            <app-reviews-kpis-answer-rate
                [filters$]="filters$"
                (hasDataChange)="hasReviewAnswerRateKpiData.set($event)"
                (isLoadingEvent)="isReviewAnswerRateKpiLoading.set($event)">
            </app-reviews-kpis-answer-rate>

            <app-reviews-kpis-answer-time
                [filters$]="filters$"
                (hasDataChange)="hasReviewAnswerTimeKpiData.set($event)"
                (isLoadingEvent)="isReviewAnswerTimeKpiLoading.set($event)">
            </app-reviews-kpis-answer-time>

            <app-reviews-kpis-average-rating
                [filters$]="filters$"
                (hasDataChange)="isReviewAverageRatingKpiLoading.set($event)"
                (isLoadingEvent)="hasReviewAverageRatingKpiData.set($event)">
            </app-reviews-kpis-average-rating>
        </div>
    } @else {
        <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
    }
</div>

<ng-template #noDataTemplate>
    <div
        class="flex !h-[100px] flex-col items-center rounded-[10px] border border-malou-color-border-primary bg-malou-color-background-white py-6">
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
        <span class="malou-text-10--regular">{{ 'statistics.e_reputation.reviews_kpis.no_data' | translate }}</span>
    </div>
</ng-template>
