import { KeywordSearchImpressionsType } from '@malou-io/package-utils';

export interface KeywordSearchImpressionsDto {
    id: string;
    restaurantId: string;
    month: number;
    year: number;
    keywordSearch: string;
    value: number;
    type: KeywordSearchImpressionsType;
    relatedKeywordIds: string[];
}

export interface KeywordSearchImpressionsInsightsDto {
    year: number;
    month: number;
    [KeywordSearchImpressionsType.BRANDING]: number;
    [KeywordSearchImpressionsType.DISCOVERY]: number;
}

export interface TopKeywordSearchImpressionsDto {
    [KeywordSearchImpressionsType.BRANDING]: {
        keywordSearch: string;
        value: number;
        type: KeywordSearchImpressionsType.BRANDING;
    }[];
    [KeywordSearchImpressionsType.DISCOVERY]: {
        keywordSearch: string;
        value: number;
        type: KeywordSearchImpressionsType.DISCOVERY;
    }[];
}
