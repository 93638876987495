import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { MalouComparisonPeriod } from '@malou-io/package-utils';

import {
    GmbDiscoveriesData,
    GmbImpressionsChartComponent,
} from ':modules/statistics/seo/gmb-insights/gmb-impressions-v2/gmb-impressions-chart/gmb-impressions-chart.component';
import { GmbInsightsChartData } from ':modules/statistics/seo/models/gmb-insight-chart-data';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-gmb-impressions-v2',
    standalone: true,
    imports: [
        GmbImpressionsChartComponent,
        AsyncPipe,
        MatIconModule,
        MatTooltipModule,
        NgTemplateOutlet,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ShortNumberPipe,
        NumberEvolutionComponent,
        SelectComponent,
        ApplyPurePipe,
        LowerCasePipe,
    ],
    templateUrl: './gmb-impressions-v2.component.html',
    styleUrl: './gmb-impressions-v2.component.scss',
})
export class GmbImpressionsV2Component implements OnInit {
    readonly dailyInsightsChartData = input.required<GmbInsightsChartData>();
    readonly dailyPreviousInsightsChartData = input.required<GmbInsightsChartData>();
    readonly weeklyInsightsChartData = input.required<GmbInsightsChartData>();
    readonly weeklyPreviousInsightsChartData = input.required<GmbInsightsChartData>();
    readonly monthlyInsightsChartData = input.required<GmbInsightsChartData>();
    readonly monthlyPreviousInsightsChartData = input.required<GmbInsightsChartData>();

    readonly showViewByTextInsteadOfSelector = input<boolean>();
    readonly viewBy = input<ViewBy>();
    readonly hiddenDatasetIndexes = input<number[]>([]);

    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly ViewBy = ViewBy;

    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);

    readonly gmbDiscoveriesData = signal<GmbDiscoveriesData>({} as GmbDiscoveriesData);
    readonly dateLabels = signal<Date[]>([]);

    // Evolution values
    readonly impressionsEvolutionPercentage = computed(() => {
        const currentImpressions = this.dailyInsightsChartData().totalImpressions;
        const previousImpressions = this.dailyPreviousInsightsChartData().totalImpressions;
        if (currentImpressions === undefined || previousImpressions === undefined || previousImpressions === 0) {
            return null;
        } else {
            return ((currentImpressions - previousImpressions) / previousImpressions) * 100;
        }
    });

    readonly comparisonPeriodKey = computed(() => {
        const comparisonPeriod = this.dailyPreviousInsightsChartData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD;
        return `date_filter.comparison_period.${comparisonPeriod}`;
    });

    readonly comparedToKey = computed(() => {
        const comparisonPeriod = this.dailyPreviousInsightsChartData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD;
        return `statistics.common.compared_to.${comparisonPeriod}`;
    });

    ngOnInit(): void {
        this._initViewBySubject();
        this.viewByFilterSubject$.pipe(takeUntil(this.killSubscriptions$)).subscribe((viewByFilter) => {
            this.viewByChange.emit(viewByFilter);
            switch (viewByFilter) {
                case ViewBy.MONTH:
                    this.dateLabels.set(this.monthlyInsightsChartData().dates);
                    this.gmbDiscoveriesData.set(
                        this._getGmbDiscoveriesData(this.monthlyInsightsChartData(), this.monthlyPreviousInsightsChartData())
                    );
                    break;
                case ViewBy.WEEK:
                    this.dateLabels.set(this.weeklyInsightsChartData().dates);
                    this.gmbDiscoveriesData.set(
                        this._getGmbDiscoveriesData(this.weeklyInsightsChartData(), this.weeklyPreviousInsightsChartData())
                    );
                    break;
                default:
                case ViewBy.DAY:
                    this.dateLabels.set(this.dailyInsightsChartData().dates);
                    this.gmbDiscoveriesData.set(
                        this._getGmbDiscoveriesData(this.dailyInsightsChartData(), this.dailyPreviousInsightsChartData())
                    );
                    break;
            }
        });
    }

    private _getGmbDiscoveriesData(
        gmbInsightsChartData: GmbInsightsChartData,
        previousGmbInsightsChartData: GmbInsightsChartData
    ): GmbDiscoveriesData {
        return {
            impressionsMaps: gmbInsightsChartData.impressionsMaps,
            impressionsSearch: gmbInsightsChartData.impressionsSearch,
            previousImpressionsMaps: previousGmbInsightsChartData.impressionsMaps,
            previousImpressionsSearch: previousGmbInsightsChartData.impressionsSearch,
            previousPeriodDates: previousGmbInsightsChartData.dates,
            comparisonPeriod: previousGmbInsightsChartData.comparisonPeriod,
        };
    }

    private _initViewBySubject(): void {
        const viewBy = this.viewBy();
        if (viewBy) {
            this.viewByFilterSubject$.next(viewBy);
        }
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');
}
