import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
    GetPlatformsByRestaurantIdsBodyDto,
    GetPlatformsForRestaurantsBodyDto,
    PlatformDto,
    SendMapstrReminderBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { PlatformMedia } from ':modules/media/utils/platform-media-downloader.service';
import { selectCurrentPlatformKeys, selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult, Platform, PlatformSearchApiResponse } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class PlatformsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/platforms`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store
    ) {}

    attachCredential(platformId: string, credentialId: string): Observable<ApiResult<PlatformDto>> {
        return this._http.patch<ApiResult<PlatformDto>>(`${this.API_BASE_URL}/${platformId}/credentials/${credentialId}`, {});
    }

    upsert(restaurantId: string, platform: any, credentialId?: string): Observable<ApiResult<Platform>> {
        return this._http.patch<ApiResult<Platform>>(
            this.API_BASE_URL,
            {
                restaurant_id: restaurantId,
                platform: platform,
                credentialId,
            },
            { withCredentials: true }
        );
    }

    delete(id: string): Observable<any> {
        return this._http.delete(`${this.API_BASE_URL}/${id}`, { withCredentials: true });
    }

    pullOverview(restaurantId: string, platformKey: string, switchPlatform = false): Observable<ApiResult<PlatformDto>> {
        return this._http.get<ApiResult<PlatformDto>>(`${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}/pull`, {
            withCredentials: true,
            params: objectToSnakeCase({ switchPlatform }),
        });
    }

    getPlatformAndUpsert(platformKey: string, restaurantId: string): Observable<ApiResult<Platform>> {
        return this._http.get(`${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}/upsert`).pipe(
            map((res: ApiResult) => {
                res.data = new Platform(res.data);
                return res;
            })
        );
    }

    getPlatform(platformKey: string, restaurantId: string): Observable<ApiResultV2<PlatformDto>> {
        return this._http.get<ApiResultV2<PlatformDto>>(`${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}`);
    }

    lockField(platformId: string, fieldKey: string): Observable<ApiResult> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}/${platformId}/${fieldKey}/lock`, {}).pipe(
            map((res: ApiResult) => {
                res.data = new Platform(res.data);
                return res;
            })
        );
    }

    unlockField(platformId: string, fieldKey: string): Observable<ApiResult> {
        return this._http.put<ApiResult>(`${this.API_BASE_URL}/${platformId}/${fieldKey}/unlock`, {}).pipe(
            map((res: ApiResult) => {
                res.data = new Platform(res.data);
                return res;
            })
        );
    }

    deleteByRestaurantId(restaurantId: string, platformKey: string): Observable<any> {
        return this._http.delete(`${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}`, { withCredentials: true });
    }

    getPlatformsForRestaurant(restaurantId: string): Observable<ApiResult<Platform[]>> {
        return this._http.get<ApiResult<Platform[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`).pipe(
            map((res: ApiResult<Platform[]>) => {
                res.data = res.data.map((data) => new Platform(data));
                return res;
            })
        );
    }

    getDisconnectedPlatformsForRestaurant(restaurantId: string): Observable<ApiResult<Platform[]>> {
        return this._http.get<ApiResult<Platform[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/disconnected`).pipe(
            map((res: ApiResult<Platform[]>) => {
                res.data = res.data.map((data) => new Platform(data));
                return res;
            })
        );
    }

    getPlatformsForRestaurants(body: GetPlatformsForRestaurantsBodyDto): Observable<ApiResultV2<PlatformDto[]>> {
        return this._http.post<ApiResultV2<PlatformDto[]>>(`${this.API_BASE_URL}/restaurants`, body);
    }

    getPlatformsForMultipleRestaurants(body: GetPlatformsByRestaurantIdsBodyDto): Observable<ApiResult<Platform[]>> {
        if (body.restaurantIds.length === 0) {
            const platforms: Platform[] = [];
            return of({ data: platforms, msg: 'No restaurant IDs provided' });
        }

        return this._http.post<ApiResult<Platform[]>>(`${this.API_BASE_URL}/search`, body, { withCredentials: true }).pipe(
            map((res: ApiResult<Platform[]>) => {
                res.data = res.data.map((data) => new Platform(data));
                return res;
            })
        );
    }

    getSocialIds({
        restaurantId,
        platformKey,
        credentialId,
        socialId,
    }: {
        restaurantId: string | null;
        platformKey: string;
        credentialId?: string;
        socialId?: string;
    }): Observable<PlatformSearchApiResponse> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId, credentialId, socialId }));
        return this._http
            .get(`${this.API_BASE_URL}/${platformKey}/search`, { params })
            .pipe(map((res: { data: PlatformSearchApiResponse }) => res.data));
    }

    scrapPlatformEndpoint(platformKey: string, endpoint: string): Observable<any> {
        return this._http.post(`${this.API_BASE_URL}/${platformKey}/scrap`, { endpoint });
    }

    getSocialNetworksPlatforms(): Observable<Platform[]> {
        return this._store.select(selectCurrentPlatforms).pipe(
            filter((platforms) => !!platforms),
            map((platforms) => platforms.filter((platform) => PlatformDefinitions.getSocialPlatformKeys().includes(platform.key)))
        );
    }

    getConnectedPlatformsAcceptingStories(): Observable<Platform[]> {
        return this._store.select(selectCurrentPlatforms).pipe(
            filter((platforms) => !!platforms),
            map((platforms) => {
                const platformKeysHandleStories = PlatformDefinitions.getPlatformKeysWithStories();
                return platforms.filter((platform) => platformKeysHandleStories.includes(platform.key));
            })
        );
    }

    getConnectedMessagingPlatforms(): Observable<PlatformKey[]> {
        return this._store
            .select(selectCurrentPlatforms)
            .pipe(take(2))
            .pipe(
                filter((platforms: Platform[]) => platforms?.length > 0),
                map((platforms: Platform[]) => {
                    const connectedPlatformKeys = platforms.map((platform) => platform.key);
                    return PlatformDefinitions.getPlatformKeysWithMessages().filter((platformKey) => {
                        const isConnected = connectedPlatformKeys.includes(platformKey);
                        return isConnected;
                    });
                })
            );
    }

    getLogoAndCoverForPlatform(
        platformKey: string,
        socialId: string,
        credentialId: string
    ): Observable<ApiResult<{ logo: PlatformMedia; cover: PlatformMedia }>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ credentialId, socialId }));
        return this._http.get<ApiResult<{ logo: PlatformMedia; cover: PlatformMedia }>>(`${this.API_BASE_URL}/${platformKey}/media`, {
            params,
            withCredentials: true,
        });
    }

    /* Public endpoint */
    getPlatformSocialLink(
        restaurantId: string,
        platformKey: string
    ): Observable<ApiResult<{ _id: string; key: PlatformKey; socialLink?: string; socialId: string; name: string }>> {
        return this._http.get<ApiResult<{ _id: string; key: PlatformKey; socialLink?: string; socialId: string; name: string }>>(
            `${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}/social_link`
        );
    }

    index(platformsKeys: string[]): Observable<ApiResult<Platform[]>> {
        return this._http.get<ApiResult<Platform[]>>(`${this.API_BASE_URL}/index?platforms_keys=${platformsKeys}`);
    }

    isPlatformConnected$(platformKey: PlatformKey): Observable<boolean> {
        return this._store.select(selectCurrentPlatformKeys).pipe(
            filter((currentPlatformKeys) => !!currentPlatformKeys),
            map((currentPlatformKeys) => currentPlatformKeys.includes(platformKey))
        );
    }

    isOneOfThesePlatformsConnected$(platformKeys: PlatformKey[]): Observable<boolean> {
        return this._store.select(selectCurrentPlatformKeys).pipe(
            filter((currentPlatformKeys) => !!currentPlatformKeys),
            map((currentPlatformKeys) => platformKeys.some((platformKey) => currentPlatformKeys.includes(platformKey)))
        );
    }

    sendMapstrReminder(body: SendMapstrReminderBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/send-mapstr-reminder`, body);
    }

    getProfilePictureUrl(restaurantId: string, platformKey: PlatformKey): Observable<ApiResultV2<{ profilePictureUrl: string }>> {
        return this._http.get<ApiResultV2<{ profilePictureUrl: string }>>(
            `${this.API_BASE_URL}/${platformKey}/restaurants/${restaurantId}/profile-picture-url`
        );
    }
}
