import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const getStoreLocatorStoresParamsValidator = z.object({
    organizationId: objectIdValidator,
});

export type GetStoreLocatorStoresParamsDto = z.infer<typeof getStoreLocatorStoresParamsValidator>;

// -------------------------------------------------------------------------------
