import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { compact, omitBy, partition, pickBy } from 'lodash';
import { combineLatest, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { GetStoredInsightsAggregatedRequestInputBodyDto, StoredInsightsAggregatedResponseDto } from '@malou-io/package-dto';
import { AggregationType, InsightsChart, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { GmbAggregatedInsightsChartData } from ':modules/aggregated-statistics/seo/models/gmb-aggregated-insights-chart-data';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsService } from ':modules/statistics/insights.service';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { GMB_METRICS } from ':shared/interfaces';
import { DatesAndPeriod, LightRestaurant, Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class GmbInsightsContext {
    private readonly _store = inject(Store);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _translate = inject(TranslateService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly dates$: Observable<DatesAndPeriod> = this._store
        .select(AggregatedStatisticsSelector.selectDatesFilter)
        .pipe(takeUntilDestroyed(this._destroyRef));

    readonly restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        takeUntilDestroyed(this._destroyRef)
    );

    readonly warningTooltip = signal<string | null>(null);
    readonly errorTooltip = signal<string | null>(null);
    readonly isLoading = signal<boolean>(true);
    readonly hasData = signal<boolean>(false);

    private readonly _currentInsights = signal<StoredInsightsAggregatedResponseDto>({} as StoredInsightsAggregatedResponseDto);
    private readonly _previousInsights = signal<StoredInsightsAggregatedResponseDto>({} as StoredInsightsAggregatedResponseDto);

    readonly currentActionsChartData = signal<GmbAggregatedInsightsChartData>({} as GmbAggregatedInsightsChartData);
    readonly previousActionsChartData = signal<GmbAggregatedInsightsChartData>({} as GmbAggregatedInsightsChartData);

    readonly currentImpressionsChartData = signal<GmbAggregatedInsightsChartData>({} as GmbAggregatedInsightsChartData);
    readonly previousImpressionsChartData = signal<GmbAggregatedInsightsChartData>({} as GmbAggregatedInsightsChartData);

    readonly gmbAggregatedInsights$ = combineLatest([this.restaurants$, this.dates$]).pipe(
        tap(() => this.isLoading.set(true)),
        filter(([restaurants, _dates]) => restaurants?.length > 0),
        map(([restaurants, dates]) => {
            const [businessRestaurant, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
            this.warningTooltip.set(this._computeWarningTooltip(businessRestaurant));
            const lightRestaurants = nonBusinessRestaurants.map((r) => LightRestaurant.fromRestaurant(r));
            return [lightRestaurants, dates];
        }),
        filter(([_restaurants, dates]: [LightRestaurant[], DatesAndPeriod]) => {
            const { startDate, endDate } = dates;
            return !!startDate && !!endDate;
        }),
        switchMap(([restaurants, dates]) => {
            const requestBody: GetStoredInsightsAggregatedRequestInputBodyDto = {
                restaurantIds: restaurants.map((r) => r.id),
                platformKeys: [PlatformKey.GMB],
                startDate: (dates.startDate as Date).toISOString(),
                endDate: (dates.endDate as Date).toISOString(),
                metrics: GMB_METRICS.map((m) => m.metric),
                aggregationType: AggregationType.TOTAL,
            };

            return forkJoin([
                this._insightsService.getStoredInsightsAggregated(requestBody),
                this._insightsService.getStoredInsightsAggregated({ ...requestBody, previousPeriod: true }),
                of(restaurants),
            ]);
        }),
        map(
            ([currentInsights, previousInsights, restaurants]: [
                StoredInsightsAggregatedResponseDto,
                StoredInsightsAggregatedResponseDto,
                LightRestaurant[],
            ]) => {
                const currentInsightsInError = pickBy(currentInsights, (value) => value?.[PlatformKey.GMB]?.hasData === false);
                const restaurantIdsInError = Object.keys(currentInsightsInError);
                const restaurantsInError = restaurantIdsInError.map((restaurantId) =>
                    restaurants.find((restaurant) => restaurant.id === restaurantId)
                );
                this.errorTooltip.set(this._computeErrorTooltip(compact(restaurantsInError)));
                return [
                    omitBy(currentInsights, (_value, key) => restaurantIdsInError.includes(key)),
                    omitBy(previousInsights, (_value, key) => restaurantIdsInError.includes(key)),
                    restaurants.filter((restaurant) => !restaurantIdsInError.includes(restaurant.id)),
                ];
            }
        ),
        tap(
            ([currentInsights, previousInsights, restaurants]: [
                StoredInsightsAggregatedResponseDto,
                StoredInsightsAggregatedResponseDto,
                LightRestaurant[],
            ]) => {
                this._currentInsights.set(currentInsights);
                this._previousInsights.set(previousInsights);

                this.setActionChartDataSorted(restaurants, ChartSortBy.DESC);
                this.setImpressionChartDataSorted(restaurants, ChartSortBy.DESC);

                this.isLoading.set(false);
            }
        )
    );

    setActionChartDataSorted(restaurants: LightRestaurant[], sortBy: ChartSortBy): void {
        this.currentActionsChartData.set(
            new GmbAggregatedInsightsChartData({
                data: this._currentInsights(),
                restaurants,
                sortBy,
                chart: InsightsChart.AGGREGATED_ACTIONS,
            })
        );
        this.previousActionsChartData.set(
            new GmbAggregatedInsightsChartData({
                data: this._previousInsights(),
                restaurants: restaurants,
                sortBy,
                chart: InsightsChart.AGGREGATED_ACTIONS,
            })
        );
    }

    setImpressionChartDataSorted(restaurants: LightRestaurant[], sortBy: ChartSortBy): void {
        this.currentImpressionsChartData.set(
            new GmbAggregatedInsightsChartData({
                data: this._currentInsights(),
                restaurants: restaurants,
                sortBy,
                chart: InsightsChart.AGGREGATED_APPARITIONS,
            })
        );

        this.previousImpressionsChartData.set(
            new GmbAggregatedInsightsChartData({
                data: this._previousInsights(),
                restaurants: restaurants,
                sortBy,
                chart: InsightsChart.AGGREGATED_APPARITIONS,
            })
        );
    }

    private _computeWarningTooltip(restaurants: Restaurant[]): string | null {
        if (!restaurants.length) {
            return null;
        }
        const restaurantsLabel = restaurants.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _computeErrorTooltip(restaurants: LightRestaurant[]): string | null {
        if (!restaurants.length) {
            return null;
        }
        const restaurantsLabel = restaurants.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_fetching_error', {
            restaurants: restaurantsLabel,
        });
    }
}
