<div class="flex flex-col gap-2 rounded-[5px] border border-malou-color-border-primary bg-white p-3 font-[Helvetica]">
    <div class="flex items-center gap-x-2">
        @if (profilePicture(); as profilePicture) {
            <img class="h-[32px] w-[32px] rounded-full border border-malou-color-border-primary" [src]="profilePicture" />
        }
        <div class="flex flex-col gap-[1px]">
            <div class="text-[12px] font-semibold text-[#050505]">{{ username() }}</div>
            <div class="flex gap-x-[3px] text-[10px] text-[#65676B]">
                @if (date(); as date) {
                    <div>{{ date | formatDate: 'mediumDate' }}</div>
                }
                @if (locationCity(); as locationCity) {
                    <div>·</div>
                    <div>{{ locationCity }}</div>
                }
                <div>·</div>
                <img
                    class="mt-[1px] h-[12px] w-[12px]"
                    [src]="'world' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
            </div>
        </div>
    </div>

    <div class="text-[13px] text-[#050505]" [innerHTML]="formattedText() | htmlTag"></div>

    <div class="flex flex-row-reverse flex-wrap-reverse gap-[2px]">
        @for (media of displayedMedias(); let index = $index; track media.id) {
            <div
                class="relative aspect-square min-h-0 w-[26%] flex-grow"
                [ngClass]="{
                    '!w-[34%]': [3, 4].includes(displayedMedias().length),
                    '!aspect-[2/1]': displayedMedias().length === 3 && index === 2,
                }">
                <app-image-viewer
                    [data]="{
                        url: media.thumbnail1024OutsideUrl,
                        dimensions: media.thumbnail1024OutsideDimensions,
                        transformData: media.transformData,
                    }"></app-image-viewer>

                @if (index === 0 && mediaNotShownCount() > 0) {
                    <div
                        class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/30 text-[18px] font-semibold text-white">
                        +{{ mediaNotShownCount() }}
                    </div>
                }
            </div>
        }
        @if (displayedMedias().length === 0) {
            <div class="flex aspect-square w-full items-center justify-center bg-malou-color-background-dark">
                <img class="w-full max-w-[250px]" [src]="'default_post' | imagePathResolver" />
            </div>
        }
    </div>

    <div class="flex flex-col gap-1">
        <div class="flex items-center gap-x-1">
            <div class="mt-[-2px] flex">
                <div class="z-10 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white">
                    <img
                        class="h-[15px] w-[15px]"
                        [src]="'likes' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'svg' }" />
                </div>
                <div class="ml-[-4px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white">
                    <img
                        class="h-[15px] w-[15px]"
                        [src]="'hearts' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'svg' }" />
                </div>
            </div>
            <div class="text-[11px] text-[#65676B]">
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook.likes' | translate }}
            </div>
        </div>

        <div class="flex justify-around border-b border-t border-[#CED0D4] py-1 text-[11px] font-medium text-[#65676B]">
            <div class="flex items-center gap-x-1">
                <img
                    class="mt-[-3px] h-[11px] opacity-60"
                    [src]="'like' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                <div>
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook.like' | translate }}
                </div>
            </div>
            <div class="flex items-center gap-x-1">
                <img
                    class="mt-[-3px] h-[11px] opacity-60"
                    [src]="'comment' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                <div>
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook.comment' | translate }}
                </div>
            </div>
            <div class="flex items-center gap-x-1">
                <img
                    class="mt-[-3px] h-[11px] opacity-60"
                    [src]="'share' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                <div>
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook.share' | translate }}
                </div>
            </div>
        </div>

        <div class="flex items-center gap-x-1">
            <div class="relative">
                @if (profilePicture(); as profilePicture) {
                    <img class="h-[22px] w-[22px] rounded-full border border-malou-color-border-primary" [src]="profilePicture" />
                } @else {
                    <div class="h-[22px] w-[22px] rounded-full border border-malou-color-border-primary bg-[#f0f2f5]"></div>
                }
                <div class="absolute bottom-0 right-0 h-2 w-2 rounded-full border border-white bg-malou-color-text-green"></div>
            </div>

            <div class="mt-[2px] flex h-[22px] grow items-center justify-between rounded-full bg-[#f0f2f5] px-2">
                <div class="text-[11px] text-[#65676B]">
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook.write_a_comment' | translate }}
                </div>
                <div class="flex items-center gap-x-[5px]">
                    <img
                        class="h-[9px]"
                        [src]="'emojis' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                    <img
                        class="h-[9px]"
                        [src]="'photos' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                    <img
                        class="h-[9px]"
                        [src]="'gif' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                    <img
                        class="h-[9px]"
                        [src]="'stickers' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
                </div>
            </div>
        </div>
    </div>
</div>
