import { z } from 'zod';

import { PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { restaurantIdParamsTransformValidator } from '../common';
import { SocialPostMediaDto } from './get-social-posts.dto';

export interface FeedItemDto {
    postId: string;
    media: SocialPostMediaDto;
    published: PostPublicationStatus;
    postType: PostType;
    plannedPublicationDate: Date | null;
    socialCreatedAt?: Date;
    updatedAt: Date;
    sortDate?: Date;
}

export const getFeedQueryValidator = z
    .object({
        cursor: z.string().datetime().nullish(),
        limit: z.coerce.number().int().positive().nullish(),
    })
    .transform((data) => ({
        cursor: data.cursor ? new Date(data.cursor) : null,
        limit: data.limit ?? null,
    }));

export type GetFeedQueryDto = z.infer<typeof getFeedQueryValidator>;

export const getFeedParamsValidator = restaurantIdParamsTransformValidator;
export type GetFeedParamsDto = z.infer<typeof getFeedParamsValidator>;
