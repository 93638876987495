import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { E2E_USER } from ':core/constants/e2e-config';
import { RestaurantsService } from ':core/services/restaurants.service';
import { FromStoreToUserFiltersMapper } from ':core/user-filters/mapper/from-store-to-user-filters.mapper';
import * as UserFiltersActions from ':core/user-filters/store/user-filters.actions';
import { UserFiltersService } from ':core/user-filters/user-filters.service';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { ReviewsDisplayMode } from ':modules/reviews/store/reviews.reducer';
import * as ReviewsSelectors from ':modules/reviews/store/reviews.selectors';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';

@Injectable()
export class UserFiltersEffects {
    readonly loadUserAggregatedFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserFiltersActions.initializeAggregatedFilters),
                switchMap((action) =>
                    this._userFiltersService.getAggregatedUserFilters(action.userId).pipe(
                        tap((data) => {
                            this._store.dispatch(AggregatedStatisticsActions.initializeState({ data: data.aggregatedStatisticsFilters }));
                            this._store.dispatch(ReviewsActions.initializeState({ data: data.aggregatedReviewsFilters }));
                            return of({ type: '[App] Initialize Aggregated filters State Success' });
                        }),
                        catchError((error) => of({ type: '[App] Initialize Aggregated State Failure', error }))
                    )
                )
            ),
        {
            dispatch: false,
        }
    );

    readonly loadUserRestaurantFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(UserFiltersActions.initializeRestaurantFilters),
                switchMap((action) =>
                    this._userFiltersService.getUserRestaurantFilters(action.userId, action.restaurantId).pipe(
                        tap((data) => {
                            this._store.dispatch(StatisticsActions.initializeState({ data: data.statisticsFilters }));
                            this._store.dispatch(ReviewsActions.initializeRestaurantFiltersState({ data: data.reviewsFilters }));
                            return of({ type: '[App] Initialize User Restaurant State Success' });
                        }),
                        catchError((error) => of({ type: '[App] Initialize User Restaurant State Failure', error }))
                    )
                )
            ),
        {
            dispatch: false,
        }
    );

    readonly editAggregatedStatisticsFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    AggregatedStatisticsActions.editDates,
                    AggregatedStatisticsActions.editPlatforms,
                    AggregatedStatisticsActions.editRestaurants,
                    AggregatedStatisticsActions.editRoiRestaurants,
                    AggregatedStatisticsActions.editTimeScale,
                    AggregatedStatisticsActions.editTotems
                ),
                withLatestFrom(
                    this._store.pipe(select(selectUserInfos)),
                    this._store.pipe(select(AggregatedStatisticsSelectors.selectFilters)),
                    this._store.pipe(select(AggregatedStatisticsSelectors.selectIsFiltersLoaded))
                ),
                switchMap(([_action, user, filters, isFiltersLoaded]) => {
                    const userId = user?._id;
                    if (user?.email === E2E_USER.email) {
                        return of({ type: '[App] Update Filters Failure', error: 'E2E user cannot edit filters' });
                    }
                    if (!userId) {
                        return of({ type: '[App] Update Filters Failure', error: 'No user id found' });
                    }
                    if (!isFiltersLoaded) {
                        return of({ type: '[App] Update Filters Failure', error: 'Filters not loaded yet' });
                    }
                    return this._userFiltersService.updateAggregatedStatisticsFilters(
                        userId,
                        this._fromStoreToUserFilterMapper.mapToAggregatedStatisticsFiltersFromState(filters)
                    );
                })
            ),
        { dispatch: false }
    );

    readonly editAggregatedReviewsFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    ReviewsActions.editReviewsFilters,
                    ReviewsActions.editReviewsFiltersSearch,
                    ReviewsActions.editSemanticAnalysisToggle,
                    ReviewsActions.resetReviewsFiltersDates,
                    ReviewsActions.editReviewsFiltersRatings,
                    ReviewsActions.editReviewsFiltersPlatforms,
                    ReviewsActions.toggleReviewsFiltersPlatform,
                    ReviewsActions.toggleReviewsFiltersStatus,
                    ReviewsActions.toggleReviewsFiltersComment,
                    ReviewsActions.toggleReviewsFiltersArchive,
                    ReviewsActions.editSelectablePlatforms,
                    ReviewsActions.toggleArchived,
                    ReviewsActions.editReviewsFiltersDates,
                    ReviewsActions.editRestaurants,
                    ReviewsActions.clearFilters
                ),
                withLatestFrom(
                    this._store.pipe(select(selectUserInfos)),
                    this._store.pipe(select(ReviewsSelectors.selectReviewsFilters)),
                    this._store.pipe(select(ReviewsSelectors.selectIsAggregatedReviewsFiltersLoaded)),
                    this._store.pipe(select(ReviewsSelectors.selectIsRestaurantFiltersLoaded)),
                    this._store.pipe(select(ReviewsSelectors.selectReviewsCurrentView))
                ),
                switchMap(([_action, user, filters, isAggregatedReviewsFiltersLoaded, isRestaurantReviewsFiltersLoaded, currentView]) => {
                    const userId = user?._id;
                    if (!userId) {
                        return of({ type: '[App] Update Filters Failure', error: 'No user id found' });
                    }
                    if (user?.email === E2E_USER.email) {
                        return of({ type: '[App] Update Filters Failure', error: 'E2E user cannot edit filters' });
                    }
                    if (currentView === ReviewsDisplayMode.AGGREGATED_RESTAURANTS) {
                        if (!isAggregatedReviewsFiltersLoaded) {
                            return of({ type: '[App] Update Aggregated Reviews Filters Failure', error: 'Filters not loaded yet' });
                        }
                        return this._userFiltersService.updateAggregatedReviewsFilters(
                            userId,
                            this._fromStoreToUserFilterMapper.mapToAggregatedReviewsFiltersFromState(filters)
                        );
                    }
                    if (!isRestaurantReviewsFiltersLoaded || !this._restaurantsService.currentRestaurant._id) {
                        return of({ type: '[App] Update Restaurant Reviews Filters Failure', error: 'Filters not loaded yet' });
                    }
                    return this._userFiltersService.updateUserRestaurantReviewsFilters(
                        userId,
                        this._restaurantsService.currentRestaurant._id,
                        this._fromStoreToUserFilterMapper.mapToUserRestaurantReviewsFiltersFromState(filters)
                    );
                })
            ),
        { dispatch: false }
    );

    readonly editUserRestaurantStatisticsFilters$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StatisticsActions.editDates,
                    StatisticsActions.editPlatforms,
                    StatisticsActions.editTotems,
                    StatisticsActions.editTimeScale,
                    StatisticsActions.resetState
                ),
                withLatestFrom(
                    this._store.pipe(select(selectUserInfos)),
                    this._store.pipe(select(StatisticsSelectors.selectFilters)),
                    this._store.pipe(select(StatisticsSelectors.selectIsFiltersLoaded))
                ),
                switchMap(([_action, user, filters, isFiltersLoaded]) => {
                    const userId = user?._id;

                    if (_action.type === StatisticsActions.resetState.type) {
                        return of({ type: '[App] Should Not Update Filters', error: 'Selecting Restaurant' });
                    }
                    if (!userId) {
                        return of({ type: '[App] Update Filters Failure', error: 'No user id found' });
                    }
                    if (user?.email === E2E_USER.email) {
                        return of({ type: '[App] Update Filters Failure', error: 'E2E user cannot edit filters' });
                    }
                    if (!isFiltersLoaded) {
                        return of({ type: '[App] Update Filters Failure', error: 'Filters not loaded yet' });
                    }
                    return this._userFiltersService.updateUserRestaurantStatisticsFilters(
                        userId,
                        this._restaurantsService.currentRestaurant._id,
                        this._fromStoreToUserFilterMapper.mapToUserRestaurantStatisticsFiltersFromState(filters)
                    );
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _userFiltersService: UserFiltersService,
        private readonly _store: Store,
        private readonly _fromStoreToUserFilterMapper: FromStoreToUserFiltersMapper,
        private readonly _restaurantsService: RestaurantsService
    ) {}
}
