import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, Plugin, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { isValidDate, MalouComparisonPeriod } from '@malou-io/package-utils';

import { PeriodLegendComponent } from ':shared/components/period-legend/period-legend.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    computePointsRadius,
    DEFAULT_DASHED_LINE_OPTIONS,
    formatViewByDate,
    malouChartColorBluePurple,
    malouChartColorBluePurpleLight30Percent,
    malouChartColorPink,
    malouChartColorPinkLight30Percent,
    malouChartColorPinkLight60Percent,
    malouChartColorPurpleLight60Percent,
    toggleVisibilityOnMultipleDatasets,
} from ':shared/helpers';
import { comparisonLegendPlugin, ComparisonLegendPluginMetadata } from ':shared/helpers/chart-comparison-legend-plugin';
import { SelectionModel } from ':shared/helpers/selection-model';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type LineChartType = Extract<ChartType, 'line'>;

export interface GmbDiscoveriesData {
    impressionsMaps: ChartDataArray;
    impressionsSearch: ChartDataArray;
    previousImpressionsMaps: ChartDataArray;
    previousImpressionsSearch: ChartDataArray;
    previousPeriodDates: Date[];
    comparisonPeriod?: MalouComparisonPeriod;
}

interface MetaData {
    metadata: {
        comparisonLegendPluginMetadata: ComparisonLegendPluginMetadata;
    };
}
@Component({
    selector: 'app-gmb-discoveries-chart',
    templateUrl: './gmb-impressions-chart.component.html',
    styleUrls: ['./gmb-impressions-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, PeriodLegendComponent],
    providers: [ShortNumberPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbImpressionsChartComponent {
    readonly gmbDiscoveriesData = input.required<GmbDiscoveriesData>();
    readonly labels = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly isMaxDataRangeReached = input.required<boolean>();
    readonly hiddenDatasetIndexes = input.required<number[]>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly CHART_TYPE: LineChartType = 'line';
    readonly legendContainerId = 'impressions-legend-container';
    readonly chartDataSets = computed((): ChartDataset<LineChartType, ChartDataArray>[] => {
        const gmbDiscoveriesData = this.gmbDiscoveriesData();
        const hiddenDatasetIndexes = this.hiddenDatasetIndexes();

        const chartDataSets = this._computeChartData(gmbDiscoveriesData);
        if (hiddenDatasetIndexes.length) {
            return chartDataSets.filter((_, index) => !hiddenDatasetIndexes.includes(index));
        }
        return chartDataSets;
    });

    readonly chartLabels = computed((): Date[] => this._computeChartLabels(this.labels()));
    readonly comparisonPeriod = computed(
        (): MalouComparisonPeriod => this.gmbDiscoveriesData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD
    );
    readonly chartOption = computed(
        (): ChartOptions<LineChartType> => this._computeChartOptions(this.gmbDiscoveriesData(), this.viewBy(), this.labels())
    );

    readonly hiddenDatasetIndexesSelection: SelectionModel<number> = new SelectionModel<number>();

    // Must be an arrow function so that it can inherit the component 'this' context
    private _onLegendItemClick = (chart: Chart, legendItem: LegendItem) => {
        const datasetIndex = legendItem.datasetIndex as number;
        const isDatasetVisible = chart.isDatasetVisible(datasetIndex);
        const associatedDatasetIndex = datasetIndex + 2;
        isDatasetVisible
            ? this.hiddenDatasetIndexesSelection.select([datasetIndex, associatedDatasetIndex])
            : this.hiddenDatasetIndexesSelection.unselect([datasetIndex, associatedDatasetIndex]);

        toggleVisibilityOnMultipleDatasets(chart, [datasetIndex, associatedDatasetIndex], !isDatasetVisible);
        this.hiddenDatasetIndexesChange.emit(this.hiddenDatasetIndexesSelection.getSelection());
    };

    readonly LEGEND_PLUGIN: Plugin = comparisonLegendPlugin({
        id: 'impressionsLegendPlugin',
        elementId: this.legendContainerId,
        onLegendItemClick: this._onLegendItemClick,
    });

    private _hoveredElement: { datasetIndex: number; index: number } | null = null;

    private readonly _translateService = inject(TranslateService);
    private readonly _shortNumberPipe = inject(ShortNumberPipe);

    private _computeChartData(data: GmbDiscoveriesData): (ChartDataset<LineChartType, ChartDataArray> & MetaData)[] {
        return [
            {
                label: this._translateService.instant('statistics.seo.gmb_impressions.impressions_maps'),
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                pointBorderColor: malouChartColorPink,
                pointBackgroundColor: malouChartColorPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.impressionsMaps,
                pointRadius: computePointsRadius(data.impressionsMaps),
                metadata: {
                    comparisonLegendPluginMetadata: {
                        display: true,
                        primaryColor: malouChartColorPink,
                        secondaryColor: malouChartColorPinkLight30Percent,
                    },
                },
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_impressions.impressions_search'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                pointBorderColor: malouChartColorBluePurple,
                pointBackgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.impressionsSearch,
                pointRadius: computePointsRadius(data.impressionsSearch),
                metadata: {
                    comparisonLegendPluginMetadata: {
                        display: true,
                        primaryColor: malouChartColorBluePurple,
                        secondaryColor: malouChartColorBluePurpleLight30Percent,
                    },
                },
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_impressions.impressions_maps'),
                borderColor: malouChartColorPinkLight60Percent,
                backgroundColor: malouChartColorPinkLight60Percent,
                pointBorderColor: malouChartColorPinkLight60Percent,
                pointBackgroundColor: malouChartColorPinkLight60Percent,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.previousImpressionsMaps,
                pointRadius: computePointsRadius(data.previousImpressionsMaps),
                borderDash: DEFAULT_DASHED_LINE_OPTIONS,
                metadata: {
                    comparisonLegendPluginMetadata: {
                        display: false,
                    },
                },
            },
            {
                label: this._translateService.instant('statistics.seo.gmb_impressions.impressions_search'),
                borderColor: malouChartColorPurpleLight60Percent,
                backgroundColor: malouChartColorPurpleLight60Percent,
                pointBorderColor: malouChartColorPurpleLight60Percent,
                pointBackgroundColor: malouChartColorPurpleLight60Percent,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.previousImpressionsSearch,
                pointRadius: computePointsRadius(data.previousImpressionsSearch),
                borderDash: DEFAULT_DASHED_LINE_OPTIONS,
                metadata: {
                    comparisonLegendPluginMetadata: {
                        display: false,
                    },
                },
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(gmbDiscoveriesData: GmbDiscoveriesData, viewBy: ViewBy, labels: Date[]): ChartOptions<LineChartType> {
        return {
            onHover: (_, element): void => {
                if (!element.length) {
                    this._hoveredElement = null;
                    return;
                }
                this._hoveredElement = {
                    datasetIndex: element[0]?.datasetIndex,
                    index: element[0]?.index,
                };
            },
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    position: 'nearest',
                    filter: (tooltipItem: TooltipItem<any>): boolean =>
                        this._hoveredElement ? this._isAssociatedDatasetIndex(this._hoveredElement, tooltipItem) : false,
                    callbacks: {
                        title: (): string => '',
                        label: (tooltipItem: TooltipItem<any>): string[] =>
                            this._computeTooltipLabel(tooltipItem, labels, viewBy, gmbDiscoveriesData),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(
        item: TooltipItem<LineChartType>,
        labels: Date[],
        viewBy: ViewBy,
        gmbDiscoveriesData: GmbDiscoveriesData
    ): string[] {
        const date = this._computeTooltipLabelDate(item, labels, viewBy, gmbDiscoveriesData);
        const value = `${item.formattedValue} ${item.dataset.label}`;
        return [` ${date}`, value];
    }

    private _computeTooltipLabelDate(
        item: TooltipItem<LineChartType>,
        labels: Date[],
        viewBy: ViewBy,
        gmbDiscoveriesData: GmbDiscoveriesData
    ): string {
        if (!item) {
            return '';
        }

        const currentDate = DateTime.fromJSDate(new Date(labels[item.dataIndex]));
        let title: string = formatViewByDate(currentDate.toJSDate(), viewBy);

        if (this._isPreviousPeriodDatasetIndex(item.datasetIndex)) {
            const associatedDateInPreviousPeriod = gmbDiscoveriesData.previousPeriodDates[item.dataIndex];
            if (!isValidDate(associatedDateInPreviousPeriod)) {
                title = '-';
            }
            title = formatViewByDate(associatedDateInPreviousPeriod, viewBy);
        }
        if (viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.seo.gmb_impressions.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    private _isPreviousPeriodDatasetIndex(index: number): boolean {
        return index > 1;
    }

    // dataset 2 is the previous period of dataset 0, and dataset 3 is the previous period of dataset 1
    private _isAssociatedDatasetIndex(item1: { datasetIndex: number }, item2: { datasetIndex: number }): boolean {
        return item1?.datasetIndex % 2 === item2?.datasetIndex % 2;
    }
}
