<div
    class="flex flex-col gap-y-5 border-t border-malou-color-border-primary p-3"
    (click)="openPostHashtagsPanel()"
    (appClickOutside)="closePostHashtagsPanel()">
    @if (!isGeneratingHashtags() && selectedHashtags().length === 0) {
        <button
            class="malou-btn-icon--secondary btn-sm flex w-max items-center !bg-malou-color-chart-purple--accent/10"
            data-testid="social-post-generate-hashtags-btn"
            mat-button
            [id]="'tracking_new_social_post_modal_generate_' + (isReel() ? 'reel' : 'post') + '_hashtags_with_ai_button'"
            [disabled]="isGeneratingPostTextFromAI() || postText().length === 0"
            (click)="chooseHashtagsWithAi()">
            <mat-icon class="h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>

            <div class="!malou-text-10--semibold text-malou-color-chart-purple--accent">
                {{ 'social_posts.new_social_post.add_hashtags' | translate }}
            </div>
        </button>
    } @else if (!isGeneratingHashtags() && selectedHashtags().length > 0) {
        <div class="flex flex-wrap items-center gap-2">
            @for (hashtag of selectedHashtags(); track $index) {
                <div class="malou-chip malou-chip--primary" data-testid="social-post-hashtag-chip">
                    <span>{{ hashtag.text }}</span>
                    <mat-icon
                        class="malou-chip-icon--right malou-color-primary shrink-0"
                        [svgIcon]="SvgIcon.REMOVE"
                        (click)="removeHashtag($index)"></mat-icon>
                </div>
            }
        </div>
    }

    @if (isGeneratingHashtags()) {
        <app-skeleton
            flexWrap="flex-wrap"
            flexDirection="flex-row"
            gapClass="gap-2"
            skeletonClass="!h-[2.5rem] !w-[6.5rem]"
            [count]="6"></app-skeleton>
    }

    @if (isGeneratingHashtags() || selectedHashtags().length > 0) {
        <div class="flex items-center gap-x-2">
            <mat-icon
                class="!w-4 cursor-pointer text-malou-color-chart-purple--accent"
                [svgIcon]="SvgIcon.MAGIC_WAND"
                (click)="openPostHashtagsPanel()"></mat-icon>
            <button
                class="malou-btn-raised--secondary btn-sm !h-auto !rounded !px-2.5 !py-2 !text-[10px] !font-medium !text-malou-color-chart-purple--accent"
                mat-raised-button
                [disabled]="isGeneratingHashtags()"
                (click)="retryHashtagsGeneration()">
                {{ 'common.retry' | translate }}
            </button>
        </div>
    }
</div>
