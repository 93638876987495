import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { formatPhoneNumber, MalouErrorCode, platformsKeys } from '@malou-io/package-utils';

import { ComparisonRowComponent } from ':shared/components/comparison-row/comparison-row.component';
import { Comparison, ComparisonKey, DISPLAYED_NULLISH_VALUE, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { Suggestion } from './store/suggestions.interface';

const DEFAULT_COUNTRY_CODE = 'FR';
@Component({
    selector: 'app-information-suggestions-modal',
    templateUrl: './information-suggestions-modal.component.html',
    styleUrls: ['./information-suggestions-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, NgTemplateOutlet, ComparisonRowComponent],
})
export class InformationSuggestionModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly MalouErrorCode = MalouErrorCode;
    comparisons: Comparison[];
    readonly malouErrorCode = signal<Suggestion['malouErrorCode']>(null);
    readonly malouErrorCodeMessageTranslated = computed(() => this._translateMalouErrorCode(this.malouErrorCode()));

    displayCloseModal = false;
    isClosed = false;
    platformName = platformsKeys.GMB.fullName;

    private readonly _translateService = inject(TranslateService);

    constructor(
        public dialogRef: MatDialogRef<InformationSuggestionModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            restaurant: Restaurant;
            suggestion: Suggestion;
        }
    ) {
        this.comparisons = data.suggestion.comparisons;
        this.malouErrorCode.set(data.suggestion.malouErrorCode);
    }

    cancel(shouldReject: boolean): void {
        if (shouldReject) {
            this.dialogRef.close(this.acceptOrReject('reject'));
            return;
        }
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close(this.acceptOrReject('accept'));
    }

    acceptOrReject(acceptReject: 'accept' | 'reject'): Record<string, any> {
        const acceptRejectToComparisonKey = {
            accept: 'compared',
            reject: 'reference',
        };
        return this.comparisons.reduce((acc, comp) => {
            const value = comp[acceptRejectToComparisonKey[acceptReject]];
            // should handle nullish value and mapped values in platform-comparison service
            acc[comp.key] = value === DISPLAYED_NULLISH_VALUE ? null : this._mapToRestaurantValue(comp.key, value);
            return acc;
        }, {});
    }

    goGmbBusinessProfile(): void {
        window.open('https://business.google.com/u/0/locations', '_blank');
        this.dialogRef.close();
    }

    private _translateMalouErrorCode(malouErrorCode: Suggestion['malouErrorCode']): string | null {
        switch (malouErrorCode) {
            case MalouErrorCode.GMB_PIN_DROP_REQUIRED:
                return this._translateService.instant('information.suggestions.pin_drop_required');
            case MalouErrorCode.GMB_STOREFRONT_REQUIRED_FOR_CATEGORY:
                return this._translateService.instant('information.suggestions.store_front_required');
            default:
                return null;
        }
    }

    private _mapToRestaurantValue(key: ComparisonKey, value: any): any {
        switch (key) {
            case ComparisonKey.PHONE:
                return formatPhoneNumber(value, this.data.restaurant.address?.regionCode ?? DEFAULT_COUNTRY_CODE);
            default:
                return value;
        }
    }
}
