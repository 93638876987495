import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import {
    isNotNil,
    KeywordSearchImpressionsType,
    MalouComparisonPeriod,
    MalouTimeScalePeriod,
    TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ImpressionsInsightsComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights.component';
import {
    ImpressionsInsightsData,
    TopKeywordSearchImpressionsData,
} from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { TopKeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-impressions.component';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getSelectedMonthsNumberFromTimeScaleFilter, Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-keyword-search-impressions',
    standalone: true,
    imports: [
        ImpressionsInsightsComponent,
        TopKeywordSearchImpressionsComponent,
        SkeletonComponent,
        NgTemplateOutlet,
        TranslateModule,
        IllustrationPathResolverPipe,
    ],
    templateUrl: './keyword-search-impressions.component.html',
    styleUrl: './keyword-search-impressions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordSearchImpressionsComponent implements OnInit {
    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);

    readonly currentKeywordSearchImpressionsInsights: WritableSignal<ImpressionsInsightsData[]> = signal([]);
    readonly previousKeywordSearchImpressionsInsights: WritableSignal<ImpressionsInsightsData[]> = signal([]);
    readonly topKeywordSearchImpressions: WritableSignal<TopKeywordSearchImpressionsData> = signal({
        [KeywordSearchImpressionsType.BRANDING]: [],
        [KeywordSearchImpressionsType.DISCOVERY]: [],
    });
    readonly dateInterval: WritableSignal<{ startDate: Date; endDate: Date }> = signal(this._getDefaultDateInterval());
    readonly isLoading = signal(true);
    readonly hasData = signal(false);
    readonly httpError = signal(false);

    readonly selectedTimeScaleFilter$ = this._store.select(StatisticsSelector.selectTimeScaleFilter);

    readonly Illustration = Illustration;

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this.selectedTimeScaleFilter$])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                map(([restaurant, selectedTimeScaleFilter]) => {
                    const monthsNumber = getSelectedMonthsNumberFromTimeScaleFilter(selectedTimeScaleFilter);
                    const endDate = DateTime.now().toJSDate();
                    const startDate = DateTime.now()
                        .minus({ month: monthsNumber - 1 })
                        .toJSDate();
                    return [restaurant, startDate, endDate];
                }),
                tap(() => this._reset()),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) =>
                    forkJoin([
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights({
                            restaurantId: restaurant._id,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                        }),
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights({
                            restaurantId: restaurant._id,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                        this._keywordSearchImpressionsService.getTopKeywordSearchImpressions({
                            restaurantId: restaurant._id,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            limit: TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
                        }),
                        of({ startDate, endDate }),
                    ])
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([
                    currentKeywordSearchImpressionsInsights,
                    previousKeywordSearchImpressionsInsights,
                    topKeywordSearchImpressions,
                    { startDate, endDate },
                ]) => {
                    this.isLoading.set(false);
                    this.hasData.set(currentKeywordSearchImpressionsInsights?.length > 0);

                    this.currentKeywordSearchImpressionsInsights.set(currentKeywordSearchImpressionsInsights);
                    this.previousKeywordSearchImpressionsInsights.set(previousKeywordSearchImpressionsInsights);

                    this.topKeywordSearchImpressions.set(topKeywordSearchImpressions);

                    this.dateInterval.set({ startDate, endDate });
                },
                error: (error) => {
                    this.isLoading.set(false);
                    this.httpError.set(true);
                    console.warn('error :>>', error);
                },
            });
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.httpError.set(false);
        this.hasData.set(false);
    }

    private _getDefaultDateInterval(): { startDate: Date; endDate: Date } {
        const monthsNumber = getSelectedMonthsNumberFromTimeScaleFilter(MalouTimeScalePeriod.LAST_SIX_MONTHS);
        return {
            startDate: DateTime.now()
                .minus({ month: monthsNumber - 1 })
                .toJSDate(),
            endDate: DateTime.now().toJSDate(),
        };
    }
}
