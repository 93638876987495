import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { RoiActivatedNotification } from ':core/components/notification-center/models/roi-activated-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-roi-activated-notification-item',
    standalone: true,
    imports: [TranslateModule, FormatDatePipe],
    templateUrl: './roi-activated-notification-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoiActivatedNotificationItemComponent extends NotificationItemComponent {
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);

    readonly notification = computed(() => this.initialNotification() as RoiActivatedNotification);
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);

    onNotificationClick(): void {
        this.markAsRead();
        if (this.notification().data.restaurantIds.length > 1) {
            this._navigateToAggregatedRoiPage();
        } else {
            this._navigateToRoiPage();
        }
        this._notificationsCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_ROI_ACTIVATED_TRACKING_WEB_REDIRECT,
            notificationId: this.notification().id,
            properties: {
                notificationType: this.notification().type,
            },
        });
        this._notificationsCenterContext.close();
    }

    private _navigateToRoiPage(): void {
        this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'statistics', 'roi']);
    }

    private _navigateToAggregatedRoiPage(): void {
        this._router.navigate(['groups', 'statistics', 'roi']);
    }
}
