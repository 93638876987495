import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-answer-review-disconnected-platform',
    standalone: true,
    imports: [TranslateModule, MatButtonModule],
    templateUrl: './answer-review-disconnected-platform.component.html',
    styleUrl: './answer-review-disconnected-platform.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerReviewDisconnectedPlatformComponent {
    readonly platformKey = input.required<PlatformKey>();
    readonly restaurantId = input.required<string>();

    private readonly _router = inject(Router);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly prettyPlatformKey = computed(() => this._enumTranslatePipe.transform(this.platformKey(), 'platform_key'));

    goToConnectPlatform(): void {
        this._customDialogService.closeAll();
        this._router.navigate(['restaurants', this.restaurantId(), 'settings', 'platforms', 'connection'], {
            queryParams: { reconnectPlatform: this.platformKey() },
        });
    }
}
