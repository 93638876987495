<div class="malou-border-color-dark malou-background-white flex flex-col rounded-[10px] border px-9 pb-11 pt-8">
    <span class="malou-text-14--bold malou-color-text-1">{{ 'ai.general.general' | translate }}</span>
    <form class="flex flex-wrap gap-6" [formGroup]="aiGeneralSettingsForm">
        <app-input-text
            class="min-w-fit flex-1"
            testId="general-ai-settings-restaurant-name-input"
            formControlName="restaurantName"
            [title]="'ai.general.restaurant_name' | translate"
            [showIsLoading]="isLoading()"
            [svgIcon]="!isLoading() && showCheckSvgIcon() ? SvgIcon.CHECK : undefined"></app-input-text>

        <app-select-languages
            class="min-w-fit flex-1"
            data-testid="general-ai-settings-language-select"
            formControlName="language"
            [title]="'ai.general.language' | translate"
            [values]="APP_LANGUAGES"
            [multiSelection]="false">
        </app-select-languages>
    </form>
</div>

<ng-template #loadingTemplate>
    <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
</ng-template>
