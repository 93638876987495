import { PostAuthorDto } from '@malou-io/package-dto';
import { RemoveMethodsFromClass } from '@malou-io/package-utils';

export type ISocialPostAuthor = RemoveMethodsFromClass<SocialPostAuthor> & { id: string };

export class SocialPostAuthor {
    id: string;
    name?: string;
    lastname?: string;
    picture?: string;

    constructor(data: ISocialPostAuthor) {
        this.id = data.id;
        this.name = data.name;
        this.lastname = data.lastname;
        this.picture = data.picture;
    }

    static fromDto(dto: PostAuthorDto): SocialPostAuthor {
        return new SocialPostAuthor({
            id: dto.id,
            name: dto.name,
            lastname: dto.lastname,
            picture: dto.picture,
        });
    }

    getInitials(): string {
        return `${this.name?.charAt(0) ?? ''}${this.lastname?.charAt(0) ?? ''}`;
    }

    getFullName(): string {
        if (!this.name) {
            return this.lastname ?? '';
        }
        if (!this.lastname) {
            return this.name;
        }
        return `${this.name} ${this.lastname}`;
    }
}
