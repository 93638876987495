import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal, Signal, WritableSignal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, TimeUnit } from 'chart.js';
import { Plugin, Tick, TooltipItem } from 'chart.js/dist/types';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { GmbActionsData } from ':modules/statistics/seo/gmb-insights/gmb-actions-v2/gmb-actions-v2.interfaces';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartColorBlue,
    malouChartColorBlue30Percent,
    malouChartColorBluePurple,
    malouChartColorBluePurpleLight30Percent,
    malouChartColorDarkGreen,
    malouChartColorDarkGreen30Percent,
    malouChartColorLighterPink,
    malouChartColorLighterPink30Percent,
    malouChartColorPink,
    malouChartColorPinkLight30Percent,
    malouChartColorPurple,
    malouChartColorPurpleLight30Percent,
} from ':shared/helpers';
import { comparisonLegendPlugin, ComparisonLegendPluginMetadata } from ':shared/helpers/chart-comparison-legend-plugin';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

type ChartData = ChartDataset<BarChartType, ChartDataArray> & {
    metadata?: {
        isPrevious: boolean;
        comparisonLegendPluginMetadata: ComparisonLegendPluginMetadata;
    };
};

const chartKeys = [
    {
        key: 'websiteClicks',
        label: 'statistics.seo.gmb_actions.visit_website',
        color: malouChartColorBluePurple,
        comparisonColor: malouChartColorBluePurpleLight30Percent,
    },
    {
        key: 'phoneClicks',
        label: 'statistics.seo.gmb_actions.phone_call',
        color: malouChartColorPink,
        comparisonColor: malouChartColorPinkLight30Percent,
    },
    {
        key: 'drivingClicks',
        label: 'statistics.seo.gmb_actions.itinerary_request',
        color: malouChartColorDarkGreen,
        comparisonColor: malouChartColorDarkGreen30Percent,
    },
    {
        key: 'menuClicks',
        label: 'statistics.seo.gmb_actions.menu_click',
        color: malouChartColorLighterPink,
        comparisonColor: malouChartColorLighterPink30Percent,
    },
    {
        key: 'bookingClicks',
        label: 'statistics.seo.gmb_actions.booking_click',
        color: malouChartColorPurple,
        comparisonColor: malouChartColorPurpleLight30Percent,
    },
    {
        key: 'foodOrderClicks',
        label: 'statistics.seo.gmb_actions.food_order_click',
        color: malouChartColorBlue,
        comparisonColor: malouChartColorBlue30Percent,
    },
];

@Component({
    selector: 'app-gmb-actions-chart',
    templateUrl: './gmb-actions-chart.component.html',
    styleUrls: ['./gmb-actions-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [ShortNumberPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbActionsChartComponent {
    readonly gmbActionsData = input.required<GmbActionsData>();
    readonly labels = input<Date[]>([]);
    readonly previousLabels = input<Date[]>([]);
    readonly viewBy = input<ViewBy>(ViewBy.DAY);
    readonly hiddenDatasetIndexes = input<number[]>([]);

    readonly hiddenDatasetIndexesChange = output<number[]>();

    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _translateService = inject(TranslateService);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly legendContainerId = 'actions-legend-container';

    readonly chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() => {
        const chartDataSet = this._computeChartData(this.gmbActionsData());
        if (this.hiddenDatasetIndexes().length) {
            return chartDataSet.filter((_, index) => !this.hiddenDatasetIndexes().includes(index));
        }
        return chartDataSet;
    });

    readonly _hiddenDatasetIndexes: WritableSignal<number[]> = signal([]);

    readonly chartLabels: Signal<Date[]> = computed(() => this._computeChartLabels(this.labels()));
    readonly chartOption: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());

    private _onLegendItemClick = (chart: Chart, legendItem: LegendItem) => {
        const index = legendItem.datasetIndex;
        if (!isNumber(index)) {
            return;
        }
        if (chart.isDatasetVisible(index)) {
            this._hiddenDatasetIndexes.set([...this._hiddenDatasetIndexes(), index]);
            chart.hide(index);
            chart.hide(index - 1);
            legendItem.hidden = true;
        } else {
            this._hiddenDatasetIndexes.set(this._hiddenDatasetIndexes().filter((i) => i !== index));
            chart.show(index);
            chart.show(index - 1);
            legendItem.hidden = false;
        }
        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes());
    };

    readonly LEGEND_PLUGIN: Signal<Plugin> = computed(() =>
        comparisonLegendPlugin({
            id: 'impressionsLegendPlugin',
            elementId: this.legendContainerId,
            onLegendItemClick: this._onLegendItemClick,
            shouldOnlyShowPrimaryColor: this.viewBy() === ViewBy.DAY,
        })
    );

    private _computeChartData(data: GmbActionsData): ChartData[] {
        return chartKeys
            .map(
                ({ key, label, color, comparisonColor }) =>
                    [
                        {
                            label: this._translateService.instant(label),
                            borderColor: comparisonColor,
                            backgroundColor: comparisonColor,
                            xAxisID: 'xAxis',
                            yAxisID: 'yAxis',
                            data: data.comparisonPeriodData[key],
                            metadata: {
                                isPrevious: true,
                                comparisonLegendPluginMetadata: {
                                    display: false,
                                },
                            },
                        },
                        {
                            label: this._translateService.instant(label),
                            borderColor: color,
                            backgroundColor: color,
                            xAxisID: 'xAxis',
                            yAxisID: 'yAxis',
                            data: data[key],
                            metadata: {
                                comparisonLegendPluginMetadata: {
                                    display: true,
                                    primaryColor: color,
                                    secondaryColor: comparisonColor,
                                },
                            },
                        },
                    ] as ChartData[]
            )
            .flat();
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(this.viewBy()),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(this.viewBy()),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[]): string {
        const dataSet = item[0].dataset as ChartData;
        const title = dataSet.metadata?.isPrevious ? this._formatTitle(this.previousLabels()[item[0].dataIndex]) : item[0].label;
        if (this.viewBy() === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.seo.gmb_actions.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    private _formatTitle(title: Date): string {
        return this.viewBy() === ViewBy.MONTH
            ? DateTime.fromJSDate(title).toFormat(MMM_YYYY)
            : DateTime.fromJSDate(title).toFormat(DD_MMM_YYYY);
    }
}
