<div class="flex h-full flex-col gap-y-6 overflow-y-auto py-4" #topOfComponent>
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }

    <div class="flex h-full flex-col gap-y-4 overflow-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if (platformKeys().length !== 0) {
            @if (isReleaseBoosterPerformanceImprovementsEnabled()) {
                <ng-container *ngTemplateOutlet="performanceImprovementsTemplate"></ng-container>
            } @else {
                <ng-container *ngTemplateOutlet="oldVersionTemplate"></ng-container>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [lazyLoad]="Illustration.Taster | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'statistics.errors.platforms_not_connected' | translate }}</span>
            </div>
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 sm:flex-col sm:items-center md:px-0">
        <div class="flex-1">
            <app-statistics-filters [showPlatformsFilter]="true" [platformFilterPage]="PlatformFilterPage.SOCIAL_NETWORKS">
            </app-statistics-filters>
        </div>

        <button
            class="malou-btn-raised--primary !h-12"
            mat-raised-button
            [disabled]="(platformKeys$ | async)?.length === 0 || isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #performanceImprovementsTemplate>
    <div class="flex gap-6 md:flex-col">
        <div class="min-w-0 flex-1">
            <app-community-v2
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.COMMUNITY, $event)"
                (viewByChange)="onViewByChange(InsightsChart.COMMUNITY, $event)"
                (isLoadingEvent)="isCommunityLoading.set($event)">
            </app-community-v2>
        </div>
        <div class="min-w-0 flex-1">
            <app-engagement-v2
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ENGAGEMENT, $event)"
                (viewByChange)="onViewByChange(InsightsChart.ENGAGEMENT, $event)"
                (isLoadingEvent)="isEngagementLoading.set($event)">
            </app-engagement-v2>
        </div>
    </div>
    <div class="mt-5">
        <ng-container *ngTemplateOutlet="postsAndStories"></ng-container>
    </div>
</ng-template>

<ng-template #oldVersionTemplate>
    <div class="flex gap-6 md:flex-col">
        <div class="min-w-0 flex-1">
            <app-community
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.COMMUNITY, $event)"
                (viewByChange)="onViewByChange(InsightsChart.COMMUNITY, $event)"
                (isLoadingEvent)="isCommunityLoading.set($event)">
            </app-community>
        </div>
        <div class="min-w-0 flex-1">
            <app-engagement
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ENGAGEMENT, $event)"
                (viewByChange)="onViewByChange(InsightsChart.ENGAGEMENT, $event)"
                (isLoadingEvent)="isEngagementLoading.set($event)">
            </app-engagement>
        </div>
    </div>
    <div class="mt-10">
        <ng-container *ngTemplateOutlet="postsAndStories"></ng-container>
    </div>
</ng-template>

<ng-template #postsAndStories>
    <app-posts-insights-table
        (tableSortOptionsChange)="onTableSortOptionsChange($event)"
        (isLoadingEvent)="isPostInsightsTableLoading.set($event)">
    </app-posts-insights-table>
</ng-template>
