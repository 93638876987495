import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subject } from 'rxjs';

import { isNotNil, PlatformKey, SocialPostsListFilter } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SocialPostsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly posts = signal<SocialPostItem[]>([]);
    readonly isFetchingPosts = signal<boolean>(false);
    readonly isFetchingMorePosts = signal<boolean>(false);
    readonly hasFetchedPostsAtLeastOnce = signal<boolean>(false);
    readonly selectedFilter = signal<SocialPostsListFilter>(SocialPostsListFilter.ALL);
    private readonly _socialPostsCursor = signal<string | null>(null);
    readonly hasNextPage = computed(() => this._socialPostsCursor() !== null);
    readonly fetchNextPage$ = new Subject<void>();

    readonly feed = signal<FeedItem[]>([]);
    readonly isFetchingFeed = signal<boolean>(false);
    readonly isFetchingMoreFeed = signal<boolean>(false);
    private readonly _feedCursor = signal<string | null>(null);
    readonly hasNextPageFeed = computed(() => this._feedCursor() !== null);
    readonly fetchFeedNextPage$ = new Subject<void>();

    readonly postSelection = new SelectionModel<SocialPostItem>((value) => value.id);
    readonly isSelecting = signal(false);

    // Used to fetch posts until a specific post is found
    private _fetchUntilPostIsFound: string | null = null;
    readonly foundPost$ = new Subject<string>();

    readonly restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    readonly restaurant = toSignal(this.restaurant$, { initialValue: this._restaurantsService.currentRestaurant });

    readonly restaurantHasNoPost = computed(
        (): boolean =>
            this.hasFetchedPostsAtLeastOnce() && this.posts().length === 0 && !this.isFetchingPosts() && !this.isFetchingMorePosts()
    );

    readonly shouldFetchFilterOptionsCount$ = new Subject<void>();

    init(): void {
        this.hasFetchedPostsAtLeastOnce.set(false);
        this.postSelection.unselectAll();
        this.isSelecting.set(false);
        this.resetFilter();
        this.resetPagination();
        this.resetFeedPagination();
    }

    fetchPosts(postsFilter: SocialPostsListFilter, restaurantId: string, limit: number): void {
        const cursor = this._socialPostsCursor();
        this.isFetchingPosts.set(cursor === null);
        this.isFetchingMorePosts.set(cursor !== null);

        this._socialPostsService.getSocialPosts$(postsFilter, cursor, restaurantId, limit).subscribe(({ socialPostItems, nextCursor }) => {
            const newPosts = socialPostItems.map((post) => SocialPostItem.fromDto(post));
            if (cursor === null) {
                this.posts.set(newPosts);
            } else {
                this.posts.update((currentPosts) => [...currentPosts, ...newPosts]);
            }
            this.isFetchingPosts.set(false);
            this.isFetchingMorePosts.set(false);
            this.hasFetchedPostsAtLeastOnce.set(true);
            this._socialPostsCursor.set(nextCursor);

            if (this._fetchUntilPostIsFound !== null) {
                this.fetchPostsUntilPostIsFound(this._fetchUntilPostIsFound);
            }
        });
    }

    fetchFeed(restaurantId: string, limit: number): void {
        const feedCursor = this._feedCursor();
        this.isFetchingFeed.set(feedCursor === null);
        this.isFetchingMoreFeed.set(feedCursor !== null);

        this._socialPostsService.getFeed$(restaurantId, feedCursor, limit).subscribe(({ feed, nextCursor }) => {
            const newFeed = feed.map((feedItem) => FeedItem.fromDto(feedItem));
            if (feedCursor === null) {
                this.feed.set(newFeed);
            } else {
                this.feed.update((currentFeed) => [...currentFeed, ...newFeed]);
            }
            this.isFetchingFeed.set(false);
            this.isFetchingMoreFeed.set(false);
            this._feedCursor.set(nextCursor);
        });
    }

    deleteSocialPost(postId: string): void {
        const postToDelete = this.posts().find((p) => p.id === postId);
        if (!postToDelete) {
            return;
        }

        // Optimistic update
        this.posts.update((currentPosts) => currentPosts.filter((post) => post.id !== postId));
        const feedItemToDelete = this.feed().find((f) => f.postId === postId);
        if (feedItemToDelete) {
            this.feed.update((currentFeed) => currentFeed.filter((feedItem) => feedItem.postId !== postId));
        }
        this._socialPostsService.deleteSocialPost$(postId).subscribe((result) => {
            if (!result.success) {
                // Revert optimistic update
                this.posts.update((currentPosts) =>
                    [...currentPosts, postToDelete].sort(
                        (a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime()
                    )
                );
                if (feedItemToDelete) {
                    this.feed.update((currentFeed) =>
                        [...currentFeed, feedItemToDelete].sort(
                            (a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime()
                        )
                    );
                }

                this._toastService.openErrorToast(this._translateService.instant('social_posts.error_delete_post'));
            }
        });
    }

    upsertSocialPost(post: SocialPostItem): void {
        const shouldRemovePostFromList = this.selectedFilter() === SocialPostsListFilter.FEEDBACK && post.feedbackMessageCount === 0;

        this.posts.update((currentPosts) => {
            if (shouldRemovePostFromList) {
                return currentPosts.filter((p) => p.id !== post.id);
            }

            const postIndex = currentPosts.findIndex((p) => p.id === post.id);
            if (postIndex === -1) {
                return [...currentPosts, post].sort(
                    (a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime()
                );
            }
            currentPosts[postIndex] = post;
            return [...currentPosts].sort((a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime());
        });

        if (post.platformKeys.includes(PlatformKey.INSTAGRAM)) {
            const newFeedItem = FeedItem.fromSocialPostItem(post);
            this.feed.update((currentFeed) => {
                const feedItemIndex = currentFeed.findIndex((f) => f.postId === post.id);
                if (feedItemIndex === -1) {
                    return [...currentFeed, newFeedItem].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
                }
                currentFeed[feedItemIndex] = newFeedItem;
                return [...currentFeed].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
            });
        } else {
            this.feed.update((currentFeed) => currentFeed.filter((feedItem) => feedItem.postId !== post.id));
        }
    }

    resetPagination(): void {
        this._socialPostsCursor.set(null);
        this.goNextPage();
    }

    resetFilter(): void {
        this.selectedFilter.set(SocialPostsListFilter.ALL);
    }

    resetFeedPagination(): void {
        this._feedCursor.set(null);
        this.goNextPageFeed();
    }

    goNextPage(): void {
        this.fetchNextPage$.next();
    }

    goNextPageFeed(): void {
        this.fetchFeedNextPage$.next();
    }

    fetchPostsUntilPostIsFound(postId: string): void {
        const posts = this.posts();
        if (posts.some((p) => p.id === postId)) {
            // Found the post, reset the variable and notify subscribers
            this._fetchUntilPostIsFound = null;
            this.foundPost$.next(postId);
            return;
        }

        if (this._socialPostsCursor() === null) {
            // No more posts to fetch
            this._fetchUntilPostIsFound = null;
            return;
        }

        // Fetch next page to find the post
        this.fetchNextPage$.next();
    }

    setFetchUntilPostIsFound(postId: string): void {
        this._fetchUntilPostIsFound = postId;
    }

    swapPlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        this._optimisticUpdatePlannedPublicationDates(sourceIndex, destinationIndex);

        const minIndex = Math.min(sourceIndex, destinationIndex);
        const maxIndex = Math.max(sourceIndex, destinationIndex);
        const slicedFeed = this.feed().slice(minIndex, maxIndex + 1);
        const order = sourceIndex < destinationIndex ? -1 : 1;

        this._socialPostsService.swapPlannedPublicationDates$(slicedFeed, order).subscribe((result) => {
            if (!result.success) {
                this._toastService.openErrorToast(this._translateService.instant('social_posts.error_swap_dates'));
                this._undoOptimisticUpdatePlannedPublicationDates(sourceIndex, destinationIndex);
            }
        });
    }

    setIsSelecting(isSelecting: boolean): void {
        this.isSelecting.set(isSelecting);
    }

    private _optimisticUpdatePlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        const minIndex = Math.min(sourceIndex, destinationIndex);
        const maxIndex = Math.max(sourceIndex, destinationIndex);
        const order = sourceIndex < destinationIndex ? -1 : 1;

        const postsToUpdate: { postId: string; plannedPublicationDate: Date | null; sortDate?: Date }[] = [];

        this.feed.update((currentFeed) => {
            const slicedFeed = currentFeed.slice(minIndex, maxIndex + 1);
            const slicedFeedLength = slicedFeed.length;

            const updatedFeed = slicedFeed.map((item, index) => {
                const destinationFeedItemIndex = (index + order + slicedFeedLength) % slicedFeedLength;
                const destinationFeedItem = slicedFeed[destinationFeedItemIndex];
                if (!destinationFeedItem) {
                    // Should never happen
                    throw new Error(`Feed item not found for index ${destinationFeedItemIndex}`);
                }
                const updatedItem = item.copyWith({
                    plannedPublicationDate: destinationFeedItem.plannedPublicationDate,
                    sortDate: destinationFeedItem.sortDate,
                });
                postsToUpdate.push({
                    postId: updatedItem.postId,
                    plannedPublicationDate: updatedItem.plannedPublicationDate,
                    sortDate: updatedItem.sortDate,
                });
                return updatedItem;
            });
            const sortedUpdatedFeed = updatedFeed.sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
            return [...currentFeed.slice(0, minIndex), ...sortedUpdatedFeed, ...currentFeed.slice(maxIndex + 1)];
        });

        this.posts.update((currentPosts) => {
            postsToUpdate.forEach((postToUpdate) => {
                const postIndex = currentPosts.findIndex((post) => post.id === postToUpdate.postId);
                if (postIndex !== -1) {
                    currentPosts[postIndex] = currentPosts[postIndex].copyWith({
                        plannedPublicationDate: postToUpdate.plannedPublicationDate,
                        sortDate: postToUpdate.sortDate,
                    });
                }
            });
            return [...currentPosts].sort((a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime());
        });
    }

    private _undoOptimisticUpdatePlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        this._optimisticUpdatePlannedPublicationDates(destinationIndex, sourceIndex);
    }
}
