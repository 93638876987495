export namespace ImageEditorUtils {
    export interface Size {
        width: number;
        height: number;
    }

    export interface Area {
        left: number;
        top: number;
        width: number;
        height: number;
    }

    /**
     * This function computes the zoom to apply to 'image' to be able to entirely cover 'container' (like object-fit: cover)
     *
     * Ex :
     *    If image = {width: 250, height: 300} and container = {width: 500, height: 500}
     *    sideToFit will be equal to width.
     *    The result will be equal to container[width] / image[width] = 500 / 250 = 2.
     *    By multiplying the original image by the result, we will get {width: 500, height: 600} so perfectly cover the container
     */
    export function getZoomRatioToCover(image: Size, container: Size): number {
        const aspectRatio = image.width / image.height;
        const aspectRatioToCover = container.width / container.height;

        const sideToFit = aspectRatio > aspectRatioToCover ? 'height' : 'width';
        return container[sideToFit] / image[sideToFit];
    }

    export function scaleTransformArea(transformArea: Area, size: Size): Area {
        return {
            left: transformArea.left * size.width,
            top: transformArea.top * size.height,
            width: transformArea.width * size.width,
            height: transformArea.height * size.height,
        };
    }
}
