import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { KeywordSearchImpressionsType } from '@malou-io/package-utils';

import { TopKeywordSearchImpressionsData } from ':modules/statistics/seo/keyword-search-impressions//keyword-search-impressions.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';

@Component({
    selector: 'app-top-keyword-search-modal',
    standalone: true,
    imports: [TranslateModule, MatIconModule, ApplyPurePipe],
    templateUrl: './top-keyword-search-modal.component.html',
    styleUrl: './top-keyword-search-modal.component.scss',
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopKeywordSearchModalComponent {
    readonly data: { topKeywordSearchImpressions: TopKeywordSearchImpressionsData; type: KeywordSearchImpressionsType } =
        inject(MAT_DIALOG_DATA);
    readonly _dialogRef: MatDialogRef<TopKeywordSearchModalComponent> = inject(MatDialogRef);
    readonly _emojiPathResolverPipe: EmojiPathResolverPipe = inject(EmojiPathResolverPipe);

    readonly SvgIcon = SvgIcon;

    readonly topKeywordSearchImpressions: WritableSignal<TopKeywordSearchImpressionsData> = signal(this.data.topKeywordSearchImpressions);
    readonly keywordSearchImpressionsType: WritableSignal<KeywordSearchImpressionsType> = signal(this.data.type);
    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;

    cancel(): void {
        this._dialogRef.close();
    }

    getEmojiFromPosition = (position: number): string =>
        [
            this._emojiPathResolverPipe.transform(Emoji.TROPHY),
            this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.PARTY_FACE),
        ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE);
}
