import { z } from 'zod';

import { MediaType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { MediaDimensionDto, MediaTransformDataDto } from './media.dto';

export const getMediaForEditionPathValidator = z.object({
    mediaId: objectIdValidator,
});

export type GetMediaForEditionPathDto = z.infer<typeof getMediaForEditionPathValidator>;

export type GetMediaForEditionResponseDto = {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
    thumbnail256OutsideDimensions?: MediaDimensionDto;
    thumbnail1024OutsideDimensions?: MediaDimensionDto;
    type: MediaType;
    aspectRatio: number;
    transformData: MediaTransformDataDto;
    aiDescription?: string | null;
};
