<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[90vh]': !displayCloseModal() || (displayCloseModal() && isPhoneScreen()),
        'h-[350px]': displayCloseModal() && !isPhoneScreen(),
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)">
    </app-close-without-saving-modal>
</ng-template>
<ng-template #editModal>
    <div class="malou-dialog h-full w-full !flex-row">
        <div class="flex w-[63%] flex-col justify-between">
            <ng-container *ngTemplateOutlet="settingsTemplate"></ng-container>
        </div>
        <div class="flex w-[37%] flex-col justify-between">
            <app-ai-post-settings-preview
                class="h-full"
                [class.hidden]="selectedIndex() !== 0"
                [aiSeoPostSettingsForm]="aiSeoPostSettingsForm"
                [aiSocialPostSettingsForm]="aiSocialPostSettingsForm"
                [postSource]="PostSource.SOCIAL"
                (close)="close()"></app-ai-post-settings-preview>
            <app-ai-post-settings-preview
                class="h-full"
                [class.hidden]="selectedIndex() !== 1"
                [aiSeoPostSettingsForm]="aiSeoPostSettingsForm"
                [aiSocialPostSettingsForm]="aiSocialPostSettingsForm"
                [postSource]="PostSource.SEO"
                (close)="close()"></app-ai-post-settings-preview>
        </div>
    </div>
</ng-template>

<ng-template #settingsTemplate>
    <div class="malou-dialog__header !py-5">
        <div class="title">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'ai.posts.upsert.title' | translate }}
            </div>
        </div>
    </div>

    <div class="malou-dialog__body h-[75%] md:!overflow-y-scroll">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>

    @if (data.uniqueTab) {
        <div class="malou-dialog__footer !justify-between">
            <button class="malou-btn-flat flex items-center" (click)="goToAiSettings()">
                <mat-icon class="m-2 !h-4 !w-4" [svgIcon]="SvgIcon.EXPORT"></mat-icon>
                {{ 'ai.posts.personalize' | translate }}
            </button>
            <div class="flex !gap-[15px]">
                <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
            </div>
        </div>
    } @else {
        <div class="malou-dialog__footer">
            <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
        </div>
    }
</ng-template>

<ng-template #footerTemplate>
    <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button [disabled]="isSubmitting()" (click)="close()">
        {{ 'common.cancel' | translate }}
    </button>
    <button
        class="malou-btn-raised--primary !h-11 md:grow"
        id="tracking_restaurant_ai_settings_edit_validation_button"
        data-testid="general-ai-settings-save-btn"
        mat-raised-button
        [disabled]="isSubmitting() || !areFormsValid()"
        (click)="submit()">
        @if (isSubmitting()) {
            <app-malou-spinner class="flex w-[93px] justify-center" size="small" color="white"></app-malou-spinner>
        } @else {
            {{ 'common.save' | translate }}
        }
    </button>
</ng-template>

<ng-template #formTemplate>
    @switch (data.uniqueTab) {
        @case (AiPostSettingsTab.SEO) {
            <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                <app-ai-post-settings-modal-tab [postSettingsForm]="aiSeoPostSettingsForm"></app-ai-post-settings-modal-tab>
            </div>
        }
        @case (AiPostSettingsTab.SOCIAL) {
            <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                <app-ai-post-settings-modal-tab [postSettingsForm]="aiSocialPostSettingsForm"></app-ai-post-settings-modal-tab>
            </div>
        }
        @default {
            <mat-tab-group class="h-full" animationDuration="200" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)">
                <mat-tab [label]="'ai.posts.edit.tabs.social_networks' | translate">
                    <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                        <app-ai-post-settings-modal-tab [postSettingsForm]="aiSocialPostSettingsForm"></app-ai-post-settings-modal-tab>
                    </div>
                </mat-tab>
                <mat-tab [label]="'ai.posts.edit.tabs.seo' | translate">
                    <div class="my-4 flex max-h-[55vh] flex-col justify-between">
                        <app-ai-post-settings-modal-tab [postSettingsForm]="aiSeoPostSettingsForm"></app-ai-post-settings-modal-tab>
                    </div>
                </mat-tab>
            </mat-tab-group>
        }
    }
</ng-template>
