export enum UploadErrorCode {
    INVALID_FILE = 'INVALID_FILE',
    FILE_SIZE_TOO_LARGE = 'FILE_SIZE_TOO_LARGE',
}

export type UploadMediaV2ResponseDto =
    | {
          success: true;
          mediaId: string;
      }
    | {
          success: false;
          errorCode: UploadErrorCode.INVALID_FILE;
      }
    | {
          success: false;
          errorCode: UploadErrorCode.FILE_SIZE_TOO_LARGE;
          maxFileSize: number;
      };
