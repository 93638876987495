<div class="px-8 py-5">
    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
</div>

<ng-template #titleTemplate>
    <div class="flex flex-col items-center py-5">
        <div class="malou-color-text-1 malou-text-18--bold">{{ 'statistics_pdf.seo_pdf.title' | translate }}</div>
        <div class="malou-color-text-2 malou-text-9--regular">{{ { startDate, endDate } | fromToDateFormatter }}</div>
        <div class="malou-color-text-2 malou-text-9--regular">
            {{ startDate.toISOString() }} ({{ startDate.getTimezoneOffset() }}) - {{ endDate.toISOString() }} (
            {{ endDate.getTimezoneOffset() }})
        </div>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantTitle$ | async | statisticsPdfRestaurantsFormatter }}
        </span>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    @if ((displayedCharts | includes: InsightsChart.KEYWORDS) && seoKeywordsHasData) {
        <div class="mb-4 flex gap-6">
            <div class="min-w-0 flex-1">
                <app-statistics-seo-keywords-v3
                    [isCompetitorsColumnShown]="false"
                    [tableSortOptions]="chartOptions[InsightsChart.KEYWORDS]?.tableSortOptions"
                    (hasDataChange)="seoKeywordsHasData = $event">
                </app-statistics-seo-keywords-v3>
            </div>
        </div>
    }

    @if (isGmbInsightsChartsV2Enabled()) {
        <app-gmb-insights
            [shouldShowActionsChart]="displayedCharts | includes: InsightsChart.ACTIONS"
            [shouldShowImpressionsChart]="displayedCharts | includes: InsightsChart.APPARITIONS"
            [impressionsChartViewBy]="chartOptions[InsightsChart.APPARITIONS]?.viewBy"
            [actionsChartViewBy]="chartOptions[InsightsChart.ACTIONS]?.viewBy"
            [impressionsChartHiddenDatasetIndexes]="chartOptions[InsightsChart.APPARITIONS]?.hiddenDatasetIndexes ?? []"
            [actionsChartHiddenDatasetIndexes]="chartOptions[InsightsChart.ACTIONS]?.hiddenDatasetIndexes ?? []"
            [showViewByTextInsteadOfSelectorActions]="true"
            [showViewByTextInsteadOfSelectorImpressions]="true">
        </app-gmb-insights>
    } @else {
        <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
            @if ((displayedCharts | includes: InsightsChart.APPARITIONS) && gmbImpressionsHasData) {
                <div class="min-w-0 flex-1 break-inside-avoid">
                    <app-statistics-seo-gmb-discoveries
                        [showViewByTextInsteadOfSelector]="true"
                        [viewBy]="chartOptions[InsightsChart.APPARITIONS]?.viewBy"
                        [hiddenDatasetIndexes]="chartOptions[InsightsChart.APPARITIONS]?.hiddenDatasetIndexes ?? []"
                        (hasDataChange)="gmbImpressionsHasData = $event">
                    </app-statistics-seo-gmb-discoveries>
                </div>
            }
            @if ((displayedCharts | includes: InsightsChart.ACTIONS) && gmbActionsHasData) {
                <div class="min-w-0 flex-1 break-inside-avoid">
                    <app-statistics-seo-gmb-actions
                        [showViewByTextInsteadOfSelector]="true"
                        [viewBy]="chartOptions[InsightsChart.ACTIONS]?.viewBy"
                        [hiddenDatasetIndexes]="chartOptions[InsightsChart.ACTIONS]?.hiddenDatasetIndexes ?? []"
                        (hasDataChange)="gmbActionsHasData = $event">
                    </app-statistics-seo-gmb-actions>
                </div>
            }
        </div>
    }
</ng-template>
