<div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
    <div>
        <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
    </div>
    <div>
        <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
    </div>
    <div>
        <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
    </div>
</div>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'statistics.seo.gmb_actions.actions' | translate }}</span>
        @if (!showViewByTextInsteadOfSelector()) {
            <div class="flex items-center gap-2">
                <div class="malou-text-14--medium md:hidden">{{ 'statistics.seo.gmb_actions.view_by' | translate }}</div>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-gmb-actions-chart
        [gmbActionsData]="gmbActionsData()"
        [labels]="dateLabels()"
        [previousLabels]="previousDateLabels()"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)">
    </app-gmb-actions-chart>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 md:flex-col">
        <ng-container
            [ngTemplateOutlet]="infoCardTemplate"
            [ngTemplateOutletContext]="{
                title: 'statistics.seo.gmb_actions.actions' | translate,
                tooltip: 'statistics.seo.gmb_actions.user_actions_number_tooltip' | translate,
                value: dailyInsightsChartData().totalActions,
                displayedValue: dailyInsightsChartData().totalActions | shortNumber,
                evolution: actionsEvolutionPercentage(),
                displayedEvolution: actionsEvolutionPercentage() | shortNumber: { content: '%', shouldDisplayMinusSign: false },
                comparisonPeriodData: dailyPreviousInsightsChartData().totalActions | shortNumber,
            }"></ng-container>

        <ng-container
            [ngTemplateOutlet]="infoCardTemplate"
            [ngTemplateOutletContext]="{
                title: 'statistics.seo.gmb_actions.conversion_rate' | translate,
                tooltip: 'statistics.seo.gmb_actions.conversion_rate_tooltip' | translate,
                value: dailyInsightsChartData().ratioActionsOverImpressions,
                displayedValue: dailyInsightsChartData().ratioActionsOverImpressions | shortNumber: { content: '%' },
                evolution: conversionRateEvolution(),
                displayedEvolution: conversionRateEvolution() | shortNumber: { content: '%', shouldDisplayMinusSign: false },
                comparisonPeriodData: dailyPreviousInsightsChartData().ratioActionsOverImpressions | shortNumber: { content: '%' },
            }"></ng-container>
    </div>
</ng-template>

<ng-template
    let-title="title"
    let-tooltip="tooltip"
    let-value="value"
    let-displayedValue="displayedValue"
    let-evolution="evolution"
    let-displayedEvolution="displayedEvolution"
    let-shouldShowComparisonPeriodData="shouldShowComparisonPeriodData"
    let-comparisonPeriodData="comparisonPeriodData"
    #infoCardTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1" [matTooltip]="tooltip">
                {{ displayedValue }}
            </div>
            @if (value) {
                <app-number-evolution
                    [matTooltip]="comparedToKey() | translate"
                    [value]="evolution"
                    [displayedValue]="displayedEvolution"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
            {{ title }}
        </div>
        <div
            class="malou-text-10--regular malou-color-text-2 mt-1 whitespace-nowrap italic"
            [matTooltip]="comparisonPeriodKey() | translate">
            ({{ comparisonPeriodData }})
        </div>
    </div></ng-template
>
