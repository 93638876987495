import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Input, model } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { APP_DEFAULT_LANGUAGE, getTimeDifferenceInHours, KeywordScoreTextType, MIN_POSITIVE_REVIEW_RATING } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { Keyword, Restaurant } from ':shared/models';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';

@Component({
    selector: 'app-review-reply-preview',
    templateUrl: './review-reply-preview.component.html',
    styleUrls: ['./review-reply-preview.component.scss'],
    standalone: true,
    imports: [KeywordsScoreGaugeComponent, TranslateModule, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewReplyPreviewComponent {
    @Input() restaurant$: BehaviorSubject<Restaurant | null>;
    @Input() restaurantKeywords$: Observable<Keyword[]>;
    @Input() restaurantAiSettings$: Observable<RestaurantAiSettings | undefined>;
    @Input() reviewerName$: Observable<string> = of('');
    readonly reviewCommentSavedScore = input<number | null>(null);
    readonly rating = input.required<number>();
    readonly replyText = input.required<string>();
    readonly keywordsLang = input<string | undefined>();
    readonly lang = input<string | undefined>();
    readonly reviewSocialCreatedAt = input.required<Date>();
    readonly commentDate = input.required<Date | string | null>();
    readonly keywordScoreReviewSpecificData = input<{ reviewRelatedBricksCount?: number; reviewText: string } | null>(null);
    readonly isReplySectionHidden = model(false);

    private readonly _experimentationService = inject(ExperimentationService);

    readonly replyText$ = toObservable<string>(this.replyText);

    readonly gaugeTextType = computed(() => this._getTextTypeScore(this.rating()));
    readonly gaugeTextType$ = toObservable(this.gaugeTextType);

    readonly computedLang = computed(() => this.keywordsLang() ?? this.lang() ?? APP_DEFAULT_LANGUAGE);
    readonly lang$ = toObservable(this.computedLang);

    readonly responseTime = computed(() => this._getResponseTime(this.reviewSocialCreatedAt(), this.commentDate()));
    readonly responseTime$ = toObservable(this.responseTime);
    readonly isReviewRevampFeatureEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-review-revamp'), {
        initialValue: false,
    });

    onEditButton(): void {
        this.isReplySectionHidden.update((value) => !value);
    }

    private _getTextTypeScore(rating: number): KeywordScoreTextType {
        return rating < MIN_POSITIVE_REVIEW_RATING ? KeywordScoreTextType.LOW_RATE_REVIEW : KeywordScoreTextType.HIGH_RATE_REVIEW;
    }

    private _getResponseTime(socialCreatedAt: Date, commentDate: Date | string | null): number {
        commentDate = typeof commentDate === 'string' ? new Date(commentDate) : commentDate;
        return Math.floor(getTimeDifferenceInHours(commentDate ?? new Date(), socialCreatedAt));
    }
}
