import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const getProfilePictureUrlParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        platformKey: data.platform_key,
        restaurantId: data.restaurant_id,
    }));

export type GetProfilePictureUrlParamsDto = z.infer<typeof getProfilePictureUrlParamsValidator>;
