import { ApplicationLanguage } from '../../constants';
import { TranslationSource } from '../translations';

export interface IBreakdown<Category = string> {
    text: string;
    category: Category;
    translations?: ITranslations;
}

export interface ITranslations {
    id: string;
    fr?: string;
    en?: string;
    es?: string;
    it?: string;
    language: ApplicationLanguage;
    source: TranslationSource;
}

export enum KeywordPopularity {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    PENDING = 'pending',
}

export interface RankingPosition {
    position: RankingPositionOutOf | null | undefined;
    createdAt: Date;
}

export interface RankingPositionOutOf {
    /** Infinity if the restaurant was not present in the search result */
    rank: number;
    outOf: number;
}

export interface RankHistoryItem {
    /** ISO 8601 */
    fetchDate: string;

    /**
     * `null` if the restaurant was not in the first 20 results.
     *
     * The rank of the most popular keyword is 1.
     *
     * This field is null if an error occurred, if the restaurant was not present in
     * search results or if we don’t have enough data.
     */
    rank: number | null;

    outOf: number;
}
