import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { forkJoin, Observable, take } from 'rxjs';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import { MalouMetric, PostType } from '@malou-io/package-utils';

import { CommunityChartData } from ':modules/statistics/social-networks/social-networks.interfaces';
import { selectFollowersDataV2, selectPostsWithInsightsData } from ':modules/statistics/store/statistics.selectors';
import { formatDateToISO } from ':shared/helpers';
import { PostsWithInsightsByPlatforms, PostWithInsights } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface PublicationsData {
    postsWithInsights: PostsWithInsightsByPlatforms;
    followersInsights: CommunityChartData;
}

@Injectable({ providedIn: 'root' })
export class PublicationsCsvInsightsV2Service extends AbstractCsvService<PublicationsData> {
    constructor(
        private readonly _store: Store,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {
        super();
    }

    protected override _getData$(): Observable<PublicationsData> {
        return forkJoin({
            postsWithInsights: this._store.select(selectPostsWithInsightsData).pipe(take(1)),
            followersInsights: this._store.select(selectFollowersDataV2).pipe(take(1)),
        });
    }
    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Type', 'Caption', 'Platform', 'Impressions', 'Likes', 'Comments', 'Shares', 'Saves', 'Engagement Rate'];
    }
    protected override _getCsvDataRows({ postsWithInsights, followersInsights }: PublicationsData): CsvAsStringArrays {
        return postsWithInsights
            .map((platformPostsWithInsights) => {
                const platformKey = Object.keys(platformPostsWithInsights)[0];
                const posts = platformPostsWithInsights[platformKey]?.data;
                if (!posts?.length) {
                    return [];
                }
                return posts.map((post) => {
                    const postWithInsights = new PostWithInsights({
                        ...post,
                        key: platformKey,
                        nbFollowers: this._getFollowerCountByDate(new Date(post.createdAt), followersInsights[platformKey]?.insights),
                    });

                    const postDate = new Date(postWithInsights.createdAt).toLocaleDateString();
                    const type = postWithInsights.postType === PostType.REEL ? 'Reel' : 'Post';
                    const caption = postWithInsights.caption ?? '';
                    const platform = this._enumTranslatePipe.transform(platformKey, 'platform_key');
                    const impressions = postWithInsights.impressions?.toString() ?? '0';
                    const likes = postWithInsights.likes?.toString() ?? '0';
                    const comments = postWithInsights.comments?.toString() ?? '0';
                    const shares = postWithInsights.shares?.toString() ?? '0';
                    const saves = postWithInsights.saved?.toString() ?? '0';
                    const engagementRate =
                        typeof postWithInsights.engagementRate === 'number'
                            ? this._shortNumberPipe.transform(postWithInsights.engagementRate)
                            : '';

                    return [postDate, type, caption, platform, impressions, likes, comments, shares, saves, engagementRate];
                });
            })
            .flat();
    }

    private _getFollowerCountByDate(date: Date, followersByDay: DailyPlatformInsights['insights']): number | null {
        const formattedTargetDate = formatDateToISO(
            DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate()
        );
        return formattedTargetDate ? (followersByDay?.[MalouMetric.FOLLOWERS]?.[formattedTargetDate] ?? null) : null;
    }
}
