import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GetReviewAnalysesChartDataByRestaurantIdResponseDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';

@Injectable({ providedIn: 'root' })
export class SegmentAnalysesService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/segment-analyses`;

    constructor(private readonly _http: HttpClient) {}

    getSegmentAnalysesChartData(
        filters: ReviewAnalysesChartFilter
    ): Observable<ApiResultV2<GetReviewAnalysesChartDataByRestaurantIdResponseDto>> {
        const cleanFilters = {
            ...filters,
            startDate: filters.startDate ? filters.startDate.toISOString() : null,
            endDate: filters.endDate ? filters.endDate.toISOString() : null,
        };
        return this._http.post<ApiResultV2<GetReviewAnalysesChartDataByRestaurantIdResponseDto>>(
            `${this.API_BASE_URL}/chart`,
            cleanFilters
        );
    }
}
