import { LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsHttpErrorPipe } from ':modules/aggregated-statistics/aggregated-statistics-http-error.pipe';
import { ReviewsRatingsAverageChartComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average-chart/reviews-ratings-average-chart.component';
import { ReviewsRatingsAverageTableComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average-table/reviews-ratings-average-table.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ChartDataElement, isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { Address, ChartReviewsStats, DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

export interface ReviewsRatingsAverageData {
    averageRating: ChartDataElement;
    restaurant: {
        _id: string;
        name: string;
        address: Address | null;
        internalName?: string;
    };
    byPlatforms: {
        platformKey: PlatformKey;
        average: ChartDataElement;
    }[];
}

@Component({
    selector: 'app-reviews-ratings-average',
    templateUrl: './reviews-ratings-average.component.html',
    styleUrls: ['./reviews-ratings-average.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NumberEvolutionComponent,
        ReviewsRatingsAverageChartComponent,
        ReviewsRatingsAverageTableComponent,
        SelectComponent,
        SkeletonComponent,
        FormsModule,
        MatButtonToggleModule,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        AggregatedStatisticsHttpErrorPipe,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        LowerCasePipe,
        ShortNumberPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewsRatingsAverageComponent implements OnInit, KillSubscriptions {
    @Input() chartSortBy?: ChartSortBy;
    @Input() tableSort?: Sort;
    @Input() viewMode?: StatisticsDataViewMode;
    @Input() showViewModeToggle = true;
    @Output() chartSortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() viewModeChange: EventEmitter<StatisticsDataViewMode> = new EventEmitter<StatisticsDataViewMode>();
    @Input() showSortByTextInsteadOfSelector = false;
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);
    @Input() expandTable = false;

    readonly SvgIcon = SvgIcon;
    readonly StatisticsDataViewMode = StatisticsDataViewMode;
    viewModeSelected = StatisticsDataViewMode.CHART;

    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    reviewsRatingsAverageData: ReviewsRatingsAverageData[];
    previousReviewsRatingsAverageData: ReviewsRatingsAverageData[];

    totalAverageRating: number | null = null;
    totalAverageRatingEvolution: number | null = null;

    httpError: any;
    isLoading = signal(true);

    warningTooltip: string | null = null;
    errorTooltip: string | null = null;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly chartSortByControl: FormControl = new FormControl<ChartSortBy>(ChartSortBy.DESC);
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);

    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.chartSortBy) {
            this.chartSortByControl.setValue(this.chartSortBy);
        }
        if (this.viewMode) {
            this.viewModeSelected = this.viewMode;
        }
        combineLatest([this.restaurants$, this.dates$, this.platformKeys$])
            .pipe(
                filter(([_restaurants, dates, platformKeys]) => isDateSetOrGenericPeriod(dates) && platformKeys.length > 0),
                tap(([restaurants]) => {
                    this._reset();
                    this.warningTooltip = this._getWarningTooltip(restaurants);
                }),
                map(([restaurants, dates, platformKeys]) => [restaurants.filter((e) => !e.isBrandBusiness()), dates, platformKeys]),
                switchMap(([restaurants, dates, platformKeys]: [Restaurant[], DatesAndPeriod, PlatformKey[]]) => {
                    const restaurantIds = restaurants.map((e) => e._id);
                    if (!dates.startDate || !dates.endDate) {
                        throw new Error('No dates in ReviewsRatingsAverageComponent filter');
                    }
                    const body = {
                        startDate: dates.startDate.toISOString(),
                        endDate: dates.endDate.toISOString(),
                        platformKeys,
                        restaurantIds,
                        previousPeriod: false,
                    };
                    const bodyWithPreviousPeriod = {
                        ...body,
                        previousPeriod: true,
                    };
                    return forkJoin([
                        this._reviewsService.getChartReviewsForUserRestaurants(body),
                        this._reviewsService.getChartReviewsForUserRestaurants(bodyWithPreviousPeriod),
                        of(restaurants),
                    ]);
                }),
                map(([currentData, previousData, restaurants]): [ChartReviewsStats[], ChartReviewsStats[]] => {
                    const indexesInError: number[] = [];
                    const restaurantsIndexesInError: number[] = [];
                    restaurants.forEach((restaurant, index) => {
                        if (!currentData.find((data) => data.restaurant._id === restaurant._id)) {
                            restaurantsIndexesInError.push(index);
                            return;
                        }
                        if (!currentData.find((data) => data.restaurant._id === restaurant._id)?.reviews.length) {
                            indexesInError.push(currentData.findIndex((data) => data.restaurant._id === restaurant._id));
                            restaurantsIndexesInError.push(index);
                        }
                    });
                    const restaurantsInError = restaurants.filter((_e, index) => restaurantsIndexesInError.includes(index));
                    this.errorTooltip = this._getErrorTooltip(restaurantsInError);
                    return [
                        currentData.filter((_e, index) => !indexesInError.includes(index)),
                        previousData.filter((_e, index) => !indexesInError.includes(index)),
                    ];
                }),
                catchError((error) => {
                    this.httpError = error;
                    this.hasDataChange.emit(false);
                    this.isLoading.set(false);
                    return EMPTY;
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([currentData, previousData]: [ChartReviewsStats[], ChartReviewsStats[]]) => {
                    if (!currentData.length) {
                        this.hasDataChange.emit(false);
                    }
                    this.reviewsRatingsAverageData = this._computeChartData(currentData);
                    this._store.dispatch(
                        AggregatedStatisticsActions.editAverageReviewsRatingsData({ data: this.reviewsRatingsAverageData })
                    );

                    this.previousReviewsRatingsAverageData = this._computeChartData(previousData);

                    this.totalAverageRating = this._computeTotalAverageRating(currentData);
                    const previousTotalAverageRating = this._computeTotalAverageRating(previousData);
                    if (this.totalAverageRating !== null && previousTotalAverageRating !== null) {
                        this.totalAverageRatingEvolution = this.totalAverageRating - previousTotalAverageRating;
                    } else {
                        this.totalAverageRatingEvolution = null;
                    }

                    this.isLoading.set(false);
                },
            });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onChartSortByChange(sortBy: ChartSortBy): void {
        this.chartSortByControl.setValue(sortBy);
        this.chartSortByChange.emit(sortBy);
    }

    onTableSortChange(sort: Sort): void {
        this.tableSortByChange.emit(sort);
    }

    onToggleChange(value: StatisticsDataViewMode): void {
        this.viewModeSelected = value;
        this.viewModeChange.emit(value);
    }

    private _computeTotalAverageRating(chartReviewsStats: ChartReviewsStats[]): number | null {
        if (!chartReviewsStats.length) {
            return null;
        }
        return chartReviewsStats.reduce((acc, cur) => acc + (cur.averageRating ?? 0), 0) / chartReviewsStats.length;
    }

    private _computeChartData(dataArray: ChartReviewsStats[]): ReviewsRatingsAverageData[] {
        return dataArray
            .map((data) => ({
                restaurant: data.restaurant,
                averageRating: data.averageRating ?? 0,
                byPlatforms: Object.values(groupBy(data.reviews, 'key')).map((reviews) => ({
                    platformKey: reviews[0].key as PlatformKey,
                    average: reviews.length
                        ? reviews.map((review) => review.rating).reduce((acc, rating) => acc + rating, 0) / reviews.length
                        : 0,
                })),
            }))
            .sort((a, b) => {
                if (!a.restaurant.internalName || !b.restaurant.internalName) {
                    return 0;
                }
                return a.restaurant.internalName.localeCompare(b.restaurant.internalName);
            });
    }

    private _getWarningTooltip(restaurants: Restaurant[]): string | null {
        const selectedBrandBusiness = restaurants.filter((r) => r.isBrandBusiness());
        if (!selectedBrandBusiness.length) {
            return null;
        }
        const restaurantsLabel = selectedBrandBusiness.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _getErrorTooltip(restaurants: Restaurant[]): string | null {
        if (restaurants?.length) {
            const restaurantsLabel = restaurants.map((e) => e.internalName).join(', ');
            return this._translate.instant('aggregated_statistics.errors.gmb_fetching_error', {
                restaurants: restaurantsLabel,
            });
        }
        return null;
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
    }
}
