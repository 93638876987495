import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { Review, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { SegmentAnalysis } from ':shared/models/segment-analysis';

@Injectable({ providedIn: 'root' })
export class ReviewAnalysisHighlighter {
    constructor(private readonly _sanitizer: DomSanitizer) {}

    getHighlightedReviewTextHtml(review: Review | PrivateReview, isNewSemanticAnalysisFeatureEnabled: boolean): SafeHtml {
        const reviewSegments = isNewSemanticAnalysisFeatureEnabled
            ? review?.semanticAnalysisSegments
            : review?.semanticAnalysis?.segmentAnalyses;

        if (!reviewSegments?.length || !review.text) {
            return review.text ?? '';
        }
        let reviewText = review.text;
        reviewSegments?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            reviewText = reviewText.toLowerCase().replace(element.segment.toLowerCase(), highlightedSegment);
        });
        return this._sanitizer.bypassSecurityTrustHtml(reviewText);
    }

    setReviewTextHtmlSegmentsHighlights(
        review: Review | PrivateReview,
        segmentAnalysis: SegmentAnalyses | SegmentAnalysis,
        isNewSemanticAnalysisFeatureEnabled: boolean
    ): SafeHtml {
        const sameSegmentAnalysis = isNewSemanticAnalysisFeatureEnabled
            ? review.semanticAnalysisSegments?.filter(
                  (item) => item.sentiment === segmentAnalysis.sentiment && item.category === (segmentAnalysis as SegmentAnalysis).category
              )
            : review.semanticAnalysis?.segmentAnalyses?.filter(
                  (item) => item.sentiment === segmentAnalysis.sentiment && item.tag === (segmentAnalysis as SegmentAnalyses).tag
              );

        let highlightedReviewText = review.text ?? '';
        sameSegmentAnalysis?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            highlightedReviewText = highlightedReviewText.toLowerCase().replace(element.segment.toLowerCase(), highlightedSegment);
        });

        return this._sanitizer.bypassSecurityTrustHtml(highlightedReviewText);
    }

    // TODO: Remove SegmentAnalyses type when feature toggle 'release-new-semantic-analysis' is removed
    private _getHighlightedSegment(element: SegmentAnalyses | SegmentAnalysis): string {
        const color = element.sentiment === ReviewAnalysisSentiment.POSITIVE ? '#34B467' : '#EE116E';
        return `<span style="color: ${color};">${element.segment.toLowerCase()}</span>`;
    }
}
