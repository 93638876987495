<div class="flex items-center justify-between border-t border-malou-color-border-primary px-6 py-4">
    <button class="malou-text-14--semibold !text-malou-color-text-2" mat-button (click)="onCancel()">
        {{ 'common.cancel' | translate }}
    </button>

    <div class="flex items-center gap-x-3">
        @if (selectedOption() !== SubmitPublicationStatus.NOW) {
            <app-post-date-picker
                [selectedDate]="selectedDate()"
                [size]="PostDatePickerSize.BIG"
                [withBorder]="true"
                (selectedDateChange)="onSelectedDateChange($event)"></app-post-date-picker>
        }

        <div class="wrapper relative">
            @if (finalPostErrors().length > 0) {
                <div class="post-errors-tooltip flex flex-col gap-y-2">
                    @for (error of finalPostErrors(); track error) {
                        <div class="malou-text-12--regular text-white">- {{ error }}</div>
                    }
                </div>
            }
            <app-dropdown-button
                [(selectedOption)]="selectedOption"
                [options]="DROPDOWN_OPTIONS"
                [displayOption]="displayPublicationStatusOption"
                [size]="MenuButtonSize.LARGE"
                [disabled]="disabled()"
                (buttonClick)="onDropdownButtonClick($event)">
                <button class="flex !px-5" mat-menu-item>
                    <span class="malou-text-14--regular text-malou-color-text-2">{{ 'social_posts.new_social_post.now' | translate }}</span>
                </button>
                <button class="flex !px-5" mat-menu-item>
                    <span class="malou-text-14--regular text-malou-color-text-2">{{
                        'social_posts.new_social_post.later' | translate
                    }}</span>
                </button>
                <button class="flex !px-5" mat-menu-item>
                    <span class="malou-text-14--regular text-malou-color-text-2">{{ 'posts.new_post.draft' | translate }}</span>
                </button>
            </app-dropdown-button>
        </div>
    </div>
</div>
