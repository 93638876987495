import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { RestaurantReviewsCountChartData } from '../reviews-count.interface';

enum TableField {
    RESTAURANT_NAME = 'restaurantName',
    REVIEW_COUNT = 'reviewCount',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.REVIEW_COUNT,
    direction: ChartSortBy.DESC,
};

interface DataSource {
    [TableField.RESTAURANT_NAME]: string;
    [TableField.REVIEW_COUNT]: number;
    evolution: number;
}

@Component({
    selector: 'app-reviews-count-table',
    templateUrl: './reviews-count-table.component.html',
    styleUrls: ['./reviews-count-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTableModule, MatSortModule, TranslateModule, NumberEvolutionComponent, ShortNumberPipe],
})
export class ReviewsCountTableComponent implements OnInit, OnChanges {
    @Input() data: RestaurantReviewsCountChartData[];
    @Input() sortBy: Sort | undefined;
    @Output() sortChange: EventEmitter<Sort> = new EventEmitter();

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
            if (this.sortBy) {
                this.dataSource.sort.sort({ id: this.sortBy.active, start: this.sortBy.direction, disableClear: true });
            }
        }
    }

    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;
    readonly displayedColumns = Object.values(TableField);

    readonly dataSource: MatTableDataSource<DataSource> = new MatTableDataSource();

    ngOnInit(): void {
        this.updateMatDataSource(this.data);
    }

    updateMatDataSource(data: RestaurantReviewsCountChartData[]): void {
        this.dataSource.data = this.computeDataSource(data);
    }
    computeDataSource(data: RestaurantReviewsCountChartData[]): DataSource[] {
        return data.map((d) => ({
            [TableField.RESTAURANT_NAME]: d.restaurantName,
            [TableField.REVIEW_COUNT]: d.total,
            evolution: d.evolution,
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'data')) {
            this.updateMatDataSource(this.data);
        }
    }
}
