@if (isLoading()) {
    <app-skeleton skeletonClass="secondary-bg h-full"></app-skeleton>
} @else {
    <div class="w-full rounded-[10px] bg-malou-color-background-dark @container" [ngClass]="{ hidden: !hasData() }">
        <div class="flex items-center p-2 @md:p-4 @lg:p-8">
            <img
                class="m-2 h-20 rotate-[-18deg] @md:m-4 @md:h-24 @lg:m-6 @lg:h-28"
                alt="Search illustration"
                [src]="Illustration.Search | illustrationPathResolver" />
            <div class="flex h-full flex-col gap-y-2">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ 'statistics.e_reputation.reviews_rating_calculator.title' | translate }}
                </div>
                @if (hasMaximumStarRating()) {
                    <ng-container [ngTemplateOutlet]="maximumStarRatingTemplate"></ng-container>
                } @else {
                    <ng-container [ngTemplateOutlet]="sliderTemplate"></ng-container>
                }
            </div>
        </div>
    </div>
}

<ng-template #sliderTemplate>
    <div class="malou-text-10--regular @md:malou-text-11--regular @lg:malou-text-12--regular">
        {{ 'statistics.e_reputation.reviews_rating_calculator.slider_description' | translate }}
    </div>
    <mat-slider
        class="malou-mat-slider !mt-2 @md:!mt-4 @lg:!mt-6"
        id="reviews-rating-calculator-slider"
        color="primary"
        discrete
        [max]="MAXIMUM_RATING"
        [step]="DEFAULT_INCREMENT"
        [min]="initialRating()"
        #ngSlider>
        <input matSliderThumb [value]="ratingGoal()" (input)="onSliderInput($event)" />
    </mat-slider>
    @if (hasReachedGoal()) {
        <ng-container [ngTemplateOutlet]="goalReachedTextTemplate"></ng-container>
    } @else {
        <ng-container [ngTemplateOutlet]="goalNotReachedTextTemplate"></ng-container>
    }
</ng-template>

<ng-template #maximumStarRatingTemplate>
    <div class="mt-12 flex items-center gap-x-2">
        <img class="mt-1.5 h-7 w-7 sm:mb-5" [src]="Emoji.INDEX_POINTING_RIGHT | emojiPathResolver" />
        <div class="malou-text-18--bold @md:malou-text-22--bold @lg:malou-text-24--bold text-malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_rating_calculator.maximum_stars.congratulations' | translate }}
        </div>
    </div>
    <div class="flex items-center gap-x-2">
        <div class="malou-text-16 italic text-malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_rating_calculator.maximum_stars.text' | translate }}
        </div>
        <img class="mt-1.5 h-7 w-7 sm:mb-5" [src]="Emoji.PARTYING_FACE | emojiPathResolver" />
    </div>
</ng-template>

<ng-template #goalReachedTextTemplate>
    <div class="flex items-center gap-x-2 text-malou-color-text-1">
        <img class="h-7 w-7 sm:mb-5" [src]="Emoji.INDEX_POINTING_RIGHT | emojiPathResolver" />
        <span class="malou-text-18--bold @md:malou-text-22--bold @lg:malou-text-24--bold">
            {{ 'statistics.e_reputation.reviews_rating_calculator.goal_reached.congratulations' | translate }}
        </span>
        <span class="malou-text-11--bold @md:malou-text-13--bold @lg:malou-text-15--bold mt-1.5">
            {{ 'statistics.e_reputation.reviews_rating_calculator.goal_reached.your_rating' | translate: { rating: rating() } }}
        </span>
    </div>
</ng-template>

<ng-template #goalNotReachedTextTemplate>
    <div class="flex items-center gap-x-2">
        <img class="h-7 w-7 sm:mb-5" [src]="Emoji.INDEX_POINTING_RIGHT | emojiPathResolver" />
        <div
            class="malou-text-18--bold @md:malou-text-22--bold @lg:malou-text-24--bold text-malou-color-text-1"
            data-testid="reviews-rating-calculator-stars-to-reach-goal">
            {{
                'statistics.e_reputation.reviews_rating_calculator.goal_not_reached.need_reviews'
                    | translate: { numberOfReviewsToReachGoal: numberOfReviewsToReachGoal() }
            }}
        </div>
    </div>
</ng-template>
