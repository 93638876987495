import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { MAX_USER_FILTERS_SELECTABLE_RESTAURANTS } from ':shared/constants/filters';
import { Restaurant } from ':shared/models';

import { selectOwnRestaurants } from '../restaurant-list/restaurant-list.reducer';
import { AggregatedStatisticsFiltersContext } from './filters/filters.context';
import * as AggregatedStatisticsActions from './store/aggregated-statistics.actions';

@Component({
    selector: 'app-aggregated-statistics',
    templateUrl: './aggregated-statistics.component.html',
    standalone: true,
    imports: [RouterOutlet, ScrollToTopComponent],
})
export class AggregatedStatisticsComponent implements OnInit {
    restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);

    constructor(
        private readonly _store: Store,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {}

    ngOnInit(): void {
        this._initFilterSelection();
    }

    private _initFilterSelection(): void {
        this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)).subscribe((restaurantsSelected: Restaurant[]) => {
            if (restaurantsSelected.length === 0) {
                this.restaurants$
                    .pipe(
                        filter((restaurants) => restaurants.length > 0),
                        take(1)
                    )
                    .subscribe((restaurants: Restaurant[]) => {
                        this._store.dispatch(
                            AggregatedStatisticsActions.editRestaurants({
                                restaurants: restaurants
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .slice(0, MAX_USER_FILTERS_SELECTABLE_RESTAURANTS),
                            })
                        );
                    });
            }
        });
    }
}
