import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, output, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { difference, groupBy, isEqual, isEqualWith, uniq } from 'lodash';
import { combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';

import { InsightsChart, isSameDay, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ReviewsCountV2Component } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count-v2.component';
import { ReviewsRatingKpisV2Component } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-rating-kpis/reviews-rating-kpis-v2.component';
import { ReviewsRatingsAverageV2Component } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average-v2.component';
import { ChartReviewsDataWithPreviousPeriod } from ':modules/aggregated-statistics/e-reputation/reviews/reviews.interface';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ChartOptions, StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { ChartReviewsStatsByRestaurantId, ChartReviewsStatsDto, DatesAndPeriod, Restaurant } from ':shared/models';

const AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT = 10;

@Component({
    selector: 'app-reviews-insights',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
    standalone: true,
    imports: [NgClass, AsyncPipe, ReviewsCountV2Component, ReviewsRatingKpisV2Component, ReviewsRatingsAverageV2Component],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsInsightsComponent implements OnInit {
    sortByChange = output<{ chart: InsightsChart; value: ChartSortBy }>();
    tableSortOptionsChange = output<{ chart: InsightsChart; value: Sort }>();
    viewModeChange = output<{ chart: InsightsChart; value: StatisticsDataViewMode }>();
    isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    readonly screenSizeService = inject(ScreenSizeService);

    readonly PlatformFilterPage = PlatformFilterPage;
    readonly InsightsChart = InsightsChart;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)));
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter).pipe(
        distinctUntilChanged((prev, curr) => {
            const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
            const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
            return isSameStartDate && isSameEndDate;
        })
    );
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqualWith(prev, curr, (c, d) => c.id === d.id))
    );
    readonly isRestaurantsCountUiLimitExceeded$: Observable<boolean> = this.selectedRestaurants$.pipe(
        map((restaurants) => restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT)
    );
    chartReviewsDataWithPreviousPeriod$: Observable<ChartReviewsDataWithPreviousPeriod>;

    readonly reviewsRatingKpisDisplayedColumnsCount: WritableSignal<number> = signal(0);
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.AGGREGATED_REVIEWS_COUNT]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS]: {
            tableSortOptions: undefined,
        },
    });

    readonly currentChartReviewsStatsByRestaurantId: WritableSignal<ChartReviewsStatsByRestaurantId> = signal(
        ChartReviewsStatsByRestaurantId.fromDto([])
    );
    readonly previousChartReviewsStatsByRestaurantId: WritableSignal<ChartReviewsStatsByRestaurantId> = signal(
        ChartReviewsStatsByRestaurantId.fromDto([])
    );

    readonly isReviewsRatingsKpisLoading = signal(false);
    readonly isReviewsCountLoading = signal(false);
    readonly isReviewsRatingsAverageLoading = signal(false);

    isLoading = computed(() => this.isReviewsRatingsKpisLoading() || this.isReviewsCountLoading() || this.isReviewsRatingsAverageLoading());

    // TODO: Remove when the feature flag is removed
    readonly isReleaseReviewPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-review-insights-v2'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-review-insights-v2'),
        }
    );

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.E_REPUTATION }));
        this.chartReviewsDataWithPreviousPeriod$ = combineLatest([
            this.selectedRestaurants$,
            this.dates$.pipe(tap(() => this._resetChartData())),
            this.platformKeys$.pipe(tap(() => this._resetChartData())),
        ]).pipe(
            filter(([_restaurants, dates, platformKeys]) => isDateSetOrGenericPeriod(dates) && platformKeys.length > 0),
            map(([restaurants, dates, platformKeys]) => [
                restaurants.filter((restaurant) => !restaurant.isBrandBusiness()),
                dates,
                platformKeys,
            ]),
            tap(() => {
                this.isReviewsCountLoading.set(true);
                this.isReviewsRatingsAverageLoading.set(true);
            }),
            switchMap(([restaurants, dates, platformKeys]: [Restaurant[], DatesAndPeriod, PlatformKey[]]) => {
                const restaurantIds = restaurants.map((e) => e._id);
                if (!dates.startDate || !dates.endDate) {
                    throw new Error('No dates in ReviewsRatingsAverageComponent filter');
                }

                const alreadyFetchedRestaurantIds = uniq([
                    ...this.currentChartReviewsStatsByRestaurantId().getPresentRestaurantIds(),
                    ...this.previousChartReviewsStatsByRestaurantId().getPresentRestaurantIds(),
                ]);
                const restaurantIdsToFetch = difference(restaurantIds, alreadyFetchedRestaurantIds);
                const restaurantIdsToDelete = difference(alreadyFetchedRestaurantIds, restaurantIds);
                if (restaurantIdsToDelete.length) {
                    this.currentChartReviewsStatsByRestaurantId.update((current) => current.deleteMany(restaurantIdsToDelete));
                    this.previousChartReviewsStatsByRestaurantId.update((previous) => previous.deleteMany(restaurantIdsToDelete));
                }
                if (!restaurantIdsToFetch.length) {
                    return forkJoin([
                        of(ChartReviewsStatsByRestaurantId.fromDto([])),
                        of(ChartReviewsStatsByRestaurantId.fromDto([])),
                        of(restaurants),
                    ]);
                }

                const body = {
                    startDate: dates.startDate.toISOString(),
                    endDate: dates.endDate.toISOString(),
                    platformKeys,
                    restaurantIds: restaurantIdsToFetch,
                    previousPeriod: false,
                };
                const bodyWithPreviousPeriod = {
                    ...body,
                    previousPeriod: true,
                };
                const emptyChartData = this._buildEmptyChartData(restaurantIdsToFetch, restaurants);
                return forkJoin([
                    this._reviewsService.getChartReviewsForUserRestaurantsV2(body).pipe(map((data) => emptyChartData.merge(data))),
                    this._reviewsService
                        .getChartReviewsForUserRestaurantsV2(bodyWithPreviousPeriod)
                        .pipe(map((data) => emptyChartData.merge(data))),
                    of(restaurants),
                ]);
            }),
            map(([currentChartReviewsStatsByRestaurantId, previousChartReviewsStatsByRestaurantId, restaurants]) => {
                this.currentChartReviewsStatsByRestaurantId.update((current) => current.merge(currentChartReviewsStatsByRestaurantId));
                this.previousChartReviewsStatsByRestaurantId.update((previous) => previous.merge(previousChartReviewsStatsByRestaurantId));

                return {
                    currentChartReviewsDataByRestaurantId: this.currentChartReviewsStatsByRestaurantId(),
                    previousChartReviewsDataByRestaurantId: this.previousChartReviewsStatsByRestaurantId(),
                    restaurants,
                };
            }),
            shareReplay(1) // Need to use this because Observable is subscribed twice
        );
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.sortByChange.emit({ chart, value });
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.tableSortOptionsChange.emit({ chart, value });
    }

    onViewModeChange(chart: InsightsChart, value: StatisticsDataViewMode): void {
        this.viewModeChange.emit({ chart, value });
    }

    private _resetChartData(): void {
        this.currentChartReviewsStatsByRestaurantId.set(ChartReviewsStatsByRestaurantId.fromDto([]));
        this.previousChartReviewsStatsByRestaurantId.set(ChartReviewsStatsByRestaurantId.fromDto([]));
    }

    /**
     * Return empty data for all restaurants
     * Useful because if we don't retrieve result for a restaurant,
     * we will always do a call for this restaurant when changing the date or platformKeys
     */
    private _buildEmptyChartData(restaurantIds: string[], restaurants: Restaurant[]): ChartReviewsStatsByRestaurantId {
        const restaurantById = groupBy(restaurants, (restaurant) => restaurant._id);
        return ChartReviewsStatsByRestaurantId.fromDto(
            restaurantIds.reduce((acc, restaurantId) => {
                const restaurant = restaurantById[restaurantId]?.[0];
                acc.push({
                    restaurant: {
                        _id: restaurantId,
                        name: restaurant.name,
                        internalName: restaurant.internalName,
                        address: restaurant.address,
                    },
                    reviews: [],
                });
                return acc;
            }, [] as ChartReviewsStatsDto[])
        );
    }
}
