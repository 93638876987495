import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, merge } from 'rxjs';

import { BusinessCategory, PictureSize } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import {
    EditInternalNameModalComponent,
    EditInternalNameModalData,
} from ':modules/restaurant-list/restaurants/edit-internal-name-modal/edit-internal-name-modal.component';
import { RestaurantsModalsService } from ':modules/restaurant-list/restaurants/restaurants.modals.service';
import { SidenavContentRestaurantInfoComponent } from ':modules/sidenav-router/sidenav-content/sidenav-content-restaurant-info/sidenav-content-restaurant-info.component';
import * as SidenavActions from ':modules/sidenav-router/store/sidenav.actions';
import { SidenavState } from ':modules/sidenav-router/store/sidenav.reducer';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { ButtonComponent } from ':shared/components/button/button.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { IsHoveredDirective } from ':shared/directives/is-hovered.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Address, IRestaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-sidenav-content-select-restaurant-panel',
    templateUrl: './sidenav-content-select-restaurant-panel.component.html',
    styleUrl: './sidenav-content-select-restaurant-panel.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        InputTextComponent,
        TranslateModule,
        ApplyPurePipe,
        ImagePathResolverPipe,
        SidenavContentRestaurantInfoComponent,
        ScrollingModule,
        ButtonComponent,
        MatIconModule,
        IsHoveredDirective,
        SkeletonComponent,
    ],
})
export class SidenavContentSelectRestaurantPanelComponent {
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _router = inject(Router);
    private readonly _restaurantsModalsService = inject(RestaurantsModalsService);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly SvgIcon = SvgIcon;
    readonly BusinessCategory = BusinessCategory;
    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, {
        initialValue: this._screenSizeService.isPhoneScreen,
    });
    private readonly _sidenavWidth = this._store.selectSignal(SidenavSelectors.selectSidenavWidth);
    panelStyle = computed(() => (this.isPhoneScreen() ? '' : `margin-left: ${this._sidenavWidth() + 4}px;`));
    readonly isSidenavOpened = toSignal(this._store.select(SidenavSelectors.selectIsOpened), { requireSync: true });

    readonly ownRestaurants$ = merge<SidenavState['ownRestaurants'][]>(
        this._store.select(SidenavSelectors.selectOwnRestaurants),
        this._store.select(selectOwnRestaurants)
    ).pipe(
        filter((array) => array.length !== 0),
        map((array) => [...array])
    );
    readonly ownRestaurants = toSignal(
        this.ownRestaurants$.pipe(
            map((restaurants) =>
                restaurants.sort((restaurantA, restaurantB) => {
                    if (restaurantA.type === BusinessCategory.BRAND && restaurantB.type !== BusinessCategory.BRAND) {
                        return -1;
                    }
                    if (restaurantA.type !== BusinessCategory.BRAND && restaurantB.type === BusinessCategory.BRAND) {
                        return 1;
                    }
                    const restaurantAName = restaurantA.internalName ?? restaurantA.name;
                    const restaurantBName = restaurantB.internalName ?? restaurantB.name;
                    return restaurantAName.localeCompare(restaurantBName);
                })
            )
        ),
        { initialValue: [] }
    );
    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly isAllRestaurantsSelected = computed(() => !this.selectedRestaurant());

    readonly search = signal('');
    readonly filteredOwnRestaurants = computed(() => {
        const searchText = this.search().trim().toLowerCase();
        return this.ownRestaurants().filter(
            (r) => r.name?.trim().toLowerCase().includes(searchText) || r.internalName?.trim().toLowerCase().includes(searchText)
        );
    });

    readonly trackById = TrackByFunctionFactory.get('_id');
    readonly elementClassHeight = 'h-[56px]';
    readonly elementHeight = this.elementClassHeight.slice(3, 5);

    onAllBusinessesClick(mouseEvent: MouseEvent): void {
        const url = '/groups';
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(url, '_blank');
            return;
        }
        this._router.navigate([url]);
        this.togglePanel();
    }

    onBusinessClick(mouseEvent: MouseEvent, restaurantId: string): void {
        const url = this._router.url;
        let newUrl = url;
        if (url.includes('/groups')) {
            // if the current url is group, just redirect to /restaurant/:id
            newUrl = `/restaurants/${restaurantId}`;
        } else {
            // if the current url is already a restaurant one, just change the id in the url so it will keep
            // the url intact (if we are on stats/seo, it will remains on stats/seo)
            const [restaurantsPath, _id, ...rest] = url.split('/').filter(Boolean);
            // clean some query params (folderId) from gallery urls
            const cleanRestOfPaths = this._cleanRestOfPaths(rest);
            newUrl = `${restaurantsPath}/${restaurantId}/${cleanRestOfPaths.join('/')}`;
        }
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(newUrl, '_blank');
            return;
        }
        this._router.navigateByUrl(newUrl);
        this.togglePanel();
    }

    togglePanel(): void {
        this._store.dispatch(SidenavActions.toggleSelectBusinessesPanel());
    }

    onBusinessAdd(): void {
        this._restaurantsModalsService.openConnectRestaurantModal();
    }

    onEditInternalName(restaurantId: string): void {
        this._restaurantsService.show(restaurantId).subscribe((res) =>
            this._customDialogService.open<EditInternalNameModalComponent, EditInternalNameModalData>(EditInternalNameModalComponent, {
                height: undefined,
                data: {
                    restaurant: res.data,
                },
            })
        );
    }

    getFullFormattedAddress(address: IRestaurant['address']): string {
        return new Address(address).getFullFormattedAddress();
    }

    getLogoUrl(logo: IRestaurant['logo']): string | undefined {
        return logo?.urls?.[PictureSize.SMALL] ?? logo?.urls?.[PictureSize.ORIGINAL];
    }

    private _cleanRestOfPaths(rest: string[]): string[] {
        // clean folderId query param from gallery urls
        return rest.map((path) => (path.startsWith('gallery') ? 'gallery' : path));
    }
}
