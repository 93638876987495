import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Scale } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { RankingPosition } from '@malou-io/package-utils';

import { addDay, ChartDataArray, malouChartColorBluePurple, subtractDay } from ':shared/helpers';

type LineChartType = Extract<ChartType, 'line'>;

@Component({
    selector: 'app-keyword-evolution-mini',
    templateUrl: './keyword-evolution-mini.component.html',
    styleUrls: ['./keyword-evolution-mini.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgChartsModule, TranslateModule],
})
export class KeywordEvolutionMiniComponent implements OnInit {
    @Input() positions: RankingPosition[];

    chartLabels: Date[] = [];
    chartDataSets: ChartDataset<LineChartType, ChartDataArray>[];
    chartOptions: ChartOptions<LineChartType>;

    readonly CHART_TYPE: LineChartType = 'line';

    ngOnInit(): void {
        this.positions = this.positions.filter((p) => p.position).sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
        if (this.positions.length === 1) {
            const tempTime = this.positions[0].createdAt;
            this.positions.unshift({ position: null, createdAt: subtractDay(7, new Date(tempTime)) });
            this.positions.push({ position: null, createdAt: addDay(7, new Date(tempTime)) });
        }
        this.chartLabels = this._computeChartLabels(this.positions);
        this.chartDataSets = this._computeChartData(this.positions);
        this.chartOptions = this._computeChartOptions();
    }

    private _computeChartData(positions: RankingPosition[]): ChartDataset<LineChartType, ChartDataArray>[] {
        return [
            {
                data: positions.map((elem) => {
                    // if no data, we consider that the position is 20 (last positioned)
                    if (!elem.position) {
                        return 20;
                    }

                    // if rank is infinity, we consider that the position is outOf (last positioned)
                    if (elem.position.rank === Infinity) {
                        return elem.position.outOf;
                    }

                    return elem.position.rank;
                }),
                borderColor: malouChartColorBluePurple,
                pointStyle: false,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
            },
        ];
    }

    private _computeChartLabels(positions: RankingPosition[]): Date[] {
        return positions.map((elem) => elem.createdAt);
    }

    private _computeChartOptions(): ChartOptions<LineChartType> {
        return {
            plugins: {
                tooltip: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    display: false,
                    axis: 'x',
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    grid: {
                        display: false,
                    },
                },
                yAxis: {
                    display: false,
                    axis: 'y',
                    min: 0,
                    max: 21,
                    reverse: true,
                    // trick not to have the line flatted when the value is min or max
                    afterBuildTicks: function (axis: Scale): void {
                        axis.ticks = [];
                        axis.ticks.push({ value: 1 });
                        axis.ticks.push({ value: 5 });
                        axis.ticks.push({ value: 10 });
                        axis.ticks.push({ value: 15 });
                        axis.ticks.push({ value: 20 });
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        };
    }
}
