import { omit, uniq } from 'lodash';

import { RestaurantKeywordDto } from '@malou-io/package-dto';
import { ApplicationLanguage, computePopularity, IBreakdown, KeywordPopularity, KeywordVolumeProvider } from '@malou-io/package-utils';

import { RankingTableDataRow } from ':modules/keywords/keywords.interface';

export interface KeywordImpression {
    date: Date;
    value: number;
}

/**
 * This class does not really represent a keyword but a link between a restaurant and
 * a keyword. The actual keyword object (shared between many restaurants) is referenced
 * by the field `keywordId`.
 */
export class Keyword {
    restaurantId: string;
    text: string;
    language: string;
    volume: number;
    selected: boolean;
    updatedAt: Date;
    createdAt: Date;
    bricks: IBreakdown[];
    needsCheck: boolean;
    isCustomerInput: boolean;
    volumeFromAdmin: number;
    lastRefresh: Date;
    isFetched: boolean;
    volumeHistory: KeywordVolumeHistory[];
    restaurantKeywordId: string;
    impressionsHistory: KeywordImpression[];

    /** The ID of the actual keyword (keywords are shared between restaurants). */
    keywordId: string;

    apiLocationId: string;

    // This is only for temporary use before loading volumes
    isLoadingVolume = false;

    public constructor(init?: Partial<Keyword>) {
        // TODO improve this when deprecated keyword is removed
        Object.assign(this, omit(init, ['shouldRefetchVolume']));

        this.isFetched = this.volumeHistory?.length > 0 || (this.volumeFromAdmin ?? 0) > 0 || this.volume > 0;
    }

    static fromRestaurantKeywordDto(restaurantKeyword: RestaurantKeywordDto): Keyword {
        const impressionsHistory = restaurantKeyword.impressionsHistory;
        // impressionsHistory.push({
        //     date: '2024-12-01',
        //     value: Math.floor(Math.random() * 1000),
        // });
        return new Keyword({
            restaurantKeywordId: restaurantKeyword.id,
            keywordId: restaurantKeyword.keyword.id,
            restaurantId: restaurantKeyword.restaurantId,
            text: restaurantKeyword.keyword.text,
            language: restaurantKeyword.keyword.language,
            volume: restaurantKeyword.keyword.volume,
            selected: restaurantKeyword.selected,
            updatedAt: restaurantKeyword.updatedAt ? new Date(restaurantKeyword.updatedAt) : undefined,
            createdAt: restaurantKeyword.createdAt ? new Date(restaurantKeyword.createdAt) : undefined,
            bricks: restaurantKeyword.keyword.bricks,
            isCustomerInput: restaurantKeyword.keyword.isCustomerInput,
            volumeFromAdmin: restaurantKeyword.keyword.volumeFromAdmin,
            lastRefresh: restaurantKeyword.lastRankingRefresh ? new Date(restaurantKeyword.lastRankingRefresh) : undefined,
            isFetched:
                restaurantKeyword.keyword.volumeHistory?.length > 0 ||
                (restaurantKeyword.keyword.volumeFromAdmin ?? 0) > 0 ||
                restaurantKeyword.keyword.volume > 0,
            volumeHistory: restaurantKeyword.keyword.volumeHistory.map((volumeHistory) => ({
                fetchDate: new Date(volumeHistory.fetchDate),
                volume: volumeHistory.volume,
                source: volumeHistory.source,
            })),
            impressionsHistory: impressionsHistory.map((impression) => ({
                date: new Date(impression.date),
                value: impression.value,
            })),
            apiLocationId: restaurantKeyword.keyword.apiLocationId,
        });
    }

    getNotNullVolume(): number {
        return this.volumeFromAdmin ?? this.volume;
    }

    shouldRefetchVolume(): boolean {
        return this.selected && this.volume === 0;
    }

    isVolumeFetched(): boolean {
        return this.volumeHistory?.length > 0 || this.volumeFromAdmin > 0 || this.volume > 0;
    }

    getPopularity(keywords: Keyword[]): KeywordPopularity {
        const volume = this.volumeFromAdmin ?? this.volume;

        const volumes = keywords
            .map((keyword) => keyword.volumeFromAdmin ?? keyword.volume)
            .filter((keywordVolume) => keywordVolume || keywordVolume === 0)
            .sort((a, b) => a - b);

        return computePopularity(volume, volumes);
    }

    toRankingTableDataRows(keywords: Keyword[]): RankingTableDataRow {
        return {
            keywordId: this.keywordId,
            restaurantKeywordId: this.restaurantKeywordId,
            language: this.language,
            keyword: this.text,
            volumeFromAPI: this.volume,
            volume: this.getNotNullVolume(),
            lastRefresh: this.lastRefresh,
            isWaiting: false,
            shouldRefetchVolume: this.shouldRefetchVolume(),
            popularity: this.getPopularity(keywords),
        };
    }
}

export interface KeywordVolumeHistory {
    fetchDate: Date;
    volume: number;
    source?: KeywordVolumeProvider;
}

export interface SimpleKeyword {
    keyword: string;
    volume: number | string;
    lang?: string;
}

export class KeywordFactory {
    static createTestKeyword(): Keyword {
        return new Keyword({
            text: 'halal',
            language: 'en',
            volume: 6000,
            restaurantId: 'some restaurant id',
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }
}

export const highlightKeywordsInText = ({
    text,
    keywords,
    restaurantName,
    currentLang,
}: {
    text?: string;
    keywords?: Keyword[];
    restaurantName?: string;
    currentLang?: ApplicationLanguage;
}): string => {
    let formattedTextWithKeywords: string = text ?? '';
    if (restaurantName) {
        const restaurantNameEscapedForRegex = escapeRegExp(restaurantName);
        formattedTextWithKeywords = formattedTextWithKeywords.replace(
            new RegExp(restaurantNameEscapedForRegex, 'gi'),
            (match) => `<b>${match}</b>`
        );
    }

    // TODO: put brick in params directly ?
    const sortedBricks = uniq(
        keywords
            ?.map(({ bricks }) => bricks)
            .flat()
            .map((brick) => brickTranslated(brick, currentLang))
    ).sort((a, b) => b.length - a.length);

    sortedBricks.forEach((brickTextInLang) => {
        if (brickTextInLang) {
            const allOptions = [
                `\\b${brickTextInLang}\\b`,
                `\\b${brickTextInLang.replace(/-/g, ' ')}\\b`,
                `\\b${brickTextInLang.replace(/ /g, '-')}\\b`,
            ];
            const regexOptions = currentLang === ApplicationLanguage.FR ? [...allOptions, `\\b${brickTextInLang}s\\b`] : allOptions;
            regexOptions.forEach((option) => {
                formattedTextWithKeywords = formattedTextWithKeywords.replace(new RegExp(option, 'gi'), (match) => `<b>${match}</b>`);
            });
        }
    });

    formattedTextWithKeywords = formattedTextWithKeywords.replace(/\n/g, '<br/>');
    return formattedTextWithKeywords + ' ';
};

const escapeRegExp = (string: string): string => string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');

const brickTranslated = (brick: IBreakdown<string>, currentLang?: string): string =>
    (currentLang && brick.translations?.[currentLang]) ?? brick.text;
