import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, isEqual, isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
    ApplicationLanguage,
    CallToActionType,
    CountriesWithEvents,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    PostType,
    Role,
} from '@malou-io/package-utils';

import { days, ExtendedPostPublicationStatus } from ':core/constants';
import { PostCategory } from ':core/constants/post-category';
import { CalendarEventsService } from ':core/services/calendar-event.service';
import { DialogService } from ':core/services/dialog.service';
import { DuplicatePostModalService } from ':core/services/duplicate-post-modal.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { getPostCategoryFromKeys, PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { NewPostModalComponent } from ':modules/posts/new-post-modal/new-post-modal.component';
import { isPost } from ':modules/posts/posts-list/posts-list.component';
import { selectCurrentlyPublishingPosts } from ':modules/posts/posts.selectors';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { NewSocialPostModalComponent } from ':modules/social-posts/new-social-post-modal/new-social-post-modal.component';
import { SocialPostsService } from ':modules/social-posts/social-posts.service';
import { DuplicateStoriesService } from ':modules/stories/duplicate-stories.service';
import { EditStoryModalComponent } from ':modules/stories/edit-story-modal/edit-story-modal.component';
import { UserState } from ':modules/user/store/user.reducer';
import { selectUserState } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { BottomActionsModalComponent, MenuOption } from ':shared/components/bottom-actions-modal/bottom-actions-modal.component';
import {
    DuplicatePostData,
    DuplicatePostPreviewModalSubmitData,
    DuplicateSeoPostWithTextGenerationPreviewModalComponent,
} from ':shared/components/duplicate-post-preview-modal/duplicate-seo-post-with-text-generation-preview-modal/duplicate-seo-post-with-text-generation-preview-modal.component';
import {
    DuplicateSocialPostPreviewModalSubmitData,
    DuplicateSocialPostWithTextGenerationPreviewModalComponent,
} from ':shared/components/duplicate-post-preview-modal/duplicate-social-post-with-text-generation-preview-modal/duplicate-social-post-with-text-generation-preview-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    PersonalizePostDuplicationComponent,
    PersonalizePostDuplicationData,
} from ':shared/components/personalize-post-duplication/personalize-post-duplication.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import {
    addDay,
    addMinutes,
    compareDates,
    createDateFromMalouDate,
    createDateWithClientTimeZoneDifference,
    formatDate,
    formatStringDay,
    formatStringMonth,
    getMalouDateFromDate,
    getMonday,
    getMonthRange,
    getTimeStringFromDate,
    isAfterToday,
    isAfterTodayForMalouDate,
    isBetween,
    isControlValueInTimeFormat,
    isSameDay,
    isToday,
    isTodayForMalouDate,
    MalouDate,
    MonthStringSize,
    zeroPad,
} from ':shared/helpers/date';
import { showFeedbackNotification } from ':shared/helpers/show-feedback-notification';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Step } from ':shared/interfaces/step.interface';
import {
    ActionsAfterEditPostClosed,
    ActionsAfterEditStoriesClosed,
    ApiResult,
    Keyword,
    Post,
    PostDisplayFormat,
    PostWithJob,
    Restaurant,
    SpecialTimePeriod,
    Story,
} from ':shared/models';
import { CalendarEvent } from ':shared/models/calendar-event';
import { Feedback } from ':shared/models/feedback';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';

import { CalendarPostComponent } from './calendar-post/calendar-post.component';
import { CreateCalendarEventModalComponent } from './create-calendar-event-modal/create-calendar-event-modal.component';

export interface DuplicationPostData {
    duplicateDestination: DuplicationDestination;
    platformKeys: PlatformKey[];
    postId: string;
}

export interface DuplicationStoryData {
    duplicateDestination: DuplicationDestination;
    story: Story;
}

interface WeekDay {
    key: number;
    value: string | null;
    date: Date;
    specialEvents?: CalendarEvent[];
    hours?: SpecialTimePeriod[];
    posts?: PostWithJob[];
    isBankHoliday?: boolean;
}

interface MonthDay {
    date: MalouDate;
    specialEvents?: CalendarEvent[];
    hours?: SpecialTimePeriod[];
    posts?: PostWithJob[];
    isBankHoliday?: boolean;
}

interface MonthDayIndices {
    week: number | null;
    day: number | null;
}

interface DatesInterval {
    startDate: string;
    endDate: string;
    dateForRange: Date;
}

enum MonthlyCalendarSkeletonBoxType {
    HEADER = 'header',
    EVENT = 'event',
}

enum ViewMode {
    WEEK = 'week',
    MONTH = 'month',
}

type Direction = -1 | 0 | 1;

@Component({
    selector: 'app-dashboard-calendar',
    templateUrl: './dashboard-calendar.component.html',
    styleUrls: ['./dashboard-calendar.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        CalendarPostComponent,
        PlatformLogoComponent,
        NgClass,
        MatTooltipModule,
        NgStyle,
        NgTemplateOutlet,
        SkeletonComponent,
        AsyncPipe,
        FlagPathResolverPipe,
        ShortTextPipe,
        PluralTranslatePipe,
        ApplyPurePipe,
        TranslateModule,
    ],
})
@AutoUnsubscribeOnDestroy()
export class DashboardCalendarComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly isToday = isToday;
    readonly isAfterToday = isAfterToday;
    readonly isTodayForMalouDate = isTodayForMalouDate;
    readonly isAfterTodayForMalouDate = isAfterTodayForMalouDate;
    readonly compareDates = compareDates;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');
    readonly trackByDateFn = TrackByFunctionFactory.get('date');

    defaultInterval: DatesInterval = {
        startDate: DateTime.now().minus({ days: 7 }).toISO(),
        endDate: DateTime.now().toISO(),
        dateForRange: new Date(),
    };

    readonly datesInterval$ = new BehaviorSubject<DatesInterval>(this.defaultInterval);
    readonly isLoading$ = new BehaviorSubject<boolean>(true);
    readonly isLoading = toSignal(this.isLoading$, { initialValue: this.isLoading$.value });
    readonly reload$ = new BehaviorSubject<boolean>(true);
    readonly reloadPosts$ = new BehaviorSubject<boolean>(false);
    readonly isLangChanged$ = new BehaviorSubject<boolean>(true);

    readonly currentUser$: Observable<UserState> = this._store.select(selectUserState);

    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly ViewMode = ViewMode;
    readonly PostType = PostType;
    readonly PostSource = PostSource;
    readonly ExtendedPostPublicationStatus = ExtendedPostPublicationStatus;
    readonly PlatformKey = PlatformKey;

    isGmbConnected$ = of(true);
    startDate: Date | undefined;
    endDate: Date | undefined;
    weekDays: WritableSignal<WeekDay[]> = signal([]);
    monthWeeks: WritableSignal<MonthDay[][]> = signal([]);
    weekEvents: WritableSignal<CalendarEvent[]> = signal([]);
    monthEvents: WritableSignal<CalendarEvent[]> = signal([]);
    displayedPost: PostWithJob | null;
    dayCount = 0;
    currentUser: UserState;
    currentLang = LocalStorage.getLang();
    isWeeklyView = true;
    allElementsDisplayed = false;
    displayMorePosts = false;
    displayMoreEvents = false;
    dayIndices: MonthDayIndices;
    isChangingViewLoading = true;
    shouldWaitForTodayElement = false;
    postIdsToOpen: string[] | null;
    isSmallScreen = false;
    shouldDisplayDailyView = false;
    screenSize: number;
    restaurant: Restaurant | undefined;
    countriesWithEvents: CountriesWithEvents[] = Object.values(CountriesWithEvents);
    selectedEventsCountry: CountriesWithEvents = CountriesWithEvents.FR;
    showCreatePostMenuMobile = false;
    restaurantKeywords: Keyword[];
    restaurantManagers: User[];
    currentPosts: PostWithJob[] = [];
    PostDisplayFormat = PostDisplayFormat;
    MonthStringSize = MonthStringSize;
    MonthlyCalendarSkeletonBoxType = MonthlyCalendarSkeletonBoxType;
    openedFeedbacks: Feedback[] = [];

    private readonly _restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;

    private readonly _experimentationService = inject(ExperimentationService);

    readonly isNewSeoDuplicationFeatureEnabled = signal<boolean>(
        this._experimentationService.isFeatureEnabled('big-duplication-modal-post-preview')
    );
    readonly isNewSocialDuplicationFeatureEnabled = signal<boolean>(
        this._experimentationService.isFeatureEnabled('release-big-duplication-modal-social-post')
    );

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _socialPostsService: SocialPostsService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _http: HttpClient,
        public readonly translate: TranslateService,
        private readonly _store: Store,
        private readonly _calendarEventsService: CalendarEventsService,
        private readonly _duplicatePostModalService: DuplicatePostModalService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _malouDialogService: DialogService,
        private readonly _formBuilder: FormBuilder,
        private readonly _duplicateStoriesService: DuplicateStoriesService
    ) {}

    get hasPostsWithOpenedFeedback(): boolean {
        return this.openedFeedbacks.length !== 0;
    }

    ngOnInit(): void {
        this._initCloseModalOnClick();
        this._screenSizeService.resize$.subscribe((elt) => {
            this.screenSize = elt.width;
            this.isSmallScreen = elt.size === ScreenSize.IsSmallScreen;

            const previousValue = this.shouldDisplayDailyView;
            this.shouldDisplayDailyView = [ScreenSize.IsMediumScreen, ScreenSize.IsSmallScreen].includes(elt.size);

            if (this.shouldDisplayDailyView && !previousValue) {
                this.initializeWeekDays(today);
                this.initializeMonthWeeks(today);
                this.isWeeklyView = false;
                this.goToToday();
            }
        });

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.initializeWeekDays(today);
        this.initializeMonthWeeks(today);

        this.currentUser$.subscribe((user) => (this.currentUser = user));
        this.isGmbConnected$ = this._store
            .select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB }))
            .pipe(map((platform) => (platform?.credentials?.length ?? 0) > 0));

        combineLatest([this._restaurant$, this.datesInterval$, this.isLangChanged$, this.reload$])
            .pipe(
                switchMap(([restaurant, { startDate, endDate, dateForRange }]) => {
                    if (!restaurant) {
                        return EMPTY;
                    }
                    this.isLoading$.next(true);
                    this.restaurant = restaurant ?? this.restaurant;
                    this.selectedEventsCountry = this.restaurant.calendarEventsCountry ?? this.selectedEventsCountry;
                    const { start, end } = this._getDaysFromInterval(dateForRange);
                    return combineLatest([
                        this._calendarEventsService.getCalendarEventsBetweenDates(restaurant._id, start, end),
                        this._postsService.getPostsBetweenDates$(restaurant._id, startDate, endDate),
                        this._store.select(selectCurrentKeywords),
                        this._restaurantsService.show(this.restaurant._id).pipe(map((res) => res.data)),
                    ]);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([
                    events,
                    {
                        data: { posts, jobs },
                    },
                    keywords,
                    restaurantWithManagers,
                ]) => {
                    this.isLoading$.next(false);
                    this.isChangingViewLoading = false;

                    this.restaurantKeywords = keywords;
                    this.restaurantManagers = restaurantWithManagers?.managers.map((userRestaurant) => new User(userRestaurant.user));

                    this.weekEvents.set(events.data);
                    this.monthEvents.set(events.data);
                    this.currentPosts = posts
                        .filter((post) => {
                            if (this.restaurant?.isBrandBusiness()) {
                                return !this.isSeoPost(post);
                            }
                            return true;
                        })
                        .map(
                            (post) =>
                                new PostWithJob({
                                    ...post,
                                    job: (jobs || []).filter((j) => j.data?.postId === post._id)[0],
                                })
                        );
                    if (this.isWeeklyView) {
                        this._updateWeekDays();
                    } else {
                        this._updateMonthDays();
                    }
                    this.updateOpenedFeedbacks();
                },
                error: (err) => {
                    this.isLoading$.next(false);
                    this.isChangingViewLoading = false;
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this.translate.instant('dashboard.calendar.error_occurred'),
                        message: new HttpErrorPipe(this.translate).transform(err),
                        primaryButton: {
                            label: this.translate.instant('common.return'),
                        },
                    });
                },
            });

        this.reloadPosts$
            .pipe(
                filter((shouldReload) => !!shouldReload),
                switchMap(() => this.datesInterval$),
                switchMap(({ startDate, endDate }) => {
                    if (!this.restaurant) {
                        return EMPTY;
                    }
                    return this._postsService.getPostsBetweenDates$(this.restaurant?.id, startDate, endDate);
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ data: { posts, jobs } }) => {
                    this.currentPosts = posts
                        .filter((post) => {
                            if (this.restaurant?.isBrandBusiness()) {
                                return !this.isSeoPost(post);
                            }
                            return true;
                        })
                        .map(
                            (post) =>
                                new PostWithJob({
                                    ...post,
                                    job: (jobs || []).filter((j) => j.data?.postId === post._id)[0],
                                })
                        );
                },
            });

        this._activatedRoute.queryParams
            .pipe(
                filter((params) => params?.postId),
                map((params) => params.postId as string),
                switchMap((postId) =>
                    this._postsService.getPost(postId).pipe(
                        catchError((err) => {
                            if (err.status === 404) {
                                this._toastService.openErrorToast(this.translate.instant('posts.posts_list.post_not_found'));
                                this._router.navigate(['./'], { relativeTo: this._activatedRoute, queryParams: null });
                            }
                            return EMPTY;
                        }),
                        filter((res) => !!res),
                        map((res) => res.data)
                    )
                ),
                switchMap((val) => {
                    if (!val.isStory) {
                        return this.handleStandardPostOpen(val);
                    }
                    return this.handleStoryPostOpen(val);
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading$.next(false);
                },
                error: () => {
                    this.isLoading$.next(false);
                },
            });

        this._store
            .select(selectCurrentlyPublishingPosts)
            .pipe(
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (newPosts) => {
                    this.currentPosts = (this.currentPosts ?? []).filter((p) => p.bindingId !== newPosts?.[0]?.bindingId || p.key);
                    newPosts.forEach((newPost) => {
                        this._updateCurrentPostsList(new PostWithJob({ ...newPost }));
                    });
                    this._updateWeekDays();
                    this._updateMonthDays();
                    this.updateOpenedFeedbacks();
                },
            });
    }

    updateOpenedFeedbacks(): void {
        const newOpenedFeedbacks = this.getPostsWithOpenedFeedback()
            ?.filter((post) => post?.feedback && showFeedbackNotification(post, this.currentUser?.infos?.role || Role.MALOU_BASIC))
            .map((post) => post.feedback);

        this.openedFeedbacks = compact(newOpenedFeedbacks);
    }

    handleStandardPostOpen = (postData: PostWithJob): Observable<any> =>
        combineLatest([of(postData), this._store.select(selectUserState)]).pipe(
            switchMap(([resPost, userInfos]) => {
                const post = new PostWithJob(resPost);
                post.filterOutFeedbackMessageDependingOnRole(userInfos.infos?.role || Role.MALOU_BASIC);
                if (post && this.restaurant) {
                    const postComponent = this._getEditPostComponent(post);
                    return this._customDialogService
                        .open<
                            any,
                            {
                                post: PostWithJob;
                                postId: string;
                                restaurant: Restaurant;
                                restaurantKeywords: Keyword[];
                                restaurantManagers: User[];
                                isDisabled: boolean;
                                allPostIds: string[] | null;
                            },
                            { next: ActionsAfterEditPostClosed; shouldDeleteAfterClose?: boolean }
                        >(
                            postComponent,
                            {
                                width: '100%',
                                panelClass: 'malou-dialog-panel--full',
                                enterAnimationDuration: '0ms',
                                data: {
                                    post,
                                    postId: post._id,
                                    restaurant: this.restaurant,
                                    restaurantKeywords: this.restaurantKeywords,
                                    restaurantManagers: this.restaurantManagers,
                                    isDisabled: post.published === PostPublicationStatus.PUBLISHED,
                                    allPostIds: this.postIdsToOpen,
                                },
                                disableClose: true,
                            },
                            { animateScreenSize: DialogScreenSize.ALL }
                        )
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            switchMap(
                                ({
                                    next,
                                    shouldDeleteAfterClose,
                                }: {
                                    next: ActionsAfterEditPostClosed;
                                    shouldDeleteAfterClose?: boolean | undefined;
                                }) => {
                                    this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                                    if (this.isSeoPost(post)) {
                                        shouldDeleteAfterClose = next.shouldDeleteAfterClose;
                                    }

                                    if (shouldDeleteAfterClose && !next.savedAsDraft) {
                                        this.currentPosts.splice(
                                            this.currentPosts.findIndex((p) => p._id === post._id),
                                            1
                                        );
                                        return this._postsService.deletePost(post._id).pipe(switchMap(() => of([])));
                                    }
                                    const postsToFetch = [this._postsService.getPost(post._id, true).pipe(map((res) => res.data))];
                                    if (this.postIdsToOpen) {
                                        this.postIdsToOpen.map((postId) =>
                                            postsToFetch.push(this._postsService.getPost(postId, true).pipe(map((res) => res.data)))
                                        );
                                        this.postIdsToOpen = null;
                                    }
                                    if (next.duplicatedPostId) {
                                        if (this.isSeoPost(post)) {
                                            this._duplicatePostModalService.openDuplicateModal(next.duplicatedPostId, PostCategory.SOCIAL);
                                        } else {
                                            this._duplicatePostModalService.openDuplicateModal(next.duplicatedPostId, PostCategory.SEO);
                                        }
                                        postsToFetch.push(
                                            this._postsService.getPost(next.duplicatedPostId, true).pipe(map((res) => res.data))
                                        );
                                    }
                                    return forkJoin(postsToFetch);
                                }
                            )
                        );
                }
                return of([]);
            }),
            tap(([currentPost, ...otherPosts]) => {
                if (otherPosts.length) {
                    otherPosts.forEach((otherPost) => {
                        if (otherPost && isPost(otherPost)) {
                            this._updateCurrentPostsList(otherPost);
                        }
                    });
                }
                if (currentPost && isPost(currentPost)) {
                    this.currentPosts = this.currentPosts.map((p) => (p._id === currentPost._id ? new PostWithJob(currentPost) : p));
                }
                this._updateWeekDays();
                this._updateMonthDays();
                this.updateOpenedFeedbacks();
            }),
            takeUntil(this.killSubscriptions$)
        );

    handleStoryPostOpen = (postData: PostWithJob): Observable<any> =>
        this._postsService.getAllUnpublishedStoriesFromPostId(postData._id).pipe(
            switchMap(({ data: storiesData }) => of(storiesData.map((s) => new PostWithJob(s)))),
            switchMap((stories) => {
                // const post = new S(data);
                // this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                if (stories?.length) {
                    return this._customDialogService
                        .open<
                            EditStoryModalComponent,
                            {
                                posts: PostWithJob[];
                                isDisabled: boolean;
                                restaurantManagers: User[];
                            },
                            { next: ActionsAfterEditStoriesClosed }
                        >(EditStoryModalComponent, {
                            width: '100%',
                            height: undefined,
                            panelClass: 'malou-dialog-panel--full',
                            data: {
                                posts: stories,
                                isDisabled: false,
                                restaurantManagers: this.restaurantManagers,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            map(({ next }: { next: ActionsAfterEditStoriesClosed }) => {
                                this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                                if (next.reload) {
                                    this.reloadPosts$.next(true);

                                    this._updateWeekDays();
                                    this._updateMonthDays();
                                    this.updateOpenedFeedbacks();
                                }
                                return EMPTY;
                            })
                        );
                }
                return of([]);
            }),
            takeUntil(this.killSubscriptions$)
        );

    initializeWeekDays(day: Date): void {
        this.weekDays.set(
            Object.values(days).map((d) => ({
                key: d.digit,
                value: this._capitalizeFirstLetter(d[this.currentLang]),
                date: addDay(d.digit - 1, getMonday(day)),
            }))
        );
        this.startDate = this.weekDays().find((d) => d.key === 1)?.date;
        this.endDate = this.weekDays().find((d) => d.key === 7)?.date;
        if (!this.startDate || !this.endDate) {
            return;
        }
        this.datesInterval$.next({
            startDate: formatDate(this.startDate, false).replace(/\//g, '-'),
            endDate: formatDate(this.endDate, false).replace(/\//g, '-'),
            dateForRange: day,
        });
        this.updateOpenedFeedbacks();
    }

    initializeMonthWeeks(date: Date): void {
        const monthWeeks = getMonthRange(date.getFullYear(), date.getMonth() + 1).map((week) =>
            week.map((d) => ({
                date: {
                    day: d.day,
                    month: d.month,
                    year: d.year,
                },
                specialEvents: [],
                hours: [],
                posts: [],
                isBankHoliday: false,
            }))
        );
        this.monthWeeks.set(monthWeeks);
        const lastWeek = monthWeeks[monthWeeks.length - 1];
        let {
            date: { year, month, day },
        } = monthWeeks[0][0];
        this.startDate = new Date(year, month - 1, day);
        const startDateStr = day + '-' + month + '-' + year;
        ({
            date: { year, month, day },
        } = lastWeek[lastWeek.length - 1]);
        this.endDate = new Date(year, month - 1, day);
        const endDateStr = day + '-' + month + '-' + year;
        this.datesInterval$.next({
            startDate: startDateStr,
            endDate: endDateStr,
            dateForRange: date,
        });
        this.updateOpenedFeedbacks();
    }

    private _updateWeekDays(): void {
        this.weekDays.update((weekDays) => [
            ...weekDays.map((day) => {
                const events = this.getEventsByDay(day);
                const hours = this.getHoursByDay(day.date);
                return {
                    key: day.key,
                    value: day.value,
                    date: day.date,
                    isBankHoliday: !!events?.length && !!events.filter((evt) => evt.isBankHoliday).length,
                    specialEvents: events,
                    hours,
                    posts: this.getPostsByDay(this.currentPosts, day.date),
                };
            }),
        ]);
    }

    private _updateWeekDaysEvents(): void {
        return this.weekDays.update((weekDays) => [
            ...weekDays.map((day) => {
                const events = this.getEventsByDay(day);
                return {
                    ...day,
                    isBankHoliday: !!events?.length && !!events.filter((evt) => evt.isBankHoliday).length,
                    specialEvents: events,
                };
            }),
        ]);
    }

    private _updateMonthDays(): void {
        return this.monthWeeks.update((monthWeeks) => [
            ...monthWeeks.map((week) =>
                week.map((d) => {
                    const date = createDateFromMalouDate(d.date);
                    date.setHours(0, 0, 0, 0);
                    const events = this.getMonthlyEventsByDate(date);
                    return {
                        ...d,
                        specialEvents: events,
                        posts: this.getPostsByDay(this.currentPosts, date),
                        hours: this.getHoursByDay(date),
                        isBankHoliday: !!events?.length && !!events.filter((evt) => evt.isBankHoliday).length,
                    };
                })
            ),
        ]);
    }

    getAllMonthDays(): MonthDay[] {
        const currentDisplayedMonth = this.monthWeeks()[1][0]?.date.month;
        return this.monthWeeks()
            .flat()
            .filter((monthDay) => monthDay.date.month === currentDisplayedMonth);
    }

    getDayValue(day: MalouDate): string | null {
        const date = createDateFromMalouDate(day);
        return this._capitalizeFirstLetter(formatStringDay(date));
    }

    _updateMonthDaysEvents(): void {
        return this.monthWeeks.update((monthWeeks) => [
            ...monthWeeks.map((week) =>
                week.map((d) => {
                    const date = createDateFromMalouDate(d.date);
                    date.setHours(0, 0, 0, 0);
                    const events = this.getMonthlyEventsByDate(date);
                    return {
                        ...d,
                        specialEvents: events,
                        isBankHoliday: !!events?.length && !!events.filter((evt) => evt.isBankHoliday).length,
                    };
                })
            ),
        ]);
    }

    resetViewTime(): void {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.isWeeklyView ? this.initializeWeekDays(today) : this.initializeMonthWeeks(today);
        const todayIndex = today.getDay() - 1 === -1 ? 6 : today.getDay() - 1;
        this.dayCount = todayIndex;
    }

    doesMonthContainToday(): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return this.getAllMonthDays()
            .map((monthDay) => createDateFromMalouDate(monthDay.date).getTime())
            .includes(today.getTime());
    }

    getHoursByDay(day: Date): SpecialTimePeriod[] {
        if (!this.restaurant?.specialHours) {
            return [];
        }

        const specialHoursList: SpecialTimePeriod[] = [];
        for (const specialHours of this.restaurant.specialHours) {
            const start = specialHours.startDate?.getDate();
            const end = specialHours.endDate?.getDate();
            if (start) {
                if ((!end && isSameDay(day, start)) || isBetween(day, start, end)) {
                    specialHoursList.push(specialHours);
                }
            }
        }
        return specialHoursList;
    }

    createAddPostOptionsMenu(canAddSeoPost: boolean): MenuOption[] {
        const addPostMenu = [
            {
                name: this.translate.instant('dashboard.calendar.reel'),
                actionData: { postSource: PostSource.SOCIAL, postType: PostType.REEL },
            },
            {
                name: this.translate.instant('dashboard.calendar.social_post'),
                actionData: { postSource: PostSource.SOCIAL },
            },
        ];
        if (canAddSeoPost) {
            addPostMenu.push({
                name: this.translate.instant('dashboard.calendar.seo_post'),
                actionData: { postSource: PostSource.SEO },
            });
        }
        return addPostMenu;
    }

    openAddPostModal(): void {
        this.isGmbConnected$.subscribe((isGmbConnected) => {
            this.showCreatePostMenuMobile = true;
            this._customDialogService
                .open(
                    BottomActionsModalComponent,
                    {
                        panelClass: 'malou-dialog-hidden',
                        disableClose: false,
                        width: '100%',
                        maxWidth: '100%',
                        data: { options: this.createAddPostOptionsMenu(isGmbConnected) },
                    },
                    { animateScreenSize: DialogScreenSize.NONE }
                )
                .afterClosed()
                .subscribe({
                    next: (data) => {
                        this.showCreatePostMenuMobile = false;
                        if (data) {
                            this.createPostForCurrentDay(data.postSource, data.postType);
                        }
                    },
                });
        });
    }

    getElementsModalDay(): MonthDay | void {
        if (this.dayIndices.week == null || this.dayIndices.day == null) {
            return;
        }
        return this.monthWeeks()[this.dayIndices.week][this.dayIndices.day];
    }

    getPostContainerMaxHeight = (day: WeekDay): { [name: string]: string } => {
        const genericHeight = 630;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { 'max-height': `${genericHeight - (day.specialEvents?.length ?? 0) * 150}px` };
    };

    getEventsByDay(day: WeekDay): CalendarEvent[] {
        return day?.date
            ? this.weekEvents()?.filter((evt) => {
                  const eventDate = new Date(evt.startDate);
                  const eventDateComputed = evt.byDefault ? createDateWithClientTimeZoneDifference(eventDate) : eventDate;
                  return evt?.startDate && isSameDay(eventDateComputed, day.date) && evt.getNameForLang(this.currentLang);
              })
            : [];
    }

    getMonthlyEventsByDate(date: Date): CalendarEvent[] {
        return this.monthEvents()?.filter((evt) => {
            const eventDate = new Date(evt.startDate);
            const eventDateComputed = evt.byDefault ? createDateWithClientTimeZoneDifference(eventDate) : eventDate;
            return evt?.startDate && isSameDay(eventDateComputed, date) && evt.getNameForLang(this.currentLang);
        });
    }

    getPostsByDay(posts: PostWithJob[], day: Date): PostWithJob[] {
        const postsByDay = posts?.filter((p) => {
            const postDate = p.getPostDate();
            return (
                postDate.getDate() === day.getDate() &&
                postDate.getMonth() === day.getMonth() &&
                postDate.getFullYear() === day.getFullYear()
            );
        });
        if (!postsByDay) {
            return [];
        }

        postsByDay.forEach((p) => {
            p.http = this._http;
        });
        return postsByDay.sort((a, b) => a.getPostDate().getTime() - b.getPostDate().getTime());
    }

    getColumnNumberForSize(): number[] {
        return this.shouldDisplayDailyView ? [0] : [0, 1, 2, 3, 4, 5, 6];
    }

    getPostKeys(post: PostWithJob): PlatformKey[] {
        return post.keys?.length ? post.keys : [post.key];
    }

    getShortTextSizeForScreenSize(): number {
        if (this.screenSize > 1450) {
            return 40;
        }
        return 25;
    }

    getPostLogoSrc = ({ key, post }: { key: PlatformKey; post: PostWithJob }): string => (post.isStory ? `${key}_story` : key);

    change(direction: Direction): void {
        if (this.isWeeklyView) {
            this.changeWeek(direction);
        } else {
            if (this.shouldDisplayDailyView) {
                this._goToTodayOrFirstDayOfMonth();
            }
            this.changeMonth(direction);
        }
    }

    changeView(timeUnit: ViewMode): void {
        this.isChangingViewLoading = true;
        this.isWeeklyView = timeUnit === ViewMode.WEEK;
        this.change(0);
    }

    changeWeek(direction: Direction): void {
        const monday = this.weekDays().find((d) => d.key === 1)?.date;
        if (!monday) {
            return;
        }
        const diff = monday.getDate() + direction * 7;
        const newRefDate = new Date(monday.setDate(diff));
        this.initializeWeekDays(newRefDate);
    }

    changeMonth(direction: Direction): void {
        const {
            date: { year, month, day },
        } = this.monthWeeks()[2][0];
        const date = new Date(year, month - 1, day);
        date.setMonth(date.getMonth() + direction);
        this.initializeMonthWeeks(date);
    }

    editHours(date: Date): void {
        this._router.navigate(['./seo/informations'], {
            relativeTo: this._activatedRoute.parent?.parent,
            queryParams: { openSpecialHours: true, date },
        });
    }

    monthlyEditHours(weekIndex: number, dayIndex: number): void {
        const day = this.monthWeeks()[weekIndex][dayIndex];
        this.monthlyEditHoursByDate(day.date);
    }

    monthlyEditHoursByDate(date: MalouDate): void {
        this.editHours(createDateFromMalouDate(date));
    }

    monthlyCreatePost(postSource: PostSource, day: MalouDate, postType: PostType = PostType.IMAGE): void {
        const date = createDateFromMalouDate(day);
        this.createPost(postSource, date, postType);
    }

    monthlyCreateStory(day: MalouDate): void {
        const date = createDateFromMalouDate(day);
        this.createStory(date);
    }

    openPosts(postIds: string[]): void {
        this.postIdsToOpen = postIds;
        this.isLoading$.next(true);
        this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { postId: postIds[0] } });
    }

    openPost(postId: string): void {
        if (postId) {
            this.isLoading$.next(true);
            this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { postId } });
            return;
        }
        this._toastService.openErrorToast(this.translate.instant('dashboard.calendar.post_not_found'));
    }

    createStory(date: Date): void {
        if (!this.restaurant) {
            return;
        }
        const malouStoryId = uuidv4();
        const postDate = DateTime.fromJSDate(date)
            .set({ hour: DateTime.now().hour + 1, minute: 0 })
            .toJSDate();
        this._postsService
            .createStory$(this.restaurant._id, {
                keys: PlatformDefinitions.getPlatformKeysWithStories(),
                malouStoryId,
                plannedPublicationDate: postDate.toISOString(),
            })
            .subscribe({
                next: ({ data: post }) => {
                    this._router.navigate(['./'], { queryParams: { postId: post._id }, relativeTo: this._activatedRoute });
                },
                error: (err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        this._toastService.openErrorToast(this.translate.instant('stories.stories_list.no_platform'));
                    } else {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    }
                },
            });
    }

    createPost(postSource: PostSource, postDate: Date, postType: PostType = PostType.IMAGE): void {
        if (!this.restaurant) {
            return;
        }
        this.isLoading$.next(true);
        postDate = DateTime.fromJSDate(postDate)
            .set({ hour: DateTime.now().hour + 1, minute: 0 })
            .toJSDate();
        this._createEmptyDraft$(postSource, postType, postDate, this.restaurant._id).subscribe({
            next: ({ data: post }) => {
                this.currentPosts.push(new PostWithJob(post));
                this._router.navigate(['./'], { relativeTo: this._activatedRoute, queryParams: { postId: post._id } });
            },
            error: (err) => {
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    getDateFromUserScrollLocation(): Date {
        const daysContainer = document.getElementById('days') as HTMLElement;
        const allDays = document.querySelectorAll('.displayed-days');
        const firstDisplayedDayIndex = Array.from(allDays).findIndex((node) => (node as HTMLElement).offsetTop >= daysContainer.scrollTop);
        return createDateFromMalouDate(this.getAllMonthDays()[firstDisplayedDayIndex].date);
    }

    createPostForCurrentDay(postSource: PostSource, postType: PostType = PostType.IMAGE): void {
        const today = DateTime.now().set({ minute: 0, second: 0 }).plus({ hours: 1 }).toJSDate();
        const currentDateOnScroll = this.getDateFromUserScrollLocation();
        const newPostDate = isAfterToday(currentDateOnScroll) ? currentDateOnScroll : today;
        return this.createPost(postSource, newPostDate, postType);
    }

    deleteEvent(event: CalendarEvent): void {
        if (!this.restaurant) {
            return;
        }
        this._calendarEventsService.deleteCalendarEvent(this.restaurant._id, event._id).subscribe({
            next: () => {
                if (this.isWeeklyView) {
                    this.weekDays.update((weekDays) => [
                        ...weekDays.map((d) => {
                            const eventDate = new Date(event.startDate);
                            const eventDateComputed = event.byDefault ? createDateWithClientTimeZoneDifference(eventDate) : eventDate;
                            if (isSameDay(d.date, eventDateComputed)) {
                                d.specialEvents = d.specialEvents?.filter(
                                    (evt) => evt.getNameForLang(this.currentLang) !== event.getNameForLang(this.currentLang)
                                );
                            }
                            return d;
                        }),
                    ]);
                } else {
                    this.monthWeeks.update((monthWeeks) => [
                        ...monthWeeks.map((w) =>
                            w.map((d) => {
                                const eventDate = new Date(event.startDate);
                                const eventDateComputed = event.byDefault ? createDateWithClientTimeZoneDifference(eventDate) : eventDate;
                                if (isSameDay(createDateFromMalouDate(d.date), eventDateComputed)) {
                                    d.specialEvents = d.specialEvents?.filter(
                                        (evt) => evt.getNameForLang(this.currentLang) !== event.getNameForLang(this.currentLang)
                                    );
                                }
                                return d;
                            })
                        ),
                    ]);
                }
                this.updateOpenedFeedbacks();
            },
            error: () => {
                this._malouDialogService.open({
                    variant: DialogVariant.ERROR,
                    title: this.translate.instant('events.error'),
                    message: this.translate.instant('events.could_not_delete'),
                    primaryButton: {
                        label: this.translate.instant('common.return'),
                    },
                });
            },
        });
    }

    monthlyOpenCreateEventModal(day: MalouDate): void {
        const date = createDateFromMalouDate(day);
        this.openCreateEventModal(date);
    }

    openCreateEventModal(date: Date): void {
        const restaurantId = this.restaurant?._id;
        if (!restaurantId) {
            return;
        }

        this._customDialogService
            .open(CreateCalendarEventModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: this.isSmallScreen ? '90vh' : '400px',
                width: '600px',
                maxWidth: this.isSmallScreen ? '100vw' : undefined,
                position: {
                    bottom: this.isSmallScreen ? '0' : undefined,
                },
                data: {
                    startDate: date,
                    calendarEventsCountry: this.selectedEventsCountry,
                },
            })
            .afterClosed()
            .pipe(switchMap((result) => (result ? this._calendarEventsService.createCalendarEvent(restaurantId, result) : of(null))))
            .subscribe({
                next: (res) => {
                    if (res?.data) {
                        this.weekDays.update((weekDays) => [
                            ...weekDays.map((d) => {
                                if (isSameDay(d.date, new Date(res.data.startDate))) {
                                    d.specialEvents?.push(new CalendarEvent({ ...res.data, _id: res.data.id }));
                                    this.weekEvents.update((weekEvents) => [
                                        ...weekEvents,
                                        new CalendarEvent({ ...res.data, _id: res.data.id }),
                                    ]);
                                }
                                return d;
                            }),
                        ]);
                        const day = getMalouDateFromDate(date);
                        this.monthWeeks.update((monthWeeks) => [
                            ...monthWeeks.map((w) =>
                                w.map((d) => {
                                    if (JSON.stringify(d.date) === JSON.stringify(day)) {
                                        d.specialEvents?.push(new CalendarEvent({ ...res.data, _id: res.data.id }));
                                    }
                                    return d;
                                })
                            ),
                        ]);
                        this.updateOpenedFeedbacks();
                    }
                },
                error: (err) => {
                    if (err.error?.duplicateRecordError) {
                        this._malouDialogService.open({
                            variant: DialogVariant.ERROR,
                            title: this.translate.instant('events.error'),
                            message: this.translate.instant('events.event_already_exists'),
                            primaryButton: {
                                label: this.translate.instant('common.return'),
                            },
                        });
                    } else {
                        this._malouDialogService.open({
                            variant: DialogVariant.ERROR,
                            title: this.translate.instant('events.error'),
                            message: this.translate.instant('events.could_not_create'),
                            primaryButton: {
                                label: this.translate.instant('common.return'),
                            },
                        });
                    }
                },
            });
    }

    deletePost(postId: string): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this.translate.instant('posts.delete_post'),
            message: this.translate.instant('posts.want_delete_post'),
            secondaryButton: {
                label: this.translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this.translate.instant('posts.delete'),
                action: () => {
                    this._deletePostById(postId);
                },
            },
        });
    }

    getPrettyLang = (lang: CountriesWithEvents): string => this.translate.instant(`header.countries.${lang.toLowerCase()}`);

    changeEventsCountry(country: CountriesWithEvents): void {
        if (!this.restaurant) {
            return;
        }

        this.isLoading$.next(true);
        this._restaurantsService
            .update(this.restaurant._id, { calendarEventsCountry: country.toUpperCase() })
            .pipe(
                switchMap((res) => {
                    this._restaurantsService.reloadSelectedRestaurant();
                    this.restaurant = res.data;
                    this.selectedEventsCountry = this.restaurant.calendarEventsCountry;
                    const { dateForRange } = this.datesInterval$.value;
                    const { start, end } = this._getDaysFromInterval(dateForRange);
                    return this._calendarEventsService.getCalendarEventsBetweenDates(this.restaurant._id, start, end);
                })
            )
            .subscribe({
                next: (events) => {
                    this.isLoading$.next(false);
                    this.weekEvents.set(events.data);
                    this.monthEvents.set(events.data);
                    if (this.isWeeklyView) {
                        this._updateWeekDaysEvents();
                    } else {
                        this._updateMonthDaysEvents();
                    }
                    this.updateOpenedFeedbacks();
                },
                error: (err) => {
                    this.isLoading$.next(false);
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this.translate.instant('dashboard.calendar.error_occurred'),
                        message: new HttpErrorPipe(this.translate).transform(err),
                        primaryButton: {
                            label: this.translate.instant('common.return'),
                        },
                    });
                },
            });
    }

    displayAllElements(_event: MouseEvent, element: string, weekIndex: number, dayIndex: number, isLastRow: boolean): void {
        this.allElementsDisplayed = true;
        this.displayMorePosts = element === 'posts';
        this.displayMoreEvents = !this.displayMorePosts;
        this.dayIndices = {
            week: weekIndex,
            day: dayIndex,
        };
        this._setElementsModalPosition(weekIndex, dayIndex, isLastRow);
    }

    displayPost(post: PostWithJob, weekIndex: number | null = null, dayIndex: number | null = null): void {
        post.http = this._http;
        post.initWorkingPic('small');
        this.displayedPost = post;
        this._setPostModalPosition(weekIndex ?? this.dayIndices.week, dayIndex ?? this.dayIndices.day);
    }

    isSeoPost(post: Post): boolean {
        return post.key === PlatformKey.GMB || post.keys?.includes(PlatformKey.GMB);
    }

    getPostsToShow(day: WeekDay | MonthDay, postsNum: number): PostWithJob[] | undefined {
        return day.posts?.filter((p, index) => index < postsNum);
    }

    getTitle = (size: MonthStringSize = MonthStringSize.long): string => {
        let date: Date;
        if (this.isWeeklyView) {
            const monday = this.weekDays().find((d) => d.key === 1);
            if (!monday) {
                return '';
            }
            date = monday.date;
        } else {
            const {
                date: { year, month, day },
            } = this.monthWeeks()[2][0];
            date = new Date(year, month - 1, day);
        }
        const stringMonth = formatStringMonth(date);
        const stringMonthFormatted = size === MonthStringSize.short ? stringMonth.substring(0, 3) : stringMonth;
        return `${this._capitalizeFirstLetter(stringMonthFormatted)}, ${date.getFullYear()}`;
    };

    formatEventText = (event: CalendarEvent): string => {
        let tooltip = '';
        if (!this.restaurant) {
            return tooltip;
        }
        const ideaForLang = new CalendarEvent(event).getIdeasForLang(this.currentLang);
        if (ideaForLang) {
            const ideasWithRestaurantName = ideaForLang.replace(/- /g, '\n').replace(/RESTAURANT_NAME/, this.restaurant?.name);
            tooltip += this.translate.instant('dashboard.calendar.idea') + ideasWithRestaurantName + '\n \n';
        }
        const exampleForLang = new CalendarEvent(event).getExampleForLang(this.currentLang);
        if (exampleForLang) {
            const exampleWithRestaurantName = exampleForLang.replace(/- /g, '\n').replace(/RESTAURANT_NAME/, this.restaurant?.name);
            tooltip += this.translate.instant('dashboard.calendar.example') + exampleWithRestaurantName + '\n';
        }
        return tooltip;
    };

    getDateAndMonth(date: Date): string {
        const month = zeroPad(date.getMonth() + 1, 2);
        const day = zeroPad(date.getDate(), 2);

        const isEnglishFormat = this.translate.currentLang === ApplicationLanguage.EN;
        const formattedDate = isEnglishFormat ? `${month}.${day}` : `${day}.${month}`;
        return formattedDate;
    }

    duplicatePost({
        duplicateDestination,
        platformKeys,
        postId,
    }: {
        duplicateDestination: DuplicationDestination;
        platformKeys: PlatformKey[];
        postId: string;
    }): void {
        return this._prepareDuplicatePost({ duplicateDestination, platformKeys, postId });
    }

    onDuplicateStory(duplicateDestination: DuplicationDestination, story: Story): void {
        this._duplicateStoriesService.duplicateStories(
            duplicateDestination,
            [story],
            story.restaurantId,
            (duplicatedStories) => this._onStoryDuplicationSuccess(duplicatedStories),
            (err) => this._onStoryDuplicationError(err)
        );
    }

    private _onStoryDuplicationSuccess(duplicatedStories: Story[]): void {
        if (duplicatedStories.length) {
            this._toastService.openSuccessToast(this.translate.instant('stories.stories_duplicated'));
        }
    }

    private _onStoryDuplicationError = (error: unknown): void => {
        console.warn('err :>>', error);
        this._toastService.openErrorToast(this.translate.instant('stories.duplication_failed'));
    };

    getPostsWithOpenedFeedback(): PostWithJob[] {
        if (this.isWeeklyView) {
            return this.weekDays()
                .flatMap((day) => day.posts)
                .filter((post): post is PostWithJob => !!post)
                .filter((post) => post?.feedback?.isOpen);
        } else {
            return this.monthWeeks()
                .flat()
                .flatMap((day) => day.posts)
                .filter((post): post is PostWithJob => !!post)
                .filter((post) => post?.feedback?.isOpen);
        }
    }

    hasPostsWithFeedback(posts: PostWithJob[]): boolean {
        return !!posts.filter((post) => post?.feedback?.isOpen)?.length;
    }

    openPostsWithOpenedFeedback(): void {
        const postsWithOpenedFeedback = this.getPostsWithOpenedFeedback();
        if (!postsWithOpenedFeedback?.length) {
            return;
        }
        this.openPosts(postsWithOpenedFeedback.map((post) => post._id));
    }

    isDayDuringWeekEnd(dayIndex: number): boolean {
        return [5, 6].includes(dayIndex);
    }

    isDayInEndOfWeek(dayIndex: number): boolean {
        return [4, 5, 6].includes(dayIndex);
    }

    goToToday({ delayMs } = { delayMs: 2000 }): void {
        this.shouldWaitForTodayElement = true;
        if (!this.doesMonthContainToday()) {
            this.resetViewTime();
        }
        this.isLoading$.pipe(filter((val) => this.shouldWaitForTodayElement && !val)).subscribe(() => {
            setTimeout(() => {
                const todayElement = <HTMLElement>document.getElementById('today');
                const topScroll = todayElement ? todayElement.offsetTop - 20 : 0;
                const daysContainer = document.getElementById('days');
                daysContainer?.scrollTo({
                    top: topScroll,
                    behavior: 'smooth',
                });
                this.shouldWaitForTodayElement = false;
            }, delayMs);
        });
    }

    showSkeletonBox(row: number, column: number, type: MonthlyCalendarSkeletonBoxType = MonthlyCalendarSkeletonBoxType.HEADER): boolean {
        switch (row) {
            case 2:
                return [3, 4, 7].includes(column);
            case 3:
                return [0, 1, 3].includes(column);
            default:
                return type === MonthlyCalendarSkeletonBoxType.EVENT ? [0, 1, 3, 5].includes(column) : true;
        }
    }

    private _updateCurrentPostsList(post: PostWithJob): void {
        const existingPostIndex = this.currentPosts.findIndex((p) => p._id === post._id);
        if (existingPostIndex !== -1) {
            this.currentPosts[existingPostIndex] = new PostWithJob(post);
        } else {
            this.currentPosts.push(new PostWithJob(post));
        }
    }

    private _deletePostById(id: string): void {
        this.isLoading$.next(true);
        this._postsService.deletePost(id).subscribe({
            next: () => {
                this.isLoading$.next(false);
                this.currentPosts = this.currentPosts.filter((post) => post._id !== id);
                this._updateWeekDays();
                this._updateMonthDays();
                this.updateOpenedFeedbacks();
                this._toastService.openSuccessToast(this.translate.instant('dashboard.calendar.post_deleted'));
            },
            error: (err) => {
                this.isLoading$.next(false);
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _initCloseModalOnClick(): void {
        document?.addEventListener('click', (evt) => {
            const target = <HTMLElement>evt.target;
            if (!target.closest('#postModal') && !target.closest('#postModalImg')) {
                this.displayedPost = null;
            }
            if (!target.closest('#allElements') && target.id !== 'showMorePostsBtn' && target.id !== 'showMoreEventsBtn') {
                this.allElementsDisplayed = false;
                this.dayIndices = {
                    week: null,
                    day: null,
                };
            }
            if (!target.closest('#createPostBtnMobile')) {
                this.showCreatePostMenuMobile = false;
            }
        });
    }

    private _getDaysFromInterval(dateForRange: Date): { start: string; end: string } {
        let startDate: Date;
        let endDate: Date;

        if (this.isWeeklyView) {
            startDate = getMonday(dateForRange);
            endDate = addDay(6, startDate);
            // Ugly fix to transform for example Saturday at 23:00:00 to Sunday at 00:00:00
            endDate = DateTime.fromJSDate(endDate).toUTC().plus({ days: 1 }).startOf('day').toJSDate();
        } else {
            const monthDates = getMonthRange(dateForRange.getFullYear(), dateForRange.getMonth() + 1).flat();
            startDate = createDateFromMalouDate(monthDates[0]);
            endDate = createDateFromMalouDate(monthDates[monthDates.length - 1]);
        }

        const start = startDate.toISOString();
        const end = endDate.toISOString();

        return { start, end };
    }

    private _capitalizeFirstLetter(string: string): string | null {
        return string ? string.charAt(0)?.toUpperCase() + string.slice(1) : null;
    }

    private _setPostModalPosition(weekIndex: number | null, dayIndex: number | null): void {
        if (!isNumber(weekIndex) || !isNumber(dayIndex)) {
            return;
        }
        setTimeout(() => {
            const calendarCol = <NodeList>document.querySelectorAll('#monthlyCalendarColumn');
            const monthlyScrollContainer = <HTMLElement>document.querySelector('#monthlyScrollContainer');
            const monthlyScrollContainerScrollY = monthlyScrollContainer.scrollTop;
            const calendarColIndex = this.isDayDuringWeekEnd(dayIndex) ? dayIndex - 1 + weekIndex * 7 : dayIndex + 1 + weekIndex * 7;
            const calendarColOffsetLeft = (calendarCol[calendarColIndex] as HTMLElement).offsetLeft;
            const calendarColOffsetTop = (calendarCol[calendarColIndex] as HTMLElement).offsetTop - monthlyScrollContainerScrollY;
            const postModal = <HTMLElement>document.querySelector('#postModal');
            if (postModal) {
                const postModalLeftOffset = this.isDayDuringWeekEnd(dayIndex) ? 47 : 6;
                const style =
                    'top: ' + (calendarColOffsetTop - 20) + 'px; left: ' + (calendarColOffsetLeft + postModalLeftOffset - 30) + 'px;';

                postModal.style.cssText = style;
            }
        }, 10);
    }

    private _setElementsModalPosition(weekIndex: number, dayIndex: number, isLastRow: boolean): void {
        setTimeout(() => {
            const elementsModal = <HTMLElement>document.querySelector('#allElements');
            const monthlyScrollContainer = <HTMLElement>document.querySelector('#monthlyScrollContainer');
            const calendarCol = <NodeList>document.querySelectorAll('#monthlyCalendarColumn');

            const monthlyScrollContainerScrollY = monthlyScrollContainer.scrollTop;
            const calendarColIndex = dayIndex + weekIndex * 7;
            const calendarColOffsetLeft = (calendarCol[calendarColIndex] as HTMLElement).offsetLeft;
            const calendarColOffsetTop = (calendarCol[calendarColIndex] as HTMLElement).offsetTop - monthlyScrollContainerScrollY;

            let style = isLastRow ? `bottom: calc(100vh - ${calendarColOffsetTop + 320}px);` : `top: ${calendarColOffsetTop + 90}px;`;
            if (this.isDayInEndOfWeek(dayIndex)) {
                style += 'right: 0px;';
            } else {
                style += `left: ${calendarColOffsetLeft + 75}px;`;
            }
            elementsModal.style.cssText = style;
            elementsModal.style.visibility = 'visible';
        }, 10);
    }

    private _goToTodayOrFirstDayOfMonth(): void {
        setTimeout(() => {
            const todayElement = <HTMLElement>document.getElementById('today');
            const topScroll = todayElement ? todayElement.offsetTop - 20 : 0;
            const daysContainer = document.getElementById('days');
            daysContainer?.scrollTo({
                top: topScroll,
                behavior: 'smooth',
            });
        }, 10);
    }

    private _createEmptyDraft$(
        postSource: PostSource,
        postType: PostType,
        postDate: Date,
        restaurantId: string
    ): Observable<ApiResult<Post>> {
        return postSource === PostSource.SEO
            ? this._postsService.createEmptySeoDraft$(restaurantId, { postType, postDate })
            : this._postsService.createEmptySocialDraft$(restaurantId, { postType, postDate });
    }

    private _prepareDuplicatePost({
        duplicateDestination,
        platformKeys,
        postId,
    }: {
        duplicateDestination: DuplicationDestination;
        platformKeys: PlatformKey[];
        postId: string;
    }): void {
        this.isLoading$.next(true);
        this._postsService
            .getPost(postId)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (postToDuplicate) => {
                    this.isLoading$.next(false);
                    if (postToDuplicate && isPost(postToDuplicate)) {
                        this._duplicateWithStepperModal({
                            duplicateDestination,
                            platformKeys,
                            postToDuplicate,
                        });
                    }
                },
                error: (err) => {
                    this.isLoading$.next(false);
                    this._toastService.openErrorToast(
                        this.translate.instant('social_posts.duplication_failed') + ' : ' + this._httpErrorPipe.transform(err)
                    );
                },
            });
    }

    private _duplicateWithStepperModal({
        duplicateDestination,
        platformKeys,
        postToDuplicate,
    }: {
        duplicateDestination: DuplicationDestination;
        platformKeys: PlatformKey[];
        postToDuplicate: PostWithJob;
    }): void {
        if (duplicateDestination === DuplicationDestination.HERE) {
            return this._duplicatePosts([postToDuplicate], platformKeys);
        }

        const steps = this._getStepsForDuplication(postToDuplicate, platformKeys);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness: platformKeys.includes(PlatformKey.GMB),
            selectedRestaurants: [],
            hasPlatform: platformKeys,
        };

        if (this.isNewSeoDuplicationFeatureEnabled() || this.isNewSocialDuplicationFeatureEnabled()) {
            this._customDialogService.open(StepperModalComponent, {
                width: 'unset',
                height: 'unset',
                data: {
                    steps,
                    initialData,
                    fullScreenStepIndexes: [steps.length - 1],
                    openWithoutSavingCheckAsModal: true,
                    sharedData: {
                        restaurantKeywords$: of(this.restaurantKeywords),
                        restaurantKeywords: this.restaurantKeywords,
                        postToDuplicate,
                    },
                    title: this.translate.instant('duplicate_to_restaurants_dialog.title'),
                    onSuccess: ({ posts }: { posts: PostWithJob[] }) => {
                        this._onDuplicationSuccess(posts);
                    },
                    onError: (error: unknown) => {
                        this._onDuplicationError(error);
                    },
                    shouldDisplayConfirmationCloseModalAfterClosed: true,
                    malouDialogBodyCustomStyle: {
                        padding: '0px',
                        margin: '0px',
                    },
                },
            });
        } else {
            this._customDialogService.open(StepperModalComponent, {
                width: 'unset',
                height: 'unset',
                data: {
                    steps,
                    initialData,
                    openWithoutSavingCheckAsModal: true,
                    sharedData: {
                        restaurantKeywords$: of(this.restaurantKeywords),
                        restaurantKeywords: this.restaurantKeywords,
                        postToDuplicate,
                    },
                    title: this.translate.instant('duplicate_to_restaurants_dialog.title'),
                    onSuccess: (posts: PostWithJob[]) => {
                        this._onDuplicationSuccess(posts);
                    },
                    onError: (error: unknown) => {
                        this._onDuplicationError(error);
                    },
                    shouldDisplayConfirmationCloseModalAfterClosed: true,
                    malouDialogBodyCustomStyle: {},
                },
            });
        }
    }

    private _duplicatePosts(postsToDuplicate: PostWithJob[], platformKeys: PlatformKey[], duplicateOutside = false): void {
        this.isLoading$.next(true);
        forkJoin(postsToDuplicate.map((postToDuplicate) => this._postsService.duplicatePost$(postToDuplicate, platformKeys))).subscribe({
            next: (posts) => {
                this.isLoading$.next(false);
                if (posts.length) {
                    const newPostsForRestaurant = posts
                        .filter((post) => post.restaurantId === this.restaurant?._id)
                        .map((post) => new PostWithJob(post));
                    this.currentPosts = this.currentPosts.concat(newPostsForRestaurant);
                    this._updateWeekDays();
                    this._updateMonthDays();
                    this.updateOpenedFeedbacks();
                    this._toastService.openSuccessToast(this.translate.instant('posts.duplication_succeeded'));
                    if (!duplicateOutside) {
                        const isSeoPosts = getPostCategoryFromKeys(platformKeys) === PostSource.SEO;
                        if (isSeoPosts) {
                            this._duplicatePostModalService.openDuplicateModal(posts[0]._id, PostCategory.SEO);
                        } else {
                            this._duplicatePostModalService.openDuplicateModal(posts[0]._id, PostCategory.SOCIAL);
                        }
                    }
                }
            },
            error: (err) => {
                const errorMessage =
                    err.message?.match(/Unsupported file/) && !this.isSeoPost(postsToDuplicate[0])
                        ? this.translate.instant('social_posts.format_not_supported')
                        : this.translate.instant('social_posts.duplication_failed');
                this._toastService.openErrorToast(errorMessage);
                this.isLoading$.next(false);
            },
        });
    }

    private _getEditPostComponent = (
        post: PostWithJob
    ): typeof EditStoryModalComponent | typeof NewPostModalComponent | typeof NewSocialPostModalComponent => {
        if (post.isStory) {
            return EditStoryModalComponent;
        }
        return this.isSeoPost(post) ? NewPostModalComponent : NewSocialPostModalComponent;
    };

    private _onDuplicationSuccess = (posts: PostWithJob[]): void => {
        if (posts.length) {
            this._toastService.openSuccessToast(this.translate.instant('social_posts.posts_duplicated'));
            this._socialPostsService.duplicatePosts(posts, DuplicationDestination.OUT);
        }

        this.reload$.next(true);
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (error: any): void => {
        console.warn('err :>>', error);

        if (error['status'] === 403 && !!error['error']?.casl) {
            return;
        }
        if (error['message']?.match(/Unsupported file/)) {
            this._toastService.openErrorToast(this.translate.instant('social_posts.format_not_supported'));
        } else {
            this._toastService.openErrorToast(this.translate.instant('social_posts.duplication_failed'));
        }
    };

    private _populateSelectedRestaurantsWithPost(
        restaurantSelectionData: RestaurantsSelectionData,
        postToDuplicate: PostWithJob
    ): PersonalizePostDuplicationData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const date = new Date(
                    postToDuplicate.job?.nextRunAt || postToDuplicate.plannedPublicationDate || addMinutes(2, new Date())
                );

                const post = this._formBuilder.group({
                    status: [
                        postToDuplicate.published === PostPublicationStatus.DRAFT ? PostDateStatus.DRAFT : PostDateStatus.LATER,
                        Validators.required,
                    ],
                    text: [postToDuplicate.text, Validators.required],
                    date: [date],
                    time: [getTimeStringFromDate(date), isControlValueInTimeFormat(this.translate)],
                });
                return {
                    restaurant,
                    post,
                };
            }) ?? []
        );
    }

    private _duplicatePersonalizedPostOutside(
        postDuplicationDataPerRestaurant: PersonalizePostDuplicationData[],
        initialPost: PostWithJob,
        platformsKeys: PlatformKey[]
    ): Observable<{ posts: Post[] }> {
        return forkJoin(
            postDuplicationDataPerRestaurant.map(({ restaurant, post }) => {
                const postToDuplicate = initialPost;
                const date = this._getPostDuplicationDate(post);

                postToDuplicate.text = post.value.text || '';
                postToDuplicate.plannedPublicationDate = date;
                postToDuplicate.published =
                    post.value.status === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;

                return this._duplicatePostOutside({ selectedRestaurants: [restaurant] }, [postToDuplicate], platformsKeys);
            })
        ).pipe(
            mergeMap((results) =>
                of({
                    posts: results.flatMap((result) => result.posts),
                })
            )
        );
    }

    private _getPostDuplicationDate(
        post: INullableFormGroup<{
            status: PostDateStatus;
            date: Date;
            text: string;
            time: string;
        }>
    ): Date {
        switch (post.value.status) {
            case PostDateStatus.DRAFT:
            case PostDateStatus.LATER:
                const date = post.value.date ?? new Date();
                if (date && post.value.time) {
                    date.setHours(parseInt(post.value.time.substring(0, 2), 10));
                    date.setMinutes(parseInt(post.value.time.substring(3), 10));
                }
                return date;

            case PostDateStatus.NOW:
            default:
                return new Date();
        }
    }

    private _duplicatePostOutside(
        restaurantSelectionData: Pick<RestaurantsSelectionData, 'selectedRestaurants'>,
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        isDraft = false
    ): Observable<{ posts: Post[] }> {
        if (restaurantSelectionData?.selectedRestaurants?.length) {
            type CustomPostWithJob = PostWithJob & { forceDraft: boolean; platformKeys: PlatformKey[] };
            const allPosts: CustomPostWithJob[] = [];

            restaurantSelectionData.selectedRestaurants?.forEach(({ _id, platformKeys }) => {
                // separate valid and invalid platform keys for the restaurant if duplication is not as draft
                const validPlatforms = isDraft ? platformsKeys : platformsKeys.filter((key) => platformKeys.includes(key));
                const invalidPlatforms = isDraft ? [] : platformsKeys.filter((key) => !platformKeys.includes(key));

                postsToDuplicate.forEach((post) => {
                    // if post to duplicate is draft than duplicate it in all platforms
                    if (post.published === PostPublicationStatus.DRAFT) {
                        const newPost = new PostWithJob(post) as CustomPostWithJob;
                        newPost.restaurantId = _id;
                        newPost.forceDraft = false;
                        newPost.platformKeys = platformsKeys;
                        delete newPost.attachmentsName;

                        allPosts.push(newPost);
                    } else {
                        // else duplicate it as draft in invalid platforms
                        if (invalidPlatforms.length > 0) {
                            const newPost = new PostWithJob(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.published = PostPublicationStatus.DRAFT;
                            newPost.forceDraft = true;
                            newPost.platformKeys = invalidPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }

                        if (validPlatforms.length > 0) {
                            const newPost = new PostWithJob(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.forceDraft = false;
                            newPost.platformKeys = validPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }
                    }
                });
            });
            return forkJoin(
                allPosts.map((postToDuplicate) =>
                    this._postsService.duplicatePost$(
                        postToDuplicate,
                        postToDuplicate.platformKeys,
                        isDraft || postToDuplicate.forceDraft || postToDuplicate.published === PostPublicationStatus.DRAFT
                    )
                )
            ).pipe(switchMap((posts) => of({ posts })));
        } else {
            return of({ posts: [] });
        }
    }

    private _getStepsForDuplication(postToDuplicate: PostWithJob, platformKeys: PlatformKey[]): Step[] {
        if (this.isNewSeoDuplicationFeatureEnabled() && platformKeys.includes(PlatformKey.GMB)) {
            return [
                {
                    component: RestaurantsSelectionComponent,
                    subtitle: this.translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                    primaryButtonText: this.translate.instant('common.duplicate'),
                    nextFunction$: (data: RestaurantsSelectionData) =>
                        of({
                            selectedRestaurants: data.selectedRestaurants ?? [],
                        }),
                },
                {
                    component: DuplicateSeoPostWithTextGenerationPreviewModalComponent,
                    primaryButtonText: this.translate.instant('common.duplicate'),
                    nextFunction$: ({
                        newPosts,
                        hasRemainingCallToActionErrors,
                    }: DuplicatePostPreviewModalSubmitData): Observable<{
                        posts: Post[];
                        failedPosts?: unknown[];
                        shouldPreventNextStep?: boolean;
                    }> => {
                        if (!hasRemainingCallToActionErrors) {
                            return this._duplicatePersonalizedPostOutsideV2({
                                initialPost: postToDuplicate,
                                newPosts,
                            }).pipe(
                                tap((result) => {
                                    if (result?.failedPosts?.length) {
                                        this._toastService.openWarnToast(
                                            this.translate.instant('posts.some_posts_cant_be_duplicated_user_role')
                                        );
                                    }
                                })
                            );
                        }

                        return this._showAreYouSureDialogBeforeDuplication({
                            postToDuplicate,
                            newPosts,
                        });
                    },
                },
            ];
        }
        if (
            this.isNewSocialDuplicationFeatureEnabled() &&
            platformKeys.some((key) => [PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK, PlatformKey.MAPSTR].includes(key))
        ) {
            return [
                {
                    component: RestaurantsSelectionComponent,
                    subtitle: this.translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                    primaryButtonText: this.translate.instant('common.next'),
                    nextFunction$: (data: RestaurantsSelectionData) =>
                        of({
                            selectedRestaurants: data.selectedRestaurants ?? [],
                        }),
                },
                {
                    component: DuplicateSocialPostWithTextGenerationPreviewModalComponent,
                    primaryButtonText: this.translate.instant('common.duplicate'),
                    nextFunction$: (
                        data: DuplicateSocialPostPreviewModalSubmitData[]
                    ): Observable<{ posts: Post[]; failedPosts: unknown[] }> =>
                        this._duplicatePersonalizedSocialPostOutsideV2(data, postToDuplicate, false).pipe(
                            tap((result) => {
                                if (result?.failedPosts?.length) {
                                    this._toastService.openWarnToast(
                                        this.translate.instant('posts.some_posts_cant_be_duplicated_user_role')
                                    );
                                }
                            })
                        ),
                },
            ];
        }
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this.translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                primaryButtonText: this.translate.instant('common.next'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizePostDuplicationData[]> =>
                    of(this._populateSelectedRestaurantsWithPost(data, postToDuplicate)),
            },
            {
                component: PersonalizePostDuplicationComponent,
                primaryButtonText: this.translate.instant('common.duplicate'),
                nextFunction$: (data: PersonalizePostDuplicationData[]): Observable<{ posts: Post[] }> =>
                    this._duplicatePersonalizedPostOutside(data, postToDuplicate, platformKeys),
            },
        ];
    }

    private _duplicatePersonalizedSocialPostOutsideV2(
        postDuplicationDataPerRestaurant: DuplicateSocialPostPreviewModalSubmitData[],
        initialPost: PostWithJob,
        inBackground: boolean
    ): Observable<{ posts: Post[]; failedPosts: unknown[] }> {
        return forkJoin(
            postDuplicationDataPerRestaurant.map(({ restaurant, ...post }) => {
                const postToDuplicate = initialPost.copyWith({
                    text: post.text,
                    plannedPublicationDate: post.plannedPublicationDate,
                    hashtags: post.hashtags,
                    published: post.status,
                    location: post.location ?? undefined,
                });

                return this._duplicatePostOutside(
                    { selectedRestaurants: [restaurant] },
                    [postToDuplicate],
                    post.platformKeys,
                    inBackground
                ).pipe(
                    catchError((httpError: { error: { casl: boolean } }) => {
                        if (httpError.error.casl) {
                            return of({ error: httpError.error });
                        }
                        return throwError(() => httpError);
                    })
                );
            })
        ).pipe(
            mergeMap((results) => {
                const posts = results.filter((result) => 'posts' in result).flatMap((result) => (result as { posts: Post[] }).posts);
                const failedPosts = results.filter((result) => 'error' in result);
                return of({
                    posts,
                    failedPosts,
                });
            })
        );
    }

    private _duplicatePersonalizedPostOutsideV2({
        initialPost,
        newPosts,
    }: {
        initialPost: PostWithJob;
        newPosts: DuplicatePostData;
    }): Observable<{ posts: Post[]; failedPosts: unknown[]; shouldPreventNextStep?: boolean }> {
        return forkJoin(
            newPosts.map(({ restaurantId, postCaption, plannedPublicationDate, status, postCallToActionUrl }) => {
                const post = initialPost.copyWith({
                    restaurantId,
                    text: postCaption,
                    plannedPublicationDate,
                    published: status,
                    callToAction:
                        postCallToActionUrl && initialPost.callToAction
                            ? { actionType: initialPost.callToAction.actionType, url: postCallToActionUrl }
                            : { actionType: CallToActionType.NONE, url: '' },
                });
                return this._duplicatePostOutside(
                    { selectedRestaurants: [new Restaurant({ _id: restaurantId, platformKeys: [PlatformKey.GMB] })] },
                    [post],
                    [PlatformKey.GMB],
                    false
                ).pipe(
                    catchError((httpError: { error: { casl: boolean } }) => {
                        if (httpError.error.casl) {
                            return of({ error: httpError.error });
                        }
                        return throwError(() => httpError);
                    })
                );
            })
        ).pipe(
            mergeMap((results) => {
                const posts = results.filter((result) => 'posts' in result).flatMap((result) => (result as { posts: Post[] }).posts);
                const failedPosts = results.filter((result) => 'error' in result);
                return of({
                    posts,
                    failedPosts,
                });
            })
        );
    }

    private _showAreYouSureDialogBeforeDuplication({
        postToDuplicate,
        newPosts,
    }: {
        postToDuplicate: PostWithJob;
        newPosts: DuplicatePostData;
    }): Observable<{ posts: Post[]; shouldPreventNextStep?: boolean }> {
        let shouldSave = false;
        return this._malouDialogService
            .open({
                variant: DialogVariant.INFO,
                maxWidth: '100vw',
                illustration: Illustration.Stars,
                customIllustrationWidth: '170px',
                title: this.translate.instant('posts.some_posts_missing_cta'),
                message: this.translate.instant('posts.continue_without_cta'),
                secondaryButton: {
                    label: this.translate.instant('common.return'),
                },
                primaryButton: {
                    label: this.translate.instant('posts.duplicate_post_modal.duplicate_without_buttons'),
                    action: () => {
                        shouldSave = true;
                    },
                },
            })
            .afterClosed()
            .pipe(
                switchMap(() =>
                    shouldSave
                        ? this._duplicatePersonalizedPostOutsideV2({
                              initialPost: postToDuplicate,
                              newPosts,
                          })
                        : of({ posts: [], shouldPreventNextStep: true })
                )
            );
    }
}
