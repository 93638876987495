<div [formGroup]="callToActionFormGroup">
    <div class="flex items-center gap-x-3 sm:flex-col">
        <div class="w-[25vw] max-w-[50%] sm:w-full sm:max-w-full">
            <app-select
                class="cursor-pointer"
                data-testid="seo-post-call-to-action-input"
                formControlName="actionType"
                [title]="'posts.new_post.add_action_button' | translate"
                [values]="callToActions()"
                [displayWith]="displayWithCallToAction"
                [inputWithFixedWidth]="true"
                (selectChange)="selectChange($event)">
                <ng-template let-value="value" #simpleSelectedValueTemplate>
                    <div class="flex w-full items-center pl-3">
                        <ng-container
                            [ngTemplateOutlet]="callToActionTextTemplate"
                            [ngTemplateOutletContext]="{ value, isSelected: true }"></ng-container>
                    </div>
                </ng-template>
                <ng-template let-value="value" #optionTemplate>
                    <ng-container [ngTemplateOutlet]="callToActionTextTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
                </ng-template>
            </app-select>
        </div>

        @if (shouldShowCallToActionUrl()) {
            <div class="mt-3 w-[52%] sm:w-full">
                <div class="malou-border-primary border-primary mt-3 flex h-[50px] self-stretch rounded-lg bg-white">
                    <div class="w-full px-5">
                        <input
                            class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                            data-testid="seo-post-url-call-to-action-input"
                            data-cy="postUrlInput"
                            type="text"
                            placeholder="{{ 'posts.new_post.url_placeholder' | translate }}"
                            formControlName="url"
                            [matAutocomplete]="autoUrls" />
                    </div>
                </div>
                <mat-autocomplete
                    class="!min-w-[300px] transform !rounded-[10px]"
                    (optionSelected)="onUrlOptionSelected($event)"
                    #autoUrls="matAutocomplete">
                    @for (url of urlsAutocompleteOptions(); track url) {
                        <mat-option class="urls-options" [value]="url">
                            <span>{{ url }}</span>
                        </mat-option>
                    }
                </mat-autocomplete>
            </div>
        }
    </div>

    @if (shouldShowAddUrlInInformation()) {
        <div class="malou-text-10--regular mt-2 flex items-center">
            <span>{{ 'posts.new_post.add_url_in_information' | translate }}</span>
            <button class="malou-btn-flat !malou-text-10 !-ml-2 !px-4" mat-button (click)="openInformations()">
                {{ 'posts.new_post.add_website_url' | translate }}
            </button>
        </div>
    }
</div>

<ng-template let-value="value" let-isSelected="isSelected" #callToActionTextTemplate>
    <div class="flex w-full items-center" [ngClass]="{ 'cursor-not-allowed': value.disabledWithTooltip }">
        <span class="malou-text-12--regular min-w-fit truncate pr-1" [ngClass]="{ '!font-medium': isSelected }"> {{ value.text }} </span>
        @if (value.actionLink && (!isSelected || typesWithDefaultUrls.includes(value.type))) {
            <span class="malou-text-12--regular truncate italic"> ({{ value.actionLink }}) </span>
        } @else if (value.disabledWithTooltip && (!shouldShowCallToActionUrl() || currentCallToActionType() !== value.type)) {
            <span class="malou-text-12--regular truncate pr-1 italic">{{ 'posts.new_post.unset' | translate }}</span>
        }
    </div>
</ng-template>
