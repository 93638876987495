<div
    class="w-full rounded-[10px] px-4 py-2"
    id="saveTemplateRef"
    [ngClass]="{
        'malou-color-background-dark': theme() === SaveReplyAsTemplateTheme.FILLED,
        'border border-malou-color-border-primary': theme() === SaveReplyAsTemplateTheme.OUTLINED,
    }">
    <form [formGroup]="saveTemplateForm()">
        <div class="flex flex-col justify-center gap-y-4 py-[0.44rem]">
            <mat-checkbox
                class="mr-5"
                id="tracking_answer_review_modal_save_as_template_button"
                color="primary"
                [ngClass]="{ 'cursor-not-allowed opacity-50': disabled() }"
                [checked]="saveTemplateForm().get('activated')?.value"
                [disabled]="disabled()"
                (change)="toggleSaveTemplate($event)">
                <span class="malou-text-10--medium malou-color-text-1">{{
                    'reviews.reply_modal.templates.save_as_template' | translate
                }}</span>
            </mat-checkbox>
            @if (saveTemplateForm().get('activated')?.value) {
                <div class="flex flex-col gap-y-2">
                    <app-select
                        class="w-full"
                        formControlName="saveTemplateName"
                        [required]="true"
                        [title]="'reviews.reply_modal.templates.template_name' | translate"
                        [placeholder]="'common.search' | translate"
                        [values]="templateNames()"
                        [itemBuilder]="templateNameBuilder"
                        [errorMessage]="
                            saveTemplateForm().get('saveTemplateName')?.dirty &&
                            saveTemplateForm().get('saveTemplateName')?.errors?.required
                                ? ('common.required_field' | translate)
                                : ''
                        "
                        (selectChange)="onSelectSaveTemplateChange($event)"></app-select>

                    @if (shouldDisplayTemplateUpdateWarning()) {
                        <div class="malou-text-10--medium malou-color-state-warn">
                            {{ 'reviews.reply_modal.templates.update_warning' | translate }}
                        </div>
                    }
                    <app-select
                        class="w-full"
                        formControlName="withComment"
                        [required]="true"
                        [title]="'reviews.reply_modal.templates.review_type' | translate"
                        [placeholder]="'common.search' | translate"
                        [values]="availableCommentOptions()"
                        [displayWith]="commentOptionDisplayWith"
                        [errorMessage]="
                            saveTemplateForm().get('withComment')?.dirty && saveTemplateForm().get('withComment')?.errors?.required
                                ? ('common.required_field' | translate)
                                : ''
                        "></app-select>
                    <app-template-rating-filter
                        formControlName="rating"
                        [required]="true"
                        [title]="'reviews.reply_modal.templates.rating' | translate"
                        [errorMessage]="
                            saveTemplateForm().get('rating')?.dirty && saveTemplateForm().get('rating')?.errors?.required
                                ? ('common.required_field' | translate)
                                : ''
                        "
                        (selectRatings)="onSelectRatings($event)"></app-template-rating-filter>
                </div>
            }
        </div>
    </form>
</div>
