import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, NgZone, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { ScreenSizeService } from ':core/services/screen-size.service';

const appearFromBottom = [
    {
        transform: 'translateY(100%)',
    },
    {
        transform: 'translateY(0)',
    },
];

const disappearToBottom = [
    {
        transform: 'translateY(0)',
    },
    {
        transform: 'translateY(100%)',
    },
];

export enum DialogScreenSize {
    ALL = 'all',
    PHONE_SCREEN = 'phone_screen',
    LARGE_SCREEN = 'large_screen',
    NONE = 'none',
}

export interface CustomDialogServiceOptions {
    animateScreenSize?: DialogScreenSize;
    closeOnOutsideClick?: boolean;
}

type MatDialogConfigExtraProps<D> = MatDialogConfig<D> & { aggressivePopinE2ETests?: boolean };

@Injectable({ providedIn: 'root' })
export class CustomDialogService {
    constructor(
        private readonly _dialog: MatDialog,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _ngZone: NgZone
    ) {}

    /**
     * Opens a modal dialog containing the given component/template.
     * Handle the modal animation when on mobile screen by default (can be tweaked with 'options' argument).
     * It uses material MatDialogService with a default config object that contains :
     *      - width: '650px'
     *      - height: '90vh'
     *      - disableClose: true,
     *      - panelClass: 'malou-dialog-panel'
     * If you want to override a config, for example, you want to let the component/template handle his height himself,
     * you can call open function with : customDialogService.open(componentOrTemplate, { height: undefined })
     */
    open<T, D = any, R = any>(
        componentOrTemplate: ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfigExtraProps<D>,
        options?: CustomDialogServiceOptions
    ): MatDialogRef<T, R> {
        const defaultConfig = this._computeDefaultConfig(config);
        const dialogRef: MatDialogRef<T, R> = this._dialog.open(componentOrTemplate, defaultConfig);
        if (this._shouldAnimate(options?.animateScreenSize)) {
            this._animateOpening();
        }
        dialogRef.close = this._constructAnimatedCloseFn(dialogRef, options);
        if (options?.closeOnOutsideClick) {
            dialogRef.backdropClick().subscribe(() => {
                dialogRef.close();
            });
        }
        return dialogRef;
    }

    closeAll(): void {
        this._dialog.closeAll();
    }

    close(dialogId: string): void {
        this._dialog.getDialogById(dialogId)?.close();
    }

    private _setIfNotOwnProperty(obj: any, property: string, value: any): void {
        if (obj && !obj.hasOwnProperty(property)) {
            obj[property] = value;
        }
    }

    private _computeDefaultConfig<D>(config?: MatDialogConfigExtraProps<D>): MatDialogConfigExtraProps<D> {
        let defaultConfig = config;
        if (!defaultConfig) {
            defaultConfig = new MatDialogConfig<D>();
        }
        this._setIfNotOwnProperty(defaultConfig, 'width', '650px');
        this._setIfNotOwnProperty(defaultConfig, 'height', '90vh');
        this._setIfNotOwnProperty(defaultConfig, 'disableClose', true);
        this._setIfNotOwnProperty(defaultConfig, 'panelClass', 'malou-dialog-panel');

        if (config?.aggressivePopinE2ETests) {
            defaultConfig.id = 'aggressive-popin-e2e-tests';
        }

        return defaultConfig;
    }

    private _shouldAnimate(shouldAnimateScreenSize: DialogScreenSize = DialogScreenSize.PHONE_SCREEN): boolean {
        if (shouldAnimateScreenSize === DialogScreenSize.NONE) {
            return false;
        }

        const isPhoneScreen = this._screenSizeService.isPhoneScreen;
        const shouldAnimatePhoneScreen = shouldAnimateScreenSize === DialogScreenSize.PHONE_SCREEN;

        const isLargeScreen = this._screenSizeService.isLargeScreen;
        const shouldAnimateLargeScreen = shouldAnimateScreenSize === DialogScreenSize.LARGE_SCREEN;

        const shouldAnimateAllScreen = shouldAnimateScreenSize === DialogScreenSize.ALL;

        return (isPhoneScreen && shouldAnimatePhoneScreen) || (isLargeScreen && shouldAnimateLargeScreen) || shouldAnimateAllScreen;
    }

    private _animateOpening(): void {
        const dialogWrappers = document.getElementsByClassName('cdk-global-overlay-wrapper');
        if (dialogWrappers.length === 0) {
            return;
        }
        const dialogWrapper = dialogWrappers[dialogWrappers.length - 1];
        dialogWrapper.animate(appearFromBottom, {
            duration: 400,
            fill: 'forwards',
            easing: 'ease-in-out',
        });
    }

    private _constructAnimatedCloseFn<T, R>(
        dialogRef: MatDialogRef<T, R>,
        options?: CustomDialogServiceOptions
    ): (dialogResult: R) => void {
        const dialogClose = dialogRef.close;
        return (dialogResult?: R) => {
            if (this._shouldAnimate(options?.animateScreenSize)) {
                const dialogWrappers = document.getElementsByClassName('cdk-global-overlay-wrapper');
                const dialogWrapper = dialogWrappers[dialogWrappers.length - 1];
                const animation = dialogWrapper.animate(disappearToBottom, {
                    duration: 400,
                    fill: 'forwards',
                    easing: 'ease-in-out',
                });
                animation.onfinish = (): void => {
                    this._ngZone.run(() => dialogClose.call(dialogRef, dialogResult));
                };
            } else {
                dialogClose.call(dialogRef, dialogResult);
            }
        };
    }
}
