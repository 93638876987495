import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { PlatformKey } from '@malou-io/package-utils';

import { DatesAndPeriod, InsightsByPlatform, PostsWithInsightsByPlatforms, Review, TimeScaleToMetricToDataValues } from ':shared/models';

import { StoriesAndInsights } from '../social-networks/posts-insights-table/stories/stories.component';
import { PlatformFilterPage, StatisticsState } from './statistics.interface';

const selectFeature = createFeatureSelector<StatisticsState>('statistics');

export const selectFilters = createSelector(selectFeature, (state) => state.filters);
export const selectIsFiltersLoaded = createSelector(selectFeature, (state) => state.loaded);

export const selectDatesFilter = createSelector(selectFeature, (state): DatesAndPeriod => state.filters.dates);

export const selectComparisonPeriodFilter = createSelector(selectFeature, (state) => state.filters.comparisonPeriod);

export const selectPlatformsFilter = (props: {
    page: PlatformFilterPage;
}): MemoizedSelector<object, PlatformKey[], DefaultProjectorFn<PlatformKey[]>> =>
    createSelector(selectFeature, (state): PlatformKey[] => state.filters.platforms[props.page]);

export const selectTotemsFilter = createSelector(selectFeature, (state): string[] => state.filters.totemIds);

export const selectTimeScaleFilter = createSelector(selectFeature, (state) => state.filters.timeScale);

export const selectKeywords = createSelector(selectFeature, (state) => state.data.keywords);

export const selectActionsData = createSelector(selectFeature, (state): TimeScaleToMetricToDataValues | undefined => state.data.actions);

export const selectReviewsData = createSelector(selectFeature, (state): Review[] => state.data.reviews);

export const selectPostsWithInsightsData = createSelector(
    selectFeature,
    (state): PostsWithInsightsByPlatforms => state.data.postsWithInsights
);

export const selectFollowersData = createSelector(selectFeature, (state): InsightsByPlatform => state.data.followers);
export const selectFollowersDataV2 = createSelector(selectFeature, (state) => state.data.followersV2);

export const selectStoriesAndInsightsData = createSelector(selectFeature, (state): StoriesAndInsights => state.data.storiesAndInsights);
export const selectReviewsWithAnalysisData = createSelector(selectFeature, (state) => state.data.reviewsWithAnalysis);

export const selectPlatformsRatingsData = createSelector(selectFeature, (state) => state.data.platformsRatings);
export const selectReviewsRatingsEvolutionData = createSelector(selectFeature, (state) => state.data.reviewsRatingsEvolution);
export const selectReviewsRatingsTotalData = createSelector(selectFeature, (state) => state.data.reviewsRatingsTotal);

export const selectBoosterStatsData = createSelector(selectFeature, (state) => state.data.boosterStatsData);
export const selectBoosterStatsDataV2 = createSelector(selectFeature, (state) => state.data.boosterStatsDataV2);
export const selectPrivateReviewsData = createSelector(selectFeature, (state) => state.data.privateReviewsData);
