import { KeywordPopularity, RankingPositionOutOf, RestaurantRankingFormat } from '@malou-io/package-utils';

import { SortOption } from ':shared/interfaces/sort.interface';

export interface RankingTableDataRow {
    restaurantKeywordId: string;
    keywordId: string;
    keyword: string;
    language: string;
    volume: number;
    volumeFromAPI: number;
    lastRefresh: Date;
    isWaiting: boolean;
    shouldRefetchVolume?: boolean;
    popularity: KeywordPopularity;
}

export interface KeywordsWithCompetitorsTableDataRow extends RankingTableDataRow {
    currentPosition?: RankingPositionOutOf;
    ranking: RestaurantRankingFormat[];
}

export enum KeywordsListSortBy {
    Keywords = 'keywords',
    Language = 'language',
    SearchVolume = 'searchVolume',
    Ranking = 'ranking',
}

export type KeywordsListSortOption = SortOption<KeywordsListSortBy>;
