import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { PostType } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostHashtagsContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-hashtags.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-social-post-hashtags',
    templateUrl: './social-post-hashtags.component.html',
    styleUrls: ['./social-post-hashtags.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatIconModule, TranslateModule, SkeletonComponent, ClickOutsideDirective],
})
export class SocialPostHashtagsComponent {
    readonly postText = input.required<string>();

    private readonly _upsertSocialPostHashtagsContext = inject(UpsertSocialPostHashtagsContext);
    private readonly _upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _trackingService = inject(HeapService);

    readonly SvgIcon = SvgIcon;

    readonly isReel = computed(() => this._upsertSocialPostContext.upsertSocialPostState.post.postType() === PostType.REEL);
    readonly selectedHashtags = this._upsertSocialPostContext.upsertSocialPostState.post.hashtags.selected;
    readonly isGeneratingPostTextFromAI = this._upsertSocialPostAiContext.isGeneratingPostTextFromAI;
    readonly isGeneratingHashtags = this._upsertSocialPostHashtagsContext.isGeneratingHashtags;

    chooseHashtagsWithAi(): void {
        this._trackingService.track('tracking_hashtags_generate');
        this._upsertSocialPostHashtagsContext.getSuggestedHashtags();
        this._upsertSocialPostHashtagsContext.openPostHashtagsPanel();
    }

    retryHashtagsGeneration(): void {
        this._trackingService.track('tracking_hashtags_retry');
        this.chooseHashtagsWithAi();
    }

    removeHashtag(hashtagIndex: number): void {
        this._trackingService.track('tracking_hashtags_remove');
        const selectedHashtags = this.selectedHashtags();
        this._upsertSocialPostContext.updateSelectedHashtags(selectedHashtags.filter((_, index) => index !== hashtagIndex));
    }

    openPostHashtagsPanel(): void {
        this._upsertSocialPostHashtagsContext.openPostHashtagsPanel();
    }

    closePostHashtagsPanel(): void {
        this._upsertSocialPostHashtagsContext.closePostHashtagsPanel();
    }
}
