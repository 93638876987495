<div
    class="card-container !py-4 {{ parentContainerClasses() }}"
    [ngClass]="{
        'malou-card--secondary': review() | hasReply,
        'malou-card': !(review() | hasReply),
        'malou-color-background-light': !isReviewRevampFeatureEnabled() && isSelected() && !(review() | hasReply),
        'cursor-pointer': isSelectable() && !disabled(),
        '!border-malou-color-border-secondary': !isReviewRevampFeatureEnabled() && hasBorder(),
        '!border-0': isReviewRevampFeatureEnabled(),
        '!bg-malou-color-background-dark': isReviewRevampFeatureEnabled() && isSelected(),
        '!bg-malou-color-background-white': isReviewRevampFeatureEnabled() && !isSelected(),
        '!rounded-none': isReviewRevampFeatureEnabled(),
    }"
    (click)="onReviewClick()">
    <ng-container
        [ngTemplateOutlet]="reviewTemplate"
        [ngTemplateOutletContext]="{
            reviewText: review() | reviewText: { showTranslation: showTranslation() && canShowTranslation(), language: currentLang },
        }"></ng-container>
</div>

<ng-template let-reviewText="reviewText" #reviewTemplate>
    <div class="group">
        @if (isAggregatedView() && !!restaurant()) {
            <app-restaurant-header-for-review-preview
                [restaurant]="restaurant()"
                [review]="review()"
                [isSelected]="isSelected()"></app-restaurant-header-for-review-preview>
        }
        <div class="malou-card__header flex flex-col !pb-0 pl-0" [ngClass]="{ 'mb-2': reviewText?.length > 0 }" #header>
            <div class="flex w-full">
                <div
                    class="malou-card__image-container flex"
                    [ngClass]="{ 'opacity-50': disabled() }"
                    [matTooltip]="'reviews.orders' | pluralTranslate: (review() | getEaterTotalOrders)">
                    <app-platform-logo class="z-10" imgClasses="h-8 w-8 max-w-fit !rounded-full" [logo]="review().key"></app-platform-logo>
                    <img class="malou-avatar--medium -ml-4.5" alt="avatar" [src]="review() | reviewerDisplayName | avatar" />
                </div>
                <div class="w-[30%] grow" [ngClass]="{ 'opacity-50': disabled() }">
                    <div [ngClass]="{ 'mb-1 flex items-center': isReviewRevampFeatureEnabled() }">
                        <div class="malou-text-12--bold truncate">{{ review() | reviewerDisplayName }}</div>
                        @if (isReviewRevampFeatureEnabled()) {
                            <div class="ml-4 flex flex-col items-center gap-x-4 gap-y-2" id="star-chip-container">
                                <ng-container
                                    [ngTemplateOutlet]="starChipTemplate"
                                    [ngTemplateOutletContext]="{ review: review() }"></ng-container>
                            </div>
                        }
                    </div>
                    <div class="malou-text__card-subtitle">
                        {{ (review() | reviewDate: isReviewRevampFeatureEnabled()) || '-' }}
                    </div>
                </div>
                <div class="malou-card__action-button flex gap-x-2" [ngClass]="{ '!items-start': isReviewRevampFeatureEnabled() }">
                    @if (!isReviewRevampFeatureEnabled()) {
                        <div
                            class="flex flex-col items-center gap-x-4 gap-y-2"
                            id="star-chip-container"
                            [ngClass]="{ 'opacity-50': disabled() }">
                            <ng-container
                                [ngTemplateOutlet]="starChipTemplate"
                                [ngTemplateOutletContext]="{ review: review() }"></ng-container>
                        </div>
                    } @else {
                        <ng-container [ngTemplateOutlet]="reviewStatus" [ngTemplateOutletContext]="{ review: review() }"></ng-container>
                    }
                    @if (withArchiveButton()) {
                        @if (isReviewRevampFeatureEnabled()) {
                            <div class="pt-1">
                                <mat-icon
                                    class="icon-btn !h-4 cursor-pointer text-malou-color-primary group-hover:!visible"
                                    [ngClass]="{ '!invisible': !review().archived }"
                                    [svgIcon]="review().archived ? SvgIcon.BOX_FILLED : SvgIcon.BOX"
                                    [matTooltip]="review() | reviewArchiveTooltip"
                                    (click)="$event.stopPropagation(); toggleArchived(review())"></mat-icon>
                            </div>
                        } @else {
                            <button
                                class="malou-btn-icon--secondary btn-xl hover:!bg-malou-color-background-light"
                                type="button"
                                mat-icon-button
                                [ngClass]="{
                                    'malou-color-background-dark !border-malou-color-purple--light': review().archived,
                                }"
                                [matTooltip]="review() | reviewArchiveTooltip"
                                (click)="$event.stopPropagation(); toggleArchived(review())">
                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.BOX"></mat-icon>
                            </button>
                        }
                    }
                </div>
            </div>
        </div>
        <div
            class="hidden items-center justify-between gap-x-4 gap-y-2"
            id="star-chip-container-mobile"
            [ngClass]="{ 'opacity-50': disabled() }">
            <ng-container [ngTemplateOutlet]="starChipTemplate" [ngTemplateOutletContext]="{ review: review() }"></ng-container>
        </div>
        @if (reviewText?.length > 0 || (review() | hasAttachments)) {
            <div class="flex" [ngClass]="{ 'opacity-50': disabled() }">
                @if (review() | hasAttachments) {
                    <div class="flex gap-[5px]">
                        @for (
                            media of (review() | applySelfPure: 'getSocialAttachments').slice(0, MAX_MEDIA_ATTACHMENTS_SHOWN);
                            track media;
                            let i = $index
                        ) {
                            <div>
                                <div
                                    class="malou-card__media"
                                    [ngClass]="{
                                        'malou-card__image-suffix-overlay':
                                            (review() | applySelfPure: 'getSocialAttachments').length > MAX_MEDIA_ATTACHMENTS_SHOWN &&
                                            i === MAX_MEDIA_ATTACHMENTS_SHOWN - 1,
                                    }">
                                    @if (media.type === 'photo') {
                                        <img
                                            class="malou-border-radius-5"
                                            alt=""
                                            [lazyLoad]="media.urls.original"
                                            (click)="openCarousel(review().getSocialAttachments(), i)" />
                                    }
                                    @if (media.type === 'video') {
                                        <video
                                            class="malou-border-radius-5 !max-h-[50px]"
                                            autoplay
                                            loop
                                            [src]="media.urls.original"
                                            [muted]="'muted'"
                                            (click)="openCarousel(review().getSocialAttachments(), i)"></video>
                                    }
                                    @if (
                                        (review() | applySelfPure: 'getSocialAttachments').length > MAX_MEDIA_ATTACHMENTS_SHOWN &&
                                        i === MAX_MEDIA_ATTACHMENTS_SHOWN - 1
                                    ) {
                                        <span class="malou-border-radius-5">
                                            <div class="malou-text-15--semibold">
                                                +{{ (review() | applySelfPure: 'getSocialAttachments').length - 1 }}
                                            </div>
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                @if (reviewText?.length > 0) {
                    <div
                        class="malou-card__body-text"
                        data-testid="review-model-review-text"
                        [ngClass]="{
                            'ml-5': (review() | hasAttachments),
                        }">
                        @if (shouldDisplaySemanticAnalysis() && !showTranslation()) {
                            <div class="font-normal" [innerHTML]="highlightedReviewTextHtml()"></div>
                        } @else {
                            @if (
                                'text' | reviewShortTextLength: { shouldDisplayFullText: displayFullText() || !isFold.text };
                                as shortTextLength
                            ) {
                                <div>
                                    <span class="font-normal" [innerHTML]="reviewText | shortText: shortTextLength"></span>
                                    @if (this.withSeeMoreButton() && reviewText.length > shortTextLength) {
                                        <span
                                            class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5"
                                            (click)="toggleFold('text')">
                                            {{ (isFold.text ? 'common.see_more' : 'common.see_less') | translate }}
                                        </span>
                                    }
                                </div>
                            }
                        }
                    </div>
                }
            </div>
        }

        @if (shouldShowTranslationTemplate()) {
            <app-review-translations
                [review]="review()"
                [restaurant]="restaurant()"
                [showTranslation]="showTranslation()"
                (showTranslatedText)="showTranslation.set($event)"></app-review-translations>
        }

        <ng-container [ngTemplateOutlet]="reviewMenuItemsTemplate"></ng-container>

        @if (shouldDisplaySemanticAnalysis()) {
            <div class="flex" [ngClass]="{ 'mt-1': !isReviewRevampFeatureEnabled() }">
                <app-review-semantic-analysis
                    [review]="review()"
                    [whiteBorders]="true"
                    [theme]="
                        isReviewRevampFeatureEnabled() || isNewSemanticAnalysisFeatureEnabled()
                            ? SemanticAnalysisDisplayStyle.LIGHT
                            : SemanticAnalysisDisplayStyle.FULL
                    "
                    [showOriginalTextWarning]="showOriginalTextWarning()"
                    (hoveredChip)="onHoveredChip($event)"
                    (highlightAllSegments)="highlightAllSegments()"></app-review-semantic-analysis>
            </div>
        }
    </div>
</ng-template>

<ng-template #reviewMenuItemsTemplate>
    @if (review() | getMenuItemReviews; as menuItems) {
        <div [ngClass]="{ 'mb-2': shouldDisplaySemanticAnalysis() }">
            <div>
                <div class="grid-wrapper-menu-review" [class.grid-hide-rows]="isFold.menuItems" [class.gap-1.5]="!isFold.menuItems">
                    @for (
                        itemReview of menuItems | displayMenuItems: { isFold: isFold.menuItems, maxMenuItemShown: MAX_MENU_ITEM_SHOWN };
                        track itemReview
                    ) {
                        <div class="mt-1">
                            <div
                                class="flex items-end"
                                [matTooltip]="
                                    itemReview.tags?.length || itemReview.comment?.length
                                        ? itemReview.tags?.join(' ● ') || itemReview.comment
                                        : ''
                                ">
                                <mat-icon
                                    class="smaller-icon mr-2 !h-[16px] !w-[16px]"
                                    svgIcon="{{ itemReview.rating ? 'thumb-up' : 'thumb-down' }}"
                                    [ngClass]="itemReview.rating ? 'thumb-up' : 'thumb-down'">
                                </mat-icon>
                                <span class="malou-text-10--regular flex">
                                    {{ itemReview.name }}
                                </span>
                            </div>
                        </div>
                    }
                </div>
                @if (menuItems.length > MAX_MENU_ITEM_SHOWN) {
                    <span
                        class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5 pt-1 min-md:!hidden"
                        (click)="toggleFold('menuItems')">
                        {{ (isFold.menuItems ? 'common.see_more' : 'common.see_less') | translate }}
                    </span>
                }
            </div>
            @if (menuItems.length > MAX_MENU_ITEM_SHOWN) {
                <span class="flex items-end">
                    <span
                        class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5 md:!hidden"
                        (click)="toggleFold('menuItems')">
                        {{ (isFold.menuItems ? 'common.see_more' : 'common.see_less') | translate }}
                    </span>
                </span>
            }
        </div>
    }
</ng-template>

<ng-template let-review="review" #starChipTemplate>
    <app-star-gauge [stars]="review.rating"></app-star-gauge>

    @if (!isReviewRevampFeatureEnabled()) {
        <ng-container [ngTemplateOutlet]="reviewStatus" [ngTemplateOutletContext]="{ review }"></ng-container>
    }
</ng-template>

<ng-template let-review="review" #reviewStatus>
    @if (review.couldNotSendReply) {
        <div
            class="malou-chip malou-chip--red !h-6"
            [matTooltip]="'reviews.error_explain' | translate"
            [ngClass]="{ '!malou-text-10 !cursor-default !pl-1': isReviewRevampFeatureEnabled() }">
            @if (isReviewRevampFeatureEnabled()) {
                <mat-icon class="mr-1 !h-4" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            }
            <span>{{ 'reviews.error' | translate }}</span>
        </div>
    } @else {
        @switch (review | computeBadgeStatus) {
            @case (ReviewBadgeStatus.NOT_ANSWERED) {
                @if (isReviewRevampFeatureEnabled()) {
                    <div class="malou-chip malou-chip--yellow !malou-text-10 !h-6 !cursor-default !pl-1">
                        <mat-icon class="mr-1 !h-4" [svgIcon]="SvgIcon.CLOCK"></mat-icon>
                        <span>{{ 'reviews.not_answered' | translate }}</span>
                    </div>
                }
            }
            @case (ReviewBadgeStatus.PENDING) {
                <div
                    class="malou-chip malou-chip--purple-light !h-6"
                    [matTooltip]="'reviews.pending_explain' | translate"
                    [ngClass]="{ '!malou-text-10 !cursor-default !pl-1': isReviewRevampFeatureEnabled() }">
                    @if (isReviewRevampFeatureEnabled()) {
                        <mat-icon class="mr-1 !h-4" [svgIcon]="SvgIcon.CLOCK"></mat-icon>
                    }
                    <span>{{ 'reviews.pending' | translate }}</span>
                </div>
            }
            @case (ReviewBadgeStatus.REJECTED) {
                <div
                    class="malou-chip malou-chip--yellow !h-6"
                    [ngClass]="{ '!malou-text-10 !cursor-default !pl-1': isReviewRevampFeatureEnabled() }">
                    @if (isReviewRevampFeatureEnabled()) {
                        <mat-icon class="mr-1 !h-4" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    }
                    <span>{{ 'reviews.rejected' | translate }}</span>
                </div>
            }
            @case (ReviewBadgeStatus.ANSWERED) {
                <div
                    class="malou-chip malou-chip--green !h-6"
                    [ngClass]="{ '!malou-text-10 !cursor-default !pl-1': isReviewRevampFeatureEnabled() }">
                    @if (isReviewRevampFeatureEnabled()) {
                        <mat-icon class="mr-1 !h-4" [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    }
                    <span>{{ 'reviews.answered' | translate }}</span>
                </div>
            }
        }
    }
</ng-template>
