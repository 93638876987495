import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { StateChange } from 'ng-lazyload-image';

import { MediaType, PostPublicationStatus } from '@malou-io/package-utils';

import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-social-post-media-item',
    templateUrl: './social-post-media-item.component.html',
    styleUrls: ['./social-post-media-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        EnumTranslatePipe,
        ImageViewerComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediaItemComponent {
    readonly media = input.required<SocialPostMedia | null>();

    readonly icon = input<SvgIcon>();
    readonly customIconClass = input<Record<string, boolean>>({});

    readonly status = input<PostPublicationStatus>();
    readonly statusIcon = input<SvgIcon>();
    readonly customStatusContainerClass = input<Record<string, boolean>>({});

    readonly tag = input<string | undefined>();
    readonly link = input<string | undefined>();
    readonly tooltipText = input<string>('');

    readonly shouldLazyLoadMedia = input<boolean>(true);

    readonly refreshMedia = output<ErrorEvent>();
    readonly tagClick = output<void>();

    readonly MediaType = MediaType;
    readonly SvgIcon = SvgIcon;

    readonly statusIconContainerClass = computed(() => ({
        ...this.customStatusContainerClass(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'right-7': this.icon(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'right-2': !this.icon(),
    }));

    refresh(event: ErrorEvent): void {
        this.refreshMedia.emit(event);
    }

    refreshIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refresh(new ErrorEvent('Could not load some media'));
        }
    }

    openLink(): void {
        const link = this.link();
        if (link) {
            window.open(link, '_blank');
        }
    }

    onTagClick(): void {
        this.tagClick.emit();
    }
}
