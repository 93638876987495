export enum KeywordVolumeProvider {
    KEYWORD_TOOL = 'keyword_tool',
    KEYWORD_SURFER = 'keyword_surfer',
}

export const MINIMUM_VOLUME_PROVIDER_CALLS_COUNT_FOR_ONBOARDING = 30;
export const MONTHLY_KEYWORDS_VOLUME_UPDATE_DAY = 25;
export const MAX_KEYWORD_LENGTH = 80;
export const MIN_KEYWORD_LENGTH = 3;
export const MAX_WORDS_COUNT = 10;

// TODO: transform into const when feature toggle 'release-review-revamp' is removed
export enum KeywordScoreMethod {
    STATIC = 'static',
    ADJUSTABLE = 'adjustable',
}
