import { z } from 'zod';

import { addressDtoValidator } from '../restaurant';
import { objectIdValidator } from '../utils';

export const getStoreLocatorStoresValidator = z.array(
    z.object({
        id: objectIdValidator,
        name: z.string(),
        internalName: z.string().optional(),
        address: addressDtoValidator.optional(),
    })
);

export type GetStoreLocatorStoresDto = z.infer<typeof getStoreLocatorStoresValidator>;
