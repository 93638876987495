import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { ReviewAnalysisSentiment, ReviewAnalysisStatus } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { Review, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { SegmentAnalysis } from ':shared/models/segment-analysis';

const REVIEWS_ANALYSIS_START_DATE = DateTime.local().minus({ days: 3 }).toJSDate();

export enum SemanticAnalysisDisplayStyle {
    LIGHT = 'light',
    FULL = 'full',
}

@Component({
    selector: 'app-review-semantic-analysis',
    templateUrl: './review-semantic-analysis.component.html',
    styleUrls: ['./review-semantic-analysis.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewSemanticAnalysisComponent implements OnInit {
    readonly whiteBorders = input<boolean>(false);
    readonly showOriginalTextWarning = input<boolean>(false);
    readonly hoveredChip = output<SegmentAnalyses | SegmentAnalysis>();
    readonly highlightAllSegments = output<void>();
    readonly review = input.required<Review | PrivateReview | undefined>();
    readonly theme = input<SemanticAnalysisDisplayStyle>(SemanticAnalysisDisplayStyle.FULL);

    private readonly _translateService = inject(TranslateService);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly segmentAnalyses = computed((): (SegmentAnalyses | SegmentAnalysis)[] => {
        const review = this.review();
        return this.getUniqueSegmentAnalyses(review);
    });
    readonly analysisStatus = computed((): string => this.getReviewAnalysisStatus(this.review(), this.segmentAnalyses()));

    readonly ReviewAnalysisStatus = ReviewAnalysisStatus;
    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;
    readonly isAnyChipHovered = signal(false);

    readonly SemanticAnalysisDisplayStyle = SemanticAnalysisDisplayStyle;

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis')
    );

    readonly getSegmentAnalysisCategory = computed(() => (segment: SegmentAnalyses | SegmentAnalysis): string => {
        const category = this.isNewSemanticAnalysisFeatureEnabled()
            ? (segment as SegmentAnalysis).category
            : (segment as SegmentAnalyses).tag;
        return this._translateService.instant('reviews.review_analyses.' + category);
    });

    ngOnInit(): void {
        this.highlightAllSegments.emit();
    }

    getReviewAnalysisStatus(
        review: Review | PrivateReview | undefined,
        segmentAnalyses: (SegmentAnalyses | SegmentAnalysis)[]
    ): ReviewAnalysisStatus {
        if (this.isNewSemanticAnalysisFeatureEnabled()) {
            return review?.semanticAnalysisStatus ?? ReviewAnalysisStatus.PENDING;
        }

        if (!review) {
            return ReviewAnalysisStatus.PENDING;
        }
        if (!this._doesPlatformHaveAnalysis()) {
            return ReviewAnalysisStatus.UNSUPPORTED_PLATFORM;
        }
        if (review.semanticAnalysis?.status === ReviewAnalysisStatus.DONE && !segmentAnalyses.length) {
            return ReviewAnalysisStatus.NO_RESULTS;
        }
        if (
            review.semanticAnalysis?.status === ReviewAnalysisStatus.FAILED ||
            review.semanticAnalysis?.status === ReviewAnalysisStatus.DONE
        ) {
            return review.semanticAnalysis?.status;
        }
        if (review.socialCreatedAt < REVIEWS_ANALYSIS_START_DATE) {
            return ReviewAnalysisStatus.REVIEW_TOO_OLD;
        }
        return ReviewAnalysisStatus.PENDING;
    }

    getUniqueSegmentAnalyses(review: Review | PrivateReview | undefined): (SegmentAnalyses | SegmentAnalysis)[] {
        if (this.isNewSemanticAnalysisFeatureEnabled()) {
            return (
                (review?.semanticAnalysisSegments?.filter((segmentAnalysis) => !!segmentAnalysis.sentiment) ?? []).filter(
                    (segmentAnalysis, index, self) =>
                        index ===
                        self.findIndex(
                            (t) =>
                                t.category === segmentAnalysis.category &&
                                t.sentiment.toLowerCase() === segmentAnalysis.sentiment.toLowerCase()
                        )
                ) ?? []
            );
        }
        return (
            (review?.semanticAnalysis?.segmentAnalyses?.filter((segmentAnalysis) => !!segmentAnalysis.sentiment) ?? []).filter(
                (segmentAnalysis, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.tag === segmentAnalysis.tag && t.sentiment.toLowerCase() === segmentAnalysis.sentiment.toLowerCase()
                    )
            ) ?? []
        );
    }

    onMouseEnter(segmentAnalyses: SegmentAnalyses | SegmentAnalysis): void {
        this.isAnyChipHovered.set(true);
        this.hoveredChip.emit(segmentAnalyses);
    }

    onMouseLeave(): void {
        this.isAnyChipHovered.set(false);
        this.highlightAllSegments.emit();
    }

    private _doesPlatformHaveAnalysis(): boolean {
        const review = this.review();
        return !!review && review.doesPlatformHaveAnalysis();
    }
}
