import { AsyncPipe, LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { ReviewsCountChartComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count-chart/reviews-count-chart.component';
import { ReviewsCountTableComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count-table/reviews-count-table.component';
import { RestaurantReviewsCountChartData } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count.interface';
import { ChartReviewsDataWithPreviousPeriod } from ':modules/aggregated-statistics/e-reputation/reviews/reviews.interface';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { getStatisticsError } from ':shared/helpers';
import { ChartReviewsStats, ChartReviewsStatsByRestaurantId, Restaurant } from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-reviews-count-v2',
    templateUrl: './reviews-count.component.html',
    styleUrls: ['./reviews-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatTooltipModule,
        NumberEvolutionComponent,
        ReviewsCountChartComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        TranslateModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        LowerCasePipe,
        MatButtonToggleModule,
        ReviewsCountTableComponent,
        NgClass,
    ],
})
export class ReviewsCountV2Component implements OnInit {
    @Input() chartReviewsDataWithPreviousPeriod$: Observable<ChartReviewsDataWithPreviousPeriod>;
    @Input() isLoading: WritableSignal<boolean>;
    @Input() chartSortBy?: ChartSortBy;
    @Input() tableSort?: Sort;
    @Input() viewMode?: StatisticsDataViewMode;
    @Input() showViewModeToggle = true;
    @Input() showSortByTextInsteadOfSelector = false;
    @Input() expandTable = false;
    @Output() chartSortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() viewModeChange: EventEmitter<StatisticsDataViewMode> = new EventEmitter<StatisticsDataViewMode>();
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;
    readonly StatisticsDataViewMode = StatisticsDataViewMode;
    readonly Illustration = Illustration;
    viewModeSelected = StatisticsDataViewMode.CHART;

    reviewsStats$: Observable<ValueError<ChartReviewsStats[], string>>;
    restaurantReviewsChartData: RestaurantReviewsCountChartData[];
    totalRestaurantsReviews: number;
    totalRestaurantsReviewsEvolution: number;
    warningTooltip: string | null;
    errorTooltip: string | null;

    readonly chartSortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.DESC) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {}

    ngOnInit(): void {
        if (this.chartSortBy) {
            this.chartSortByControl.setValue(this.chartSortBy);
        }
        if (this.viewMode) {
            this.viewModeSelected = this.viewMode;
        }
        this.reviewsStats$ = this.loadAggregatedReviewsStats$();
    }

    loadAggregatedReviewsStats$(): Observable<ValueError<ChartReviewsStats[], string>> {
        return this.chartReviewsDataWithPreviousPeriod$.pipe(
            tap(({ currentChartReviewsDataByRestaurantId, restaurants }) => {
                this.errorTooltip = this._getErrorTooltip(currentChartReviewsDataByRestaurantId, restaurants);
                this.warningTooltip = this._getWarningTooltip(restaurants);
            }),
            map(({ currentChartReviewsDataByRestaurantId, previousChartReviewsDataByRestaurantId }) => {
                this._store.dispatch(
                    AggregatedStatisticsActions.editReviewCountsData({ data: currentChartReviewsDataByRestaurantId.getChartReviewStats() })
                );
                const restaurantsIdsInCurrentStats = currentChartReviewsDataByRestaurantId.getPresentRestaurantIds();
                const restaurantsIdsInPreviousStats = previousChartReviewsDataByRestaurantId.getPresentRestaurantIds();
                const restaurantIdsToCheck = [...restaurantsIdsInCurrentStats, ...restaurantsIdsInPreviousStats];
                const uniqRestaurantIdsToCheck = uniq(restaurantIdsToCheck);
                this.restaurantReviewsChartData = uniqRestaurantIdsToCheck
                    .map((restaurantId) => {
                        const currentStats = currentChartReviewsDataByRestaurantId.get(restaurantId);
                        const previousStats = previousChartReviewsDataByRestaurantId.get(restaurantId);
                        const restaurant = currentStats?.restaurant ?? previousStats?.restaurant!; // Restaurant is present in at least one of the stats
                        return {
                            restaurantName: restaurant.internalName ?? restaurant.name,
                            restaurantAddress: restaurant.address?.getAddressWithDistrict(),
                            reviewsCountPerPlatform: this._getReviewsCountPerPlatform(currentStats),
                            total: currentStats?.total ?? 0,
                            evolution: this._getReviewsCountEvolution(currentStats, previousStats),
                        };
                    })
                    .sort((a, b) => (b.restaurantName > a.restaurantName ? -1 : 1));
                this.totalRestaurantsReviews = currentChartReviewsDataByRestaurantId.getReviewCount();
                const previousTotalRestaurantsReviews = previousChartReviewsDataByRestaurantId.getReviewCount();
                this.totalRestaurantsReviewsEvolution = this.totalRestaurantsReviews - previousTotalRestaurantsReviews;
                if (!this.totalRestaurantsReviews) {
                    this.hasDataChange.emit(false);
                }
                this.isLoading.set(false);
                return currentChartReviewsDataByRestaurantId.getChartReviewStats();
            }),
            map((values) => ({ value: values })),
            catchError((error) => {
                this.hasDataChange.emit(false);
                this.isLoading.set(false);
                return of({
                    error: error instanceof HttpResponseBase ? getStatisticsError(error) : error.message,
                });
            })
        );
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onChartSortByChange(sortBy: ChartSortBy): void {
        this.chartSortByControl.setValue(sortBy);
        this.chartSortByChange.emit(sortBy);
    }

    onTableSortChange(sort: Sort): void {
        this.tableSortByChange.emit(sort);
    }

    onViewModeChange(value: StatisticsDataViewMode): void {
        this.viewModeSelected = value;
        this.viewModeChange.emit(value);
    }

    private _getReviewsCountPerPlatform(reviewStats: ChartReviewsStats | undefined): { [key: string]: number } {
        const reviewsCountPerPlatform = {};
        (reviewStats?.reviews ?? []).forEach((review) => {
            if (reviewsCountPerPlatform[review.key]) {
                reviewsCountPerPlatform[review.key]++;
            } else {
                reviewsCountPerPlatform[review.key] = 1;
            }
        });
        return reviewsCountPerPlatform;
    }

    private _getReviewsCountEvolution(currentStats: ChartReviewsStats | undefined, previousStats: ChartReviewsStats | undefined): number {
        const currentReviewsCount = currentStats?.total ?? 0;
        const previousReviewsCount = previousStats?.total ?? 0;
        return currentReviewsCount - previousReviewsCount;
    }

    private _getWarningTooltip(restaurants: Restaurant[]): string | null {
        const selectedBrandBusiness = restaurants.filter((r) => r.isBrandBusiness());
        if (!selectedBrandBusiness.length) {
            return null;
        }
        const restaurantsLabel = selectedBrandBusiness.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _getErrorTooltip(
        currentRestaurantReviewsStatsByRestaurantId: ChartReviewsStatsByRestaurantId,
        restaurants: Restaurant[]
    ): string | null {
        const restaurantsLabel = restaurants
            .filter((r) => !r.isBrandBusiness())
            .reduce((acc, curr) => {
                const currentRestaurantReviewsStatsForRestaurant = currentRestaurantReviewsStatsByRestaurantId.get(curr._id);
                if (!currentRestaurantReviewsStatsForRestaurant) {
                    return acc + curr.internalName + ', ';
                }
                return acc;
            }, '');
        if (restaurantsLabel === '') {
            return null;
        }
        return this._translate.instant('aggregated_statistics.errors.gmb_fetching_error', {
            restaurants: restaurantsLabel,
        });
    }
}
