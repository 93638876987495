import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isNil, mean, sum } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import { AggregationTimeScale, isNotNil, MalouComparisonPeriod, PlatformKey, PostType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SocialNetworksCommunityInsightsContext } from ':modules/statistics/social-networks/context/social-networks-community-insights.context';
import { SocialNetworksPostsInsightsContext } from ':modules/statistics/social-networks/context/social-networks-post-insights.context';
import { EngagementChartV2Component } from ':modules/statistics/social-networks/engagement-v2/engagement-chart/engagement-chart.component';
import { EngagementData, EngagementDataType } from ':modules/statistics/social-networks/engagement-v2/engagement.interface';
import { SplittedEngagementData } from ':modules/statistics/social-networks/engagement/engagement-chart/engagement-chart.component';
import { EngagementInsightsChartData } from ':modules/statistics/social-networks/models/engagement-insight-chart-data';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { DatesAndPeriod, PostWithInsights } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface CurrentAndDiffInsights {
    current: number | null;
    diff: number | null;
}

const DEFAULT_SPLITTED_DATA: SplittedEngagementData = {
    facebookData: [],
    instagramData: [],
    total: [],
};

@Component({
    selector: 'app-engagement-v2',
    templateUrl: './engagement.component.html',
    styleUrls: ['./engagement.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        NumberEvolutionComponent,
        ShortNumberPipe,
        LowerCasePipe,
        ApplyPurePipe,
        EngagementChartV2Component,
        MatTooltipModule,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementV2Component implements OnInit {
    readonly showViewByTextInsteadOfSelector = input<boolean>(false);
    readonly viewBy = input<ViewBy>(ViewBy.DAY);
    readonly hiddenDatasetIndexes = input<number[]>([]);

    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _socialNetworksCommunityInsightsContext = inject(SocialNetworksCommunityInsightsContext);
    private readonly _socialNetworksPostsInsightsContext = inject(SocialNetworksPostsInsightsContext);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly Illustration = Illustration;
    readonly ViewBy = ViewBy;

    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelector.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly httpError: WritableSignal<string | null> = signal(null);
    readonly insightsError: WritableSignal<string | null> = signal(null);
    readonly viewByFilter: WritableSignal<ViewBy> = signal(ViewBy.DAY);

    private readonly _engagementChartData = signal<EngagementInsightsChartData>({} as EngagementInsightsChartData);
    private readonly _previousEngagementChartData = signal<EngagementInsightsChartData>({} as EngagementInsightsChartData);
    private readonly _currentTotalEngagementRate: WritableSignal<number | null> = signal(null);
    private readonly _previousTotalEngagementRate: WritableSignal<number | null> = signal(null);
    private readonly _currentTotalImpressions: WritableSignal<number | null> = signal(null);
    private readonly _previousTotalImpressions: WritableSignal<number | null> = signal(null);

    readonly areAllPlatformsInError = computed(() => this._socialNetworksPostsInsightsContext.areAllPlatformsInError());
    readonly platformsErrorTooltip = computed(() => this._socialNetworksPostsInsightsContext.platformsErrorTooltip());
    readonly comparisonPeriod = computed(() => this._socialNetworksPostsInsightsContext.comparisonPeriod());
    readonly isLoading = computed(
        () => this._socialNetworksCommunityInsightsContext.isLoading() || this._socialNetworksPostsInsightsContext.isLoading()
    );
    readonly comparisonPeriodKey = computed(() => {
        const comparisonPeriod = this._previousEngagementChartData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD;
        return `date_filter.comparison_period.${comparisonPeriod}`;
    });
    readonly comparedToKey = computed(() => {
        const comparisonPeriod = this._previousEngagementChartData().comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD;
        return `statistics.common.compared_to.${comparisonPeriod}`;
    });
    readonly totalEngagementRate: Signal<CurrentAndDiffInsights> = computed(() => {
        const current = this._currentTotalEngagementRate();
        const previous = this._previousTotalEngagementRate();
        return {
            current: current,
            diff: isNotNil(current) && isNotNil(previous) ? current - previous : null,
        };
    });

    readonly totalImpressions: Signal<CurrentAndDiffInsights> = computed(() => {
        const current = this._currentTotalImpressions();
        const previous = this._previousTotalImpressions();
        return {
            current: current,
            diff: isNotNil(current) && isNotNil(previous) ? current - previous : null,
        };
    });

    readonly previousTotalImpressions: Signal<number | null> = computed(() => this._previousTotalImpressions());
    readonly previousTotalEngagementRate: Signal<number | null> = computed(() => this._previousTotalEngagementRate());

    readonly engagementData: Signal<{ currentEngagementData: EngagementData; previousEngagementData: EngagementData }> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return {
                    currentEngagementData: this._getEngagementData(this._engagementChartData(), AggregationTimeScale.BY_MONTH),
                    previousEngagementData: this._getEngagementData(this._previousEngagementChartData(), AggregationTimeScale.BY_MONTH),
                };
                break;
            case ViewBy.WEEK:
                return {
                    currentEngagementData: this._getEngagementData(this._engagementChartData(), AggregationTimeScale.BY_WEEK),
                    previousEngagementData: this._getEngagementData(this._previousEngagementChartData(), AggregationTimeScale.BY_WEEK),
                };
                break;
            default:
            case ViewBy.DAY:
                return {
                    currentEngagementData: this._getEngagementData(this._engagementChartData(), AggregationTimeScale.BY_DAY),
                    previousEngagementData: this._getEngagementData(this._previousEngagementChartData(), AggregationTimeScale.BY_DAY),
                };
                break;
        }
    });

    readonly labels: Signal<{ currentLabels: Date[]; previousLabels: Date[] }> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return {
                    currentLabels: this._getLabels(this._engagementChartData(), AggregationTimeScale.BY_MONTH),
                    previousLabels: this._getLabels(this._previousEngagementChartData(), AggregationTimeScale.BY_MONTH),
                };
            case ViewBy.WEEK:
                return {
                    currentLabels: this._getLabels(this._engagementChartData(), AggregationTimeScale.BY_WEEK),
                    previousLabels: this._getLabels(this._previousEngagementChartData(), AggregationTimeScale.BY_WEEK),
                };
            default:
            case ViewBy.DAY:
                return {
                    currentLabels: this._getLabels(this._engagementChartData(), AggregationTimeScale.BY_DAY),
                    previousLabels: this._getLabels(this._previousEngagementChartData(), AggregationTimeScale.BY_DAY),
                };
        }
    });

    constructor() {
        effect(
            () => {
                this.isLoadingEvent.emit(this.isLoading());
                this._computeChartData();
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByFilterSubject$.next(this.viewBy());
        }

        this._socialNetworksPostsInsightsContext.postsWithInsights$.subscribe();

        this.viewByFilterSubject$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (viewByFilter) => {
                this.viewByChange.emit(viewByFilter);
                this.viewByFilter.set(viewByFilter);
            },
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    private _computeChartData(): void {
        const currentPostsInsights = this._socialNetworksPostsInsightsContext.currentPostsWithInsightsByPlatform();
        const previousPostsInsights = this._socialNetworksPostsInsightsContext.previousPostsWithInsightsByPlatform();

        const dailyInsightsChartData =
            this._socialNetworksCommunityInsightsContext.dailyStoredInsightsResponseDtoData()[
                this._restaurantsService.currentRestaurant._id
            ];
        const dailyPreviousInsightsChartData =
            this._socialNetworksCommunityInsightsContext.dailyPreviousStoredInsightsResponseDtoData()[
                this._restaurantsService.currentRestaurant._id
            ];

        if (!currentPostsInsights || !dailyInsightsChartData) {
            return;
        }

        const startDate = this._socialNetworksCommunityInsightsContext.startDate();
        const endDate = this._socialNetworksCommunityInsightsContext.endDate();

        const previousPeriod = this._socialNetworksPostsInsightsContext.previousPeriodDates();

        if (!startDate || !endDate) {
            return;
        }

        const engagementInsightsChartData = new EngagementInsightsChartData({
            data: {
                postsWithInsightsByPlatforms: currentPostsInsights,
                followersCountByPlatformAndDay: dailyInsightsChartData,
            },
            previousData:
                previousPostsInsights && dailyPreviousInsightsChartData
                    ? {
                          postsWithInsightsByPlatforms: previousPostsInsights,
                          followersCountByPlatformAndDay: dailyPreviousInsightsChartData,
                      }
                    : null,
            startDate,
            endDate,
        });

        if (previousPostsInsights && dailyPreviousInsightsChartData && previousPeriod) {
            const previousEngagementInsightsChartData = new EngagementInsightsChartData({
                data: {
                    postsWithInsightsByPlatforms: previousPostsInsights,
                    followersCountByPlatformAndDay: dailyPreviousInsightsChartData,
                },
                previousData: null,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate,
                comparisonPeriod: this.comparisonPeriod(),
            });
            this._previousEngagementChartData.set(previousEngagementInsightsChartData);
        }

        this._engagementChartData.set(engagementInsightsChartData);

        // kpi data
        this._currentTotalEngagementRate.set(
            this._computeTotalEngagementRate(engagementInsightsChartData.postsWithInsights, dailyInsightsChartData)
        );
        this._currentTotalImpressions.set(this._computeTotalImpressions(engagementInsightsChartData.postsWithInsights));

        if (previousPostsInsights && dailyPreviousInsightsChartData) {
            const previousPostsWithInsights = engagementInsightsChartData.previousPostsWithInsights;
            this._previousTotalEngagementRate.set(
                this._computeTotalEngagementRate(previousPostsWithInsights, dailyPreviousInsightsChartData)
            );
            this._previousTotalImpressions.set(this._computeTotalImpressions(previousPostsWithInsights));
        }
    }

    private _computeTotalEngagementRate(
        posts: PostWithInsights[],
        followersInsights: Partial<Record<PlatformKey, DailyPlatformInsights>>
    ): number {
        const engagementRateByPlatforms: number[] = [];
        const platforms = Object.keys(followersInsights) as PlatformKey[];
        for (const platform of platforms) {
            if (!followersInsights[platform]?.hasData || followersInsights[platform]?.malouErrorCode) {
                continue;
            }
            const platformPosts = posts.filter((post) => post.key === platform);
            const platformLastFollowersCount = Object.values(followersInsights[platform]?.insights?.followers!).slice(-1)[0] ?? 0;
            engagementRateByPlatforms.push(this._computeTotalEngagementRateByPlatform(platformPosts, platformLastFollowersCount));
        }
        return mean(engagementRateByPlatforms);
    }

    private _computeTotalEngagementRateByPlatform(platformPosts: PostWithInsights[], platformLastFollowersCount: number): number {
        if (platformPosts.length === 0 || platformLastFollowersCount === 0) {
            return 0;
        }
        const currentTotalEngagement = sum(platformPosts.map((post) => post.getEngagement()));
        return (currentTotalEngagement * 100) / platformLastFollowersCount / platformPosts.length;
    }

    private _computeTotalImpressions(posts: PostWithInsights[]): number {
        const postsImpressions = sum(posts.filter((post) => post.postType !== PostType.REEL).map((post) => post.impressions));
        const reelsImpressions = sum(posts.filter((post) => post.postType === PostType.REEL).map((post) => post.plays));
        return postsImpressions + reelsImpressions;
    }

    private _getEngagementData(
        engagementChartData: EngagementInsightsChartData,
        aggregationTimeScale: AggregationTimeScale
    ): EngagementData {
        if (isNil(engagementChartData.partialEngagementData)) {
            return {
                [EngagementDataType.ENGAGEMENT]: DEFAULT_SPLITTED_DATA,
                [EngagementDataType.IMPRESSIONS]: DEFAULT_SPLITTED_DATA,
                postsCount: DEFAULT_SPLITTED_DATA,
            };
        }
        return {
            [EngagementDataType.ENGAGEMENT]: engagementChartData.partialEngagementData[aggregationTimeScale],
            [EngagementDataType.IMPRESSIONS]: engagementChartData.partialImpressionsData[aggregationTimeScale],
            postsCount: engagementChartData.postCount[aggregationTimeScale],
        };
    }

    private _getLabels(engagementChartData: EngagementInsightsChartData, aggregationTimeScale: AggregationTimeScale): Date[] {
        if (isNil(engagementChartData.dates)) {
            return [];
        }
        return engagementChartData.dates[aggregationTimeScale];
    }
}
