import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { times } from ':core/constants';
import { DEFAULT_HOURS_PERIOD } from ':modules/informations/hours-modal/hours-modal.interface';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { Period, TIME_24 } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

const DEFAULT_MAX_PERIOD_COUNT = 3; // this is due to Facebook restrictions
@Component({
    selector: 'app-select-open-hours',
    templateUrl: './select-open-hours.component.html',
    styleUrls: ['./select-open-hours.component.scss'],
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, MatIconModule, TranslateModule, SimpleSelectComponent, ApplyPurePipe, FormatTimePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOpenHoursComponent {
    readonly periods = model.required<Period[]>();
    readonly withIsClosedSelection = input<boolean>(true);
    readonly isRestaurantClosedDuringPeriods = model<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly maxPeriodCount = input<number>(DEFAULT_MAX_PERIOD_COUNT);

    private readonly _translateService = inject(TranslateService);

    readonly TIMES = times;
    readonly TIME_24 = TIME_24;
    readonly TIMES_WITH_24 = [this.TIME_24, ...times];
    readonly IS_RESTAURANT_CLOSED_OPTIONS = [false, true];

    readonly SvgIcon = SvgIcon;

    updateOpenTime(index: number, value: string): void {
        if (this.disabled() || !value) {
            return;
        }
        if (this.periods()[index].openTime !== value) {
            this.periods.update((currentPeriods) => {
                const closeTime = value === this.TIME_24 ? null : currentPeriods[index].closeTime;
                currentPeriods[index] = new Period({ ...currentPeriods[index], openTime: value, closeTime });
                return [...currentPeriods];
            });
        }
    }

    updateCloseTime(index: number, value: string): void {
        if (this.disabled() || !value) {
            return;
        }
        if (this.periods()[index].closeTime !== value) {
            this.periods.update((currentPeriods) => {
                currentPeriods[index] = new Period({ ...currentPeriods[index], closeTime: value });
                return [...currentPeriods];
            });
        }
    }

    deletePeriod(index: number): void {
        if (this.disabled() || this.periods().length <= 1) {
            return;
        }
        this.periods.update((periods) => {
            if (periods.length <= 1) {
                return periods;
            }
            return periods.filter((_period, i) => i !== index);
        });
    }

    addPeriod(): void {
        if (this.disabled() || this.periods().length >= this.maxPeriodCount()) {
            return;
        }
        this.periods.update((periods) => [...periods, DEFAULT_HOURS_PERIOD]);
    }

    displayIsRestaurantClosedDuringPeriodsWith = (value: boolean): string =>
        value
            ? this._translateService.instant('information.special_hours.closed')
            : this._translateService.instant('information.special_hours.open');

    updateIsRestaurantClosedDuringPeriods(value: boolean): void {
        if (value === null) {
            return;
        }
        this.isRestaurantClosedDuringPeriods.set(value);
    }

    getPeriodOpenTimeError = (period: Period): string => {
        if (!period.openTime && !this.disabled()) {
            return this._translateService.instant('common.required_field');
        }
        return '';
    };

    getPeriodCloseTimeError = (period: Period): string => {
        if (period.openTime !== this.TIME_24 && !period.closeTime && !this.disabled()) {
            return this._translateService.instant('common.required_field');
        }
        if (!!period.closeTime && period.openTime === period.closeTime && !this.disabled()) {
            return this._translateService.instant('information.business_hours.hours_should_be_different');
        }
        return '';
    };
}
