import { AsyncPipe, KeyValuePipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { isNotNil, PartialRecord, ReviewAnalysisSentiment, ReviewAnalysisTag } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { NavBarTab, TabNavBarComponent } from ':shared/components/tab-nav-bar/tab-nav-bar.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';
import { ChartDataArray } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { ReviewWithAnalysis, SegmentWithReview } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { SelectComponent } from '../../select/select.component';
import { TagEvolutionData, TagsEvolutionChartComponent } from './tags-evolution-chart/tags-evolution-chart.component';

const analysisTagsSorted: ReviewAnalysisTag[] = [
    ReviewAnalysisTag.FOOD,
    ReviewAnalysisTag.SERVICE,
    ReviewAnalysisTag.ATMOSPHERE,
    ReviewAnalysisTag.PRICE,
    ReviewAnalysisTag.EXPEDITIOUSNESS,
    ReviewAnalysisTag.HYGIENE,
];

@Component({
    selector: 'app-tags-evolution',
    templateUrl: './tags-evolution.component.html',
    styleUrls: ['./tags-evolution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        TabNavBarComponent,
        TagsEvolutionChartComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        LowerCasePipe,
        KeyValuePipe,
    ],
    providers: [EnumTranslatePipe],
})
@AutoUnsubscribeOnDestroy()
export class TagsEvolutionComponent implements OnInit, KillSubscriptions {
    @Input() warningTooltip?: string;
    @Input() shouldDetailTagsEvolutionCharts: boolean;
    @Input() sortBy?: ChartSortBy;
    @Output() sortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();

    readonly SvgIcon = SvgIcon;

    _reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>;
    currentTag: string;
    tabs: NavBarTab[] = [];
    labels: string[];
    tagEvolutionDataByTag: Record<string, TagEvolutionData> = {};
    noData = true;

    viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.WEEK);
    viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.WEEK) as FormControl<ViewBy>;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;
    readonly CHART_SORT_BY_VALUES = Object.values(ChartSortBy);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {}

    get reviewsByRestaurantId(): Record<string, ReviewWithAnalysis[]> {
        return this._reviewsByRestaurantId;
    }

    @Input() set reviewsByRestaurantId(value: Record<string, ReviewWithAnalysis[]>) {
        this._reviewsByRestaurantId = this._cleanReviewByRestaurantsId(value);
        this._initTagEvolutionData(this.reviewsByRestaurantId);
    }

    ngOnInit(): void {
        if (this.sortBy) {
            this.sortByControl.setValue(this.sortBy);
        }
    }

    onTabChange(tab: NavBarTab): void {
        this.currentTag = tab.data;
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    getChartTitleByKey = (key: string): string => this._translate.instant(`reviews.review_analyses.${key}`);

    onSortByChange(sortBy: ChartSortBy): void {
        this.sortByControl.setValue(sortBy);
        this.sortByChange.emit(sortBy);
    }

    private _cleanReviewByRestaurantsId(reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>): Record<string, ReviewWithAnalysis[]> {
        return Object.entries(reviewsByRestaurantId).reduce(
            (acc, [restaurantId, review]) => ({
                ...acc,
                [restaurantId]: this._getReviewsWithAnalysisWithPositiveAndNegativeSentimentsForRestaurant(review),
            }),
            {}
        );
    }

    private _getReviewsWithAnalysisWithPositiveAndNegativeSentimentsForRestaurant(reviews: ReviewWithAnalysis[]): ReviewWithAnalysis[] {
        return reviews.map((review) => ({
            ...review,
            semanticAnalysis: {
                ...review.semanticAnalysis,
                segmentAnalyses: review?.semanticAnalysis?.segmentAnalyses?.filter((segment) =>
                    [ReviewAnalysisSentiment.POSITIVE, ReviewAnalysisSentiment.NEGATIVE].includes(segment.sentiment)
                ),
            },
        })) as ReviewWithAnalysis[];
    }

    private _initTagEvolutionData(reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>): void {
        const tagsWithCount: PartialRecord<ReviewAnalysisTag, number> = this._getTagsWithCount(reviewsByRestaurantId);
        const allTags: string[] = Object.keys(tagsWithCount);
        this.tabs = Object.entries(tagsWithCount)
            .map(([key, value]: [ReviewAnalysisTag, number]) => ({
                title: this._translate.instant(`reviews.review_analyses.${key}`),
                subtitle: `(${value})`,
                data: key,
            }))
            .sort((a, b) => analysisTagsSorted.indexOf(a.data) - analysisTagsSorted.indexOf(b.data));
        if (this.tabs.length === 0) {
            this.noData = true;
            return;
        } else {
            this.noData = false;
        }
        if (!this.currentTag) {
            this.currentTag = this.tabs[0].data;
        }

        const segmentsWithReviewByTagByRestaurantId: Record<string, Record<string, SegmentWithReview[]>> = Object.entries(
            reviewsByRestaurantId
        ).reduce(
            (acc, cur) => ({
                ...acc,
                [cur[0]]: this._getSegmentsWithReviewByTag(cur[1]),
            }),
            {}
        );

        const restaurantsIds: string[] = Object.keys(reviewsByRestaurantId);
        const tagEvolutionDataByTag: Record<string, TagEvolutionData> = {};

        for (const tag of allTags) {
            const chartDataNegative: ChartDataArray = [];
            const chartDataPositive: ChartDataArray = [];
            for (const restaurantId of restaurantsIds) {
                const negativeSentimentValue = (segmentsWithReviewByTagByRestaurantId[restaurantId][tag] ?? []).filter(
                    (segment) => segment.sentiment === ReviewAnalysisSentiment.NEGATIVE
                ).length;
                const positiveSentimentValue = (segmentsWithReviewByTagByRestaurantId[restaurantId][tag] ?? []).filter(
                    (segment) => segment.sentiment === ReviewAnalysisSentiment.POSITIVE
                ).length;

                chartDataNegative.push(negativeSentimentValue);
                chartDataPositive.push(positiveSentimentValue);
            }
            tagEvolutionDataByTag[tag] = {
                [ReviewAnalysisSentiment.NEGATIVE]: chartDataNegative,
                [ReviewAnalysisSentiment.POSITIVE]: chartDataPositive,
            };
        }

        this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)).subscribe((restaurants) => {
            this.tagEvolutionDataByTag = tagEvolutionDataByTag;
            this.labels = restaurantsIds
                .map((id) => restaurants.find((r) => r._id === id)?.internalName)
                .filter(isNotNil)
                .sort((a, b) => a.localeCompare(b));
        });
    }

    private _getTagsWithCount(reviewsByRestaurantId: Record<string, ReviewWithAnalysis[]>): PartialRecord<ReviewAnalysisTag, number> {
        return Object.values(reviewsByRestaurantId)
            .flatMap((e) => e)
            .flatMap((review) => review.semanticAnalysis?.segmentAnalyses ?? [])
            .map((segment) => segment.tag)
            .reduce((acc, segmentTag) => {
                acc[segmentTag] = (acc[segmentTag] ?? 0) + 1;
                return acc;
            }, {});
    }

    private _getSegmentsWithReviewByTag(reviews: ReviewWithAnalysis[]): Record<string, SegmentWithReview[]> {
        const segmentsWithReview = reviews.flatMap((review) =>
            (review.semanticAnalysis?.segmentAnalyses ?? []).map((segmentAnalyses) => ({
                review: review,
                ...segmentAnalyses,
            }))
        );
        return groupBy(segmentsWithReview, 'tag');
    }
}
