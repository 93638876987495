<div class="flex flex-col gap-6 px-[8%] py-8 md:gap-4 md:px-0 md:py-4">
    <div class="flex flex-col md:gap-2 md:px-[8%]">
        <div class="malou-text-30--bold md:malou-text-20--bold text-center text-malou-color-text-1">
            <span class="md:hidden">🚀</span> {{ 'boosters.presentation.title' | translate }}
        </div>
        <div class="malou-text-13--medium md:malou-text-12--medium text-center text-malou-color-text-2">
            {{ 'boosters.presentation.subtitle' | translate }}
        </div>
    </div>

    <div class="flex md:flex-col">
        <div class="min-w-[50%] max-w-[50%] overflow-hidden rounded-l-[10px] md:min-w-full md:max-w-full md:rounded-none">
            <img class="h-full object-cover" [src]="'boosterPack' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>

        <div
            class="flex flex-col justify-between rounded-r-[10px] border border-malou-color-border-primary bg-white px-12 py-10 md:gap-3 md:rounded-none md:px-8 md:py-6">
            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.totems_and_stickers' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.positive_reviews' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.retain_your_customers' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-3">
                <div class="flex w-1/2 flex-col gap-2">
                    <app-button
                        id="tracking_boosters_get_it_now"
                        buttonClasses="h-11 w-full"
                        [disabled]="isRequestSent()"
                        [loading]="isSendingRequest()"
                        [text]="'boosters.presentation.get_now' | translate"
                        [tooltip]="isRequestSent() ? ('wheel_of_fortune.subscription_request_modal.request_sent' | translate) : ''"
                        (click)="sendRequest()"></app-button>
                    <div class="malou-text-10 text-center italic text-malou-color-text-2">
                        {{ 'boosters.presentation.you_will_be_contacted' | translate }}
                    </div>
                </div>

                <button class="malou-btn-raised--secondary h-11 w-1/2" id="tracking_boosters_more_information" mat-raised-button>
                    {{ 'boosters.presentation.learn_more' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="flex rounded-[5px] border border-malou-color-border-primary bg-white md:mx-6 md:flex-col">
        <div class="flex min-w-[160px] flex-col justify-center bg-malou-color-background-dark px-3 py-2 md:items-center md:py-3">
            <div class="malou-text-30">🏆</div>
            <div class="malou-text-10--medium text-malou-color-text-2">
                {{ 'boosters.presentation.do_like_our_client' | translate }}
            </div>
            <div class="malou-text-17--bold text-malou-color-text-1">PACIFIC GRILL</div>
        </div>
        <div class="flex grow py-3 md:flex-col md:px-3 md:py-0">
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 md:border-b md:border-r-0 md:px-10 md:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.more_reviews.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.more_reviews.text' | translate }}
                </div>
            </div>
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 md:border-b md:border-r-0 md:px-10 md:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.reviews_per_month.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.reviews_per_month.text' | translate }}
                </div>
            </div>
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 md:border-b md:border-r-0 md:px-10 md:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.average_rating.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.average_rating.text' | translate }}
                </div>
            </div>
            <div class="flex grow basis-0 flex-col items-center justify-center px-3 py-2 md:px-10 md:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.gift_retrieved.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.gift_retrieved.text' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
