<ng-container [ngTemplateOutlet]="shouldDetailPostTables() ? detailedInsightsTemplate : defaultInsightsTemplate"></ng-container>

<ng-template #detailedInsightsTemplate>
    <div class="flex flex-col gap-6">
        @if (shouldDisplayPostInsightsTable() && !(posts().length === 0 && shouldHidePublicationsTablesIfNoData())) {
            <div class="flex flex-col gap-2">
                <span class="malou-text-18--bold malou-color-text-1">{{ 'statistics.social_networks.posts.posts' | translate }}</span>
                <ng-container [ngTemplateOutlet]="postsTabTemplate"></ng-container>
            </div>
        }
        @if (shouldDisplayReelInsightsTable() && !(reels().length === 0 && shouldHidePublicationsTablesIfNoData())) {
            <div class="flex flex-col gap-2">
                <span class="malou-text-18--bold malou-color-text-1">{{ 'statistics.social_networks.posts.reels' | translate }}</span>
                <ng-container [ngTemplateOutlet]="reelsTabTemplate"></ng-container>
            </div>
        }
        @if (shouldDisplayStoryInsightsTable() && !(storiesHasNoData() && shouldHidePublicationsTablesIfNoData())) {
            <div class="flex flex-col gap-2">
                <span class="malou-text-18--bold malou-color-text-1">{{ 'statistics.social_networks.stories.stories' | translate }}</span>
                <ng-container [ngTemplateOutlet]="storiesTabTemplate"></ng-container>
            </div>
        }
    </div>
</ng-template>

<ng-template #defaultInsightsTemplate>
    <mat-tab-group animationDuration="5ms" mat-align-tabs="start" (selectedIndexChange)="handleTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="malou-text-14--medium break-inside-avoid">
                    <span class="tab-title">{{ 'statistics.social_networks.posts.posts' | translate }}</span>
                    <span class="italic">({{ postsCount() }})</span>
                </span>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="malou-text-14--medium break-inside-avoid">
                    <span class="tab-title">{{ 'statistics.social_networks.posts.reels' | translate }}</span>
                    <span class="italic">({{ reelsCount() }})</span>
                </span>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="malou-text-14--medium break-inside-avoid">
                    <span class="tab-title">{{ 'statistics.social_networks.stories.stories' | translate }}</span>
                    <span class="italic">({{ storiesCount() }})</span>
                </span>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    @switch (selectedTab()) {
        @case (SocialNetworkInsightsTabs.POSTS) {
            @if (shouldDisplayPostInsightsTable() && !(posts().length === 0 && shouldHidePublicationsTablesIfNoData)) {
                <ng-container [ngTemplateOutlet]="postsTabTemplate"></ng-container>
            }
        }
        @case (SocialNetworkInsightsTabs.REELS) {
            @if (shouldDisplayReelInsightsTable() && !(reels().length === 0 && shouldHidePublicationsTablesIfNoData)) {
                <ng-container [ngTemplateOutlet]="reelsTabTemplate"></ng-container>
            }
        }
        @case (SocialNetworkInsightsTabs.STORIES) {
            @if (shouldDisplayStoryInsightsTable() && !(storiesHasNoData() && shouldHidePublicationsTablesIfNoData)) {
                <ng-container [ngTemplateOutlet]="storiesTabTemplate"></ng-container>
            }
        }
        @default {
            <div></div>
        }
    }
</ng-template>

<ng-template #postsTabTemplate>
    <div class="flex flex-col gap-y-8 pt-8">
        @if (topPostCardInputsFromPosts().length > 0) {
            <div class="malou-text-14--semibold malou-color-text-1 flex">
                {{ 'statistics.social_networks.posts_insights_table.top_3_posts_title' | translate }}
            </div>
            <div class="flex break-inside-avoid gap-6 md:flex-col">
                @if (isLoading()) {
                    @for (post of [1, 2, 3]; track post) {
                        <div class="flex-1">
                            <app-top-post-card-skeleton [withRestaurantInfo]="false"></app-top-post-card-skeleton>
                        </div>
                    }
                }
                @if (!isLoading()) {
                    @for (post of topPostCardInputsFromPosts(); track post.url) {
                        <div class="flex-1">
                            <app-top-post-card [topPostCardInputData]="post"></app-top-post-card>
                        </div>
                    }
                    @for (_ of 3 - topPostCardInputsFromPosts().length | createArray; track _) {
                        <div class="flex-1"></div>
                    }
                }
            </div>
        }

        <app-social-networks-posts
            [posts]="posts"
            [areAllPlatformsInError]="areAllPlatformsInError()"
            [platformsErrorTooltip]="platformsErrorTooltip() ?? ''"
            [httpError]="httpError()"
            [isLoading]="isLoading()"
            [shouldDisplayElementsPaginated]="!shouldDetailPostTables()"
            [shouldLazyLoadMedia]="shouldLazyLoadMedia()"
            [tableSortOptions]="postsTableSortOptions()"
            (tableSortOptionsChange)="tableSortOptionsChange.emit($event)">
        </app-social-networks-posts>
    </div>
</ng-template>

<ng-template #reelsTabTemplate>
    <div class="flex flex-col gap-y-8 pt-8">
        @if (topPostCardInputsFromReels().length > 0) {
            <div class="malou-text-14--semibold malou-color-text-1 flex">
                {{ 'statistics.social_networks.posts_insights_table.top_3_reels_title' | translate }}
            </div>
            <div class="flex break-inside-avoid gap-x-6 md:flex-col">
                @if (isLoading()) {
                    @for (post of [1, 2, 3]; track post) {
                        <div class="flex-1">
                            <app-top-post-card-skeleton [withRestaurantInfo]="false"></app-top-post-card-skeleton>
                        </div>
                    }
                }
                @if (!isLoading()) {
                    @for (post of topPostCardInputsFromReels(); track post.url) {
                        <div class="flex-1">
                            <app-top-post-card [topPostCardInputData]="post"></app-top-post-card>
                        </div>
                    }
                    @for (_ of 3 - topPostCardInputsFromReels().length | createArray; track _) {
                        <div class="flex-1"></div>
                    }
                }
            </div>
        }

        <app-social-networks-posts
            [posts]="reels"
            [isReelTable]="true"
            [areAllPlatformsInError]="areAllPlatformsInError()"
            [platformsErrorTooltip]="platformsErrorTooltip() ?? ''"
            [httpError]="httpError()"
            [isLoading]="isLoading()"
            [shouldDisplayElementsPaginated]="!shouldDetailPostTables()"
            [shouldLazyLoadMedia]="shouldLazyLoadMedia()"
            [tableSortOptions]="reelsTableSortOptions()"
            (tableSortOptionsChange)="tableSortOptionsChange.emit($event)">
        </app-social-networks-posts>
    </div>
</ng-template>

<ng-template #storiesTabTemplate>
    <app-social-network-stories
        [shouldHideIfNoData]="shouldHidePublicationsTablesIfNoData()"
        [shouldDisplayElementsPaginated]="!shouldDetailPostTables()"
        [shouldLazyLoadMedia]="shouldLazyLoadMedia()"
        [tableSortOptions]="storiesTableSortOptions()"
        (tableSortOptionsChange)="tableSortOptionsChange.emit($event)"
        (hasNoData)="storiesHasNoData.set($event)">
    </app-social-network-stories>
</ng-template>
