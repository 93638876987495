<div class="h-screen w-screen overflow-y-auto py-4">
    @if (!isError) {
        @if (data$ | async; as data) {
            @if (!isRedirectionInProgress) {
                @if (!showPrivateReviewPage) {
                    <ng-container [ngTemplateOutlet]="pageTemplate" [ngTemplateOutletContext]="{ restaurant: data.nfc.restaurant }">
                    </ng-container>
                }
                @if (showPrivateReviewPage) {
                    <ng-container
                        [ngTemplateOutlet]="privateReviewTemplate"
                        [ngTemplateOutletContext]="{ restaurant: data.nfc.restaurant, scan: data.scan }">
                    </ng-container>
                }
            } @else {
                <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
                    {{ 'scan.redirection_in_progress' | translate }}
                </div>
            }
        } @else {
            <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
                <app-malou-spinner></app-malou-spinner>
            </div>
        }
    } @else {
        @if (boosterPackDeactivated) {
            <ng-container [ngTemplateOutlet]="boosterPackDeactivatedTemplate"></ng-container>
        } @else {
            <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
                <div><img class="w-24" [src]="Illustration.Cook | illustrationPathResolver" /></div>
                <div>{{ 'scan.error' | translate }}</div>
            </div>
        }
    }
</div>

<ng-template let-restaurant="restaurant" #pageTemplate>
    <div class="flex min-h-full flex-col items-center justify-center gap-y-8 px-8">
        <div>
            <img
                class="h-24 w-24 rounded-full"
                alt="logo"
                [src]="restaurant.logoPopulated?.urls?.original || ('default_logo' | imagePathResolver)" />
        </div>
        <div class="malou-text-32--bold malou-color-text-1 text-center">
            {{ getTemplateTitle | applyPure: restaurant }}
        </div>
        <div class="malou-text-13--regular malou-color-text-2 text-center">
            {{ getTemplateDescription | applyPure: restaurant }}
        </div>
        <div class="mt-8 h-10 w-60">
            <app-rate-with-stars (starClicked)="onStarClicked($event)"></app-rate-with-stars>
        </div>
    </div>
</ng-template>

<ng-template let-restaurant="restaurant" let-scan="scan" #privateReviewTemplate>
    <app-scan-private-review [params]="{ restaurantId: restaurant._id, rating: starNumber, scanId: scan.id }"></app-scan-private-review>
</ng-template>

<ng-template #boosterPackDeactivatedTemplate>
    <div class="flex flex-col items-center">
        <div class="relative ml-5" [ngClass]="{ '!mb-[20%] mt-[30%]': isPhoneScreen(), 'my-[5%]': !isPhoneScreen() }">
            <div class="absolute bottom-0 right-[20px] h-[140px] w-[140px] rounded-full bg-malou-color-primary"></div>
            <img class="relative w-[140px]" alt="" [src]="'pleading-face' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        </div>

        <div class="malou-text-24--semibold malou-color-text-1 text-center">
            {{ 'get_my_gift.image_with_background_templates.booster_pack_deactivated.title' | translate }}
        </div>
        <div
            class="malou-text-15--semibold malou-color-text-2 text-center leading-7"
            [ngClass]="{ 'mt-[25%]': isPhoneScreen(), 'mt-[5%]': !isPhoneScreen() }">
            {{ 'get_my_gift.image_with_background_templates.booster_pack_deactivated.description' | translate }}
        </div>
    </div>
</ng-template>
