import { Action, createReducer, on } from '@ngrx/store';
import { xor } from 'lodash';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { ReviewTemplatesFilters } from ':shared/models/template-filters';

import * as ReviewTemplatesActions from './review-templates.actions';

export interface ReviewTemplateState {
    filters: ReviewTemplatesFilters;
    sort: {
        sortBy: string;
        sortOrder: string;
    };
    templateCount: number;
}

export const initialState: ReviewTemplateState = {
    filters: {
        ratings: [0, 1, 2, 3, 4, 5],
        automated: true,
        manual: true,
        withText: true,
        withoutText: true,
    },
    sort: {
        sortBy: 'name',
        sortOrder: ChartSortBy.ASC,
    },
    templateCount: 0,
};

const reviewTemplatesReducer = createReducer(
    initialState,
    on(ReviewTemplatesActions.editReviewTemplatesFilters, (state, { filters }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...filters,
        },
    })),
    on(ReviewTemplatesActions.editReviewTemplatesFiltersRatings, (state, { ratings }) => ({
        ...state,
        filters: {
            ...state.filters,
            ratings: xor(state.filters.ratings, ratings),
        },
    })),
    on(ReviewTemplatesActions.toggleReviewTemplatesFilters, (state, { filter }) => ({
        ...state,
        filters: {
            ...state.filters,
            [filter]: !state.filters[filter],
        },
    })),
    on(ReviewTemplatesActions.editReviewTemplatesSort, (state, sort) => ({
        ...state,
        sort: {
            ...state.sort,
            ...sort,
        },
    })),
    on(ReviewTemplatesActions.setTemplateCount, (state, { count }) => ({
        ...state,
        templateCount: count,
    })),
    on(ReviewTemplatesActions.resetReviewTemplatesFilters, () => initialState)
);

export function reducer(state: ReviewTemplateState | undefined, action: Action): ReviewTemplateState {
    return reviewTemplatesReducer(state, action);
}
