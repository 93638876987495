import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppInjectorService {
    static injector: Injector;

    constructor(injector: Injector) {
        AppInjectorService.injector = injector;
    }

    static getInjector(): Injector {
        return AppInjectorService.injector;
    }
}
