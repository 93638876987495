import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    computePointsRadius,
    malouChartBackgroundColorGreen,
    malouChartBackgroundColorRed,
    malouChartColorGreen,
    malouChartColorRed,
} from ':shared/helpers';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type LineChartType = Extract<ChartType, 'line'>;

export interface TagEvolutionData {
    [ReviewAnalysisSentiment.POSITIVE]: ChartDataArray;
    [ReviewAnalysisSentiment.NEGATIVE]: ChartDataArray;
}

@Component({
    selector: 'app-tags-evolution-chart',
    templateUrl: './tags-evolution-chart.component.html',
    styleUrls: ['./tags-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsEvolutionChartComponent {
    readonly tagEvolutionData = input.required<TagEvolutionData>();
    readonly labels = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    private _hiddenDatasetIndexes: number[] = [];
    readonly CHART_TYPE: LineChartType = 'line';

    readonly chartDataSets = computed((): ChartDataset<LineChartType, ChartDataArray>[] => this._computeChartData(this.tagEvolutionData()));
    readonly chartLabels = computed((): Date[] => this._computeChartLabels(this.labels()));
    readonly chartOption = computed((): ChartOptions<LineChartType> => this._computeChartOptions(this.viewBy()));

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService
    ) {}

    private _computeChartData(data: TagEvolutionData): ChartDataset<LineChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('statistics.e_reputation.reviews_analysis.negative_sentiments'),
                borderColor: malouChartBackgroundColorRed,
                backgroundColor: malouChartBackgroundColorRed,
                pointBorderColor: malouChartColorRed,
                pointBackgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                fill: true,
                data: data[ReviewAnalysisSentiment.NEGATIVE],
                pointRadius: computePointsRadius(data[ReviewAnalysisSentiment.NEGATIVE]),
            },
            {
                label: this._translateService.instant('statistics.e_reputation.reviews_analysis.positive_sentiments'),
                borderColor: malouChartBackgroundColorGreen,
                backgroundColor: malouChartBackgroundColorGreen,
                pointBorderColor: malouChartColorGreen,
                pointBackgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                fill: true,
                data: data[ReviewAnalysisSentiment.POSITIVE],
                pointRadius: computePointsRadius(data[ReviewAnalysisSentiment.POSITIVE]),
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(viewBy: ViewBy): ChartOptions<LineChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems, viewBy),
                        footer: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipFooter(tooltipItems),
                    },
                    // To get positive label on top of negative label
                    itemSort(itemA: TooltipItem<any>, itemB: TooltipItem<any>): number {
                        return itemB.datasetIndex - itemA.datasetIndex;
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false,
            },
            scales: {
                xAxis: {
                    offset: false,
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        stepSize: 1,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(items: TooltipItem<LineChartType>[], viewBy: ViewBy): string {
        const title: string = items[0].label;
        if (viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.e_reputation.reviews_analysis.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTooltipFooter(items: TooltipItem<LineChartType>[]): string {
        const positiveValue: number = items[1]?.parsed.y ?? 0;
        const negativeValue: number = items[0]?.parsed.y ?? 0;
        const total = positiveValue + negativeValue;
        return this._translateService.instant('statistics.e_reputation.reviews_analysis.on_total_reviews', { total });
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy?.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }
}
