export enum RoutePath {
    DASHBOARD = 'dashboard',
    SEO = 'seo',
    BOOSTERS = 'boosters',
    E_REPUTATION = 'e-reputation',
    SOCIAL_NETWORKS = 'social-networks',
    INTERACTIONS = 'interactions',
    STATISTICS = 'statistics',
    RESOURCES = 'resources',
    SETTINGS = 'settings',

    // SEO
    INFORMATIONS = 'informations',
    POSTS = 'posts',

    // BOOSTERS
    WHEEL_OF_FORTUNE = 'wheel-of-fortune',
    TOTEMS = 'totems',
    BOOSTERS_PRESENTATION = 'presentation',

    // E-REPUTATION
    REVIEWS = 'reviews',
    CAMPAIGNS = 'campaigns',

    // SOCIAL NETWORKS
    SOCIAL_POSTS = 'social-posts',
    STORIES = 'stories',
    INSPIRATIONS = 'inspirations',

    // INTERACTIONS
    MESSAGES = 'messages',
    COMMENTS = 'comments',

    // STATISTICS
    EARNINGS = 'earnings',
    SEO_STATS = 'seo-stats',
    E_REPUTATION_STATS = 'e-reputation-stats',
    SOCIAL_NETWORKS_STATS = 'social-networks-stats',
    BOOSTERS_STATS = 'boosters-stats',

    // RESOURCES
    GALLERY = 'gallery',
    REVIEW_TEMPLATES = 'review-templates',
    MESSAGE_TEMPLATES = 'message-templates',
    CUSTOMERS = 'customers',
    KEYWORDS = 'keywords',

    // SETTINGS
    PLATFORMS = 'platforms',
    AUTOMATIONS = 'automations',
    AI = 'ai',
    USERS = 'users',
}
